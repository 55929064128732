import React, { useState, useEffect } from "react";
import { Button, LabeledInput } from "layers";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import {
  isMatchPasswords,
  isPatternPasswordValid,
} from "helpers/profileValidation";
import { IUserInfo } from "models/interfaces";
import { useTranslation } from "react-i18next";
import { Confirmation } from "components/modals/Confirmation";
import { modifyUserInfo } from "redux/actions/Authorization";
import { useAppDispatch, useAppSelector } from "redux/store";

export default function ChangePassword() {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [isShowRePassword, setIsShowRePassword] = useState(false);
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [hasOneDigitChars, setHasOneDigitChars] = useState(false);
  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasUppercaseChar, setHasUppercaseChar] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const editPasswordData: any = useAppSelector((state) => state.editUserInfo);
  const [userInfo, setUserInfo] = useState<IUserInfo>({
    personalPhoto: null,
    aboutMe: "",
    firstName: "",
    state: "",
    city: "",
    postalCode: "",
    lastName: "",
    displayName: "",
    phoneCode: "",
    phoneNumberWithoutCode: "",
    verificationCode: "",
    email: "",
    address: "",
    positionLat: "",
    positionLong: "",
    passportPhoto: null,
    secondIdentityPhoto: null,
    isPhoneNumberVerified: false,
    isAddressVerified: false,
    isPassportVerified: false,
    isSecondIdentityVerified: false,
    balance: "",
    password: "",
    rePassword: "",
    rating: 0,
  });

  useEffect(() => {
    if (isEightChars()) {
      setHasEightChars(true);
    } else {
      setHasEightChars(false);
    }
    if (isUpperCaseLowerCase()) {
      setHasUppercaseChar(true);
    } else {
      setHasUppercaseChar(false);
    }
    if (isSpecialChars()) {
      setHasSpecialChar(true);
    } else {
      setHasSpecialChar(false);
    }
    if (hasOneDigit()) {
      setHasOneDigitChars(true);
    } else {
      setHasOneDigitChars(false);
    }
  }, [userInfo]);

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const isUpperCaseLowerCase = () => {
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    return (
      lowerCaseRegex.test(userInfo?.password) &&
      upperCaseRegex.test(userInfo?.password)
    );
  };

  const isSpecialChars = () => {
    const passwordRegex = /[@$!%?&]/;
    return passwordRegex.test(userInfo?.password);
  };

  const hasOneDigit = () => {
    const passwordRegex = /\d/;
    return passwordRegex.test(userInfo?.password);
  };

  const isEightChars = () => {
    const passwordRegex = /.{8,}/;
    return passwordRegex.test(userInfo?.password);
  };

  const editPassword = () => {
    setEditClicked(true);
    setIsOpenModal(!isOpenModal);
  };

  const callUpdateInfo = () => {
    const body = new FormData();
    body.append("Password", userInfo.password);
    body.append("RePassword", userInfo.rePassword);
    dispatch(modifyUserInfo(body));
  };

  // useEffect(() => {
  //  if(editPasswordData) window.location.reload();
  // }, [editPasswordData]);

  const updatePersonalInformation = () => {
    callUpdateInfo();
  };

  useEffect(() => {
    if (isConfirmed && isOpenModal) {
      updatePersonalInformation();
    }
  }, [isConfirmed, isOpenModal]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };

  const handleShowRePassword = () => {
    setIsShowRePassword(!isShowRePassword);
  };

  return (
    <div className="change-password-profile-wrapper mt-4">
      <span className="profile-accordion-title">{t("changePassword")}</span>
      <Row className="drawer mt-3">
        <Col xs={12} sm={12} md={6} lg={6} className="mb-4">
          <div className="mb-2 text-align-last-left ">
            {isShowPassword ? (
              <a
                className="hide-show-pass-wrapper"
                onClick={handleShowPassword}
              >
                <FontAwesomeIcon icon={faEye} /> Hide
              </a>
            ) : (
              <a
                className="hide-show-pass-wrapper"
                onClick={handleShowPassword}
              >
                <FontAwesomeIcon icon={faEyeSlash} /> Show
              </a>
            )}
          </div>
          <div className="labeled-input-wrapper">
            <LabeledInput
              size="sm"
              id="password-input"
              placeholder="**********"
              className={`half-custom-input-register inputText text-left pr-0 pl-2 ${
                editClicked &&
                !isPatternPasswordValid(userInfo.password) &&
                "empty-input-style"
              }`}
              name="password"
              type={isShowPassword ? "text" : "password"}
              value={userInfo.password}
              onChange={handleChange}
            />
          </div>
          {editClicked && !isPatternPasswordValid(userInfo.password) && (
            <span className="err-validation">
              "Password pattern is not correct"
            </span>
          )}
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className="mb-4 text-left pl-0">
          <div className="mb-2 ml-3 text-align-last-left ">
            {isShowRePassword ? (
              <a
                className="hide-show-pass-wrapper"
                onClick={handleShowRePassword}
              >
                <FontAwesomeIcon icon={faEye} /> Hide
              </a>
            ) : (
              <a
                className="hide-show-pass-wrapper"
                onClick={handleShowRePassword}
              >
                <FontAwesomeIcon icon={faEyeSlash} /> Show
              </a>
            )}
          </div>
          <div className="labeled-input-wrapper">
            <LabeledInput
              size="sm"
              id="rePassword-input"
              placeholder="**********"
              name="rePassword"
              type={isShowRePassword ? "text" : "password"}
              className={`half-custom-input-register ml-3 inputText text-left pr-0 pl-2 ${
                editClicked &&
                !isMatchPasswords(userInfo.rePassword, userInfo.password) &&
                "empty-input-style"
              }`}
              value={userInfo.rePassword}
              onChange={handleChange}
            />
          </div>
          {editClicked &&
            !isMatchPasswords(userInfo.rePassword, userInfo.password) && (
              <span className="err-validation">The passwords don't match!</span>
            )}
        </Col>
        <Col xs={12} className={`mb-4 ${isPersian ? "dir-rtl" : "dir-ltr"}`}>
          <span className="password-condition-title mb-2">
            {t("createPassContains")}:
          </span>
          <span
            className={`${
              hasEightChars
                ? "password-condition-correct"
                : "password-condition-wrong"
            }`}
          >
            {hasEightChars && <FontAwesomeIcon icon={faCheck} />}
            {t("eightChars")}
          </span>
          <span
            className={`${
              hasOneDigitChars
                ? "password-condition-correct"
                : "password-condition-wrong"
            }`}
          >
            {hasOneDigitChars && <FontAwesomeIcon icon={faCheck} />}
            {t("oneNumber")}
          </span>
          <span
            className={`${
              hasUppercaseChar
                ? "password-condition-correct"
                : "password-condition-wrong"
            }`}
          >
            {hasUppercaseChar && <FontAwesomeIcon icon={faCheck} />}
            {t("uppercaseLowercase")}
          </span>
          <span
            className={`${
              hasSpecialChar
                ? "password-condition-correct"
                : "password-condition-wrong"
            }`}
          >
            {hasSpecialChar && <FontAwesomeIcon icon={faCheck} />}1
            {t("specialChar")}{" "}
            <span
              className={`${
                hasSpecialChar
                  ? "password-special-char-example-correct"
                  : "password-special-char-example"
              }  `}
            >
              $, !, @, %, &
            </span>
          </span>
        </Col>
      </Row>
      <Row className="my-4 ml-3">
      {isPersian ? (
        <span className="close-account-texts">
          ما قدردان حضور شما هستیم ، اما اگر نیاز به بستن حساب خود دارید،
          می‌توانید این کار را از{" "}
          <a style={{ color: "#60a0c5" }} href="/close-accounts">
            اینجا{" "}
          </a>
          انجام دهید.
        </span>
      ) : (
        <span>
          Your presence has been appreciated, but if you need to close your
          account, you can do it{" "}
          <a style={{ color: "#60a0c5" }} href="/close-accounts">
            here
          </a>
          .
        </span>
      )}
      </Row>
      <Row className={`update-info-btn-wrapper mt-4 `}>
        <Button
          variant="primary"
          className="update-info-btn"
          onClick={editPassword}
        >
          {t("updateInformation")}
        </Button>
        {isOpenModal && (
          <Confirmation
            title={t("updateInformation")}
            description={t("sureEditProfile")}
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
            setIsConfirmed={setIsConfirmed}
            type="updateProfile"
          />
        )}
      </Row>
    </div>
  );
}
