import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Col, Row, Dropdown } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { IRetrieve, ITravel } from "models/interfaces";
import { InputOption, ReuseTravelHistory } from "components";
import { SizeInformation } from "components/modals/sizeInformation";
import { getDate, convertHumanDateToUnix } from "helpers/convertDate";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getAllHomeTravelFilter } from "redux/actions/flight";
import { MultipleUploader } from "components";
import { Oval } from "react-loader-spinner";
import {
  searchLocation,
  searchAirportLocation,
  searchLocationApi,
} from "helpers/searchLocation";
import { searchAirline } from "helpers/searchAirline";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import {
  faClose,
  faInfo,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  getAllSizeRange,
  getAllDeliveryType,
  getAllPackagesType,
  getAllPaymentMethod,
  getAllCancellationPolicy,
} from "redux/actions/types";
import { addNewLandingTravel } from "redux/actions/landing";
import {
  isValidPackageType,
  isValidPayment,
  isValidSize,
  isValidPolicy,
  isValidDefinedLengthSize,
  isValidDefinedWidthSize,
  isValidDefinedHeightSize,
  isValidFrom,
  isValidTo,
  isEndDateGreaterThanStartDate,
  isEndDateGreaterEqualThanStartDate,
  isValid,
} from "helpers/travelValidation";
import { useTranslation } from "react-i18next";
import { Calendar, utils } from "react-modern-calendar-datepicker";
import { Link } from "react-router-dom";
// import { registerLocale, setDefaultLocale } from "react-datepicker";
// import fa from "date-fns-jalali/locale/fa-IR";
// import { toDate, format } from "date-fns-jalali";

interface IProps {
  setIsOpen: Function;
  isOpen: boolean;
  onSelectReuse: (key: any) => void;
}

export const AddTravel: React.FC<IProps> = ({
  setIsOpen,
  isOpen,
  onSelectReuse,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // registerLocale("fa", fa); // Register the Persian (Farsi) locale
  // setDefaultLocale("fa");
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const [offerData, setOfferData] = useState<ITravel>({
    packagetypeIds: [],
    packageType: "",
    serviceIds: [],
    sizerangeIds: [],
    weight: "",
    weightUnit: "kg",
    paymentmethodIds: [],
    value: "",
    sizeWidth: "",
    sizeHeight: "",
    sizeLength: "",
    lengthUnit: "cm",
    deliverytypeIds: [],
    fromCountry: "",
    fromCountryCity: "",
    toCountry: "",
    toCountryCity: "",
    fromDate1: "",
    fromDate2: "",
    toDate1: "",
    toDate2: "",
    offerPrice: "",
    currencyType: "CAD",
    cancellationPolicyId: "",
    message: "",
    images: [],
    airlineName: "",
    flightNumber: "",
  });
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isCancellation, setIsCancellation] = useState(true);
  const [packagetypeIds, setPackagetypeIds] = useState<any>([]);
  const [serviceIds, setServiceIds] = useState<any>([]);
  const [sizerangeIds, setSizerangeIds] = useState<any>([]);
  const [paymentmethodIds, setPaymentmethodIds] = useState<any>([]);
  const [deliverytypeIds, setDeliverytypeIds] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [deliveryType, setDeliveryType] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [payment, setPayment] = useState(null);
  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [cancellationPolicyOptions, setCancellationPolicyOptions] = useState(
    []
  );
  const [paymentsOptions, setPaymentsOptions] = useState([]);
  const windowSize = UseWindowSize();
  const [weightUnit, setWeightUnit] = useState({ value: "kg", label: "KG" });
  const [lengthUnit, setLengthUnit] = useState({ value: "cm", label: "CM" });
  const [airline, setAirline] = useState<any>();
  const [type, setType] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [reuseData, setReuseData] = useState<any>({});
  const [isCompanion, setIsCompanion] = useState(false);
  const [isValidFromAirport, setIsValidFromAirport] = useState(true);
  const [isValidToAirport, setIsValidToAirport] = useState(true);
  const [retrieveData, setRetrieveData] = useState<IRetrieve>({
    type: "travel",
    fromCityEntityId: "",
    toCityEntityId: "",
    fromDate1: "",
    fromDate2: "",
  });
  const [size, setSize] = useState(null);
  const [sizeOptions, setSizeOptions] = useState([]);
  const createOffer: any = useAppSelector((state) => state.createOffer);
  const sizeRanges = useAppSelector((state) => state.sizeRange);
  const packagesType = useAppSelector((state) => state.packageTypes);
  const policies = useAppSelector((state) => state.cancellationPolicy);
  const payments = useAppSelector((state) => state.paymentMethod);
  const deliveryTypes = useAppSelector((state) => state.deliveryType);
  const [arrivalBetweenDate, setArrivalBetweenDate] = useState(new Date());
  const [arrivalToDate, setArrivalToDate] = useState(new Date());
  const [departureBetweenDate, setDepartureBetweenDate] = useState(new Date());
  const [departureToDate, setDepartureToDate] = useState(new Date());
  const [fromDate1, setFromDate1] = useState(getDate(new Date()));
  const [fromDate2, setFromDate2] = useState(getDate(new Date()));
  const [toDate1, setToDate1] = useState(getDate(new Date()));
  const [toDate2, setToDate2] = useState(getDate(new Date()));
  const [from, setFrom] = useState<any>();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [showReuse, setShowReuse] = useState(false);
  const [fromCountry, setFromCountry] = useState("");
  const [fromCountryCity, setFromCountryCity] = useState("Canada, Toronto");
  const [to, setTo] = useState<any>();
  const [toCountry, setToCountry] = useState("");
  const [toCountryCity, setToCountryCity] = useState("Iran, Tehran");
  const [isLoading, setIsLoading] = useState(false);
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [isSizeDefined, setIsSizeDefined] = useState(false);
  const [isCad, setIsCad] = useState(true);
  const [isCash, setIsCash] = useState(true);
  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];
  const unitLengthOption = [
    { value: "cm", label: "CM" },
    { value: "inch", label: "INCH" },
  ];
  const currencies = useAppSelector((state) => state.currencies);
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [currenciesOption, setCurrenciesOption] = useState([
    {
      value: localStorage.getItem("currency"),
      label: localStorage.getItem("currency"),
    },
  ]);
  const isMobile = windowSize.width < 768;
  const isMobileTablet = windowSize.width < 992;

  const preventTypeText = (e) => {
    const validInputRegex = /^[0-9.]$/; // Allow digits and dot
    const currentValue = e.target.value + e.key;
    if (validInputRegex.test(e.key) || e.key === "Backspace") {
      const regex = /^(?!1000(\.0)?$)\d{1,3}(\.\d{0,1})?$/;
      if (regex.test(currentValue) || currentValue === "") {
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const token = window.localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(
    token === "undefined" || token === "null" || token === null ? false : true
  );

  window.addEventListener("storage", () => {
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogin(false);
    else setIsLogin(true);
  });

  useEffect(() => {
    // console.log(reuseData);
    // setOfferData({
    //   ...offerData,
    //   offerPrice: reuseData?.offerPrice,
    //   // offerPrice: reuseData?.offerPrice,
    // });
    setShowReuse(false);
    onSelectReuse(false);
  }, [reuseData]);

  useEffect(() => {
    if (Object.keys(reuseData).length !== 0) {
      const data = reuseData;
      const width = data?.sizeWidth;
      const height = data?.sizeHeight;
      const length = data?.sizeLength;
      const currency = data.currencyType ? data.currencyType : "CAD";
      const unit = data.weightUnit ? data.weightUnit : "KG";
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      searchLocationApi(data.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}
                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}
                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      setPolicy(
        cancellationPolicyOptions.find(
          (option) => option.label === data.cancellationpolicy
        )
      );
      setArrivalBetweenDate(
        data?.fromDate1 ? convertHumanDateToUnix(data?.fromDate1) : new Date()
      );
      setArrivalToDate(
        data?.toDate1 ? convertHumanDateToUnix(data?.toDate1) : new Date()
      );
      setDepartureBetweenDate(
        data?.fromDate2 ? convertHumanDateToUnix(data?.fromDate2) : new Date()
      );
      setDepartureToDate(
        data?.toDate2 ? convertHumanDateToUnix(data?.toDate2) : new Date()
      );
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      setAirline({ value: data?.airlineName, label: data?.airlineName });

      setOfferData({
        ...offerData,
        weight: data.weight,
        message: data.description,
        value: data.itemValue,
        offerPrice: data.offerPrice,
        sizeWidth: width,
        sizeHeight: height,
        sizeLength: length,
        flightNumber: data?.flightNumber,
      });
      setImages(data.images);
    }
  }, [reuseData]);

  useEffect(() => {
    if (Object.keys(reuseData).length !== 0) {
      const data = reuseData;

      if (data.packagetypes) {
        let types = data?.packagetypes?.split(", ");
        if (data?.packagetypes?.includes(",")) {
          const packages = types
            .map((item) => {
              return typeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setType(packages);
        } else
          setType(
            typeOptions.find((option) => option.label === data.packagetypes)
          );
      } else {
        setType([{ value: packagesType[0]?.id, label: packagesType[0]?.name }]);
      }
      if (data.deliveryTypes) {
        let types = data?.deliveryTypes?.split(", ");
        if (data?.deliveryTypes?.includes(",")) {
          const deliveries = types
            .map((item) => {
              return deliveryTypeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setDeliveryType(deliveries);
        } else
          setDeliveryType(
            deliveryTypeOptions.find(
              (option) => option.label === data.deliveryTypes
            )
          );
      }
      if (data.sizeranges) {
        let sizes = data?.sizeranges?.split(", ");
        if (
          data?.sizeranges?.includes("Custom") ||
          data?.sizeranges?.includes("سفارشی")
        )
          setIsCustom(true);
        else setIsCustom(false);
        if (data?.sizeranges?.includes(",")) {
          const ranges = sizes
            .map((item) => {
              return sizeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setSize(ranges);
          const results = ranges?.map((pkg) => {
            return pkg.value;
          });
          setSizerangeIds(results);
        } else
          setSize(
            sizeOptions.find((option) => option.label === data.sizeranges)
          );
      } else {
        setSize({ value: sizeRanges[0]?.id, label: sizeRanges[0]?.name });
      }

      if (data.paymentmethods) {
        if (data?.paymentmethods?.includes(",")) {
          const paymentsData = data?.paymentmethods
            ?.split(", ")
            .map((item) => {
              return paymentsOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setPayment(paymentsData);
        } else
          setPayment(
            paymentsOptions.find(
              (option) => option.label === data.paymentmethods
            )
          );
      } else {
        setPayment({ value: payments[0]?.id, label: payments[0]?.name });
      }
    }
  }, [
    reuseData,
    typeOptions,
    sizeOptions,
    deliveryTypeOptions,
    paymentsOptions,
  ]);

  useEffect(() => {
    dispatch(getAllSizeRange());
    dispatch(getAllDeliveryType());
    dispatch(getAllPackagesType());
    dispatch(getAllCancellationPolicy());
    dispatch(getAllPaymentMethod());
  }, []);

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const handleCurrencyChange = (selected) => {
    if (selected.value !== "CAD") setIsCad(false);
    else setIsCad(true);
    setCurrency(selected);
    localStorage.setItem("currency", selected.value);
  };

  const handleWeightUnitChange = (selected) => {
    setWeightUnit(selected);
  };

  const handleLengthUnitChange = (selected) => {
    setLengthUnit(selected);
  };

  const openInfoModal = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
      height: 50,
      minHeight: 50,
      fontSize: isMobile && "12px",
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? "#c1c1c1" : "#00043d",
      cursor: isDisabled ? "default" : "pointer",
      backgroundColor: "#fff",
      fontSize: isMobile && "12px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
  };

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
      fontSize: isMobile && "12px",
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#707070",
      flexWrap: "nowrap",
      fontSize: isMobile && "12px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
  };

  // const changeFromPlace = (e) => {
  //   setFrom(getCityCountryFromGooglePlace(e).location);
  //   setFromCountry(getCityCountryFromGooglePlace(e).country);
  //   setFromCountryCity(getCityCountryFromGooglePlace(e).countryCity);
  // };

  // const changeToPlace = (e) => {
  //   setTo(getCityCountryFromGooglePlace(e).location);
  //   setToCountry(getCityCountryFromGooglePlace(e).country);
  //   setToCountryCity(getCityCountryFromGooglePlace(e).countryCity);
  // };

  const handleTermsCheckedChange = () => {
    setTermsChecked(!termsChecked);
  };

  useEffect(() => {
    const options = deliveryTypes?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setDeliveryType(null);
    // setDeliverytypeIds([deliveryTypes[0]?.id]);
    setDeliveryTypeOptions(options);
  }, [deliveryTypes]);

  useEffect(() => {
    const options = payments?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setPayment({ value: payments[0]?.id, label: payments[0]?.name });
    setPaymentmethodIds([payments[0]?.id]);
    setPaymentsOptions(options);
  }, [payments]);

  useEffect(() => {
    const options = policies?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setPolicy(null);
    const valueToDisable = "23e33081-d1fd-48ef-a17a-fbbdc69076ce";
    const modifiedCancellationPolicyOptions = options.map((option) => ({
      ...option,
      isDisabled: option.value === valueToDisable,
    }));
    // const modifiedCancellationPolicyOptions = options.filter(
    //   (option) => option.value !== valueToDisable
    // );
    setCancellationPolicyOptions(modifiedCancellationPolicyOptions);
    // setCancellationPolicyOptions(options);
  }, [policies]);

  useEffect(() => {
    const options = sizeRanges?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSize(null);
    setSizerangeIds([sizeRanges[0]?.id]);
    setSizeOptions(options);
  }, [sizeRanges]);

  useEffect(() => {
    const options = packagesType?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setType(null);
    setPackagetypeIds([packagesType[0]?.id]);
    // setPackagetypeIds(
    //   [
    //     ...packagetypeIds,
    //     { value: packagesType[0]?.id, label: packagesType[0]?.name },
    //   ].filter(function (x) {
    //     return x.value !== undefined;
    //   })
    // );
    setTypeOptions(options);
  }, [packagesType]);

  const handleAirlineChange = (selected) => {
    setAirline(selected);
  };

  const handleFromChange = (selected) => {
    setFrom(selected);
  };

  const handleToChange = (selected) => {
    setTo(selected);
  };

  const handlePolicyChange = (selected) => {
    setPolicy(selected);
  };

  const handlePaymentChange = (selected) => {
    setPayment(selected);
    if (selected.value === "6dd94ea2-a4d7-4dd6-824c-b004b65a6b85")
      setIsCash(false);
    else setIsCash(true);
    if (selected.value === "0737c3c9-f109-460d-aeaf-431c80df4e65")
      setIsCancellation(true);
    else setIsCancellation(false);
    setPaymentmethodIds(selected.value);
  };

  const handleDeliveryTypeChange = (selected) => {
    setDeliveryType(selected);
    setDeliverytypeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
  };

  const handleSizeChange = (selected) => {
    setSize(selected);
    setSizerangeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
    if (
      selected?.find((item) => {
        return item.value === "6a50f6c5-0c00-498e-9653-1d8914172181";
      })
    ) {
      setIsCustom(true);
      setIsSizeDefined(true);
    } else {
      setIsCustom(false);
      setIsSizeDefined(false);
    }
  };

  const handleTypeChange = (selected) => {
    // if (
    //   selected?.find((item) => {
    //     return item.label === "Traveling Companion";
    //   })
    // ) {
    //   setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);
    //   setIsCompanion(true);
    //   setSize({
    //     value: "6a50f6c5-0c00-498e-9653-1d8914172181",
    //     label: "Custom",
    //   });
    //   setSizerangeIds("6a50f6c5-0c00-498e-9653-1d8914172181");
    //   setOfferData({
    //     ...offerData,
    //     sizeWidth: "0",
    //     sizeHeight: "0",
    //     sizeLength: "0",
    //   });
    //   setType([
    //     {
    //       value: "60db617a-dba2-406c-a960-1928a8b45320",
    //       label: "Traveling Companion",
    //     },
    //   ]);
    //   setIsSizeDefined(false);
    // } else {
    setIsCompanion(false);
    setIsSizeDefined(false);
    setType(selected);
    var result1 = selected.map(function (item) {
      return item.value;
    });
    const result2 = result1.filter(function (item) {
      return item !== "60db617a-dba2-406c-a960-1928a8b45320";
    });
    setPackagetypeIds(result2);
    // }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    // setOfferData({ ...offerData, [e.target.name]: e.target.value });
    const newValue = e.target.value;
    if (newValue.length <= 100) {
      setOfferData({ ...offerData, [e.target.name]: newValue });
    } else {
      setOfferData({
        ...offerData,
        [e.target.name]: newValue.slice(0, 100),
      });
    }
  };

  const onArrivalBetweenDateChange = (date) => {
    setArrivalBetweenDate(date);
    setDepartureBetweenDate(date);
    setArrivalToDate(date);
    setDepartureToDate(addDays(date, 1));
    setFromDate1(getDate(date));
    setFromDate2(getDate(date));
    setToDate1(getDate(date));
    setToDate2(getDate(addDays(date, 1)));
  };

  const onDepartureFromDateChange = (date) => {
    setDepartureBetweenDate(date);
    setArrivalToDate(date);
    setDepartureToDate(addDays(date, 1));
    setFromDate2(getDate(date));
    setToDate1(getDate(date));
    setToDate2(getDate(addDays(date, 1)));
  };

  const onArrivalToDateChange = (date) => {
    setArrivalToDate(date);
    setDepartureToDate(addDays(date, 1));
    setToDate1(getDate(date));
    setToDate2(getDate(addDays(date, 1)));
  };

  const onDepartureToDateChange = (date) => {
    setDepartureToDate(date);
    setToDate2(getDate(addDays(date, 1)));
  };

  const addNewTravel = () => {
    setSubmitClicked(true);
    const data = {
      type,
      size,
      // service,
      payment,
      policy,
      length: offerData.sizeLength,
      width: offerData.sizeWidth,
      height: offerData.sizeHeight,
      from,
      to,
      arrivalBetweenDate,
      departureBetweenDate,
      arrivalToDate,
      departureToDate,

      // departureBetweenDate,
      // departureToDate,
      // arrivalBetweenDate,
      // arrivalToDate,
    };
    if (!isValid(termsChecked, data, isCancellation)) {
      toast.error(
        lang === "fa"
          ? "پارامترها را به درستی وارد نمایید"
          : "Enter the parameters correctly!"
      );
      return;
    }
    addNewTravelApiCall();
    document.documentElement.style.overflow = "auto";
  };

  const addNewTravelApiCall = () => {
    document.documentElement.style.overflow = "hidden";
    const body = new FormData();
    body.append("packagetypeIds", packagetypeIds);
    body.append("packageType", "");
    // body.append("serviceIds", serviceIds);
    body.append("sizerangeIds", sizerangeIds);
    body.append("weight", offerData?.weight);
    body.append("weightUnit", weightUnit?.value);
    body.append("paymentmethodIds", paymentmethodIds);
    body.append("value", offerData?.value);
    body.append("sizeWidth", isCustom ? offerData?.sizeWidth : "");
    body.append("sizeHeight", isCustom ? offerData?.sizeHeight : "");
    body.append("sizeLength", isCustom ? offerData?.sizeLength : "");
    body.append("lengthUnit", lengthUnit?.value);
    body.append("deliverytypeIds", deliverytypeIds);
    body.append("fromCityEntityId", from?.value ? from?.value : "");
    body.append("toCityEntityId", to?.value ? to?.value : "");
    // body.append("fromCountry", fromCountry);
    // body.append("fromCountryCity", from);
    // body.append("toCountry", toCountry);
    // body.append("toCountryCity", to);
    body.append("fromDate1", fromDate1);
    body.append("fromDate2", fromDate2);
    body.append("toDate1", toDate1);
    body.append("toDate2", toDate2);
    body.append("offerPrice", offerData?.offerPrice);
    body.append("flightNumber", offerData.flightNumber);
    body.append("currencyType", currency?.value);
    body.append("cancellationPolicyId", policy?.value ? policy?.value : "");
    body.append("message", offerData.message);
    for (const image of images) {
      body.append("images", image);
    }
    body.append("airlineName", airline?.label ? airline?.label : "");
    dispatch(addNewLandingTravel(body));
    setIsLoading(true);
  };

  useEffect(() => {
    if (createOffer?.isSuccess === false) {
      document.documentElement.style.overflow = "hidden";
      if (sizerangeIds[0]?.includes("6a50f6c5-0c00-498e-9653-1d8914172181")) {
        setIsCustom(true);
        setIsSizeDefined(true);
      }
      setIsLoading(false);
    } else if (createOffer?.isSuccess === true) {
      document.documentElement.style.overflow = "auto";
      setIsLoading(false);
      setIsOpen(false);
      const data = {
        type: "travel",
        packagetypeIds: null,
        weightrangeIds: null,
        deliverytypeIds: null,
        sizerangeIds: null,
        serviceIds: null,
        paymentmethodIds: null,
        // cancellationpolicyIds: "422A36FD-F65F-4A1D-830D-20C1D66C42AB",
        cancellationpolicyIds: null,
        userLevelIds: null,
        page: 1,
      };
      dispatch(getAllHomeTravelFilter(data));
      document.documentElement.style.overflow = "auto";
      navigate("/dashboard/myTravels", { state: t("myTravels") });
    }
  }, [createOffer]);

  useEffect(() => {
    setRetrieveData({
      type: "travel",
      fromCityEntityId: from?.value,
      toCityEntityId: to?.value,
      fromDate1: fromDate1,
      fromDate2: fromDate2,
    });
  }, [from, to, fromDate1, fromDate2]);

  const checkLabel = () => {
    if (
      Array.isArray(size) &&
      size?.find((item) => {
        return (
          item.value === "6a50f6c5-0c00-498e-9653-1d8914172181" && (
            <span className="star-require">*</span>
          )
        );
      })
    ) {
      return <span className="star-require">*</span>;
    } else if (size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181") {
      <span className="star-require">*</span>;
    }
  };

  const handleChangeClose = () => {
    if (showReuse === true) {
      setShowReuse(false);
      onSelectReuse(false);
    } else {
      setIsOpen(!isOpen);
      document.documentElement.style.overflow = "auto";
      navigate("/");
      // window.location.reload();
    }
  };

  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const openReuseSidebar = () => {
    if (from?.value !== undefined && to?.value !== undefined) {
      setShowReuse(true);
      onSelectReuse(true);
    } else if (from?.value === undefined) {
      toast.warn(
        lang === "fa"
          ? "لطفا فرودگاه مبدا را وارد کنید"
          : "Please enter departure airport"
      );
      if (!isValidFrom(from) || from === undefined)
        setIsValidFromAirport(false);
    } else if (to?.value === undefined) {
      toast.warn(
        lang === "fa"
          ? "لطفا فرودگاه مقصد را وارد کنید"
          : "Please enter arrival airport"
      );
      if (!isValidTo(to) || to === undefined) setIsValidToAirport(false);
    }
  };

  useEffect(() => {
    if (isValidFrom(from)) setIsValidFromAirport(true);
    if (isValidTo(to)) setIsValidToAirport(true);
  }, [from, to]);

  return (
    <>
      <div
        className={`${
          showReuse ? "set-alert-slider-container" : "request-slider-container"
        }`}
      >
        <Row className={`request-wrapper pb-3`}>
          <Button
            variant="white"
            className="close-sidebar-btn"
            onClick={handleChangeClose}
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
          <Col
            xs={12}
            className={`request-form`}
            style={{ paddingRight: isMobile ? "15px" : "1.79rem" }}
          >
            <div className={`${isMobile ? "" : "d-flex"}`}>
              <h1 className="d-inline-block">
                {showReuse ? t("travelHistory") : t("addTravel")}
              </h1>
              {!showReuse && (
                <Button
                  variant="primary"
                  className="d-inline-block reuse-previous-travel-btn"
                  onClick={openReuseSidebar}
                >
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                  {t("RetrievePreviousTravel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {showReuse ? (
          <ReuseTravelHistory
            retrieveData={retrieveData}
            setReuseData={setReuseData}
          />
        ) : (
          <Row className={`request-wrapper pt-0 ${isPersian ? "dir-rtl" : ""}`}>
            <Col xs={12} className={`request-form`}>
              <div className="send-input-wrapper">
                <Row className="align-items-center">
                  <Col
                    xs={2}
                    className={`${isPersian ? "text-right pr-3" : ""}`}
                  >
                    <span className="send-pack-title">
                      {t("from")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col xs={10}>
                    {/* <GooglePlacesAutocomplete
                  selectProps={{
                    className: "custom-place-from d-inline-block",
                    value: from,
                    placeholder: "City",
                    onChange: (e) => changeFromPlace(e),
                    noOptionsMessage: () => null,
                    components: {
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      MenuList: SelectMenuButton,
                    },
                    styles: customStyle,
                  }}
                  apiKey="AIzaSyBxY7vo5Y6IHZ2_0Xk0g3ZBFyVL_wZTuho"
                /> */}
                    <AsyncSelect
                      className={`custom-package-place-from ${
                        ((submitClicked && !isValidFrom(from)) ||
                          !isValidFromAirport) &&
                        "custom-empty-input-style"
                      }`}
                      value={from}
                      onChange={handleFromChange}
                      cacheOptions
                      defaultOptions
                      placeholder={
                        <span className="select-placeholder-text">
                          {t("countryCityAirport")}
                        </span>
                      }
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                      styles={customStyle}
                      loadOptions={searchAirportLocation}
                      loadingMessage={() => "Loading ..."}
                    />
                  </Col>
                </Row>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={2}
                    className={`${isPersian ? "text-right pr-3" : ""}`}
                  >
                    <span className="send-pack-title">
                      {t("to")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col xs={10}>
                    <AsyncSelect
                      className={`custom-input-at-destination ${
                        ((submitClicked && !isValidTo(to)) ||
                          !isValidToAirport) &&
                        "custom-empty-input-style"
                      }`}
                      value={to}
                      onChange={handleToChange}
                      cacheOptions
                      defaultOptions
                      placeholder={
                        <span className="select-placeholder-text">
                          {t("countryCityAirport")}
                        </span>
                      }
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                      styles={customStyle}
                      loadOptions={searchAirportLocation}
                      loadingMessage={() => "Loading ..."}
                    />
                  </Col>
                </Row>
                {/* <GooglePlacesAutocomplete
                  selectProps={{
                    className: "custom-place-to d-inline-block",
                    value: to,
                    placeholder: "City",
                    onChange: (e) => changeToPlace(e),
                    noOptionsMessage: () => null,
                    components: {
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      MenuList: SelectMenuButton,
                    },
                    styles: customStyle,
                  }}
                  apiKey="AIzaSyBxY7vo5Y6IHZ2_0Xk0g3ZBFyVL_wZTuho"
                /> */}
              </div>
            </Col>
            <Col xs={12} className={`request-form`}>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={isPersian ? 4 : 5}
                      className={`${isPersian ? "text-right pr-3" : ""}`}
                    >
                      <span className="send-pack-title">
                        {t("travelDepartureDate")}
                        <span className="star-require">*</span>
                      </span>
                    </Col>
                    <Col
                      xs={isPersian ? 8 : 7}
                      className={isPersian ? "pr-0" : "pl-0"}
                    >
                      <DatePicker
                        className="custom-travel-input-between w-100 m-0"
                        selected={arrivalBetweenDate}
                        onChange={(date) => onArrivalBetweenDateChange(date)}
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        minDate={new Date()}
                        // value={format(
                        //   toDate(arrivalBetweenDate),
                        //   "EEEE, MM/dd/yyyy",
                        //   { locale: fa }
                        // )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={isPersian ? 4 : 5}
                      className={`${!isMobile && "text-center"} ${
                        isPersian ? "text-right pr-3" : "pr-0"
                      }`}
                    >
                      <span className="send-pack-title">
                        {t("travelArrivalDate")}
                        <span className="star-require">*</span>
                      </span>
                    </Col>
                    <Col
                      xs={isPersian ? 8 : 7}
                      className={`${isPersian ? " text-right pr-1" : "pl-0"}`}
                    >
                      <DatePicker
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        onChange={(date) => onDepartureFromDateChange(date)}
                        selected={departureBetweenDate}
                        className={`custom-input-to-travel w-100 m-0 ${
                          submitClicked &&
                          !isEndDateGreaterEqualThanStartDate(
                            arrivalBetweenDate,
                            departureBetweenDate
                          ) &&
                          "empty-input-style"
                        }`}
                        minDate={addDays(arrivalBetweenDate, 0)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={12} className={`request-form`}>
              <Row className="align-items-center mt-3">
                <Col
                  xs={5}
                  lg={isPersian ? 4 : 3}
                  md={isPersian ? 4 : 3}
                  sm={5}
                  className={`${isPersian ? "text-right pr-3" : ""}`}
                >
                  <span className="send-pack-title">
                    {t("maxSizeParcelCarry")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col
                  xs={6}
                  lg={isPersian ? 7 : 8}
                  md={isPersian ? 7 : 8}
                  sm={6}
                  className="pl-0"
                >
                  <Select
                    className={`custom-select-travel-size w-100 ${
                      submitClicked &&
                      !isValidSize(size) &&
                      "custom-empty-input-style"
                    }`}
                    value={size}
                    onChange={handleSizeChange}
                    options={sizeOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      Option: InputOption,
                    }}
                    styles={customStyle}
                    isMulti
                    isDisabled={isCompanion}
                    placeholder={t("selectMaxSizePlaceholder")}
                  />
                </Col>
                <Col
                  xs={1}
                  lg={1}
                  md={1}
                  sm={1}
                  className={`${isPersian ? "pr-0" : "pl-0"}`}
                >
                  <Dropdown
                    className="size-info-dropdown d-inline"
                    onToggle={handleSelect}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="px-0 mx-0"
                    >
                      <div className="triangle">
                        <FontAwesomeIcon
                          icon={faInfo}
                          className="info-defined-wrapper"
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <strong>{t("small")}</strong>, {t("smallExample")}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <strong>{t("medium")}</strong>, {t("mediumExample")}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <strong>{t("large")}</strong>, {t("largeExample")}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <strong>{t("xlarge")}</strong>, {t("xLargeExample")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
              <div className="send-input-wrapper"></div>
            </Col>
            {isCustom && (
              <Col xs={12} className={`request-form`}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={3}
                    className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                  >
                    <span className="send-pack-title">
                      {t("definedAs")}
                      {checkLabel()}
                    </span>
                  </Col>
                  <Col xs={2} className="pl-0">
                    <Input
                      size="sm"
                      id="sizeLength"
                      name="sizeLength"
                      placeholder="Length"
                      className={`custom-input-size-length w-100 ${
                        submitClicked && Array.isArray(size)
                          ? size?.map((item) => {
                              return (
                                item.value ===
                                  "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                                !isValidDefinedLengthSize(
                                  offerData.sizeLength
                                ) &&
                                "empty-input-style"
                              );
                            })
                          : size?.value ===
                              "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                            !isValidDefinedLengthSize(offerData.sizeLength) &&
                            "empty-input-style"
                      }`}
                      value={offerData.sizeLength}
                      onChange={handleChange}
                      // disabled={!isCustom || isCompanion}
                      disabled={!isCustom}
                      onKeyPress={preventTypeText}
                    />
                  </Col>
                  <Col xs={2} className="pl-0">
                    <Input
                      size="sm"
                      id="sizeWidth"
                      name="sizeWidth"
                      placeholder="Width"
                      className={`custom-input-size-width w-100 ${
                        submitClicked && Array.isArray(size)
                          ? size?.map((item) => {
                              return (
                                item.value ===
                                  "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                                !isValidDefinedWidthSize(offerData.sizeWidth) &&
                                "empty-input-style"
                              );
                            })
                          : size?.value ===
                              "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                            !isValidDefinedWidthSize(offerData.sizeWidth) &&
                            "empty-input-style"
                      }`}
                      value={offerData.sizeWidth}
                      onChange={handleChange}
                      disabled={!isCustom}
                      onKeyPress={preventTypeText}
                    />
                  </Col>
                  <Col xs={2} className="pl-0">
                    <Input
                      size="sm"
                      id="sizeHeight"
                      name="sizeHeight"
                      placeholder="Height"
                      className={`custom-input-size-height w-100 ${
                        submitClicked && Array.isArray(size)
                          ? size?.map((item) => {
                              return (
                                item.value ===
                                  "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                                !isValidDefinedHeightSize(
                                  offerData.sizeHeight
                                ) &&
                                "empty-input-style"
                              );
                            })
                          : size?.value ===
                              "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                            !isValidDefinedHeightSize(offerData.sizeHeight) &&
                            "empty-input-style"
                      }`}
                      value={offerData.sizeHeight}
                      onChange={handleChange}
                      disabled={!isCustom}
                      onKeyPress={preventTypeText}
                    />
                  </Col>
                  <Col xs={2} className="pl-0">
                    <Select
                      className="custom-select-unit-length w-100"
                      value={lengthUnit}
                      onChange={handleLengthUnitChange}
                      options={unitLengthOption}
                      styles={unitCustomStyle}
                      isDisabled={!isCustom}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                    />
                  </Col>
                  <Col xs={1} className={`${isPersian ? "pr-0" : "pl-0"}`}>
                    <div className="triangle">
                      <FontAwesomeIcon
                        icon={faInfo}
                        className="info-defined-wrapper"
                        onClick={openInfoModal}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            )}

            {isInfoOpen && (
              <SizeInformation isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
            )}
            <Col xs={12} className={`request-form`}>
              <Row
                className={`align-items-center mt-3  ${
                  isPersian ? "pl-0" : ""
                }`}
              >
                <Col
                  xs={isPersian ? 4 : 3}
                  className={`${isPersian ? "text-right pr-3" : ""}`}
                >
                  <span className="send-pack-title">{t("maxWeightOf")}</span>
                </Col>
                <Col
                  xs={isPersian ? 8 : 9}
                  className={`${isPersian ? "" : "pl-0"}`}
                >
                  <Input
                    size="sm"
                    id="weight"
                    name="weight"
                    placeholder={t("weight")}
                    className="custom-input-package w-100"
                    value={offerData.weight}
                    onChange={handleChange}
                    onKeyPress={preventTypeText}
                    // maxLength="3"
                    disabled={isCompanion}
                    // type="number"
                  />
                  <Select
                    className="custom-select-unit-size"
                    value={weightUnit}
                    isDisabled={isCompanion}
                    onChange={handleWeightUnitChange}
                    options={unitWeightOption}
                    styles={unitCustomStyle}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </Col>
              </Row>
              {isCash && !isCad && payment && (
                <span className="warning-payable-title d-block mb-3 px-3 w-100">
                  {t("currencyWarning")}
                </span>
              )}
            </Col>
            <Col xs={12} className={`request-form`}>
              <Row className="align-items-center mt-3">
                <Col xs={3} className={`${isPersian ? "text-right pr-3" : ""}`}>
                  <span className="send-pack-title">
                    {t("payableBy")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col xs={9} className={`${isPersian ? "" : "pl-0"}`}>
                  <Select
                    className={`custom-select-carry-payment w-100 ${
                      submitClicked &&
                      !isValidPayment(payment) &&
                      "custom-empty-input-style"
                    }`}
                    value={payment}
                    onChange={handlePaymentChange}
                    options={paymentsOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      Option: InputOption,
                    }}
                    styles={customStyle}
                    // isMulti
                  />
                </Col>
                {
                  !isCancellation && payment ? (
                    <span className="px-3 warning-payable-title d-block mb-3 w-100">
                      {t("cashBeforeShippingWarning")}
                    </span>
                  ) : isCancellation && payment ? (
                    <span className="px-3 info-payable-title d-block mb-3 w-100">
                      {t("attentionTravelerPart1")}{" "}
                      {isMobileTablet ? (
                        <Link
                          // target="_blank"
                          reloadDocument
                          to="/settings/addBankAccount"
                          onClick={() => {
                            localStorage.setItem("tab", "financial");
                          }}
                        >
                          {" "}
                          {t("attentionTravelerPart2")}{" "}
                        </Link>
                      ) : (
                        <a
                          onClick={() => {
                            localStorage.setItem("tab", "userInfo");
                          }}
                          target="_blank"
                          href="/settings/addBankAccount"
                        >
                          {" "}
                          {t("attentionTravelerPart2")}{" "}
                        </a>
                      )}
                      {t("attentionTravelerPart3")}
                    </span>
                  ) : null // </span> //   Bank information - Payout/ Stripe connect Express // <span className="success-payable-title d-block mt-0 mb-3 w-100">
                }
              </Row>
            </Col>
            <Col xs={12} className={`request-form`}>
              <Row className="align-items-center mt-3">
                <Col
                  xs={4}
                  className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                >
                  <span className="send-pack-title">
                    {t("acceptablePackageType")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col xs={8} className={`${isPersian ? "" : "pl-0"} `}>
                  <Select
                    className={`custom-select-package-type ${
                      submitClicked &&
                      !isValidPackageType(type) &&
                      "custom-empty-input-style"
                    }`}
                    value={type}
                    placeholder={t("selectPackageTypePlaceholder")}
                    onChange={handleTypeChange}
                    options={typeOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      Option: InputOption,
                    }}
                    isMulti
                    styles={customStyle}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className={`request-form`}>
              <Row className="align-items-center mt-3">
                <Col
                  xs={4}
                  className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                >
                  <span className="send-pack-title">
                    {t("providedService")}
                  </span>
                </Col>
                <Col xs={8} className={`${isPersian ? "" : "pl-0"} `}>
                  <Select
                    className="custom-select-deliver-service"
                    value={deliveryType}
                    onChange={handleDeliveryTypeChange}
                    options={deliveryTypeOptions}
                    placeholder={t("providedServicePlaceholder")}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    styles={customStyle}
                    isMulti
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className={`request-form`}>
              <span
                className={`send-pack-title mt-4 d-block w-100 ${
                  isPersian ? "text-right" : ""
                }`}
              >
                {t("deliveryDateWillBe")}:
              </span>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={3}
                      className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                    >
                      <span className="send-pack-title">
                        {t("fromDate")}
                        <span className="star-require">*</span>
                      </span>
                    </Col>
                    <Col xs={9} className={isPersian ? "pr-0" : "pl-0"}>
                      <DatePicker
                        // className="custom-input-from-to-travel"
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        selected={arrivalToDate}
                        onChange={(date) => onArrivalToDateChange(date)}
                        className={`first-custom-input-from-to-travel ${
                          submitClicked &&
                          !isEndDateGreaterEqualThanStartDate(
                            departureBetweenDate,
                            arrivalToDate
                          ) &&
                          "empty-input-style"
                        }`}
                        minDate={addDays(departureBetweenDate, 0)}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={3}
                      className={` ${isMobile ? "" : "pr-0 text-center"}`}
                    >
                      <span className="send-pack-title">
                        {t("toDate")}
                        <span className="star-require">*</span>
                      </span>
                    </Col>
                    <Col xs={9} className={`${isPersian ? "" : "pl-0"} `}>
                      <DatePicker
                        // className={`custom-input-from-to-travel d-inline-block`}
                        selected={departureToDate}
                        onChange={(date) => onDepartureToDateChange(date)}
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        className={`second-custom-input-from-to-travel ${
                          submitClicked &&
                          !isEndDateGreaterEqualThanStartDate(
                            arrivalToDate,
                            departureToDate
                          ) &&
                          "empty-input-style"
                        }`}
                        minDate={addDays(arrivalToDate, 1)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className={`request-form`}>
              <Row>
                <Col lg={isPersian ? 7 : 6} md={isPersian ? 7 : 6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={4}
                      lg={5}
                      md={5}
                      sm={4}
                      className={isPersian ? "text-right pr-3" : ""}
                    >
                      <span className="send-pack-title">
                        {t("flightNameIs")}
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      lg={7}
                      md={7}
                      sm={8}
                      className={`${isPersian ? "pr-0" : "pl-0"} `}
                    >
                      <AsyncSelect
                        className="custom-input-airline"
                        value={airline}
                        onChange={handleAirlineChange}
                        cacheOptions
                        defaultOptions
                        placeholder={
                          <span className="select-placeholder-text">
                            {t("flightNameIs")}
                          </span>
                        }
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                          Option: InputOption,
                        }}
                        styles={customStyle}
                        loadOptions={searchAirline}
                        loadingMessage={() => "Loading ..."}
                        // noOptionsMessage={() => ""}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={isPersian ? 5 : 6} md={isPersian ? 5 : 6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={4}
                      lg={isPersian ? 4 : 5}
                      md={isPersian ? 4 : 5}
                      sm={4}
                      className={` ${isMobile ? "" : "text-right"}`}
                    >
                      <span className="send-pack-title">
                        {t("andFlightNumber")}
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      lg={isPersian ? 8 : 7}
                      md={isPersian ? 8 : 7}
                      sm={8}
                      className={`${isPersian ? "pr-0" : "pl-0"} `}
                    >
                      <Input
                        id="flightNumber"
                        name="flightNumber"
                        placeholder={t("andFlightNumber")}
                        className="custom-input-offer-flight-number"
                        value={offerData.flightNumber}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {isCancellation && (
              <Col xs={12} className={`request-form`}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={isPersian ? 3 : 4}
                    className={` ${isPersian ? "text-right pr-3" : ""}`}
                  >
                    <span className="send-pack-title">
                      {t("cancellationPolicy")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col
                    xs={isPersian ? 9 : 8}
                    className={`${isPersian ? "pr-0" : "pl-0"} `}
                  >
                    <Select
                      className={`custom-select-package-policy ${
                        submitClicked &&
                        !isValidPolicy(policy, isCancellation) &&
                        "custom-empty-input-style"
                      }`}
                      // className={`custom-select-package-policy d-inline-block`}
                      value={policy}
                      onChange={handlePolicyChange}
                      options={cancellationPolicyOptions}
                      placeholder={t("selectCancellationPlaceholder")}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                        Option: InputOption,
                      }}
                      styles={customStyle}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col lg={6} md={6} sm={12} className={`request-form mt-3`}>
              <div
                style={
                  isMobile
                    ? isPersian
                      ? { width: "345px", paddingLeft: "20px" }
                      : { width: "345px", paddingRight: "20px" }
                    : isPersian
                    ? { width: "100%", textAlign: "right" }
                    : { width: "580px" }
                }
              >
                <MultipleUploader
                  title={t("uploadTicketOptional")}
                  setPhotos={setImages}
                  image={images}
                  disabled={false}
                  width="w-273"
                  description={t("dropImageHere")}
                  hasRemove={true}
                />
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} className="request-form h-100 mt-3">
              <div
                className={`send-input-wrapper ${
                  isPersian ? "text-right" : ""
                }`}
                style={{ marginTop: "18px" }}
              >
                <span className="message-title">{t("noteDetail")}</span>
                <div>
                  <Input
                    size="sm"
                    id="message"
                    name="message"
                    placeholder={t("acceptAllVisibleDocs")}
                    className="custom-input-message-package"
                    value={offerData.message}
                    rows={4}
                    textArea={true}
                    onChange={handleChange}
                    maxLength="100"
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} className={`request-form`}>
              <div style={{ marginTop: "30px" }}>
                <label className="checkbox-container">
                  {t("termConditionLinkText")}{" "}
                  <a
                    target="_blank"
                    href="/termsCondition"
                    style={{ textDecorationLine: "underline" }}
                  >
                    {t("termConditionLink")}{" "}
                  </a>
                  {t("termConditionLink2")}.
                  <input
                    type="checkbox"
                    checked={termsChecked}
                    onChange={handleTermsCheckedChange}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </Col>
            {/* <Col xs={12} className={`request-form ${isPersian ? "p-0" : ""}`}> */}
            <div
              className="d-flex w-100 px-3 dir-ltr"
              style={{ marginTop: "24px" }}
            >
              <Button
                onClick={handleChangeClose}
                className="close-request-btn ml-auto mt-4 mr-2"
              >
                {t("close")}
              </Button>
              <Button
                variant="primary"
                onClick={addNewTravel}
                className="request-submit-request-btn mt-4"
                disabled={!termsChecked}
              >
                {t("submitRequest")}
                {isLoading && (
                  <Oval
                    width="20"
                    height="20"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                  />
                )}
              </Button>
            </div>
            {/* </Col> */}
          </Row>
        )}
      </div>
    </>
  );
};
