import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useAppDispatch, useAppSelector } from "redux/store";
import { useTranslation } from "react-i18next";
import {
  reportOfferProblem,
  reportRequestProblem,
  getAllDashboardData,
} from "redux/actions/dashboard";
import { Oval } from "react-loader-spinner";
import {
  getAllAnnouncement,
  getAllAnnouncementCount,
} from "redux/actions/notifications";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface IProp {
  id: string;
  titleTab: string;
  isOpen: boolean;
  sidebarType: string;
  setIsOpen: Function;
}

export const ReportProblem: React.FC<IProp> = ({
  id,
  sidebarType,
  setIsOpen,
  isOpen,
  titleTab,
}) => {
  const size = UseWindowSize();
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessContract, setIsSuccessContract] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const offerProblemResult: any = useAppSelector((state) => state.offerProblem);
  const requestProblemResult: any = useAppSelector(
    (state) => state.requestProblem
  );
  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const reportProblem = () => {
    setIsLoading(true);
    setIsSuccessContract(true);
    let data;
    if (sidebarType === "offerProblem") {
      data = { offId: id, description: message };
      dispatch(reportOfferProblem(data));
    } else if (sidebarType === "requestProblem") {
      data = { reqId: id, description: message };
      dispatch(reportRequestProblem(data));
    }
  };

  useEffect(() => {
    if (!offerProblemResult?.isSuccess && isSuccessContract) {
      setIsLoading(false);
    } else if (offerProblemResult?.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      navigate("/dashboard", { state: t(`${titleTab}`) });
      dispatch(getAllDashboardData());
      document.documentElement.style.overflow = "auto";
      navigate("/dashboard", { state: t(`${titleTab}`) });
      setIsSuccessContract(false);
      const notifyData = {
        page: 1,
        pageSize: 5,
      };
      dispatch(getAllAnnouncement(notifyData));
      dispatch(getAllAnnouncementCount());
    }
  }, [offerProblemResult]);

  useEffect(() => {
    if (!requestProblemResult?.isSuccess) {
      setIsLoading(false);
    } else if (requestProblemResult?.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      navigate("/dashboard", { state: t(`${titleTab}`) });
      window.location.reload();
    }
  }, [requestProblemResult]);

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/dashboard", { state: t(`${titleTab}`) });
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          navigate("/dashboard", { state: t(`${titleTab}`) });
        }
      },
      false
    );
  }, []);

  return (
    <div className="request-slider-container">
      <Row className="request-wrapper">
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className="offer-form">
          <h1>{t("reportProblem")}</h1>
          <div className="pickup-input-wrapper">
            <span>{t("message")}</span>
            <div>
              <Input
                size="sm"
                id="message"
                placeholder={t("describeProblem")}
                className="custom-input-message"
                value={message}
                onChange={handleMessageChange}
                textArea={true}
                rows={4}
              />
            </div>
          </div>
        </Col>
        <div style={{ marginTop: "24px" }}>
          <Button
            variant="primary"
            data-test="docs-btn-anchor"
            className="submit-request-btn mt-4"
            onClick={reportProblem}
          >
            {t("submitTheRequest")}
            {isLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </div>
      </Row>
    </div>
  );
};
