import { createSlice } from "@reduxjs/toolkit";

export const addressVerificationSlice = createSlice({
  name: "addressVerification",
  initialState: [],
  reducers: {
    addressVerification: (state, action) => {
      return (state = action.payload);
    },
    addressVerificationFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { addressVerification, addressVerificationFailure } =
  addressVerificationSlice.actions;
export default addressVerificationSlice.reducer;
