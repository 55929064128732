import { createSlice } from "@reduxjs/toolkit";

export const filterHomeTravelSearchSlice = createSlice({
  name: "filterHomeTravelSearch",
  initialState: [],
  reducers: {
    filterHomeTravelSearch: (state, action) => {
      return (state = action.payload);
    },
    filterHomeTravelSearchFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { filterHomeTravelSearch, filterHomeTravelSearchFailure } =
  filterHomeTravelSearchSlice.actions;

export default filterHomeTravelSearchSlice.reducer;
