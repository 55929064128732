import { createSlice } from "@reduxjs/toolkit";

export const currencySlice = createSlice({
  name: "currencies",
  initialState: [],
  reducers: {
    currency: (state, action) => {
      return (state = action.payload.data);
    },
    currencyFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { currency, currencyFailure } =
  currencySlice.actions;
export default currencySlice.reducer;
