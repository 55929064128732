import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { MultipleUploader } from "components";
import { Col, Row, Dropdown } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import Select from "react-select";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { IPackage, IRetrieve } from "models/interfaces";
import { useAppSelector, useAppDispatch } from "redux/store";
import { getDate, convertHumanDateToUnix } from "helpers/convertDate";
import { InputOption, ReusePackageHistory } from "components";
import { addNewLandingPackage } from "redux/actions/landing";
import { getAllHomeRequestFilter } from "redux/actions/flight";
import { AddInputForms } from "components/common/addInputForm";
import { Oval } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { searchAirline } from "helpers/searchAirline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faInfo,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  getAllSizeRange,
  getAllDeliveryType,
  getAllPackagesType,
} from "redux/actions/types";
import {
  searchLocation,
  searchLocationApi,
  searchAirportLocation,
} from "helpers/searchLocation";
import {
  isValidPackageType,
  isValidSize,
  isValidDefinedLengthSize,
  isValidDefinedWidthSize,
  isValidDefinedHeightSize,
  isValidOfferPrice,
  isValidFrom,
  isValidTo,
  isEndDateGreaterEqualStartDate,
  isEndDateGreaterStartDate,
  isValid,
} from "helpers/packageValidation";
import { SizeInformation } from "components/modals/sizeInformation";

interface IProps {
  setIsOpen: Function;
  isOpen: boolean;
  onSelectReuse: (key: any) => void;
}

const getOneAddDate = (date) => {
  const originalDate = new Date(date);
  const updatedDate = new Date(originalDate);
  var d = new Date(updatedDate.setDate(originalDate.getDate() + 1));
  return d;
};

export const AddPackage: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  onSelectReuse,
}) => {
  const windowSize = UseWindowSize();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [acceptPurchase, setAcceptPurchase] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [disable, setDisable] = useState(false);
  const navigate = useNavigate();
  const [retrieveData, setRetrieveData] = useState<IRetrieve>({
    type: "package",
    fromCityEntityId: "",
    toCityEntityId: "",
    fromDate1: "",
    fromDate2: "",
  });
  const [requestData, setRequestData] = useState<IPackage>({
    packagetypeIds: [],
    packageType: "",
    sizerangeId: "",
    weight: "",
    weightUnit: "kg",
    value: "",
    sizeWidth: "",
    sizeHeight: "",
    sizeLength: "",
    lengthUnit: "cm",
    deliverytypeIds: [],
    fromCountry: "",
    fromCountryCity: "",
    toCountry: "",
    toCountryCity: "",
    fromDate1: "",
    fromDate2: "",
    offerPrice: "",
    currencyType: "CAD",
    message: "",
    airlineName: "",
    flightNumber: "",
    images: [],
    Purchases: [],
  });
  const [size, setSize] = useState(null);
  const [reuseData, setReuseData] = useState<any>({});
  const [showReuse, setShowReuse] = useState(false);
  const [isValidFromAirport, setIsValidFromAirport] = useState(true);
  const [isValidToAirport, setIsValidToAirport] = useState(true);
  const [packagetypeIds, setPackagetypeIds] = useState<any>([]);
  const [deliverytypeIds, setDeliverytypeIds] = useState<any>([]);
  const [airline, setAirline] = useState<any>();
  const [trvId, setTrvId] = useState("");
  const [sizeOptions, setSizeOptions] = useState([]);
  const createRequest: any = useAppSelector((state) => state.createRequest);
  const sizeRanges = useAppSelector((state) => state.sizeRange);
  const [termsChecked, setTermsChecked] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [governmentChecked, setGovernmentChecked] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isCompanion, setIsCompanion] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [type, setType] = useState(null);
  const packagesType = useAppSelector((state) => state.packageTypes);
  const [weightUnit, setWeightUnit] = useState({ value: "kg", label: "KG" });
  const [lengthUnit, setLengthUnit] = useState({ value: "cm", label: "CM" });
  const [service, setService] = useState(null);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const isMobile = windowSize.width < 768;
  const [purchases, setPurchases] = useState<any>([
    { Link: "", Quantity: "0" },
  ]);
  var today = new Date();
  var tomorrow = new Date();
  const services = useAppSelector((state) => state.deliveryType);
  const screenSize = UseWindowSize();
  const [arrivalBetweenDate, setArrivalBetweenDate] = useState(new Date());
  const [arrivalToDate, setArrivalToDate] = useState(new Date());
  const [departureBetweenDate, setDepartureBetweenDate] = useState<any>(
    tomorrow.setDate(today.getDate() + 1)
  );
  const [departureToDate, setDepartureToDate] = useState<any>(
    getOneAddDate(today)
  );
  // const [departureToDate, setDepartureToDate] = useState<any>(
  //   tomorrow.setDate(today.getDate() + 1)
  // );
  const [fromDate1, setFromDate1] = useState(getDate(new Date()));
  const [fromDate2, setFromDate2] = useState(getDate(getOneAddDate(today)));
  const [toDate1, setToDate1] = useState(getDate(new Date()));
  const [toDate2, setToDate2] = useState(
    getDate(new Date(getOneAddDate(today)))
  );
  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();
  const currencies = useAppSelector((state) => state.currencies);
  const token = window.localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(
    token === "undefined" || token === "null" || token === null ? false : true
  );

  window.addEventListener("storage", () => {
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogin(false);
    else setIsLogin(true);
  });

  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [currenciesOption, setCurrenciesOption] = useState([
    {
      value: localStorage.getItem("currency"),
      label: localStorage.getItem("currency"),
    },
  ]);

  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];

  const unitLengthOption = [
    { value: "cm", label: "CM" },
    { value: "inch", label: "INCH" },
  ];

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const handleTermsCheckedChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleLengthUnitChange = (selected) => {
    setLengthUnit(selected);
  };

  const openInfoModal = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  const handleGovernmentChange = () => {
    setGovernmentChecked(!governmentChecked);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    // setRequestData({ ...requestData, [e.target.name]: e.target.value });

    const newValue = e.target.value;
    if (newValue.length <= 100) {
      setRequestData({ ...requestData, [e.target.name]: newValue });
    } else {
      setRequestData({
        ...requestData,
        [e.target.name]: newValue.slice(0, 100),
      });
    }
  };

  useEffect(() => {
    dispatch(getAllSizeRange());
    dispatch(getAllDeliveryType());
    dispatch(getAllPackagesType());
  }, []);

  useEffect(() => {
    const options = packagesType?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setType(null);
    setPackagetypeIds([packagesType[0]?.id]);
    setTypeOptions(options);
  }, [packagesType]);

  const handleTypeChange = (selected) => {
    if (
      selected?.find((item) => {
        return item.value === "60db617a-dba2-406c-a960-1928a8b45320";
      })
    ) {
      setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);
      setIsCompanion(true);
      setIsCustom(false);
      if (isPersian)
        setSize({
          value: "6a50f6c5-0c00-498e-9653-1d8914172181",
          label: "سفارشی",
        });
      else
        setSize({
          value: "6a50f6c5-0c00-498e-9653-1d8914172181",
          label: "Custom",
        });
      setRequestData({
        ...requestData,
        sizeWidth: "0",
        sizeHeight: "0",
        sizeLength: "0",
      });
      setType([
        {
          value: "60db617a-dba2-406c-a960-1928a8b45320",
          label: isPersian ? "همسفر" : "Traveling Companion",
        },
      ]);
    } else {
      setIsCustom(false);
      setIsCompanion(false);
      setType(selected);
      var result1 = selected.map(function (item) {
        return item.value;
      });
      const result2 = result1.filter(function (item) {
        return item !== "60db617a-dba2-406c-a960-1928a8b45320";
      });
      setPackagetypeIds(result2);
      if (isPersian)
        setSize({
          value: "aed0d42c-c59f-4267-9d84-ebb772705553",
          label: "کوچک",
        });
      else
        setSize({
          value: "aed0d42c-c59f-4267-9d84-ebb772705553",
          label: "Small",
        });
    }
  };

  useEffect(() => {
    const options = sizeRanges?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSize(
      options?.find(
        (item) => item.value === "aed0d42c-c59f-4267-9d84-ebb772705553"
      )
    );
    setSizeOptions(options);
  }, [sizeRanges]);

  useEffect(() => {
    const options = services?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setService(null);
    // setDeliverytypeIds([services[0]?.id]);
    setServicesOptions(options);
  }, [services]);

  const handleServicesChange = (selected) => {
    setService(selected);
    setDeliverytypeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
  };

  const getOneAddDateString = (date) => {
    const originalDate = new Date(date);
    const updatedDate = new Date(originalDate);
    updatedDate.setDate(originalDate.getDate() + 1);
    const year = updatedDate.getFullYear();
    const month = String(updatedDate.getMonth() + 1).padStart(2, "0");
    const day = String(updatedDate.getDate()).padStart(2, "0");
    const updatedDateString = `${year}-${month}-${day}`;
    return updatedDateString;
  };

  const onArrivalBetweenDateChange = (date) => {
    setArrivalBetweenDate(date);
    setFromDate1(getDate(date));
    setDepartureBetweenDate(getOneAddDate(date));
    setFromDate2(getOneAddDateString(getDate(date)));
    setDepartureToDate(getOneAddDate(date));
    setToDate2(getOneAddDateString(getDate(date)));
  };

  // const onArrivalToDateChange = (date) => {
  //   setArrivalToDate(date);
  //   setToDate1(getDate(date));
  // };

  const onDepartureFromDateChange = (date) => {
    setDepartureBetweenDate(date);
    setFromDate2(getDate(date));
    setDepartureToDate(date);
    setToDate2(getDate(date));
  };

  const onDepartureToDateChange = (date) => {
    setDepartureToDate(date);
    setToDate2(getDate(date));
  };

  const handleUnitChange = (selected) => {
    setWeightUnit(selected);
  };

  const handleCurrencyChange = (selected) => {
    setCurrency(selected);
  };

  const handleSizeChange = (selected) => {
    setSize(selected);
    if (selected.value === "6a50f6c5-0c00-498e-9653-1d8914172181")
      setIsCustom(true);
    else setIsCustom(false);
  };

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#707070",
      whiteSpace: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "auto",
    }),
  };

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 50,
      minWidth: 165,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#fff",
      whiteSpace: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "auto",
    }),
  };

  const handleFromChange = (selected) => {
    setFrom(selected);
  };

  const handleToChange = (selected) => {
    setTo(selected);
  };

  const addNewPackage = () => {
    setSubmitClicked(true);
    const data = {
      offerPrice: requestData.offerPrice,
      type,
      size,
      length: requestData.sizeLength,
      width: requestData.sizeWidth,
      height: requestData.sizeHeight,
      from,
      to,
      arrivalBetweenDate,
      departureBetweenDate,
      departureToDate,
      isCompanion,
    };
    if (!isValid(termsChecked, governmentChecked, data)) {
      toast.error(
        lang === "fa"
          ? "پارامترها را به درستی وارد نمایید"
          : "Enter the parameters correctly!"
      );
      return;
    }
    addNewPackageApiCall();
    document.documentElement.style.overflow = "auto";
  };

  const addNewPackageApiCall = () => {
    document.documentElement.style.overflow = "hidden";
    const body = new FormData();
    body.append("packagetypeIds", packagetypeIds);
    body.append("packageType", "");
    body.append("sizerangeId", size.value);
    body.append("weight", requestData.weight);
    body.append("weightUnit", weightUnit.value);
    body.append("value", requestData.value);
    body.append("sizeWidth", requestData.sizeWidth);
    body.append("sizeHeight", requestData.sizeHeight);
    body.append("sizeLength", requestData.sizeLength);
    body.append("lengthUnit", lengthUnit.value);
    body.append("deliverytypeIds", deliverytypeIds ? deliverytypeIds : "");
    body.append("fromCityEntityId", from.value ? from.value : "");
    body.append("toCityEntityId", to.value ? to.value : "");
    body.append("fromDate1", fromDate1);
    body.append("fromDate2", fromDate2);
    body.append("toDate2", toDate2);
    body.append("offerPrice", requestData.offerPrice);
    isCompanion &&
      body.append("airlineName", airline?.label ? airline?.label : "");
    isCompanion && body.append("flightNumber", requestData.flightNumber);
    body.append("currencyType", currency.value);
    body.append("message", requestData.message);
    for (const image of images) {
      body.append("images", image);
    }
    const itemsToBuy = purchases.filter((purchase) => {
      return !(purchase.Link === "" && purchase.Quantity === "0");
    });
    let i;
    for (i = 0; i < itemsToBuy?.length; i++) {
      body.append(`Purchases[${i}][Quantity]`, itemsToBuy[i].Quantity);
      body.append(`Purchases[${i}][Link]`, itemsToBuy[i].Link);
    }
    dispatch(addNewLandingPackage(body));
    setIsLoading(true);
  };

  useEffect(() => {
    setShowReuse(false);
    onSelectReuse(false);
  }, [reuseData]);

  useEffect(() => {
    if (createRequest?.isSuccess === false) {
      document.documentElement.style.overflow = "hidden";
      setIsLoading(false);
    } else if (createRequest?.isSuccess === true) {
      document.documentElement.style.overflow = "auto";
      setIsLoading(false);
      setIsOpen(false);
      const data = {
        type: "package",
        packagetypeIds: null,
        weightrangeIds: null,
        deliverytypeIds: null,
        sizerangeIds: null,
        serviceIds: null,
        paymentmethodIds: null,
        cancellationpolicyIds: null,
        userLevelIds: null,
        page: 1,
      };
      dispatch(getAllHomeRequestFilter(data));
      document.documentElement.style.overflow = "auto";
      navigate("/dashboard/myPackages", { state: t("myPackages") });
    }
  }, [createRequest]);

  const preventTypeTextAndDot = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventTypeText = (e) => {
    const validInputRegex = /^[0-9.]$/; // Allow digits and dot
    const currentValue = e.target.value + e.key;
    if (validInputRegex.test(e.key) || e.key === "Backspace") {
      const regex = /^(?!1000(\.0)?$)\d{1,3}(\.\d{0,1})?$/;
      if (regex.test(currentValue) || currentValue === "") {
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const handleChangeClose = () => {
    if (showReuse === true) {
      setShowReuse(false);
      onSelectReuse(false);
    } else {
      setIsOpen(!isOpen);
      document.documentElement.style.overflow = "auto";
      navigate("/");
      // window.location.reload();
    }
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  const handleAirlineChange = (selected) => {
    setAirline(selected);
  };

  useEffect(() => {
    setRetrieveData({
      type: "package",
      fromCityEntityId: from?.value,
      toCityEntityId: to?.value,
      fromDate1: fromDate1,
      fromDate2: fromDate2,
    });
  }, [from, to, fromDate1, fromDate2]);

  useEffect(() => {
    const data = reuseData;
    if (Object.keys(reuseData).length !== 0) {
      if (data.packagetypes) {
        let types = data?.packagetypes?.split(", ");
        if (data?.packagetypes?.includes(",")) {
          const packages = types
            .map((item) => {
              return typeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setType(packages);
        } else
          setType(
            typeOptions.find((option) => option.label === data.packagetypes)
          );
      } else {
        setType({ value: packagesType[0]?.id, label: packagesType[0]?.name });
      }

      if (data.deliveryTypes) {
        let types = data?.deliveryTypes?.split(", ");
        if (data?.deliveryTypes?.includes(",")) {
          const deliveries = types
            .map((item) => {
              return servicesOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setService(deliveries);
        } else
          setService(
            servicesOptions.find(
              (option) => option.label === data.deliveryTypes
            )
          );
      } else {
        setService({
          value: services[0]?.id,
          label: services[0]?.name,
        });
      }
    }
  }, [reuseData, typeOptions, servicesOptions]);

  useEffect(() => {
    if (Object.keys(reuseData).length !== 0) {
      const data = reuseData;
      searchLocationApi(data.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}
                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}
                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });
      setAcceptPurchase(data.acceptPurchase);
      const currency = data.currencyType ? data.currencyType : "CAD";
      const unit = data.weightUnit ? data.weightUnit : "KG";
      const lUnit = data.lengthUnit ? data.lengthUnit : "CM";
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setLengthUnit({ value: lUnit, label: lUnit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      // setSize(sizeOptions.find((option) => option.label === data.sizerange));
      if (data.sizerange) {
        let sizes = data?.sizerange?.split(", ");
        if (
          data?.sizerange?.includes("Custom") ||
          data?.sizerange?.includes("سفارشی")
        )
          setIsCustom(true);
        else setIsCustom(false);
        if (data?.sizerange?.includes(",")) {
          const ranges = sizes
            .map((item) => {
              return sizeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setSize(ranges[0]);
        } else {
          if (
            data?.packagetypes === "همسفر" ||
            data?.packagetypes === "Traveling Companion"
          ) {
            if (isPersian)
              setSize({
                value: "6a50f6c5-0c00-498e-9653-1d8914172181",
                label: "سفارشی",
              });
            else
              setSize({
                value: "6a50f6c5-0c00-498e-9653-1d8914172181",
                label: "Custom",
              });
          } else
            setSize(
              sizeOptions.find((option) => option.label === data.sizerange)
            );
        }
      }
      setArrivalBetweenDate(
        data?.fromDate1 ? convertHumanDateToUnix(data?.fromDate1) : new Date()
      );
      // setArrivalToDate(convertHumanDateToUnix(data?.toDate1));
      setDepartureBetweenDate(
        data?.fromDate2
          ? convertHumanDateToUnix(data?.fromDate2)
          : tomorrow.setDate(today.getDate() + 1)
      );
      setDepartureToDate(
        data?.toDate2
          ? convertHumanDateToUnix(data?.toDate2)
          : tomorrow.setDate(today.getDate() + 1)
      );
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      setAirline({ value: data?.airlineName, label: data?.airlineName });
      const newPurchases = data?.purchases?.map(({ link, quantity }) => ({
        Link: link,
        Quantity: quantity,
      }));
      setPurchases(
        newPurchases?.length !== 0
          ? newPurchases
          : [{ Link: "", Quantity: "0" }]
      );
      setRequestData({
        ...requestData,
        weight: data.weight,
        message: data.description,
        value: data.itemValue,
        offerPrice: data.offerPrice,
        sizeWidth: data.sizeWidth,
        sizeHeight: data.sizeHeight,
        sizeLength: data.sizeLength,
        flightNumber: data?.flightNumber,
        // link: data.link,
      });
      setImages(data.images);
    }
  }, [reuseData]);

  const openReuseSidebar = () => {
    if (from?.value !== undefined && to?.value !== undefined) {
      setShowReuse(true);
      onSelectReuse(true);
    } else if (from?.value === undefined) {
      toast.warn(
        lang === "fa"
          ? "لطفا فرودگاه مبدا را وارد کنید"
          : "Please enter departure airport"
      );
      if (!isValidFrom(from) || from === undefined)
        setIsValidFromAirport(false);
    } else if (to?.value === undefined) {
      toast.warn(
        lang === "fa"
          ? "لطفا فرودگاه مقصد را وارد کنید"
          : "Please enter arrival airport"
      );
      if (!isValidTo(to) || to === undefined) setIsValidToAirport(false);
    }
  };

  useEffect(() => {
    if (isValidFrom(from)) setIsValidFromAirport(true);
    if (isValidTo(to)) setIsValidToAirport(true);
  }, [from, to]);

  return (
    <div
      className={`${
        showReuse ? "set-alert-slider-container" : "request-slider-container"
      }`}
    >
      <Row className="request-wrapper pb-3">
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col
          xs={12}
          className="request-form"
          style={{ paddingRight: isMobile ? "15px" : "1.79rem" }}
        >
          <div className={`${isMobile ? "" : "d-flex"}`}>
            <h1 className="d-inline-block">
              {showReuse ? t("packageHistory") : t("addRequest")}
            </h1>
            {!showReuse && (
              <Button
                variant="primary"
                className="d-inline-block reuse-previous-travel-btn"
                onClick={openReuseSidebar}
              >
                <FontAwesomeIcon icon={faClockRotateLeft} />
                {t("retrievePreviousPackage")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      {showReuse ? (
        <ReusePackageHistory
          retrieveData={retrieveData}
          setReuseData={setReuseData}
        />
      ) : (
        <Row className={`request-wrapper pt-0 ${isPersian ? "dir-rtl" : ""}`}>
          <Col xs={12} className="request-form">
            <Row className="align-items-center">
              <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("from")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={10}>
                <AsyncSelect
                  className={`custom-package-place-from ${
                    ((submitClicked && !isValidFrom(from)) ||
                      !isValidFromAirport) &&
                    "custom-empty-input-style"
                  }`}
                  value={from}
                  onChange={handleFromChange}
                  cacheOptions
                  defaultOptions
                  placeholder={
                    <span className="select-placeholder-text">
                      {t("countryCityAirport")}
                    </span>
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  styles={customStyle}
                  loadOptions={searchAirportLocation}
                  loadingMessage={() => "Loading ..."}
                />
              </Col>
            </Row>
            <Row className="align-items-center mt-3">
              <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("to")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={10}>
                <AsyncSelect
                  className={`custom-input-at-destination ${
                    ((submitClicked && !isValidTo(to)) || !isValidToAirport) &&
                    "custom-empty-input-style"
                  }`}
                  value={to}
                  onChange={handleToChange}
                  cacheOptions
                  defaultOptions
                  placeholder={
                    <span className="select-placeholder-text">
                      {t("countryCityAirport")}
                    </span>
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  styles={customStyle}
                  loadOptions={searchAirportLocation}
                  loadingMessage={() => "Loading ..."}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="request-form">
            <span
              className={`send-pack-title mt-4 d-block w-100 ${
                isPersian ? "text-right" : ""
              }`}
            >
              {t("shippingDateRange")}:
            </span>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={3}
                    className={`${isPersian ? "text-right pr-3" : ""}`}
                  >
                    <span className="send-pack-title">
                      {t("fromDate")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col xs={9} className={isPersian ? "pr-0" : "pl-0"}>
                    <DatePicker
                      className={`custom-input-between d-inline-block`}
                      selected={arrivalBetweenDate}
                      onChange={(date) => onArrivalBetweenDateChange(date)}
                      dateFormat="EEEE, MM/dd/yyyy"
                      showTimeInput
                      minDate={new Date()}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={3}
                    className={`${!isMobile && "text-center"} ${
                      isPersian ? "text-right pr-3" : "pr-0"
                    }`}
                  >
                    <span className="send-pack-title">
                      {t("toDate")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col
                    xs={9}
                    className={`${isPersian ? " text-right pr-1" : "pl-0"}`}
                  >
                    <DatePicker
                      className={`custom-input-to-request ${
                        submitClicked &&
                        !isEndDateGreaterEqualStartDate(
                          arrivalBetweenDate,
                          departureBetweenDate
                        ) &&
                        "empty-input-style"
                      }`}
                      selected={departureBetweenDate}
                      onChange={(date) => onDepartureFromDateChange(date)}
                      dateFormat="EEEE, MM/dd/yyyy"
                      showTimeInput
                      minDate={addDays(arrivalBetweenDate, 1)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col
                xs={isPersian ? 2 : 3}
                className={`${isPersian ? "text-right pr-3" : ""}`}
              >
                <span className="send-pack-title">
                  {t("PackagesType")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={isPersian ? 10 : 9}>
                <Select
                  className={`custom-select-send ${
                    submitClicked &&
                    !isValidPackageType(type) &&
                    "custom-empty-input-style"
                  }`}
                  value={type}
                  placeholder={t("selectFromOptions")}
                  onChange={handleTypeChange}
                  options={typeOptions}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    Option: InputOption,
                  }}
                  styles={customStyle}
                  isMulti
                  // isDisabled={isCompanion}
                />
              </Col>
            </Row>
            {isCompanion && (
              <span className="warning-payable-title d-block mt-2 mb-3 w-100">
                {t("companionWarning")}
              </span>
            )}
            {!isCompanion && (
              <Row className="align-items-center mt-3">
                <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                  <span className="send-pack-title">
                    {t("weight")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col xs={10}>
                  <Input
                    size="sm"
                    id="weight"
                    name="weight"
                    placeholder={t("weightKgLbs")}
                    className="custom-input-weight"
                    value={requestData.weight}
                    onChange={handleChange}
                    disabled={isCompanion}
                    onKeyPress={preventTypeText}
                  />
                  <Select
                    className="custom-select-unit-size d-inline-block"
                    value={weightUnit}
                    onChange={handleUnitChange}
                    options={unitWeightOption}
                    styles={unitCustomStyle}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    isDisabled={isCompanion}
                  />
                </Col>
              </Row>
            )}

            {!isCompanion && (
              <Row className="align-items-center mt-3">
                <Col
                  xs={2}
                  className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                >
                  <span className="send-pack-title">
                    {t("andSize")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col xs={9}>
                  <Select
                    className={`custom-select-size-package ${
                      submitClicked &&
                      !isValidSize(size, isCompanion) &&
                      "custom-empty-input-style"
                    }`}
                    value={size}
                    onChange={handleSizeChange}
                    options={sizeOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: InputOption,
                    }}
                    styles={customStyle}
                    isDisabled={isCompanion}
                    placeholder={t("selectMaxSizePlaceholder")}
                  />
                </Col>
                <Col xs={1} className={`${isPersian ? "pr-0" : ""}`}>
                  <Dropdown
                    className="size-info-dropdown d-inline"
                    onToggle={handleSelect}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="px-0 mx-0"
                    >
                      <div className="triangle">
                        <FontAwesomeIcon
                          icon={faInfo}
                          className="info-defined-wrapper"
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item>
                        <strong>{t("small")}</strong>, {t("smallExample")}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <strong>{t("medium")}</strong>, {t("mediumExample")}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <strong>{t("large")}</strong>, {t("largeExample")}
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <strong>{t("xlarge")}</strong>, {t("xLargeExample")}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </Row>
            )}
          </Col>

          {isCustom && (
            <Col xs={12} className="request-form">
              <Row className="align-items-center mt-3">
                <Col
                  xs={2}
                  className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                >
                  <span className="send-pack-title">
                    {t("definedAs")}
                    {size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" && (
                      <span className="star-require">*</span>
                    )}
                  </span>
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeLength"
                    name="sizeLength"
                    placeholder="Length"
                    value={requestData.sizeLength}
                    onChange={handleChange}
                    disabled={!isCustom}
                    className={`custom-input-size-length w-100 ${
                      submitClicked &&
                      size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                      !isValidDefinedLengthSize(requestData.sizeLength) &&
                      "empty-input-style"
                    }`}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeWidth"
                    name="sizeWidth"
                    placeholder="Width"
                    value={requestData.sizeWidth}
                    onChange={handleChange}
                    disabled={!isCustom}
                    className={`custom-input-size-width w-100 ${
                      submitClicked &&
                      size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                      !isValidDefinedWidthSize(requestData.sizeWidth) &&
                      "empty-input-style"
                    }`}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeHeight"
                    name="sizeHeight"
                    placeholder="Height"
                    value={requestData.sizeHeight}
                    onChange={handleChange}
                    disabled={!isCustom}
                    className={`custom-input-size-height w-100 ${
                      submitClicked &&
                      size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                      !isValidDefinedHeightSize(requestData.sizeHeight) &&
                      "empty-input-style"
                    }`}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Select
                    className="custom-select-unit-length w-100"
                    value={lengthUnit}
                    onChange={handleLengthUnitChange}
                    options={unitLengthOption}
                    styles={unitCustomStyle}
                    isDisabled={!isCustom}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </Col>
                <Col
                  xs={2}
                  className={`${isPersian ? "text-right" : "text-left"}`}
                >
                  {isCustom && (
                    <div className="triangle">
                      <FontAwesomeIcon
                        icon={faInfo}
                        className="info-defined-wrapper"
                        onClick={openInfoModal}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          )}

          {isInfoOpen && (
            <SizeInformation isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
          )}

          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col
                xs={isPersian ? 3 : 4}
                className={`${isPersian ? "text-right pr-3" : ""}`}
              >
                <span className="send-pack-title">
                  {t("forPrice")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={isPersian ? 9 : 8}>
                <Input
                  size="sm"
                  id="offerPrice"
                  name="offerPrice"
                  placeholder={t("amount")}
                  className={`custom-input-and-offer ${
                    submitClicked &&
                    !isValidOfferPrice(requestData.offerPrice) &&
                    "empty-input-style"
                  }`}
                  value={requestData.offerPrice}
                  onChange={handleChange}
                  onKeyPress={preventTypeTextAndDot}
                />
                <Select
                  className="custom-select-unit-price d-inline-block"
                  value={currency}
                  onChange={handleCurrencyChange}
                  options={currenciesOption}
                  styles={unitCustomStyle}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                />
              </Col>
            </Row>
            <span className="info-payable-title d-block mt-3 mb-3 w-100">
              {t("onlinePaymentForCanada")}
            </span>
            <Row className="align-items-center mt-3">
              <Col xs={4} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("deliveryDeadlineIs")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={8}>
                <DatePicker
                  className={`custom-input-between-deadline `}
                  selected={departureToDate}
                  onChange={(date) => onDepartureToDateChange(date)}
                  dateFormat="EEEE, MM/dd/yyyy"
                  showTimeInput
                  minDate={addDays(departureBetweenDate, 0)}
                />
              </Col>
            </Row>
          </Col>
          {!isCompanion && (
            <Col xs={12} className="request-form">
              <Row className="align-items-center mt-3">
                <Col xs={3} className={`${isPersian ? "text-right pr-3" : ""}`}>
                  <span className="send-pack-title">
                    {t("requestedServices")}
                  </span>
                </Col>
                <Col xs={9}>
                  <Select
                    className="custom-select-package-service"
                    value={service}
                    onChange={handleServicesChange}
                    options={servicesOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: InputOption,
                    }}
                    placeholder={t("selectFromOptions")}
                    styles={customStyle}
                    isMulti
                  />
                </Col>
              </Row>
            </Col>
          )}
          {isCompanion && (
            <Col xs={12} className={`request-form`}>
              <Row>
                <Col lg={isPersian ? 7 : 6} md={isPersian ? 7 : 6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={4}
                      lg={5}
                      md={5}
                      sm={4}
                      className={isPersian ? "text-right pr-3" : ""}
                    >
                      <span className="send-pack-title">
                        {t("flightNameIs")}
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      lg={7}
                      md={7}
                      sm={8}
                      className={`${isPersian ? "pr-0" : "pl-0"} `}
                    >
                      <AsyncSelect
                        className="custom-input-airline"
                        value={airline}
                        onChange={handleAirlineChange}
                        cacheOptions
                        defaultOptions
                        placeholder={
                          <span className="select-placeholder-text">
                            {t("flightNameIs")}
                          </span>
                        }
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                          Option: InputOption,
                        }}
                        styles={customStyle}
                        loadOptions={searchAirline}
                        loadingMessage={() => "Loading ..."}
                        // noOptionsMessage={() => ""}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={isPersian ? 5 : 6} md={isPersian ? 5 : 6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={4}
                      lg={isPersian ? 4 : 5}
                      md={isPersian ? 4 : 5}
                      sm={4}
                      className={` ${isMobile ? "" : "text-right"}`}
                    >
                      <span className="send-pack-title">
                        {t("andFlightNumber")}
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      lg={isPersian ? 8 : 7}
                      md={isPersian ? 8 : 7}
                      sm={8}
                      className={`${isPersian ? "pr-0" : "pl-0"} `}
                    >
                      <Input
                        id="flightNumber"
                        name="flightNumber"
                        placeholder={t("andFlightNumber")}
                        className="custom-input-offer-flight-number"
                        value={requestData.flightNumber}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}

          <Col xs={12} sm={12} md={6} lg={6} className="request-form mt-4">
            <div
              style={
                isMobile
                  ? isPersian
                    ? { width: "345px", paddingLeft: "20px" }
                    : { width: "345px", paddingRight: "20px" }
                  : isPersian
                  ? { width: "100%", textAlign: "right" }
                  : { width: "580px" }
              }
            >
              <MultipleUploader
                title={t("uploadPkgImgOptional")}
                setPhotos={setImages}
                image={images}
                disabled={false}
                width="w-273"
                description={t("dropImageHere")}
                hasRemove={true}
              />
              {/* <Uploader title="Upload package photo" /> */}
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="request-form mt-4">
            <div
              className={`send-input-wrapper ${isPersian ? "text-right" : ""}`}
              style={{ marginTop: "18px" }}
            >
              <span className="message-title">{t("noteDetail")}</span>
              <div>
                <Input
                  size="sm"
                  id="message"
                  name="message"
                  placeholder={t("shipExample")}
                  className="custom-input-message-package"
                  value={requestData.message}
                  onChange={handleChange}
                  textArea={true}
                  rows={4}
                  maxLength="100"
                />
              </div>
            </div>
          </Col>
          <AddInputForms
            setPurchases={setPurchases}
            purchases={purchases}
            disabled={false}
          />
          <Col xs={12} className="request-form">
            <div style={{ marginTop: "30px" }}>
              <label className="checkbox-container">
                {t("termConditionLinkText")}{" "}
                <a
                  target="_blank"
                  href="/termsCondition"
                  style={{ textDecorationLine: "underline" }}
                >
                  {t("termConditionLink")}{" "}
                </a>
                {t("termConditionLink2")}.
                <input
                  type="checkbox"
                  checked={termsChecked}
                  onChange={handleTermsCheckedChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </Col>
          <Col xs={12} className="request-form">
            <div>
              <label className="checkbox-container gov-checkbox-container">
                {t("acceptAllGovernmentTaxes")}
                <span className="star-require">*</span>
                <input
                  type="checkbox"
                  checked={governmentChecked}
                  onChange={handleGovernmentChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </Col>
          {/* <Col xs={12} className="request-form"> */}
          <div
            className="d-flex w-100 px-3 dir-ltr"
            style={{ marginTop: "24px" }}
          >
            <Button
              onClick={handleChangeClose}
              className="close-request-btn ml-auto mt-4"
            >
              {t("close")}
            </Button>
            <Button
              variant="primary"
              data-test="docs-btn-anchor"
              className="request-submit-request-btn mt-4 mx-3"
              onClick={addNewPackage}
              disabled={!termsChecked || !governmentChecked}
            >
              {t("submitTheRequest")}
              {isLoading && (
                <Oval
                  width="20"
                  height="20"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                />
              )}
            </Button>
          </div>
          {/* </Col> */}
        </Row>
      )}
    </div>
  );
};
