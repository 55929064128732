import { createSlice } from "@reduxjs/toolkit";

export const expenseTypeSlice = createSlice({
  name: "expenseType",
  initialState: [],
  reducers: {
    expenseType: (state, action) => {
      return (state = action.payload.data);
    },
    expenseTypeFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { expenseType, expenseTypeFailure } = expenseTypeSlice.actions;
export default expenseTypeSlice.reducer;
