import { createSlice } from "@reduxjs/toolkit";

export const validationTokenSlice = createSlice({
  name: "validationToken",
  initialState: [],
  reducers: {
    validationToken: (state, action) => {
      return (state = action.payload);
    },
    validationTokenFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { validationToken, validationTokenFailure } = validationTokenSlice.actions;
export default validationTokenSlice.reducer;
