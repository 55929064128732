import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Col, Row, Dropdown } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { ITravel } from "models/interfaces";
import { InputOption } from "components";
import {
  searchAirportLocation,
  searchLocationApi,
} from "helpers/searchLocation";
import { SizeInformation } from "components/modals/sizeInformation";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { getCityCountryFromGooglePlace } from "helpers/googlePlaceCityCountry";
import { components } from "react-select";
import { getDate, convertHumanDateToUnix } from "helpers/convertDate";
import { useAppDispatch, useAppSelector } from "redux/store";
import { MultipleUploader } from "components";
import { Oval } from "react-loader-spinner";
import { searchLocation } from "helpers/searchLocation";
import { searchAirline } from "helpers/searchAirline";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faInfo } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import {
  getAllSizeRange,
  getAllDeliveryType,
  getAllPackagesType,
  // getAllServices,
  getAllPaymentMethod,
  getAllCancellationPolicy,
} from "redux/actions/types";
import {
  addNewTravel,
  editUserTravel,
  getAllDashboardData,
} from "redux/actions/dashboard";
import {
  isValidPackageType,
  isValidPayment,
  isValidSize,
  // isValidService,
  isValidPolicy,
  isValidDefinedLengthSize,
  isValidDefinedWidthSize,
  isValidDefinedHeightSize,
  isEndDateGreaterThanStartDate,
  isEndDateGreaterEqualThanStartDate,
  isValidFrom,
  isValidTo,
  isValid,
} from "helpers/travelValidation";
import { Link, useNavigate } from "react-router-dom";

interface IProp {
  setIsOpen: Function;
  isOpen: boolean;
  mode: string;
  trvId?: string;
}

export const AddEditTravel: React.FC<IProp> = ({
  setIsOpen,
  isOpen,
  mode,
  trvId,
}) => {
  const dispatch = useAppDispatch();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [travelData, setTravelData] = useState<ITravel>({
    packagetypeIds: [],
    packageType: "",
    serviceIds: [],
    sizerangeIds: [],
    weight: "",
    weightUnit: "kg",
    paymentmethodIds: [],
    value: "",
    sizeWidth: "",
    sizeHeight: "",
    sizeLength: "",
    lengthUnit: "cm",
    deliverytypeIds: [],
    fromCountry: "",
    fromCountryCity: "",
    toCountry: "",
    toCountryCity: "",
    fromDate1: "",
    fromDate2: "",
    toDate1: "",
    toDate2: "",
    offerPrice: "",
    currencyType: "CAD",
    cancellationPolicyId: "",
    message: "",
    images: [],
    airlineName: "",
    flightNumber: "",
  });
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const [isCancellation, setIsCancellation] = useState(true);
  const navigate = useNavigate();
  const [packagetypeIds, setPackagetypeIds] = useState<any>([]);
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [serviceIds, setServiceIds] = useState<any>([]);
  const [sizerangeIds, setSizerangeIds] = useState<any>([]);
  const [paymentmethodIds, setPaymentmethodIds] = useState<any>([]);
  const [deliverytypeIds, setDeliverytypeIds] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [deliveryType, setDeliveryType] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [payment, setPayment] = useState(null);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState([]);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [cancellationPolicyOptions, setCancellationPolicyOptions] = useState(
    []
  );
  const [paymentsOptions, setPaymentsOptions] = useState([]);
  const windowSize = UseWindowSize();
  const [weightUnit, setWeightUnit] = useState({ value: "kg", label: "KG" });
  const [lengthUnit, setLengthUnit] = useState({ value: "cm", label: "CM" });
  const [airline, setAirline] = useState<any>();
  const [type, setType] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [size, setSize] = useState(null);
  const [sizeOptions, setSizeOptions] = useState([]);
  const addTravelData: any = useAppSelector((state) => state.addTravel);
  const editTravelData: any = useAppSelector((state) => state?.editTravel);
  const sizeRanges = useAppSelector((state) => state.sizeRange);
  const packagesType = useAppSelector((state) => state.packageTypes);
  const policies = useAppSelector((state) => state.cancellationPolicy);
  const payments = useAppSelector((state) => state.paymentMethod);
  const userTravel: any = useAppSelector((state) => state?.userTravel);
  const deliveryTypes = useAppSelector((state) => state.deliveryType);
  const [arrivalBetweenDate, setArrivalBetweenDate] = useState(new Date());
  const [arrivalToDate, setArrivalToDate] = useState(new Date());
  const [departureBetweenDate, setDepartureBetweenDate] = useState(new Date());
  const [departureToDate, setDepartureToDate] = useState(new Date());
  const [fromDate1, setFromDate1] = useState(getDate(new Date()));
  const [fromDate2, setFromDate2] = useState(getDate(new Date()));
  const [toDate1, setToDate1] = useState(getDate(new Date()));
  const [toDate2, setToDate2] = useState(getDate(new Date()));
  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();
  const [isCad, setIsCad] = useState(true);
  const [isCash, setIsCash] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];
  const unitLengthOption = [
    { value: "cm", label: "CM" },
    { value: "inch", label: "INCH" },
  ];
  const currencies = useAppSelector((state) => state.currencies);
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [currenciesOption, setCurrenciesOption] = useState([
    {
      value: localStorage.getItem("currency"),
      label: localStorage.getItem("currency"),
    },
  ]);

  useEffect(() => {
    dispatch(getAllSizeRange());
    dispatch(getAllDeliveryType());
    dispatch(getAllPackagesType());
    dispatch(getAllCancellationPolicy());
    dispatch(getAllPaymentMethod());
  }, []);

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const handleCurrencyChange = (selected) => {
    if (selected.value !== "CAD") setIsCad(false);
    else setIsCad(true);
    setCurrency(selected);
    localStorage.setItem("currency", selected.value);
  };

  const handleWeightUnitChange = (selected) => {
    setWeightUnit(selected);
  };

  const handleLengthUnitChange = (selected) => {
    setLengthUnit(selected);
  };

  const openInfoModal = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  const handleTermsCheckedChange = () => {
    setTermsChecked(!termsChecked);
  };

  useEffect(() => {
    const options = deliveryTypes?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setDeliveryType(null);
    // setDeliverytypeIds([deliveryTypes[0]?.id]);
    setDeliveryTypeOptions(options);
  }, [deliveryTypes]);

  useEffect(() => {
    const options = payments?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setPayment({ value: payments[0]?.id, label: payments[0]?.name });
    setPaymentmethodIds([payments[0]?.id]);
    setPaymentsOptions(options);
  }, [payments]);

  useEffect(() => {
    const options = policies?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setPolicy(null);
    const valueToDisable = "23e33081-d1fd-48ef-a17a-fbbdc69076ce";
    const modifiedCancellationPolicyOptions = options.map((option) => ({
      ...option,
      isDisabled: option.value === valueToDisable,
    }));

    // const modifiedCancellationPolicyOptions = options.filter(
    //   (option) => option.value !== valueToDisable
    // );
    setCancellationPolicyOptions(modifiedCancellationPolicyOptions);
    // setCancellationPolicyOptions(options);
  }, [policies]);

  useEffect(() => {
    const options = sizeRanges?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSize(null);
    // setSizerangeIds([sizeRanges[0]?.id]);
    setSizeOptions(options);
  }, [sizeRanges]);

  useEffect(() => {
    const options = packagesType?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setType(null);
    setPackagetypeIds([packagesType[0]?.id]);
    // setPackagetypeIds(
    //   [
    //     ...packagetypeIds,
    //     { value: packagesType[0]?.id, label: packagesType[0]?.name },
    //   ].filter(function (x) {
    //     return x.value !== undefined;
    //   })
    // );
    setTypeOptions(options);
  }, [packagesType]);

  const handleAirlineChange = (selected) => {
    setAirline(selected);
  };

  const handlePolicyChange = (selected) => {
    setPolicy(selected);
  };

  const handlePaymentChange = (selected) => {
    if (selected.value === "6dd94ea2-a4d7-4dd6-824c-b004b65a6b85")
      setIsCash(false);
    else setIsCash(true);
    if (selected.value === "0737c3c9-f109-460d-aeaf-431c80df4e65")
      setIsCancellation(true);
    else setIsCancellation(false);
    setPayment(selected);
    setPaymentmethodIds(selected.value);
  };

  const handleDeliveryTypeChange = (selected) => {
    setDeliveryType(selected);
    setDeliverytypeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
  };

  const handleSizeChange = (selected) => {
    setSize(selected);
    setSizerangeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
    if (
      selected?.find((item) => {
        return item.value === "6a50f6c5-0c00-498e-9653-1d8914172181";
      })
    )
      setIsCustom(true);
    else setIsCustom(false);
  };

  const handleTypeChange = (selected) => {
    setType(selected);
    setPackagetypeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    // setTravelData({ ...travelData, [e.target.name]: e.target.value });
    const newValue = e.target.value;
    if (newValue.length <= 100) {
      setTravelData({ ...travelData, [e.target.name]: newValue });
    } else {
      setTravelData({
        ...travelData,
        [e.target.name]: newValue.slice(0, 100),
      });
    }
  };

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    );
  };

  const onArrivalBetweenDateChange = (date) => {
    setArrivalBetweenDate(date);
    setDepartureBetweenDate(date);
    setArrivalToDate(date);
    setDepartureToDate(addDays(date, 1));
    setFromDate1(getDate(date));
    setFromDate2(getDate(date));
    setToDate1(getDate(date));
    setToDate2(getDate(addDays(date, 1)));
  };

  const onDepartureFromDateChange = (date) => {
    setDepartureBetweenDate(date);
    setArrivalToDate(date);
    setDepartureToDate(addDays(date, 1));
    setFromDate2(getDate(date));
    setToDate1(getDate(date));
    setToDate2(getDate(addDays(date, 1)));
  };

  const onArrivalToDateChange = (date) => {
    setArrivalToDate(date);
    setDepartureToDate(addDays(date, 1));
    setToDate1(getDate(date));
    setToDate2(getDate(addDays(date, 1)));
  };

  const onDepartureToDateChange = (date) => {
    setDepartureToDate(date);
    setToDate2(getDate(addDays(date, 1)));
  };

  useEffect(() => {
    const data = userTravel.data;
    if (userTravel?.length !== 0 && mode === "edit") {
      if (data.packagetypes) {
        let types = data?.packagetypes?.split(", ");
        if (data?.packagetypes?.includes(",")) {
          const packages = types
            .map((item) => {
              return typeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setType(packages);
          const results = packages?.map((pkg) => {
            return pkg.value;
          });
          setPackagetypeIds(results);
        } else
          setType(
            typeOptions.find((option) => option.label === data.packagetypes)
          );
      } else {
        setType([{ value: packagesType[0]?.id, label: packagesType[0]?.name }]);
      }
      if (data.deliveryTypes) {
        let types = data?.deliveryTypes?.split(", ");
        if (data?.deliveryTypes?.includes(",")) {
          const deliveries = types
            .map((item) => {
              return deliveryTypeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setDeliveryType(deliveries);
          const results = deliveries?.map((pkg) => {
            return pkg.value;
          });
          setDeliverytypeIds(results);
        } else
          setDeliveryType(
            deliveryTypeOptions.find(
              (option) => option.label === data.deliveryTypes
            )
          );
      }
      if (data.sizeranges) {
        let sizes = data?.sizeranges?.split(", ");
        if (
          data?.sizeranges?.includes("سفارشی") ||
          data?.sizeranges?.includes("Custom")
        )
          setIsCustom(true);
        else setIsCustom(false);
        if (data?.sizeranges?.includes(",")) {
          const ranges = sizes
            .map((item) => {
              return sizeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setSize(ranges);
          const results = ranges?.map((pkg) => {
            return pkg.value;
          });
          setSizerangeIds(results);
        } else {
          setSize(
            sizeOptions.find((option) => {
              return option.label === data?.sizeranges;
            })
          );
          setSizerangeIds(
            sizeOptions.find((option) => {
              return option.label === data?.sizeranges;
            })?.value
          );
        }
      } else {
        setSize({ value: sizeRanges[0]?.id, label: sizeRanges[0]?.name });
      }

      if (data.paymentmethods) {
        if (data?.paymentmethods?.includes(",")) {
          const paymentsData = data?.paymentmethods
            ?.split(", ")
            .map((item) => {
              return paymentsOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setPayment(paymentsData);
        } else {
          setPayment(
            paymentsOptions.find(
              (option) => option.label === data.paymentmethods
            )
          );
          setPaymentmethodIds(
            paymentsOptions.find((option) => {
              return option.label === data?.paymentmethods;
            })?.value
          );
          if (data.paymentmethods === "Cash before shipping") {
            setIsCancellation(false);
            setIsCash(false);
            setPayment(
              paymentsOptions.find(
                (option) => option.label === data.paymentmethods
              )
            );
            setPaymentmethodIds("6dd94ea2-a4d7-4dd6-824c-b004b65a6b85");
          } else setIsCash(true);
        }
      } else {
        setPayment({ value: payments[0]?.id, label: payments[0]?.name });
      }

      if (data.cancellationpolicy) {
        if (data?.cancellationpolicy?.includes(",")) {
          const cancellationData = data?.cancellationpolicy
            ?.split(", ")
            .map((item) => {
              return cancellationPolicyOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setPolicy(cancellationData);
        } else {
          setPolicy(
            cancellationPolicyOptions.find(
              (option) => option.label === data.cancellationpolicy
            )
          );
        }
      } else {
        setPolicy({ value: policies[0]?.id, label: policies[0]?.name });
      }
    }
  }, [
    userTravel,
    typeOptions,
    sizeOptions,
    deliveryTypeOptions,
    servicesOptions,
    paymentsOptions,
    cancellationPolicyOptions,
  ]);

  useEffect(() => {
    if (userTravel?.length !== 0 && mode === "edit") {
      const data = userTravel?.data;
      const width = data?.sizeWidth;
      const height = data?.sizeHeight;
      const length = data?.sizeLength;
      if (data.currencyType !== "CAD") setIsCad(false);
      else setIsCad(true);
      const currency = data.currencyType ? data.currencyType : "CAD";
      const unit = data.weightUnit ? data.weightUnit : "KG";
      const sizeUnit = data.lengthUnit ? data.lengthUnit : "CM";
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setLengthUnit({ value: sizeUnit, label: sizeUnit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      searchLocationApi(data.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      setPolicy(
        cancellationPolicyOptions.find(
          (option) => option.label === data.cancellationpolicy
        )
      );
      setArrivalBetweenDate(
        data?.fromDate1 ? convertHumanDateToUnix(data?.fromDate1) : new Date()
      );
      setArrivalToDate(
        data?.toDate1 ? convertHumanDateToUnix(data?.toDate1) : new Date()
      );
      setDepartureBetweenDate(
        data?.fromDate2 ? convertHumanDateToUnix(data?.fromDate2) : new Date()
      );
      setDepartureToDate(
        data?.toDate2 ? convertHumanDateToUnix(data?.toDate2) : new Date()
      );
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      setAirline({ value: data?.airlineName, label: data?.airlineName });

      setTravelData({
        ...travelData,
        weight: data.weight,
        message: data.description,
        value: data.itemValue,
        offerPrice: data.offerPrice,
        sizeWidth: width,
        sizeHeight: height,
        sizeLength: length,
        flightNumber: data?.flightNumber,
      });
      setImages(data.images);
    }
  }, [userTravel, mode]);

  useEffect(() => {
    if (mode === "add") {
      setFrom(null);
      setTo(null);
      setSize(null);
      setType(null);
      setPayment(null);
      setPolicy(null);
      setArrivalBetweenDate(new Date());
      setArrivalToDate(new Date());
      setDepartureBetweenDate(new Date());
      setDepartureToDate(new Date());
      setTravelData({
        ...travelData,
        weight: "",
        message: "",
        value: "",
        offerPrice: "",
        sizeWidth: "0",
        sizeHeight: "0",
        sizeLength: "0",
        airlineName: "",
        flightNumber: "",
      });
      setImages([]);
    }
  }, [mode]);
  const isMobileTablet = windowSize.width < 992;
  const isMobile = windowSize.width < 768;

  const addEditTravel = () => {
    setSubmitClicked(true);
    const data = {
      type,
      size,
      payment,
      policy,
      length: travelData.sizeLength,
      width: travelData.sizeWidth,
      height: travelData.sizeHeight,
      from,
      to,
      arrivalBetweenDate,
      departureBetweenDate,
      arrivalToDate,
      departureToDate,
    };
    if (!isValid(termsChecked, data, isCancellation)) {
      toast.error(
        lang === "fa"
          ? "پارامترها را به درستی وارد نمایید"
          : "Enter the parameters correctly!"
      );
      return;
    }

    addEditTravelApiCall();
    document.documentElement.style.overflow = "auto";
  };

  const addEditTravelApiCall = () => {
    document.documentElement.style.overflow = "hidden";
    const body = new FormData();
    if (mode === "edit") body.append("trvId", trvId);
    body.append("packagetypeIds", packagetypeIds);
    body.append("packageType", "");
    body.append("sizerangeIds", sizerangeIds);
    body.append("weight", travelData?.weight);
    body.append("weightUnit", weightUnit?.value);
    body.append("paymentmethodIds", paymentmethodIds);
    body.append("value", travelData?.value);
    body.append("sizeWidth", isCustom ? travelData?.sizeWidth : "");
    body.append("sizeHeight", isCustom ? travelData?.sizeHeight : "");
    body.append("sizeLength", isCustom ? travelData?.sizeLength : "");
    body.append("lengthUnit", lengthUnit?.value);
    body.append("deliverytypeIds", deliverytypeIds);
    body.append("fromCityEntityId", from?.value ? from?.value : "");
    body.append("toCityEntityId", to?.value ? to?.value : "");
    body.append("fromDate1", fromDate1);
    body.append("fromDate2", fromDate2);
    body.append("toDate1", toDate1);
    body.append("toDate2", toDate2);
    body.append("offerPrice", travelData.offerPrice);
    body.append("currencyType", currency?.value);
    body.append("cancellationPolicyId", policy?.value ? policy?.value : "");
    body.append("message", travelData?.message);
    body.append("airlineName", airline?.label ? airline?.label : "");
    body.append("flightNumber", travelData?.flightNumber);
    for (const image of images) {
      body.append("images", image);
    }
    if (mode === "add") dispatch(addNewTravel(body));
    else if (mode === "edit") dispatch(editUserTravel(body));
    setIsLoading(true);
  };

  useEffect(() => {
    if (!addTravelData?.isSuccess) {
      setIsLoading(false);
    } else if (addTravelData?.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      dispatch(getAllDashboardData());
    }
  }, [addTravelData]);

  useEffect(() => {
    if (!editTravelData?.isSuccess) {
      setIsLoading(false);
    } else if (editTravelData?.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      dispatch(getAllDashboardData());
    }
  }, [editTravelData]);

  const handleFromChange = (selected) => {
    setFrom(selected);
  };

  const handleToChange = (selected) => {
    setTo(selected);
  };

  const preventTypeText = (e) => {
    const validInputRegex = /^[0-9.]$/; // Allow digits and dot
    const currentValue = e.target.value + e.key;
    if (validInputRegex.test(e.key) || e.key === "Backspace") {
      const regex = /^(?!1000(\.0)?$)\d{1,3}(\.\d{0,1})?$/;
      if (regex.test(currentValue) || currentValue === "") {
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const checkLabel = () => {
    if (
      Array.isArray(size) &&
      size?.find((item) => {
        return (
          item.value === "6a50f6c5-0c00-498e-9653-1d8914172181" && (
            <span className="star-require">*</span>
          )
        );
      })
    ) {
      return <span className="star-require">*</span>;
    } else if (size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181") {
      <span className="star-require">*</span>;
    }
  };

  const handleChangeClose = () => {
    const str = window.location.pathname;
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    if (str?.includes("dashboard/addTravel"))
      navigate("/dashboard/myTravels", { state: t("myTravels") });
    if (str?.includes("dashboard/editTravel"))
      navigate("/dashboard/myTravels", { state: t("myTravels") });
  };

  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
      height: 50,
      minHeight: 50,
      ontSize: isMobile && "12px",
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? "#c1c1c1" : "#00043d",
      cursor: isDisabled ? "default" : "pointer",
      backgroundColor: "#fff",
      ontSize: isMobile && "12px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
  };

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#707070",
      flexWrap: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      <div className="request-slider-container">
        <Row className={`request-wrapper ${isPersian ? "dir-rtl" : ""}`}>
          <Button
            variant="white"
            className="close-sidebar-btn"
            onClick={handleChangeClose}
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
          <Col xs={12} className="request-form">
            <h1>{mode === "add" ? t("addTravel") : t("editTravel")}</h1>
            <Row className="align-items-center">
              <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("from")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={10}>
                <AsyncSelect
                  className={`custom-place-from ${
                    submitClicked &&
                    !isValidFrom(from) &&
                    "custom-empty-input-style"
                  }`}
                  value={from}
                  onChange={handleFromChange}
                  cacheOptions
                  defaultOptions
                  placeholder={
                    <span className="select-placeholder-text">
                      {t("countryCityAirport")}
                    </span>
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  styles={customStyle}
                  loadOptions={searchAirportLocation}
                  loadingMessage={() => "Loading ..."}
                />
              </Col>
            </Row>
            <Row className="align-items-center mt-3">
              <Col xs={2}>
                <span className="send-pack-title">
                  {t("to")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={10}>
                <AsyncSelect
                  className={`custom-place-to ${
                    submitClicked &&
                    !isValidTo(to) &&
                    "custom-empty-input-style"
                  }`}
                  value={to}
                  onChange={handleToChange}
                  cacheOptions
                  defaultOptions
                  placeholder={
                    <span className="select-placeholder-text">
                      {t("countryCityAirport")}
                    </span>
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  styles={customStyle}
                  loadOptions={searchAirportLocation}
                  loadingMessage={() => "Loading ..."}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="request-form">
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={isPersian ? 4 : 5}
                    className={`${isPersian ? "text-right pr-3" : ""}`}
                  >
                    <span className="send-pack-title">
                      {t("travelDepartureDate")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col
                    xs={isPersian ? 8 : 7}
                    className={isPersian ? "pr-0" : "pl-0"}
                  >
                    <DatePicker
                      className="custom-travel-input-between"
                      selected={arrivalBetweenDate}
                      onChange={(date) => onArrivalBetweenDateChange(date)}
                      dateFormat="EEEE, MM/dd/yyyy"
                      showTimeInput
                      minDate={new Date()}
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={6} md={6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={isPersian ? 4 : 5}
                    className={`${!isMobile && "text-center"} ${
                      isPersian ? "text-right pr-3" : "pr-0"
                    }`}
                  >
                    <span className="send-pack-title">
                      {t("travelArrivalDate")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col
                    xs={isPersian ? 8 : 7}
                    className={`${isPersian ? " text-right pr-1" : "pl-0"}`}
                  >
                    {" "}
                    <DatePicker
                      dateFormat="EEEE, MM/dd/yyyy"
                      showTimeInput
                      onChange={(date) => onDepartureFromDateChange(date)}
                      selected={departureBetweenDate}
                      className={`custom-input-to-travel ${
                        submitClicked &&
                        !isEndDateGreaterEqualThanStartDate(
                          arrivalBetweenDate,
                          departureBetweenDate
                        ) &&
                        "empty-input-style"
                      }`}
                      minDate={addDays(arrivalBetweenDate, 0)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>

          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col
                xs={5}
                lg={isPersian ? 4 : 3}
                md={isPersian ? 4 : 3}
                sm={5}
                className={`${isPersian ? "text-right pr-3" : ""}`}
              >
                <span className="send-pack-title">
                  {t("maxSizeParcelCarry")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col
                xs={6}
                lg={isPersian ? 7 : 8}
                md={isPersian ? 7 : 8}
                sm={6}
                className="pl-0"
              >
                <Select
                  className={`custom-select-travel-size ${
                    submitClicked &&
                    !isValidSize(size) &&
                    "custom-empty-input-style"
                  }`}
                  value={size}
                  onChange={handleSizeChange}
                  options={sizeOptions}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    Option: InputOption,
                  }}
                  styles={customStyle}
                  isMulti
                />
              </Col>
              <Col
                xs={1}
                lg={1}
                md={1}
                sm={1}
                className={`${isPersian ? "pr-0" : "pl-0"}`}
              >
                <Dropdown
                  className="size-info-dropdown d-inline"
                  onToggle={handleSelect}
                >
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="px-0 mx-0"
                  >
                    <div className="triangle">
                      <FontAwesomeIcon
                        icon={faInfo}
                        className="info-defined-wrapper"
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <strong>{t("small")}</strong>, {t("smallExample")}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <strong>{t("medium")}</strong>, {t("mediumExample")}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <strong>{t("large")}</strong>, {t("largeExample")}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <strong>{t("xlarge")}</strong>, {t("xLargeExample")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Col>
          {isCustom && (
            <Col xs={12} className="request-form">
              <Row className="align-items-center mt-3">
                <Col
                  xs={3}
                  className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                >
                  <span className="send-pack-title">
                    {t("definedAs")}
                    {checkLabel()}
                  </span>
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeLength"
                    name="sizeLength"
                    placeholder="Length"
                    className={`custom-input-size-length ${
                      submitClicked && Array.isArray(size)
                        ? size?.find((item) => {
                            return (
                              item.value ===
                                "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                              !isValidDefinedLengthSize(
                                travelData.sizeLength
                              ) &&
                              "empty-input-style"
                            );
                          })
                        : size?.value ===
                            "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                          !isValidDefinedLengthSize(travelData.sizeLength) &&
                          "empty-input-style"
                    }`}
                    value={travelData.sizeLength}
                    onChange={handleChange}
                    disabled={!isCustom}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeWidth"
                    name="sizeWidth"
                    placeholder="Width"
                    className={`custom-input-size-width ${
                      submitClicked && Array.isArray(size)
                        ? size?.find((item) => {
                            return (
                              item.value ===
                                "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                              !isValidDefinedWidthSize(travelData.sizeWidth) &&
                              "empty-input-style"
                            );
                          })
                        : size?.value ===
                            "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                          !isValidDefinedWidthSize(travelData.sizeWidth) &&
                          "empty-input-style"
                    }`}
                    value={travelData.sizeWidth}
                    onChange={handleChange}
                    disabled={!isCustom}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeHeight"
                    name="sizeHeight"
                    placeholder="Height"
                    className={`custom-input-size-height ${
                      submitClicked && Array.isArray(size)
                        ? size?.find((item) => {
                            return (
                              item.value ===
                                "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                              !isValidDefinedHeightSize(
                                travelData.sizeHeight
                              ) &&
                              "empty-input-style"
                            );
                          })
                        : size?.value ===
                            "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                          !isValidDefinedHeightSize(travelData.sizeHeight) &&
                          "empty-input-style"
                    }`}
                    value={travelData.sizeHeight}
                    onChange={handleChange}
                    disabled={!isCustom}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Select
                    className="custom-select-unit-length d-inline-block"
                    value={lengthUnit}
                    onChange={handleLengthUnitChange}
                    options={unitLengthOption}
                    styles={unitCustomStyle}
                    isDisabled={!isCustom}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </Col>
                <Col xs={1} className={`${isPersian ? "pr-0" : "pl-0"}`}>
                  <div className="triangle">
                    <FontAwesomeIcon
                      icon={faInfo}
                      className="info-defined-wrapper"
                      onClick={openInfoModal}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          )}

          {isInfoOpen && (
            <SizeInformation isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
          )}
          <Col xs={12} className="request-form">
            <Row
              className={`align-items-center mt-3  ${isPersian ? "pl-0" : ""}`}
            >
              <Col
                xs={isPersian ? 4 : 3}
                className={`${isPersian ? "text-right pr-3" : ""}`}
              >
                <span className="send-pack-title">{t("maxWeightOf")}</span>
              </Col>
              <Col
                xs={isPersian ? 8 : 9}
                className={`${isPersian ? "" : "pl-0"}`}
              >
                <Input
                  size="sm"
                  id="weight"
                  name="weight"
                  placeholder={t("weightKgLbs")}
                  className="custom-input-package"
                  value={travelData.weight}
                  onChange={handleChange}
                  onKeyPress={preventTypeText}
                />
                <Select
                  className="custom-select-unit-size"
                  value={weightUnit}
                  onChange={handleWeightUnitChange}
                  options={unitWeightOption}
                  styles={unitCustomStyle}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                />
              </Col>
            </Row>
            <div className="send-input-wrapper"></div>
            {isCash && !isCad && payment && (
              <span className="warning-payable-title d-block mt-0 mb-3 w-100">
                {t("currencyWarning")}
              </span>
            )}
          </Col>
          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col xs={3} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("payableBy")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={9} className={`${isPersian ? "" : "pl-0"}`}>
                <Select
                  className={`custom-select-carry-payment ${
                    submitClicked &&
                    !isValidPayment(payment) &&
                    "custom-empty-input-style"
                  }`}
                  value={payment}
                  placeholder="[Select payment method from available options]"
                  onChange={handlePaymentChange}
                  options={paymentsOptions}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    Option: InputOption,
                  }}
                  styles={customStyle}
                  // isMulti
                />
              </Col>
            </Row>
            {!isCancellation && payment ? (
              <span className="warning-payable-title d-block mt-3 mb-3 w-100">
                {t("cashBeforeShippingWarning")}
              </span>
            ) : isCancellation && payment ? (
              <span className="info-payable-title d-block mt-2 mb-3 w-100">
                {t("attentionTravelerPart1")}{" "}
                {isMobileTablet ? (
                  <Link
                    target="_blank"
                    to="/settings/addBankAccount"
                    onClick={() => {
                      localStorage.setItem("tab", "financial");
                    }}
                  >
                    {" "}
                    {t("attentionTravelerPart2")}{" "}
                  </Link>
                ) : (
                  <a
                    onClick={() => {
                      localStorage.setItem("tab", "userInfo");
                    }}
                    target="_blank"
                    href="/settings/addBankAccount"
                  >
                    {" "}
                    {t("attentionTravelerPart2")}{" "}
                  </a>
                )}
                {t("attentionTravelerPart3")}
              </span>
            ) : null}
          </Col>
          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col
                xs={4}
                className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
              >
                <span className="send-pack-title">
                  {t("acceptablePackageType")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={8} className={`${isPersian ? "" : "pl-0"} `}>
                <Select
                  className={`custom-select-package-type ${
                    submitClicked &&
                    !isValidPackageType(type) &&
                    "custom-empty-input-style"
                  }`}
                  placeholder={t("selectPackageTypePlaceholder")}
                  value={type}
                  onChange={handleTypeChange}
                  options={typeOptions}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    Option: InputOption,
                  }}
                  isMulti
                  styles={customStyle}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col
                xs={4}
                className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
              >
                <span className="send-pack-title">{t("providedService")}</span>
              </Col>
              <Col xs={8} className={`${isPersian ? "" : "pl-0"} `}>
                <Select
                  className="custom-select-deliver-service"
                  value={deliveryType}
                  onChange={handleDeliveryTypeChange}
                  options={deliveryTypeOptions}
                  placeholder={t("providedServicePlaceholder")}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  styles={customStyle}
                  isMulti
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="request-form">
            <span
              className={`send-pack-title mt-4 d-block w-100 ${
                isPersian ? "text-right" : ""
              }`}
            >
              {t("deliveryDateWillBe")}:
            </span>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={3}
                    className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                  >
                    <span className="send-pack-title">
                      {t("fromDate")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col xs={9} className={isPersian ? "pr-0" : "pl-0"}>
                    <DatePicker
                      // className="custom-input-from-to-travel"
                      dateFormat="EEEE, MM/dd/yyyy"
                      showTimeInput
                      selected={arrivalToDate}
                      onChange={(date) => onArrivalToDateChange(date)}
                      className={`first-custom-input-from-to-travel d-inline-block ${
                        submitClicked &&
                        !isEndDateGreaterEqualThanStartDate(
                          departureBetweenDate,
                          arrivalToDate
                        ) &&
                        "empty-input-style"
                      }`}
                      minDate={addDays(departureBetweenDate, 0)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={3}
                    className={` ${isMobile ? "" : "pr-0 text-center"}`}
                  >
                    <span className="send-pack-title">
                      {t("toDate")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col xs={9} className={`${isPersian ? "" : "pl-0"} `}>
                    <DatePicker
                      // className={`custom-input-from-to-travel d-inline-block`}
                      selected={departureToDate}
                      onChange={(date) => onDepartureToDateChange(date)}
                      dateFormat="EEEE, MM/dd/yyyy"
                      showTimeInput
                      className={`second-custom-input-from-to-travel d-inline-block ${
                        submitClicked &&
                        !isEndDateGreaterEqualThanStartDate(
                          arrivalToDate,
                          departureToDate
                        ) &&
                        "empty-input-style"
                      }`}
                      minDate={addDays(arrivalToDate, 1)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="request-form">
            <Row>
              <Col lg={isPersian ? 7 : 6} md={isPersian ? 7 : 6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={4}
                    lg={5}
                    md={5}
                    sm={4}
                    className={isPersian ? "text-right pr-3" : ""}
                  >
                    <span className="send-pack-title">{t("flightNameIs")}</span>
                  </Col>
                  <Col
                    xs={8}
                    lg={7}
                    md={7}
                    sm={8}
                    className={`${isPersian ? "pr-0" : "pl-0"} `}
                  >
                    <AsyncSelect
                      className="custom-input-airline"
                      value={airline}
                      onChange={handleAirlineChange}
                      cacheOptions
                      defaultOptions
                      placeholder={t("flightNameIs")}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                        Option: InputOption,
                      }}
                      styles={customStyle}
                      loadOptions={searchAirline}
                      loadingMessage={() => "Loading ..."}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={isPersian ? 5 : 6} md={isPersian ? 5 : 6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={4}
                    lg={isPersian ? 4 : 5}
                    md={isPersian ? 4 : 5}
                    sm={4}
                    className={` ${isMobile ? "" : "text-right"}`}
                  >
                    <span className="send-pack-title">
                      {t("andFlightNumber")}
                    </span>
                  </Col>
                  <Col
                    xs={8}
                    lg={isPersian ? 8 : 7}
                    md={isPersian ? 8 : 7}
                    sm={8}
                    className={`${isPersian ? "pr-0" : "pl-0"} `}
                  >
                    <Input
                      id="flightNumber"
                      name="flightNumber"
                      placeholder="XDS4528"
                      className="custom-input-offer-flight-number"
                      value={travelData.flightNumber}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {isCancellation && (
            <Col xs={12} className="request-form">
              <Row className="align-items-center mt-3">
                <Col
                  xs={isPersian ? 3 : 4}
                  className={` ${isPersian ? "text-right pr-3" : ""}`}
                >
                  <span className="send-pack-title">
                    {t("cancellationPolicy")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col
                  xs={isPersian ? 9 : 8}
                  className={`${isPersian ? "pr-0" : "pl-0"} `}
                >
                  <Select
                    className={`custom-select-package-policy ${
                      submitClicked &&
                      !isValidPolicy(policy, isCancellation) &&
                      "custom-empty-input-style"
                    }`}
                    // className={`custom-select-package-policy d-inline-block `}
                    value={policy}
                    onChange={handlePolicyChange}
                    placeholder={t("selectCancellationPlaceholder")}
                    options={cancellationPolicyOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      Option: InputOption,
                    }}
                    styles={customStyle}
                  />
                </Col>
              </Row>
            </Col>
          )}

          <Col lg={6} md={6} sm={12} className="request-form">
            <div
              style={
                isMobile
                  ? isPersian
                    ? { width: "345px", paddingLeft: "20px" }
                    : { width: "345px", paddingRight: "20px" }
                  : isPersian
                  ? { width: "100%", textAlign: "right" }
                  : { width: "580px" }
              }
            >
              <MultipleUploader
                title={t("uploadTicketOptional")}
                setPhotos={setImages}
                image={images}
                disabled={false}
                width="w-273"
                description={t("dropImageHere")}
                hasRemove={true}
                trvId={trvId}
              />
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} className="request-form h-100">
            <div
              className={`send-input-wrapper ${isPersian ? "text-right" : ""}`}
              style={{ marginTop: "18px" }}
            >
              <span className="message-title">{t("noteDetail")}</span>
              <div>
                <Input
                  size="sm"
                  id="message"
                  name="message"
                  placeholder={t("acceptAllVisibleDocs")}
                  className="custom-input-message-package"
                  value={travelData.message}
                  rows={4}
                  textArea={true}
                  onChange={handleChange}
                  maxLength="100"
                />
              </div>
            </div>
          </Col>
          <Col xs={12} className="request-form">
            <div style={{ marginTop: "30px" }}>
              <label className="checkbox-container">
                {t("termConditionLinkText")}{" "}
                <a
                  target="_blank"
                  href="/termsCondition"
                  style={{ textDecorationLine: "underline" }}
                >
                  {t("termConditionLink")} {t("termConditionLink2")}
                </a>
                .
                <input
                  type="checkbox"
                  checked={termsChecked}
                  onChange={handleTermsCheckedChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </Col>
          <div
            className="d-flex w-100 px-3 dir-ltr"
            style={{ marginTop: "24px" }}
          >
            <Button
              onClick={handleChangeClose}
              className="close-request-btn ml-auto mr-2 mt-4"
            >
              {t("close")}
            </Button>
            <Button
              variant="primary"
              onClick={addEditTravel}
              className="request-submit-request-btn"
              disabled={!termsChecked}
            >
              {t("submitRequest")}
              {isLoading && (
                <Oval
                  width="20"
                  height="20"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                />
              )}
            </Button>
          </div>
          {/* </Col> */}
        </Row>
      </div>
    </>
  );
};
