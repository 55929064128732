import { createSlice } from "@reduxjs/toolkit";

export const userChatSlice = createSlice({
  name: "userChat",
  initialState: [],
  reducers: {
    userChat: (state, action) => {
      return (state = action.payload);
    },
    userChatFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { userChat, userChatFailure } = userChatSlice.actions;
export default userChatSlice.reducer;
