import React, { useState, useEffect } from "react";
import { Button } from "layers";
import { Row, Col } from "react-bootstrap";
import { Gallery } from "react-photoswipe-gallery";
import { LightBoxItem, PersonaTemplate } from "components";
import { Item } from "react-photoswipe-gallery";
import {
  gatVerifyPassport,
  gatVerifySecondIdentity,
} from "redux/actions/persona";
import { useAppDispatch, useAppSelector } from "redux/store";
import UploadIcon from "../../../assets/images/upload.png";
import { useTranslation } from "react-i18next";
import { UploadIdentity } from "components/modals/UploadIdentity";

interface IProp {
  title: string;
  photo?: File;
  image?: string;
  confirmedStatus?: string;
  type?: string;
  setDeleted?: Function;
  setPhoto?: React.Dispatch<React.SetStateAction<File>>;
}
export const ProfileUploader: React.FC<IProp> = ({
  title,
  image,
  setPhoto,
  setDeleted,
  confirmedStatus,
  type,
}) => {
  const [img, setImg] = useState(image);
  const { t } = useTranslation();
  const [isOpenPersonaModal, setIsOpenPersonaModal] = useState(false);
  const dispatch = useAppDispatch();
  const verificationType = useAppSelector<any>(
    (state) => state.verificationType
  );
  const updateImage = (e) => {
    setPhoto(e.target.files[0]);
    setImg(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    // if (verificationType?.isSuccess)
  }, [verificationType]);

  const verifyPassport = () => {
    // dispatch(gatVerifyPassport());
    setIsOpenPersonaModal(true);
  };

  const verifySecondIdentity = () => {
    // dispatch(gatVerifySecondIdentity());
    setIsOpenPersonaModal(true);
  };

  const deleteImage = () => {
    setImg("");
    setPhoto(null);
    setDeleted(true);
  };

  return (
    <>
      <span className="upload-title">{title}</span>
      <div className="uploader-img-wrapper">
        <div
          className="image-container text-center"
          onClick={type === "Passport" ? verifyPassport : verifySecondIdentity}
        >
          {img ? (
            <Gallery>
              <Item original={img} thumbnail={img} width="auto" height="auto">
                {({ ref, open }) => (
                  <img
                    ref={ref as any}
                    // ref={ref as React.MutableRefObject<HTMLImageElement>}
                    onClick={open}
                    src={img}
                    alt={img}
                  />
                )}
              </Item>
            </Gallery>
          ) : type === "Passport" ? (
            <>
              <span className="upload-id-card-title">
                {t("uploadPassportFrontPage")}
              </span>
              <img
                style={{ width: "35px", height: "35px", opacity: 0.6 }}
                src={UploadIcon}
                alt={"passport-img"}
              />
            </>
          ) : (
            <>
              <span className="upload-id-card-title">{t("uploadIdPage")}</span>
              <img
                style={{ width: "35px", height: "35px", opacity: 0.6 }}
                src={UploadIcon}
                alt={"identity-img"}
              />
            </>
          )}
        </div>
        <div className="operating-btn-wrapper">
          {/* {isConfirmed ? (
            <Button className="verify-image-btn">{t("verified")}</Button>
          ) : (
            <Button
              className="other-uploader-btn"
              onClick={
                type === "Passport" ? verifyPassport : verifySecondIdentity
              }
            >
              {t("verify")}
            </Button>
          )} */}
          {confirmedStatus === "Passed" ? (
            <Button disabled className="verify-image-btn">
              {confirmedStatus}
            </Button>
          ) : confirmedStatus === "Pending" ? (
            <Button disabled className="other-uploader-btn">
              {confirmedStatus}
            </Button>
          ) : (
            <Button
              className="other-uploader-btn"
              onClick={
                type === "Passport" ? verifyPassport : verifySecondIdentity
              }
            >
              {t("verify")}
            </Button>
          )}
          <PersonaTemplate
            isOpen={isOpenPersonaModal}
            setIsOpen={setIsOpenPersonaModal}
          />

          {isOpenPersonaModal && (
            <UploadIdentity
              isOpen={isOpenPersonaModal}
              setIsOpen={setIsOpenPersonaModal}
              type={type}
            />
          )}
        </div>
      </div>
    </>
  );
};
