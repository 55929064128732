import { createSlice } from "@reduxjs/toolkit";

export const allProfileSlice = createSlice({
  name: "allProfile",
  initialState: [],
  reducers: {
    allProfile: (state, action) => {
      return (state = action.payload);
    },
    allProfileFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allProfile, allProfileFailure } = allProfileSlice.actions;
export default allProfileSlice.reducer;
