import { createSlice } from "@reduxjs/toolkit";

export const closeAccountReasonsSlice = createSlice({
  name: "closeAccountReasons",
  initialState: [],
  reducers: {
    closeAccountReasons: (state, action) => {
      return (state = action.payload);
    },
    closeAccountReasonsFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { closeAccountReasons, closeAccountReasonsFailure } = closeAccountReasonsSlice.actions;
export default closeAccountReasonsSlice.reducer;
