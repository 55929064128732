import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { RightSidebar } from "layers/sidebar";
import Instagram from "../../assets/images/instagram.png";
import Facebook from "../../assets/images/facebook.png";
import Linkedin from "../../assets/images/linkedin.png";

export const Footer: React.FC = () => {
  const size = UseWindowSize();
  const { t } = useTranslation();
  const [showContactSidebar, setShowContactSidebar] = useState(false);
  const navigate = useNavigate();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const handleOpenContact = () => {
    setShowContactSidebar(!showContactSidebar);
    navigate("/contactUS");
  };

  return (
    <>
      <div className="main-footer">
        <div>
          <Row className="mt-5 mb-5">
            <Col lg={4} md={4} sm={12}>
              <div className="links-footer-wrapper">
                <h3>{t("usefulLinks")}</h3>
                <ol>
                  <li>
                    <a href="/vision-mission">
                      <span className="bullet-icon" />
                      {t("ourVisionAndMission")}
                    </a>
                  </li>
                  <li>
                    <a href="/howitworks">
                      <span className="bullet-icon" />
                      {t("howItWorks")}
                    </a>
                  </li>
                  <li>
                    <a href="/founder-profile">
                      <span className="bullet-icon" />
                      {t("boardOfDirectors")}
                    </a>
                  </li>
                </ol>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div className="links-footer-wrapper">
                <h3>
                  <a href="/aboutUs">{t("aboutUs")}</a>
                </h3>
                <ol>
                  <li>
                    <a href="/services">
                      <span className="bullet-icon" />
                      {t("ourServices")}
                    </a>
                  </li>
                </ol>
                <h3>
                  <a onClick={handleOpenContact}>{t("contact")}</a>
                </h3>

                <span
                  className={`bullet-icon  ${
                    isPersian ? "dir-rtl" : "dir-ltr"
                  }`}
                >
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className={isPersian ? "ml-2" : "mr-2"}
                    style={{ color: "#a9a9a9" }}
                  />{" "}
                  {t("email")}: <span>info@koolbaar.com</span>
                </span>
                <div className={`bullet-icon`}>
                  <span className={isPersian ? "dir-rtl" : "dir-ltr"}>
                    <FontAwesomeIcon
                      icon={faPhone}
                      className={isPersian ? "ml-2" : "mr-2"}
                      style={{ color: "#a9a9a9" }}
                    />{" "}
                    {t("phoneNumber")}:
                  </span>
                  <span className="ltr d-inline-block">1-438-338-9457</span>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <div className="links-footer-wrapper">
                <h3>{t("aboutKoolbaar")}</h3>
                <ol>
                  <li className="last-list-footer">
                    <span>{t("koolbaarDescFooter")} </span>
                  </li>
                </ol>
                <a
                  href="http://instagram.com/_u/koolbaar_farsi/"
                  target="_blank"
                >
                  <img
                    src={Instagram}
                    className="social-icon mt-1"
                    alt="location-img"
                  />
                </a>
                <a>
                  <img
                    src={Facebook}
                    className="social-icon mt-1"
                    alt="location-img"
                  />
                </a>
                <a>
                  <img
                    src={Linkedin}
                    className="social-icon mt-1"
                    alt="location-img"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="main-footer-copyright">
        <Row>
          <Col lg={2} md={2} sm={0}></Col>
          <Col
            lg={4}
            md={4}
            sm={6}
            style={
              size.width < 768 ? { lineHeight: 1.71 } : { lineHeight: "80px" }
            }
          >
            <span>Copyright © 2020 ADV. All rights reserved.</span>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={6}
            style={
              size.width < 768
                ? { lineHeight: 1.71 }
                : isPersian
                ? { lineHeight: "70px" }
                : { lineHeight: "80px" }
            }
          >
            <a className="footer-privacy-title" href="/termsCondition">
              {t("termsAndConditions")}
            </a>
            <a className="footer-privacy-title ml-3" href="/privacyPolicy">
              {t("privacyPolicy")}
            </a>            
          </Col>
          <Col lg={2} md={2} sm={0}></Col>
        </Row>
        {showContactSidebar && (
          <div className="offer-sidebar">
            <RightSidebar
              isOpen={showContactSidebar}
              setIsOpen={setShowContactSidebar}
              sidebarType="contactUs"
            />
          </div>
        )}
      </div>
    </>
  );
};
