import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "layers";
import { ITransaction } from "models/interfaces";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getAllTransactions } from "redux/actions/banks";
import { useTranslation } from "react-i18next";

export const Transactions: React.FC = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const size = UseWindowSize();
  const isMobile = size.width < 768;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllTransactions(currentPage));
  }, [currentPage]);

  const transactionsData: any = useAppSelector(
    (state) => state.allTransactions
  );

  useEffect(() => {
    if (transactionsData?.isSuccess) {
      if (currentPage === 1) {
        setTransactions(transactionsData.data);
      } else {
        setTransactions((prevTransactions) => [
          ...prevTransactions,
          ...transactionsData.data,
        ]);
      }
      if (transactionsData.data.length < 5) {
        setHasMoreData(false);
      }
    }
  }, [transactionsData]);

  return (
    <>
      {transactions?.length !== 0 ? (
        <div className="transaction-wrapper">
          <div className="mb-3 d-flex align-item-center">
            <h2 className="d-inline mb-0">{t("transactions")}</h2>
            <Button className="view-all-btn ml-auto" href="/transaction">
              {t("viewAll")}
            </Button>
          </div>
          {transactions?.map((item, index) => {
            return (
              <div key={index} className="transaction-box">
                <Row>
                  {/* <Col xs={isMobile ? 3 : 2} className="pr-0">
                    <div className="icon-transaction-wrapper">
                    <img src={item?.imageUrl} alt="shopping-bag-img" />
                    </div>
                  </Col> */}
                  <Col xs={12}>
                    <Row>
                      <Col xs={7} className="pl-2">
                        <span className="transaction-name d-block">
                          {item?.trxId}
                        </span>
                        <span className="transaction-date">
                          {item?.insertTime}
                        </span>
                      </Col>
                      <Col xs={5} className="transaction-amount-wrapper">
                        <span>${item?.amount}</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          })}
          {hasMoreData && (
            <Button
              className="transaction-load-more-btn"
              onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
            >
              {t("loadMore")}
            </Button>
          )}
        </div>
      ) : (
        <div className="no-data text-center mt-5">{t("noData")}</div>
      )}
    </>
  );
};
