import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { useTranslation } from "react-i18next";
import { getAllCurrencies } from "redux/actions/types";
import { useAppDispatch } from "redux/store";

export const FounderProfile: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>Founder's Profile</h2>
      </div>
      <div className="flex-grow-1 services-pages-wrapper my-5">
        <h3 className="mt-5">Davood Fekret</h3>
        <h4>CEO and Founder</h4>
        <p className="mt-4 mb-2">
          Davood Fekret is the driving force behind Koolbaar, our crowd-shipping
          platform, leveraging extensive expertise in Supply Chain Management to
          shape our operations. With over a decade of experience in this field,
          Mr. Fekret's deep understanding of supply chain dynamics has been
          instrumental in building Koolbaar.
        </p>
        <p className="mt-4 mb-2">
          Davood Fekret's supply chain management expertise is the cornerstone
          of Koolbaar's success. As the CEO and Founder, he leverages his
          extensive knowledge to ensure the seamless and efficient delivery of
          goods, making Koolbaar a trusted solution for businesses and
          individuals.
        </p>
      </div>
      <Footer />
    </div>
  );
};
