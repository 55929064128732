import { createSlice } from "@reduxjs/toolkit";

export const cancellationSlice = createSlice({
  name: "cancellation",
  initialState: [],
  reducers: {
    cancellation: (state, action) => {
      return (state = action.payload);
    },
    cancellationFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { cancellation, cancellationFailure } = cancellationSlice.actions;
export default cancellationSlice.reducer;
