import { createSlice } from "@reduxjs/toolkit";

export const filterByTypeIdSlice = createSlice({
  name: "filterByTypeId",
  initialState: [],
  reducers: {
    filterByTypeId: (state, action) => {
      return (state = action.payload);
    },
    filterByTypeIdFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { filterByTypeId, filterByTypeIdFailure } = filterByTypeIdSlice.actions;
export default filterByTypeIdSlice.reducer;
