import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBank,
  faBell,
  faCertificate,
  faLock,
  faMoneyBill,
  faPassport,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";

interface IProp {
  selectedTab: string;
  onSelectedTab: Function;
}

export const SettingItems: React.FC<IProp> = ({
  selectedTab,
  onSelectedTab,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const isMobile = windowSize.width < 768;
  const isMobileTablet = windowSize.width < 992;

  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");

  const handleTabClick = (tabTitle) => {
    setActiveTab(tabTitle);
    onSelectedTab(tabTitle);
  };

  useEffect(() => {
    if (selectedTab === t("personalInfo") || selectedTab === "personalInfo") {
      setActiveTab(t("personalInfo"));
      navigate("/settings/personalInfo", { state: "personalInfo" });
    } else if (
      selectedTab === t("identityVerification") ||
      selectedTab === "identityVerification"
    ) {
      setActiveTab(t("identityVerification"));
      navigate("/settings/identityVerification", {
        state: "identityVerification",
      });
    } else if (selectedTab === t("security") || selectedTab === "security") {
      setActiveTab(t("security"));
      navigate("/settings/security", { state: "security" });
    } else if (
      selectedTab === t("addBankAccount") ||
      selectedTab === "addBankAccount"
    ) {
      setActiveTab(t("addBankAccount"));
      navigate("/settings/addBankAccount", { state: "addBankAccount" });
    } else if (
      selectedTab === t("transactions") ||
      selectedTab === "transactions"
    ) {
      setActiveTab(t("transactions"));
      navigate("/settings/transactions", { state: "transactions" });
    } else if (
      selectedTab === t("notifications") ||
      selectedTab === "notifications"
    ) {
      setActiveTab(t("notifications"));
      navigate("/settings/notifications", { state: "notifications" });
    }
  }, [selectedTab]);

  // useEffect(() => {
  //   console.log(offersClicked, requestsClicked);
  // }, [offersClicked, requestsClicked]);

  return (
    <div className="setting-tabs-container">
      <div className="tabs-wrapper scrollable-tabs-container">
        <div className="tab-container">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <div
                className={`tab ${
                  activeTab === t("personalInfo") ? "active" : ""
                }`}
                onClick={() => handleTabClick(t("personalInfo"))}
              >
                <FontAwesomeIcon
                  icon={faUserPlus}
                  color={`${
                    activeTab === t("personalInfo") ? "#006ce4" : "#1a1a1a"
                  }`}
                  className="mx-3"
                  style={{ fontSize: "20px" }}
                />
                <div className={`${isPersian ? "text-right" : ""}`}>
                  <h2>{t("personalInfo")}</h2>
                  <span>{t("personalInfoDesc")}</span>
                  {!isMobile && (
                    <a>
                      {t("manage")} {t("personalInfo")}
                    </a>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div
                className={`tab ${
                  activeTab === t("identityVerification") ? "active" : ""
                }`}
                onClick={() => handleTabClick(t("identityVerification"))}
              >
                <FontAwesomeIcon
                  icon={faPassport}
                  style={{ fontSize: "20px" }}
                  color={`${
                    activeTab === t("identityVerification")
                      ? "#006ce4"
                      : "#1a1a1a"
                  }`}
                  className="mx-3"
                />
                <div className={`${isPersian ? "text-right" : ""}`}>
                  <h2>{t("identityVerification")}</h2>
                  <span>{t("identityVerificationDesc")}</span>
                  {!isMobile && (
                    <a>
                      {t("manage")} {t("identityVerification")}
                    </a>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div
                className={`tab ${activeTab === t("security") ? "active" : ""}`}
                onClick={() => handleTabClick(t("security"))}
              >
                <FontAwesomeIcon
                  icon={faLock}
                  style={{ fontSize: "20px" }}
                  color={`${
                    activeTab === t("security") ? "#006ce4" : "#1a1a1a"
                  }`}
                  className="mx-3"
                />
                <div className={`${isPersian ? "text-right" : ""}`}>
                  <h2>{t("security")}</h2>
                  <span>{t("securityDesc")}</span>
                  {!isMobile && (
                    <a>
                      {t("manage")} {t("security")}
                    </a>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div
                className={`tab ${
                  activeTab === t("addBankAccount") ? "active" : ""
                }`}
                onClick={() => handleTabClick(t("addBankAccount"))}
              >
                <FontAwesomeIcon
                  icon={faBank}
                  color={`${
                    activeTab === t("addBankAccount") ? "#006ce4" : "#1a1a1a"
                  }`}
                  style={{ fontSize: "20px" }}
                  className="mx-3"
                />
                <div className={`${isPersian ? "text-right" : ""}`}>
                  <h2>{t("addBankAccount")}</h2>
                  <span>{t("addBankAccountDesc")}</span>
                  {!isMobile && (
                    <a>
                      {t("manage")} {t("addBankAccount")}
                    </a>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div
                className={`tab ${
                  activeTab === t("transactions") ? "active" : ""
                }`}
                onClick={() => handleTabClick(t("transactions"))}
              >
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  color={`${
                    activeTab === t("transactions") ? "#006ce4" : "#1a1a1a"
                  }`}
                  style={{ fontSize: "20px" }}
                  className="mx-3"
                />
                <div className={`${isPersian ? "text-right" : ""}`}>
                  <h2>{t("transactions")}</h2>
                  <span>{t("transactionDesc")}</span>
                  {!isMobile && (
                    <a>
                      {t("manage")} {t("transactions")}
                    </a>
                  )}
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <div
                className={`tab ${
                  activeTab === t("notifications") ? "active" : ""
                }`}
                onClick={() => handleTabClick(t("notifications"))}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  color={`${
                    activeTab === t("notifications") ? "#006ce4" : "#1a1a1a"
                  }`}
                  style={{ fontSize: "20px" }}
                  className="mx-3"
                />
                <div className={`${isPersian ? "text-right" : ""}`}>
                  <h2>{t("notifications")}</h2>
                  <span>{t("notificationDesc")}</span>
                  {!isMobile && (
                    <a>
                      {t("manage")} {t("notifications")}
                    </a>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
