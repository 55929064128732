import { createSlice } from "@reduxjs/toolkit";

export const retrieveSlice = createSlice({
  name: "retrieve",
  initialState: [],
  reducers: {
    retrieve: (state, action) => {
      return (state = action.payload);
    },
    retrieveFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { retrieve, retrieveFailure } = retrieveSlice.actions;
export default retrieveSlice.reducer;
