import { createSlice } from "@reduxjs/toolkit";

export const forgetPassSlice = createSlice({
  name: "forgetPass",
  initialState: [],
  reducers: {
    forgetPass: (state, action) => {
      return (state = action.payload);
    },
    forgetPassFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { forgetPass, forgetPassFailure } = forgetPassSlice.actions;
export default forgetPassSlice.reducer;
