import { createSlice } from "@reduxjs/toolkit";

export const offerProblemSlice = createSlice({
  name: "offerProblem",
  initialState: [],
  reducers: {
    offerProblem: (state, action) => {
      return (state = action.payload);
    },
    offerProblemFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { offerProblem, offerProblemFailure } =
offerProblemSlice.actions;
export default offerProblemSlice.reducer;
