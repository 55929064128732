import { createSlice } from "@reduxjs/toolkit";

export const allOfferStatusSlice = createSlice({
  name: "allOfferStatus",
  initialState: [],
  reducers: {
    allOfferStatus: (state, action) => {
      return (state = action.payload.data);
    },
    allOfferStatusFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allOfferStatus, allOfferStatusFailure } =
allOfferStatusSlice.actions;
export default allOfferStatusSlice.reducer;
