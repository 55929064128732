import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Image,
  Badge,
  Modal,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import TimeAgo from "timeago-react";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getAllRooms, getMultiChatByTypeId } from "redux/actions/chat";
import { IChatData, Message, User } from "models/interfaces";
import MultiChatList from "components/multiChatList";
import NoMessage from "assets/images/no-message-2.png";
import { getUserInfo } from "redux/actions/Authorization";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
}

const MultiUserChat: React.FC<IProps> = ({ isOpen, setIsOpen }) => {
  const handleClose = () => setIsOpen(false);
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const isMobile = windowSize.width < 768;
  const [typeId, setTypeId] = useState<string>("");
  const [packageData, setPackageData] = useState<User>();
  const [newMessage, setNewMessage] = useState<string>("");
  const [roomId, setRoomId] = useState<string>("");
  const [latestMessages, setLatestMessages] = useState<Message[]>();
  const [showChatList, setShowChatList] = useState<boolean>(true);
  const [showTyping, setShowTyping] = useState<boolean>(false);
  const [newMsg, setNewMsg] = useState<Message>();
  const chatByTypeId: any = useAppSelector((state) => state.chatByTypeId);
  const allRooms: any = useAppSelector((state) => state.getRooms);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const chatWrapper = document.getElementById("chat-wrapper");
  const [clickedChatIds, setClickedChatIds] = useState<Set<string>>(new Set());

  useEffect(() => {
    if (isMobile) setShowChatList(false);
  }, [isMobile]);

  useEffect(() => {
    setLatestMessages([]);
    dispatch(getAllRooms());
  }, [isOpen]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    if (allRooms?.data?.length !== 0) setUsers(allRooms.data);
  }, [allRooms]);

  const onMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const handleBack = () => {
    setShowChatList(false);
  };

  const handleUserClick = (user: User) => {
    if (user) {
      if (isMobile) setShowChatList(true);
      dispatch(getMultiChatByTypeId(user?.typeId, user?.roomId));
      setTypeId(user?.typeId);
      setSelectedUser(user);
      setShowTyping(true);
      if (chatWrapper) {
        chatWrapper.scrollTop = chatWrapper.scrollHeight;
      }
      setPackageData(user);
      setClickedChatIds((prev) => new Set(prev).add(user.typeId)); // Add clicked user typeId to the set
    }
  };

  useEffect(() => {
    if (chatByTypeId?.data?.length !== 0) {
      setLatestMessages(chatByTypeId?.data?.messages);
      setRoomId(chatByTypeId?.data?.roomdId);
    }
  }, [chatByTypeId]);

  const noMessage = () => {
    return (
      <Col
        sm={12}
        md={12}
        lg={7}
        className={`chat-window-bg ${isMobile ? "pt-2" : "pt-5"}`}
      >
        {" "}
        <div className="chat-window text-center">
          <img
            width="300px"
            className="pt-3"
            src={NoMessage}
            alt="no-message"
          />
          <p className="no-message-yet">No Message Yet</p>
        </div>
      </Col>
    );
  };

  const renderChatList = () => {
    return (
      <MultiChatList
        typeId={typeId}
        showTyping={showTyping}
        latestMessages={latestMessages}
        roomId={roomId}
        packageData={packageData}
      />
    );
  };

  const renderUserList = () => {
    return (
      <Col
        sm={12}
        md={12}
        lg={5}
        className={`${isMobile ? "pl-2" : "pl-5"} pt-5 pr-2`}
      >
        {!isMobile && (
          <a onClick={handleClose}>
            <FontAwesomeIcon icon={faClose} />
          </a>
        )}

        <ListGroup>
          <ListGroup.Item>
            {users?.length !== 0 &&
              users?.map((user) => (
                <>
                  <Row
                    key={user?.typeId}
                    className="user-left-chat-box cursor-pointer"
                    onClick={() => handleUserClick(user)}
                  >
                    <Col xs={3} className="position-relative">
                      <Image
                        src={user?.userImageUrl}
                        roundedCircle
                        className="avatar"
                      />
                      {user?.isOnline && (
                        <span className="showing-online"></span>
                      )}
                    </Col>
                    <Col xs={6} className="align-self-center">
                      <span className="chat-full-name">{user?.fullName}</span>
                      <span className="chat-user-name py-1">
                        {user?.title.substring(0, 13)}
                      </span>
                      <span className="chat-user-last-text">
                        {user?.lastMessage.substr(0, 50)}
                      </span>
                    </Col>
                    <Col
                      xs={3}
                      className="align-self-center p-0 pr-2 position-relative"
                    >
                      <TimeAgo
                        className="timeago-text"
                        datetime={user?.lastMessageDate}
                        locale={isPersian ? "fa_IR" : "en-US"}
                      />
                      {user?.hasNewMessage &&
                        !clickedChatIds.has(user.typeId) && (
                          <Badge variant="primary" className="unread-badge">
                            {user?.hasNewMessage && 1}
                          </Badge>
                        )}
                    </Col>
                  </Row>
                </>
              ))}
          </ListGroup.Item>
        </ListGroup>
      </Col>
    );
  };

  return (
    <Modal
      className="multi-chathub-modal-wrapper"
      show={isOpen}
      backdrop="static"
      onHide={handleClose}
    >
      <Modal.Body>
        <div className="chat-socket-container">
          {isMobile &&
            (showChatList ? (
              <a onClick={handleBack}>
                <FontAwesomeIcon icon={faChevronLeft} /> Back
              </a>
            ) : (
              <a onClick={handleClose}>
                <FontAwesomeIcon icon={faClose} />
              </a>
            ))}
          <div className="chat-container">
            <Row>
              {isMobile ? !showChatList && renderUserList() : renderUserList()}
              {showChatList && latestMessages
                ? renderChatList()
                : !isMobile && noMessage()}
            </Row>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MultiUserChat;
