import { createSlice } from "@reduxjs/toolkit";

export const searchFlightSlice = createSlice({
  name: "searchFlight",
  initialState: [],
  reducers: {
    searchFlight: (state, action) => {
      return (state = action.payload.data);
    },
    searchFlightFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { searchFlight, searchFlightFailure } = searchFlightSlice.actions;
export default searchFlightSlice.reducer;
