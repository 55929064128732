import { BaseService } from "./baseService";

class BanksService extends BaseService {
  getAllGateways() {
    return this.authorizedHttp().get("/user/Gateway/All");
  }

  getAllTransactions(page) {
    return this.authorizedHttp().get(`/User/Transaction/${page}`);
  }

  addNewBankAccount(data) {
    return this.authorizedHttp().post("/user/BankAccount/New", data);
  }

  getAllBankAccounts() {
    return this.authorizedHttp().get("/user/BankAccount/All");
  }

  editBankAccount(data) {
    return this.authorizedHttp().put("/user/BankAccount/Edit", data);
  }

  deleteBankAccount(id) {
    return this.authorizedHttp().delete(`/user/BankAccount/DelById/${id}`);
  }
}

export default new BanksService();
