import { createSlice } from "@reduxjs/toolkit";

export const filterHomeRequestSearchSlice = createSlice({
  name: "filterHomeRequestSearch",
  initialState: [],
  reducers: {
    filterHomeRequestSearch: (state, action) => {
      return (state = action.payload);
    },
    filterHomeRequestSearchFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { filterHomeRequestSearch, filterHomeRequestSearchFailure } =
  filterHomeRequestSearchSlice.actions;

export default filterHomeRequestSearchSlice.reducer;
