import React, { useState, useEffect, useRef } from "react";
import {
  Login,
  AcceptOffer,
  ReportProblem,
  SendRequest,
  AddPackage,
  AddTravel,
  StripePayment,
  AddAccount,
  SetAlert,
  AddEditTravel,
  AddEditPackage,
  ReviewProfile,
  DashboardReviewProfile,
  WriteReview,
  Cancellation,
  Disapproval,
  ContactUs,
} from "components";
import { slide as Menu } from "react-burger-menu";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { IRequest, IAccount, IAddTravel, ICheckout } from "models/interfaces";
import { checkTokenValidation } from "redux/actions/Authorization";
import { useAppDispatch, useAppSelector } from "redux/store";
import { useNavigate } from "react-router-dom";
import { ForgetPassword } from "components/forgetPassword";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
  setIsLogin?: Function;
  setIsOpenConfirm?: Function;
  setCloseConfirm?: Function;
  sidebarType: string;
  data?: IRequest;
  reviewData?: any;
  acceptData?: IAddTravel;
  accountData?: IAccount;
  mode?: string;
  pkgId?: string;
  trvId?: string;
  id?: string;
  owner?: string;
  userName?: string;
  type?: string;
  price?: number;
  reviewFor?: string;
  titleTab?: string;
  isLogin?: boolean;
  checkoutData?: ICheckout;
}

export const RightSidebar: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  sidebarType,
  setIsLogin,
  setIsOpenConfirm,
  data,
  mode,
  trvId,
  pkgId,
  id,
  owner,
  reviewFor,
  reviewData,
  accountData,
  type,
  price,
  titleTab,
  checkoutData,
  setCloseConfirm,
}) => {
  const size = UseWindowSize();
  const dispatch = useAppDispatch();
  let token = window.localStorage.getItem("token");
  // const [isLogin, setIsLogon] = useState(null);
  const [isLogon, setIsLogon] = useState(true);
  const [hasVibration, setHasVibration] = useState(false);
  const [showReuse, setShowReuse] = useState(false);
  const [sidebar, setSidebar] = useState(sidebarType);
  const isValidToken = useAppSelector<any>((state) => state.validationToken);
  const navigate = useNavigate();

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen, setIsOpen]);

  // let token;

  // window.addEventListener("storage", () => {
  //   token = localStorage.getItem("token");
  //   console.log(token);
  // });

  window.addEventListener("storage", () => {
    window.localStorage.setItem("token", window.localStorage.getItem("token"));
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogon(false);
    else setIsLogon(true);
  });

  useEffect(() => {
    if (isOpen === true) {
      const data = {
        accessToken: token,
      };
      // dispatch(checkTokenValidation(data));
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }, [isOpen]);

  // useEffect(() => {
  //   if (isValidToken?.isSuccess === true) setIsLogon(true);
  //   else if (isValidToken?.isSuccess === false) {
  //     setIsLogon(false);
  //     setSidebar("login");
  //   }
  // }, [isValidToken]);

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          if (
            sidebarType === "addTravel" ||
            sidebarType === "addPackage" ||
            sidebarType === "sendRequest" ||
            sidebarType === "acceptOffer" ||
            sidebarType === "dashboardTravel" ||
            sidebarType === "dashboardPackage"
          ) {
            document.documentElement.style.overflow = "hidden";
            setHasVibration(true);
            setTimeout(() => {
              setHasVibration(false);
            }, 300);
          } else {
            document.documentElement.style.overflow = "auto";
            setIsOpen(false);
          }
        }
      },
      false
    );
  }, []);

  const handleReuse = (e) => {
    setShowReuse(e);
  };
  const sidebarRef = useRef<HTMLDivElement>(null);

  return (
    <Menu
      className={`${hasVibration && "vibrate"} `}
      ref={sidebarRef}
      right
      disableOverlayClick={
        sidebarType === "addTravel" ||
        sidebarType === "addPackage" ||
        sidebarType === "sendRequest" ||
        sidebarType === "acceptOffer" ||
        sidebarType === "dashboardTravel" ||
        sidebarType === "dashboardPackage"
      }
      width={
        sidebarType === "alert" ||
        sidebarType === "review" ||
        sidebarType === "dashboardReview"
          ? size.width < 768
            ? "100%"
            : "508px"
          : size.width < 768
          ? "100%"
          : // : showReuse
            // ? "508px"
            "657px"
      }
      isOpen={isOpen}
      onOpen={setIsOpen}
      sidebar="login"
    >
      {sidebar === "login" ? (
        // !isLogin && (
        <Login setIsOpen={setIsOpen} setIsLogin={setIsLogin} isOpen={isOpen} />
      ) : // )
      sidebar === "requestProblem" || sidebar === "offerProblem" ? (
        isLogon ? (
          <ReportProblem
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            id={id}
            sidebarType={sidebar}
            titleTab={titleTab}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "contactUs" ? (
        isLogon ? (
          <ContactUs
            setIsOpen={setIsOpen}
            id={id}
            type={type}
            isOpen={isOpen}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "cancellation" ? (
        isLogon ? (
          <Cancellation
            setIsOpen={setIsOpen}
            id={id}
            type={type}
            isOpen={isOpen}
            titleTab={titleTab}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "disapproval" ? (
        isLogon ? (
          <Disapproval
            setIsOpenConfirm={setIsOpenConfirm}
            setIsOpen={setIsOpen}
            id={id}
            isOpen={isOpen}
            titleTab={titleTab}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "addTravel" ? (
        isLogon ? (
          // isLogin === false || isLogin === undefined ? (
          <AddTravel
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            onSelectReuse={handleReuse}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "acceptOffer" ? (
        isLogon ? (
          <AcceptOffer
            onSelectReuse={handleReuse}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            data={data}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "sendRequest" ? (
        isLogon ? (
          <SendRequest
            onSelectReuse={handleReuse}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            data={data}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "addPackage" ? (
        isLogon ? (
          // isLogin === false || isLogin === undefined ? (
          <AddPackage
            onSelectReuse={handleReuse}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "dashboardTravel" ? (
        isLogon ? (
          <AddEditTravel
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            trvId={trvId}
            mode={mode}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "dashboardPackage" ? (
        isLogon ? (
          <AddEditPackage
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            pkgId={pkgId}
            mode={mode}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "payment" ? (
        isLogon ? (
          <StripePayment
            titleTab={titleTab}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            checkoutData={checkoutData}
            price={price}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "review" ? (
        isLogon ? (
          <ReviewProfile setIsOpen={setIsOpen} isOpen={isOpen} />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "dashboardReview" ? (
        isLogon ? (
          <DashboardReviewProfile
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            titleTab={titleTab}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "writeReview" ? (
        isLogon ? (
          <WriteReview
            setIsOpen={setIsOpen}
            setCloseConfirm={setCloseConfirm}
            isOpen={isOpen}
            id={id}
            owner={owner}
            reviewFor={reviewFor}
            data={reviewData}
            titleTab={titleTab}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "alert" ? (
        isLogon ? (
          <SetAlert setIsOpen={setIsOpen} isOpen={isOpen} type={type} />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "account" ? (
        isLogon ? (
          <AddAccount
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            data={accountData}
            mode={mode}
          />
        ) : (
          <Login
            setIsOpen={setIsOpen}
            setIsLogin={setIsLogin}
            isOpen={isOpen}
          />
        )
      ) : sidebar === "forgetPassword" ? (
        <ForgetPassword
          setIsOpen={setIsOpen}
          setIsLogin={setIsLogin}
          isOpen={isOpen}
        />
      ) : null}
    </Menu>
  );
};
