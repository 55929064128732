import React from "react";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import LogoImg from "../../assets/images/logo/koolbaar-logo-transparent.png";

export const Logo: React.FC = () => {
  const size = UseWindowSize();

  return (
    <div className="mr-auto">
      <a href="/">
        <img src={LogoImg} alt="logo" className="logo-img" />
      </a>
    </div>
  );
};
