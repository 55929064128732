import { createSlice } from "@reduxjs/toolkit";

export const paymentResultSlice = createSlice({
  name: "paymentResult",
  initialState: [],
  reducers: {
    paymentResult: (state, action) => {
      return (state = action.payload);
    },
    paymentResultFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { paymentResult, paymentResultFailure } = paymentResultSlice.actions;
export default paymentResultSlice.reducer;
