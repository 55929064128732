import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { PersonalInfo, FinancialInfo } from "components";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getUserInfo } from "redux/actions/Authorization";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { ToastContainer } from "react-toastify";
import { getAllCurrencies } from "redux/actions/types";
import FailedImg from "../../assets/images/failed-payment.png";
import SuccessImg from "../../assets/images/success-payment.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const SuccessPayment: React.FC = () => {
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const isMobile = windowSize.width < 768;
  const userData = useAppSelector(
    (state) => state?.userInfo?.data && state?.userInfo?.data[0]?.data
  );
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>{t("myAccount")}</h2>
      </div>
      <div className="flex-grow-1 request-info-wrapper mb-3">
        <Row className="payment-status-wrapper">
          <Col xs={12} sm={12} md={4} lg={4} className="text-right">
            <img
              src={SuccessImg}
              className="status-payment-img"
              alt="status-img"
            />
          </Col>
          <Col
            xs={12}
            sm={12}
            md={8}
            lg={8}
            className={isMobile && "payment-distance-top"}
          >
            <h1 className="status-payment-title">Successful</h1>
            <span className="status-payment-detail">
              Your payment was successful, you can check your email for
              transaction details
            </span>
            <Button variant="primary" className="go-to-dashboard-btn">
              <Link style={{ color: "#fff" }} to={"/dashboard"}>
                Go to dashboard
              </Link>
            </Button>
          </Col>
        </Row>
      </div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
