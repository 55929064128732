import { createSlice } from "@reduxjs/toolkit";

export const allAgreementsStatusSlice = createSlice({
  name: "allAgreementsStatus",
  initialState: [],
  reducers: {
    allAgreementsStatus: (state, action) => {
      return (state = action.payload);
    },
    allAgreementsStatusFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allAgreementsStatus, allAgreementsStatusFailure } = allAgreementsStatusSlice.actions;
export default allAgreementsStatusSlice.reducer;
