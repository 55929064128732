import { createSlice } from "@reduxjs/toolkit";

export const userAllChatSlice = createSlice({
  name: "userAllChat",
  initialState: [],
  reducers: {
    userAllChat: (state, action) => {
      return (state = action.payload);
    },
    userAllChatFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { userAllChat, userAllChatFailure } = userAllChatSlice.actions;
export default userAllChatSlice.reducer;
