import Persona from "persona";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "redux/store";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
}

export const PersonaTemplate: React.FC<IProps> = ({ isOpen, setIsOpen }) => {
  const handleClose = () => setIsOpen(false);
  const addressVerification = useAppSelector<any>(
    (state) => state.addressVerification
  );
  const passportVerification = useAppSelector<any>(
    (state) => state.passportVerification
  );
  const secondIdentityVerification = useAppSelector<any>(
    (state) => state.secondIdentityVerification
  );

  useEffect(() => {
    if (addressVerification?.isSuccess && isOpen) {
      window.open(addressVerification?.data?.redirectUrl, "_blank");
      setIsOpen(false);
    }
  }, [addressVerification]);

  useEffect(() => {
    if (passportVerification?.isSuccess && isOpen) {
      window.open(passportVerification?.data?.redirectUrl, "_blank");
      setIsOpen(false);
    }
  }, [passportVerification]);

  useEffect(() => {
    if (secondIdentityVerification?.isSuccess && isOpen) {
      window.open(secondIdentityVerification?.data?.redirectUrl, "_blank");
      setIsOpen(false);
    }
  }, [secondIdentityVerification]);

  return (
    <>
      <div></div>
    </>
  );
};
