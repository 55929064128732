import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { getAllCurrencies } from "redux/actions/types";
import { useAppDispatch } from "redux/store";

export const PrivacyPolicy: React.FC = () => {
  const dispatch = useAppDispatch();

  const smoothScrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      block: "start",
      behavior: "smooth", // smooth scroll
    });
  };

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>Privacy Policy</h2>
      </div>
      <div className="flex-grow-1 rules-pages-wrapper my-5">
        <p>
          This privacy notice is for Koolbaar Courier Ltd. ('<strong>We</strong>
          ," "<strong>us</strong>," or "<strong>our</strong>"), describes how
          and why we might collect, store, use, and/or share ("
          <strong>process</strong>") your information when you use our services
          ("<strong>Services</strong>
          "), such as when you:
        </p>
        <ul>
          <li>
            - Visit our website at{" "}
            <a href="http://www.koolbaar.com" target="_blank">
              http://www.koolbaar.com
            </a>
            , or any website of ours that links to this privacy notice.
          </li>
          <li>
            - Engage with us in other related ways, including any sales,
            marketing, or events.
          </li>
        </ul>
        <p className="mt-4">
          <strong>Questions or concerns?</strong> Reading this privacy notice
          Will help you understand your privacy rights and choices, if you do
          not agree with our policies and practices, please do not use our
          Services. If you still have any questions or concerns, please contact
          us at <a href="mailto:info@koolbaar.com">info@koolbaar.com</a>
        </p>
        <h1 className="mb-4 mt-5">SUMMARY OF KEY POINTS</h1>
        <h4 className="mb-3 mt-4">
          This summary provides Key points from our privacy notice, but you can
          find out more details about any of these topics by clicking the link
          following each key point or by using our{" "}
          <a onClick={() => smoothScrollTo("content-table")}>
            table of contents
          </a>{" "}
          below to find the section you are looking for.
        </h4>
        <p className="mt-3">
          <strong>What personal information do we process?</strong>
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about personal information you disclose to us.
        </p>
        <p>
          <strong>Do we process any sensitive personal information?</strong> We
          do not process sensitive personal information.
        </p>
        <p>
          <strong>Do we receive any information from third parties?</strong> We
          do not receive any information from third parties.
        </p>
        <p>
          <strong>How do we process your information? </strong>
          We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and
          to comply with law. We may also process your information for other
          purposes with your consent. We process your information only when we
          have a valid legal reason to do so. Learn more about{" "}
          <span style={{ color: "#0070c0" }}>
            how we process your information
          </span>
          .
        </p>
        <p>
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information. Learn more about
          your <span style={{ color: "#0070c0" }}>privacy rights</span>.
        </p>
        <p>
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by visiting{" "}
          <a
            onClick={() => {
              localStorage.setItem("tab", "userInfo");
            }}
            href="https://koolbaar.com/settings"
          >
            https://koolbaar.com/settings
          </a>
          , or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
        <p>
          <strong>
            Want to learn more about what we do with any information we collect?
          </strong>{" "}
          <span style={{ color: "#0070c0" }}>
            Review the privacy notice in full
          </span>
        </p>
        <h1 id="content-table" className="mb-4 mt-5">
          TABLE OF CONTENTS
        </h1>
        <div className="content-table">
          <a onClick={() => smoothScrollTo("information-collect")}>
            1. WHAT INFORMATION DO WE COLLECT?
          </a>
          <a onClick={() => smoothScrollTo("process-information")}>
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </a>
          <a onClick={() => smoothScrollTo("rely-process")}>
            3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
            INFORMATION?
          </a>
          <a onClick={() => smoothScrollTo("share-personal-information")}>
            4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </a>
          <a onClick={() => smoothScrollTo("tracking-technology")}>
            5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          </a>
          <a onClick={() => smoothScrollTo("social-login")}>
            6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
          </a>
          <a onClick={() => smoothScrollTo("keep-information")}>
            7. HOW LONG DO WE KEEP YOUR INFORMATION?
          </a>
          <a onClick={() => smoothScrollTo("safe-information")}>
            8. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </a>
          <a onClick={() => smoothScrollTo("information-minors")}>
            9. DO WE COLLECT INFORMATION FROM MINORS?
          </a>
          <a onClick={() => smoothScrollTo("privacy-rights")}>
            10. WHAT ARE YOUR PRIVACY RIGHTS?
          </a>
          <a onClick={() => smoothScrollTo("do-not-track-features")}>
            11. CONTROLS FOR DO-NOT-TRACK FEATURES
          </a>
          <a
            onClick={() => smoothScrollTo("california-specific-privacy-rights")}
          >
            12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
          <a onClick={() => smoothScrollTo("virginia-specific-privacy-rights")}>
            13. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
          </a>
          <a onClick={() => smoothScrollTo("update-notice")}>
            14. DO WE MAKE UPDATES TO THIS NOTICE?
          </a>
          <a onClick={() => smoothScrollTo("contact-about-notice")}>
            15. HOW CAN you CONTACT US ABOUT THIS NOTICE?
          </a>
          <a onClick={() => smoothScrollTo("update-collect-data")}>
            16. HOW CAN YOU REVIEVE\I UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </a>
        </div>
        <h3 id="information-collect" className="mb-4 mt-5">
          WHAT INFORMATION DO WE COLLECT?
        </h3>
        <h4 className="mb-3 mt-4">Personal information you disclose to us.</h4>
        <p>
          <i>
            <strong>In Short: </strong>146 collects personal information that
            you provide to us.
          </i>
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when you register on the Services, express an interest in obtaining
          information about us or our products and Services, when you
          participate in activities on the Services, or otherwise when you
          contact us.
        </p>
        <p>
          <strong>Personal Information Provided by You.</strong>The personal
          information that we collect depends on the context of your
          interactions with us and the Services, the choices you make, and the
          products and features you use. The personal information we collect may
          include the following:
        </p>
        <ul>
          <li>
            <span className="privacy-bullet-icon" />
            names
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            Phone numbers
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            Mailing addresses
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            Usernames
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            Passwords
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            Debit/Credit card numbers
          </li>
        </ul>
        <p>
          <strong>Sensitive Information.</strong>We do not process sensitive
          information.
        </p>
        <p>
          <strong>Payment Data.</strong> We may collect data necessary to
          process your payment if you make purchases, such as your payment
          instrument number, and the security code associated with your payment
          instrument. All payment data is stored by Stripe. You may find their
          privacy notice link(s) here:{" "}
          <a target="_blank" href="https://stripe.com/en-ca/privacy">
            https://stripe.com/en-ca/privacy
          </a>
          .
        </p>
        <p>
          <strong>Social Media Login Data</strong>. We may provide you with the
          option to register with us using your existing social media account
          details, like your Facebook, Twitter, or other social media account.
          If you choose to register in this way, we will collect the information
          described in the section called{" "}
          <a>"HOW DO WE HANDLE YOUR SOCIAL LOGINS?"</a> below.
        </p>
        <p>
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <h4 className="mb-3 mt-4">Information automatically collected</h4>
        <p>
          <i>
            <strong>In Short:</strong> some information — such as your Internet
            Protocol ('P) address and/or browser and device characteristics — is
            collected automatically when you visit our Services.
          </i>
        </p>
        <p>
          We automatically collect certain information when you visit, use, or
          navigate the Services. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Services, and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Services, and for our internal analytics and reporting
          purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <p>The information we collect includes:</p>
        <ul>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>Location Data</strong>. We collect location data such as
            information about your device's location, which can be either
            precise or imprecise. How much information we collect depends on the
            type and settings of the device you use to access the Services. For
            example, we may use GPS and other technologies to collect
            geolocation data that tells us your current location (based on your
            IP address). You can opt out of allowing us to collect this
            information either by refusing access to the information or by
            disabling your Location setting on your device. However, if you
            choose to opt out, you may not be able to use certain aspects of the
            Services.
          </li>
        </ul>
        <h3 id="process-information" className="mb-4 mt-5">
          HOW DO WE PROCESS YOUR INFORMATION?
        </h3>
        <p>
          <i>
            <strong>In Short:</strong>process your information to provide,
            improve, and administer our Services, communicate with you, for
            security and fraud prevention, and to comply with law. We may also
            process your information for other purposes with your consent.
          </i>
        </p>
        <h4 className="mb-3 mt-4">
          We process your personal information for a variety of reasons,
          depending on how you interact with our Services, including:
        </h4>
        <ul>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>
              To facilitate account creation and authentication and otherwise
              manage user accounts.
            </strong>{" "}
            We may process your information so you can create and log in to your
            account, as well as keep your account in working order.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>
              To deliver and facilitate delivery of services to the user.
            </strong>{" "}
            We may process your information to provide you with the requested
            service.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>
              To respond to user inquiries/offer support to users.
            </strong>{" "}
            We may process your information to respond to your inquiries and
            solve any potential issues you might have with the requested
            service.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>To enable user-to-user communications.</strong> We may
            process your information if you choose to use any of our offerings
            that allow for communication with another user.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>To protect our Services.</strong> We may process your
            information as part of our efforts to keep our Services safe and
            secure, including fraud monitoring and prevention.
          </li>{" "}
          <li>
            <span className="privacy-bullet-icon" />
            <strong>
              To save or protect an individual's vital interest.
            </strong>{" "}
            We may process your information when necessary to save or protect an
            individual's vital interest, such as to prevent harm.
          </li>
        </ul>
        <h3 className="mb-4 mt-5" id="rely-process">
          WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
        </h3>
        <p>
          <i>
            <strong>In Short:</strong> We only process your personal information
            when we believe it is necessary and we have a valid legal reason
            (i.e., legal basis) to do so under applicable law, like with your
            consent, to comply with laws, to provide you with services to enter
            into or fulfill our contractual obligations, to protect your rights,
            or to fulfill our legitimate business interests.
          </i>
        </p>
        <p style={{ textDecoration: "underline" }}>
          <strong>
            If you are located in the EU or UK, this section applies to you.
          </strong>
        </p>
        <p>
          The General Data Protection Regulation (GDPR) and UK GDPR require us
          to explain the valid legal bases we rely on in order to process your
          personal information. As such, we may rely on the following legal
          bases to process your personal information:
        </p>
        <ul>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>Consent.</strong> We may process your information if you
            have given us permission (i.e., consent) to use your personal
            information for a specific purpose. You can withdraw your consent at
            any time. Learn more about <a>withdrawing your consent.</a>
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>Performance of a Contract.</strong> We may process your
            personal information when we believe it is necessary to fulfill our
            contractual obligations to you, including providing our Services or
            at your request prior to entering into a contract with you.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>
              Diagnose problems and/or prevent fraudulent activities.
            </strong>
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>Legal Obligations.</strong> We may process your information
            where we believe it is necessary for compliance with our legal
            obligations, such as to cooperate with a law enforcement body or
            regulatory agency, exercise or defend our legal rights, or disclose
            your information as evidence in litigation in which we are involved.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            <strong>Vital Interests.</strong> We may process your information
            where we believe it is necessary to protect your vital interests or
            the vital interests of a third party, such as situations involving
            potential threats to the safety of any person.
          </li>
        </ul>
        <p style={{ textDecoration: "underline" }}>
          <strong>
            If you are located in Canada, this section applies to you.
          </strong>
        </p>
        <p>
          We may process your information if you have given us specific
          permission (i.e., express consent) to use your personal information
          for a specific purpose, or in situations where your permission can be
          inferred (i.e., implied consent). You can <a>withdraw your consent</a>{" "}
          at any time.
        </p>
        <p>
          In some exceptional cases, we may be legally permitted under
          applicable law to process your information without your consent,
          including, for example:
        </p>
        <ul>
          <li>
            <span className="privacy-bullet-icon" />
            If collection is clearly in the interests of an individual and
            consent cannot be obtained in a timely way
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            For investigations and fraud detection and prevention
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            For business transactions provided certain conditions are met
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            If it is contained in a witness statement and the collection is
            necessary to assess, process, or settle an insurance claim.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            For identifying injured, ill, or deceased persons and communicating
            with next of kin
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            If we have reasonable grounds to believe an individual has been, is,
            or may be victim of financial abuse.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            If it is reasonable to expect collection and use with consent would
            compromise the availability or the accuracy of the information and
            the collection is reasonable for purposes related to investigating a
            breach of an agreement or a contravention of the laws of Canada or a
            province
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            If disclosure is required to comply with a subpoena, warrant, court
            order, or rules of the court relating to the production of records.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            If it was produced by an individual in the course of their
            employment, business, or profession and the collection is consistent
            with the purposes for which the information was produced
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            If the collection is solely for journalistic, artistic, or literary
            purposes.
          </li>
          <li>
            <span className="privacy-bullet-icon" />
            If the information is publicly available and is specified by the
            regulations
          </li>
        </ul>
        <h3 className="mb-4 mt-5" id="share-personal-information">
          WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>may share information in specific
            situations described in this section and/or with the following third
            patties.
          </i>
        </p>
        <p>
          <strong>
            Vendors, Consultants, and Other Third-Party Service Providers.
          </strong>{" "}
          We may share your data with third-party vendors, service providers,
          contractors, or agents (<strong>"third parties"</strong>) who perform
          services for us or on our behalf and require access to such
          information to do that work. We have contracts in place with our third
          parties, which are designed to help safeguard your personal
          information. This means that they cannot do anything with your
          personal information unless we have instructed them to do it. They
          will also not share your personal information with any organization
          apart from us. They also commit to protect the data they hold on our
          behalf and to retain it for the period we instruct. The third parties
          we may share personal information with are as follows:
        </p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <strong>
              Allow Users to Connect to Their Third-Party Accounts
            </strong>
          </li>
          <li>Google account and Facebook account</li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <strong>Invoice and Billing</strong>
          </li>
          <li>Stripe</li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <strong>Web and Mobile Analytics</strong>
          </li>
          <li>Google Analytics</li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <strong>User Authentication Services</strong>
          </li>
          <li>Persona</li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <strong>Communicate & Chat with Users</strong>
          </li>
          <li>Chat Engine</li>
        </ul>
        <p>
          We also may need to share your personal information in the following
          situations:
        </p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <strong>Business Transfers. </strong>We may share or transfer your
            information in connection with, or during negotiations of, any
            merger, sale of company assets, financing, or acquisition of all or
            a portion of our business to another company.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <strong>When we use Google Maps Platform APIs. </strong> We may
            share your information with certain Google Maps Platform APIs (e.g.,
            Google Maps API, Places API). We obtain and store on your device
            ("cache") your location. You may revoke your consent anytime by
            contacting us at the contact details provided at the end of this
            document.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <strong>Other Users. </strong>When you share personal information
            (for example, by posting comments, contributions, or other content
            to the Services) or otherwise interact with public areas of the
            Services, such personal information may be viewed by all users and
            may be publicly made available outside the Services in perpetuity.
            If you interact with other users of our Services and register for
            our Services through a social network (such as Facebook), your
            contacts on the social network will see your name, profile Photo,
            and descriptions of your activity. Similarly, other users will be
            able to view descriptions of your activity, communicate with you
            within our Services, and view your profile.
          </li>
        </ul>

        <h3 className="mb-4 mt-5" id="tracking-technology">
          DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>We may use cookies and other tracking
            technologies to collect and store your information.
          </i>
        </p>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Notice.
        </p>
        <h3 className="mb-4 mt-5" id="social-login">
          HOW DO WE HANDLE YOUR SOCIAL LOGINS?
        </h3>
        <p>
          <i>
            <strong>In Short:</strong>If you choose to register or log in to our
            Services using a social media account, we may have access to certain
            information about you.
          </i>
        </p>
        <p>
          Our Services offer you the ability to register and log in using your
          third-party social media account details (like your Facebook or
          Twitter logins). Where you choose to do this, we will receive certain
          profile information about you from your social media provider. The
          profile information we receive may vary depending on the social media
          provider concerned, but will often include your name, email address,
          friends list, and profile picture, as well as other information you
          choose to make public on such a social media platform.
        </p>
        <p>
          We will use the information we receive only for the purposes that are
          described in this privacy notice or that are otherwise made clear to
          you on the relevant Services. Please note that we do not control, and
          are not responsible for, other uses of your personal information by
          your third-party social media provider. We recommend that you review
          their privacy notices to understand how they collect, use, and share
          your personal information, and how you can set your privacy
          preferences on their sites and apps.
        </p>
        <h3 className="mb-4 mt-5" id="keep-information">
          HOW LONG DO WE KEEP YOUR INFORMATION?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>keep your information for as long as
            necessary to fulfill the purposes outlined in this privacy notice
            unless otherwise required by law.
          </i>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). No purpose in this notice
          will require us to keep your personal information for longer than the
          period of time in which users have an account with us.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h3 className="mb-4 mt-5" id="safe-information">
          HOW DO WE KEEP YOUR INFORMATION SAFE?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>I've aim to protect your personal
            information through a system Of organizational and technical
            security measures.
          </i>
        </p>
        <p>
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <h3 className="mb-4 mt-5" id="information-minors">
          DO WE COLLECT INFORMATION FROM MINORS?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>WC do not knowingly collect data from or
            market to children under 18 years of age.
          </i>
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 or that you are the parent or guardian of such a minor and
          consent to such minor dependent's use of the Services. If we leam that
          personal information from users less than 18 years of age has been
          collected, we will deactivate the account and take reasonable measures
          to promptly delete such data from our records. If you become aware of
          any data we may have collected from children under the age of 18,
          please contact us at{" "}
          <a href="mailto:info@koolbaar.com">info@koolbaar.com</a>.
        </p>

        <h3 className="mb-4 mt-5" id="privacy-rights">
          WHAT ARE YOUR PRIVACY RIGHTS?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>In some regions, such as the European
            Economic Area (EEA), United Kingdom (IJK), and Canada, you have
            rights that allow you greater access to and control over your
            personal information. You may review, change, or terminate your
            account at any time.
          </i>
        </p>
        <p>
          In some regions (like the EEA, UK, and Canada), you have certain
          rights under applicable data protection laws. These may include the
          right (I) to request access and obtain a copy of your personal
          information, (II) to request rectification or erasure; (III) to
          restrict the processing of your personal information; and (IV) if
          applicable, to data portability. In certain circumstances, you may
          also have the right to object to the processing of your personal
          information. You can make such a request by contacting us by using the
          contact details provided in the section{" "}
          <a onClick={() => smoothScrollTo("contact-about-notice")}>
            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
          </a>{" "}
          below.
        </p>
        <p>
          We will consider and act upon any request in accordance with
          applicable data protection laws.
        </p>
        <p>
          If you are located in the EEA or UK and you believe we are unlawfully
          processing your personal information, you also have the right to
          complain to your <a>Member State data protection authority</a> or{" "}
          <a>UK data protection authority</a>.
        </p>
        <p>
          If you are located in Switzerland, you may contact the{" "}
          <a>Federal Data Protection and Information Commissioner</a>.
        </p>
        <p>
          <strong>Withdrawing your consent:</strong> If we are relying on your
          consent to process your personal information, which may be express
          and/or implied consent depending on the applicable law, you have the
          right to withdraw your consent at any time. You can withdraw your
          consent at any time by contacting us by using the contact details
          provided in the section{" "}
          <a onClick={() => smoothScrollTo("contact-about-notice")}>
            "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"
          </a>{" "}
          below.
        </p>
        <p>
          However, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
        </p>
        <h4 className="mb-3 mt-4">Account Information</h4>
        <p>
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Log in to your account settings and update your user account
          </li>
        </ul>
        <p>
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Cookies and similar technologies;
          </strong>{" "}
          Most Web browsers are set to accept cookies by default. If you prefer,
          you can usually choose to set your browser to remove cookies and to
          reject cookies. If you choose to remove cookies or reject cookies,
          this could affect certain features or services of our Services. You
          may also <a>opt out of interest-based advertising by advertisers</a>{" "}
          on our Services.
        </p>
        <p>
          If you have questions or comments about your privacy rights, you may
          email us at <a href="mailto:info@koolbaar.com">info@koolbaar.com</a>.
        </p>
        <h3 className="mb-4 mt-5" id="do-not-track-features">
          CONTROLS FOR DO-NOT-TRACK FEATURES
        </h3>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h3 className="mb-4 mt-5" id="california-specific-privacy-rights">
          DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>Yes, if you are a resident of California,
            you are granted specific rights regarding access to your personal
            information.
          </i>
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The
          Light" law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with Services, you have the right to request
          removal of unwanted data that you publicly post on the Services. To
          request removal of such data, please contact us using the contact
          information provided below and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Services, but
          please be aware that the data may not be completely or comprehensively
          removed from all our systems (e.g., backups, etc.).
        </p>
        <h4 className="mb-3 mt-4">CCPA Privacy Notice</h4>
        <p>The California Code of Regulations defines a •resident" as:</p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            every individual who is in the State of Califomia for other than a
            temporary or transitory purpose and
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            every individual who is domiciled in the State of California who is
            outside the State of Califomia for a temporary or transitory purpose
          </li>
        </ul>
        <p>All other individuals are defined as "non-residents."</p>
        <p>
          If this definition of "resident" applies to you, we must adhere to
          certain rights and obligations regarding your personal information.
        </p>
        <p>
          <strong>
            What categories of personal information do we collect?
          </strong>
        </p>
        <p>
          We have collected the following categories of personal information in
          the past twelve (12) months:
        </p>
        <Table striped bordered hover className="privacy-page-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Examples</th>
              <th>Collected</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A. Identifiers</td>
              <td>
                Contact details, such as real name, alias, postal address,
                telephone or mobile contact number, unique personal identifier,
                online identifier, Internet Protocol address, email address, and
                account name
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                B. Personal information categories listed in the California
                Customer Records statute
              </td>
              <td>
                Name, contact information, education, employment, employment
                history, and financial information
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>
                C. Protected classification characteristics under California or
                federal law
              </td>
              <td>Gender and date of birth</td>
              <td>NO</td>
            </tr>
            <tr>
              <td>D. Commercial information</td>
              <td>
                Transaction information, purchase history, financial details,
                and payment information
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>E. Biometric information</td>
              <td>Fingerprints and voiceprints</td>
              <td></td>
            </tr>
            <tr>
              <td>F. Internet or other similar network activity</td>
              <td>
                Browsing history, search history, online behavior, interest
                data, and interactions with our and other websites,
                applications, Systems, and advertisements
              </td>
              <td></td>
            </tr>
            <tr>
              <td>G. Geolocation data</td>
              <td>Device location</td>
              <td></td>
            </tr>
            <tr>
              <td>
                H. Audio, electronic, visual, thermal, olfactory, or similar
                information
              </td>
              <td>
                Images and audio, video or call recordings created in connection
                with our business activities
              </td>
              <td></td>
            </tr>
            <tr>
              <td>I. Professional or employment-related information</td>
              <td>
                Business contact details in order to provide you our services at
                a business level or job title, work history, and professional
                qualifications it you apply for a job with us
              </td>
              <td></td>
            </tr>
            <tr>
              <td>J. Education Information</td>
              <td>Student records and directory information</td>
              <td></td>
            </tr>
            <tr>
              <td>K. Inferences drawn from other personal information</td>
              <td>
                Inferences drawn from any of the collected personal information
                listed above to create a profile or summary about, for example,
                an individual's preferences and characteristics
              </td>
              <td>YES</td>
            </tr>
            <tr>
              <td>L. Sensitive Personal Information</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <p className="mt-4">
          We will use and retain the collected personal information as needed to
          provide the Services or for:
        </p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Category A - As long as the user has an account with us
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Category B - As long as the user has an account with us Category D -
            As long as the user has an account with us.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Category K - As long as the user has an account with us
          </li>
        </ul>
        <p>
          We may also collect other personal information outside of these
          categories through instances where you interact with us in person,
          online, or by phone or mail in the context of:
        </p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Receiving help through our customer support channels.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Participation in customer surveys or contests; and
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Facilitation in the delivery of our Services and to respond to your
            inquiries.
          </li>
        </ul>
        <h4 className="mb-3 mt-4">
          How do we use and share your personal information?
        </h4>
        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>
        <p>
          You may contact us by email at{" "}
          <a href="mailto:info@koolbaar.com">info@koolbaar.com</a>, or by
          referring to the contact details at the bottom of this document.
        </p>
        <p>
          If you are using an authorized agent to exercise your right to opt
          out, we may deny a request if the authorized agent does not submit
          proof that they have been validly authorized to act on your behalf.
        </p>
        <h4 className="mb-3 mt-4">
          Will your information be shared with anyone else?
        </h4>
        <p>
          We may disclose your personal information with our service providers
          pursuant to a written contract between us and each service provider.
          Each service provider is a for-profit entity that processes the
          information on our behalf, following the same strict privacy
          protection obligations mandated by the CCPA.
        </p>
        <p>
          We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological
          development and demonstration. This is not considered to be the
          “selling" of your personal information.
        </p>
        <p>
          We have not sold or shared any personal information to third parties
          for a business or commercial purpose in the preceding twelve (12)
          months. We have disclosed the following categories of personal
          information to third parties for a business or commercial purpose in
          the preceding twelve (12) months:
        </p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Category A. Identifiers, such as contact details like your real
            name, alias, postal address, telephone or mobile contact number,
            unique personal identifier, online identifier, Internet Protocol
            address, email address, and account name.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Category B. Personal Information, as defined in the California
            Customer Records law, such as your name, contact information,
            education, employment, employment history, and financial
            information.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Category D. Commercial information, such as transaction information,
            purchase history, financial details, and payment information.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Category K. Inferences drawn from any of the personal information
            listed above to create a profile or summary about, for example, an
            individual's preferences and characteristics.
          </li>
        </ul>
        <p>
          The categories of third parties to whom we disclosed personal
          information for a business or commercial purpose can be found under
          <a onClick={() => smoothScrollTo("share-personal-information")}>
            "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?"
          </a>
          .
        </p>
        <h4 className="mb-3 mt-4">
          Your rights with respect to your personal data
        </h4>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Right to request deletion of data ---Request to delete
          </strong>
        </p>
        <p>
          You can ask for the deletion of your personal information. If you ask
          us to delete your personal information, we will respect your request
          and delete your personal information, subject to certain exceptions
          provided by law, such as (but not limited to) the exercise by another
          consumer of his or her right to free speech, our compliance
          requirements resulting from a legal obligation, or any processing that
          may be required to protect against illegal activities.
        </p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Right to be informed --- Request to know
          </strong>
        </p>
        <p>Depending on the circumstances, you have a right to know.</p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            whether we collect and use your personal information; the categories
            of personal information that we collect.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            the purposes for which the collected personal information is used.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            whether we sell or share personal information to third parties.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            the categories of personal information that we sold, shared, or
            disclosed for a business purpose.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            the categories of third parties to whom the personal information was
            sold, shared, or disclosed for a business purpose.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            the business or commercial purpose for collecting, selling, or
            sharing personal information; and
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            the specific pieces of personal information we collected about you.
          </li>
        </ul>
        <p>
          In accordance with applicable law, we are not obligated to provide or
          delete consumer information that is de-identified in response to a
          consumer request or to re-identify individual data to verify a
          consumer request.
        </p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Right to Non-Discrimination for the exercise of a consumer’s Privacy
            Rights
          </strong>
        </p>
        <p>
          We will not discriminate against you if you exercise your privacy
          rights.
        </p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Right to Limit use and Disclosure of Sensitive Personal Information
          </strong>
        </p>
        <p>We do not process consumer's sensitive personal information.</p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            verification process
          </strong>
        </p>
        <p>
          Upon receiving your request, we will need to verify your identity to
          determine you are the same person about whom we have the information
          in our system. These verification efforts require us to ask you to
          provide information so that we can match it with information you have
          previously provided us. For instance, depending on the type of request
          you submit, we may ask you to provide certain information so that we
          can match the information you provide with the information we already
          have on file, or we may contact you through a communication meth0d
          (e.g., Phone or email) that you have previously provided to us. We may
          also use other verification methods as the circumstances dictate.
        </p>
        <p>
          We will only use personal information provided in your request to
          verify your identity or authority to make the request. To the extent
          possible, we will avoid requesting additional information from you for
          the purposes of verification. However, if we cannot verify your
          identity from the information already maintained by us, we may request
          that you provide additional information for the purposes of verifying
          your identity and for security or fraud-prevention purposes. We will
          delete such additionally provided information as soon as we finish
          verifying you.
        </p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Other Privacy Rights
          </strong>
        </p>

        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            You may object to the processing of your personal information.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            You may request correction of your personal data if it is incorrect
            or no longer relevant or ask to restrict the processing of the
            information.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            You can designate an authorized agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorized agent
            that does not submit proof that they have been validly authorized to
            act on your behalf in accordance with the CCPA.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            You may request to opt out from future selling or sharing of your
            personal information to third parties, upon receiving an opt- out
            request, we will act upon the request as soon as feasibly possible,
            but no later than fifteen (15) days from the date of the request
            submission.
          </li>
        </ul>
        <p>
          To exercise these rights, you can contact us by email at
          <a href="mailto:info@koolbaar.com">info@koolbaar.com</a>, or by
          referring to the contact details at the bottom of this document. If
          you have a complaint about how we handle your data, we would like to
          hear from you.
        </p>
        <h3 className="mb-4 mt-5" id="virginia-specific-privacy-rights">
          DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>Yes, if you are a resident of Virginia,
            you may be granted specific rights regarding access to and use of
            your personal information.
          </i>
        </p>
        <h4 className="mb-3 mt-4">Virginia CDPA Privacy Notice</h4>
        <p>Under the Virginia Consumer Data Protection Act (CDPA):</p>
        <p>
          "Consumer" means a natural person who is a resident of the
          Commonwealth acting only in an individual or household context. It
          does not include a natural person acting in a commercial or employment
          context.
        </p>
        <p>
          "Personal data" means any information that is linked or reasonably
          linkable to an identified or identifiable natural person. "Personal
          data" does not include de-identified data or publicly available
          information.
        </p>
        <p>
          "Sale of personal data" means the exchange of personal data for
          monetary consideration.
        </p>
        <p>
          If this definition "consumer" applies to you, we must adhere to
          certain rights and obligations regarding your personal data.
        </p>
        <p>
          The information we collect, use, and disclose about you will vary
          depending on how you interact with us and our Services. To find out
          more, please visit the following links:
        </p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <a>Personal data we collect</a>
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <a>How we use your personal data</a>
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            <a>When and with whom we share your personal data</a>
          </li>
        </ul>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Your rights with respect to your-personal-data
          </strong>
        </p>
        <ul>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Right to be informed whether or not we are processing your personal
            data.
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Right to access your personal data
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Right to correct inaccuracies in your personal data
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Right to request deletion of your personal data
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Right to obtain a copy of the personal data you previously shared
            with us
          </li>
          <li className="mt-3">
            <span className="privacy-bullet-icon" />
            Right to opt out of the processing of your personal data if it is
            used for targeted advertising, the sale of personal data, or
            profiling in furtherance of decisions that produce legal or
            similarly significant effects ("profiling")
          </li>
        </ul>
        <p>
          We have not sold any personal data to third parties for business or
          commercial purposes. We will not sell personal data in the future
          belonging to website visitors, users, and other consumers.
        </p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Exercise your right provided under the Viginia CDPA
          </strong>
        </p>
        <p>
          More information about our data collection and sharing practices can
          be found in this privacy notice.
        </p>
        <p>
          You may contact us by email at{" "}
          <a
            onClick={() => {
              localStorage.setItem("tab", "userInfo");
            }}
            href="mailto:info@koolbaar.com"
          >
            info@koolbaar.com
          </a>
          , by visiting https://koolbaar.com/settings, or by referring to the
          contact details at the bottom of this document.
        </p>
        <p>
          If you are using an authorized agent to exercise your rights, we may
          deny a request if the authorized agent does not submit proof that they
          have been validly authorized to act on your behalf.
        </p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Verification process
          </strong>
        </p>
        <p>
          We may request that you provide additional information reasonably
          necessary to verify you and your consumer's request. If you submit the
          request through an authorized agent, we may need to collect additional
          information to verify your identity before processing your request.
        </p>
        <p>
          Upon receiving your request, we will respond without undue delay, but
          in all cases, within forty-five (45) days of receipt. The response
          period may be extended once by forty-five (45) additional days when
          reasonably necessary. We will inform you of any such extension within
          the initial 45-day response period, together with the reason for the
          extension.
        </p>
        <p>
          <strong style={{ textDecoration: "underline" }}>
            Right to appeal
          </strong>
        </p>
        <p>
          If we decline to take action regarding your request, we will inform
          you of our decision and the reasoning behind it. If you wish to appeal
          our decision, please email us at{" "}
          <a href="mailto:info@koolbaar.com">info@koolbaar.com</a>. Within sixty
          (60) days of receipt of an appeal, we will inform you in writing of
          any action taken or not taken in response to the appeal, including a
          written explanation of the reasons for the decisions. If your appeal
          if denied, you may contact the Attorney General to submit a complaint.
        </p>

        <h3 className="mb-4 mt-5" id="update-notice">
          DO WE MAKE UPDATES TO THIS NOTICE?
        </h3>
        <p>
          <i>
            <strong>In Short: </strong>Yes, we will update this notice as
            necessary to stay compliant with relevant laws.
          </i>
        </p>
        <p>
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </p>
        <h3 className="mb-4 mt-5" id="contact-about-notice">
          HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
        </h3>
        <p>
          If you have questions or comments about this notice, you may email us
          at <a href="mailto:info@koolbaar.com">info@koolbaar.com</a> or contact
          us by post at:
        </p>
        <p>Koolbaar Courier Ltd. 3415 AV. Girouard apt</p>
        <p>402 Montreal, Quebec H4A 3C6 Canada</p>
        <h3 className="mb-4 mt-5" id="update-collect-data">
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
        </h3>
        <p>
          Based on the applicable laws of your country, you may have the right
          to request access to the personal information we collect from you,
          change that information, or delete it. To request to review, update,
          or delete your personal information, please visit:{" "}
          <a
            onClick={() => {
              localStorage.setItem("tab", "userInfo");
            }}
            href="https://koolbaar.com/settings"
          >
            https://koolbaar.com/settings
          </a>
          .
        </p>
      </div>
      <Footer />
    </div>
  );
};
