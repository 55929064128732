/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IOfferSent } from "models/interfaces";
import { getOfferTimeline } from "redux/actions/dashboard";
import { useAppDispatch, useAppSelector } from "redux/store";
import { RightSidebar } from "layers";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProp {
  setShowConfirmDenyDetail: (key: any) => void;
  fade: boolean;
  reviewId: string;
  reviewOwner: string;
  titleTab: string;
  isOpenDisapproval: boolean;
  setIsDenyConfirmed: Function;
  setIsOpenDisapproval: (key: any) => void;
}
export const DenyConfirm: React.FC<IProp> = ({
  setShowConfirmDenyDetail,
  fade,
  reviewId,
  reviewOwner,
  setIsDenyConfirmed,
  setIsOpenDisapproval,
  isOpenDisapproval,
  titleTab,
}) => {
  const [showCover, setShowCover] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleShow = () => {
    setShowCover(!showCover);
    setShowConfirmDenyDetail(showCover);
    setFadeOut(true);
  };

  const confirm = () => {
    setShowConfirmDenyDetail(false);
    setIsDenyConfirmed(true);
    // window.location.reload();
  };

  const notConfirm = () => {
    setIsOpenDisapproval(true);
    navigate("/dashboard/confirm-deny", { state: t(`${titleTab}`) });
    // setShowConfirmDenyDetail(false);
    // window.location.reload();
  };

  return (
    <div className="dashboard-more-detail-wrapper more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`receive-offer-card-wrapper mb-3 review-card-info-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Body className="request-card-border location-cover-info offer-card-cover-body">
          <Row className="detail-review-wrapper">
            <div className="review-question-text text-center">
              <span>Do you want to confirm Delivery/ Posted</span>
            </div>
            <div className="note-offer-detail mt-5 mx-auto">
              <Button
                variant="primary"
                className="confirm-review-btn"
                onClick={confirm}
              >
                {t("confirm")}
              </Button>
              <Button
                variant="white"
                className="no-confirm-review-btn"
                onClick={notConfirm}
              >
                Deny
              </Button>
            </div>
          </Row>
        </Card.Body>
      </Card>
      {isOpenDisapproval && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={isOpenDisapproval}
            setIsOpen={setIsOpenDisapproval}
            sidebarType="disapproval"
            setIsOpenConfirm={setShowConfirmDenyDetail}
            id={reviewId}
            titleTab={titleTab}
          />
        </div>
      )}
    </div>
  );
};
