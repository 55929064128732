import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "layers";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
  type: string;
}

export const FirstMakeYourCard: React.FC<IProps> = ({ isOpen, setIsOpen,type }) => {
  const handleClose = () => setIsOpen(false);
  const { t } = useTranslation();

  const confirmDeleteBtn = () => {
    setIsOpen(false);
  };

  return (
    <>
      <Modal
        className="remove-modal-wrapper"
        show={isOpen}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="close-modal-btn-wrapper">
            <a onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </a>
          </div>
          <Row>
            <Col xs={12}>
              <span className="remove-desc-title">
                {type === "travel" ? t("noTrvAd") : t("noPkgAd")}
              </span>
            </Col>
          </Row>
          <Button
            variant="warning"
            data-test="docs-btn-anchor"
            onClick={confirmDeleteBtn}
            className="confirm-remove-btn"
          >
            {t("confirm")}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
