import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Card, OverlayTrigger } from "react-bootstrap";
import { Button, RightSidebar } from "layers";
import { PackageCover } from "./PackageCover";
import { ShareThisCover } from "./ShareThisCover";
import PlaneIcon from "../../assets/images/plane.png";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import {
  IChatData,
  IChatEngineData,
  IRequest,
  Message,
} from "models/interfaces";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as htmlToImage from "html-to-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShareAlt,
  faPlane,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "redux/store";
import { LoginConfirm } from "components/modals/Login";
import Tooltip from "react-bootstrap/Tooltip";
import { getPackageById } from "redux/actions/landing";
import { FirstMakeYourCard } from "components/modals/FirstMakeYourCard";
import { Chat } from "components/modals/Chat";
import { checkTokenValidation, getUserInfo } from "redux/actions/Authorization";
import { getChatByTypeId, getUserChat } from "redux/actions/chat";
import { SingleChatList } from "components/singleUserChat";
import { toast } from "react-toastify";

interface IProps {
  data: IRequest;
  setShareImage: Function;
}

export const Cards: React.FC<IProps> = ({ data, setShareImage }) => {
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const dispatch = useAppDispatch();
  const [hasAvailableTravel, setHasAvailableTravel] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const [fade, setFade] = useState(false);
  const [isShowShareThis, setIsShowShareThis] = useState(false);
  const [isClickedChat, setIsClickedChat] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [httpsUrl, setHttpsUrl] = useState("");
  const [isOpenChatModal, setIsOpenChatModal] = useState(false);
  const [acceptOfferData, setAcceptOfferData] = useState();
  const windowSize = UseWindowSize();
  const { t } = useTranslation();
  const str = window.location.pathname;
  const [showLoginSidebar, setShowLoginSidebar] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showSignInSidebar, setShowSignInSidebar] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isCalledApi, setIsCalledApi] = useState(false);
  const [roomId, setRoomId] = useState<string>("");
  const token = window.localStorage.getItem("token");
  const [packageData, setPackageData] = useState<IChatData>();
  const chatData = useAppSelector<any>((state) => state.userChat);
  const [chatEngineData, setChatEngineData] = useState<IChatEngineData>();
  const userPackage: any = useAppSelector((state) => state?.packageById);
  const checkTravelData: any = useAppSelector((state) => state?.checkTravel);
  const isValidToken = useAppSelector<any>((state) => state.validationToken);
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(
    token === "undefined" || token === "null" || token === null ? false : true
  );
  const [latestMessages, setLatestMessages] = useState<Message[]>([]);
  const chatByTypeId: any = useAppSelector((state) => state.chatByTypeId);

  // useEffect(() => {
  //   setLatestMessages(chatByTypeId?.data?.messages);
  //   setRoomId(chatByTypeId?.data?.roomdId);
  // }, [chatByTypeId]);

  useEffect(() => {
    if (isClickedChat) {
      if (chatByTypeId?.isSuccess) {
        setLatestMessages(chatByTypeId?.data?.messages);
        setRoomId(chatByTypeId?.data?.roomdId);
      } else if (!chatByTypeId?.isSuccess) {
        toast.error(chatByTypeId?.message);
        setIsOpenChatModal(false);
      }
    }
  }, [chatByTypeId]);

  useEffect(() => {
    if (chatByTypeId?.isSuccess && isClickedChat) {
      setIsOpenChatModal(true);
    }
  }, [chatByTypeId, isClickedChat]);

  window.addEventListener("storage", () => {
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogin(false);
    else setIsLogin(true);
  });

  useEffect(() => {
    if (showLoginSidebar) HandleLoginSidebar();
  }, [showLoginSidebar]);

  const HandleLoginSidebar = () => {
    setShowSignInSidebar(!showSignInSidebar);
  };

  const handleShowMoreDetail = (key) => {
    setShowMoreDetail(key);
  };

  const handleShowShareThis = (key) => {
    setIsShowShareThis(key);
  };

  const handleMoreDetail = () => {
    setShowMoreDetail(true);
    setFade(true);
  };

  const checkActiveOffer = (data) => {
    if (hasAvailableTravel) showAcceptOfferSidebar(data);
    else showTravelModal();
  };

  const openChatModal = () => {
    setIsOpenChatModal(false);
    const tokenData = {
      accessToken: token,
    };
    setIsClickedChat(true);
    dispatch(checkTokenValidation(tokenData));
    dispatch(getChatByTypeId(data.pkgId));
  };

  useEffect(() => {
    if (checkTravelData?.isSuccess)
      setHasAvailableTravel(checkTravelData?.data);
  }, [checkTravelData]);

  const showTravelModal = () => {
    setIsOpenModal(true);
  };

  const showAcceptOfferSidebar = (data) => {
    setShowLoginSidebar(false);
    const currentRoute = window.location.pathname;
    if (!currentRoute.includes("/packageId/")) {
      navigate(`packageId/${data.pkgId}`);
    }
    if (isLogin) {
      dispatch(getPackageById(data?.pkgId));
    } else {
      setShowLoginModal(!showLoginModal);
    }
    setIsSuccess(true);
  };

  useEffect(() => {
    if (userPackage?.isSuccess && isSuccess) {
      setAcceptOfferData(userPackage.data);
      setShowSidebar(!showSidebar);
      setIsSuccess(false);
    }
  }, [userPackage]);

  useEffect(() => {
    const typeData = {
      typeId: data.pkgId,
    };
    if (isClickedChat && isValidToken?.length !== 0) {
      if (isValidToken?.isSuccess) {
        dispatch(getUserInfo());
        dispatch(getUserChat(typeData));
        setIsCalledApi(true);
      }
    }
  }, [isValidToken, isClickedChat]);

  useEffect(() => {
    if (isClickedChat && chatData?.length !== 0) {
      if (chatData?.isSuccess && isCalledApi) {
        setChatEngineData(chatData.data.chatengine);
        setPackageData(chatData.data);
        if (chatData.data?.senderFullName !== chatData.data?.travelerFullName) {
          // setIsOpenChatModal(true);
        }
        setIsClickedChat(false);
        setIsCalledApi(false);
      } else if (!chatData?.isSuccess && isCalledApi) {
      }
    }
  }, [chatData, isClickedChat, isOpenChatModal]);

  useEffect(() => {
    if (str?.includes("/packageId/") && !showSidebar) {
      if (isLogin) {
        dispatch(getPackageById(data?.pkgId));
      } else setShowLoginModal(!showLoginModal);
      // setIsSuccess(true);
    }
  }, [data]);

  let title = "Koolbaar";
  let image = shareUrl;
  let description = "description accept offer";

  const openShareThis = () => {
    setIsShowShareThis(!isShowShareThis);
  };

  useEffect(() => {
    setShareImage(shareUrl);
  }, [shareUrl]);

  const url = window.location.host + `/packageId/${data.pkgId}`;

  const generateImage = () => {
    function filterNode(node) {
      if (node instanceof Text) {
        return true;
      }
      return (
        [
          "div",
          "span",
          "button",
          "a",
          "p",
          "svg",
          "i",
          "strong",
          "img",
          "main",
          "aside",
          "article",
          "pre",
          "code",
          "time",
          "address",
          "header",
          "footer",
        ].includes(node.tagName.toLowerCase()) ||
        /^h[123456]$/i.test(node.tagName)
      );
    }
    const node = document.getElementById(`request-to-image-${data?.pkgId}`);
    htmlToImage.toPng(node, { filter: filterNode }).then(function (dataUrl) {
      const link = document.createElement("a");
      // link.download = "image.png";
      link.href = dataUrl;
      setShareUrl(dataUrl);
      // link.click();
    });
  };

  useEffect(() => {
    generateImage();
    if (!url.startsWith("https://")) {
      setHttpsUrl("https://" + url);
    } else {
      setHttpsUrl(url);
    }
  }, [url]);

  const fromTooltip = (
    <Tooltip id="fromCity">
      <span>
        {t("from")}: {data?.fromCountryCity}
      </span>
    </Tooltip>
  );
  const toTooltip = (
    <Tooltip id="toCity">
      <span>
        {t("to")}: {data?.toCountryCity}
      </span>
    </Tooltip>
  );
  const chatTooltip = (
    <Tooltip id="chatwith">
      <span>
        {t("chatwith")}: {data?.owner}
      </span>
    </Tooltip>
  );
  const shareTooltip = (
    <Tooltip id="share">
      <span>{t("share")}</span>
    </Tooltip>
  );

  return (
    <Col
      key={data?.pkgId}
      lg={4}
      md={6}
      sm={12}
      className={`${windowSize.width < 768 && "p-0"} mb-3`}
      style={windowSize.width < 768 ? { width: "100%" } : null}
      data-testid="container"
      id={`request-to-image-${data?.pkgId}`}
    >
      <Card className="request-package-card-wrapper">
        <Card.Header className="card-request-package-header">
          <Row>
            <Col xs={12} className="text-center header-card-plane">
              <div>
                <span className="fw-bold package-type-title-card">
                  {data?.packagetypes}
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={5}
              className="text-left header-card-titles pr-0 city-header-card-titles"
              // style={{ marginTop: "-5px" }}
            >
              <OverlayTrigger placement="top" overlay={fromTooltip}>
                <div>
                  <span className="text-left cursor-pointer">
                    {data?.fromCity}
                  </span>
                </div>
              </OverlayTrigger>
            </Col>
            <Col xs={2} className="text-center header-card-plane p-0">
              <div>
                <span
                  className="mb-0"
                  style={{ fontSize: 18, fontWeight: 400 }}
                >
                  {"✈︎"}
                </span>
              </div>
            </Col>
            <Col
              xs={5}
              className="header-card-titles pl-0 city-header-card-titles"
              // style={{ marginTop: "-5px" }}
            >
              <OverlayTrigger placement="top" overlay={toTooltip}>
                <div className="text-right cursor-pointer">
                  <span>{data?.toCity}</span>
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              className="text-left header-card-titles pr-0 date-header-card-titles"
            >
              <div>
                <span className="text-left">{data?.fromDate1}</span>
              </div>
            </Col>

            <Col
              xs={6}
              className="header-card-titles pl-0 date-header-card-titles"
            >
              <div className="text-right">
                <span>{data?.fromDate2}</span>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="card-request-body">
          <Row>
            <Col xs={12} className="request-body-info">
              <div className={isPersian ? "dir-rtl" : "dir-ltr"}>
                <span className="package-travel-card-id">
                  {t("PKGID")}: {data?.pkgId}
                </span>
              </div>
              {((isPersian && data?.packagetypes !== "همسفر") ||
                (!isPersian &&
                  data?.packagetypes !== "Traveling Companion")) && (
                <>
                  <div className={isPersian ? "dir-rtl" : "dir-ltr"}>
                    <span className="card-text">
                      {t("size")}: {data?.size}
                    </span>
                  </div>
                  <div className={isPersian ? "dir-rtl" : "dir-ltr"}>
                    <span className="card-text">
                      {t("weight")}: {data?.weight} {data?.weightUnit}
                    </span>
                  </div>
                </>
              )}

              {/* <div>
                <span className="card-text">
                  {t("itemValue")}: {data?.itemValue} {data?.currencyType}
                </span>
              </div> */}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={7} className="request-body-date">
              <div className={isPersian ? "dir-rtl" : "dir-ltr"}>
                <span className="card-text">{t("shippingDeadline")}:</span>
              </div>
              <div>
                <span className="card-text">{data?.shippingDeadline}</span>
              </div>
            </Col>
            <Col xs={5} style={{ textAlign: "right", marginTop: "-25px" }}>
              <div className="mt-1 mb-1">
                <OverlayTrigger placement="top" overlay={shareTooltip}>
                  <a onClick={openShareThis}>
                    <FontAwesomeIcon
                      icon={faShareAlt}
                      className="sharethis-icon"
                    />
                  </a>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={chatTooltip}>
                  <a onClick={openChatModal}>
                    <FontAwesomeIcon
                      icon={faComment}
                      className="chat-card-icon"
                    />
                  </a>
                </OverlayTrigger>
              </div>
              <Button
                variant="primary"
                data-test="docs-btn-anchor"
                className="offer-btn"
              >
                {t("offer")}{" "}
                <span className="d-block">
                  <strong>{data?.offerPrice}</strong> {data?.currencyType}
                </span>
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="card-request-footer">
          <Button
            variant="gray7"
            data-test="docs-btn-anchor"
            className="more-detail-btn"
            onClick={() => {
              handleMoreDetail();
            }}
            onAnimationEnd={() => setFade(false)}
          >
            {t("moreDetails")}
          </Button>
          {str?.includes("/packageId/") ? (
            <Button
              variant="warning"
              data-test="docs-btn-anchor"
              className="make-offer-btn"
              onClick={() => checkActiveOffer(data)}
            >
              {t("acceptAndMakeOffer")}
            </Button>
          ) : (
            // <Link
            //   to={
            //     str?.includes("/packageId/")
            //       ? undefined
            //       : `packageId/${data.pkgId}`
            //   }
            // >
            <Button
              variant="warning"
              data-test="docs-btn-anchor"
              className="make-offer-btn"
              onClick={() => checkActiveOffer(data)}
            >
              {t("acceptAndMakeOffer")}
            </Button>
            // </Link>
          )}
        </Card.Footer>
      </Card>
      {showMoreDetail && (
        <PackageCover
          data={data}
          fade={fade}
          onShowCover={handleShowMoreDetail}
        />
      )}
      {showLoginModal && (
        <LoginConfirm
          title={t("acceptAndMakeOffer")}
          isOpen={showLoginModal}
          setIsOpen={setShowLoginModal}
          type="acceptOffer"
          setShowLoginSidebar={setShowLoginSidebar}
        />
      )}
      {showSignInSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSignInSidebar}
            setIsOpen={setShowSignInSidebar}
            setIsLogin={setIsLogin}
            sidebarType="login"
          />
        </div>
      )}
      {showSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSidebar}
            setIsOpen={setShowSidebar}
            sidebarType="acceptOffer"
            // mode="choose"
            data={acceptOfferData}
          />
        </div>
      )}
      {isShowShareThis && (
        <ShareThisCover
          fade={fade}
          onShowShareCover={handleShowShareThis}
          // url={window.location.host + `/packageId/${data.pkgId}`}
          httpsUrl={httpsUrl}
          title={data?.pkgId}
          description={description}
          image={image}
        />
      )}
      {isOpenModal && (
        <FirstMakeYourCard
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          type="travel"
        />
      )}
      {isOpenChatModal && (
        // <Chat
        //   isOpen={isOpenChatModal}
        //   setIsOpen={setIsOpenChatModal}
        //   chatEngineData={chatEngineData}
        //   packageData={packageData}
        // />
        <Row>
          <Col lg={4} md={6} sm={12}>
            <SingleChatList
              isOpen={isOpenChatModal}
              setIsOpen={setIsOpenChatModal}
              id={data?.pkgId}
              avatar={data?.personalPhoto}
              packageData={packageData}
              latestMessages={latestMessages}
              roomId={roomId}
            />
          </Col>
        </Row>
      )}
    </Col>
  );
};
