import { createSlice } from "@reduxjs/toolkit";

export const verificationTypeSlice = createSlice({
  name: "verificationType",
  initialState: [],
  reducers: {
    verificationType: (state, action) => {
      return (state = action.payload);
    },
    verificationTypeFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { verificationType, verificationTypeFailure } = verificationTypeSlice.actions;
export default verificationTypeSlice.reducer;
