import React, { useState, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import data from "json/requests.json";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { SkeletonGrid } from "components";
import { Option, IRequest } from "models/interfaces";
import { Cards } from "./Cards";
import { useAppDispatch } from "redux/store";
import { getAllHomeRequestFilter } from "redux/actions/flight";
import { Button } from "layers";
import { useTranslation } from "react-i18next";

interface IProps {
  hasLoadMore: boolean;
  isEmpty: boolean;
  isLoading: boolean;
  setIsLoad: Function;
  setShareImage: Function;
  pageCount: number;
  requestData: IRequest[];
  type: Option;
  size: Option;
  weight: Option;
  deliveryTypes: Option;
  payments: Option;
  services: Option;
  availableRequestData: any;
  currentPage: number;
  setCurrentPage: Function;
}

export const PackageCard: React.FC<IProps> = ({
  requestData,
  isLoading,
  type,
  size,
  weight,
  deliveryTypes,
  services,
  payments,
  setIsLoad,
  pageCount,
  availableRequestData,
  setShareImage,
  currentPage,
  setCurrentPage,
  hasLoadMore,
}) => {
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const [isFetching, setIsFetching] = useState(false);
  const { t } = useTranslation();

  const fetchMoreData = () => {
    let pageNumber: number = currentPage + 1;
    setCurrentPage(pageNumber);
    const data = {
      type: "package",
      packagetypeIds: type.value ? type.value : null,
      weightrangeIds: weight?.value ? weight?.value : null,
      deliverytypeIds: deliveryTypes?.value ? deliveryTypes?.value : null,
      sizerangeIds: size?.value ? size?.value : null,
      serviceIds: services?.value ? services?.value : null,
      paymentmethodIds: payments?.value ? payments?.value : null,
      page: pageNumber,
    };
    setIsLoad(true);
    dispatch(getAllHomeRequestFilter(data));
  };

  // useEffect(() => {
  //   const data = {
  //     type: "package",
  //     packagetypeIds: type.value ? type.value : null,
  //     weightrangeIds: weight?.value ? weight?.value : null,
  //     deliverytypeIds: deliveryTypes?.value ? deliveryTypes?.value : null,
  //     sizerangeIds: size?.value ? size?.value : null,
  //     serviceIds: services?.value ? services?.value : null,
  //     paymentmethodIds: payments?.value ? payments?.value : null,
  //     page: currentPage,
  //   };
  //   setIsLoad(true);
  //   console.log(currentPage)
  //   dispatch(getAllHomeRequestFilter(data));
  // }, [currentPage]);

  return (
    <>
      <div className="flex-grow-1 mb-3">
        <Row
          style={
            windowSize.width < 768 ? { width: "100%", margin: "auto" } : null
          }
        >
          {isLoading ? (
            (data?.items ?? []).map((item) => (
              <Col key={item.name} lg={4} md={4} sm={12}>
                <SkeletonGrid />
              </Col>
            ))
          ) : (
            <>
              {requestData?.length !== 0 ? (
                requestData?.map((data, idx) => (
                  <Cards
                    key={idx}
                    setShareImage={setShareImage}
                    data={data}
                    // availableRequestData={availableRequestData}
                  />
                ))
              ) : (
                <div className="no-data-dashboard text-center">
                  {t("noPackage")}
                </div>
              )}
              {/* <button onClick={fetchMoreData}>get more data</button> */}
            </>
          )}
          <div className="w-100 text-center">
            {hasLoadMore && currentPage < pageCount && (
              <Button
                variant="primary"
                onClick={fetchMoreData}
                className="load-more-btn mt-3 mx-auto"
              >
                {t("loadMore")}
              </Button>
            )}
          </div>
        </Row>
      </div>
    </>
  );
};
