import NotificationsService from "services/notificationsService";
import {
  allNotification,
  allNotificationFailure,
} from "redux/slices/notifications/getAllNotifications";
import {
  allNotificationCount,
  allNotificationCountFailure,
} from "redux/slices/notifications/getAllNotificationsCount";
import { toast } from "react-toastify";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

export const getAllAnnouncement = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await NotificationsService.getAllNotifications(data);
    dispatch(allNotification(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(allNotificationFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getAllAnnouncementCount = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await NotificationsService.getAllNotificationsCount();
    dispatch(allNotificationCount(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(allNotificationCountFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};
