import { createSlice } from "@reduxjs/toolkit";

export const createRequestSlice = createSlice({
  name: "createRequest",
  initialState: [],
  reducers: {
    createRequest: (state, action) => {
      return (state = action.payload);
    },
    createRequestFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { createRequest, createRequestFailure } = createRequestSlice.actions;
export default createRequestSlice.reducer;
