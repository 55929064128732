import { BaseService } from "./baseService";

class ReminderService extends BaseService {
  getAllFareAlerts(data) {
    return this.authorizedHttp().get("/User/FareAlert", data);
  }

  getFareAlertCount() {
    return this.authorizedHttp().get("/User/FareAlert/Count");
  }

  setFareAlerts(data) {
    return this.authorizedHttp().post("/User/FareAlert", data);
  }

  removeFareAlert(id) {
    return this.authorizedHttp().delete(`/User/FareAlert/${id}`);
  }

  changeStatusFareAlert(data) {
    return this.authorizedHttp().post(`/User/FareAlert/ChangeStatus`, data);
  }
}

export default new ReminderService();
