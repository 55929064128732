import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Header, Footer, Button, Input } from "layers";
import { useAppDispatch, useAppSelector } from "redux/store";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getUserInfo } from "redux/actions/Authorization";
import {
  getCloseAccountReasons,
  sendCloseAccountRequest,
} from "redux/actions/closeAccount";
import { Oval } from "react-loader-spinner";
import { getAllCurrencies } from "redux/actions/types";
import { useTranslation } from "react-i18next";

export const CloseAccounts: React.FC = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const [data, setData] = useState<any>();
  const [selectedReasonIds, setSelectedReasonIds] = useState([]);
  const [description, setDescription] = useState("");
  const [showLastMessage, setShowLastMessage] = useState("");
  const [closeAccountReasons, setCloseAccountReasons] = useState([]);
  const [showFirstCard, setShowFirstCard] = useState(true);
  const [showSecondCard, setShowSecondCard] = useState(false);
  const [showThirdCard, setShowThirdCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const size = UseWindowSize();
  const userData = useAppSelector(
    (state) => state?.userInfo?.data && state?.userInfo?.data[0]?.data
  );
  const allReasons: any = useAppSelector((state) => state?.closeAccountReasons);
  const sendRequest: any = useAppSelector(
    (state) => state?.closeAccountRequest
  );

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getCloseAccountReasons());
    dispatch(getAllCurrencies());
  }, []);

  useEffect(() => {
    if (userData) setData(userData);
  }, [userData]);

  useEffect(() => {
    if (allReasons?.isSuccess) {
      setCloseAccountReasons(allReasons?.data);
    }
  }, [allReasons]);

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCheckedChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedReasonIds((prevSelected) => [...prevSelected, value]);
    } else {
      setSelectedReasonIds((prevSelected) =>
        prevSelected.filter((id) => id !== value)
      );
    }
  };

  useEffect(() => {
    if (!sendRequest?.isSuccess) {
      setIsLoading(false);
    } else if (sendRequest?.isSuccess) {
      setIsLoading(false);
      setShowLastMessage("Your Account has been successfully Deleted.");
      setShowFirstCard(false);
      setShowSecondCard(false);
      setShowThirdCard(false);
      setTimeout(() => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("avatar");
        window.localStorage.removeItem("expire");
        window.localStorage.removeItem("timestampExpire");
        window.localStorage.removeItem("refreshToken");
        window.localStorage.removeItem("tokenType");
        const win: Window = window;
        win.location = "/";
      }, 3000);
    }
  }, [sendRequest]);

  const showSecondCardHandle = () => {
    setShowFirstCard(false);
    setShowSecondCard(true);
    setShowThirdCard(false);
  };

  const showThirdCardHandle = () => {
    setShowFirstCard(false);
    setShowSecondCard(false);
    setShowThirdCard(true);
  };

  const closeUserAccount = () => {
    setIsLoading(true);
    let data;
    data = {
      description: description,
      closeaccountreasonIds: selectedReasonIds.toString(),
      accessToken: window.localStorage.getItem("token"),
      refreshToken: window.localStorage.getItem("refreshToken"),
    };
    dispatch(sendCloseAccountRequest(data));
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>{t("accountManagement")}</h2>
      </div>
      <Card className="close-account-card-wrapper my-5">
        <Card.Header className="close-account-card-header d-flex">
          <h3 className="mb-0">{t("closeAccount")}</h3>
          <a
            href="/settings"
            onClick={() => {
              localStorage.setItem("tab", "userInfo");
            }}
            className={isPersian ? "mr-auto close-account-texts" : "ml-auto"}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> {t("back")}
          </a>
        </Card.Header>
        {showFirstCard && (
          <Card.Body className={isPersian ? "text-right" : ""}>
            {userData && (
              <div>
                <span className="close-account-texts">
                  <strong>{data?.firstName}</strong>, {t("sorrySeeYouGo")}
                </span>
                <span className="mt-4 mb-3 d-block close-account-texts">
                  {t("reasonForCloseAccount")}:
                </span>
                {closeAccountReasons?.length !== 0 &&
                  closeAccountReasons?.map((reason) => {
                    return (
                      <div className="mt-2">
                        <input
                          className="ml-0 mr-1 close-account-radio-btn"
                          type="checkbox"
                          name={reason.name}
                          value={reason.id}
                          onChange={handleCheckedChange}
                        />
                        <span className="contract-content-radio">
                          {reason.name}
                        </span>
                      </div>
                    );
                  })}

                <Button
                  variant="primary"
                  data-test="docs-btn-anchor"
                  className="withdraw-btn mt-4"
                  onClick={showSecondCardHandle}
                >
                  {t("continue")}
                </Button>
              </div>
            )}
          </Card.Body>
        )}
        {showSecondCard && (
          <Card.Body className={isPersian ? "text-right" : ""}>
            {userData && (
              <div>
                <span className="close-account-texts">
                  <strong>{data?.firstName}</strong>, {t("sorrySeeYouGo")}
                </span>
                <span className="mt-4 mb-2 d-block close-account-texts">
                  {t("moreInfoToHelpUs")}
                </span>
                <Input
                  size="sm"
                  id="message"
                  placeholder={t("reasonForClosingAccount")}
                  className="custom-input-message close-account-texts"
                  value={description}
                  onChange={handleDescriptionChange}
                  textArea={true}
                  rows={10}
                  maxLength="250"
                />
                <Button
                  variant="primary"
                  data-test="docs-btn-anchor"
                  className="withdraw-btn mt-4 close-account-texts"
                  disabled={description?.length === 0}
                  onClick={showThirdCardHandle}
                >
                  {t("next")}
                </Button>
              </div>
            )}
          </Card.Body>
        )}
        {showThirdCard && (
          <Card.Body className={isPersian ? "text-right" : ""}>
            {userData && (
              <div>
                <span className="close-account-texts">
                  <strong>{data?.firstName}</strong>, {t("sorrySeeYouGo")}
                </span>
                <span className="mt-4 mb-2 d-block close-account-texts">
                  {t("sureWantCloseAccount")}
                </span>

                <Button
                  variant="primary"
                  data-test="docs-btn-anchor"
                  className="close-account-btn-approve mt-4"
                  onClick={closeUserAccount}
                >
                  {t("yes")}
                  {isLoading && (
                    <Oval
                      width="20"
                      height="20"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                    />
                  )}
                </Button>
                <Button
                  className={`close-account-btn-not-approve mt-4 ${
                    isPersian ? "mr-4" : "ml-4"
                  }`}
                  href="/settings"
                  onClick={() => {
                    localStorage.setItem("tab", "userInfo");
                  }}
                >
                  {t("no")}
                </Button>
              </div>
            )}
          </Card.Body>
        )}
        {showLastMessage?.length !== 0 && (
          <Card.Body className={isPersian ? "text-right" : ""}>
            <div>
              <span className="message-close-account">
                <strong>
                  {t("dear")} {data?.firstName}
                </strong>
                , {showLastMessage}
              </span>
            </div>
          </Card.Body>
        )}
      </Card>
      <Footer />
    </div>
  );
};
