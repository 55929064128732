import React from "react";
import { Header } from "layers";
import { useTranslation } from "react-i18next";

export const Banner: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="img-banner-Wrapper">
      <Header />
      <div className="banner-text">
        <h1 className="banner-title">{t("sloganBanner")}</h1>
        {/* <span className="banner-subtitle">
          Make your travel the most comfortable and affordable
        </span> */}
      </div>
    </div>
  );
};
