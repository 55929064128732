import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "layers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Uploader } from "components/common/dropZone";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getUserInfo, uploadUserIdentity } from "redux/actions/Authorization";
import { Oval } from "react-loader-spinner";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
  type: string;
}

export const UploadIdentity: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  type,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [addressPhoto, setAddressPhoto] = useState(null);
  const [secondIdentityPhoto, setSecondIdentityPhoto] = useState(null);
  const [hasVibration, setHasVibration] = useState(false);
  const uploadFile: any = useAppSelector((state) => state.uploadFile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";

  const handleClose = () => {
    setIsOpen(false);
  };

  const uploadPassport = () => {
    setIsLoading(true);
    const body = new FormData();
    body.append("Type", "passport");
    body.append("File", passportPhoto);
    dispatch(uploadUserIdentity(body));
  };

  const uploadAddress = () => {
    setIsLoading(true);
    const body = new FormData();
    body.append("Type", "address");
    body.append("File", addressPhoto);
    dispatch(uploadUserIdentity(body));
  };

  const uploadSecondIdentity = () => {
    setIsLoading(true);
    const body = new FormData();
    body.append("Type", "secondidentity");
    body.append("File", secondIdentityPhoto);
    dispatch(uploadUserIdentity(body));
  };

  useEffect(() => {
    if (uploadFile.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      window.location.reload();
    } else {
      setIsLoading(false);
    }
  }, [uploadFile]);

  return (
    <>
      <Modal
        className={`remove-modal-wrapper`}
        show={isOpen}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="close-modal-btn-wrapper">
            <a onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </a>
          </div>
          <p className="enter-travel-information pt-4 text-center">
            {type === "Passport"
              ? t("passportUpload")
              : type === "address"
              ? t("addressUpload")
              : t("licenseUpload")}
          </p>
          <Row>
            <section className="container">
              <Uploader
                title=""
                photo={
                  type === "Passport"
                    ? passportPhoto
                    : type === "address"
                    ? addressPhoto
                    : secondIdentityPhoto
                }
                setPhoto={
                  type === "Passport"
                    ? setPassportPhoto
                    : type === "address"
                    ? setAddressPhoto
                    : setSecondIdentityPhoto
                }
              />
            </section>
          </Row>
          <div className="text-center">
            <Button
              variant="warning"
              data-test="docs-btn-anchor"
              className="confirm-remove-btn text-center"
              onClick={
                type === "Passport"
                  ? uploadPassport
                  : type === "address"
                  ? uploadAddress
                  : uploadSecondIdentity
              }
            >
              {t("submitRequest")}
              {isLoading && (
                <Oval
                  width="20"
                  height="20"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                />
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
