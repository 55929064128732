import { createSlice } from "@reduxjs/toolkit";

export const uploadFileSlice = createSlice({
  name: "uploadFile",
  initialState: [],
  reducers: {
    uploadFile: (state, action) => {
      return (state = action.payload);
    },
    uploadFileFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { uploadFile, uploadFileFailure } = uploadFileSlice.actions;
export default uploadFileSlice.reducer;
