import { createSlice } from "@reduxjs/toolkit";

export const allAirlinesSlice = createSlice({
  name: "allAirlines",
  initialState: [],
  reducers: {
    allAirlines: (state, action) => {
      return (state = action.payload.data);
    },
    allAirlinesFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allAirlines, allAirlinesFailure } =
  allAirlinesSlice.actions;
export default allAirlinesSlice.reducer;

