import React, { FC, useState, useEffect, useRef } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { Button } from "layers";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import CalendarIcon from "../../assets/images/calendar.png";
import DeparturePlan from "../../assets/images/svg/departure-plane.svg";
import ArrivalPlan from "../../assets/images/svg/arrival-plane.svg";
import { components } from "react-select";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  getAllFareAlerts,
  setNewFareAlert,
  removeFareAlert,
  changeFareAlertStatus,
} from "redux/actions/fareAlert";
import { IFlightSelect, ITravelInformation } from "models/interfaces";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { getDate, getDefaultDate, getDefaultTime } from "helpers/convertDate";
import { Oval } from "react-loader-spinner";
import AsyncSelect from "react-select/async";
import { searchLocation } from "helpers/searchLocation";
import { useTranslation } from "react-i18next";
import YellowReminderIcon from "../../assets/images/yellow-reminder.png";
import GrayReminderIcon from "../../assets/images/gray-reminder.png";
import TrashIcon from "../../assets/images/trash.png";
import { useNavigate } from "react-router-dom";

interface IProp {
  setIsOpen: Function;
  isOpen: boolean;
  type: string;
}

export const SetAlert: React.FC<IProp> = ({ isOpen, setIsOpen, type }) => {
  const size = UseWindowSize();
  const { t } = useTranslation();

  const flightFromWrapper = useRef(null);
  const flightsToWrapper = useRef(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
  const [isToDropdownOpen, setIsToDropdownOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [hover, setHover] = useState({});
  const [from, setFrom] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [fromAirportName, setFromAirportName] = useState("");
  const [toAirportName, setToAirportName] = useState("");
  const [fromCountry, setFromCountry] = useState("");
  const [to, setTo] = useState("");
  const [toCity, setToCity] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [allAlerts, setAllAlerts] = useState([]);
  const [fromText, setFromText] = useState<IFlightSelect>({
    value: "",
    cityLabel: "",
    airportLabel: "",
    countryLabel: "",
    label: <></>,
  });
  const [toText, setToText] = useState<IFlightSelect>({
    value: "",
    cityLabel: "",
    airportLabel: "",
    countryLabel: "",
    label: <></>,
  });
  const [departureDate, setDepartureDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(getDefaultDate(new Date()));
  const [fromTime, setFromTime] = useState(getDefaultTime(new Date()));
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [toDate, setToDate] = useState(getDefaultDate(new Date()));
  const [toTime, setToTime] = useState(getDefaultTime(new Date()));
  const searchReminderResult: any = useAppSelector(
    (state) => state.allFareAlerts
  );
  const addReminderResult: any = useAppSelector((state) => state.setFareAlert);
  const fareAlertChangeResult: any = useAppSelector(
    (state) => state.changeStatusFareAlert
  );
  const removeReminderResult: any = useAppSelector(
    (state) => state.deleteFareAlert
  );

  const locations = useAppSelector((state) => state.searchFlight);
  const [locationsOptions, setLocationsOptions] = useState([]);

  const handleFromTextChange = (selected) => {
    setFromText(selected);
    setIsSelected(true);
    setIsFromDropdownOpen(false);
    setFrom(selected?.value);
    setFromCity(selected?.cityLabel);
    setFromAirportName(selected?.airportLabel);
    setFromCountry(selected?.countryLabel);
  };

  const handleToTextChange = (selected) => {
    setToText(selected);
    setIsSelected(true);
    setIsToDropdownOpen(false);
    setTo(selected?.value);
    setToAirportName(selected?.airportLabel);
    setToCity(selected?.cityLabel);
    setToCountry(selected?.countryLabel);
  };

  const onDepartureDateChange = (date) => {
    setDepartureDate(date);
    setFromDate(getDefaultDate(date));
    setFromTime(getDefaultTime(date));
  };

  const onArrivalDateChange = (date) => {
    setArrivalDate(date);
    setToDate(getDefaultDate(date));
    setToTime(getDefaultTime(date));
  };

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          navigate("/");
        }
      },
      false
    );
  }, []);

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (isFromDropdownOpen)
      setFromText({
        value: locationsOptions[0]?.airportCode,
        cityLabel: locationsOptions[0]?.cityName,
        airportLabel: locationsOptions[0]?.airportName,
        countryLabel: locationsOptions[0]?.countryName,
        label: locationsOptions[0]?.airportName,
      });

    if (isToDropdownOpen)
      setToText({
        value: locationsOptions[0]?.airportCode,
        cityLabel: locationsOptions[0]?.cityName,
        airportLabel: locationsOptions[0]?.airportName,
        countryLabel: locationsOptions[0]?.countryName,
        label: locationsOptions[0]?.airportName,
      });
  }, [locations, locationsOptions, isFromDropdownOpen, isToDropdownOpen]);

  const customStyle = {
    control: (styles) => ({
      ...styles,
      height: 45,
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#ffa200",
      fontSize: "14px",
      zIndex: 1,
      borderBottom: "1px solid rgb(255 255 255 / 35%)",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#333333",
    }),
    Menu: (styles) => ({
      ...styles,
      background: "#ffa200",
      border: "none",
    }),
    menuList: (styles) => ({
      ...styles,
      height: 200,
      background: "#ffa200",
      width: size.width < 768 ? 300 : 378,
      borderRadius: 5,
      border: "none",
      padding: "5px 15px 0 0",
    }),
  };

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    );
  };

  const openFromDropdown = () => {
    setIsFromDropdownOpen(!isFromDropdownOpen);
  };
  const openToDropdown = () => {
    setIsToDropdownOpen(!isToDropdownOpen);
  };

  const getAllFareAlert = () => {
    const data: ITravelInformation = {
      type: type,
      fromCityEntityId: fromText?.value,
      fromDate: getDate(departureDate),
      toCityEntityId: toText?.value,
      toDate: getDate(arrivalDate),
    };
    dispatch(getAllFareAlerts(data));
  };

  useEffect(() => {
    getAllFareAlert();
  }, []);

  const setAlert = () => {
    setIsSearchLoading(true);

    const data: ITravelInformation = {
      type: type,
      fromCityEntityId: fromText?.value,
      fromDate: getDate(departureDate),
      toCityEntityId: toText?.value,
      toDate: getDate(arrivalDate),
    };
    dispatch(setNewFareAlert(data));
  };

  const deleteFareAlert = (id) => {
    dispatch(removeFareAlert(id));
  };

  useEffect(() => {
    if (addReminderResult?.isSuccess) {
      setIsSearchLoading(false);
      getAllFareAlert();
    } else {
      setIsSearchLoading(false);
    }
  }, [addReminderResult]);

  useEffect(() => {
    if (removeReminderResult?.isSuccess) {
      getAllFareAlert();
    }
  }, [removeReminderResult]);

  useEffect(() => {
    if (searchReminderResult?.isSuccess) {
      setIsSearchLoading(false);
      setAllAlerts(searchReminderResult?.data);
    } else {
      setIsSearchLoading(false);
    }
  }, [searchReminderResult]);

  function flightListOutsideClickListener(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsFromDropdownOpen(false);
        setIsToDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  // const mouseEnter = (id) => {
  //   setShowRemoveIcon(true);
  // };

  // const mouseLeave = (id) => {
  //   setShowRemoveIcon(false);
  // };

  const mouseEnter = (event, index) => {
    setHover((c) => {
      return {
        ...c,
        [index]: true,
      };
    });
  };

  const mouseLeave = (event, index) => {
    setHover((c) => {
      return {
        ...c,
        [index]: false,
      };
    });
  };

  flightListOutsideClickListener(flightFromWrapper);
  flightListOutsideClickListener(flightsToWrapper);

  const changeStatus = (id, isActive) => {
    const data = {
      id,
      isActive: !isActive,
    };
    dispatch(changeFareAlertStatus(data));
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (fareAlertChangeResult?.isSuccess) {
      getAllFareAlert();
    }
  }, [fareAlertChangeResult]);

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/");
  };

  return (
    <div className="set-alert-slider-container">
      <Row className="sidebar-content-wrapper">
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className="payment-form">
          <h1>{t("setAlert")}</h1>
        </Col>
        <Col xs={12} className="payment-form">
          <Row className="tabs-wrapper travelers-tabs">
            <Col lg={6} md={6} sm={12}>
              <div className="text-align-last-left mb-1">
                <span className="search-arrival-info">
                  {t("departureInfo")}
                </span>
              </div>
              <Row
                className={`${
                  isFromDropdownOpen
                    ? "from-active-set-alert-input-wrapper"
                    : "from-deActive-set-alert-input-wrapper"
                }`}
                onClick={openFromDropdown}
              >
                <Col xs={10}>
                  {from ? (
                    <>
                      {/* <span className="abbr-name-location">
                        {fromAirportName}
                      </span> */}
                      <span className="country-name-location">
                        {fromCity}
                        {fromCity && fromCountry && ","} {fromCountry}
                      </span>
                    </>
                  ) : (
                    <span className="abbr-title-location">{t("city")}</span>
                  )}
                </Col>
                <Col xs={2} className="text-center p-0">
                  <img
                    // src={
                    //   isFromDropdownOpen ? whiteLocationIcon : blackLocationIcon
                    // }
                    src={DeparturePlan}
                    className="location-icons"
                    alt="location-img"
                  />
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="location-chevron"
                    color={`${isFromDropdownOpen ? "white" : "black"}`}
                  />
                </Col>
              </Row>
              {isFromDropdownOpen && (
                <div
                  ref={flightFromWrapper}
                  className="flight-info-dropdown d-inline-block"
                  style={{ position: "absolute", zIndex: 1, height: 275 }}
                >
                  <AsyncSelect
                    className="custom-place-location d-inline-block"
                    value={fromText}
                    menuIsOpen
                    autoFocus
                    onChange={handleFromTextChange}
                    loadOptions={searchLocation}
                    isLoading={isLoading}
                    placeholder="Search your Location"
                    loadingMessage={() => "Loading ..."}
                    cacheOptions
                    defaultOptions
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      MenuList: SelectMenuButton,
                    }}
                    styles={customStyle}
                  />
                </div>
              )}
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className={size.width >= 768 ? "ml-title-15" : ""}
            >
              <div
                className={`${
                  size.width < 768 ? "mt-2" : ""
                } text-align-last-left mb-1`}
              >
                <span className="search-departure-info">
                  {t("departureTime")}
                </span>
              </div>

              <div
                className="alert-datepicker-wrapper"
              >
                <DatePicker
                  selected={departureDate}
                  onChange={(date) => onDepartureDateChange(date)}
                  minDate={new Date()}
                  showTimeInput
                  customInput={
                    <Row className="from-time-input-wrapper w-100">
                      <Col xs={10}>
                        <span className="abbr-title-location">{fromDate}</span>
                        <span className="country-name-time">{fromTime}</span>
                      </Col>
                      <Col xs={2} className="text-center p-0">
                        <img
                          src={CalendarIcon}
                          className="time-icons"
                          alt="time-img"
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="location-chevron"
                          color="black"
                        />
                      </Col>
                    </Row>
                  }
                />
              </div>
            </Col>
          </Row>
          <Row className="tabs-wrapper travelers-tabs mt-3">
            <Col lg={6} md={6} sm={12}>
              <div className="text-align-last-left mb-1">
                <span className="search-arrival-info">{t("arrivalInfo")}</span>
              </div>
              <Row
                className={`${
                  isToDropdownOpen
                    ? "from-active-set-alert-input-wrapper"
                    : "from-deActive-set-alert-input-wrapper"
                }`}
                onClick={openToDropdown}
              >
                <Col xs={10}>
                  {to ? (
                    <>
                      {/* <span className="abbr-name-location">
                        {toAirportName}
                      </span> */}
                      <span className="country-name-location">
                        {toCity}
                        {toCity && toCountry && ","} {toCountry}
                      </span>
                    </>
                  ) : (
                    <span className="abbr-title-location">{t("city")}</span>
                  )}
                </Col>
                <Col xs={2} className="pl-0 text-center">
                  <img
                    // src={isToDropdownOpen ? whiteLocationIcon : blackLocationIcon}
                    src={ArrivalPlan}
                    className="location-icons"
                    alt="location-img"
                  />
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className="location-chevron"
                    color={`${isToDropdownOpen ? "white" : "black"}`}
                  />
                </Col>
              </Row>
              {isToDropdownOpen && (
                <div
                  ref={flightsToWrapper}
                  className="flight-info-dropdown d-inline-block"
                  style={{ position: "absolute", zIndex: 1, height: 275 }}
                >
                  <AsyncSelect
                    className="custom-place-location d-inline-block"
                    value={toText}
                    menuIsOpen
                    autoFocus
                    onChange={handleToTextChange}
                    loadOptions={searchLocation}
                    isLoading={isLoading}
                    placeholder="Search your Location"
                    loadingMessage={() => "Loading ..."}
                    cacheOptions
                    defaultOptions
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      // loadingMessage: () => {"Loading ..."},
                      MenuList: SelectMenuButton,
                    }}
                    styles={customStyle}
                  />
                </div>
              )}
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className={size.width >= 768 ? "m-0" : ""}
            >
              <div
                className={`${
                  size.width < 768 ? "mt-2" : ""
                } text-align-last-left mb-1`}
              >
                <span
                  className={`${
                    size.width >= 768 ? " ml-title-15" : ""
                  } search-departure-info`}
                >
                  {t("arrivalTime")}
                </span>
              </div>
              <div
                className="alert-datepicker-wrapper"
              >
                <DatePicker
                  selected={arrivalDate}
                  onChange={(date) => onArrivalDateChange(date)}
                  minDate={departureDate}
                  showTimeInput
                  customInput={
                    <Row className="alarm-to-time-input-wrapper w-100">
                      <Col xs={10}>
                        <span className="abbr-title-location">{toDate}</span>
                        <span className="country-name-time">{toTime}</span>
                      </Col>
                      <Col xs={2} className="text-center p-0">
                        <img
                          src={CalendarIcon}
                          className="time-icons"
                          alt="time-img"
                        />
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="location-chevron"
                          color="black"
                        />
                      </Col>
                    </Row>
                  }
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Button
            onClick={setAlert}
            type="button"
            className="set-reminder-btn"
            variant="warning"
          >
            {type === "Package" ? t("snoopAlert") : t("trackAlert")}
            {isSearchLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </Col>
        <Col xs={12} className="payment-form mt-3">
          <h1 className="mb-3">{t("yourAlerts")}</h1>
        </Col>
        {allAlerts?.map((item, index) => {
          return (
            item?.type === type && (
              <Col
                xs={12}
                className="payment-form mt-1"
                key={index}
                onMouseEnter={(e) => {
                  mouseEnter(e, index);
                }}
                onMouseLeave={(e) => {
                  mouseLeave(e, index);
                }}
              >
                <Row className="your-location-alert-wrapper">
                  <Col xs={8} className="p-0">
                    <span className="your-location-alert d-block">
                      {item.cities}
                    </span>
                    <span className="your-date-alert d-block">
                      {item.dates}
                    </span>
                  </Col>
                  <Col xs={4} className="p-0 text-right">
                    {/* {showRemoveIcon && ( */}
                    <Button
                      hidden={!hover[index]}
                      variant="warning"
                      className="trash-icon-wrapper"
                      onClick={() => deleteFareAlert(item.id)}
                    >
                      <img
                        src={TrashIcon}
                        className="trash-icon"
                        alt="trash-img"
                      />
                    </Button>
                    {/* )} */}

                    <Button
                      variant="white"
                      className="reminder-icon-wrapper"
                      onClick={() => changeStatus(item.id, item.isActive)}
                    >
                      <img
                        src={
                          item.isActive ? YellowReminderIcon : GrayReminderIcon
                        }
                        className="reminder-icon"
                        alt="reminder-img"
                      />
                    </Button>
                  </Col>
                </Row>
              </Col>
            )
          );
        })}
      </Row>
    </div>
  );
};
