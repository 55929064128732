export const validatePhoneNumber = (phoneNumber) => {
    // Remove any non-digit characters from the phone number
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Define regular expressions for valid phone number formats
    const phoneRegexes = [
      /^\d{10}$/,                 // 1234567890
      /^(\d{3}-){2}\d{4}$/,       // 123-456-7890
      /^\(\d{3}\)\s?\d{3}-\d{4}$/, // (123) 456-7890 or (123)456-7890
    ];
  
    // Check if the cleaned phone number matches any of the valid formats
    return phoneRegexes.some((regex) => regex.test(cleanedPhoneNumber));
  };