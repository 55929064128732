import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Input, Button } from "layers";
import { IItemToBuy } from "models/interfaces";
import Select from "react-select";
import { useAppSelector } from "redux/store";
import { useTranslation } from "react-i18next";

interface IProp {
  contractItems: IItemToBuy[];
  setContractItems: Function;
  disabled: boolean;
}

export const AddContractItems: React.FC<IProp> = ({
  contractItems,
  setContractItems,
  disabled,
}) => {
  const [isDesignation, setIsDesignation] = useState(false);
  const [isQuantity, setIsQuantity] = useState(false);
  const [isValue, setIsValue] = useState(false);
  const [isPrice, setIsPrice] = useState(false);
  const [isWeight, setIsWeight] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const { t } = useTranslation();
  const currencies = useAppSelector((state) => state.currencies);
  const [currenciesOption, setCurrenciesOption] = useState([]);
  function getTextSize(txt, font) {
    let element = document.createElement("canvas");
    let context = element.getContext("2d");
    context.font = font;
    return context.measureText(txt).width;
  }

  useEffect(() => {
    if (contractItems) {
      for (let idx = 0; idx < contractItems?.length; idx++) {
        if (isDesignation === false) adjustInputSize("", `designation${idx}`);
        if (isQuantity === false) adjustInputSize("", `quantity${idx}`);
        if (isValue === false) adjustInputSize("", `value${idx}`);
        if (isPrice === false) adjustInputSize("", `price${idx}`);
        if (isWeight === false) adjustInputSize("", `weight${idx}`);
        if (isLink === false) adjustInputSize("", `link${idx}`);
      }
    }
  }, [contractItems]);

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      border: "transparent",
      backgroundColor: isDisabled ? "#efefef !important" : "white",
      textAlign: "center",
    }),
    option: (styles) => ({
      ...styles,
      color: "#707070",
      backgroundColor: "#fff",
      flexWrap: "nowrap",
      fontWeight: "normal",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: "-5px",
    }),
  };

  const adjustInputSize = (text, id) => {
    const nameLocation = document.getElementById(id);
    if (text?.length < 1) {
      nameLocation.style.width =
        getTextSize(nameLocation.ariaLabel, "16px sans-serif") + 10 + "px";
    } else {
      nameLocation.style.width =
        getTextSize(text, "16px sans-serif") + 10 + "px";
    }
  };

  const handleShareholderDesignationItemChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newContractItems = contractItems?.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, designation: evt.target.value };
    });
    setContractItems(newContractItems);
    setIsDesignation(true);
  };

  const handleShareholderQuantityItemChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newContractItems = contractItems?.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, quantity: evt.target.value };
    });
    setContractItems(newContractItems);
    setIsQuantity(true);
  };

  const handleShareholderValuePerUnitItemChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newContractItems = contractItems?.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, value: evt.target.value };
    });
    setContractItems(newContractItems);
    setIsValue(true);
  };

  const handleShareholderTotalPriceItemChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newContractItems = contractItems?.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, price: evt.target.value };
    });
    setContractItems(newContractItems);
    setIsPrice(true);
  };

  const handleShareholderWeightItemChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newContractItems = contractItems?.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, weight: evt.target.value };
    });
    setContractItems(newContractItems);
    setIsWeight(true);
  };

  const handleShareholderWeightUnitChange = (idx) => (selectedOption) => {
    const newContractItems = contractItems?.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, weightUnit: selectedOption.value };
    });
    setContractItems(newContractItems);
  };

  const handleShareholderCurrencyChange = (idx) => (selectedOption) => {
    const newContractItems = contractItems?.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, currencyType: selectedOption.value };
    });
    setContractItems(newContractItems);
  };

  const handleShareholderOptionalLinkItemChange = (idx) => (evt) => {
    const newContractItems = contractItems?.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, link: evt.target.value };
    });
    setContractItems(newContractItems);
    adjustInputSize(evt.target.value, evt.target.name);
    setIsLink(true);
  };

  const handleAddShareholder = () => {
    setContractItems(
      contractItems.concat([
        {
          designation: "",
          quantity: "",
          value: "",
          price: "",
          currencyType: "",
          weight: "",
          weightUnit: "",
          link: "",
        },
      ])
    );
  };

  const handleRemoveShareholder = (idx) => () => {
    setContractItems(contractItems.filter((s, sidx) => idx !== sidx));
  };

  const preventTypeText = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <p className="contract-content">Items to Buy/carry:</p>
      <form className="w-100">
        {contractItems?.map((shareholder, idx) => (
          <>
            <p className="contract-content">ITEM {idx + 1}:</p>
            <p className="contract-content" key="idx">
              - Designation:{" "}
              <Input
                id={`designation${idx}`}
                name={`designation${idx}`}
                ariaLabel={
                  shareholder.designation
                    ? `${shareholder.designation}`
                    : "(Example: Smartphone)"
                }
                placeholder={
                  shareholder.designation
                    ? `${shareholder.designation}`
                    : "(Example: Smartphone)"
                }
                className="custom-input-example-contract"
                value={shareholder.designation}
                onChange={handleShareholderDesignationItemChange(idx)}
                disabled={disabled}
              />
              - Quantity:{" "}
              <Input
                id={`quantity${idx}`}
                name={`quantity${idx}`}
                ariaLabel={
                  shareholder.quantity
                    ? `${shareholder.quantity}`
                    : "(Example: 2)"
                }
                placeholder={
                  shareholder.quantity
                    ? `${shareholder.quantity}`
                    : "(Example: 2)"
                }
                className="custom-input-contract"
                value={shareholder.quantity}
                onChange={handleShareholderQuantityItemChange(idx)}
                disabled={disabled}
              />
              - Approximate Value per unit:{" "}
              <Input
                id={`value${idx}`}
                name={`value${idx}`}
                ariaLabel={
                  shareholder.value ? `${shareholder.value}` : "(Example: 50)"
                }
                placeholder={
                  shareholder.value ? `${shareholder.value}` : "(Example: 50)"
                }
                className="custom-input-unit-contract"
                value={shareholder.value}
                onKeyPress={preventTypeText}
                onChange={handleShareholderValuePerUnitItemChange(idx)}
                disabled={disabled}
              />
              <Select
                id={`currencyType${idx}`}
                name={`currencyType${idx}`}
                className="custom-input-weight-unit-contract d-inline-block"
                options={currenciesOption}
                value={
                  shareholder.currencyType
                    ? {
                        value: shareholder.currencyType,
                        label: shareholder.currencyType,
                      }
                    : null
                }
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleShareholderCurrencyChange(idx)}
                isDisabled={disabled}
                styles={unitCustomStyle}
                placeholder="CAD"
              />
              - Total price:{" "}
              <Input
                id={`price${idx}`}
                name={`price${idx}`}
                ariaLabel={
                  shareholder.price
                    ? `${shareholder.price}`
                    : "(Unit Value X QTY)"
                }
                placeholder={
                  shareholder.price
                    ? `${shareholder.price}`
                    : "(Unit Value X QTY)"
                }
                className="custom-input-total-contract"
                value={shareholder.price}
                onChange={handleShareholderTotalPriceItemChange(idx)}
                onKeyPress={preventTypeText}
                disabled={disabled}
              />
              <Select
                id={`currencyType${idx}`}
                name={`currencyType${idx}`}
                className="custom-input-weight-unit-contract d-inline-block"
                options={currenciesOption}
                value={
                  shareholder.currencyType
                    ? {
                        value: shareholder.currencyType,
                        label: shareholder.currencyType,
                      }
                    : null
                }
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleShareholderCurrencyChange(idx)}
                isDisabled={disabled}
                styles={unitCustomStyle}
                placeholder="CAD"
              />
              - Weight of the Object:{" "}
              <Input
                id={`weight${idx}`}
                name={`weight${idx}`}
                ariaLabel={
                  shareholder.weight ? `${shareholder.weight}` : "......"
                }
                placeholder={
                  shareholder.weight ? `${shareholder.weight}` : "......"
                }
                className="custom-input-weight-contract"
                value={shareholder.weight}
                onChange={handleShareholderWeightItemChange(idx)}
                disabled={disabled}
                onKeyPress={preventTypeText}
              />
              <Select
                id={`weightUnit${idx}`}
                name={`weightUnit${idx}`}
                className="custom-input-weight-unit-contract d-inline-block"
                options={unitWeightOption}
                value={
                  shareholder.weightUnit
                    ? {
                        value: shareholder.weightUnit,
                        label: shareholder.weightUnit.toUpperCase(),
                      }
                    : null
                }
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleShareholderWeightUnitChange(idx)}
                isDisabled={disabled}
                styles={unitCustomStyle}
                placeholder="KG"
              />
              - Optional Link:{" "}
              <Input
                id={`link${idx}`}
                name={`link${idx}`}
                ariaLabel={
                  shareholder.link ? `${shareholder.link}` : "(Optional)"
                }
                placeholder={
                  shareholder.link ? `${shareholder.link}` : "(Optional)"
                }
                className="custom-input-optional-contract"
                value={shareholder.link}
                onChange={handleShareholderOptionalLinkItemChange(idx)}
                disabled={disabled}
              />
              {!disabled &&
                (idx === 0 ? (
                  <Button
                    variant="white"
                    onClick={handleAddShareholder}
                    className="add-companion-btn ml-auto"
                  >
                    +
                  </Button>
                ) : (
                  <Button
                    variant="white"
                    onClick={handleRemoveShareholder(idx)}
                    className="remove-companion-btn ml-auto"
                  >
                    -
                  </Button>
                ))}
            </p>
          </>
        ))}
      </form>
    </>
  );
};
