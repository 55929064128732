import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getUserInfo } from "redux/actions/Authorization";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { ToastContainer } from "react-toastify";
import { getAllCurrencies } from "redux/actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { getAllAnnouncement } from "redux/actions/notifications";
import { INotification } from "models/interfaces";
import { useTranslation } from "react-i18next";
import { NotificationsBox } from "components/notifications";

export const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const size = UseWindowSize();
  const token = window.localStorage.getItem("token");
  const [openTransactionIndex, setOpenTransactionIndex] = useState<
    number | null
  >(null);
  const [allAnnouncement, setAllAnnouncement] = useState<INotification[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getAllCurrencies());
  }, [token]);

  window.addEventListener("storage", () => {
    window.localStorage.setItem("token", window.localStorage.getItem("token"));
  });
  const allNotification: any = useAppSelector((state) => state.allNotification);

  useEffect(() => {
    const data = {
      page: currentPage,
      pageSize: 10,
    };
    dispatch(getAllAnnouncement(data));
  }, [currentPage]);

  useEffect(() => {
    if (allNotification?.isSuccess) {
      if (currentPage > 1) {
        setAllAnnouncement([
          ...allAnnouncement,
          ...allNotification?.data?.data,
        ]);
      } else setAllAnnouncement(allNotification?.data?.data);
      if (allNotification.data.pageCount > 1) {
        setHasMoreData(true);
      }
    }
  }, [allNotification]);

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>{t("notifications")}</h2>
      </div>
      <div className="flex-grow-1 request-info-wrapper mb-3">
        <Row style={{ placeContent: "center" }}>
          <Col lg={10} md={10} sm={12}>
            <NotificationsBox />{" "}
          </Col>
        </Row>
      </div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
