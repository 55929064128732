export const resources = {
  en: {
    translation: {
      hello: "Hello",
      greet: "Hello, {{name}}!",
      documentation: "Go To Documentation",
      requests: "Requests",
      availableRequests: "Available Packages",
      availableTravelers: "Available Travelers",
      login: "Sign in",
      fullName: "Full Name",
      register: "Register",
      home: "Home",
      yourAlerts: "Your Alerts",
      contact: "Contact us",
      reasonForClosingAccount: "Reason for closing account",
      more: "More",
      loginSignup: "Login/Sign up",
      iAmATraveler: "I am a traveler",
      iAmLookingForTravelers: "I am looking for travelers",
      flightNumber: "Flight Number",
      packages: "PKG Type",
      flightDate: "Flight Date",
      idExpirationDate: "ID Expiration Date",
      IDontHaveMyFlightNumber: "I don't have my flight number",
      direct: "Direct",
      indirect: "Indirect",
      activationCode: "Activation code will pop up in your email",
      enterTheFlightNumbers: "Enter The Flight Numbers",
      addTheConnectingFlights: "Add the connecting flights",
      confirm: "Confirm",
      province: "Province",
      originalPrice: "Original Price",
      operationsFee: "Operations Fee",
      commissionFee: "Commission Fee",
      removeTheFlight: "Remove The Flight",
      latestSentOffer: "Latest Offers",
      latestSentRequest: "Latest Requests",
      linkCopied: "Link Copied",
      copyLink: "Copy Link",
      saveCard: "Save card",
      from: "From",
      to: "To",
      ok: "OK",
      fromDate: "From",
      toDate: "To",
      searchThisFlight: "Search This Flight",
      selectThisFlight: "Select This Flight",
      defineFlightNo: "define flight number to start",
      type: "Type",
      PackagesType: "Package Types",
      companionWarning:
        "Traveling Companion' cannot be selected with other package types.",
      all: "All",
      cloths: "Cloths",
      gadget: "Gadget",
      reportProblem: "Report a problem",
      describeProblem: "Describe your problem",
      cosmetics: "Cosmetics",
      next: "Next",
      moreInfoToHelpUs:
        "Please provide a little more information to help us improve.",
      supplement: "Supplement",
      documents: "Documents",
      accessories: "Accessories",
      optional: "Optional",
      completeCheckout: "Complete Checkout",
      verify: "Verify",
      updateInformation: "Update Information",
      addBankAccount: "Add Bank Account",
      securePayouts: "Secure Payouts",
      displayName: "Nick Name",
      previewOn: "Preview on",
      writeReview: "Write your review for",
      reVerifyAddress:
        "Attention: If you change your verified address, you will need to re-verify it",
      phoneNumber: "Phone Number",
      noData: "There is no data",
      pickup: "Pickup",
      subject: "Subject",
      reportAProblem: "Report",
      changePassword: "Change Password",
      shippingDateRange: "Shipping Date Range",
      proofOfDelivery: "Proof of Delivery",
      proofOfAddress: "Proof of address",
      chooseCancellationReason: "Choose the reason for canceling your request",
      chooseDenyingReason: "Choose the reason for denying",
      dropoff: "Drop off",
      viewRequests: "View Requests",
      reject: "Reject",
      purchaseAndPost: "Purchase & Post",
      PurchaseAndDropOff: "Purchase & Drop off",
      services: "Services",
      addMyTravel: "Add my travel",
      itemValue: "Item Value",
      shippingDeadline: "Delivery Deadline",
      packageID: "Package ID",
      PKGID: "PKG ID",
      TRVID: "TRV ID",
      offer: "Offer",
      passportExpirationDate: "Passport Expiration Date",
      sureEditProfile: "Are you sure you want to Edit this profile?",
      acceptOffer: "Accept Offer",
      acceptAndMakeOffer: "Accept / Make Offer",
      addYourTravelInfo: "Add Your Travel Info",
      fromCityCountry: "From City/Country",
      toCityCountry: "To City/Country",
      yes: "Yes",
      no: "No",
      city: "City",
      verified: "Verified",
      logout: "Logout",
      beingEvaluated: "Being Evaluated",
      departureDate: "Departure Date",
      arrivalTime: "Arrival",
      departureTime: "Departure",
      DepartureFirsTab: "Departure",
      arrivalFirstTab: "Arrival",
      small: "Small",
      medium: "Medium",
      large: "Large",
      xlarge: "Xlarge",
      smallExample: "Ex: Keys, Document",
      mediumExample: "Ex: Cell Phone, Book, Bag",
      largeExample: "Ex: Big Box, Instrument, Pet",
      xLargeExample: "Ex: 23kg Luggage, Pallet",
      back: "Back",
      dear: "Dear",
      reuse: "Reuse",
      travelDate: "Travel Date",
      setAlert: "Set Alert",
      snoopAlert: "Set a SenderSnoop Alert",
      trackAlert: "Set a TravelerTrack Alert",
      DepartureSecondTab: "Departure",
      arrivalSecondTab: "Arrival",
      arrivalDate: "Arrival Date",
      weightKgLbs: "Weight (KG/LBS)",
      flightNotFound: "Flight not found!",
      addYourTravelInfoManually: "Add Your Travel Info Manually",
      owner: "Owner",
      postalCode: "Postal/Zip Code",
      viewAll: "View All",
      uploadPassportFrontPage:
        "Verify Your Passport (All corners must be visible)",
      uploadIdPage: "Upload ID Driving License/PR Card/ Health Insurance Card",
      uploadIdDriving:
        "Upload ID Driving License/PR Card/ Green Card/National Card)",
      koolbaarDescFooter:
        "Koolbaar is an eco-friendly, cost-effective crowd-shipping platform connecting senders and travelers, prioritizing trust, security, and sustainability.",
      noPackage: "No Package is available",
      noTraveler: "No Traveler is available",
      noRequest: "No request is available",
      noOffer: "No offer is available",
      myTravels: "My Travels",
      myPackages: "My Packages",
      addNew: "Add New",
      about: "About",
      edit: "Edit",
      editRequest: "Edit Package",
      searchTrvId: "Search Travel Id",
      searchPkgId: "Search Package Id",
      viewOffers: "View Offers",
      viewContract: "View/Create Contract",
      location: "Location",
      images: "Images",
      timeline: "Timeline",
      displayAll: "Display All",
      changeFlight: "Change Flight",
      accept: "Accept",
      updateOrEdit: "Update / Edit",
      search: "Search",
      myAccount: "My Account",
      upload: "Upload",
      reviews: "Reviews",
      manage: "Manage",
      personalInfo: "Personal details",
      personal: "Personal",
      idVerification: "Id Verification",
      identityVerification: "Identity verification",
      fee: "Fee",
      closeAccount: "Close Account",
      accountManagement: "Account management",
      character: "Character",
      searchTraveler: "Search Traveler",
      searchPackage: "Search Packages",
      departureInfo: "From Where?",
      arrivalInfo: "To Where?",
      addMyRequest: "Add my package",
      addRequest: "Add Package",
      approve: "Approve",
      subtotal: "Subtotal",
      rating: "Rating",
      transactionDesc: "Get the list of your recent transactions.",
      notificationDesc: "Get the list of your recent notifications.",
      personalInfoDesc: "Update your info and find out how it's used",
      securityDesc:
        "Change your security settings, set up secure authentication, or delete your account.",
      selectYourCountry: "Please select your country",
      howItWorks: "How it works",
      updateStatus: "Update Status",
      maxSize: "Max Size",
      servicesProvided: "Services Provided",
      size: "Size",
      maxItemValue: "Max Item Value",
      maxWeight: "Max Weight",
      weight: "Weight",
      OfferId: "Offer ID",
      moreDetails: "Detail",
      sendRequest: "Send Request",
      aboutMe: "About Me",
      proofOfTravel: "Proof of travel",
      financial: "Financial",
      travelID: "Travel ID",
      less: "Less",
      describeReason: "Describe your reason",
      usefulLinks: "Useful Links",
      ourVisionAndMission: "Our Vision & Mission",
      visionAndMission: "Vision & Mission",
      ourServices: "Our Services",
      boardOfDirectors: "Founder's Profile",
      profile: "Profile",
      aboutUs: "About us",
      aboutKoolbaar: "About Koolbaar",
      termsAndConditions: "Terms & Conditions",
      privacyPolicy: "Privacy Policy",
      prohibitedItems: "Prohibited Items",
      cancel: "Cancel",
      chat: "Chat",
      removeMyTravel: "Remove My Travel",
      removeMyPackage: "Remove My Package",
      confirmRemoveCard: "Are you sure you want to delete this item?",
      amount: "Amount (CAD)",
      exit: "Exit",
      continue: "Continue",
      country: "Country",
      sureWantCloseAccount: "Are you sure you want to close your account?",
      transactions: "Transactions",
      exitDesc: "Are you sure you want to Exit?",
      countryCityAirport: "Country, City",
      onlinePaymentForCanada:
        "Attention! Online payments are available for Canada only.",
      iAcceptKoolbaarTerms:
        "I accept Koolbaar Terms & Conditions and have read the Koolbaar Privacy Policy. ",
      submitTheRequest: "Submit Request ",
      message: "Message",
      close: "Close",
      denyDelivery: "Deny Delivery",
      cancellation: "Cancellation",
      uploadThePackagePhotos:
        "Upload The Package Photos (Only *.jpeg and *.png)",
      selectPackageTypePlaceholder:
        "Select package type from available options",
      paymentMethod: "Payment Method",
      verificationCodeSent: "Verification code has been sent",
      payment: "Payment",
      SigninWith: "Sign in / Register",
      facebook: "Facebook",
      google: "Google",
      filters: "Filters",
      removeFilters: "Remove Filters",
      applyFilters: "Apply Filters",
      email: "Email",
      state: "State/Province",
      emailAddress: "Email Address",
      address: "Address",
      forgotPassword: "Forgot Password? ",
      password: "Password",
      retypePass: "Retype Password",
      share: "Share",
      click: "Click",
      here: "Here",
      dashboard: "Dashboard",
      or: "OR",
      security: "Security",
      firstName: "First Name",
      sendVerificationCode: "Send Verification Code",
      lastName: "Last Name",
      sloganBanner:
        "Travel, Earn, Help Others, and Transform Shipping's Future",
      aboutUsExample: "eg. I am a PhD student that lives downtown Toronto",
      aboutUsParagraph1:
        "Welcome to Koolbaar, your premier crowd-shipping platform connecting people needing parcel delivery with trusted and verified travelers. Our innovative platform revolutionizes how parcels are delivered, offering a cost-effective and eco-friendly solution that benefits both senders and travelers.",
      aboutUsParagraph2:
        "Our platform bridges the gap between individuals looking to send parcels and travelers with available luggage space. Through mutual agreements, senders can have their items delivered reliably and affordably, while travelers get the opportunity to monetize their journeys.",
      aboutUsParagraph3:
        "What sets Koolbaar apart is our commitment to trust and security. All travelers in our network undergo a rigorous verification process, ensuring that your parcels are in safe hands. We prioritize transparency and open communication, giving you peace of mind throughout the delivery journey.",
      aboutUsParagraph4:
        "For senders, Koolbaar offers a seamless experience. Say goodbye to exorbitant shipping fees and hello to a more sustainable way of getting your items where they need to be. By leveraging the spare luggage space of travelers already headed in the right direction, we're minimizing carbon footprints and making the most of existing travel routes.",
      aboutUsParagraph5:
        "Travelers, too, find Koolbaar to be a valuable platform. Monetize your trips by offering up your extra luggage space to senders. Whether you're traveling for business or pleasure, your journeys can now contribute to your finances meaningfully. Koolbaar empowers you to make the most of your travel plans while helping others.",
      aboutUsParagraph6:
        "Join us in reshaping the future of parcel delivery. Experience a community-driven platform that puts collaboration, cost savings, and environmental consciousness at the forefront. Discover the convenience and benefits of Koolbaar today.",
      serviceFirstLine:
        "You may post your Ad on Koolbaar as a Traveler or a Sender.",
      serviceSecondLine: "Koolbaar will provide you with the Services below:",
      deliveredTrustedTitle: "Delivery by trusted and verified travelers",
      temptNumberTitle:
        "We tempt members to complete their profiles with the following:",
      temptNumberItem1: "A Profile photo",
      temptNumberItem2:
        "A valid Identity Document. (Passport, PR Card, Green Card, Driving License, etc.)",
      temptNumberItem3: "Address of residence.",
      temptNumberItem4:
        "A valid   e-mail address and a mobile telephone number.",
      temptNumberItem5: "Their social network accounts.",
      temptNumberEnd:
        "Before any transaction, the identity of the user must be made known. These considerations allow us to suspend fraudulent profiles and prevent the creation of fake accounts.",
      usersEvaluationTitle: "Users evaluation system",
      usersEvaluationTitleItem:
        "The goal of evaluating our members is to level them based on their experience with Koolbaar, their profile information, their received rates, and the Koolbaar Evaluation Matrix.",
      usersEvaluationItem1: "Regular User",
      usersEvaluationItem2: "Bronze User",
      usersEvaluationItem3: "Silver User",
      usersEvaluationItem4: "Golden  User",
      securePaymentTitle: "Secure Payment Service",
      securePaymentItem1:
        "Koolbaar relies on the services of its partner Stripe to manage all the payments that take place on its platform.",
      securePaymentItem2:
        "Stripe is an American company specializing in the international management of payments via the Internet for individuals and professionals.Stripe is already the payment provider for well-known services such as Facebook and Deliveroo.",
      securePaymentItem3:
        "At no time will Koolbaar have access to personal credit card numbers, and will not keep them in its database.",
      securePaymentItem4:
        "The Sender pays the total amount by credit through our partner Stripe.",
      securePaymentItem5:
        "The amount is locked in until the actual delivery of the item.Both traveler and sender confirm the delivery.The traveler will be paid the agreed amount unless the Sender does not confirm the receiving.",
      trackingServiceTitle: "Tracking Service",
      trackingServiceItem1:
        "You will be able to see the last status of your shipment.",
      trackingServiceItem2:
        "You will be notified when your parcel reaches the predetermined stages by SMS or WhatsApp.",
      refundPolicyTitle: "Refund Policy",
      refundPolicyItem:
        "In the event of a declined delivery or any disputes, our refund process comes into play. We thoroughly review the situation, taking into account evidence such as proof of deliveries, timelines, agreements, and other relevant details. If the sender's claim is valid, we initiate a refund process, deducting the applicable commission and operations costs. This ensures fair resolutions while maintaining transparency.",
      chatFunctionality: "User-to-User Chat Functionality",
      chatFunctionalityItem:
        "Communication is key, and our platform facilitates direct communication between users. You can chat with other members to discuss delivery details, coordinate pickup and drop-off locations, and address any questions or concerns. Our integrated chat feature ensures smooth collaboration and enhances your experience.",
      contractSenderTravelerTitle:
        "The contract between the Sender and the traveler",
      contractSenderTravelerItem1:
        "Koolbaar provided you with a contract for transporting parcels between individuals, which is strongly recommended for security, transparency, and trust. It will enable the two parties to gather all the information necessary for the safe delivery of the package.",
      contractSenderTravelerItem2:
        "Transporting parcels between individuals is a new service, not yet framed by the Law in several countries, involving risks including loss, damage, or theft. This contract is the first step toward Koolbaar's goal of minimizing risks as much as possible. Both parties will negotiate more seriously, with clarity and tranquility.",
      contractSenderTravelerItem3:
        "We take this opportunity to remind you that Koolbaar is not liable for any loss, damage, or theft of the package because we do not intervene in our members' transactions.",
      reviewRatingTitle: "Leaving Reviews and Ratings",
      reviewRatingItem:
        "As part of our commitment to transparency, you can leave reviews and rate your experience with other users. After a successful delivery, senders and travelers can provide interaction feedback. This system promotes accountability and helps fellow users make informed decisions when engaging in transactions.",
      sendParcelTitle: "Do you want to send a parcel?",
      sendParcelDescription:
        "The sender posts their Ad on the Koolbaar Platform to find a traveler interested in carrying a package, document, etc.",
      sendParcelItem1:
        "Register and search for potential travelers by indicating the Shipping deadline, city or country of departure, and parcel destination.",
      sendParcelItem2: "Choose from travelers who will go to that destination.",
      sendParcelItem3:
        "Place your offer, discuss the terms, and details via messaging or phone.",
      sendParcelItem4:
        "Both Sender and Traveler need to complete the necessary fields for creating an Agreement.",
      sendParcelItem5:
        "The Sender pays through the Stripe-secured platform. The amount is locked in until the actual delivery of the item.",
      sendParcelItem6:
        "Koolbaar will add a 18% that includes commission and Operations fee. This allows us to cover our operational costs.",
      sendParcelItem7:
        "Koolbaar creates the official Agreement between the sender and the traveler.",
      sendParcelItem8: "The sender hands over the package to the traveler.",
      sendParcelItem9: "The sender confirms the delivery at the destination.",
      sendParcelItem10:
        "The agreed amount will release, and Koolbaar will pay the traveler.",
      sendParcelItem11:
        "The sender can rate and leave a comment on the received service.",
      carryParcelTitle:
        "Do you want to carry a parcel and earn money while traveling?",
      carryParcelDescription:
        "The traveler with room in his luggage posts their Ad on the Koolbaar Platform to find potential senders.",
      carryParcelItem1:
        "Register and search for potential travelers by indicating the date, the cities or country of departure, and the travel destination.",
      carryParcelItem2:
        "Choose one or more senders, Send your Request, and discuss the terms, a reasonable fee, and details.",
      carryParcelItem3:
        "Both Sender and Traveler need to complete the necessary fields for creating an Agreement.",
      carryParcelItem4:
        "The Sender pays the agreed amount through the Stripe-secured platform. This amount is locked in until the actual delivery of the item.",
      carryParcelItem5:
        "Koolbaar creates the official Agreement between the sender and the traveler.",
      carryParcelItem6:
        "The sender hands over the package to the traveler, and the traveler carries the package.",
      carryParcelItem7:
        "The traveler confirms the delivery to the final receiver, and then the sender needs to approve the receiving.",
      carryParcelItem8:
        "The traveler will be paid the agreed amount unless the Sender does not confirm the receiving.",
      carryParcelItem9: "The traveler can rate the sender and leave a comment",
      addTravel: "Add Travel",
      editTravel: "Edit Travel",
      addBankAccountDesc:
        "Securely add your bank account or other preferred payment methods to facilitate smoother payouts.",
      identityVerificationDesc:
        "Upload your ID and easily verify yourself within the system for added security and trust.",
      RetrievePreviousTravel: "Retrieve previous travel",
      IWillTravelFrom: "I will travel from",
      travelDepartureDate: "Departure Date",
      chatwith: "Chat with",
      loadMore: "Load More",
      notifications: "Notifications",
      travelArrivalDate: "Arrival Date",
      maxSizeParcelCarry: "Max Parcel Size",
      selectMaxSizePlaceholder: "Select maximum size from available options",
      definedAs: "Defined as",
      maxWeightOf: "Max Parcel Weight",
      payableBy: "Payment Method",
      passportUpload: "Passport Upload",
      addressUpload: "Upload your recent Bank Statement",
      licenseUpload: "Upload ID Driving License/PR Card/ Health Insurance Card",
      attentionTravelerPart1:
        "Attention Travelers! 'Payment by Koolbaar' is for Canada only. Provide your",
      attentionTravelerPart2: " bank details",
      attentionTravelerPart3: " for faster payouts.",
      cashBeforeShippingWarning:
        "Selecting 'Cash before shipping' will disable various features, including Cancellation Policy, Agreement creation, proof of delivery, Refund, and Tracking. ",
      packageType: "Package Type",
      acceptablePackageType: "Acceptable Package Types",
      packageTypes: "Package Types",
      providedService: "Services I Can Provide",
      deliveryDateWillBe: "Delivery Date Range",
      flightNameIs: "Airline Name",
      andFlightNumber: "Flight Number",
      sender: "Sender",
      traveler: "Traveler",
      cancellationPolicy: "Cancellation Policy",
      selectCancellationPlaceholder:
        "Select cancellation policy from available options",
      uploadTicketOptional: "Upload Your Ticket (Optional)",
      dropImageHere:
        "Drop images here or click to upload (Only *.jpeg and *.png)",
      noteDetail: "Note/Detail (Optional)",
      note: "Note",
      currencyWarning:
        "Attention: Online payments only available in CAD currency",
      acceptAllVisibleDocs:
        "I accept all visible documents but passport. (Maximum 100 characters)",
      termConditionLinkText:
        "I agree with the terms and conditions defined on this",
      termConditionLink: "link",
      termConditionLink2: "",
      submitRequest: "Submit",
      uploadMore: "Upload More",
      retrievePreviousPackage: "Retrieve previous package",
      shipFind: "I want to ship/find my",
      atWeight: "Weight",
      andSize: "Parcel Size",
      betweenFromDate: "Between",
      forPrice: "The proposed amount",
      iWantShipFind: "Delivery Deadline",
      deliveryDeadlineIs: "Delivery Deadline",
      providedServicePlaceholder: "Select services from available options",
      requestedServices: "Requested Services",
      uploadPkgImgOptional: "Upload Package Image (Optional)",
      shipExample: "I want to ship a book. (Maximum 100 characters)",
      itemsLinks: "Items' link to be purchased",
      qty: "QTY",
      travelHistory: "Travel History",
      packageHistory: "Package History",
      acceptAllGovernmentTaxes:
        "I accept that this offer includes all expenses except the one forced by the government like import fee taxes, etc.",
      counterpart: "Counterpart",
      selectFromOptions: "Select from available options",
      createPassContains: "Create a password that contains at least",
      eightChars: "8 characters",
      oneNumber: "1 number",
      uppercaseLowercase: "uppercase and Lowercase letters",
      specialChar: "1 special character",
      sorrySeeYouGo: "we’re sorry to see you go",
      reasonForCloseAccount: "Tell us the reason for closing your account",
      ourVision: "Vision",
      ourMission: "Mission",
      visionDesc:
        "To create a world where parcel delivery is collaborative, cost-effective, and eco-conscious, connecting people globally while minimizing environmental impact.",
      missionTitle:
        "At Koolbaar, our mission is to revolutionize parcel shipping and delivery by",
      missionTitle1: "Connecting Senders and Travelers",
      missionDesc1:
        "We strive to bridge the gap between individuals who need to send parcels and trusted travelers with available luggage space, fostering a global community of cooperation",
      missionTitle2: "Ensuring Trust and Security",
      missionDesc2:
        "We are committed to providing a secure and transparent platform where all travelers undergo rigorous verification, guaranteeing the safety of parcels and peace of mind for users.",
      missionTitle3: "Prioritizing Sustainability",
      missionDesc3:
        "We are dedicated to reducing carbon footprints and promoting environmental consciousness by making the most of existing travel routes and minimizing wasteful shipping practices",
      missionTitle4: "Empowering Travelers",
      missionDesc4:
        "We aim to empower travelers to monetize their journeys by offering extra luggage space to senders, contributing to their financial well-being while helping others.",
      missionTitle5: "Fostering Collaboration",
      missionDesc5:
        "We believe in a community-driven approach that emphasizes collaboration, cost savings, and eco-friendly parcel delivery solutions.",
      missionLastDesc:
        "Through these principles, we strive to reshape the future of parcel delivery, making it more convenient, affordable, and environmentally responsible for all.",
      accountBenefits: "Account type and benefits",
      verifyUserAccount:
        "Notice: For your safety, it is strongly recommended to deal with verified users on Koolbaar. Verified users have undergone additional checks and validations, reducing potential risks in your transactions.",
      regular: "Regular",
      bronze: "Bronze",
      silver: "Silver",
      golden: "Golden",
      profilePic: "Profile Picture",
      validPass: "Valid Passport",
      validPhone: "Valid Phone Number",
      validBankAccount: "Valid Bank Account",
      validID: "Valid ID",
      successfulTransaction: "Successful Transaction",
      noPkgAd: "No Package Ad detected! Please add your package first",
      noTrvAd: "No Travel Ad detected! Please add your Travel first",
    },
  },
  fa: {
    translation: {
      ourVision: "چشم انداز",
      ourMission: "ماموریت",
      verifyUserAccount:
        "توجه: برای ایمنی شما، اکیداً توصیه می شود با کاربران تأیید شده در کولبار معامله کنید. کاربران تایید شده تحت بررسی‌ها و اعتبارسنجی‌های بیشتری قرار گرفته‌اند که خطرات احتمالی کلاهبرداری در معاملات شما را کاهش می‌دهد.",
      visionDesc:
        "برای ایجاد جهانی که در آن تحویل بسته به صورت مشارکتی، مقرون به صرفه و آگاهانه از محیط زیست باشد، مردم را در سطح جهانی به هم متصل کند و در عین حال اثرات زیست محیطی را به حداقل برساند.",
      missionTitle:
        "ماموریت کولبار ایجاد انقلابی در ارسال و تحویل بسته است از طریق",
      missionTitle1: "اتصال فرستنده ها و مسافران",
      missionDesc1:
        "ما در تلاش هستیم تا شکاف بین افرادی را که نیاز به ارسال بسته دارند و مسافران مورد اعتماد با فضای چمدان موجود، پر کنیم و جامعه جهانی همکاری را تقویت کنیم",
      missionTitle2: "اطمینان از اعتماد و امنیت",
      missionDesc2:
        "ما متعهد به ارائه یک پلت فرم ایمن و شفاف هستیم که در آن همه مسافران مورد تأیید دقیق قرار می گیرند و ایمنی بسته ها و آرامش خاطر را برای کاربران تضمین می کند",
      missionTitle3: "اولویت‌بندی پایداری",
      missionDesc3:
        "ما به کاهش ردپای کربن و ارتقای آگاهی زیست‌محیطی با استفاده حداکثری از مسیرهای سفر موجود و به حداقل رساندن شیوه‌های حمل و نقل بیهوده اختصاص داریم",
      missionTitle4: "توانمندسازی مسافران",
      missionDesc4:
        "هدف ما این است که با ارائه فضای چمدان اضافی به فرستندگان، مسافران را برای کسب درآمد از سفرهای خود توانمند کنیم و به رفاه مالی آنها کمک کنیم و به دیگران کمک کنیم",
      missionTitle5: "تقویت همکاری",
      missionDesc5:
        "ما به یک رویکرد جامعه محور معتقدیم که بر همکاری، صرفه جویی در هزینه و راه حل های تحویل بسته سازگار با محیط زیست تاکید دارد",
      missionLastDesc:
        "از طریق این اصول، ما در تلاش هستیم تا آینده تحویل بسته را تغییر دهیم و آن را راحت‌تر، مقرون به صرفه‌تر و از نظر زیست‌محیطی برای همه مسئولیت‌پذیر کنیم",
      accountBenefits: "نوع حساب کاربری و مزایای آن",
      hello: "سلام",
      greet: "سلام, {{name}}!",
      documentation: "برو به دایکومنت",
      availableRequests: "بسته های موجود",
      availableTravelers: "مسافران موجود",
      login: "ورود",
      register: "ثبت نام",
      home: "خانه",
      contact: "تماس با ما",
      more: "بیشتر",
      loginSignup: "ورود/ثبت نام",
      iAmATraveler: "مسافر هستم",
      iAmLookingForTravelers: "دنبال مسافر می گردم",
      flightNumber: "شماره پرواز",
      flightDate: "تاریخ پرواز",
      iDontHaveMyFlightNumber: "شماره پروازم را ندارم",
      direct: "مستقیم",
      indirect: "غیر مستقیم",
      enterTheFlightNumbers: "شماره پروازها را وارد کنید",
      addTheConnectingFlights: "اضافه کردن پروازهای اتصالی",
      addBankAccountDesc:
        "به صورتی کاملا امن اطلاعات بانکی یا سایر روش‌های پرداخت خود را برای پرداختی راحت تر اضافه کنید.",
      confirm: "تائید",
      updateOrEdit: "ویرایش",
      profile: "پروفایل",
      removeTheFlight: "حذف پرواز",
      shippingDateRange: "محدوده تاریخ ارسال",
      countryCityAirport: "کشور، شهر",
      from: "مبدا",
      to: "مقصد",
      fromDate: "از",
      toDate: "تا",
      searchThisFlight: "جستجوی پرواز",
      selectThisFlight: "انتخاب پرواز",
      type: "نوع بسته",
      all: "همه",
      cloths: "پوشاک",
      gadget: "وسایل الکترونیکی",
      cosmetics: "وسایل آرایشی",
      supplement: "مکمل، دارو",
      documents: "مدارک",
      accept: "پذیرفته شده",
      accessories: "تزئینی و متفرقه",
      post: "امکان ارسال به مقصد نهایی",
      pickup: "امکان تحویل گرفتن محموله در مبدا",
      dropoff: "امکان تحویل دادن محموله در مقصد",
      purchaseAndPost: "امکان خرید و ارسال به مقصد نهایی",
      PurchaseAndDropOff: "امکان خرید و تحویل دادن در مقصد نهایی",
      services: "خدمات",
      addMyTravel: "اضافه کردن آگهی سفر",
      itemValue: "ارزش محموله",
      shippingDeadline: "آخرین مهلت تحویل در مقصد",
      packageID: "شناسه بسته",
      PKGID: "شناسه بسته",
      TRVID: "شناسه سفر",
      offer: "پیشنهاد",
      DepartureFirsTab: "ابتدای بازه درخواست حمل",
      arrivalFirstTab: "انتهای بازه درخواست حمل",
      DepartureSecondTab: "تاریخ حرکت مسافر",
      arrivalSecondTab: "تاریخ  رسیدن مسافر",
      sureEditProfile: "آیا مطمئن هستید که می خواهید این نمایه را ویرایش کنید؟",
      acceptOffer: "قبول پیشنهاد ",
      acceptAndMakeOffer: "پذیرش/ارائه پیشنهاد",
      addYourTravelInfo: "مبدا و مقصد سفرتان را وارد کنید",
      fromCityCountry: "مبدا شهر/کشور",
      defineFlightNo: "شماره پرواز را برای شروع تعریف کنید",
      toCityCountry: "مقصد شهر/کشور",
      arrivalDate: "تاریخ رسیدن",
      arrivalTime: "انتهای بازه تنظیم هشدار",
      departureDate: "تاریخ حرکت",
      departureTime: "ابتدای بازه تنظیم هشدار",
      flightNotFound: "پروازی یافت نشد!",
      addYourTravelInfoManually: "اطلاعات پروازتان را وارد کنید",
      owner: "آگهی دهنده",
      passportExpirationDate: "تاریخ انقضای پاسپورت",
      idExpirationDate: "تاریخ انقضای شناسه",
      location: "موقعیت",
      images: "عکس ها",
      edit: "ویرایش",
      regular: "عادی",
      bronze: "برنزی",
      silver: "نقره ای",
      golden: "طلایی",
      profilePic: "عکس پروفایل",
      validPass: "پاسپورت معتبر",
      validPhone: "شماره تلفن معتبر",
      validBankAccount: "حساب بانکی معتبر",
      validID: "کارت شناسایی معتبر",
      successfulTransaction: "تراکنش موفق",
      cancel: "لغو",
      changeFlight: "تغییر دادن پرواز",
      search: "جستجو",
      searchTraveler: "جستجوی مسافر",
      searchPackage: "جستجوی بسته ها",
      departureInfo: "مبدا",
      arrivalInfo: "مقصد",
      sloganBanner:
        "سفر کن، درآمد کسب کن، به دیگران کمک کن، و آینده حمل و نقل را متحول کن",
      noPkgAd:
        "هیچ آگهی بسته ای شناسایی نشد! لطفا ابتدا آگهی بسته خود را اضافه کنید",
      noTrvAd:
        "هیچ آگهی سفری شناسایی نشد! لطفا ابتدا آگهی سفر خود را اضافه کنید",
      addMyRequest: "اضافه کردن آگهی ارسال بار",
      addRequest: "اضافه کردن آگهی ارسال بار",
      editRequest: "ویرایش بسته",
      rating: "رتبه",
      maxSize: "حداکثر اندازه",
      size: "اندازه",
      maxItemValue: "حداکثر ارزش",
      maxWeight: "حداکثر وزن",
      weight: "وزن",
      myTravels: "سفرهای من",
      myPackages: "بسته های من",
      weightKgLbs: "(کیلوگرم/پوند) وزن",
      viewOffers: "مشاهده پیشنهادات",
      moreDetails: "جزئیات",
      sendRequest: "ارسال درخواست",
      aboutMe: "درباره من",
      viewContract: "مشاهده قرارداد",
      proofOfTravel: "تائیدیه سفر",
      travelID: "شناسه سفر",
      subject: "موضوع",
      updateStatus: "ویرایش وضعیت",
      fullName: "نام و نام خانوادگی",
      usefulLinks: "لینک های مفید",
      reportAProblem: "گزارش",
      acceptablePackageType: "انواع بسته قابل قبول",
      selectPackageTypePlaceholder:
        "نوع بسته را از گزینه های موجود انتخاب کنید",
      proofOfDelivery: "اثبات تحویل",
      proofOfAddress: "اثبات آدرس",
      reject: "رد کردن",
      describeProblem: "مشکل خود را شرح دهید",
      share: "اشتراک گذاری",
      ourVisionAndMission: "چشم انداز و ماموریت ما",
      visionAndMission: "چشم انداز و ماموریت",
      ourServices: "خدمات ما",
      howItWorks: "چگونه کار می کند",
      aboutUs: "درباره ما",
      aboutKoolbaar: "درباره کولبار",
      termsAndConditions: "شرایط و ظوابط",
      exit: "خروج",
      exitDesc: "آیا مطمئنید که می‌خواهید خارج شوید؟",
      privacyPolicy: "سیاست حفظ حریم خصوصی",
      iAcceptKoolbaarTerms:
        "من شرایط و ضوابط کولبار را می پذیرم و سیاست حفظ حریم خصوصی کولبار را مطالعه کرده ام.",
      submitTheRequest: "ثبت درخواست",
      message: "پیام",
      reportProblem: "گزارش مشکل",
      uploadThePackagePhotos: "بارگذاری عکس های محموله",
      SigninWith: "ورود با اکانت",
      facebook: "فیسبوک",
      google: "گوگل",
      emailAddress: "آدرس ایمیل",
      forgotPassword: "رمز عبور را فراموش کرده اید؟",
      password: "رمز عبور",
      click: "کلیک کنید",
      accountManagement: "مدیریت حساب کاربری",
      optional: "اختیاری",
      displayName: "نام مستعار",
      here: "اینجا را",
      dashboard: "داشبورد",
      less: "کمتر",
      logout: "خروج",
      or: "یا",
      packages: "نوع بسته",
      paymentMethod: "روش پرداخت",
      selectCancellationPlaceholder:
        "سیاست لغو را از گزینه های موجود انتخاب کنید",
      firstName: "نام",
      cancellation: "لغو",
      lastName: "نام خانوادگی",
      aboutUsExample:
        "به عنوان مثال. من دانشجوی دکترا هستم که در شهر تورنتو زندگی می کنم",
      aboutUsParagraph1:
        "به کولبارخوش آمدید، یک پلتفرم برتر حمل و نقل جمعی که افرادی را که به ارسال بسته نیاز دارند به مسافران مورد اعتماد و تأیید شده متصل می کند. پلتفرم نوآورانه ما نحوه ارسال و تحویل بسته ها را متحول می کند و راه حلی مقرون به صرفه و سازگار با محیط زیست ارائه می دهد که هم برای فرستندگان و هم برای مسافران سودمند است.",
      aboutUsParagraph2:
        "پلتفرم ما پلی است بین افرادی که به دنبال ارسال بسته هستند و مسافرانی که فضای موجود در چمدان خود را به اشتراک می گذارند. از طریق توافقات متقابل، فرستندگان می توانند اقلام خود را به طور قابل اعتماد و مقرون به صرفه تحویل دهند، در حالی که مسافران فرصت کسب درآمد از سفر خود را دارند.",
      aboutUsParagraph3:
        "آنچه  کولبار را متمایز می کند تعهد ما به اعتماد و امنیت است. همه مسافران در شبکه ما تحت یک فرآیند راستی‌آزمایی دقیق قرار می‌گیرند تا اطمینان حاصل شود که بسته‌های شما در دستان امنی هستند. ما شفافیت و ارتباطات باز را در اولویت قرار می دهیم و به شما آرامش خاطر را تحویل می دهیم.",
      aboutUsParagraph4:
        "برای فرستندگان،  کولبار یک تجربه با متفاوت و یکپارچه رائه می دهد. با هزینه های گزاف حمل و نقل خداحافظی کنید و به روشی پایدارتر برای رساندن اقلام خود به جایی که باید باشند سلام کنید. با استفاده از فضای بارمسافرانی که به مقصد مورد نظر شما حرکت می کنند، ردپای کربن را به حداقل می‌رسانیم و از مسیرهای سفر موجود بهترین استفاده را می‌کنیم.",
      aboutUsParagraph5:
        "مسافران نیز کولبار را یک پلتفرم ارزشمند می دانند. با ارائه فضای بار خود به فرستندگان، از سفرهای خود درآمد کسب کنید. چه برای کار و چه برای تفریح سفر می کنید، سفرهای شما اکنون می تواند به طور معناداری به امور مالی شما کمک کند.  کولباربه شما این امکان را می دهد که از برنامه های سفر خود نهایت استفاده را ببرید و در عین حال به دیگران کمک کنید.",
      aboutUsParagraph6:
        "در شکل دادن به آینده تحویل بسته به ما بپیوندید. یک پلتفرم جامعه محور را تجربه کنید که همکاری، صرفه جویی در هزینه و آگاهی زیست محیطی را سرلوحه قرار می دهد. راحتی و مزایای  کولبار را امروز کشف کنید",
      serviceFirstLine:
        "می توانید آگهی خود را به عنوان مسافر یا فرستنده درکولبارارسال کنید.",
      serviceSecondLine: "کولبار  خدمات زیر را به شما ارائه می دهد",
      deliveredTrustedTitle: "تحویل توسط مسافران مورد اعتماد و تایید شده",
      temptNumberTitle:
        "ما کاربران را ترغیب می کنیم تا پروفایل خود را با موارد زیر تکمیل کنند",
      temptNumberItem1: "عکس پروفایل",
      temptNumberItem2:
        "مدرک شناسایی معتبر. (گذرنامه، کارت روابط عمومی، گرین کارت، گواهینامه رانندگی و ...)",
      temptNumberItem3: "آدرس محل سکونت",
      temptNumberItem4: "آدرس ایمیل معتبرو شماره تلفن همراه",
      temptNumberItem5: "حساب های شبکه اجتماعی آنها",
      temptNumberEnd:
        "قبل از هر معامله، هویت کاربر باید مشخص شود. این ملاحظات به ما اجازه می دهد تا پروفایل های جعلی را تعلیق کنیم و از ایجاد حساب های جعلی جلوگیری کنیم",
      usersEvaluationTitle: "سیستم ارزیابی کاربران",
      usersEvaluationTitleItem:
        "هدف از ارزیابی کاربران این است که آنها را بر اساس تجربه و سابقه در سامانه کولبار، اطلاعات پروفایل آنها، ارزیابی سایر کاربران و ماتریس ارزیابی کولبارسطح بندی کنیم",
      usersEvaluationItem1: "کاربر عادی",
      usersEvaluationItem2: "کاربر برنزی",
      usersEvaluationItem3: "کاربر نقره ای",
      usersEvaluationItem4: "کاربر طلایی",
      securePaymentTitle: "خدمات پرداخت امن",
      fee: "قیمت",
      payment: "پرداخت",
      PackagesType: "نوع بسته",
      selectFromOptions: "از گزینه های موجود انتخاب کنید",
      securePaymentItem1:
        "کولبار  برای مدیریت تمام پرداخت هایی که در پلتفرم خود انجام می شود به خدمات شریک خود استرایپ متکی است",
      securePaymentItem2:
        "استرایپ یک شرکت آمریکایی متخصص در مدیریت بین المللی پرداخت از طریق اینترنت برای افراد و متخصصان است. این درگاه در حال حاضر ارائه دهنده پرداخت خدمات شناخته شده ای مانند فیس بوک و دلیورو است",
      securePaymentItem3:
        "کولبار هیچ وقت به شماره کارت اعتباری شخصی شما دسترسی نخواهد داشت و آنها را در پایگاه داده خود نگه نخواهد داشت",
      securePaymentItem4:
        "فرستنده کل مبلغ را از طریق شریک ما استرایپ پرداخت می کند. مبلغ تا زمان تحویل واقعی کالا بلاک می شود",
      securePaymentItem5:
        "پس از آنکه هم مسافر و هم فرستنده تحویل را تایید کردند مبلغ توافق شده به مسافر پرداخت می شود مگر اینکه فرستنده رسید کالا به مقصد را تائید نکند",
      trackingServiceTitle: "خدمات ردیابی",
      trackingServiceItem1:
        "شما می توانید آخرین وضعیت محموله خود را در سامانه کولبار مشاهده کنید.",
      trackingServiceItem2:
        "زمانی که بسته شما به مراحل از پیش تعیین شده رسید، از طریق پیامک یا واتساپ به شما اطلاع داده می شود",
      refundPolicyTitle: "سیاست بازپرداخت",
      refundPolicyItem:
        "در صورت رد شدن تحویل یا هر گونه اختلاف، روند بازپرداخت ما وارد عمل می شود. ما با در نظر گرفتن شواهدی مانند اثبات تحویل، زمان‌بندی، توافق‌نامه‌ها و سایر جزئیات مرتبط، وضعیت را به‌طور کامل بررسی می‌کنیم. اگر ادعای فرستنده معتبر باشد، ما یک فرآیند بازپرداخت را آغاز می‌کنیم و کمیسیون و هزینه‌های عملیات مربوطه را کسر می‌کنیم. این امر ضمن حفظ شفافیت، قطعنامه های منصفانه را تضمین می کند",
      chatFunctionality: "چت کاربر با کاربر",
      chatFunctionalityItem:
        "ارتباطات کلیدی است و پلت فرم ما ارتباط مستقیم بین کاربران را تسهیل می کند. می‌توانید با سایر اعضا چت کنید تا درباره جزئیات تحویل صحبت کنید، مکان‌های تحویل و تحویل را هماهنگ کنید، و به هر گونه سؤال یا نگرانی رسیدگی کنید. ویژگی چت یکپارچه ما همکاری روان را تضمین می کند و تجربه شما را افزایش می دهد",
      contractSenderTravelerTitle: "قرارداد بین فرستنده و مسافر",
      contractSenderTravelerItem1:
        "کولبار قراردادی را در رابطه با حمل و نقل بسته بین افراد به شما ارائه کرده است که استفاده از آن برای امنیت، شفافیت و اعتماد اکیدا توصیه می شود. این قرارداد دو طرف را قادر می سازد تا تمام اطلاعات لازم و موارد توافق شده برای تحویل ایمن بسته را جمع آوری کنند",
      contractSenderTravelerItem2:
        "حمل و نقل بسته ها بین افراد یک سرویس جدید است که هنوز در چندین کشور توسط قانون تنظیم نشده است و خطراتی از جمله گم شدن، آسیب یا سرقت را در بر می گیرد. این قرارداد اولین گام به سوی هدف کولبار برای به حداقل رساندن ریسک ها تا حد امکان است و هر دو طرف به طور جدی تر، با وضوح و آرامش مذاکره خواهند کرد",
      contractSenderTravelerItem3:
        "از این فرصت استفاده می کنیم و یادآور می شویم که کولبار مسئولیتی در قبال مفقود شدن، آسیب یا سرقت بسته ندارد زیرا ما در تراکنش های کاربران خود دخالت نمی کنیم",
      reviewRatingTitle: "سیستم بازبینی و امتیاز",
      reviewRatingItem:
        "به عنوان بخشی از تعهد ما به شفافیت، شما این فرصت را دارید که نظرات خود را با سایر کاربران ارزیابی کنید. پس از تحویل موفقیت آمیز، هم فرستندگان و هم مسافران می توانند در مورد تعاملات خود بازخورد ارائه دهند. این سیستم مسئولیت‌پذیری را ارتقا می‌دهد و به کاربران دیگر کمک می‌کند هنگام انجام معاملات تصمیم‌گیری آگاهانه بگیرند",
      sendParcelTitle: "آیا می خواهید یک بسته بفرستید؟",
      sendParcelDescription:
        "فرستنده آگهی خود را در پلتفرم کولبار ارسال می کند تا مسافری را که علاقه مند به حمل بسته، سند و غیره است پیدا کند.",
      sendParcelItem1:
        "پیدا کنید ثبت نام کنید و مسافران احتمالی را با ذکر مهلت ارسال، شهر یا کشور مبدأ و مقصد بسته",
      sendParcelItem2: "از بین مسافرانی که به آن مقصد می روند انتخاب کنید.",
      sendParcelItem3:
        "آفر خود را ارسال کنید، در مورد شرایط و جزئیات از طریق پیام یا تلفن صحبت کنید.",
      sendParcelItem4:
        "فرستنده و مسافر باید فیلدهای لازم در سلمانه را برای ایجاد یک توافقنامه تکمیل کنند.",
      sendParcelItem5:
        "فرستنده از طریق پلت فرم امن استرایپ مبلغ را پرداخت می کند. این مبلغ تا زمان تحویل واقعی کالا بلاک می شود.",
      sendParcelItem6:
        "کولبار  17 درصد کمیسیون و هزینه خدمات اضافه خواهد کرد. این به ما امکان می دهد تا هزینه های عملیاتی خود را پوشش دهیم.",
      sendParcelItem7:
        "کولبار یک قرارداد رسمی بین فرستنده و مسافر ایجاد می کند.",
      sendParcelItem8: "فرستنده بسته را به مسافر تحویل می دهد.",
      sendParcelItem9: "فرستنده تحویل در مقصد را تایید می کند.",
      sendParcelItem10:
        "مبلغ توافق شده آزاد می شود و کولبار آن را به مسافر پرداخت می کند..",
      sendParcelItem11:
        "فرستنده می تواند برای سرویس دریافتی به مسافر امتیاز دهد و نظر بدهد.",
      carryParcelTitle:
        "آیا می خواهید با حمل یک یا چند بسته در حین سفر کسب درآمد کنید؟",
      carryParcelDescription:
        "مسافری که هنوز در چمدانش جا دارد آگهی خود را در پلتفرم کولبار منتشر می کند تا فرستندگان احتمالی را پیدا کند.",
      carryParcelItem1:
        "ثبت نام کنید و مسافران احتمالی را با ذکر تاریخ، شهرها یا کشور مبدا و مقصد سفر پیدا کنید.",
      carryParcelItem2:
        "یک یا چند فرستنده را انتخاب کنید ، درخواست خود را ارسال کنید، و در مورد شرایط، هزینه انجام کار و جزئیات گفتگو کنید.",
      carryParcelItem3:
        "فرستنده و مسافر باید فیلدهای لازم در سامانه را برای ایجاد یک توافقنامه تکمیل کنند.",
      carryParcelItem4:
        "فرستنده از طریق پلت فرم امن استرایپ مبلغ را پرداخت می کند. این مبلغ تا زمان تحویل واقعی کالا بلاک می شود.",
      carryParcelItem5:
        "کولبار یک قرارداد رسمی بین فرستنده و مسافر ایجاد می کند.",
      carryParcelItem6:
        "فرستنده بسته را به مسافر تحویل می دهد و مسافر بسته را حمل می کند.",
      carryParcelItem7:
        "مسافر تحویل بسته به مقصد را تایید می کند و سپس فرستنده هم باید تحویل را تایید کند.",
      carryParcelItem8:
        "مبلغ توافق شده به مسافر پرداخت می شود مگر اینکه فرستنده دریافت بسته در مقصد را تایید نکند.",
      carryParcelItem9: "مسافر می تواند به فرستنده امتیاز دهد و نظر بدهد.",
      addTravel: "اضافه کردن آگهی سفر",
      editTravel: "ویرایش سفر",
      securePayouts: "پرداخت های مطمئن",
      RetrievePreviousTravel: " بازیابی آگهی سفر قبلی",
      IWillTravelFrom: "من سفر خواهم کرد از",
      travelDepartureDate: "تاریخ حرکت",
      travelArrivalDate: "تاریخ رسیدن",
      maxSizeParcelCarry: "حداکثر اندازه بسته قابل قبول",
      selectMaxSizePlaceholder:
        "حداکثر اندازه را از گزینه های موجود انتخاب کنید",
      definedAs: "به ابعاد",
      maxWeightOf: "حداکثر وزن بسته قابل قبول",
      payableBy: "روش پرداخت",
      timeline: "نمودار زمانی",
      country: "کشور",
      province: "استان",
      chat: "چت کنید",
      removeFilters: "حذف فیلترها",
      describeReason: "دلیل خود را شرح دهید",
      chooseCancellationReason: "دلیل لغو درخواست خود را انتخاب کنید",
      chooseDenyingReason: "دلیل رد کردن تحویل را انتخاب کنید",
      attentionTravelerPart1:
        "توجه مسافران! 'پرداخت توسط کولبار' فقط برای کانادا است.",
      attentionTravelerPart2: " اطلاعات بانکی ",
      attentionTravelerPart3: "خود را برای پرداخت سریعتر ارائه دهید.",
      cashBeforeShippingWarning:
        "انتخاب «پول نقد قبل از ارسال» امکانات مختلفی از جمله خط‌مشی لغو، ایجاد توافقنامه، اثبات تحویل، بازپرداخت و ردیابی را غیرفعال می‌کند.",
      packageType: "نوع بسته قابل حمل",
      providedService: "خدماتی که می توانم ارائه دهم",
      servicesProvided: "خدمات ارائه شده",
      providedServicePlaceholder: "خدمات را از گزینه های موجود انتخاب کنید",
      deliveryDateWillBe: "محدوده تاریخ تحویل",
      ok: "باشه",
      loadMore: "نمایش بیشتر",
      memberSince: "عضویت",
      reVerifyAddress:
        "توجه: اگر آدرس تأیید شده خود را تغییر دهید، باید آن را دوباره تأیید کنید",
      flightNameIs: "شرکت هواپیمایی",
      noData: "هیچ اطلاعاتی وجود ندارد",
      andFlightNumber: "شماره پرواز",
      cancellationPolicy: "شرط کنسلی",
      noPackage: "هیچ بسته ای موجود نیست",
      noTraveler: "هیچ مسافری موجود نیست",
      noRequest: "هیچ درخواستی موجود نیست",
      noOffer: "هیچ پیشنهادی موجود نیست",
      uploadTicketOptional: "بلیط خود را آپلود کنید (اختیاری)",
      dropImageHere: "تصاویر را اینجا رها کنید یا برای آپلود کلیک کنید",
      noteDetail: "یادداشت/جزئیات (اختیاری)",
      acceptAllVisibleDocs:
        "من تمام مدارک قابل مشاهده به جز پاسپورت را قبول می کنم. (حداکثر 100 کاراکتر)",
      termConditionLinkText: "من با شرایط و ضوابط تعریف شده در این ",
      personalInfoDesc:
        "اطلاعات خود را به روز کنید و نحوه استفاده از آن را بیابید",
      transactionDesc: "لیست تراکنش های اخیر خود را از اینجا دریافت کنید",
      notificationDesc: "لیست اعلان های اخیر خود را از اینجا دریافت کنید",
      securityDesc:
        "تنظیمات امنیتی خود را تغییر دهید، احراز هویت ایمن را تنظیم کنید یا حساب خود را حذف کنید.",
      identityVerificationDesc:
        "اطلاعات شناسایی خود را آپلود کنید و برای امنیت و اعتماد بیشتر به راحتی خود را در سیستم تأیید کنید.",
      termConditionLink: " لینک",
      termConditionLink2: "موافقم",
      addNew: "اضافه کردن",
      uploadMore: "آپلود بیشتر",
      submitRequest: "ارسال",
      next: "بعدی",
      previewOn: "پیش نمایش",
      dear: "عزیز",
      reasonForClosingAccount: "دلیل بستن حساب",
      retrievePreviousPackage: " بازیابی آگهی حمل بار قبلی",
      currencyWarning:
        "توجه: پرداخت های آنلاین فقط با ارز 'CAD'  قابل پرداخت است",
      shipFind: "من قصد ارسال/پیدا کردن .",
      atWeight: "به وزن",
      companionWarning:
        "انتخاب گزینه 'همسفر' با انواع دیگر بسته امکان پذیر نمی باشد.",
      andSize: "اندازه بسته",
      activationCode: "کد فعال سازی در ایمیل شما ظاهر می شود",
      betweenFromDate: "ازه زمانی مورد نظر از تاریخ ",
      forPrice: "مبلغ پیشنهادی",
      OfferId: "شناسه پیشنهاد",
      travelDate: "تاریخ سفر",
      upload: "آپلود",
      koolbaarDescFooter:
        "کولبار یک پلتفرم حمل و نقل جمعی سازگار با محیط زیست و مقرون به صرفه است که فرستندگان و مسافران را به هم متصل می کند و اعتماد، امنیت و پایداری را در اولویت قرار می دهد.",
      sureWantCloseAccount: "آیا مطمئن هستید که می خواهید حساب خود را ببندید؟",
      deliveryDeadlineIs: "مهلت تحویل در مقصد",
      requestedServices: "خدمات درخواستی",
      uploadPkgImgOptional: "عکس پکیج خود را آپلود کنید (اختیاری)",
      shipExample: "می خواهم یک کتاب ارسال کنم. (حداکثر 100 کاراکتر)",
      itemsLinks: "لینک اقلام برای خرید",
      qty: "تعداد",
      myAccount: "حساب کاربری من",
      amount: "مبلغ (CAD)",
      updateInformation: "به روزرسانی اطلاعات",
      displayAll: "نمایش همه",
      denyDelivery: "رد کردن تحویل",
      acceptAllGovernmentTaxes:
        "می پذیرم که این پیشنهاد شامل تمام هزینه ها به جز هزینه های اجباری دولت مانند مالیات بر حق واردات و غیره می شود.",
      travelHistory: "تاریخچه سفر",
      packageHistory: "تاریخچه محموله",
      reuse: "تاریخچه",
      searchTrvId: "جستجوی شناسه سفر",
      searchPkgId: "جستجوی شناسه بسته",
      small: "کوچک",
      medium: "متوسط",
      large: "بزرگ",
      xlarge: "خیلی بزرگ",
      passportUpload: "بارگذاری گذرنامه",
      addressUpload: "صورت حساب بانکی خود را آپلود کنید",
      licenseUpload:
        "بارگذاری گواهینامه رانندگی / کارت اقامت دائم / کارت بیمه سلامت",
      smallExample: "مثال: سند، کلید",
      mediumExample: "مثال: تلفن همراه، کتاب، کیف",
      largeExample: "مثال: جعبه بزرگ، ابزار، حیوان خانگی",
      xLargeExample: "مثال: چمدان 23 کیلویی، پالت",
      boardOfDirectors: "هيئت مدیره",
      uploadPassportFrontPage:
        "گذرنامه خود را تأیید کنید (همه گوشه ها باید قابل مشاهده باشند)",
      uploadIdPage:
        "بارگذاری گواهینامه رانندگی / کارت اقامت دائم / کارت بیمه سلامت",
      uploadIdDriving:
        "کارت شناسایی خود را تأیید کنید (گواهینامه رانندگی / کارت PR / کارت سبز / کارت ملی)",
      close: "بستن",
      about: "درباره",
      completeCheckout: "تسویه حساب",
      selectYourCountry: "لطفا کشور خود را انتخاب کنید",
      notifications: "اعلان ها",
      approve: "تایید",
      applyFilters: "اعمال فیلترها",
      email: "ایمیل",
      verificationCodeSent: "کد تایید ارسال شد",
      onlinePaymentForCanada:
        "توجه! پرداخت آنلاین فقط برای کانادا در دسترس است.",
      counterpart: "Counterpart",
      note: "پیام",
      personalInfo: "اطلاعات شخصی",
      address: "آدرس",
      yes: "بله",
      no: "خیر",
      verify: "تأیید",
      addBankAccount: "اضافه کردن حساب بانکی",
      state: "ایالت/استان",
      filters: "فیلترها",
      city: "شهر",
      security: "امنیت",
      yourAlerts: "هشدارهای شما",
      setAlert: "تنظیم هشدار",
      viewAll: "مشاهده همه",
      snoopAlert: "تنظیم هشدار یافتن بسته",
      trackAlert: "تنظیم هشدار یافتن مسافر",
      transactions: "تراکنش ها",
      postalCode: "کد پستی",
      reviews: "بازبینی ها",
      verified: "تایید شده",
      beingEvaluated: "در حال ارزیابی",
      originalPrice: "قیمت واقعی",
      operationsFee: "هزینه عملیات",
      subtotal: "مجموع",
      commissionFee: "هزینه کمیسیون",
      writeReview: "ثبت نظر برای",
      phoneNumber: "شماره تلفن",
      closeAccount: "بستن حساب",
      retypePass: "رمز عبور را دوباره تایپ کنید",
      removeMyTravel: "سفر من را حذف کنید",
      removeMyPackage: "بسته من را حذف کنید",
      confirmRemoveCard: "آیا مطمئن هستید که میخواهید این مورد را حذف کنید؟",
      requests: "درخواست ها",
      copyLink: "کپی کردن لینک",
      saveCard: "ذخیره کارت",
      linkCopied: "لینک کپی شد",
      viewRequests: "مشاهده درخواست ها",
      latestSentRequest: "آخرین درخواست ها",
      latestSentOffer: "آخرین پیشنهادها",
      personal: "شخصی",
      chatwith: "چت با ",
      sender: "فرستنده",
      traveler: "مسافر",
      manage: "مدیریت",
      identityVerification: "تایید هویت",
      idVerification: "تأیید هویت",
      financial: "مالی",
      character: "کاراکتر",
      sendVerificationCode: "ارسال کد تایید",
      changePassword: "تغییر رمز عبور",
      createPassContains: "یک رمز عبور بسازید که حتما شامل موارد زیر باشد",
      eightChars: "8 کاراکتر",
      oneNumber: "1 عدد",
      uppercaseLowercase: "حروف بزرگ و کوچک",
      specialChar: "1 کاراکتر خاص",
      sorrySeeYouGo: "از رفتن شما متاسفیم",
      reasonForCloseAccount: "دلیل بستن حساب خود را به ما بگویید",
      continue: "ادامه",
      back: "بازگشت",
      prohibitedItems: "اقلام ممنوعه",
      moreInfoToHelpUs:
        "لطفاً  جهت بهبود ارائه خدمات، اطلاعات بیشتری به ما ارائه دهید.",
      prohibitTitle: "اقلام ممنوعه سفر به کانادا",
      prohibitSubDesc1: "",
      prohibitSubDesc2: "",
    },
  },
} as const;
