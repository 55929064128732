import { BaseService } from "./baseService";

class FlightsService extends BaseService {
  flightInquiry(data) {
    return this.http().post("/Flight/Inquiry", data);
  }

  getHomeFilter(data) {
    return this.http().post("/HomeFilter", data);
  }

  getHomeTravelerByTravelId(data) {
    return this.http().get("/HomeTraveler/GetByTravelId", data);
  }

  getAirlines(data) {
    return this.http().post("/Airline/Search", data);
  }

  // getHomeRequestByTravelInfo(data) {
  //   return this.http().post("/HomeRequest/FilterByTravelInformation", data);
  // }
  getHomeRequestByTravelInfo(data) {
    return this.http().post("/HomeFilter/ByTravelInformation", data);
  }

  searchFlight(data) {
    return this.http().post("/Flight/Search", data);
  }
}

export default new FlightsService();
