export const isValidOfferPrice = (price) =>
  price !== null && price !== undefined && price !== "";

export const isValidPackageType = (type) =>
  type !== null &&
  type !== undefined &&
  (Array.isArray(type) ? type?.length !== 0 : true);

export const isValidSize = (size, isCompanion) =>
  (size !== null &&
    size !== undefined &&
    (Array.isArray(size) ? size?.length !== 0 : true)) ||
  isCompanion;

export const isValidDefinedLengthSize = (length) => length !== "";

export const isValidDefinedWidthSize = (width) => width !== "";

export const isValidDefinedHeightSize = (height) => height !== "";

export const isValidFrom = (from) => from !== undefined && from;

export const isValidTo = (to) => to !== undefined && to;

export const isEndDateGreaterEqualStartDate = (date1, date2) => {
  // console.log(new Date(date2))
  return date1.getTime() <= new Date(date2).getTime();
};

export const isEndDateGreaterStartDate = (from, to) =>
  from.getTime() < to.getTime();

export const isValid = (termsChecked, governmentChecked, data) => {
  return (
    termsChecked &&
    governmentChecked &&
    isValidPackageType(data.type) &&
    isValidOfferPrice(data.offerPrice) &&
    isValidSize(data.size, data.isCompanion) &&
    (data.size.label === "Custom"
      ? isValidDefinedLengthSize(data.length) &&
        isValidDefinedWidthSize(data.width) &&
        isValidDefinedHeightSize(data.height)
      : true) &&
    isValidFrom(data.from) &&
    isValidTo(data.to) &&
    isEndDateGreaterEqualStartDate(
      data.arrivalBetweenDate,
      data.departureBetweenDate
    )
    // && isEndDateGreaterStartDate(data.departureBetweenDate, data.departureToDate)
  );
};
