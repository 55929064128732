import { createSlice } from "@reduxjs/toolkit";

export const sendReviewSlice = createSlice({
  name: "sendReview",
  initialState: [],
  reducers: {
    sendReview: (state, action) => {
      return (state = action.payload);
    },
    sendReviewFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { sendReview, sendReviewFailure } =
sendReviewSlice.actions;
export default sendReviewSlice.reducer;
