import { combineReducers } from "redux";
import {
  counter,
  createUser,
  packageTypes,
  login,
  refresh,
  isValidToken,
  logout,
  checkToken,
  weightRange,
  sizeRange,
  deliveryType,
  flightInquiry,
  homeRequestFilter,
  homeTravelFilter,
  userInfo,
  editUserInfo,
  filterHomeRequestSearch,
  filterHomeTravelSearch,
  userDashboard,
  changeOfferStatus,
  offerStatus,
  requestStatus,
  changeRequestStatus,
  getChangedStatus,
  sendAgreement,
  sendReview,
  addTravel,
  editTravel,
  userTravel,
  deleteTravel,
  addPackage,
  editPackage,
  userPackage,
  deletePackage,
  addBankAccount,
  bankAccounts,
  deleteAccount,
  editAccount,
  gateways,
  offerTimeline,
  requestTimeline,
  currencies,
  offerProblem,
  requestProblem,
  createOffer,
  createRequest,
  chooseRequest,
  chooseOffer,
  searchFlight,
  allAirlines,
  cancellationPolicy,
  expenseType,
  agreementTerm,
  paymentMethod,
  service,
  verifyPhoneNo,
  verificationCode,
  allOfferStatus,
  allRequestStatus,
  filterRequestStatus,
  filterOfferStatus,
  changeButtonStatus,
  changeButtonPaymentStatusOffer,
  filterByTypeId,
  changeButtonPaymentStatusRequest,
  searchByTrvId,
  searchByPkgId,
  verificationType,
  verificationResponse,
  allFareAlerts,
  deleteFareAlert,
  setFareAlert,
  fareAlertCount,
  changeStatusFareAlert,
  validationToken,
  retrieve,
  proof,
  offersClicked,
  requestsClicked,
  proofById,
  cancellation,
  changeStatus,
  senderReasonCancellation,
  travelerReasonCancellation,
  disapprovalReason,
  sendDisapprovalReason,
  agreementByTypeReceive,
  agreementByTypeSend,
  allAgreementsStatus,
  allTransactions,
  approveSender,
  approveTraveler,
  modifySenderAgreement,
  modifyTravelerAgreement,
  allProfile,
  paymentResult,
  verifyStripe,
  otpCode,
  deletePackageImg,
  deleteTravelImg,
  userChat,
  hasNewChat,
  userAllChat,
  closeAccountReasons,
  closeAccountRequest,
  allNotification,
  addressVerification,
  passportVerification,
  secondIdentityVerification,
  allNotificationCount,
  allCountries,
  allDashboardProfile,
  forgetPass,
  sendContact,
  packageById,
  travelById,
  checkPackage,
  checkTravel,
  chatByTypeId,
  changeLanguage,
  getRooms,
  uploadFile,
  chatCount,
  newDashboard,
} from "redux/slices";

const rootReducer = combineReducers({
  counter,
  createUser,
  packageTypes,
  login,
  refresh,
  isValidToken,
  logout,
  checkToken,
  weightRange,
  sizeRange,
  deliveryType,
  flightInquiry,
  homeRequestFilter,
  homeTravelFilter,
  userInfo,
  editUserInfo,
  filterHomeRequestSearch,
  filterHomeTravelSearch,
  userDashboard,
  changeOfferStatus,
  offerStatus,
  requestStatus,
  changeRequestStatus,
  getChangedStatus,
  sendAgreement,
  sendReview,
  addTravel,
  editTravel,
  userTravel,
  deleteTravel,
  addPackage,
  changeButtonStatus,
  changeButtonPaymentStatusOffer,
  changeButtonPaymentStatusRequest,
  editPackage,
  userPackage,
  deletePackage,
  addBankAccount,
  bankAccounts,
  deleteAccount,
  editAccount,
  gateways,
  offerTimeline,
  requestTimeline,
  currencies,
  offerProblem,
  requestProblem,
  createOffer,
  filterByTypeId,
  createRequest,
  chooseRequest,
  chooseOffer,
  searchFlight,
  allAirlines,
  cancellationPolicy,
  expenseType,
  agreementTerm,
  paymentMethod,
  service,
  verifyPhoneNo,
  verificationCode,
  allOfferStatus,
  allRequestStatus,
  filterRequestStatus,
  filterOfferStatus,
  searchByTrvId,
  searchByPkgId,
  verificationType,
  verificationResponse,
  allFareAlerts,
  deleteFareAlert,
  setFareAlert,
  fareAlertCount,
  changeStatusFareAlert,
  validationToken,
  retrieve,
  proof,
  proofById,
  cancellation,
  changeStatus,
  senderReasonCancellation,
  travelerReasonCancellation,
  disapprovalReason,
  sendDisapprovalReason,
  agreementByTypeReceive,
  agreementByTypeSend,
  allAgreementsStatus,
  approveSender,
  approveTraveler,
  modifySenderAgreement,
  modifyTravelerAgreement,
  allProfile,
  paymentResult,
  allTransactions,
  otpCode,
  verifyStripe,
  deletePackageImg,
  deleteTravelImg,
  userChat,
  hasNewChat,
  userAllChat,
  closeAccountReasons,
  closeAccountRequest,
  allNotification,
  addressVerification,
  passportVerification,
  secondIdentityVerification,
  allNotificationCount,
  allCountries,
  allDashboardProfile,
  newDashboard,
  forgetPass,
  sendContact,
  packageById,
  travelById,
  checkPackage,
  checkTravel,
  chatByTypeId,
  offersClicked,
  requestsClicked,
  changeLanguage,
  getRooms,
  uploadFile,
  chatCount,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
