import React, { useState, useEffect } from "react";
import { Col, Row, OverlayTrigger } from "react-bootstrap";
import Select from "react-select";
import { Button } from "layers";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  getAllDeliveryType,
  getAllPaymentMethod,
  getAllCancellationPolicy,
  getAllPackagesType,
  getAllWeightRange,
  getAllSizeRange,
} from "redux/actions/types";
import { RightSidebar } from "layers";
import { useTranslation } from "react-i18next";
import AlarmIcon from "assets/images/noun-reminder.png";
import { useNavigate } from "react-router-dom";
import { LoginConfirm } from "components/modals/Login";
import {
  getAllHomeRequestFilter,
  getAllHomeTravelFilter,
} from "redux/actions/flight";
import Tooltip from "react-bootstrap/Tooltip";

interface IProp {
  tab: number;
}

export const Filters: React.FC<IProp> = ({ tab }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const screenSize = UseWindowSize();
  const { t } = useTranslation();
  const [typeOptions, setTypeOptions] = useState([]);
  const [showTypeName, setShowTypeName] = useState(false);
  const [showSizeName, setShowSizeName] = useState(false);
  const [showWeightName, setShowWeightName] = useState(false);
  const [showServiceName, setShowServiceName] = useState(false);
  const [showDeliveryTypeName, setShowDeliveryTypeName] = useState(false);
  const [showPaymentName, setShowPaymentName] = useState(false);
  const [showPolicyName, setShowPolicyName] = useState(false);
  const [showOfferSidebar, setShowOfferSidebar] = useState(false);
  const [showAlertSidebar, setShowAlertSidebar] = useState(false);
  const [showRequestSidebar, setShowRequestSidebar] = useState(false);
  const [showLoginSidebar, setShowLoginSidebar] = useState(false);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [policyOptions, setPolicyOptions] = useState([]);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [weightOptions, setWeightOptions] = useState([]);
  const [count, setCount] = useState(0);
  const [deliveryTypesOptions, setDeliveryTypesOptions] = useState([]);
  const [type, setType] = useState({ value: 0, label: t("type") });
  const [size, setSize] = useState({ value: 0, label: t("size") });
  const [policy, setPolicy] = useState({
    value: 0,
    label: t("cancellationPolicy"),
  });
  const [payment, setPayment] = useState({
    value: 0,
    label: t("paymentMethod"),
  });
  const [weight, setWeight] = useState({ value: 0, label: t("weight") });
  const [deliveryType, setDeliveryType] = useState({
    value: 0,
    label: t("services"),
  });
  const packagesType = useAppSelector((state) => state.packageTypes);
  const paymentMethod = useAppSelector((state) => state.paymentMethod);
  const sizeRanges = useAppSelector((state) => state.sizeRange);
  const policies = useAppSelector((state) => state.cancellationPolicy);
  const weightRanges = useAppSelector((state) => state.weightRange);
  const deliveryTypes = useAppSelector((state) => state.deliveryType);
  const alertsCount: any = useAppSelector((state) => state.fareAlertCount);
  const str = window.location.pathname;
  const [showLoginOfferModal, setShowLoginOfferModal] = useState(false);
  const [showLoginRequestModal, setShowLoginRequestModal] = useState(false);
  const [showSignInSidebar, setShowSignInSidebar] = useState(false);
  const windowSize = UseWindowSize();
  const isMobileTablet = windowSize.width < 992;

  const token = window.localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(
    token === "undefined" || token === "null" || token === null ? false : true
  );
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  useEffect(() => {
    if (showLoginSidebar) HandleLoginSidebar();
  }, [showLoginSidebar]);

  const HandleLoginSidebar = () => {
    setShowSignInSidebar(!showSignInSidebar);
  };

  window.addEventListener("storage", () => {
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogin(false);
    else setIsLogin(true);
  });

  const typeCustomStyle = {
    control: (styles) => ({
      ...styles,
      height: screenSize?.width < 768 ? 34 : 47,
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#f3f3f3",
      flexWrap: "nowrap",
      width: "250px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
      minWidth: 50,
      width: "250px",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "250px",
      whiteSpace: "nowrap",
      padding: 0,
    }),
    menu: (styles) => ({
      ...styles,
      width: "250px",
    }),
  };
  const sizeCustomStyle = {
    control: (styles) => ({
      ...styles,
      height: screenSize?.width < 768 ? 34 : 47,
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#f3f3f3",
      flexWrap: "nowrap",
      width: "110px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
      minWidth: 50,
      width: "110px",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "110px",
      whiteSpace: "nowrap",
      padding: 0,
    }),
    menu: (styles) => ({
      ...styles,
      width: "110px",
    }),
  };
  const policyCustomStyle = {
    control: (styles) => ({
      ...styles,
      height: screenSize?.width < 768 ? 34 : 47,
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? "#c1c1c1" : "#00043d",
      backgroundColor: "#f3f3f3",
      flexWrap: "nowrap",
      width: "400px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
      minWidth: 50,
      width: "400px",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "400px",
      whiteSpace: "nowrap",
      padding: 0,
    }),
    menu: (styles) => ({
      ...styles,
      width: "400px",
    }),
  };
  const serviceCustomStyle = {
    control: (styles) => ({
      ...styles,
      height: screenSize?.width < 768 ? 34 : 47,
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#f3f3f3",
      flexWrap: "nowrap",
      width: isPersian ? "auto" : "120px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
      minWidth: 50,
      width: isPersian ? "250px" : "120px",
    }),
    menuList: (styles) => ({
      ...styles,
      whiteSpace: "nowrap",
      width: isPersian ? "250px" : "120px",
      padding: 0,
    }),
    menu: (styles) => ({
      ...styles,
      width: "120px",
    }),
  };

  // useEffect(() => {
  //   document.addEventListener(
  //     "click",
  //     function (e) {
  //       const target = e.target as HTMLTextAreaElement;
  //       const element = target.className;
  //       if (element === "bm-overlay") {
  //         document.documentElement.style.overflow = "auto";
  //         navigate("/");
  //       }
  //     },
  //     false
  //   );
  // }, []);

  useEffect(() => {
    dispatch(getAllPackagesType());
    dispatch(getAllWeightRange());
    dispatch(getAllSizeRange());
    dispatch(getAllDeliveryType());
    dispatch(getAllPaymentMethod());
    dispatch(getAllCancellationPolicy());
  }, []);

  useEffect(() => {
    if (alertsCount?.isSuccess && tab === 1) {
      setCount(alertsCount.data.packageCount);
    }
    if (alertsCount?.isSuccess && tab === 2) {
      setCount(alertsCount.data.travelCount);
    }
  }, [alertsCount, tab]);

  useEffect(() => {
    if (tab === 1) {
      setType({ value: 0, label: t("type") });
      setSize({ value: 0, label: t("size") });
      setWeight({ value: 0, label: t("weight") });
      setDeliveryType({ value: 0, label: t("services") });
      setShowTypeName(false);
      setShowSizeName(false);
      setShowWeightName(false);
      setShowServiceName(false);
    } else if (tab === 2) {
      setType({ value: 0, label: t("type") });
      setSize({ value: 0, label: t("size") });
      setWeight({ value: 0, label: t("weight") });
      setPolicy({ value: 0, label: t("cancellationPolicy") });
      setPayment({ value: 0, label: t("paymentMethod") });
      setDeliveryType({ value: 0, label: t("services") });
      setShowTypeName(false);
      setShowWeightName(false);
      setShowSizeName(false);
      setShowDeliveryTypeName(false);
      setShowPaymentName(false);
      setShowPolicyName(false);
    }
  }, [tab]);

  useEffect(() => {
    if (tab === 1 && !isMobileTablet) {
      const data = {
        type: "package",
        packagetypeIds: type.value ? type.value : null,
        weightrangeIds: weight?.value ? weight?.value : null,
        deliverytypeIds: null,
        sizerangeIds: size?.value ? size?.value : null,
        serviceIds: deliveryType?.value ? deliveryType?.value : null,
        paymentmethodIds: null,
        page: 1,
      };
      dispatch(getAllHomeRequestFilter(data));
    } else if (tab === 2 && !isMobileTablet) {
      const data = {
        type: "travel",
        packagetypeIds: type.value ? type.value : null,
        weightrangeIds: weight?.value ? weight?.value : null,
        deliverytypeIds: deliveryType?.value ? deliveryType?.value : null,
        paymentmethodIds: payment?.value ? payment?.value : null,
        sizerangeIds: size?.value ? size?.value : null,
        cancellationpolicyIds: policy?.value ? policy?.value : null,
        serviceIds: deliveryType?.value ? deliveryType?.value : null,
        userLevelIds: null,
        page: 1,
      };
      dispatch(getAllHomeTravelFilter(data));
    }
  // }, [tab, size, type, weight, policy, payment, deliveryType]);
  }, [size, type, weight, policy, payment, deliveryType]);

  useEffect(() => {
    const options = packagesType?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setTypeOptions([{ value: null, label: t("all") }, ...options]);
  }, [packagesType]);

  useEffect(() => {
    const options = weightRanges?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setWeightOptions([{ value: null, label: t("all") }, ...options]);
  }, [weightRanges]);

  useEffect(() => {
    const options = sizeRanges?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSizeOptions([{ value: null, label: t("all") }, ...options]);
  }, [sizeRanges]);

  useEffect(() => {
    const options = policies?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    const valueToDisable = "23e33081-d1fd-48ef-a17a-fbbdc69076ce";
    const modifiedCancellationPolicyOptions = options.map((option) => ({
      ...option,
      isDisabled: option.value === valueToDisable,
    }));
    setPolicyOptions([
      { value: null, label: t("all") },
      ...modifiedCancellationPolicyOptions,
    ]);
    // setPolicy({ value: policies[0]?.id, label: policies[0]?.name });
  }, [policies]);

  useEffect(() => {
    const options = paymentMethod?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setPaymentOptions([{ value: null, label: t("all") }, ...options]);
  }, [paymentMethod]);

  useEffect(() => {
    const options = deliveryTypes?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setDeliveryTypesOptions([{ value: null, label: t("all") }, ...options]);
  }, [deliveryTypes]);

  useEffect(() => {
    if (str?.includes("addTravel") && isLogin && windowSize?.width > 991) {
      setShowLoginSidebar(false);
      if (isLogin) setShowOfferSidebar(true);
      else setShowLoginOfferModal(!showLoginOfferModal);
    }
  }, [str, windowSize]);

  const handleOfferSidebar = () => {
    if (windowSize?.width > 991) {
      setShowLoginSidebar(false);
      if (isLogin) setShowOfferSidebar(true);
      else setShowLoginOfferModal(!showLoginOfferModal);
      navigate("/addTravel");
    }
  };

  const handleAlertSidebar = (data) => {
    setShowAlertSidebar(!showAlertSidebar);
    if (!window.location.pathname.includes("/set-alert")) navigate("set-alert");
  };

  useEffect(() => {
    if (str?.includes("addPackage") && isLogin && windowSize?.width > 991) {
      setShowLoginSidebar(false);
      if (isLogin) setShowRequestSidebar(true);
      else setShowLoginRequestModal(!showLoginRequestModal);
    }
  }, [str, windowSize]);

  const handleRequestSidebar = () => {
    if (windowSize?.width > 991) {
      setShowLoginSidebar(false);
      if (isLogin) setShowRequestSidebar(true);
      else setShowLoginRequestModal(!showLoginRequestModal);
      navigate("/addPackage");
    }
  };

  const setAlertTooltip = (
    <Tooltip id="set-alert">
      <span>{isPersian ? "گوش به زنگ" : "Set Alert"}</span>
    </Tooltip>
  );

  return (
    <>
      {screenSize.width >= 992 && (
        <div className="filter-info-wrapper">
          <Row>
            <Col
              xs={12}
              className={`add-travel-wrapper ${
                isPersian ? "text-left" : "text-right"
              }`}
            >
              <div>
                {/* <Link to={tab === 1 ? "/addTravel" : "/addPackage"}> */}

                {tab === 1 && (
                  <Button
                    variant="primary"
                    className="add-travel-btn animated_btn"
                    onClick={handleOfferSidebar}
                  >
                    {t("addMyTravel")}
                  </Button>
                )}
                {tab === 2 && (
                  <Button
                    variant="primary"
                    className="add-travel-btn animated_btn"
                    onClick={handleRequestSidebar}
                  >
                    {t("addMyRequest")}
                  </Button>
                )}

                {/* </Link> */}
                {window.localStorage.getItem("token") && (
                  <OverlayTrigger placement="top" overlay={setAlertTooltip}>
                    <Button
                      className="set-alert-btn"
                      onClick={handleAlertSidebar}
                    >
                      <img
                        src={AlarmIcon}
                        className="alarm-icon"
                        alt="attach-img"
                      />
                      <span className="notification-count">{count}</span>
                    </Button>
                  </OverlayTrigger>
                )}
              </div>
            </Col>
          </Row>
          {showOfferSidebar && (
            <div className="offer-sidebar">
              <RightSidebar
                isOpen={showOfferSidebar}
                setIsOpen={setShowOfferSidebar}
                sidebarType="addTravel"
              />
            </div>
          )}
          {showLoginOfferModal && (
            <LoginConfirm
              setShowLoginSidebar={setShowLoginSidebar}
              title={t("addTravel")}
              isOpen={showLoginOfferModal}
              setIsOpen={setShowLoginOfferModal}
              type="addTravel"
            />
          )}
          {showLoginRequestModal && (
            <LoginConfirm
              setShowLoginSidebar={setShowLoginSidebar}
              title={t("addRequest")}
              isOpen={showLoginRequestModal}
              setIsOpen={setShowLoginRequestModal}
              type="addPackage"
            />
          )}
          {showAlertSidebar && (
            <div className="offer-sidebar">
              <RightSidebar
                isOpen={showAlertSidebar}
                setIsOpen={setShowAlertSidebar}
                sidebarType="alert"
                type={tab === 1 ? "Package" : "Travel"}
              />
            </div>
          )}
          {showRequestSidebar && (
            <div className="offer-sidebar">
              <RightSidebar
                isOpen={showRequestSidebar}
                setIsOpen={setShowRequestSidebar}
                sidebarType="addPackage"
                mode="add"
              />
            </div>
          )}
          {showSignInSidebar && (
            <div className="offer-sidebar">
              <RightSidebar
                isOpen={showSignInSidebar}
                setIsOpen={setShowSignInSidebar}
                setIsLogin={setIsLogin}
                sidebarType="login"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
