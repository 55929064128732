import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Col, Row, Dropdown } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { IAcceptOffer, IRetrieve } from "models/interfaces";
import { InputOption, ReuseTravelHistory } from "components";
import {
  searchAirportLocation,
  searchLocationApi,
} from "helpers/searchLocation";
import { getDate, convertHumanDateToUnix } from "helpers/convertDate";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  getAllHomeRequestFilter,
  getAllHomeTravelFilter,
} from "redux/actions/flight";
import { AddInputForms } from "components/common/addInputForm";
import { MultipleUploader } from "components";
import { Oval } from "react-loader-spinner";
import { searchAirline } from "helpers/searchAirline";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faClose,
  faInfo,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { SizeInformation } from "components/modals/sizeInformation";
import {
  getAllSizeRange,
  getAllDeliveryType,
  getAllPackagesType,
  // getAllServices,
  getAllPaymentMethod,
  getAllCancellationPolicy,
} from "redux/actions/types";
import { chooseLandingTravel } from "redux/actions/landing";
import {
  isValidPackageType,
  isValidPolicy,
  isValidPayment,
  isValidSize,
  isValidDefinedLengthSize,
  isValidDefinedWidthSize,
  isValidDefinedHeightSize,
  isValid,
} from "helpers/acceptOfferValidation";
import { Link, useNavigate } from "react-router-dom";

interface IProps {
  data: any;
  isOpen: boolean;
  setIsOpen: Function;
  onSelectReuse: (key: any) => void;
}

export const AcceptOffer: React.FC<IProps> = ({
  data,
  setIsOpen,
  isOpen,
  onSelectReuse,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const [offerData, setOfferData] = useState<IAcceptOffer>({
    packagetypeIds: [],
    packageType: "",
    serviceIds: [],
    sizerangeIds: [],
    weight: "0",
    weightUnit: "kg",
    paymentmethodIds: [],
    value: "",
    sizeWidth: "",
    sizeHeight: "",
    sizeLength: "",
    lengthUnit: "cm",
    deliverytypeIds: [],
    fromCountry: "",
    fromCountryCity: "",
    toCountry: "",
    toCountryCity: "",
    fromDate1: "",
    fromDate2: "",
    toDate1: "",
    toDate2: "",
    offerPrice: "",
    currencyType: "CAD",
    cancellationPolicyId: "",
    message: "",
    images: [],
    airlineName: "",
    flightNumber: "",
  });
  const [isCompanion, setIsCompanion] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [reuseData, setReuseData] = useState<any>({});
  const [showReuse, setShowReuse] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [packagetypeIds, setPackagetypeIds] = useState<any>([]);
  const [sizerangeIds, setSizerangeIds] = useState<any>([]);
  const [deliverytypeIds, setDeliverytypeIds] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [packageImages, setPackageImages] = useState<any>([]);
  const [termsChecked, setTermsChecked] = useState(false);
  const [hasPurchase, setHasPurchase] = useState(false);
  const [governmentChecked, setGovernmentChecked] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [payment, setPayment] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [deliveryType, setDeliveryType] = useState(null);
  const [isCancellation, setIsCancellation] = useState(true);
  const [isCad, setIsCad] = useState(true);
  const [isCash, setIsCash] = useState(true);
  const [purchases, setPurchases] = useState<any>([
    { Link: "", Quantity: "0" },
  ]);
  const [cancellationPolicyOptions, setCancellationPolicyOptions] = useState(
    []
  );
  const windowSize = UseWindowSize();
  const policies = useAppSelector((state) => state.cancellationPolicy);
  const [paymentmethodIds, setPaymentmethodIds] = useState<any>([]);
  const [weightUnit, setWeightUnit] = useState({ value: "kg", label: "KG" });
  const [lengthUnit, setLengthUnit] = useState({ value: "cm", label: "CM" });
  const [airline, setAirline] = useState<any>();
  const [airlineOptions, setAirlineOptions] = useState([]);
  const [type, setType] = useState(null);
  const [typeOptions, setTypeOptions] = useState([]);
  const [size, setSize] = useState(null);
  const [sizeOptions, setSizeOptions] = useState([]);
  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState([]);
  const [paymentsOptions, setPaymentsOptions] = useState([]);
  const [pkgId, setPkgId] = useState("");
  const updatedChooseOffer: any = useAppSelector((state) => state.chooseOffer);
  const createOffer: any = useAppSelector((state) => state.createOffer);
  const sizeRanges = useAppSelector((state) => state.sizeRange);
  const airlines = useAppSelector((state) => state.allAirlines);
  const packagesType = useAppSelector((state) => state.packageTypes);
  const services = useAppSelector((state) => state.service);
  const deliveryTypes = useAppSelector((state) => state.deliveryType);
  const payments = useAppSelector((state) => state.paymentMethod);
  const [arrivalBetweenDate, setArrivalBetweenDate] = useState(new Date());
  const [fromDateRange1, setFromDateRange1] = useState(new Date());
  const [fromDateRange2, setFromDateRange2] = useState(new Date());
  const [arrivalToDate, setArrivalToDate] = useState(new Date());
  const [departureBetweenDate, setDepartureBetweenDate] = useState(new Date());
  const [departureToDate, setDepartureToDate] = useState(new Date());
  const [fromDate1, setFromDate1] = useState(getDate(new Date()));
  const [fromDate2, setFromDate2] = useState(getDate(new Date()));
  const [toDate1, setToDate1] = useState(getDate(new Date()));
  const [toDate2, setToDate2] = useState(getDate(new Date()));
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const str = window.location.pathname;
  const isMobileTablet = windowSize.width < 992;
  const isMobile = windowSize.width < 768;
  const navigate = useNavigate();
  const isPersian = lang === "fa";
  const [retrieveData, setRetrieveData] = useState<IRetrieve>({
    type: "travel",
    fromCityEntityId: "",
    toCityEntityId: "",
    fromDate1: "",
    fromDate2: "",
  });
  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];
  const unitLengthOption = [
    { value: "cm", label: "CM" },
    { value: "inch", label: "INCH" },
  ];
  const [reuseTrvId, setReuseTrvId] = useState("");
  const currencies = useAppSelector((state) => state.currencies);
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [currenciesOption, setCurrenciesOption] = useState([
    {
      value: localStorage.getItem("currency"),
      label: localStorage.getItem("currency"),
    },
  ]);

  useEffect(() => {
    dispatch(getAllSizeRange());
    dispatch(getAllDeliveryType());
    dispatch(getAllPackagesType());
    dispatch(getAllCancellationPolicy());
    // dispatch(getAllServices());
    dispatch(getAllPaymentMethod());
  }, []);

  useEffect(() => {
    setShowReuse(false);
    onSelectReuse(false);
  }, [reuseData]);

  useEffect(() => {
    if (Object.keys(reuseData).length !== 0) {
      const data = reuseData;
      setReuseTrvId(data?.trvId);
      const width = data?.sizeWidth;
      const height = data?.sizeHeight;
      const length = data?.sizeLength;
      const currency = data?.currencyType ? data?.currencyType : "CAD";
      const unit = data?.weightUnit ? data?.weightUnit : "KG";
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      searchLocationApi(data?.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data?.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });
      setArrivalBetweenDate(
        data?.fromDate1 ? convertHumanDateToUnix(data?.fromDate1) : new Date()
      );
      setArrivalToDate(
        data?.toDate1 ? convertHumanDateToUnix(data?.toDate1) : new Date()
      );
      setDepartureBetweenDate(
        data?.fromDate2 ? convertHumanDateToUnix(data?.fromDate2) : new Date()
      );
      setDepartureToDate(
        data?.toDate2 ? convertHumanDateToUnix(data?.toDate2) : new Date()
      );
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      setAirline({ value: data?.airlineName, label: data?.airlineName });

      setOfferData({
        ...offerData,
        weight: data?.weight,
        message: data?.description,
        value: data?.itemValue,
        offerPrice: data?.offerPrice,
        sizeWidth: width,
        sizeHeight: height,
        sizeLength: length,
        flightNumber: data?.flightNumber,
      });
      setImages(data?.images);
    }
  }, [reuseData]);

  useEffect(() => {
    if (Object.keys(reuseData).length !== 0) {
      const data = reuseData;

      if (data?.packagetypes) {
        let types = data?.packagetypes?.split(", ");
        if (data?.packagetypes?.includes(",")) {
          const packages = types
            .map((item) => {
              return typeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setType(packages);
          const results = packages?.map((pkg) => {
            return pkg.value;
          });
          setPackagetypeIds(results);
        } else {
          setType(
            typeOptions.find((option) => option.label === data?.packagetypes)
          );
          setPackagetypeIds(
            typeOptions.find((option) => option.label === data?.packagetypes)
              ?.value
          );
        }
      } else {
        setType([{ value: packagesType[0]?.id, label: packagesType[0]?.name }]);
      }
      if (data?.deliveryTypes) {
        let services = data?.deliveryTypes?.split(", ");
        if (data?.deliveryTypes?.includes(",")) {
          const deliveries = services
            .map((item) => {
              return deliveryTypeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setDeliveryType(deliveries);
          const results = deliveries?.map((srv) => {
            return srv.value;
          });
          setDeliverytypeIds(results);
        } else {
          setDeliveryType(
            deliveryTypeOptions.find(
              (option) => option.label === data?.deliveryTypes
            )
          );
          setDeliverytypeIds(
            deliveryTypeOptions.find(
              (option) => option.label === data?.deliveryTypes
            )?.value
          );
        }
      }
      //  else {
      //   setDeliveryType({
      //     value: deliveryTypes[0]?.id,
      //     label: deliveryTypes[0]?.name,
      //   });
      // }
      if (data?.size) {
        let sizes = data?.size?.split(", ");
        if (data?.size?.includes("Custom") || data?.size?.includes("سفارشی")) {
          setIsCustom(true);
          setSize(
            sizeOptions.find(
              (option) =>
                option.value === "6a50f6c5-0c00-498e-9653-1d8914172181"
            )
          );
          setSizerangeIds("6a50f6c5-0c00-498e-9653-1d8914172181");
        } else setIsCustom(false);
        if (data?.size?.includes(",")) {
          const ranges = sizes
            .map((item) => {
              return sizeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setSize(ranges[0]);
        } else
          setSize(sizeOptions.find((option) => option.label === data?.size));
      } else {
        setSize({ value: sizeRanges[0]?.id, label: sizeRanges[0]?.name });
      }
      if (data?.paymentmethods) {
        if (data?.paymentmethods?.includes(",")) {
          const paymentsData = data?.paymentmethods
            ?.split(", ")
            .map((item) => {
              return paymentsOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setPayment(paymentsData);
        } else
          setPayment(
            paymentsOptions.find(
              (option) => option.label === data?.paymentmethods
            )
          );
      } else {
        setPayment({ value: payments[0]?.id, label: payments[0]?.name });
      }
      if (data?.cancellationpolicy) {
        if (data?.cancellationpolicy?.includes(",")) {
          const policyData = data?.cancellationpolicy
            ?.split(", ")
            .map((item) => {
              return cancellationPolicyOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setPolicy(policyData);
        } else
          setPolicy(
            cancellationPolicyOptions.find(
              (option) => option.label === data?.cancellationpolicy
            )
          );
      } else {
        setPolicy({ value: policies[0]?.id, label: policies[0]?.name });
      }
    }
  }, [
    reuseData,
    typeOptions,
    sizeOptions,
    deliveryTypeOptions,
    paymentsOptions,
    cancellationPolicyOptions,
  ]);

  useEffect(() => {
    setRetrieveData({
      type: "travel",
      fromCityEntityId: from?.value,
      toCityEntityId: to?.value,
      fromDate1: fromDate1,
      fromDate2: fromDate2,
    });
  }, [from, to, fromDate1, fromDate2]);

  const handleChangeClose = () => {
    if (showReuse === true) {
      setShowReuse(false);
      onSelectReuse(false);
    } else {
      setIsOpen(!isOpen);
      document.documentElement.style.overflow = "auto";
      if (str?.includes("/packageId/")) {
        navigate("/");
        const data = {
          type: "package",
          packagetypeIds: null,
          weightrangeIds: null,
          deliverytypeIds: null,
          sizerangeIds: null,
          serviceIds: null,
          paymentmethodIds: null,
          cancellationpolicyIds: null,
          userLevelIds: null,
          page: 1,
        };
        dispatch(getAllHomeRequestFilter(data));
      }
    }
  };

  // useEffect(() => {
  //   document.addEventListener(
  //     "click",
  //     function (e) {
  //       const target = e.target as HTMLTextAreaElement;
  //       const element = target.className;
  //       if (element === "bm-overlay") {
  //         document.documentElement.style.overflow = "auto";
  //         // navigate("/");
  //       }
  //     },
  //     false
  //   );
  // }, []);

  const openReuseSidebar = () => {
    if (from?.value !== undefined && to?.value !== undefined) {
      setShowReuse(true);
      onSelectReuse(true);
    } else if (from?.value === undefined)
      toast.warn(
        lang === "fa"
          ? "لطفا فرودگاه مبدا را وارد کنید"
          : "Please enter departure airport"
      );
    else if (to?.value === undefined)
      toast.warn(
        lang === "fa"
          ? "لطفا فرودگاه مقصد را وارد کنید"
          : "Please enter arrival airport"
      );
  };

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  useEffect(() => {
    const options = payments?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setPayment({ value: payments[0]?.id, label: payments[0]?.name });
    setPaymentmethodIds(payments[0].id);
    setPaymentsOptions(options);
  }, [payments]);

  const handlePaymentChange = (selected) => {
    setPayment(selected);
    if (selected.value === "6dd94ea2-a4d7-4dd6-824c-b004b65a6b85")
      setIsCash(false);
    else setIsCash(true);
    if (selected.value === "0737c3c9-f109-460d-aeaf-431c80df4e65")
      setIsCancellation(true);
    else setIsCancellation(false);
    setPaymentmethodIds(selected.value);
  };

  const handlePolicyChange = (selected) => {
    setPolicy(selected);
  };

  const handleCurrencyChange = (selected) => {
    if (selected.value !== "CAD") setIsCad(false);
    else setIsCad(true);
    setCurrency(selected);
    localStorage.setItem("currency", selected.value);
  };

  const handleUnitChange = (selected) => {
    setWeightUnit(selected);
  };

  const handleLengthUnitChange = (selected) => {
    setLengthUnit(selected);
  };

  const openInfoModal = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
      height: "auto",
      minHeight: 50,
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? "#c1c1c1" : "#00043d",
      backgroundColor: "#fff",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
  };

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#707070",
      flexWrap: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
  };

  const handleTermsCheckedChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleGovernmentChange = () => {
    setGovernmentChecked(!governmentChecked);
  };

  useEffect(() => {
    const options = deliveryTypes?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setDeliveryType(null);
    // setDeliverytypeIds([deliveryTypes[0]?.id]);
    setDeliveryTypeOptions(options);
  }, [deliveryTypes]);

  useEffect(() => {
    const options = policies?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setPolicy(null);
    const valueToDisable = "23e33081-d1fd-48ef-a17a-fbbdc69076ce";
    const modifiedCancellationPolicyOptions = options.map((option) => ({
      ...option,
      isDisabled: option.value === valueToDisable,
    }));
    setCancellationPolicyOptions(modifiedCancellationPolicyOptions);
  }, [policies]);

  useEffect(() => {
    const options = airlines?.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    setAirlineOptions(options);
  }, [airlines]);

  useEffect(() => {
    const options = sizeRanges?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSize(null);
    setSizerangeIds([sizeRanges[0]?.id]);
    setSizeOptions(options);
  }, [sizeRanges]);

  useEffect(() => {
    const options = packagesType?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setType(null);

    setPackagetypeIds(
      [
        ...packagetypeIds,
        { value: packagesType[0]?.id, label: packagesType[0]?.name },
      ].filter(function (x) {
        return x.value !== undefined;
      })
    );
    setTypeOptions(options);
  }, [packagesType]);

  const handleAirlineChange = (selected) => {
    setAirline(selected);
    // setIsSelected(true);
    // setIsFromDropdownOpen(false);
  };

  const handleDeliveryTypeChange = (selected) => {
    setDeliveryType(selected);
    setDeliverytypeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
  };

  const handleSizeChange = (selected) => {
    setSize(selected);
    setSizerangeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
    if (
      selected?.find((item) => {
        return item.value === "6a50f6c5-0c00-498e-9653-1d8914172181";
      })
    )
      setIsCustom(true);
    else setIsCustom(false);
  };

  const handleTypeChange = (selected) => {
    // if (
    //   selected?.find((item) => {
    //     return item.label === "Traveling Companion";
    //   })
    // ) {
    // setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);
    // setIsCompanion(true);
    // setSize({
    //   value: "6a50f6c5-0c00-498e-9653-1d8914172181",
    //   label: "Custom",
    // });
    // setSizerangeIds("6a50f6c5-0c00-498e-9653-1d8914172181");
    // setOfferData({
    //   ...offerData,
    //   sizeWidth: "0",
    //   sizeHeight: "0",
    //   sizeLength: "0",
    // });
    // setType([
    //   {
    //     value: "60db617a-dba2-406c-a960-1928a8b45320",
    //     label: "Traveling Companion",
    //   },
    // ]);
    // } else {
    setIsCompanion(false);
    setType(selected);
    var result1 = selected.map(function (item) {
      return item.value;
    });
    const result2 = result1.filter(function (item) {
      return item !== "60db617a-dba2-406c-a960-1928a8b45320";
    });
    setPackagetypeIds(result2);
    // }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    // setOfferData({ ...offerData, [e.target.name]: e.target.value });
    const newValue = e.target.value;
    if (newValue.length <= 100) {
      setOfferData({ ...offerData, [e.target.name]: newValue });
    } else {
      setOfferData({
        ...offerData,
        [e.target.name]: newValue.slice(0, 100),
      });
    }
  };

  const handleFromChange = (selected) => {
    setFrom(selected);
  };

  const handleToChange = (selected) => {
    setTo(selected);
  };

  const onArrivalBetweenDateChange = (date) => {
    setArrivalBetweenDate(date);
    setDepartureBetweenDate(date);
    setFromDate1(getDate(date));
    setFromDate2(getDate(date));
    setArrivalToDate(date);
    setToDate1(getDate(date));
  };

  const onDepartureFromDateChange = (date) => {
    setDepartureBetweenDate(date);
    setFromDate2(getDate(date));
    setArrivalToDate(date);
    setToDate1(getDate(date));
  };

  const onArrivalToDateChange = (date) => {
    setArrivalToDate(date);
    setToDate1(getDate(date));
  };

  const onDepartureToDateChange = (date) => {
    setDepartureToDate(date);
    setToDate2(getDate(date));
  };

  useEffect(() => {
    setIsCompanion(false);
    if (data?.packagetypes) {
      let types = data?.packagetypes?.split(", ");
      if (data?.packagetypes?.includes(",")) {
        const packages = types
          .map((item) => {
            return typeOptions?.filter((x) => {
              return item === x.label;
            });
          })
          .flat(2);
        setType(packages);
        const results = packages?.map((pkg) => {
          return pkg.value;
        });
        setPackagetypeIds(results);
      } else if (
        data?.packagetypes === "همسفر" ||
        data?.packagetypes === "Traveling Companion"
      ) {
        setIsCompanion(true);
        if (isPersian) {
          setSize({
            value: "6a50f6c5-0c00-498e-9653-1d8914172181",
            label: "سفارشی",
          });
        } else {
          setSize({
            value: "6a50f6c5-0c00-498e-9653-1d8914172181",
            label: "Custom",
          });
        }

        setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);

        setOfferData({
          ...offerData,
          sizeWidth: "0",
          sizeHeight: "0",
          sizeLength: "0",
        });
        setType([
          {
            value: "60db617a-dba2-406c-a960-1928a8b45320",
            label: isPersian ? "همسفر" : "Traveling Companion",
          },
        ]);
      } else {
        setIsCompanion(false);
        setType(
          typeOptions.find((option) => option.label === data?.packagetypes)
        );
        setPackagetypeIds(
          typeOptions.find((option) => option.label === data?.packagetypes)
            ?.value
        );
      }
    } else {
      setType([{ value: packagesType[0]?.id, label: packagesType[0]?.name }]);
    }
    if (data?.deliveryTypes) {
      let services = data?.deliveryTypes?.split(", ");
      if (data?.deliveryTypes?.includes(",")) {
        const deliveries = services
          .map((item) => {
            return deliveryTypeOptions?.filter((x) => {
              return item === x.label;
            });
          })
          .flat(2);
        setDeliveryType(deliveries);
        const results = deliveries?.map((srv) => {
          return srv.value;
        });
        setDeliverytypeIds(results);
      } else {
        setDeliveryType(
          deliveryTypeOptions.find(
            (option) => option.label === data?.deliveryTypes
          )
        );

        setDeliverytypeIds(
          deliveryTypeOptions.find(
            (option) => option.label === data?.deliveryTypes
          )?.value
        );
      }
    } else {
      setDeliveryType({
        value: deliveryTypes[0]?.id,
        label: deliveryTypes[0]?.name,
      });
    }
    if (data?.sizerange) {
      let sizes = data?.sizerange?.split(", ");
      if (
        data?.sizerange?.includes("Custom") ||
        data?.sizerange?.includes("سفارشی")
      ) {
        setIsCustom(true);
        setSize(
          sizeOptions.find(
            (option) => option.value === "6a50f6c5-0c00-498e-9653-1d8914172181"
          )
        );
        setSizerangeIds("6a50f6c5-0c00-498e-9653-1d8914172181");
      } else setIsCustom(false);
      if (data?.sizerange?.includes(",")) {
        const ranges = sizes
          .map((item) => {
            return sizeOptions?.filter((x) => {
              return item === x.label;
            });
          })
          .flat(2);
        setSize(ranges);
        const results = ranges?.map((srv) => {
          return srv.value;
        });
        setSizerangeIds(results);
      } else {
        setSize(sizeOptions.find((option) => option.label === data?.sizerange));

        setSizerangeIds(
          sizeOptions.find((option) => option.label === data?.sizerange)?.value
        );
      }
    } else {
      setSize({ value: sizeRanges[0]?.id, label: sizeRanges[0]?.name });
    }
  }, [data, typeOptions, sizeOptions, deliveryTypeOptions]);

  useEffect(() => {
    if (data) {
      const width = data?.sizeWidth;
      const height = data?.sizeHeight;
      const length = data?.sizeLength;
      const currency = data?.currencyType ? data?.currencyType : "CAD";
      const unit = data?.weightUnit ? data?.weightUnit : "KG";
      setPolicy(
        cancellationPolicyOptions.find(
          (option) => option.label === data.cancellationpolicy
        )
      );
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      setFrom({ value: 0, label: data?.fromCountry });
      setHasPurchase(data?.hasPurchase);
      setTo({ value: 0, label: data?.toCountry });
      setArrivalBetweenDate(convertHumanDateToUnix(data?.fromDate1));
      setFromDateRange1(convertHumanDateToUnix(data?.fromDate1));
      setFromDateRange2(convertHumanDateToUnix(data?.fromDate2));
      setArrivalToDate(convertHumanDateToUnix(data?.fromDate2));
      setDepartureBetweenDate(convertHumanDateToUnix(data?.fromDate2));
      setDepartureToDate(convertHumanDateToUnix(data?.toDate2));
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      setPkgId(data?.pkgId);
      searchLocationApi(data?.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data?.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });
      const newPurchases = data?.purchases?.map(({ link, quantity }) => ({
        Link: link,
        Quantity: quantity,
      }));
      setPurchases(
        newPurchases?.length !== 0
          ? newPurchases
          : [{ Link: "", Quantity: "0" }]
      );
      setOfferData({
        ...offerData,
        weight: data?.weight,
        value: data?.itemValue,
        offerPrice: data?.offerPrice,
        sizeWidth: width,
        sizeHeight: height,
        sizeLength: length,
        airlineName: data?.AirlineName,
      });
      setPackageImages(data?.images);
    }
  }, [data, sizeOptions]);

  const chooseOffer = () => {
    setSubmitClicked(true);
    const data = {
      // service,
      type,
      size,
      payment,
      policy,
      length: offerData.sizeLength,
      width: offerData.sizeWidth,
      height: offerData.sizeHeight,
    };
    if (!isValid(termsChecked, governmentChecked, isCancellation, data)) {
      toast.error(
        lang === "fa"
          ? "پارامترها را به درستی وارد نمایید"
          : "Enter the parameters correctly!"
      );
      return;
    }
    chooseOfferApiCall();
    document.documentElement.style.overflow = "auto";
  };

  const chooseOfferApiCall = () => {
    document.documentElement.style.overflow = "hidden";
    setIsLoading(true);
    const body = new FormData();
    body.append("pkgId", pkgId);
    // body.append("trvId", reuseTrvId);
    // body.append("packagetypeIds", packagetypeIds);
    body.append("sizerangeIds", sizerangeIds);
    body.append("paymentmethodIds", paymentmethodIds);
    body.append("cancellationPolicyId", policy?.value ? policy?.value : "");
    body.append("value", offerData?.value);
    body.append("sizeWidth", isCustom ? offerData?.sizeWidth : "");
    body.append("sizeHeight", isCustom ? offerData?.sizeHeight : "");
    body.append("sizeLength", isCustom ? offerData?.sizeLength : "");
    body.append("lengthUnit", lengthUnit?.value);
    body.append("deliverytypeIds", deliverytypeIds);
    body.append("toCityEntityId", to?.value ? to?.value : "");
    body.append("fromDate1", fromDate1);
    body.append("fromDate2", fromDate2);
    body.append("toDate2", toDate2);
    body.append("offerPrice", offerData?.offerPrice);
    body.append("currencyType", currency?.value);
    body.append("message", offerData.message);
    for (const image of images) {
      body.append("images", image);
    }
    body.append("weight", offerData.weight);
    body.append("weightUnit", weightUnit?.value);
    body.append("airlineName", airline?.value ? airline?.value : "");
    body.append("flightNumber", offerData?.flightNumber);
    dispatch(chooseLandingTravel(body));
    setIsLoading(true);
    setIsSuccess(true);
  };

  useEffect(() => {
    if (!updatedChooseOffer?.isSuccess) {
      setIsLoading(false);
      document.documentElement.style.overflow = "hidden";
    } else if (updatedChooseOffer?.isSuccess && isSuccess) {
      document.documentElement.style.overflow = "auto";
      setIsLoading(false);
      setIsOpen(false);
      const data = {
        type: "travel",
        packagetypeIds: null,
        weightrangeIds: null,
        deliverytypeIds: null,
        sizerangeIds: null,
        serviceIds: null,
        paymentmethodIds: null,
        cancellationpolicyIds: null,
        // cancellationpolicyIds: "422A36FD-F65F-4A1D-830D-20C1D66C42AB",
        userLevelIds: null,
        page: 1,
      };
      dispatch(getAllHomeTravelFilter(data));
      setIsSuccess(false);
      navigate("/dashboard/latestSentOffer", { state: t("latestSentOffer") });
    }
  }, [updatedChooseOffer]);

  const preventTypeTextAndDot = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventTypeText = (e) => {
    const validInputRegex = /^[0-9.]$/; // Allow digits and dot
    const currentValue = e.target.value + e.key;
    if (validInputRegex.test(e.key) || e.key === "Backspace") {
      const regex = /^(?!1000(\.0)?$)\d{1,3}(\.\d{0,1})?$/;
      if (regex.test(currentValue) || currentValue === "") {
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const checkLabel = () => {
    if (
      Array.isArray(size) &&
      size?.find((item) => {
        return (
          item.value === "6a50f6c5-0c00-498e-9653-1d8914172181" && (
            <span className="star-require">*</span>
          )
        );
      })
    ) {
      return <span className="star-require">*</span>;
    } else if (size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181") {
      <span className="star-require">*</span>;
    }
  };

  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  return (
    <>
      <div
        className={`${
          showReuse ? "set-alert-slider-container" : "request-slider-container"
        }`}
      >
        <Row className="request-wrapper pb-3">
          <Link
            className="ml-auto"
            to={str?.includes("/packageId/") && !showReuse && `/`}
          >
            <Button
              variant="white"
              className="close-sidebar-btn"
              onClick={handleChangeClose}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </Link>
          <Col
            xs={12}
            className="request-form"
            style={{ paddingRight: isMobile ? "15px" : "1.79rem" }}
          >
            <div className={`${isMobile ? "" : "d-flex"}`}>
              <h1 className="d-inline-block">
                {showReuse ? t("travelHistory") : t("acceptOffer")}
              </h1>
              {!showReuse && (
                <Button
                  variant="primary"
                  className="d-inline-block reuse-previous-travel-btn"
                  onClick={openReuseSidebar}
                >
                  <FontAwesomeIcon icon={faClockRotateLeft} />
                  {t("RetrievePreviousTravel")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {showReuse ? (
          <ReuseTravelHistory
            retrieveData={retrieveData}
            setReuseData={setReuseData}
          />
        ) : (
          <Row className={`request-wrapper pt-0 ${isPersian ? "dir-rtl" : ""}`}>
            <Col xs={12} className="request-form">
              <Row className="align-items-center">
                <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                  <span className="send-pack-title">{t("from")}</span>
                </Col>
                <Col xs={10}>
                  <AsyncSelect
                    className={`custom-place-from-acceptOffer`}
                    value={from}
                    isDisabled
                    onChange={handleFromChange}
                    cacheOptions
                    defaultOptions
                    placeholder={
                      <span className="select-placeholder-text">
                        {t("countryCityAirport")}
                      </span>
                    }
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    styles={customStyle}
                    loadOptions={searchAirportLocation}
                    loadingMessage={() => "Loading ..."}
                  />
                </Col>
              </Row>
              <Row className="align-items-center mt-3">
                <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                  <span className="send-pack-title">{t("to")}</span>
                </Col>
                <Col xs={10}>
                  <AsyncSelect
                    className={`custom-place-from-acceptOffer`}
                    value={to}
                    onChange={handleToChange}
                    cacheOptions
                    defaultOptions
                    isDisabled
                    placeholder={
                      <span className="select-placeholder-text">
                        {t("countryCityAirport")}
                      </span>
                    }
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    styles={customStyle}
                    loadOptions={searchAirportLocation}
                    loadingMessage={() => "Loading ..."}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="request-form">
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={isPersian ? 4 : 5}
                      className={`${isPersian ? "text-right pr-3" : ""}`}
                    >
                      <span className="send-pack-title">
                        {t("travelDepartureDate")}
                      </span>
                    </Col>
                    <Col
                      xs={isPersian ? 8 : 7}
                      className={isPersian ? "pr-0" : "pr-0 pl-0"}
                    >
                      <DatePicker
                        className="custom-input-between-acceptOffer"
                        selected={arrivalBetweenDate}
                        onChange={(date) => onArrivalBetweenDateChange(date)}
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        minDate={fromDateRange1}
                        maxDate={fromDateRange2}
                        // disabled
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={isPersian ? 4 : 5}
                      className={`${!isMobile && "text-center"} ${
                        isPersian ? "text-right pr-3" : "pr-0"
                      }`}
                    >
                      <span className="send-pack-title">
                        {t("travelArrivalDate")}
                      </span>
                    </Col>
                    <Col
                      xs={isPersian ? 8 : 7}
                      className={`${isPersian ? " text-right pr-1" : "pl-0"}`}
                    >
                      <DatePicker
                        className="custom-input-to-request-acceptOffer"
                        selected={departureBetweenDate}
                        onChange={(date) => onDepartureFromDateChange(date)}
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        // disabled
                        minDate={addDays(arrivalBetweenDate, 0)}
                        // minDate={fromDateRange1}
                        maxDate={fromDateRange2}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="request-form">
              <Row className="align-items-center mt-3">
                <Col xs={3} className={isPersian ? "text-right pr-3" : ""}>
                  <span className="send-pack-title">
                    {t("acceptablePackageType")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col xs={9} className={isPersian ? "" : "pl-0"}>
                  <Select
                    className={`custom-select-accept-offer-type ${
                      submitClicked &&
                      !isValidPackageType(type) &&
                      "custom-empty-input-style"
                    }`}
                    value={type}
                    onChange={handleTypeChange}
                    placeholder={t("selectFromOptions")}
                    options={typeOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      Option: InputOption,
                    }}
                    isMulti
                    styles={customStyle}
                    isDisabled
                  />
                </Col>
              </Row>
            </Col>
            {isCompanion ? null : (
              <Col xs={12} className="request-form">
                <Row className="align-items-center mt-3">
                  <Col
                    xs={5}
                    lg={isPersian ? 4 : 3}
                    md={isPersian ? 4 : 3}
                    sm={5}
                    className={isPersian ? "text-right pr-3" : ""}
                  >
                    <span className="send-pack-title">
                      {t("maxSizeParcelCarry")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col
                    xs={6}
                    lg={isPersian ? 7 : 8}
                    md={isPersian ? 7 : 8}
                    sm={6}
                    className={isPersian ? "" : "pl-0"}
                  >
                    <Select
                      className={`custom-select-package-size ${
                        submitClicked &&
                        !isValidSize(size) &&
                        "custom-empty-input-style"
                      }`}
                      value={size}
                      onChange={handleSizeChange}
                      options={sizeOptions}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                        Option: InputOption,
                      }}
                      placeholder={t("selectMaxSizePlaceholder")}
                      styles={customStyle}
                      isMulti
                      isDisabled={isCompanion}
                    />
                  </Col>
                  <Col xs={1} className={`${isPersian ? "pr-0" : "pl-0"}`}>
                    <Dropdown
                      className="size-info-dropdown"
                      onToggle={handleSelect}
                    >
                      <Dropdown.Toggle
                        variant="transparent"
                        id="dropdown-basic"
                        className="px-0 mx-0"
                      >
                        <div className="triangle">
                          <FontAwesomeIcon
                            icon={faInfo}
                            className="info-defined-wrapper"
                          />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <strong>{t("small")}</strong>, {t("smallExample")}
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <strong>{t("medium")}</strong>, {t("mediumExample")}
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <strong>{t("large")}</strong>, {t("largeExample")}
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <strong>{t("xlarge")}</strong>, {t("xLargeExample")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </Col>
            )}

            {!isCustom || isCompanion ? null : (
              <Col xs={12} className="request-form">
                <Row className="align-items-center mt-3">
                  <Col
                    xs={3}
                    className={isPersian ? "text-right pr-3" : "pr-0"}
                  >
                    <span className="send-pack-title">
                      Defined as
                      {checkLabel()}
                    </span>
                  </Col>
                  <Col xs={2} className="pl-0">
                    <Input
                      size="sm"
                      id="sizeLength"
                      name="sizeLength"
                      placeholder="Length"
                      className={`custom-input-size-length ${
                        submitClicked && Array.isArray(size)
                          ? size?.map((item) => {
                              return (
                                item.value ===
                                  "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                                !isValidDefinedLengthSize(
                                  offerData.sizeLength
                                ) &&
                                "empty-input-style"
                              );
                            })
                          : size?.value ===
                              "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                            !isValidDefinedLengthSize(offerData.sizeLength) &&
                            "empty-input-style"
                      }`}
                      value={offerData.sizeLength}
                      onChange={handleChange}
                      disabled={!isCustom || isCompanion}
                      onKeyPress={preventTypeText}
                    />
                  </Col>
                  <Col xs={2} className="pl-0">
                    <Input
                      size="sm"
                      id="sizeWidth"
                      name="sizeWidth"
                      placeholder="Width"
                      className={`custom-input-size-width ${
                        submitClicked && Array.isArray(size)
                          ? size?.map((item) => {
                              return (
                                item.value ===
                                  "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                                !isValidDefinedWidthSize(offerData.sizeWidth) &&
                                "empty-input-style"
                              );
                            })
                          : size?.value ===
                              "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                            !isValidDefinedWidthSize(offerData.sizeWidth) &&
                            "empty-input-style"
                      }`}
                      value={offerData.sizeWidth}
                      onChange={handleChange}
                      disabled={!isCustom || isCompanion}
                      onKeyPress={preventTypeText}
                    />
                  </Col>
                  <Col xs={2} className="pl-0">
                    <Input
                      size="sm"
                      id="sizeHeight"
                      name="sizeHeight"
                      placeholder="Height"
                      className={`custom-input-size-height ${
                        submitClicked && Array.isArray(size)
                          ? size?.map((item) => {
                              return (
                                item.value ===
                                  "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                                !isValidDefinedHeightSize(
                                  offerData.sizeHeight
                                ) &&
                                "empty-input-style"
                              );
                            })
                          : size?.value ===
                              "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                            !isValidDefinedHeightSize(offerData.sizeHeight) &&
                            "empty-input-style"
                      }`}
                      value={offerData.sizeHeight}
                      onChange={handleChange}
                      disabled={!isCustom || isCompanion}
                      onKeyPress={preventTypeText}
                    />
                  </Col>
                  <Col xs={2} className="pl-0">
                    <Select
                      className="custom-select-unit-length d-inline-block"
                      value={lengthUnit}
                      onChange={handleLengthUnitChange}
                      options={unitLengthOption}
                      styles={unitCustomStyle}
                      isDisabled={!isCustom || isCompanion}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                    />
                  </Col>
                  <Col xs={1} className={`${isPersian ? "pr-0" : "pl-0"}`}>
                    <div className="triangle">
                      <FontAwesomeIcon
                        icon={faInfo}
                        className="info-defined-wrapper"
                        onClick={openInfoModal}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            )}

            {isInfoOpen && (
              <SizeInformation isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
            )}
            <Col xs={12} className="request-form">
              {isCompanion ? null : (
                <Row className="align-items-center mt-3">
                  <Col xs={3} className={isPersian ? "text-right pr-3" : ""}>
                    <span className="send-pack-title">{t("maxWeightOf")}</span>
                  </Col>
                  <Col xs={9} className={isPersian ? "" : "pl-0"}>
                    <Input
                      size="sm"
                      id="weight"
                      name="weight"
                      placeholder={t("weightKgLbs")}
                      className="custom-input-package"
                      value={offerData.weight}
                      onChange={handleChange}
                      // disabled
                      onKeyPress={preventTypeText}
                    />
                    <Select
                      className="custom-select-unit-size"
                      value={weightUnit}
                      onChange={handleUnitChange}
                      options={unitWeightOption}
                      styles={unitCustomStyle}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                      // isDisabled
                    />
                  </Col>
                </Row>
              )}
            </Col>

            <Col xs={12} className="request-form">
              {isCompanion ? null : (
                <Row className="align-items-center mt-3">
                  <Col xs={4} className={isPersian ? "text-right pr-3" : ""}>
                    <span className="send-pack-title">
                      {t("providedService")}
                    </span>
                  </Col>
                  <Col xs={8} className={isPersian ? "" : "pl-0"}>
                    <Select
                      className="custom-select-offer-carry-delivery"
                      value={deliveryType}
                      placeholder={t("providedServicePlaceholder")}
                      onChange={handleDeliveryTypeChange}
                      options={deliveryTypeOptions}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                        Option: InputOption,
                      }}
                      styles={customStyle}
                      isMulti
                    />
                  </Col>
                </Row>
              )}
            </Col>

            <Col xs={12} className="request-form">
              <Row className="align-items-center mt-3">
                <Col
                  xs={2}
                  className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                >
                  <span className="send-pack-title">{t("fee")}</span>
                </Col>
                <Col xs={10}>
                  <Input
                    size="sm"
                    id="offerPrice"
                    name="offerPrice"
                    placeholder="0"
                    className="custom-input-package-price"
                    value={offerData.offerPrice}
                    onChange={handleChange}
                    onKeyPress={preventTypeTextAndDot}
                  />
                  <Select
                    className="custom-select-unit-size d-inline-block"
                    value={currency}
                    onChange={handleCurrencyChange}
                    options={currenciesOption}
                    styles={unitCustomStyle}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </Col>
              </Row>
              <Row className="align-items-center mt-3">
                <Col xs={3} className={isPersian ? "text-right pr-3" : ""}>
                  <span className="send-pack-title">
                    {t("paymentMethod")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col xs={9} className={isPersian ? "" : "pl-0"}>
                  <Select
                    className={`custom-select-carry-payable ${
                      submitClicked &&
                      !isValidPayment(payment) &&
                      "custom-empty-input-style"
                    }`}
                    value={payment}
                    onChange={handlePaymentChange}
                    options={paymentsOptions}
                    placeholder="Select payment method from available options"
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      Option: InputOption,
                    }}
                    styles={customStyle}
                    // isMulti
                  />
                </Col>
              </Row>
              {
                !isCancellation && payment ? (
                  <span className="warning-payable-title d-block mt-3 mb-3 w-100">
                    {t("cashBeforeShippingWarning")}
                  </span>
                ) : isCancellation && payment ? (
                  <span className="info-payable-title d-block mt-2 mb-3 w-100">
                    {t("attentionTravelerPart1")}{" "}
                    {isMobileTablet ? (
                      <Link
                        target="_blank"
                        to="/settings/addBankAccount"
                        onClick={() => {
                          localStorage.setItem("tab", "financial");
                        }}
                      >
                        {" "}
                        {t("attentionTravelerPart2")}{" "}
                      </Link>
                    ) : (
                      <a
                        target="_blank"
                        href="/settings/addBankAccount"
                        onClick={() => {
                          localStorage.setItem("tab", "userInfo");
                        }}
                      >
                        {" "}
                        {t("attentionTravelerPart2")}{" "}
                      </a>
                    )}
                    {t("attentionTravelerPart3")}
                  </span>
                ) : null // </span> //   Bank information - Payout/ Stripe connect Express // <span className="success-payable-title d-block mt-0 mb-3 w-100">
              }
              {isCash && !isCad && payment && (
                <span className="warning-payable-title d-block mt-3 mb-3 w-100">
                  {t("currencyWarning")}
                </span>
              )}
            </Col>
            <Col xs={12} className="request-form">
              <span
                className={`send-pack-title mt-4 d-block w-100 ${
                  isPersian ? "text-right" : ""
                }`}
              >
                {t("deliveryDateWillBe")}:
              </span>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={3}
                      className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                    >
                      <span className="send-pack-title">{t("fromDate")}</span>
                    </Col>
                    <Col xs={9} className={isPersian ? "pr-0" : "pl-0"}>
                      <DatePicker
                        className="custom-input-between-acceptOffer"
                        selected={arrivalToDate}
                        onChange={(date) => onArrivalToDateChange(date)}
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={3}
                      className={` ${isMobile ? "" : "pr-0 text-center"}`}
                    >
                      <span className="send-pack-title">{t("toDate")}</span>
                    </Col>
                    <Col xs={9} className={`${isPersian ? "" : "pl-0"} `}>
                      <DatePicker
                        className="custom-input-to-request-acceptOffer"
                        selected={departureToDate}
                        onChange={(date) => onDepartureToDateChange(date)}
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        // disabled
                        minDate={addDays(arrivalToDate, 0)}
                        maxDate={addDays(departureToDate, 0)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className="request-form">
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={4}
                      lg={5}
                      md={5}
                      sm={4}
                      className={isPersian ? "text-right pr-3" : ""}
                    >
                      <span className="send-pack-title">
                        {t("flightNameIs")}
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      lg={7}
                      md={7}
                      sm={8}
                      className={`${isPersian ? "pr-0" : "pl-0"} `}
                    >
                      <AsyncSelect
                        className="custom-input-airline"
                        value={airline}
                        onChange={handleAirlineChange}
                        cacheOptions
                        defaultOptions
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                          Option: InputOption,
                        }}
                        styles={customStyle}
                        placeholder={t("flightNameIs")}
                        loadOptions={searchAirline}
                        loadingMessage={() => "Loading ..."}
                        // noOptionsMessage={() => ""}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={4}
                      lg={isPersian ? 4 : 5}
                      md={isPersian ? 4 : 5}
                      sm={4}
                      className={` ${isMobile ? "" : "text-right"}`}
                    >
                      <span className="send-pack-title">
                        {t("andFlightNumber")}
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      lg={isPersian ? 8 : 7}
                      md={isPersian ? 8 : 7}
                      sm={8}
                      className={`${isPersian ? "pr-0" : "pl-0"} `}
                    >
                      <Input
                        id="flightNumber"
                        name="flightNumber"
                        placeholder={t("flightNumber")}
                        className="custom-input-offer-flight-number"
                        value={offerData.flightNumber}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {isCancellation && (
              <Col xs={12} className={`request-form`}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={isPersian ? 3 : 4}
                    className={` ${isPersian ? "text-right pr-3" : ""}`}
                  >
                    <span className="send-pack-title">
                      {t("cancellationPolicy")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col
                    xs={isPersian ? 9 : 8}
                    className={`${isPersian ? "pr-0" : "pl-0"} `}
                  >
                    <Select
                      className={`custom-select-package-policy ${
                        submitClicked &&
                        !isValidPolicy(policy, isCancellation) &&
                        "custom-empty-input-style"
                      }`}
                      // className={`custom-select-package-policy d-inline-block`}
                      value={policy}
                      onChange={handlePolicyChange}
                      options={cancellationPolicyOptions}
                      placeholder={t("selectCancellationPlaceholder")}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                        Option: InputOption,
                      }}
                      styles={customStyle}
                    />
                  </Col>
                </Row>
              </Col>
            )}
            <Col xs={12} sm={12} md={6} lg={6} className="request-form">
              <div
                style={
                  isMobile
                    ? isPersian
                      ? { width: "345px", paddingLeft: "20px" }
                      : { width: "345px", paddingRight: "20px" }
                    : isPersian
                    ? { width: "100%", textAlign: "right" }
                    : { width: "580px" }
                }
              >
                <MultipleUploader
                  title={t("uploadTicketOptional")}
                  setPhotos={setImages}
                  image={images}
                  disabled={false}
                  width="w-273"
                  description={t("dropImageHere")}
                  hasRemove={true}
                />
              </div>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className="request-form mt-4 h-100"
            >
              <div
                className={`send-input-wrapper ${
                  isPersian ? "text-right" : ""
                }`}
                style={{ marginTop: "-8px" }}
              >
                <span className="message-title">{t("noteDetail")}</span>
                <div>
                  <Input
                    id="message"
                    name="message"
                    placeholder={t("acceptAllVisibleDocs")}
                    className="custom-input-message-package"
                    value={offerData.message}
                    rows={4}
                    textArea={true}
                    onChange={handleChange}
                    maxLength="100"
                  />
                </div>
              </div>
            </Col>
            {data?.description && (
              <Col xs={12} className="request-form">
                <span className="description-offer-text">
                  {/* <strong>Package Note:</strong> {data?.description} */}
                </span>
              </Col>
            )}

            {hasPurchase && (
              <AddInputForms
                setPurchases={setPurchases}
                purchases={purchases}
                disabled={true}
              />
            )}

            <Col xs={12} className="request-form">
              <div style={{ marginTop: "30px" }}>
                <label className="checkbox-container">
                  {t("termConditionLinkText")}{" "}
                  <a
                    target="_blank"
                    href="/termsCondition"
                    style={{ textDecorationLine: "underline" }}
                  >
                    {t("termConditionLink")}{" "}
                  </a>
                  {t("termConditionLink2")}.
                  <input
                    type="checkbox"
                    checked={termsChecked}
                    onChange={handleTermsCheckedChange}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </Col>
            <Col xs={12} className="request-form">
              <div>
                <label className="checkbox-container gov-checkbox-container">
                  {t("acceptAllGovernmentTaxes")}{" "}
                  <input
                    type="checkbox"
                    checked={governmentChecked}
                    onChange={handleGovernmentChange}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </Col>
            <div
              className="d-flex w-100 px-3 dir-ltr"
              style={{ marginTop: "24px" }}
            >
              <Button
                onClick={handleChangeClose}
                className="close-request-btn ml-auto mt-4"
              >
                {t("close")}
              </Button>
              <Button
                variant="primary"
                onClick={chooseOffer}
                className="submit-request-btn mt-4 mx-3"
                disabled={!termsChecked || !governmentChecked}
              >
                {t("submitTheRequest")}
                {isLoading && (
                  <Oval
                    width="20"
                    height="20"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                  />
                )}
              </Button>
            </div>
            {/* </Col> */}
          </Row>
        )}
      </div>
    </>
  );
};
