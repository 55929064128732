import axios from "axios";

class FileService {
  deletePackageImg(data) {
    return axios.delete(
      `${process.env.REACT_APP_BASE_URL}user/Package/DelImage`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Accept-Language": localStorage.getItem("language"),
          Currency: localStorage.getItem("currency"),
          "Time-Zone": localStorage.getItem("timezone"),
        },
        data: JSON.stringify(data),
      }
    );
  }
  deleteTravelImg(data) {
    return axios.delete(
      `${process.env.REACT_APP_BASE_URL}user/Travel/DelImage`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
          "Accept-Language": localStorage.getItem("language"),
          Currency: localStorage.getItem("currency"),
          "Time-Zone": localStorage.getItem("timezone"),
        },
        data: JSON.stringify(data),
      }
    );
  }
}

export default new FileService();
