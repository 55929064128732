import { createSlice } from "@reduxjs/toolkit";

export const requestProblemSlice = createSlice({
  name: "requestProblem",
  initialState: [],
  reducers: {
    requestProblem: (state, action) => {
      return (state = action.payload);
    },
    requestProblemFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { requestProblem, requestProblemFailure } =
requestProblemSlice.actions;
export default requestProblemSlice.reducer;
