import React, { FC, useState, useEffect, useRef } from "react";
import {
  faClose,
  faCheck,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "redux/store";
import { IReviewProfile } from "models/interfaces";
import { LevelMarker } from "components/common/levelMarker";
import { getUserLevelColor } from "helpers/getUserLevel";
import { Rating } from "react-simple-star-rating";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProp {
  setIsOpen: Function;
  isOpen: boolean;
}

export const ReviewProfile: React.FC<IProp> = ({ isOpen, setIsOpen }) => {
  const [allProfile, setAllProfile] = useState<IReviewProfile>();
  const [allReviews, setAllReviews] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/");
  };

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          navigate("/");
        }
      },
      false
    );
  }, []);

  const reviewsData: any = useAppSelector((state) => state.allProfile);

  useEffect(() => {
    if (reviewsData?.isSuccess) {
      setAllProfile(reviewsData?.data);
      setAllReviews(reviewsData?.data?.reviews);
    }
  }, [reviewsData]);

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="set-alert-slider-container">
      <Row className="sidebar-content-wrapper">
        <Button
          variant="white"
          className="close-sidebar-btn mb-0"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className={`payment-form ${isPersian ? "dir-rtl" : "dir-ltr"}`}>
          <div className="image-profile-review-wrapper">
            <img
              className="img-review-profile-avatar"
              src={allProfile?.personalPhoto}
              alt="profile-image"
            />
            <div className="d-flex mt-3" style={{ justifyContent: "center" }}>
              <h1>
                {allProfile?.firstName} {allProfile?.lastName}{" "}
                {/* <LevelMarker color={getUserLevelColor(allProfile?.userLevel)} /> */}
              </h1>
              <div className="profile-userlevel-icons-wrapper">
                <FontAwesomeIcon
                  className="mr-2 userlevel-certificate-icon"
                  icon={faCertificate}
                  color={allProfile?.userLevelHexColor}
                ></FontAwesomeIcon>
                <FontAwesomeIcon
                  className="mr-2 userlevel-check-icon"
                  icon={faCheck}
                />
              </div>
            </div>
          </div>
        </Col>

        <Col xs={12} className={`payment-form  ${isPersian ? "text-right" : "text-left"}`}>
          {allProfile?.aboutMe && (
            <span className="title-profile-review d-inline-block">
              {t("about")} {allProfile?.displayName}
            </span>
          )}

          {allProfile?.aboutMe && (
            <span className="about-me-profile-review">
              {allProfile?.aboutMe}
            </span>
          )}
          <span
            className="d-inline-block"
            // style={{ width: "100px" }}
          >
            <Rating size={20} initialValue={allProfile?.rating} readonly />
            <span className="reviews-number-profile">
              {allReviews?.length} {t("reviews")}
            </span>
          </span>
        </Col>

        <Col xs={12} className={`payment-form mt-3 ${isPersian ? "dir-rtl" : "dir-ltr"}`}>
          <span className="items-profile-review">
            <span className="bullet-validation"></span>
            {t("validPass")}{" "}
            {allProfile?.isValidPassport ? (
              <FontAwesomeIcon
                className="icon-validation-user validation-items-profile"
                icon={faCheck}
              />
            ) : (
              <FontAwesomeIcon
                className="icon-validation-user not-validation-items-profile"
                icon={faClose}
              />
            )}
          </span>
          <span className="items-profile-review">
            <span className="bullet-validation"></span>
            {t("validPhone")}{" "}
            {allProfile?.isPhoneNumberVerified ? (
              <FontAwesomeIcon
                className="mr-2 validation-items-profile"
                icon={faCheck}
              />
            ) : (
              <FontAwesomeIcon
                className="mr-2 not-validation-items-profile"
                icon={faClose}
              />
            )}
          </span>
          <span className="items-profile-review">
            <span className="bullet-validation"></span>
            {t("validID")}{" "}
            {allProfile?.isIdValidation ? (
              <FontAwesomeIcon
                className="mr-2 validation-items-profile"
                icon={faCheck}
              />
            ) : (
              <FontAwesomeIcon
                className="mr-2 not-validation-items-profile"
                icon={faClose}
              />
            )}
          </span>
          <span className="items-profile-review">
            <span className="bullet-validation"></span>
            {t("proofOfAddress")}{" "}
            {allProfile?.isProofOfAddress ? (
              <FontAwesomeIcon
                className="mr-2 validation-items-profile"
                icon={faCheck}
              />
            ) : (
              <FontAwesomeIcon
                className="mr-2 not-validation-items-profile"
                icon={faClose}
              />
            )}
          </span>
          <span className="items-profile-review">
            <span className="bullet-validation"></span>
            {t("successfulTransaction")} (3+){" "}
            {allProfile?.isSuccessfulTransaction ? (
              <FontAwesomeIcon
                className="mr-2 validation-items-profile"
                icon={faCheck}
              />
            ) : (
              <FontAwesomeIcon
                className="mr-2 not-validation-items-profile"
                icon={faClose}
              />
            )}
          </span>
          <span className="end-profile-part"></span>
        </Col>
        <Col xs={12} className="payment-form mt-5">
          <h1>{t("reviews")}</h1>
        </Col>
        <Col xs={12}>
          {allReviews?.length !== 0 ? (
            allReviews?.map((item) => {
              return (
                <div className="review-box-wrapper">
                  <h3 className="review-personName">{item.personName}</h3>
                  <h3 className="review-title">{item.title}</h3>
                  <p className="review-text">{item.description}</p>
                  <div className="text-left">
                    <span className="review-details">
                      {item.date} | {item.fromCountry} to {item.toCountry} |
                      Carry {item.packagetypes}
                    </span>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="no-data text-center">{t("noData")}</div>
          )}
        </Col>
      </Row>
    </div>
  );
};
