import React, { useState, useMemo, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import AttachIcon from "assets/images/attach.png";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useTranslation } from "react-i18next";

const baseStyle = {
  alignItems: "center",
  borderWidth: 1,
  borderColor: "#d6d6d6",
  borderStyle: "solid",
  width: "90%",
  height: "174px",
  margin: "auto",
  // padding: "60px 50px",
  borderRadius: "5px",
  backgroundColor: "#fff",
  position: "relative",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  // marginTop: 16,
} as React.CSSProperties;

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  // border: "1px solid #eaeaea",
  // marginBottom: 8,
  // marginRight: 8,
  width: 244,
  height: 177,
  padding: "13px 23px 14px",
  boxSizing: "border-box",
  margin: "auto",
} as React.CSSProperties;

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
  position: "relative",
} as React.CSSProperties;

const img = {
  display: "block",
  width: "100%",
  height: "fit-content",
  alignSelf: "center",
} as React.CSSProperties;

interface IProp {
  title: string;
  photo?: File;
  image?: string;
  setPhoto?: React.Dispatch<React.SetStateAction<File>>;
}

export const Uploader: React.FC<IProp> = ({
  title,
  image,
  photo,
  setPhoto,
}) => {
  const { isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "image/*": [] },
  });
  const { t } = useTranslation();
  const size = UseWindowSize();
  const isMobile = size.width < 768;

  const [defaultImage, setDefaultImage] = useState(null);
  // const [isDisabled, setIsDisabled] = useState(false);
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    // disabled: isDisabled,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".png"],
      // "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setPhoto(acceptedFiles[0]);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  ) as React.CSSProperties;

  useEffect(() => {
    setDefaultImage(photo);
  }, [photo]);

  const removeFile = () => {
    setFiles([]);
    setDefaultImage(null);
    // setIsDisabled(true)
  };

  const thumbs = files.map((file) => (
    <>
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img
            src={file.preview}
            style={img}
            alt="preview"
            onLoad={() => {
              URL.revokeObjectURL(file.preview);
            }}
          />
        </div>
      </div>
    </>
  ));

  return (
    <>
      <>
        <span className="upload-title">{title}</span>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />

          <img src={AttachIcon} className="attach-icon" alt="attach-img" />
          {thumbs && defaultImage ? (
            <aside style={thumbsContainer}>
              {defaultImage && files?.length === 0 && (
                <div style={thumb}>
                  <div style={thumbInner}>
                    <img src={defaultImage} style={img} alt="previewImg" />
                    <a className="remove-img-link" onClick={removeFile}>
                      <FontAwesomeIcon icon={faClose} />
                    </a>
                  </div>
                </div>
              )}
              {thumbs}
            </aside>
          ) : (
            <p className="thumb-title" style={{ padding: "45px 0" , width: "150px"}}>
              {t("dropImageHere")}
            </p>
          )}
        </div>
      </>
    </>
  );
};
