import React, { useState } from "react";
import Select, { Options } from "react-select";
import { ICheckout, ITaxCountry, ITaxRates } from "models/interfaces";
import { useTranslation } from "react-i18next";

interface IProp {
  checkoutData: ICheckout;
  setHasProvince: Function;
  setTaxPercentage: Function;
  setTaxRateId: Function;
}

export const TaxCountries: React.FC<IProp> = ({
  checkoutData,
  setHasProvince,
  setTaxPercentage,
  setTaxRateId,
}) => {
  const [selectedCountry, setSelectedCountry] = useState<ITaxCountry | null>(
    null
  );
  const [selectedTaxRate, setSelectedTaxRate] = useState<ITaxRates | null>(
    null
  );
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const { t } = useTranslation();

  const handleCountryChange = (selectedOption: any) => {
    setSelectedCountry(selectedOption);
    setSelectedTaxRate(null);
  };

  const handleTaxRateChange = (selectedOption: any) => {
    setSelectedTaxRate(selectedOption);
    setTaxPercentage(selectedOption.taxPercentage);
    setTaxRateId(selectedOption.id);
    setHasProvince(true);
  };

  return (
    <div className={isPersian ? "dir-rtl text-right" : "dir-ltr text-left"}>
      <div className="d-inline-block mr-3">
        <span className="filters-select-title mb-2">
          {t("country")}
          <span className="star-require">*</span>
        </span>
        <Select
          options={checkoutData?.taxcountries}
          className="custom-select-payment-country d-inline-block"
          value={selectedCountry}
          onChange={handleCountryChange}
          getOptionLabel={(option: ITaxCountry) => option.name}
          getOptionValue={(option: ITaxCountry) => option.id}
        />
      </div>
      {selectedCountry && (
        <div className="d-inline-block mr-2">
          <span className="filters-select-title mb-2">
            {t("province")}
            <span className="star-require">*</span>
          </span>

          <Select
            options={selectedCountry?.taxrates}
            className="custom-select-payment-country d-inline-block"
            value={selectedTaxRate}
            onChange={handleTaxRateChange}
            getOptionLabel={(option: ITaxRates) => option.name}
            getOptionValue={(option: ITaxRates) => option.id}
          />
        </div>
      )}
    </div>
  );
};
