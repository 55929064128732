export const isValidPackageType = (type) =>
  type !== null &&
  type !== undefined &&
  (Array.isArray(type) ? type?.length !== 0 : true);

export const isValidSize = (size) =>
  size !== null &&
  size !== undefined &&
  (Array.isArray(size) ? size?.length !== 0 : true);

// export const isValidService = (service) =>
//   service !== null &&
//   service !== undefined &&
//   (Array.isArray(service) ? service?.length !== 0 : true);

export const isValidPolicy = (policy, isCancellation) => {
  return isCancellation
    ? policy !== null &&
        policy !== undefined &&
        Object.keys(policy).length !== 0
    : true;
};

export const isValidPayment = (payment) =>
  payment !== null &&
  payment !== undefined &&
  (Array.isArray(payment) ? payment?.length !== 0 : true);

export const isValidDefinedLengthSize = (length) => length !== "";

export const isValidDefinedWidthSize = (width) => width !== "";

export const isValidDefinedHeightSize = (height) => height !== "";

export const isValid = (
  termsChecked,
  governmentChecked,
  isCancellation,
  data
) => {
  return (
    termsChecked &&
    governmentChecked &&
    isValidPackageType(data.type) &&
    isValidSize(data.size) &&
    isValidPolicy(data.policy, isCancellation) &&
    // isValidService(data.service) &&
    isValidPayment(data.payment) &&
    (Array.isArray(data.size)
      ? data?.size?.find((item) => {
          return item.label === "Custom"
            ? isValidDefinedLengthSize(data.length) &&
                isValidDefinedWidthSize(data.width) &&
                isValidDefinedHeightSize(data.height)
            : true;
        })
      : data.size.label === "Custom"
      ? isValidDefinedLengthSize(data.length) &&
        isValidDefinedWidthSize(data.width) &&
        isValidDefinedHeightSize(data.height)
      : true)
  );
};
