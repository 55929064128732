import { createSlice } from "@reduxjs/toolkit";

export const paymentMethodSlice = createSlice({
  name: "paymentMethod",
  initialState: [],
  reducers: {
    paymentMethod: (state, action) => {
      return (state = action.payload.data);
    },
    paymentMethodFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { paymentMethod, paymentMethodFailure } = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;
