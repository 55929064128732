import { createSlice } from "@reduxjs/toolkit";

export const allCountriesSlice = createSlice({
  name: "allCountries",
  initialState: [],
  reducers: {
    allCountries: (state, action) => {
      return (state = action.payload.data);
    },
    allCountriesFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allCountries, allCountriesFailure } = allCountriesSlice.actions;
export default allCountriesSlice.reducer;
