import React from "react";
import { Modal } from "react-bootstrap";
import SizeInfoImg from "assets/images/size-info.png";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
}

export const SizeInformation: React.FC<IProps> = ({ isOpen, setIsOpen }) => {
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Modal
        className="info-size-modal-wrapper"
        show={isOpen}
        // backdrop="static"
        onHide={handleClose}
        size={"sm"}
      >
        <Modal.Body>
          <img src={SizeInfoImg} />
        </Modal.Body>
      </Modal>
    </>
  );
};
