import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GoogleMapAPI, Uploader } from "components";
import { Input, Button } from "layers";
import UserAvatar from "./../../assets/images/user-avatar.png";
import { Col, Modal, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useAppDispatch, useAppSelector } from "redux/store";
import { IRegister, Option } from "models/interfaces";
import {
  createNewUser,
  getUserInfo,
  getVerificationOtpCode,
} from "redux/actions/Authorization";
import { Oval } from "react-loader-spinner";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import Geocode from "react-geocode";
import Select from "react-select";
import {
  isValidFirstName,
  isValidLastName,
  isValidPhoneNumber,
  isValidEmail,
  isValidPassword,
  isValidRePassword,
  isMatchPasswords,
  isValidFormatEmail,
  isPatternPasswordValid,
  isValid,
  isPatternPhoneValid,
} from "helpers/registerValidation";
import { v4 as uuidv4 } from "uuid";
import { getAllCountriesList } from "redux/actions/types";
import OTPInput from "react-otp-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

interface IGoogleResponse {
  firstName: string;
  lastName: string;
  avatar: string;
  email: string;
}

interface IProps {
  deviceModel: string;
  // thirdPartyResponse: IGoogleResponse;
  setIsOpen: Function;
  setIsLogin: Function;
  isOpen: boolean;
}

export const Register: React.FC<IProps> = ({
  deviceModel,
  // thirdPartyResponse,
  isOpen,
  setIsOpen,
  setIsLogin,
}) => {
  const dispatch = useAppDispatch();
  const size = UseWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const uniqueId = uuidv4();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const createUserData: any = useAppSelector((state) => state.createUser);
  const otpCodeData: any = useAppSelector((state) => state.otpCode);
  const [isSuccessContract, setIsSuccessContract] = useState(false);
  const [positionAddress, setPositionAddress] = useState<any>("CA");
  const [randomCode, setRandomCode] = useState("");
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [secondIdentityPhoto, setSecondIdentityPhoto] = useState(null);
  const [avatar, setAvatar] = useState(UserAvatar);
  const [personalPhoto, setPersonalPhoto] = useState(null);
  const [code, setCode] = useState("+1");
  const [otpCodeValue, setOtpCodeValue] = useState("");
  const [changeImageStyle, setChangeImageStyle] = useState(false);
  const [positionLat, setLat] = useState(null);
  const [positionLong, setLng] = useState(null);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasOneDigitChars, setHasOneDigitChars] = useState(false);
  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasUppercaseChar, setHasUppercaseChar] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasNotWhiteSpace, setHasNotWhiteSpace] = useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false);
  const [registerClicked, setRegisterClicked] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [countries, setCountries] = useState<any>();
  const allCountries = useAppSelector<any>((state) => state.allCountries);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowRePassword, setIsShowRePassword] = useState(false);
  const [country, setCountry] = useState<any>({
    value: "Canada",
    label: (
      <>
        <div className="number-code-wrapper">
          <img
            className="number-code-flag"
            src="https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg"
          />
          <span className="number-code-code">{"+1"}</span>
        </div>
      </>
    ),
  });
  const clientId = "517D58DC-95A5-4732-B182-2188A9853CF5";
  const clientSecret =
    "QVWglh6wamKIEyI8kdSlWsD/gNTUpYKdC4GjTw/zFibEcBWH5Djoyw==";
  const deviceId = "";
  const playerId = "";
  const isMobile = size.width < 768;
  const [registerData, setRegisterData] = useState<IRegister>({
    personalPhoto: null,
    aboutMe: "",
    firstName: "",
    lastName: "",
    displayName: "",
    phoneCode: "",
    phoneNumber: "",
    email: "",
    address: "",
    positionLat: 0,
    positionLong: 0,
    password: "",
    rePassword: "",
    passportPhoto: null,
    secondIdentityPhoto: null,
    clientId: "517D58DC-95A5-4732-B182-2188A9853CF5",
    clientSecret: "QVWglh6wamKIEyI8kdSlWsD/gNTUpYKdC4GjTw/zFibEcBWH5Djoyw==",
    deviceModel: "",
    deviceId: "",
    playerId: "",
  });

  useEffect(() => {
    dispatch(getAllCountriesList());
    setShowOtpModal(false);
  }, []);

  useEffect(() => {
    setCountries(
      allCountries?.map((item) => {
        return {
          value: item.name,
          flag: item.flag,
          code: item.number,
          label: (
            <>
              <div className="number-code-wrapper">
                <span className="number-code-name">{item.name}</span>
              </div>
            </>
          ),
        };
      })
    );
  }, [allCountries]);

  const handleChangeCountryCode = (selected) => {
    const countryCode = {
      value: selected.value,
      label: (
        <>
          <div className="number-code-wrapper">
            <img className="number-code-flag" src={selected.flag} />
            <span className="number-code-code">{selected.code}</span>
          </div>
        </>
      ),
    };
    setCode(selected.code);
    setCountry(countryCode);
  };

  // useEffect(() => {
  //   if (thirdPartyResponse.avatar) {
  //     setAvatar(thirdPartyResponse.avatar);
  //     const image = thirdPartyResponse.avatar;
  //     const urlToObject = async () => {
  //       const response = await fetch(image);
  //       const blob = await response.blob();
  //       const file = new File([blob], "image.jpg", { type: blob.type });
  //       setPersonalPhoto(file);
  //       return file;
  //     };
  //     setRegisterData({
  //       ...registerData,
  //       personalPhoto: urlToObject(),
  //       firstName: thirdPartyResponse.firstName,
  //       lastName: thirdPartyResponse.lastName,
  //       email: thirdPartyResponse.email,
  //     });
  //   }
  // }, [thirdPartyResponse]);

  useEffect(() => {
    Geocode.setApiKey("AIzaSyAtDg1xWnADH7dCR_ZaJmhwTqMmQo9-VGM");

    Geocode.fromLatLng(positionLat, positionLong).then(
      (response) => {
        const address = response.results[0].formatted_address;
        let city, state, countryAbbr, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                countryAbbr =
                  response.results[0].address_components[i].short_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        // console.log(address);

        const abbr = countryAbbr ? countryAbbr : "CA";
        setPositionAddress(abbr);
        if (getCountryCallingCode(abbr))
          setCode(`+${getCountryCallingCode(abbr)}`);
      },
      (error) => {
        console.error(error);
      }
    );
  }, [positionLat, positionLong]);

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 50,
      minWidth: 130,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#fff",
      whiteSpace: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "auto",
    }),
  };

  const handleCheckChange = () => {
    setChecked(!checked);
  };

  const updateAvatars = (e) => {
    setAvatar(URL.createObjectURL(e.target.files[0]));
    setChangeImageStyle(true);
    setPersonalPhoto(e.target.files[0]);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  // const handleVerifySuccess = () => {
  //   setOtpCodeValue(otpCodeValue);
  // };

  const onHandleVerify = () => {
    if (otp?.length === 6) callRegisterApi();
  };

  const handleCloseOTP = () => setShowOtpModal(false);

  const handleOtpChange = (e) => {
    setOtp(e);
    if (e.length === 6) {
      setIsOtpLoading(true);
      setOtpCodeValue(e);
    }
  };

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  const handleShowRePassword = () => {
    setIsShowRePassword(!isShowRePassword);
  };

  useEffect(() => {
    if (otpCodeValue !== "") callRegisterApi();
  }, [otpCodeValue]);

  const callRegisterApi = () => {
    const body = new FormData();
    body.append("personalPhoto", personalPhoto);
    body.append("passportPhoto", passportPhoto);
    body.append("secondIdentityPhoto", secondIdentityPhoto);
    body.append("aboutMe", registerData.aboutMe);
    body.append("firstName", registerData.firstName);
    body.append("lastName", registerData.lastName);
    body.append("displayName", registerData.displayName);
    body.append("phoneCode", code);
    body.append("phoneNumberWithoutCode", registerData.phoneNumber);
    body.append("email", registerData.email);
    body.append("address", registerData.address);
    body.append("positionLat", positionLat);
    body.append("positionLong", positionLong);
    body.append("password", registerData.password);
    body.append("rePassword", registerData.rePassword);
    body.append("clientId", clientId);
    body.append("clientSecret", clientSecret);
    body.append("deviceModel", deviceModel);
    body.append("deviceId", deviceId);
    body.append("playerId", playerId);
    body.append("RandomCode", randomCode);
    body.append("OTP", otpCodeValue);
    dispatch(createNewUser(body));
    setIsSuccessContract(true);
  };

  const isUpperCaseLowerCase = () => {
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    return (
      lowerCaseRegex.test(registerData?.password) &&
      upperCaseRegex.test(registerData?.password)
    );
  };

  const isSpecialChars = () => {
    const passwordRegex = /[@$!%?&]/;
    return passwordRegex.test(registerData.password);
  };

  const hasOneDigit = () => {
    const passwordRegex = /\d/;
    return passwordRegex.test(registerData.password);
  };

  const isEightChars = () => {
    const passwordRegex = /.{8,}/;
    return passwordRegex.test(registerData.password);
  };

  useEffect(() => {
    if (isEightChars()) {
      setHasEightChars(true);
    } else {
      setHasEightChars(false);
    }
    if (isUpperCaseLowerCase()) {
      setHasUppercaseChar(true);
    } else {
      setHasUppercaseChar(false);
    }
    if (isSpecialChars()) {
      setHasSpecialChar(true);
    } else {
      setHasSpecialChar(false);
    }
    if (hasOneDigit()) {
      setHasOneDigitChars(true);
    } else {
      setHasOneDigitChars(false);
    }
  }, [registerData]);

  const register = () => {
    setRegisterClicked(true);
    if (!isValid(checked, registerData, code)) {
      toast.error(
        lang === "fa"
          ? "پارامترها را به درستی وارد نمایید"
          : "Enter the parameters correctly!"
      );
      return;
    }
    sendGuidToGetCode();
    document.documentElement.style.overflow = "auto";
  };

  const sendGuidToGetCode = () => {
    const data = {
      email: registerData.email,
      phoneNumber: code + registerData.phoneNumber,
      randomCode: uniqueId,
    };
    setRandomCode(uniqueId);
    dispatch(getVerificationOtpCode(data));
    setIsLoading(true);
  };

  useEffect(() => {
    setShowOtpModal(false);
    if (!otpCodeData?.isSuccess) {
      setIsLoading(false);
    } else if (otpCodeData?.isSuccess) {
      setShowOtpModal(true);
      setIsLoading(false);
    }
  }, [otpCodeData]);

  useEffect(() => {
    if (createUserData?.isSuccess) {
      setIsOtpLoading(false);
      window.dispatchEvent(new Event("storage"));
      window.localStorage.setItem("token", createUserData?.data?.accessToken);
      window.localStorage.setItem(
        "avatar",
        createUserData?.data?.personalPhoto
      );
      window.localStorage.setItem("expire", createUserData?.data?.expiresIn);
      window.localStorage.setItem("userLevel", createUserData?.data?.userLevel);
      window.localStorage.setItem(
        "userLevelHexColor",
        createUserData?.data?.userLevelHexColor
      );
      window.localStorage.setItem(
        "timestampExpire",
        createUserData?.data?.expiresInTimnestamp
      );
      window.localStorage.setItem(
        "refreshToken",
        createUserData?.data?.refreshToken
      );
      window.localStorage.setItem("tokenType", createUserData?.data?.tokenType);
      setIsOpen(false);
      setIsLoading(false);
      setIsOtpLoading(false);
      // setIsLogin(true);
      setShowOtpModal(false);
      dispatch(getUserInfo());
      setOtp("");
      setOtpCodeValue("");
      navigate("/");
      return;
    } else if (!createUserData?.isSuccess && isSuccessContract) {
      setIsOtpLoading(false);
      setIsLoading(false);
      setOtp("");
      setOtpCodeValue("");
      // setShowOtpModal(false);
      setIsSuccessContract(false);
    } else if (!isSuccessContract && !createUserData?.isSuccess) {
      setShowOtpModal(false);
    }
  }, [createUserData]);

  // useEffect(() => {
  //  if(!isOpen)  setShowOtpModal(!showOtpModal);
  // }, [isOpen])

  return (
    <>
      <div className="register-wrapper">
        <h1>{t("register")}</h1>
        <div className="d-flex">
          <div
            className={`${
              changeImageStyle ? "user-profile-box" : "user-default-box"
            }`}
          >
            <Input
              label={
                <img
                  // src={
                  //   thirdPartyResponse?.avatar
                  //     ? thirdPartyResponse?.avatar
                  //     : avatar
                  // }
                  src={avatar}
                  alt="user-avatar"
                  className="avatar-main-img"
                />
              }
              size="sm"
              id="avatar-input"
              name="avatar"
              type="file"
              className="avatar-control-file"
              onChange={updateAvatars}
              // disabled={thirdPartyResponse?.avatar !== ""}
            />
          </div>
          <div className="profile-box-container">
            {/* <span className="short-label">{t("aboutMe")}</span> */}
            <Input
              size="sm"
              id="aboutMe-input"
              placeholder={t("aboutMe")}
              className="custom-textarea-register"
              type="text"
              name="aboutMe"
              value={registerData.aboutMe}
              onChange={handleChange}
              textArea={true}
              rows={2}
            />
          </div>
        </div>
        <Row>
          <Col sm={12} md={6} className="mb-4">
            <Input
              size="sm"
              id="firstName-input"
              placeholder={t("firstName")}
              name="firstName"
              className={`half-custom-input-register ${
                registerClicked &&
                !isValidFirstName(registerData.firstName) &&
                "empty-input-style"
              }`}
              value={registerData.firstName}
              // disabled={thirdPartyResponse?.firstName !== ""}
              onChange={handleChange}
            />
            {registerClicked && !isValidFirstName(registerData.firstName) && (
              <span className="err-validation">Required!</span>
            )}
          </Col>
          <Col sm={12} md={6} className="mb-4 text-left">
            <Input
              size="sm"
              id="lastName-input"
              placeholder={t("lastName")}
              name="lastName"
              className={`half-custom-input-register-last ${
                registerClicked &&
                !isValidLastName(registerData.lastName) &&
                "empty-input-style"
              }`}
              value={registerData.lastName}
              // disabled={thirdPartyResponse?.lastName !== ""}
              onChange={handleChange}
            />
            {registerClicked && !isValidLastName(registerData.lastName) && (
              <span className="err-validation">Required!</span>
            )}
          </Col>
          <Col xs={size.width < 768 ? 12 : 6} className="mb-4">
            <Input
              size="sm"
              id="displayName-input"
              placeholder={`${t("displayName")} (${t("optional")})`}
              name="displayName"
              className={`half-custom-input-register `}
              value={registerData.displayName}
              onChange={handleChange}
            />
          </Col>
          <Col
            xs={size.width < 768 ? 12 : 6}
            className="mb-4 text-left phone-wrapper"
          >
            <Select
              styles={customStyle}
              className="code-number-wrapper d-inline-block"
              value={country}
              onChange={handleChangeCountryCode}
              options={countries}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
            {/* <PhoneInput
              international
              defaultCountry={positionAddress}
              value={code}
              onChange={setCode}
              id="phoneCode"
            ></PhoneInput> */}
            {/* <span className="PhoneInputCountryArrow"></span> */}
            <Input
              size="sm"
              id="phoneNumber-input"
              placeholder={t("phoneNumber")}
              name="phoneNumber"
              className={`${
                size.width < 768
                  ? "responsive-half-custom-phone-register"
                  : "half-custom-phone-register"
              }  d-inline-flex ${
                registerClicked &&
                (!isValidPhoneNumber(registerData.phoneNumber) ||
                  !isPatternPhoneValid(registerData.phoneNumber, code)) &&
                "empty-input-style"
              }`}
              value={registerData.phoneNumber}
              onChange={handleChange}
            />
            {registerClicked &&
              !isValidPhoneNumber(registerData.phoneNumber) && (
                <span className="err-validation">Required!</span>
              )}
            {registerClicked &&
              !isPatternPhoneValid(registerData.phoneNumber, code) && (
                <span className="err-validation">
                  Phone number format is not correct!
                </span>
              )}
            {/* <NumericFormat
              className="half-custom-input-register"
              id="phoneNumber"
              placeholder="Phone Number"
              name="phoneNumber"
              value={registerData.phoneNumber}
              allowLeadingZeros={true}
              // onValueChange={handleChange}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setPhoneNumber(formattedValue);
              }}
            /> */}
          </Col>
          <Col xs={12} className="mb-4">
            <Input
              size="sm"
              id="email-input"
              placeholder={t("email")}
              name="email"
              className={`full-custom-input-register dir-ltr text-left pl-2 ${
                registerClicked &&
                (!isValidEmail(registerData.email) ||
                  !isValidFormatEmail(registerData.email)) &&
                "empty-input-style"
              }`}
              // disabled={thirdPartyResponse?.email !== ""}
              value={registerData.email}
              onChange={handleChange}
            />
            {registerClicked && !isValidEmail(registerData.email) && (
              <span className="err-validation">Required!</span>
            )}
            {registerClicked && !isValidFormatEmail(registerData.email) && (
              <span className="err-validation">
                Email format isn't correct!
              </span>
            )}
          </Col>
          {/* <Col xs={12} className="mb-4">
            <Input
              size="sm"
              id="address-input"
              placeholder="Address (OPTIONAL)"
              name="address"
              className="full-custom-input-register"
              value={registerData.address}
              onChange={handleChange}
            />
          </Col> */}
        </Row>
        {/* <div className="map-wrapper">
          <GoogleMapAPI setLat={setLat} setLng={setLng} />
        </div> */}
        <Row className="mt-1">
          <Col sm={12} md={6} className="mb-2">
            <div className="mb-2">
              {isShowPassword ? (
                <a
                  className="hide-show-pass-wrapper"
                  onClick={handleShowPassword}
                >
                  <FontAwesomeIcon icon={faEye} /> Hide
                </a>
              ) : (
                <a
                  className="hide-show-pass-wrapper"
                  onClick={handleShowPassword}
                >
                  <FontAwesomeIcon icon={faEyeSlash} /> Show
                </a>
              )}
            </div>
            <Input
              size="sm"
              id="password-input"
              placeholder={t("password")}
              className={`half-custom-input-register dir-ltr text-left pr-2 pl-2 ${
                registerClicked &&
                (!isPatternPasswordValid(registerData.password) ||
                  !isValidPassword(registerData.password)) &&
                "empty-input-style"
              }`}
              name="password"
              type={isShowPassword ? "text" : "password"}
              value={registerData.password}
              onChange={handleChange}
            />

            {registerClicked && !isValidPassword(registerData.password) && (
              <span className="err-validation">Required!</span>
            )}
            {registerClicked &&
              !isPatternPasswordValid(registerData.password) && (
                <span className="err-validation">
                  "Password pattern is not correct"
                </span>
              )}
          </Col>
          <Col sm={12} md={6} className="mb-2">
            <div className="mb-2 ml-1">
              {isShowRePassword ? (
                <a
                  className="hide-show-pass-wrapper"
                  onClick={handleShowRePassword}
                >
                  <FontAwesomeIcon icon={faEye} /> Hide
                </a>
              ) : (
                <a
                  className="hide-show-pass-wrapper"
                  onClick={handleShowRePassword}
                >
                  <FontAwesomeIcon icon={faEyeSlash} /> Show
                </a>
              )}
            </div>
            <Input
              size="sm"
              id="rePassword-input"
              placeholder={t("retypePass")}
              name="rePassword"
              type={isShowRePassword ? "text" : "password"}
              className={`half-custom-input-register-last dir-ltr ${
                isPersian ? "text-left" : "text-left"
              } pr-2 pl-2 ${
                registerClicked &&
                (!isValidRePassword(registerData.rePassword) ||
                  !isMatchPasswords(
                    registerData.rePassword,
                    registerData.password
                  )) &&
                "empty-input-style"
              }`}
              value={registerData.rePassword}
              onChange={handleChange}
            />
            {registerClicked && !isValidRePassword(registerData.rePassword) && (
              <span className="err-validation">Required!</span>
            )}
            {registerClicked &&
              !isMatchPasswords(
                registerData.rePassword,
                registerData.password
              ) && (
                <span className="err-validation">
                  The passwords don't match!
                </span>
              )}
          </Col>
          <Col xs={12} className={`mb-4 ${isPersian ? "dir-rtl" : "dir-ltr"}`}>
            <span className="password-condition-title mb-2">
              {t("createPassContains")}:
            </span>
            <span
              className={`${
                hasEightChars
                  ? "password-condition-correct"
                  : "password-condition-wrong"
              }`}
            >
              {hasEightChars && <FontAwesomeIcon icon={faCheck} />}
              {t("eightChars")}
            </span>
            <span
              className={`${
                hasOneDigitChars
                  ? "password-condition-correct"
                  : "password-condition-wrong"
              }`}
            >
              {hasOneDigitChars && <FontAwesomeIcon icon={faCheck} />}
              {t("oneNumber")}
            </span>
            <span
              className={`${
                hasUppercaseChar
                  ? "password-condition-correct"
                  : "password-condition-wrong"
              }`}
            >
              {hasUppercaseChar && <FontAwesomeIcon icon={faCheck} />}
              {t("uppercaseLowercase")}
            </span>
            <span
              className={`${
                hasSpecialChar
                  ? "password-condition-correct"
                  : "password-condition-wrong"
              }`}
            >
              {hasSpecialChar && <FontAwesomeIcon icon={faCheck} />}
              {t("specialChar")}{" "}
              <span
                className={`${
                  hasSpecialChar
                    ? "password-special-char-example-correct"
                    : "password-special-char-example"
                }  `}
              >
                $, !, @, %, &
              </span>
            </span>
          </Col>
        </Row>
        {/* <Row>
          <section
            className="container"
            style={size.width < 768 ? { width: "400px" } : { width: "601px" }}
          >
            <Uploader
              title="Upload Passport front page"
              photo={passportPhoto}
              setPhoto={setPassportPhoto}
            />
          </section>
        </Row>
        <Row>
          <section
            className="container"
            style={size.width < 768 ? { width: "400px" } : { width: "601px" }}
          >
            <Uploader
              title="Upload ID Driving License/PR Card/ Green Card/National Card)"
              photo={secondIdentityPhoto}
              setPhoto={setSecondIdentityPhoto}
            />
          </section>
        </Row> */}
        <div>
          <label className="checkbox-container">
            {t("termConditionLinkText")}{" "}
            <a
              target="_blank"
              href="/termsCondition"
              style={{ textDecorationLine: "underline" }}
            >
              {t("termConditionLink")}{" "}
            </a>
            {t("termConditionLink2")}.
            <input
              type="checkbox"
              checked={checked}
              onChange={handleCheckChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div>
          <Button
            disabled={!checked}
            variant="primary"
            data-test="docs-btn-anchor"
            className="submit-request-btn mt-4"
            onClick={register}
          >
            {t("submitTheRequest")}
            {isLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
          {/* <OtpVerificationModal
            show={showOtpModal}
            onClose={() => setShowOtpModal(false)}
            onVerify={handleVerifySuccess}
            setOtpCodeValue={setOtpCodeValue}
            setIsOtpLoading={setIsOtpLoading}
            isOtpLoading={isOtpLoading}
          /> */}

          <Modal
            show={showOtpModal}
            className="otp-modal-wrapper"
            backdrop="static"
            onHide={handleCloseOTP}
          >
            <Modal.Body>
              <div className="close-modal-btn-wrapper"></div>
              <div className="content-otp">
                <span className="enter-verify-information mb-3">
                  {t("activationCode")}
                </span>
                <OTPInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{ display: "block", margin: "20px 0" }}
                  inputStyle={{
                    width: isMobile ? "2rem" : "3rem",
                    height: isMobile ? "2rem" : "3rem",
                    margin: "10px",
                    border: "1px solid #c5c5c5",
                    borderRadius: "4px",
                    fontSize: "28px",
                    color: "#00043d",
                  }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseOTP}>
                {t("close")}
              </Button>
              <Button
                variant="primary"
                disabled={otp?.length !== 6}
                onClick={onHandleVerify}
              >
                {t("verify")}{" "}
                {isOtpLoading && (
                  <Oval
                    width="20"
                    height="20"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                  />
                )}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};
