import { createSlice } from "@reduxjs/toolkit";

export const getRoomsSlice = createSlice({
  name: "getRooms",
  initialState: [],
  reducers: {
    getRooms: (state, action) => {
      return (state = action.payload);
    },
    getRoomsFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { getRooms, getRoomsFailure } = getRoomsSlice.actions;
export default getRoomsSlice.reducer;
