import { createSlice } from "@reduxjs/toolkit";

export const travelByIdSlice = createSlice({
  name: "travelById",
  initialState: [],
  reducers: {
    travelById: (state, action) => {
      return (state = action.payload);
    },
    travelByIdFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { travelById, travelByIdFailure } = travelByIdSlice.actions;
export default travelByIdSlice.reducer;
