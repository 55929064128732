import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getUserInfo } from "redux/actions/Authorization";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { ToastContainer } from "react-toastify";
import { getAllCurrencies } from "redux/actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { getAllTransactions } from "redux/actions/banks";
import { useTranslation } from "react-i18next";

export const Transaction: React.FC = () => {
  const dispatch = useAppDispatch();
  const size = UseWindowSize();
  const token = window.localStorage.getItem("token");
  const [openTransactionIndex, setOpenTransactionIndex] = useState<
    number | null
  >(null);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getAllCurrencies());
  }, [token]);

  window.addEventListener("storage", () => {
    window.localStorage.setItem("token", window.localStorage.getItem("token"));
  });

  useEffect(() => {
    dispatch(getAllTransactions(currentPage));
  }, [currentPage]);

  const transactionsData: any = useAppSelector(
    (state) => state.allTransactions
  );

  useEffect(() => {
    if (transactionsData?.isSuccess) {
      if (currentPage === 1) {
        setTransactions(transactionsData.data);
      } else {
        setTransactions((prevTransactions) => [
          ...prevTransactions,
          ...transactionsData.data,
        ]);
      }
      if (transactionsData.data.length < 5) {
        setHasMoreData(false);
      }
    }
  }, [transactionsData]);

  const isMobile = size.width < 768;

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>Transaction</h2>
      </div>
      <div className="flex-grow-1 request-info-wrapper mb-3">
        <Row style={{ placeContent: "center" }}>
          <Col lg={10} md={12} sm={12}>
            {transactions?.length !== 0 && (
              <div className="transaction-page-wrapper">
                {transactions?.map((item, index) => {
                  const isTransactionOpen = openTransactionIndex === index;

                  return (
                    <div
                      key={index}
                      className="transaction-page-box"
                      onClick={() =>
                        setOpenTransactionIndex(
                          isTransactionOpen ? null : index
                        )
                      }
                    >
                      <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                          <span className="transaction-name d-block">
                            {item?.trxId}
                          </span>
                          <span className="transaction-date">
                            {item?.insertTime}
                          </span>
                        </Col>
                        <Col
                          xs={5}
                          sm={5}
                          md={2}
                          lg={2}
                          style={{ alignSelf: "center" }}
                        >
                          <span
                            className="status-transaction d-block"
                            style={{
                              background: `${
                                item.transactionType === "Refund"
                                  ? "#ffa200"
                                  : item.transactionType === "Payout"
                                  ? "#b400ff"
                                  : "#00af0d"
                              }`,
                            }}
                          >
                            {item?.transactionType}
                          </span>
                        </Col>
                        <Col
                          xs={7}
                          sm={7}
                          md={4}
                          lg={4}
                          className="transaction-amount-wrapper"
                        >
                          <span className="mr-3">${item?.amount}</span>
                          <FontAwesomeIcon
                            className="collapse-chevron"
                            icon={
                              isTransactionOpen ? faChevronDown : faChevronRight
                            }
                          />
                        </Col>
                      </Row>
                      {isTransactionOpen && (
                        <>
                          <Row className="drawer mt-5">
                            <Col
                              xs={12}
                              sm={12}
                              md={3}
                              lg={3}
                              className="collapse-detail-transaction"
                            >
                              <span>Pay From</span>
                              <span>{item.payFrom}</span>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={3}
                              lg={3}
                              className="collapse-detail-transaction"
                            >
                              <span>Commission Fee</span>
                              <span>
                                {item.commissionFee}
                                {item.currency}
                              </span>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              className="collapse-detail-transaction"
                            >
                              <span>Tax</span>
                              <span>
                                {item.taxAmount}
                                {item.currency}
                              </span>
                            </Col>
                          </Row>
                          <Row className="drawer my-4">
                            <Col
                              xs={12}
                              sm={12}
                              md={3}
                              lg={3}
                              className="collapse-detail-transaction"
                            >
                              <span>Pay to</span>
                              <span>{item.payTo}</span>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={3}
                              lg={3}
                              className="collapse-detail-transaction"
                            >
                              <span>Operation Fee</span>
                              <span>{item.operationFee}</span>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              className="collapse-detail-transaction"
                            >
                              <span>{t("OfferId")} / Request ID</span>
                              <span>{item.typeId}</span>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
            {hasMoreData && (
              <Button
                className="transaction-load-more-btn"
                onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
              >
                Load More
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
