import { createSlice } from "@reduxjs/toolkit";

export const requestsClickedSlice = createSlice({
  name: "requestsClicked",
  initialState: [],
  reducers: {
    requestsClicked: (state, action) => {
      return (state = action.payload);
    },
    requestsClickedFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { requestsClicked, requestsClickedFailure } = requestsClickedSlice.actions;
export default requestsClickedSlice.reducer;
