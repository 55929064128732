import { createSlice } from "@reduxjs/toolkit";

export const verificationCodeSlice = createSlice({
  name: "verificationCode",
  initialState: [],
  reducers: {
    verificationCode: (state, action) => {
      return (state = action.payload);
    },
    verificationCodeFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { verificationCode, verificationCodeFailure } = verificationCodeSlice.actions;
export default verificationCodeSlice.reducer;
