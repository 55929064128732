import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { resources } from "./resources";

i18n.use(initReactI18next).init({
  resources,
  // resources: {
  //   en: { translation: en },
  //   fa: { translation: fa }
  // },
  lng: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa",

  keySeparator: false,

  interpolation: {
    escapeValue: false,
  },
});

document.documentElement.lang = i18n.language;

export default i18n;
