import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Uploader, MultipleUploader } from "components";
import { Col, Row, Dropdown } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { InputOption, ReusePackageHistory } from "components";
import { components } from "react-select";
import { IPackage, IRetrieve } from "models/interfaces";
import { useAppSelector, useAppDispatch } from "redux/store";
import { chooseLandingPackage } from "redux/actions/landing";
import { getDate, convertHumanDateToUnix } from "helpers/convertDate";
import {
  getAllHomeRequestFilter,
  getAllHomeTravelFilter,
} from "redux/actions/flight";
import { Oval } from "react-loader-spinner";
import { AddInputForms } from "components/common/addInputForm";
import AsyncSelect from "react-select/async";
import { addDays } from "date-fns";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {
  faClose,
  faInfo,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { SizeInformation } from "components/modals/sizeInformation";
import { Link, useNavigate } from "react-router-dom";
import {
  getAllSizeRange,
  getAllDeliveryType,
  getAllPackagesType,
} from "redux/actions/types";
import { searchAirportLocation, searchLocation } from "helpers/searchLocation";
import { searchLocationApi } from "helpers/searchLocation";
import {
  isValidPackageType,
  isValidSize,
  isValidDefinedLengthSize,
  isValidDefinedWidthSize,
  isValidDefinedHeightSize,
  isValidOfferPrice,
  isValidFrom,
  isValidTo,
  isEndDateGreaterEqualStartDate,
  isEndDateGreaterStartDate,
  isValid,
} from "helpers/packageValidation";
import { searchAirline } from "helpers/searchAirline";

interface IProps {
  data: any;
  isOpen: boolean;
  setIsOpen: Function;
  onSelectReuse: (key: any) => void;
}

export const SendRequest: React.FC<IProps> = ({
  data,
  setIsOpen,
  isOpen,
  onSelectReuse,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const windowSize = UseWindowSize();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isCompanion, setIsCompanion] = useState(false);
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [reuseData, setReuseData] = useState<any>({});
  const [showReuse, setShowReuse] = useState(false);
  const [requestData, setRequestData] = useState<IPackage>({
    trvId: "",
    packagetypeIds: [],
    packageType: "",
    sizerangeId: "",
    weight: "0",
    weightUnit: "kg",
    value: "",
    sizeWidth: "",
    sizeHeight: "",
    sizeLength: "",
    lengthUnit: "cm",
    deliverytypeIds: [],
    fromCountry: "",
    fromCountryCity: "",
    toCountry: "",
    toCountryCity: "",
    fromDate1: "",
    fromDate2: "",
    offerPrice: "",
    currencyType: "CAD",
    message: "",
    images: [],
    Purchases: [],
    airlineName: "",
    flightNumber: "",
  });
  const [size, setSize] = useState(null);
  const [reusePkgId, setReusePkgId] = useState("");
  const [packagetypeIds, setPackagetypeIds] = useState<any>([]);
  const [deliverytypeIds, setDeliverytypeIds] = useState<any>([]);
  const [trvId, setTrvId] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [sizeOptions, setSizeOptions] = useState([]);
  const updatedChooseRequest: any = useAppSelector(
    (state) => state.chooseRequest
  );
  const [purchases, setPurchases] = useState<any>([
    { Link: "", Quantity: "0" },
  ]);
  const [retrieveData, setRetrieveData] = useState<IRetrieve>({
    type: "package",
    fromCityEntityId: "",
    toCityEntityId: "",
    fromDate1: "",
    fromDate2: "",
  });
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const str = window.location.pathname;
  const sizeRanges = useAppSelector((state) => state.sizeRange);
  const [isValidFromAirport, setIsValidFromAirport] = useState(true);
  const [isValidToAirport, setIsValidToAirport] = useState(true);
  const [termsChecked, setTermsChecked] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [acceptPurchase, setAcceptPurchase] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [governmentChecked, setGovernmentChecked] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [type, setType] = useState(null);
  const [airline, setAirline] = useState<any>();
  const packagesType = useAppSelector((state) => state.packageTypes);
  const [lengthUnit, setLengthUnit] = useState({ value: "cm", label: "CM" });
  const [weightUnit, setWeightUnit] = useState({ value: "kg", label: "KG" });
  const [service, setService] = useState(null);
  const [servicesOptions, setServicesOptions] = useState([]);
  const airlines = useAppSelector((state) => state.allAirlines);
  const services = useAppSelector((state) => state.deliveryType);
  const screenSize = UseWindowSize();
  const [arrivalBetweenDate, setArrivalBetweenDate] = useState(new Date());
  const [arrivalToDate, setArrivalToDate] = useState(new Date());
  const [departureBetweenDate, setDepartureBetweenDate] = useState(new Date());
  const [departureToDate, setDepartureToDate] = useState(new Date());
  const [fromDate1, setFromDate1] = useState(getDate(new Date()));
  const [fromDate2, setFromDate2] = useState(getDate(new Date()));
  const [toDate1, setToDate1] = useState(getDate(new Date()));
  const [toDate2, setToDate2] = useState(getDate(new Date()));
  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();
  const currencies = useAppSelector((state) => state.currencies);
  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [currenciesOption, setCurrenciesOption] = useState([
    {
      value: localStorage.getItem("currency"),
      label: localStorage.getItem("currency"),
    },
  ]);
  const isMobile = windowSize.width < 768;

  useEffect(() => {
    setRetrieveData({
      type: "package",
      fromCityEntityId: from?.value,
      toCityEntityId: to?.value,
      fromDate1: fromDate1,
      fromDate2: fromDate2,
    });
  }, [from, to, fromDate1, fromDate2]);

  useEffect(() => {
    setShowReuse(false);
    onSelectReuse(false);
  }, [reuseData]);

  // useEffect(() => {
  //   document.addEventListener(
  //     "click",
  //     function (e) {
  //       const target = e.target as HTMLTextAreaElement;
  //       const element = target.className;
  //       if (element === "bm-overlay") {
  //         document.documentElement.style.overflow = "auto";
  //         // navigate("/");
  //         e.preventDefault()
  //       }
  //     },
  //     false
  //   );
  // }, []);

  const handleChangeClose = () => {
    if (showReuse === true) {
      setShowReuse(false);
      onSelectReuse(false);
    } else {
      setIsOpen(!isOpen);
      document.documentElement.style.overflow = "auto";
      if (str?.includes("/travelId/")) {
        navigate("/");
        const data = {
          type: "travel",
          packagetypeIds: null,
          weightrangeIds: null,
          deliverytypeIds: null,
          sizerangeIds: null,
          serviceIds: null,
          paymentmethodIds: null,
          cancellationpolicyIds: null,
          // cancellationpolicyIds: "422A36FD-F65F-4A1D-830D-20C1D66C42AB",
          userLevelIds: null,
          page: 1,
        };
        dispatch(getAllHomeTravelFilter(data));
      }
    }
  };

  useEffect(() => {
    const data = reuseData;
    if (Object.keys(reuseData).length !== 0) {
      if (data?.packagetypes) {
        let types = data?.packagetypes?.split(", ");
        if (data?.packagetypes?.includes(",")) {
          const packages = types
            .map((item) => {
              return typeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setType(packages);
          const results = packages?.map((pkg) => {
            return pkg.value;
          });
          setPackagetypeIds(results);
        } else if (
          data?.packagetypes === "همسفر" ||
          data?.packagetypes === "Traveling Companion"
        ) {
          setIsCompanion(true);
          if (isPersian)
            setSize({
              value: "6a50f6c5-0c00-498e-9653-1d8914172181",
              label: "سفارشی",
            });
          else
            setSize({
              value: "6a50f6c5-0c00-498e-9653-1d8914172181",
              label: "Custom",
            });
          setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);

          setRequestData({
            ...requestData,
            sizeWidth: "0",
            sizeHeight: "0",
            sizeLength: "0",
          });
          setType([
            {
              value: "60db617a-dba2-406c-a960-1928a8b45320",
              label: isPersian ? "همسفر" : "Traveling Companion",
            },
          ]);
        } else {
          setIsCompanion(false);
          setType(
            typeOptions.find((option) => option.label === data?.packagetypes)
          );
          setPackagetypeIds(
            typeOptions.find((option) => option.label === data?.packagetypes)
              ?.value
          );
        }
      } else {
        setType([{ value: packagesType[0]?.id, label: packagesType[0]?.name }]);
      }

      if (data?.deliveryTypes) {
        let services = data?.deliveryTypes?.split(", ");
        if (data?.deliveryTypes?.includes(",")) {
          const deliveries = services
            .map((item) => {
              return servicesOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setService(deliveries);
          const results = deliveries?.map((srv) => {
            return srv.value;
          });
          setDeliverytypeIds(results);
        } else {
          setService(
            servicesOptions.find(
              (option) => option.label === data?.deliveryTypes
            )
          );
          setDeliverytypeIds(
            servicesOptions.find(
              (option) => option.label === data?.deliveryTypes
            )?.value
          );
        }
      } else {
        setService({
          value: services[0]?.id,
          label: services[0]?.name,
        });
      }

      if (data?.size) {
        let sizes = data?.size?.split(", ");
        if (data?.size?.includes("سفارشی") || data?.size?.includes("Custom"))
          setIsCustom(true);
        else setIsCustom(false);
        if (data?.size?.includes(",")) {
          const ranges = sizes
            .map((item) => {
              return sizeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setSize(ranges[0]);
        } else
          setSize(sizeOptions.find((option) => option.label === data?.size));
      } else {
        setSize({ value: sizeRanges[0]?.id, label: sizeRanges[0]?.name });
      }
    }
  }, [reuseData, typeOptions, servicesOptions, sizeOptions]);

  useEffect(() => {
    if (Object.keys(reuseData).length !== 0) {
      const data = reuseData;
      setReusePkgId(data?.pkgId);
      searchLocationApi(data?.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data?.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}
                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });
      setAcceptPurchase(data?.acceptPurchase);
      const currency = data?.currencyType ? data?.currencyType : "CAD";
      const unit = data?.weightUnit ? data?.weightUnit : "KG";
      const lUnit = data?.lengthUnit ? data?.lengthUnit : "CM";
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setLengthUnit({ value: lUnit, label: lUnit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      setSize(sizeOptions.find((option) => option.label === data?.sizerange));
      setIsCustom(
        isPersian ? data?.sizerange === "سفارشی" : data?.sizerange === "Custom"
      );
      setArrivalBetweenDate(
        data?.fromDate1 ? convertHumanDateToUnix(data?.fromDate1) : new Date()
      );
      // setArrivalToDate(convertHumanDateToUnix(data?.toDate1));
      setDepartureBetweenDate(
        data?.fromDate2 ? convertHumanDateToUnix(data?.fromDate2) : new Date()
      );
      setDepartureToDate(
        data?.toDate2 ? convertHumanDateToUnix(data?.toDate2) : new Date()
      );
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      setAirline({ value: data?.airlineName, label: data?.airlineName });
      const newPurchases = data?.purchases?.map(({ link, quantity }) => ({
        Link: link,
        Quantity: quantity,
      }));
      setPurchases(
        newPurchases?.length !== 0
          ? newPurchases
          : [{ Link: "", Quantity: "0" }]
      );
      setRequestData({
        ...requestData,
        weight: data?.weight,
        message: data?.description,
        value: data?.itemValue,
        offerPrice: data?.offerPrice,
        sizeWidth: data?.sizeWidth,
        sizeHeight: data?.sizeHeight,
        sizeLength: data?.sizeLength,
        flightNumber: data?.flightNumber,
      });
      setImages(data?.images);
    }
  }, [reuseData]);

  const openReuseSidebar = () => {
    if (from?.value !== undefined && to?.value !== undefined) {
      setShowReuse(true);
      onSelectReuse(true);
    } else if (from?.value === undefined) {
      toast.warn(
        lang === "fa"
          ? "لطفا فرودگاه مبدا را وارد کنید"
          : "Please enter departure airport"
      );
      if (!isValidFrom(from) || from === undefined)
        setIsValidFromAirport(false);
    } else if (to?.value === undefined) {
      toast.warn(
        lang === "fa"
          ? "لطفا فرودگاه مقصد را وارد کنید"
          : "Please enter arrival airport"
      );
      if (!isValidTo(to) || to === undefined) setIsValidToAirport(false);
    }
  };

  useEffect(() => {
    if (isValidFrom(from)) setIsValidFromAirport(true);
    if (isValidTo(to)) setIsValidToAirport(true);
  }, [from, to]);

  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];

  const unitLengthOption = [
    { value: "cm", label: "CM" },
    { value: "inch", label: "INCH" },
  ];

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const handleTermsCheckedChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleGovernmentChange = () => {
    setGovernmentChecked(!governmentChecked);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    // setRequestData({ ...requestData, [e.target.name]: e.target.value });
    const newValue = e.target.value;
    if (newValue.length <= 100) {
      setRequestData({ ...requestData, [e.target.name]: newValue });
    } else {
      setRequestData({
        ...requestData,
        [e.target.name]: newValue.slice(0, 100),
      });
    }
  };

  useEffect(() => {
    const options = airlines?.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    // setAirlineOptions(options);
  }, [airlines]);

  const handleAirlineChange = (selected) => {
    setAirline(selected);
  };

  useEffect(() => {
    if (Object.keys(reuseData).length !== 0) {
      const data = reuseData;
      setReusePkgId(data?.pkgId);
      searchLocationApi(data?.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data?.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}
                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });
      setAcceptPurchase(data?.acceptPurchase);
      const currency = data?.currencyType ? data?.currencyType : "CAD";
      const unit = data?.weightUnit ? data?.weightUnit : "KG";
      const lUnit = data?.lengthUnit ? data?.lengthUnit : "CM";
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setLengthUnit({ value: lUnit, label: lUnit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      setSize(sizeOptions.find((option) => option.label === data?.sizerange));
      setIsCustom(
        isPersian ? data?.sizerange === "سفارشی" : data?.sizerange === "Custom"
      );
      setArrivalBetweenDate(
        data?.fromDate1 ? convertHumanDateToUnix(data?.fromDate1) : new Date()
      );
      // setArrivalToDate(convertHumanDateToUnix(data?.toDate1));
      setDepartureBetweenDate(
        data?.fromDate2 ? convertHumanDateToUnix(data?.fromDate2) : new Date()
      );
      setDepartureToDate(
        data?.toDate2 ? convertHumanDateToUnix(data?.toDate2) : new Date()
      );
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      const newPurchases = data?.purchases?.map(({ link, quantity }) => ({
        Link: link,
        Quantity: quantity,
      }));
      setPurchases(
        newPurchases?.length !== 0
          ? newPurchases
          : [{ Link: "", Quantity: "0" }]
      );
      setRequestData({
        ...requestData,
        weight: data?.weight,
        message: data?.description,
        value: data?.itemValue,
        offerPrice: data?.offerPrice,
        sizeWidth: data?.sizeWidth,
        sizeHeight: data?.sizeHeight,
        sizeLength: data?.sizeLength,
        // link: data?.link,
      });
      setImages(data?.images);
    }
  }, [reuseData]);

  useEffect(() => {
    if (data?.packagetypes) {
      let types = data?.packagetypes?.split(", ");
      if (data?.packagetypes?.includes(",")) {
        const packages = types
          .map((item) => {
            return typeOptions?.filter((x) => {
              return item === x.label;
            });
          })
          .flat(2);
        setType(packages);
        const results = packages?.map((pkg) => {
          return pkg.value;
        });
        setPackagetypeIds(results);
      } else if (
        data?.packagetypes === "همسفر" ||
        data?.packagetypes === "Traveling Companion"
      ) {
        setIsCompanion(true);
        if (isPersian)
          setSize({
            value: "6a50f6c5-0c00-498e-9653-1d8914172181",
            label: "سفارشی",
          });
        else
          setSize({
            value: "6a50f6c5-0c00-498e-9653-1d8914172181",
            label: "Custom",
          });
        setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);

        setRequestData({
          ...requestData,
          sizeWidth: "0",
          sizeHeight: "0",
          sizeLength: "0",
          airlineName: data?.AirlineName,
        });
        setType([
          {
            value: "60db617a-dba2-406c-a960-1928a8b45320",
            label: isPersian ? "همسفر" : "Traveling Companion",
          },
        ]);
      } else {
        setIsCompanion(false);
        setType(
          typeOptions.find((option) => option.label === data?.packagetypes)
        );
        setPackagetypeIds(
          typeOptions.find((option) => option.label === data?.packagetypes)
            ?.value
        );
      }
    } else {
      setType([{ value: packagesType[0]?.id, label: packagesType[0]?.name }]);
    }
    if (data) {
      let sizes = data?.sizeranges?.split(", ");
      if (
        data?.sizeranges?.includes("Custom") ||
        data?.sizeranges?.includes("سفارشی")
      )
        setIsCustom(true);
      else setIsCustom(false);
      if (data?.sizeranges?.includes(",")) {
        const ranges = sizes
          .map((item) => {
            return sizeOptions?.filter((x) => {
              return item === x.label;
            });
          })
          .flat(2);
        setSize(ranges[0]);
      } else
        setSize(
          sizeOptions.find((option) => option.label === data?.sizeranges)
        );
    }
    if (data) {
      let services = data?.deliveryTypes?.split(", ");
      if (data?.deliveryTypes?.includes(",")) {
        const deliveries = services
          .map((item) => {
            return servicesOptions?.filter((x) => {
              return item === x.label;
            });
          })
          .flat(2);
        setService(deliveries);
        const results = deliveries?.map((srv) => {
          return srv.value;
        });
        setDeliverytypeIds(results);
      } else {
        setService(
          servicesOptions.find((option) => option.label === data?.deliveryTypes)
        );
        setDeliverytypeIds(
          servicesOptions.find((option) => option.label === data?.deliveryTypes)
            ?.value
        );
      }
    }
  }, [data, typeOptions, sizeOptions, servicesOptions]);

  useEffect(() => {
    if (data) {
      searchLocationApi(data?.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data?.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data?.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  <div>
                    {/* <span>
                      {`${item?.airportName}${" "}(${item?.airportCode})`},{" "}
                    </span> */}

                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      setAcceptPurchase(data?.acceptPurchase);
      const currency = data?.currencyType ? data?.currencyType : "CAD";
      const unit = data?.weightUnit ? data?.weightUnit : "KG";
      const lUnit = data?.lengthUnit ? data?.lengthUnit : "CM";
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setLengthUnit({ value: lUnit, label: lUnit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      setArrivalBetweenDate(
        data?.fromDate1 ? convertHumanDateToUnix(data?.fromDate1) : null
      );
      // setArrivalToDate(convertHumanDateToUnix(data?.toDate1));
      setDepartureBetweenDate(
        data?.fromDate2 ? convertHumanDateToUnix(data?.fromDate2) : null
      );
      setDepartureToDate(
        data?.toDate2 ? convertHumanDateToUnix(data?.toDate2) : null
      );
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      setTrvId(data?.trvId);
      // setPurchases(data?.purchases);
      setRequestData({
        ...requestData,
        weight: data?.weight,
        // message: data?.description,
        value: data?.itemValue,
        offerPrice: data?.offerPrice,
        sizeWidth: data?.sizeWidth,
        sizeHeight: data?.sizeHeight,
        sizeLength: data?.sizeLength,
        // link: data?.link,
      });
      // setImages(data?.images);
    }
  }, [data, typeOptions, servicesOptions, sizeOptions]);

  useEffect(() => {
    dispatch(getAllSizeRange());
    dispatch(getAllDeliveryType());
    dispatch(getAllPackagesType());
  }, []);

  useEffect(() => {
    const options = packagesType?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setType(null);
    setPackagetypeIds([packagesType[0]?.id]);
    setTypeOptions(options);
  }, [packagesType]);

  const handleTypeChange = (selected) => {
    if (
      selected?.find((item) => {
        return item.value === "60db617a-dba2-406c-a960-1928a8b45320";
      })
    ) {
      setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);
      setIsCompanion(true);
      setIsCustom(false);
      setType([
        {
          value: "60db617a-dba2-406c-a960-1928a8b45320",
          label: isPersian ? "همسفر" : "Traveling Companion",
        },
      ]);
      setRequestData({
        ...requestData,
        sizeWidth: "0",
        sizeHeight: "0",
        sizeLength: "0",
      });
      if (isPersian)
        setSize({
          value: "6a50f6c5-0c00-498e-9653-1d8914172181",
          label: "سفارشی",
        });
      else
        setSize({
          value: "6a50f6c5-0c00-498e-9653-1d8914172181",
          label: "Custom",
        });
    } else {
      setIsCompanion(false);
      setIsCustom(false);
      setType(selected);
      var result1 = selected.map(function (item) {
        return item.value;
      });
      const result2 = result1.filter(function (item) {
        return item !== "60db617a-dba2-406c-a960-1928a8b45320";
      });
      setPackagetypeIds(result2);
      if (isPersian)
        setSize({
          value: "aed0d42c-c59f-4267-9d84-ebb772705553",
          label: "کوچک",
        });
      else
        setSize({
          value: "aed0d42c-c59f-4267-9d84-ebb772705553",
          label: "Small",
        });
    }
  };

  useEffect(() => {
    const options = sizeRanges?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSize(null);
    setSizeOptions(options);
  }, [sizeRanges]);

  useEffect(() => {
    const options = services?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setService(null);
    setDeliverytypeIds([services[0]?.id]);
    setServicesOptions(options);
  }, [services]);

  const handleServicesChange = (selected) => {
    setService(selected);
    setDeliverytypeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
  };

  const handleFromChange = (selected) => {
    setFrom(selected);
  };

  const handleToChange = (selected) => {
    setTo(selected);
  };

  const onArrivalBetweenDateChange = (date) => {
    setArrivalBetweenDate(date);
    setFromDate1(getDate(date));
  };

  // const onArrivalToDateChange = (date) => {
  //   setArrivalToDate(date);
  //   setToDate1(getDate(date));
  // };

  const onDepartureFromDateChange = (date) => {
    setDepartureBetweenDate(date);
    setDepartureToDate(date);
    setFromDate2(getDate(date));
  };

  const onDepartureToDateChange = (date) => {
    setDepartureToDate(date);
    setToDate2(getDate(date));
  };

  const handleUnitChange = (selected) => {
    setWeightUnit(selected);
  };

  const handleLengthUnitChange = (selected) => {
    setLengthUnit(selected);
  };

  const openInfoModal = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  const handleCurrencyChange = (selected) => {
    setCurrency(selected);
  };

  const handleSizeChange = (selected) => {
    setSize(selected);
    if (selected.value === "6a50f6c5-0c00-498e-9653-1d8914172181")
      setIsCustom(true);
    else setIsCustom(false);
  };

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    );
  };

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#707070",
      flexWrap: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
  };

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 50,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#fff",
      flexWrap: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
  };

  const editSendRequest = () => {
    setSubmitClicked(true);
    const data = {
      offerPrice: requestData.offerPrice,
      type,
      size,
      length: requestData.sizeLength,
      width: requestData.sizeWidth,
      height: requestData.sizeHeight,
      from,
      to,
      arrivalBetweenDate,
      departureBetweenDate,
      departureToDate,
      isCompanion,
    };
    if (!isValid(termsChecked, governmentChecked, data)) {
      toast.error(
        lang === "fa"
          ? "پارامترها را به درستی وارد نمایید"
          : "Enter the parameters correctly!"
      );
      return;
    }
    editSendRequestApiCall();
    document.documentElement.style.overflow = "auto";
  };

  const editSendRequestApiCall = () => {
    document.documentElement.style.overflow = "hidden";
    setIsLoading(true);
    const body = new FormData();
    // body.append("pkgId", reusePkgId);
    body.append("trvId", trvId);
    body.append("packagetypeIds", packagetypeIds);
    body.append("sizerangeId", size.value);
    body.append("weight", requestData.weight);
    body.append("weightUnit", weightUnit.value);
    body.append("value", requestData.value);
    body.append("sizeWidth", requestData.sizeWidth);
    body.append("sizeHeight", requestData.sizeHeight);
    body.append("sizeLength", requestData.sizeLength);
    body.append("lengthUnit", lengthUnit.value);
    body.append("deliverytypeIds", deliverytypeIds ? deliverytypeIds : "");
    body.append("fromDate2", fromDate2);
    body.append("toDate2", toDate2);
    body.append("offerPrice", requestData.offerPrice);
    body.append("currencyType", currency.value);
    isCompanion &&
      body.append("airlineName", airline?.value ? airline?.value : "");
    isCompanion && body.append("flightNumber", requestData?.flightNumber);
    body.append("message", requestData.message);
    for (const image of images) {
      body.append("images", image);
    }
    const itemsToBuy = purchases.filter((purchase) => {
      return !(purchase.Link === "" && purchase.Quantity === "0");
    });
    let i;
    for (i = 0; i < itemsToBuy?.length; i++) {
      body.append(`Purchases[${i}][Quantity]`, itemsToBuy[i].Quantity);
      body.append(`Purchases[${i}][Link]`, itemsToBuy[i].Link);
    }
    dispatch(chooseLandingPackage(body));
    setIsSuccess(true);
  };

  useEffect(() => {
    if (!updatedChooseRequest?.isSuccess) {
      setIsLoading(false);
      document.documentElement.style.overflow = "hidden";
    } else if (updatedChooseRequest?.isSuccess && isSuccess) {
      document.documentElement.style.overflow = "auto";
      setIsLoading(false);
      setIsOpen(false);
      const data = {
        type: "package",
        packagetypeIds: null,
        weightrangeIds: null,
        deliverytypeIds: null,
        sizerangeIds: null,
        serviceIds: null,
        paymentmethodIds: null,
        page: 1,
      };
      dispatch(getAllHomeRequestFilter(data));
      setIsSuccess(false);
      navigate("/dashboard/latestSentRequest", {
        state: t("latestSentRequest"),
      });
    }
  }, [updatedChooseRequest]);

  const preventTypeTextAndDot = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventTypeText = (e) => {
    const validInputRegex = /^[0-9.]$/; // Allow digits and dot
    const currentValue = e.target.value + e.key;
    if (validInputRegex.test(e.key) || e.key === "Backspace") {
      const regex = /^(?!1000(\.0)?$)\d{1,3}(\.\d{0,1})?$/;
      if (regex.test(currentValue) || currentValue === "") {
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  return (
    <div
      className={`${
        showReuse ? "set-alert-slider-container" : "request-slider-container"
      }`}
    >
      <Row className="request-wrapper pb-3">
        <Link
          className="ml-auto"
          to={str?.includes("/travelId/") && !showReuse && `/`}
        >
          <Button
            variant="white"
            className="close-sidebar-btn"
            onClick={handleChangeClose}
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
        </Link>

        <Col
          xs={12}
          className="request-form"
          style={{ paddingRight: isMobile ? "15px" : "1.79rem" }}
        >
          <div className={`${isMobile ? "" : "d-flex"}`}>
            <h1 className="d-inline-block">
              {showReuse ? t("packageHistory") : t("sendRequest")}
            </h1>
            {!showReuse && (
              <Button
                variant="primary"
                className="d-inline-block reuse-previous-travel-btn"
                onClick={openReuseSidebar}
              >
                <FontAwesomeIcon icon={faClockRotateLeft} />
                {t("retrievePreviousPackage")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
      {showReuse ? (
        <ReusePackageHistory
          retrieveData={retrieveData}
          setReuseData={setReuseData}
        />
      ) : (
        <Row className={`request-wrapper pt-0 ${isPersian ? "dir-rtl" : ""}`}>
          <Col xs={12} className="request-form">
            <Row className="align-items-center">
              <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("from")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={10}>
                <AsyncSelect
                  className={`custom-package-place-from ${
                    ((submitClicked && !isValidFrom(from)) ||
                      !isValidFromAirport) &&
                    "custom-empty-input-style"
                  }`}
                  value={from}
                  onChange={handleFromChange}
                  cacheOptions
                  defaultOptions
                  placeholder={
                    <span className="select-placeholder-text">
                      {t("countryCityAirport")}
                    </span>
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  styles={customStyle}
                  loadOptions={searchAirportLocation}
                  loadingMessage={() => "Loading ..."}
                  isDisabled
                />
              </Col>
            </Row>
            <Row className="align-items-center mt-3">
              <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("to")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={10}>
                <AsyncSelect
                  className={`custom-input-at-destination ${
                    ((submitClicked && !isValidTo(to)) || !isValidToAirport) &&
                    "custom-empty-input-style"
                  }`}
                  value={to}
                  onChange={handleToChange}
                  cacheOptions
                  defaultOptions
                  placeholder={
                    <span className="select-placeholder-text">
                      {t("countryCityAirport")}
                    </span>
                  }
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                  styles={customStyle}
                  loadOptions={searchAirportLocation}
                  loadingMessage={() => "Loading ..."}
                  isDisabled
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="request-form">
            <span
              className={`send-pack-title mt-4 d-block w-100 ${
                isPersian ? "text-right" : ""
              }`}
            >
              {t("shippingDateRange")}:
            </span>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={3}
                    className={`${isPersian ? "text-right pr-3" : ""}`}
                  >
                    <span className="send-pack-title">
                      {t("fromDate")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col xs={9} className={isPersian ? "pr-0" : "pl-0"}>
                    <DatePicker
                      className="custom-input-between"
                      selected={arrivalBetweenDate}
                      onChange={(date) => onArrivalBetweenDateChange(date)}
                      dateFormat="EEEE, MM/dd/yyyy"
                      showTimeInput
                      disabled
                      minDate={new Date()}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={3}
                    className={`${!isMobile && "text-center"} ${
                      isPersian ? "text-right pr-3" : "pr-0"
                    }`}
                  >
                    <span className="send-pack-title">
                      {t("toDate")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col
                    xs={9}
                    className={`${isPersian ? " text-right pr-1" : "pl-0"}`}
                  >
                    <DatePicker
                      className={`custom-input-to-request d-inline-block ${
                        submitClicked &&
                        !isEndDateGreaterEqualStartDate(
                          arrivalBetweenDate,
                          departureBetweenDate
                        ) &&
                        "empty-input-style"
                      }`}
                      selected={departureBetweenDate}
                      onChange={(date) => onDepartureFromDateChange(date)}
                      dateFormat="EEEE, MM/dd/yyyy"
                      showTimeInput
                      minDate={addDays(arrivalBetweenDate, 1)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="request-form">
            <div className="send-input-wrapper">
              <Row className="align-items-center mt-3">
                <Col
                  xs={isPersian ? 2 : 3}
                  className={`${isPersian ? "text-right pr-3" : ""}`}
                >
                  <span className="send-pack-title">
                    {t("PackagesType")}
                    <span className="star-require">*</span>
                  </span>
                </Col>
                <Col xs={isPersian ? 10 : 9}>
                  <Select
                    className={`custom-select-send ${
                      submitClicked &&
                      !isValidPackageType(type) &&
                      "custom-empty-input-style"
                    }`}
                    value={type}
                    onChange={handleTypeChange}
                    options={typeOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                      Option: InputOption,
                    }}
                    styles={customStyle}
                    isMulti
                  />
                </Col>
              </Row>
              {isCompanion && (
                <span className="warning-payable-title d-block mt-2 mb-3 w-100">
                  {t("companionWarning")}
                </span>
              )}
              {!isCompanion && (
                <Row className="align-items-center mt-3">
                  <Col
                    xs={2}
                    className={`${isPersian ? "text-right pr-3" : ""}`}
                  >
                    <span className="send-pack-title">
                      {t("weight")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col xs={10}>
                    <Input
                      size="sm"
                      id="weight"
                      name="weight"
                      placeholder={t("weightKgLbs")}
                      className="custom-input-weight"
                      value={requestData.weight}
                      onChange={handleChange}
                      disabled={isCompanion}
                      onKeyPress={preventTypeText}
                    />
                    <Select
                      className="custom-select-unit-size d-inline-block"
                      value={weightUnit}
                      onChange={handleUnitChange}
                      options={unitWeightOption}
                      styles={unitCustomStyle}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                      }}
                      isDisabled={isCompanion}
                    />
                  </Col>
                </Row>
              )}
              {!isCompanion && (
                <Row className="align-items-center mt-3">
                  <Col
                    xs={2}
                    className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                  >
                    <span className="send-pack-title">
                      {t("andSize")}
                      <span className="star-require">*</span>
                    </span>
                  </Col>
                  <Col xs={9}>
                    <Select
                      className={`custom-select-size-package ${
                        submitClicked &&
                        !isValidSize(size, isCompanion) &&
                        "custom-empty-input-style"
                      }`}
                      value={size}
                      onChange={handleSizeChange}
                      options={sizeOptions}
                      components={{
                        IndicatorSeparator: () => null,
                        Option: InputOption,
                      }}
                      styles={customStyle}
                      isDisabled={isCompanion}
                    />
                  </Col>
                  <Col xs={1} className={`${isPersian ? "pr-0" : ""}`}>
                    <Dropdown
                      className="size-info-dropdown d-inline"
                      onToggle={handleSelect}
                    >
                      <Dropdown.Toggle
                        variant="transparent"
                        id="dropdown-basic"
                        className="px-0 mx-0"
                      >
                        <div className="triangle">
                          <FontAwesomeIcon
                            icon={faInfo}
                            className="info-defined-wrapper"
                          />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item>
                          <strong>{t("small")}</strong>, {t("smallExample")}
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <strong>{t("medium")}</strong>, {t("mediumExample")}
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <strong>{t("large")}</strong>, {t("largeExample")}
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <strong>{t("xlarge")}</strong>, {t("xLargeExample")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              )}
            </div>
          </Col>
          {isCustom && (
            <Col xs={12} className="request-form">
              <Row className="align-items-center mt-3">
                <Col
                  xs={2}
                  className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
                >
                  <span className="send-pack-title">
                    {t("definedAs")}
                    {size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" && (
                      <span className="star-require">*</span>
                    )}
                  </span>
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeLength"
                    name="sizeLength"
                    placeholder="Length"
                    className={`custom-input-size-length ${
                      submitClicked &&
                      size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                      !isValidDefinedLengthSize(requestData.sizeLength) &&
                      "empty-input-style"
                    }`}
                    value={requestData.sizeLength}
                    onChange={handleChange}
                    disabled={!isCustom}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeWidth"
                    name="sizeWidth"
                    placeholder="Width"
                    className={`custom-input-size-width ${
                      submitClicked &&
                      size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                      !isValidDefinedWidthSize(requestData.sizeWidth) &&
                      "empty-input-style"
                    }`}
                    value={requestData.sizeWidth}
                    onChange={handleChange}
                    disabled={!isCustom}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Input
                    size="sm"
                    id="sizeHeight"
                    name="sizeHeight"
                    placeholder="Height"
                    className={`custom-input-size-height ${
                      submitClicked &&
                      size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                      !isValidDefinedHeightSize(requestData.sizeHeight) &&
                      "empty-input-style"
                    }`}
                    value={requestData.sizeHeight}
                    onChange={handleChange}
                    disabled={!isCustom}
                    onKeyPress={preventTypeText}
                  />
                </Col>
                <Col xs={2} className="pl-0">
                  <Select
                    className="custom-select-unit-length d-inline-block"
                    value={lengthUnit}
                    onChange={handleLengthUnitChange}
                    options={unitLengthOption}
                    styles={unitCustomStyle}
                    isDisabled={!isCustom}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </Col>
                <Col
                  xs={2}
                  className={`${isPersian ? "text-right" : "text-left"}`}
                >
                  <div className="triangle">
                    <FontAwesomeIcon
                      icon={faInfo}
                      className="info-defined-wrapper"
                      onClick={openInfoModal}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          )}

          {isInfoOpen && (
            <SizeInformation isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
          )}

          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col
                xs={isPersian ? 3 : 4}
                className={`${isPersian ? "text-right pr-3" : ""}`}
              >
                <span className="send-pack-title">
                  {t("forPrice")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={isPersian ? 9 : 8}>
                <Input
                  size="sm"
                  id="offerPrice"
                  name="offerPrice"
                  placeholder={t("amount")}
                  className={`custom-input-and-offer d-inline-block ${
                    submitClicked &&
                    !isValidOfferPrice(requestData.offerPrice) &&
                    "empty-input-style"
                  }`}
                  value={requestData.offerPrice}
                  onChange={handleChange}
                  onKeyPress={preventTypeTextAndDot}
                />
                <Select
                  className="custom-select-unit-price d-inline-block"
                  value={currency}
                  onChange={handleCurrencyChange}
                  options={currenciesOption}
                  styles={unitCustomStyle}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                />
              </Col>
            </Row>
            <span className="info-payable-title d-block mt-3 mb-3 w-100">
              {t("onlinePaymentForCanada")}
            </span>
          </Col>
          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col xs={4} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("deliveryDeadlineIs")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={8}>
                <DatePicker
                  className={`custom-input-between-deadline`}
                  selected={departureToDate}
                  onChange={(date) => onDepartureToDateChange(date)}
                  dateFormat="EEEE, MM/dd/yyyy"
                  showTimeInput
                  // disabled
                  // minDate={arrivalBetweenDate}
                  minDate={addDays(convertHumanDateToUnix(data?.fromDate2), 0)}
                />
              </Col>
            </Row>
          </Col>

          {!isCompanion && (
            <Col xs={12} className="request-form">
              <Row className="align-items-center mt-3">
                <Col xs={3} className={`${isPersian ? "text-right pr-3" : ""}`}>
                  <span className="send-pack-title">
                    {t("requestedServices")}
                  </span>
                </Col>
                <Col xs={9}>
                  <Select
                    className="custom-select-package-service"
                    value={service}
                    onChange={handleServicesChange}
                    options={servicesOptions}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: InputOption,
                    }}
                    styles={customStyle}
                    isMulti
                  />
                </Col>
              </Row>
            </Col>
          )}
          {isCompanion && (
            <Col xs={12} className="request-form">
              <Row>
                <Col lg={isPersian ? 7 : 6} md={isPersian ? 7 : 6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={4}
                      lg={5}
                      md={5}
                      sm={4}
                      className={isPersian ? "text-right pr-3" : ""}
                    >
                      <span className="send-pack-title">
                        {t("flightNameIs")}
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      lg={7}
                      md={7}
                      sm={8}
                      className={`${isPersian ? "pr-0" : "pl-0"} `}
                    >
                      <AsyncSelect
                        className="custom-input-airline"
                        value={airline}
                        onChange={handleAirlineChange}
                        cacheOptions
                        defaultOptions
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => null,
                          Option: InputOption,
                        }}
                        styles={customStyle}
                        placeholder={t("flightNameIs")}
                        loadOptions={searchAirline}
                        loadingMessage={() => "Loading ..."}
                        // noOptionsMessage={() => ""}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={4}
                      lg={isPersian ? 4 : 5}
                      md={isPersian ? 4 : 5}
                      sm={4}
                      className={` ${isMobile ? "" : "text-right"}`}
                    >
                      <span className="send-pack-title">
                        {t("andFlightNumber")}
                      </span>
                    </Col>
                    <Col
                      xs={8}
                      lg={isPersian ? 8 : 7}
                      md={isPersian ? 8 : 7}
                      sm={8}
                      className={`${isPersian ? "pr-0" : "pl-0"} `}
                    >
                      <Input
                        id="flightNumber"
                        name="flightNumber"
                        placeholder={t("flightNumber")}
                        className="custom-input-offer-flight-number"
                        value={requestData.flightNumber}
                        onChange={handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}

          <Col xs={12} sm={12} md={6} lg={6} className="request-form mt-4">
            <div
              style={
                isMobile
                  ? isPersian
                    ? { width: "345px", paddingLeft: "20px" }
                    : { width: "345px", paddingRight: "20px" }
                  : isPersian
                  ? { width: "100%", textAlign: "right" }
                  : { width: "580px" }
              }
            >
              <MultipleUploader
                title={t("uploadPkgImgOptional")}
                setPhotos={setImages}
                image={images}
                disabled={false}
                width="w-273"
                description={t("dropImageHere")}
                hasRemove={true}
              />
              {/* <Uploader title="Upload package photo" /> */}
            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="request-form mt-4">
            <div
              className={`send-input-wrapper ${isPersian ? "text-right" : ""}`}
              style={{ marginTop: "18px" }}
            >
              <span className="send-pack-title">{t("noteDetail")}</span>
              <div>
                <Input
                  size="sm"
                  id="message"
                  name="message"
                  placeholder={t("acceptAllVisibleDocs")}
                  className="custom-input-message-package"
                  value={requestData.message}
                  onChange={handleChange}
                  textArea={true}
                  rows={4}
                  maxLength="100"
                />
              </div>
            </div>
          </Col>
          {acceptPurchase && (
            <AddInputForms
              setPurchases={setPurchases}
              purchases={purchases}
              disabled={false}
            />
          )}
          <Col xs={12} className="request-form">
            <div style={{ marginTop: "30px" }}>
              <label className="checkbox-container">
                {t("termConditionLinkText")}{" "}
                <a
                  target="_blank"
                  href="/termsCondition"
                  style={{ textDecorationLine: "underline" }}
                >
                  {t("termConditionLink")}{" "}
                </a>
                {t("termConditionLink2")}.
                <input
                  type="checkbox"
                  checked={termsChecked}
                  onChange={handleTermsCheckedChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </Col>
          <Col xs={12} className="request-form">
            <div>
              <label className="checkbox-container gov-checkbox-container">
                {t("acceptAllGovernmentTaxes")}
                <span className="star-require">*</span>
                <input
                  type="checkbox"
                  checked={governmentChecked}
                  onChange={handleGovernmentChange}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </Col>
          {/* <Col xs={12} className="request-form"> */}
          <div
            className="d-flex w-100 px-3 dir-ltr"
            style={{ marginTop: "24px" }}
          >
            <Button
              onClick={handleChangeClose}
              className="close-request-btn ml-auto mt-4"
            >
              {t("close")}
            </Button>
            <Button
              variant="primary"
              data-test="docs-btn-anchor"
              className="submit-request-btn mt-4 mx-3"
              onClick={editSendRequest}
              disabled={!termsChecked || !governmentChecked}
            >
              {t("submitTheRequest")}
              {isLoading && (
                <Oval
                  width="20"
                  height="20"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                />
              )}
            </Button>
          </div>
          {/* </Col> */}
        </Row>
      )}
    </div>
  );
};
