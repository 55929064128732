import FileService from "services/fileService";
import {
  deletePackageImg,
  deletePackageImgFailure,
} from "redux/slices/files/deletePackageImg";
import {
  deleteTravelImg,
  deleteTravelImgFailure,
} from "redux/slices/files/deleteTravelImg";
import { toast } from "react-toastify";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

export const deletePackageImage = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await FileService.deletePackageImg(id);
    dispatch(deletePackageImg(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(deletePackageImgFailure(err?.response));
  }
};

export const deleteTravelImage = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await FileService.deleteTravelImg(id);
    dispatch(deleteTravelImg(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(deleteTravelImgFailure(err?.response));
  }
};
