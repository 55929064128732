import { createSlice } from "@reduxjs/toolkit";

export const changeButtonStatusSlice = createSlice({
  name: "changeButtonStatus",
  initialState: [],
  reducers: {
    changeButtonStatus: (state, action) => {
      return (state = action.payload);
    },
    changeButtonStatusFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { changeButtonStatus, changeButtonStatusFailure } = changeButtonStatusSlice.actions;
export default changeButtonStatusSlice.reducer;