import { createSlice } from "@reduxjs/toolkit";

export const isValidTokenSlice = createSlice({
  name: "isValidToken",
  initialState: [],
  reducers: {
    isValidToken: (state, action) => {
      return (state = action.payload);
    },
    isValidTokenFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { isValidToken, isValidTokenFailure } = isValidTokenSlice.actions;
export default isValidTokenSlice.reducer;