import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { useTranslation } from "react-i18next";
import { getAllCurrencies } from "redux/actions/types";
import { useAppDispatch } from "redux/store";

export const ProhibitedItems: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>{t("prohibitTitle")}</h2>
      </div>
      <div className="flex-grow-1 other-pages-wrapper my-5">
        <p></p>
      </div>
      <Footer />
    </div>
  );
};
