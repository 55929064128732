import React, { useState } from "react";
// import { InputGroup, Form } from "react-bootstrap";
import {
  InputGroup as BaseInput,
  InputGroupProps,
  FormControl,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

export interface InputProps
  extends InputGroupProps,
    React.HTMLAttributes<HTMLElement> {
  type?: string;
  step?: string;
  value?: any;
  textArea?: boolean;
  rows?: number;
  label?: any;
  name?: string;
  min?: string;
  max?: string;
  disabled?: boolean;
  maxLength?: string;
  ariaLabel?: string;
  pattern?: string;
  ref?: React.RefObject<HTMLInputElement>;
}

export type IInput = InputProps;

export const Input: React.FC<IInput> = ({
  id,
  ref,
  step,
  placeholder,
  ariaLabel,
  size,
  name,
  className,
  textArea = false,
  type,
  maxLength,
  style,
  value,
  rows,
  label,
  disabled,
  min,
  max,
  pattern,
  onChange,
  ...rest
}) => {
  const [_id] = useState(id || `basic-input-${Math.random()}`);

  return (
    <>
      <BaseInput {...rest} size={size}>
        {label && <Form.Label htmlFor={_id}>{label}</Form.Label>}
        <FormControl
          as={`${textArea ? "textarea" : "input"}`}
          rows={`${textArea ? rows : 0}`}
          placeholder={placeholder}
          aria-label={placeholder}
          aria-describedby={_id}
          className={className}
          step={step}
          type={type}
          id={_id}
          value={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
          maxLength={maxLength}
          style={style}
          max={max}
          min={min}
          pattern={pattern}
          ref={ref}
        />
      </BaseInput>
    </>
  );
};

export const LabeledInput: React.FC<IInput> = ({
  id,
  placeholder,
  ariaLabel,
  size,
  name,
  className,
  textArea = false,
  type,
  ref,
  style,
  value,
  rows,
  label,
  disabled,
  onChange,
  ...rest
}) => {
  const [_id] = useState(id || `basic-input-${Math.random()}`);
  const { t } = useTranslation();

  return (
    <>
      <BaseInput {...rest} size={size}>
        {label && <Form.Label htmlFor={_id}>{label}</Form.Label>}
        <FormControl
          as={`${textArea ? "textarea" : "input"}`}
          rows={`${textArea ? rows : 0}`}
          placeholder={value === "" && placeholder}
          aria-describedby={_id}
          className={className}
          type={type}
          id={_id}
          value={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
          style={style}
          ref={ref}
        />
        {name === "password" ? (
          <span className="floating-label">{t("password")}</span>
        ) : name === "rePassword" ? (
          <span style={{ left: "25px" }} className="floating-label">
            {t("retypePass")}
          </span>
        ) : (
          <span className="floating-label">{value !== "" && placeholder}</span>
        )}
      </BaseInput>
    </>
  );
};
