import React, { useState, useEffect } from "react";
import { Row, Col, Card, Dropdown, OverlayTrigger } from "react-bootstrap";
import { Button } from "layers";
import { RightSidebar } from "layers";
import { PackageCover } from "./PackageCover";
import PlaneIcon from "../../assets/images/plane.png";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faShareAlt,
  faCertificate,
  faCheck,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import * as htmlToImage from "html-to-image";
import {
  IChatData,
  IChatEngineData,
  ITraveler,
  Message,
} from "models/interfaces";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/store";
import { Helmet } from "react-helmet";
import { Chat } from "components/modals/Chat";
import { ShareThisCover } from "./ShareThisCover";
import { getAllProfile, getTravelById } from "redux/actions/landing";
import { checkTokenValidation, getUserInfo } from "redux/actions/Authorization";
import { getChatByTypeId, getUserChat } from "redux/actions/chat";
import { useTranslation } from "react-i18next";
import { LoginConfirm } from "components/modals/Login";
import Tooltip from "react-bootstrap/Tooltip";
import { getAllHomeTravelFilter } from "redux/actions/flight";
import { FirstMakeYourCard } from "components/modals/FirstMakeYourCard";
import { SingleChatList } from "components/singleUserChat";
import { toast } from "react-toastify";

interface IProps {
  data: ITraveler;
}

export const Cards: React.FC<IProps> = ({ data }) => {
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const dispatch = useAppDispatch();
  const [chatEngineData, setChatEngineData] = useState<IChatEngineData>();
  const [packageData, setPackageData] = useState<IChatData>();
  const [showSidebar, setShowSidebar] = useState(false);
  const [isCalledApi, setIsCalledApi] = useState(false);
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const [roomId, setRoomId] = useState<string>("");
  const [fade, setFade] = useState(false);
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const str = window.location.pathname;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenChatModal, setIsOpenChatModal] = useState(false);
  const [isShowShareThis, setIsShowShareThis] = useState(false);
  const [isClickedChat, setIsClickedChat] = useState(false);
  const isValidToken = useAppSelector<any>((state) => state.validationToken);
  const chatData = useAppSelector<any>((state) => state.userChat);
  const { t } = useTranslation();
  const [showLoginSidebar, setShowLoginSidebar] = useState(false);
  const [showSignInSidebar, setShowSignInSidebar] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [hasAvailablePackage, setHasAvailablePackage] = useState(false);
  const [sendRequestData, setSendRequestData] = useState();
  const token = window.localStorage.getItem("token");
  const [isSuccess, setIsSuccess] = useState(false);
  const [httpsUrl, setHttpsUrl] = useState("");
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(
    token === "undefined" || token === "null" || token === null ? false : true
  );
  const homeTravelFilter = useAppSelector<any>(
    (state) => state.homeTravelFilter
  );
  const userTravel: any = useAppSelector((state) => state?.travelById);
  const checkPackageData: any = useAppSelector((state) => state?.checkPackage);
  const [latestMessages, setLatestMessages] = useState<Message[]>([]);
  const chatByTypeId: any = useAppSelector((state) => state.chatByTypeId);

  useEffect(() => {
    if (isClickedChat) {
      if (chatByTypeId?.isSuccess) {
        setLatestMessages(chatByTypeId?.data?.messages);
        setRoomId(chatByTypeId?.data?.roomdId);
      } else if (!chatByTypeId?.isSuccess) {
        toast.error(chatByTypeId?.message);
        setIsOpenChatModal(false);
      }
    }
  }, [chatByTypeId]);

  useEffect(() => {
    if (chatByTypeId?.isSuccess && isClickedChat) {
      setIsOpenChatModal(true);
    }
  }, [chatByTypeId, isClickedChat]);

  window.addEventListener("storage", () => {
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogin(false);
    else setIsLogin(true);
  });
  const homeRequestData = useAppSelector<any>(
    (state) => state.homeRequestFilter
  );
  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  const fromTooltip = (
    <Tooltip id="fromCity">
      <span>
        {t("from")}: {data?.fromCountryCity}
      </span>
    </Tooltip>
  );
  const toTooltip = (
    <Tooltip id="toCity">
      <span>
        {t("to")}: {data?.toCountryCity}
      </span>
    </Tooltip>
  );
  const chatTooltip = (
    <Tooltip id="chatwith">
      <span>
        {t("chatwith")}: {data?.owner}
      </span>
    </Tooltip>
  );
  const shareTooltip = (
    <Tooltip id="share">
      <span>{t("share")}</span>
    </Tooltip>
  );

  useEffect(() => {
    const typeData = {
      typeId: data.trvId,
    };
    if (isClickedChat && isValidToken?.length !== 0) {
      if (isValidToken?.isSuccess) {
        dispatch(getUserChat(typeData));
        dispatch(getUserInfo());
        setIsCalledApi(true);
      }
    }
  }, [isValidToken, isClickedChat]);

  useEffect(() => {
    if (isClickedChat && chatData?.length !== 0) {
      if (chatData?.isSuccess && isCalledApi) {
        if (chatData.data?.senderFullName !== chatData.data?.travelerFullName) {
          // setIsOpenChatModal(true);
        }
        setChatEngineData(chatData.data.chatengine);
        setPackageData(chatData.data);
        setIsClickedChat(false);
        setIsCalledApi(false);
      }
    }
  }, [chatData, isClickedChat, isOpenChatModal]);

  const openReviewSidebar = () => {
    setOpenReview(!openReview);
    dispatch(getAllProfile(data.trvId));
    navigate(`/user/${data.trvId}`);
  };

  const windowSize = UseWindowSize();

  useEffect(() => {
    if (checkPackageData?.isSuccess)
      setHasAvailablePackage(checkPackageData?.data);
  }, [checkPackageData]);

  const checkActiveRequest = (data) => {
    if (hasAvailablePackage) showSendRequestSidebar(data);
    else showPackageModal();
  };

  const showPackageModal = () => {
    setIsOpenModal(true);
  };

  const showSendRequestSidebar = (data) => {
    setShowLoginSidebar(false);
    const currentRoute = window.location.pathname;
    if (!currentRoute.includes("/travelId/")) {
      navigate(`travelId/${data.trvId}`);
    }
    if (isLogin) {
      dispatch(getTravelById(data?.trvId));
    } else {
      setShowLoginModal(!showLoginModal);
    }
    setIsSuccess(true);
  };

  useEffect(() => {
    if (userTravel?.isSuccess && isSuccess) {
      setSendRequestData(userTravel.data);
      setShowSidebar(!showSidebar);
      setIsSuccess(false);
    }
  }, [userTravel]);

  useEffect(() => {
    if (str?.includes("/travelId/") && !showSidebar) {
      if (isLogin) {
        dispatch(getTravelById(data?.trvId));
      } else setShowLoginModal(!showLoginModal);
      // setIsSuccess(true);
    }
  }, [data]);

  useEffect(() => {
    if (showLoginSidebar) HandleLoginSidebar();
  }, [showLoginSidebar]);

  const HandleLoginSidebar = () => {
    setShowSignInSidebar(!showSignInSidebar);
  };

  const handleShowMoreDetail = (key) => {
    setShowMoreDetail(key);
  };

  const handleMoreDetail = () => {
    setShowMoreDetail(true);
    setFade(true);
  };

  const handleShowShareThis = (key) => {
    setIsShowShareThis(key);
  };

  const openChatModal = () => {
    setIsOpenChatModal(false);
    const tokenData = {
      accessToken: token,
    };
    setIsClickedChat(true);
    dispatch(checkTokenValidation(tokenData));
    dispatch(getChatByTypeId(data.trvId));
  };

  let title = "Koolbaar";
  let image = shareUrl;
  let description = "description send request";
  let quote = "";

  const openShareThis = () => {
    setIsShowShareThis(!isShowShareThis);
  };

  const generateImage = () => {
    function filterNode(node) {
      if (node instanceof Text) {
        return true;
      }
      return (
        [
          "div",
          "span",
          "button",
          "a",
          "p",
          "svg",
          "img",
          "i",
          "strong",
          "main",
          "aside",
          "article",
          "pre",
          "code",
          "time",
          "address",
          "header",
          "footer",
        ].includes(node.tagName.toLowerCase()) ||
        /^h[123456]$/i.test(node.tagName)
      );
    }
    const node = document.getElementById(`travel-to-image-${data.trvId}`);
    htmlToImage.toPng(node, { filter: filterNode }).then(function (dataUrl) {
      const link = document.createElement("a");
      // link.download = "image.png";
      link.href = dataUrl;
      setShareUrl(dataUrl);
      // link.click();
    });
  };

  const url = window.location.host + `/travelId/${data.trvId}`;

  useEffect(() => {
    setShareUrl("");
    generateImage();
    if (!url.startsWith("https://")) {
      setHttpsUrl("https://" + url);
    } else {
      setHttpsUrl(url);
    }
  }, [url, data]);

  return (
    <Col
      key={data?.trvId}
      lg={4}
      md={6}
      sm={12}
      className={`${windowSize.width < 768 && "p-0"} mb-4`}
      style={windowSize.width < 768 ? { width: "100%" } : null}
      data-testid="container"
      id={`travel-to-image-${data.trvId}`}
    >
      <Card className="traveler-package-card-wrapper">
        <Card.Header className="card-request-header">
          <Row>
            <Col xs={12} className="text-center header-card-plane px-1">
              <div>
                <span>
                  <Dropdown
                    className="profile-dropdown d-inline ml-1 traveler-dropdown"
                    onToggle={handleSelect}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="px-0 mx-0"
                    >
                      <span
                        className="open-profile-with-name mr-2"
                        // onClick={openReviewSidebar}
                      >
                        {data?.owner}{" "}
                      </span>
                      {/* <LevelMarker color={getUserLevelColor(data.userLevel)} /> */}
                      <div className="traveler-userlevel-icons-wrapper">
                        <FontAwesomeIcon
                          className="mr-2 userlevel-certificate-icon"
                          icon={faCertificate}
                          color={data?.userLevelHexColor}
                        ></FontAwesomeIcon>
                        <FontAwesomeIcon
                          className="mr-2 userlevel-check-icon"
                          icon={faCheck}
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="text-center">
                      <span
                        className={`${
                          data.personalPhoto === "null" && "pt-3"
                        } user-dropdown-avatar d-inline-block`}
                      >
                        {data.personalPhoto !== "null" ? (
                          <img src={data.personalPhoto} alt="avatar-img" />
                        ) : (
                          <FontAwesomeIcon
                            className="no-img-avatar"
                            icon={faUser}
                          />
                        )}
                      </span>
                      <p className="member-date-dropdown">
                        {t("memberSince")} {data.memberSince}
                      </p>
                      <Button
                        className="review-dropdown-btn"
                        variant="white"
                        onClick={openReviewSidebar}
                      >
                        {t("profile")}
                      </Button>
                    </Dropdown.Menu>
                  </Dropdown>
                </span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={5}
              className="text-left header-card-titles pr-0 city-header-card-titles"
              // style={{ marginTop: "-5px" }}
            >
              <OverlayTrigger placement="top" overlay={fromTooltip}>
                <div>
                  <span className="text-left cursor-pointer">
                    {data?.fromCity}
                  </span>
                </div>
              </OverlayTrigger>
            </Col>
            <Col xs={2} className="text-center header-card-plane px-0">
              <div>
                <span
                  className="mb-0"
                  style={{ fontSize: 18, fontWeight: 400 }}
                >
                  {"✈︎"}
                </span>
                <span className="mb-0">
                  {/* <img
                    src={PlaneIcon}
                    className="card-request-icon mt-1"
                    alt="location-img"
                  />{" "} */}
                </span>
              </div>
            </Col>
            <Col
              xs={5}
              className="header-card-titles pl-0 city-header-card-titles"
              // style={{ marginTop: "-5px" }}
            >
              <OverlayTrigger placement="top" overlay={toTooltip}>
                <div className="text-right cursor-pointer">
                  <span>{data?.toCity}</span>
                </div>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              className="text-left header-card-titles pr-0 date-header-card-titles"
            >
              <div>
                <span className="text-left">{data?.fromDate1}</span>
              </div>
            </Col>
            <Col
              xs={6}
              className="header-card-titles pl-0 date-header-card-titles"
            >
              <div className="text-right">
                <span>{data?.fromDate2}</span>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="card-request-body pt-3">
          <Row>
            <Col xs={8} className="request-body-info">
              <div className={isPersian ? "dir-rtl" : "dir-ltr"}>
                <span className="card-text package-travel-card-id">
                  {t("TRVID")}: {data?.trvId}
                </span>
              </div>
              <div className={isPersian ? "dir-rtl" : "dir-ltr"}>
                <span className="card-text d-block mt-2">
                  {t("maxSize")}: {data?.size}
                </span>
              </div>

              <div className={isPersian ? "dir-rtl" : "dir-ltr"}>
                <span className="card-text d-block mt-2">
                  {t("maxWeight")}: {data?.weight} {data?.weightUnit}
                </span>
              </div>
            </Col>
            <Col xs={4} className="request-body-package text-right">
              <OverlayTrigger placement="top" overlay={shareTooltip}>
                <a onClick={openShareThis}>
                  <FontAwesomeIcon
                    icon={faShareAlt}
                    className="sharethis-icon"
                  />
                </a>
              </OverlayTrigger>
              <OverlayTrigger placement="top" overlay={chatTooltip}>
                <a onClick={openChatModal}>
                  <FontAwesomeIcon
                    icon={faComment}
                    className="chat-card-icon"
                  />
                </a>
              </OverlayTrigger>
              <Button
                variant="primary"
                data-test="docs-btn-anchor"
                className="rating-btn"
              >
                {t("rating")} <span>{data?.rating}/5</span>
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="card-request-footer">
          <Button
            variant="gray7"
            data-test="docs-btn-anchor"
            className="more-detail-btn"
            onClick={() => {
              handleMoreDetail();
            }}
            onAnimationEnd={() => setFade(false)}
          >
            {t("moreDetails")}
          </Button>

          {str?.includes("/travelId/") ? (
            <Button
              variant="warning"
              data-test="docs-btn-anchor"
              className="send-request-btn"
              onClick={() => checkActiveRequest(data)}
            >
              {t("sendRequest")}
            </Button>
          ) : (
            // <Link
            //   to={
            //     str?.includes("/travelId/")
            //       ? undefined
            //       : `travelId/${data.trvId}`
            //   }
            // >
            <Button
              variant="warning"
              data-test="docs-btn-anchor"
              className="send-request-btn"
              onClick={() => checkActiveRequest(data)}
            >
              {t("sendRequest")}
            </Button>
            // </Link>
          )}
        </Card.Footer>
      </Card>
      {showMoreDetail && (
        <PackageCover
          data={data}
          fade={fade}
          onShowCover={handleShowMoreDetail}
        />
      )}
      {showLoginModal && (
        <LoginConfirm
          title={t("addRequest")}
          isOpen={showLoginModal}
          setIsOpen={setShowLoginModal}
          type="addPackage"
          setShowLoginSidebar={setShowLoginSidebar}
        />
      )}
      {showSignInSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSignInSidebar}
            setIsOpen={setShowSignInSidebar}
            setIsLogin={setIsLogin}
            sidebarType="login"
          />
        </div>
      )}
      {openReview && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={openReview}
            setIsOpen={setOpenReview}
            sidebarType="review"
          />
        </div>
      )}
      {showSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSidebar}
            setIsOpen={setShowSidebar}
            sidebarType="sendRequest"
            // mode="choose"
            data={sendRequestData}
          />
        </div>
      )}
      {isShowShareThis && (
        <ShareThisCover
          fade={fade}
          onShowShareCover={handleShowShareThis}
          httpsUrl={httpsUrl}
          title={data?.trvId}
          description={description}
          image={image}
        />
      )}
      {isOpenChatModal && (
        // <Chat
        //   isOpen={isOpenChatModal}
        //   setIsOpen={setIsOpenChatModal}
        //   chatEngineData={chatEngineData}
        //   packageData={packageData}
        // />
        <Row>
          <Col lg={4} md={6} sm={12}>
            <SingleChatList
              isOpen={isOpenChatModal}
              setIsOpen={setIsOpenChatModal}
              id={data?.trvId}
              avatar={data?.personalPhoto}
              packageData={packageData}
              latestMessages={latestMessages}
              roomId={roomId}
            />
          </Col>
        </Row>
      )}
      {isOpenModal && (
        <FirstMakeYourCard
          isOpen={isOpenModal}
          setIsOpen={setIsOpenModal}
          type="package"
        />
      )}
    </Col>
  );
};
