/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import { Button } from "layers";
import { Gallery } from "react-photoswipe-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faCheck,
  faClose,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { LightBoxItem } from "components";
import { IChatData, IChatEngineData, IRequest } from "models/interfaces";
import { LevelMarker } from "components/common/levelMarker";
import { getUserLevelColor } from "helpers/getUserLevel";
import { getAllProfile } from "redux/actions/landing";
import { useAppDispatch, useAppSelector } from "redux/store";
import { RightSidebar } from "layers";
import { Chat } from "components/modals/Chat";
import { Link, useNavigate } from "react-router-dom";
import { checkTokenValidation } from "redux/actions/Authorization";
import { toast } from "react-toastify";
import { getUserChat } from "redux/actions/chat";
import { useTranslation } from "react-i18next";

interface IProp {
  onShowCover: (key: any) => void;
  fade: boolean;
  data: IRequest;
}
export const PackageCover: React.FC<IProp> = ({ onShowCover, fade, data }) => {
  const dispatch = useAppDispatch();
  const [showCover, setShowCover] = useState(false);
  const [chatEngineData, setChatEngineData] = useState<IChatEngineData>();
  const [packageData, setPackageData] = useState<IChatData>();
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [isOpenChatModal, setIsOpenChatModal] = useState(false);
  const [isCalledApi, setIsCalledApi] = useState(false);
  const [isClickedChat, setIsClickedChat] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const isValidToken = useAppSelector<any>((state) => state.validationToken);
  const chatData = useAppSelector<any>((state) => state.userChat);
  let token = window.localStorage.getItem("token");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleShow = () => {
    setShowCover(!showCover);
    onShowCover(showCover);
    setFadeOut(true);
  };

  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  const openReviewSidebar = () => {
    setOpenReview(!openReview);
    dispatch(getAllProfile(data.pkgId));
    navigate(`/user/${data.pkgId}`);
  };

  const getOriginUrl = (address) => {
    const url = new URL(address);
    const hostname = url.hostname;

    const siteAddress = hostname.replace("www.", "");
    return siteAddress;
  };

  const openChatModal = () => {
    setIsOpenChatModal(false);
    const data = {
      accessToken: token,
    };
    setIsClickedChat(true);
    dispatch(checkTokenValidation(data));
  };

  useEffect(() => {
    const typeData = {
      typeId: data.pkgId,
    };
    if (isClickedChat && isValidToken?.length !== 0) {
      if (isValidToken?.isSuccess) {
        dispatch(getUserChat(typeData));
        setIsCalledApi(true);
      }
    }
  }, [isValidToken, isClickedChat]);

  useEffect(() => {
    if (isClickedChat && chatData?.length !== 0) {
      if (chatData?.isSuccess && isCalledApi) {
        setIsOpenChatModal(true);
        setChatEngineData(chatData.data.chatengine);
        setPackageData(chatData.data);
        setIsClickedChat(false);
        setIsCalledApi(false);
      }
    }
  }, [chatData, isClickedChat, isOpenChatModal]);

  return (
    <div className="more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`request-package-card-wrapper mb-3 request-card-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Header className="request-card-cover-header">
          <div>
            <span style={{ fontSize: "12px" }}>
              {/* <span
                className="open-profile-with-name"
                onClick={openReviewSidebar}
              >
                {data?.owner}{" "}
              </span> */}
              <Dropdown
                style={{ verticalAlign: "top" }}
                className="profile-dropdown ml-1 package-dropdown d-flex"
                onToggle={handleSelect}
              >
                <Dropdown.Toggle
                  variant="transparent"
                  id="dropdown-basic"
                  className="px-0 mx-0 d-flex"
                  style={{ alignItems: "center" }}
                >
                  <span
                    className="open-profile-with-name"
                    // onClick={openReviewSidebar}
                  >
                    {data?.owner}{" "}
                  </span>
                  <div className="package-userlevel-icons-wrapper">
                    <FontAwesomeIcon
                      className="mr-2 userlevel-certificate-icon"
                      icon={faCertificate}
                      color={data?.userLevelHexColor}
                    ></FontAwesomeIcon>
                    <FontAwesomeIcon
                      className="mr-2 userlevel-check-icon"
                      icon={faCheck}
                    />
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="text-center">
                  <span
                    className={`${
                      data.personalPhoto === "null" && "pt-3"
                    } user-dropdown-avatar d-inline-block`}
                  >
                    {data.personalPhoto !== "null" ? (
                      <img src={data.personalPhoto} alt="avatar-img" />
                    ) : (
                      <FontAwesomeIcon
                        className="no-img-avatar"
                        icon={faUser}
                      />
                    )}
                  </span>
                  <p className="member-date-dropdown">
                    {t("memberSince")} {data.memberSince}
                  </p>
                  <Button
                    className="review-dropdown-btn"
                    variant="white"
                    onClick={openReviewSidebar}
                  >
                    {t("profile")}
                  </Button>
                  {/* <Button
                    onClick={openChatModal}
                    className="chat-dropdown-btn"
                    variant="white"
                  >
                    {t("chat")}
                  </Button> */}
                </Dropdown.Menu>
              </Dropdown>
            </span>
          </div>
        </Card.Header>
        <Card.Body className="request-card-border location-cover-info traveler-card-cover-body">
          {data?.airlineName && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">{t("flightNameIs")}: </span>
              <span className="location-description">{data?.airlineName}</span>
            </div>
          )}
          {data?.flightNumber && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">{t("flightNumber")}: </span>
              <span className="location-description">{data?.flightNumber}</span>
            </div>
          )}
          {data?.deliveryTypes && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">
                {t("requestedServices")}:{" "}
              </span>
              <span className="location-description">
                {data?.deliveryTypes}
              </span>
            </div>
          )}
          {data?.sizerange && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">{t("size")}: </span>
              <span className="location-description">{data?.sizerange}</span>
            </div>
          )}
          {data?.description && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">{t("note")}: </span>
              <span className="location-description">{data?.description}</span>
            </div>
          )}
          {/* {data?.fromCountryCity && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">From: </span>
              <span className="location-description">
                {data?.fromCountryCity}
              </span>
            </div>
          )} */}
          {/* {data?.toCountryCity && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">To: </span>
              <span className="location-description">
                {data?.toCountryCity}
              </span>
            </div>
          )} */}
          {data?.hasPurchase && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">Purchase link: </span>
              {data?.purchases?.map((item, idx) => {
                return item.link ? (
                  <>
                    <a
                      href={item.link}
                      key={idx}
                      target="_blank"
                      className="purchase-link-more"
                    >
                      {item.link ? getOriginUrl(item.link) : ""} - (QTY:{" "}
                      {item.quantity ? item.quantity : ""})
                    </a>
                  </>
                ) : (
                  <span className="location-description">......</span>
                );
              })}
            </div>
          )}
          <Row className="image-more-wrapper mt-2">
            <Gallery>
              {data?.images?.map((item, idx) => {
                return (
                  <div
                    className="text-center images-delivery-img-wrapper"
                    key={idx}
                  >
                    <LightBoxItem
                      original={item}
                      thumbnail={item}
                      width="45px"
                      height="45px"
                    />
                  </div>
                );
              })}
            </Gallery>
          </Row>
          {isOpenChatModal && (
            <Chat
              isOpen={isOpenChatModal}
              setIsOpen={setIsOpenChatModal}
              chatEngineData={chatEngineData}
              packageData={packageData}
            />
          )}
          {/* {openReview && (
            <div className="offer-sidebar">
              <RightSidebar
                isOpen={openReview}
                setIsOpen={setOpenReview}
                sidebarType="review"
              />
            </div>
          )} */}
        </Card.Body>
      </Card>
    </div>
  );
};
