import { BaseService } from "./baseService";

class NotificationsService extends BaseService {
  getAllNotifications(data) {
    return this.authorizedHttp().post("/User/Announcement", data);
  }
  getAllNotificationsCount() {
    return this.authorizedHttp().get("/User/Announcement/Count");
  }
}

export default new NotificationsService();
