import checkEmail from "helpers/checkEmail";

export const isValidFirstName = (firstName) => firstName !== "";

export const isValidLastName = (lastName) => lastName !== "";

export const isValidState = (state) => state !== "";
export const isValidCity = (city) => city !== "";
export const isValidPostalCode = (postalCode) => postalCode !== "";

export const isValidPhoneNumber = (phoneNumber) => phoneNumber !== "";
export const isValidPhoneNumberLength = (phoneNumber) =>
  phoneNumber?.length === 10;

export const isValidEmail = (email) => email !== "";

export const isValidPassword = (password) => password !== "";

export const isValidRePassword = (rePassword) => rePassword !== "";

export const isValidFormatEmail = (email) => {
  if (email !== "" && !checkEmail(email)) return false;
  return true;
};

export const isPatternPhoneValid = (phoneNumber, code) => {
  const phone = code + phoneNumber;
  if (phoneNumber !== "" && code !== "") {
    const phonePattern = /^\+[1-9]{1}[0-9]{8,14}$/;
    if (phonePattern.test(phone)) return true;
    return false;
  }
  return true;
};

export const isPatternPasswordValid = (password) => {
  const regex1 = /[A-Z]/;
  const regex2 = /[@$!%?&]/;
  const regex3 = /\d/;
  const regex4 = /.{8,}/;
  const regex5 = /[a-z]/;
  if (
    regex1.test(password) &&
    regex2.test(password) &&
    regex3.test(password) &&
    regex4.test(password) &&
    regex5.test(password)
  )
    return true;
};

export const isMatchPasswords = (rePassword, password) => {
  if (rePassword !== "" && rePassword !== password) return false;
  return true;
};

export const isValid = (checked, registerData, code) => {
  return (
    checked &&
    isValidFirstName(registerData.firstName) &&
    isValidLastName(registerData.lastName) &&
    isValidPhoneNumber(registerData.phoneNumber) &&
    // isValidPhoneNumberLength(registerData.phoneNumber) &&
    isPatternPhoneValid(registerData.phoneNumber, code) &&
    isValidEmail(registerData.email) &&
    isValidFormatEmail(registerData.email) &&
    isValidPassword(registerData.password) &&
    isValidRePassword(registerData.rePassword) &&
    isPatternPasswordValid(registerData.password) &&
    isMatchPasswords(registerData.rePassword, registerData.password)
  );
};
