import { BaseService } from "./baseService";

class PaymentService extends BaseService {
  payment(data) {
    return this.authorizedHttp().post("/User/Payment", data);
  }

  verifyStripe() {
    return this.authorizedHttp().get("/User/Stripe/Verify");
  }
}

export default new PaymentService();
