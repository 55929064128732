import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import data from "json/travelers.json";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { SkeletonGrid } from "components";
import { Option, ITraveler } from "models/interfaces";
import { Cards } from "./Cards";
import { Button } from "layers";
import { getAllHomeTravelFilter } from "redux/actions/flight";
import { useAppDispatch } from "redux/store";
import { useTranslation } from "react-i18next";

interface IProps {
  hasLoadMore: boolean;
  pageCount: number;
  isEmpty: boolean;
  isLoading: boolean;
  travelerData: ITraveler[];
  setIsLoad: Function;
  type: Option;
  size: Option;
  policy: Option;
  weight: Option;
  deliveryTypes: Option;
  payments: Option;
  services: Option;
  currentPage: number;
  setCurrentPage: Function;
}

export const PackageCard: React.FC<IProps> = ({
  travelerData,
  pageCount,
  isLoading,
  type,
  size,
  policy,
  weight,
  deliveryTypes,
  services,
  payments,
  setIsLoad,
  currentPage,
  setCurrentPage,
  hasLoadMore,
}) => {
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const [isFetching, setIsFetching] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);

  // useEffect(() => {
  //   if (!isFetching) return;
  //   fetchMoreData();
  // }, [isFetching]);

  // function handleScroll() {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop !==
  //       document.documentElement.offsetHeight ||
  //     isFetching
  //   )
  //     return;
  //   setIsFetching(true);
  // }

  const fetchMoreData = () => {
    let pageNumber: number = currentPage + 1;
    setCurrentPage(pageNumber);
    const data = {
      type: "travel",
      packagetypeIds: type.value ? type.value : null,
      weightrangeIds: weight?.value ? weight?.value : null,
      deliverytypeIds: deliveryTypes?.value ? deliveryTypes?.value : null,
      paymentmethodIds: payments?.value ? payments?.value : null,
      sizerangeIds: size?.value ? size?.value : null,
      cancellationpolicyIds: policy?.value ? policy?.value : null,
      // cancellationpolicyIds: policy?.value
      // ? policy?.value
      // : "422A36FD-F65F-4A1D-830D-20C1D66C42AB",
      serviceIds: services?.value ? services?.value : null,
      userLevelIds: null,
      page: pageNumber,
    };

    setIsLoad(true);
    dispatch(getAllHomeTravelFilter(data));
  };

  useEffect(() => {
    const data = {
      type: "travel",
      packagetypeIds: type.value ? type.value : null,
      weightrangeIds: weight?.value ? weight?.value : null,
      deliverytypeIds: deliveryTypes?.value ? deliveryTypes?.value : null,
      paymentmethodIds: payments?.value ? payments?.value : null,
      sizerangeIds: size?.value ? size?.value : null,
      cancellationpolicyIds: policy?.value ? policy?.value : null,
      // cancellationpolicyIds: policy?.value
      // ? policy?.value
      // : "422A36FD-F65F-4A1D-830D-20C1D66C42AB",
      serviceIds: services?.value ? services?.value : null,
      userLevelIds: null,
      page: currentPage,
    };

    setIsLoad(true);
    dispatch(getAllHomeTravelFilter(data));
  }, [currentPage]);

  return (
    <>
      <div className="flex-grow-1 mb-3">
        <Row
          style={
            windowSize.width < 768 ? { width: "100%", margin: "auto" } : null
          }
        >
          {isLoading ? (
            (data?.items ?? []).map((item) => (
              <Col key={item.name} lg={4} md={4} sm={12}>
                <SkeletonGrid />
              </Col>
            ))
          ) : (
            <>
              {travelerData?.length !== 0 ? (
                travelerData?.map((data, idx) => (
                  <Cards key={idx} data={data} />
                ))
              ) : (
                <div className="no-data-dashboard text-center">
                 {t("noTraveler")}
                </div>
              )}
            </>
          )}
          {/* <a className="m-auto mt-5 cursor-pointer" onClick={fetchMoreData}>
            {isFetching && page < pageCount && "Loading ..."}
          </a> */}
          {hasLoadMore && currentPage < pageCount && (
            <Button
              variant="primary"
              onClick={fetchMoreData}
              className="load-more-btn mt-3 mx-auto"
            >
              {t("loadMore")}
            </Button>
          )}
        </Row>
      </div>
    </>
  );
};
