/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IOfferSent } from "models/interfaces";
import { getRequestTimeline } from "redux/actions/dashboard";
import { useAppDispatch, useAppSelector } from "redux/store";
import { Gallery } from "react-photoswipe-gallery";
import { LightBoxItem } from "components";
import { useTranslation } from "react-i18next";

interface IProp {
  setShowProofDelivery: (key: any) => void;
  fade: boolean;
  images: any;
  setFiles: Function;
  setImages: Function;
}
export const ProofDelivery: React.FC<IProp> = ({
  setShowProofDelivery: setShowMoreDetail,
  fade,
  setImages,
  images,
  setFiles,
}) => {
  const [showCover, setShowCover] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const proofById: any = useAppSelector((state) => state?.proofById);
  const [photos, setPhotos] = useState<any>([]);
  const { t } = useTranslation();

  const handleShow = () => {
    setShowCover(!showCover);
    setShowMoreDetail(showCover);
    setFadeOut(true);
  };

  useEffect(() => {
    if (proofById.isSuccess) {
      setPhotos(proofById.data.filesUrl);
    }
  }, [proofById]);

  useEffect(() => {
    if (images[0]) {
      setFiles(images);
      let element;
      for (let i = 0; i < images.length; i++) {
        element = images[i];
      }
    }
  }, [images]);

  const removeFile = (index: number) => {
    const updatedFiles = [...images];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    setImages(updatedFiles);
  };

  return (
    <div className="dashboard-more-detail-wrapper more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`dashboard-receive-offer-card-wrapper mb-3 offers-card-info-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Body className="request-card-border location-cover-info offer-card-cover-body">
          <div className="deliver-status text-left mt-4">
            <span className="delivery-proof-title pr-2">{t("proofOfDelivery")}</span>
          </div>
          <Row className="image-proof-wrapper mt-2">
            <Gallery>
              {photos?.length !== 0 &&
                photos?.map((item, idx) => {
                  return (
                    <div
                      className="text-center proof-delivery-img-wrapper position-relative"
                      key={idx}
                    >
                      <LightBoxItem
                        original={item.type ? URL.createObjectURL(item) : item}
                        thumbnail={item.type ? URL.createObjectURL(item) : item}
                        width="103px"
                        height="103px"
                      />
                    </div>
                  );
                })}
            </Gallery>
            <Gallery>
              {images?.length !== 0 &&
                images?.map((item, idx) => {
                  return (
                    <div
                      className="text-center proof-delivery-img-wrapper position-relative"
                      key={idx}
                    >
                      <a
                        onClick={() => removeFile(idx)}
                        className="remove-img-dropzone"
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </a>
                      <LightBoxItem
                        original={URL.createObjectURL(item)}
                        thumbnail={URL.createObjectURL(item)}
                        width="103px"
                        height="103px"
                      />
                    </div>
                  );
                })}
            </Gallery>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
