import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useAppDispatch, useAppSelector } from "redux/store";
import { Oval } from "react-loader-spinner";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IContract } from "models/interfaces";
import {
  isValidFullName,
  isValidPhoneNumber,
  isValidEmail,
  isValidFormatEmail,
  isValid,
  isPatternPhoneValid,
  isValidSubject,
  isValidMessage,
} from "helpers/contractValidation";
import { toast } from "react-toastify";
import { sendContactForm } from "redux/actions/landing";
import { getAllCountriesList } from "redux/actions/types";

interface IProp {
  id: string;
  setIsOpen: Function;
  type: string;
  isOpen: boolean;
}

export const ContactUs: React.FC<IProp> = ({ id, setIsOpen, type, isOpen }) => {
  const size = UseWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [contractClicked, setContractClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessContract, setIsSuccessContract] = useState(false);
  const dispatch = useAppDispatch();
  const contactForm: any = useAppSelector((state) => state.sendContact);
  const [countries, setCountries] = useState<any>();
  const allCountries = useAppSelector<any>((state) => state.allCountries);
  const [code, setCode] = useState("+1");
  const [country, setCountry] = useState<any>({
    value: "Canada",
    label: (
      <>
        <div className="number-code-wrapper">
          <img
            className="number-code-flag"
            src="https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg"
          />
          <span className="number-code-code">{"+1"}</span>
        </div>
      </>
    ),
  });
  const [contractData, setContractData] = useState<IContract>({
    fullName: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    dispatch(getAllCountriesList());
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setContractData({ ...contractData, [e.target.name]: e.target.value });
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    setCountries(
      allCountries?.map((item) => {
        return {
          value: item.name,
          flag: item.flag,
          code: item.number,
          label: (
            <>
              <div className="number-code-wrapper">
                <span className="number-code-name">{item.name}</span>
              </div>
            </>
          ),
        };
      })
    );
  }, [allCountries]);

  const handleChangeCountryCode = (selected) => {
    const countryCode = {
      value: selected.value,
      label: (
        <>
          <div className="number-code-wrapper">
            <img className="number-code-flag" src={selected.flag} />
            <span className="number-code-code">{selected.code}</span>
          </div>
        </>
      ),
    };
    setCode(selected.code);
    setCountry(countryCode);
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 50,
      minWidth: 130,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#fff",
      whiteSpace: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "auto",
    }),
  };

  useEffect(() => {
    if (!contactForm?.isSuccess) {
      setIsLoading(false);
    } else if (contactForm?.isSuccess && isSuccessContract) {
      setIsLoading(false);
      setIsOpen(false);
      navigate("/");
      setIsSuccessContract(false);
      document.documentElement.style.overflow = "auto";
      // window.location.reload();
    }
  }, [contactForm]);

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          navigate("/");
        }
      },
      false
    );
  }, []);

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/");
  };

  const sendForm = () => {
    setContractClicked(true);
    // if (!isValid(contractData, code)) {
    //   toast.error("Enter the parameters correctly!");
    //   return;
    // }
    callSendApi();
    document.documentElement.style.overflow = "auto";
  };

  const callSendApi = () => {
    const data = {
      fullName: contractData.fullName,
      phone: code + contractData.phone,
      email: contractData.email,
      subject: contractData.subject,
      message: contractData.message,
    };
    dispatch(sendContactForm(data));
    setIsLoading(true);
    setIsSuccessContract(true);
  };

  return (
    <div className="request-slider-container">
      <Row className="request-wrapper">
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className="offer-form">
          <h1>{t("contact")}</h1>
        </Col>
        <Col xs={12} className="mb-4">
          <Input
            size="sm"
            id="fullName-input"
            placeholder={t("fullName")}
            name="fullName"
            // className={`half-custom-input-register ${
            //   contractClicked &&
            //   !isValidFullName(contractData.fullName) &&
            //   "empty-input-style"
            // }`}
            className={`half-custom-input-register`}
            value={contractData.fullName}
            // disabled={thirdPartyResponse?.fullName !== ""}
            onChange={handleChange}
          />
          {/* {contractClicked && !isValidFullName(contractData.fullName) && (
            <span className="err-validation">Required!</span>
          )} */}
        </Col>
        <Col xs={12} className="mb-4 text-left">
          <Row>
            <Col xs={5}>
              <Select
                styles={customStyle}
                className="contract-code-number-wrapper w-100"
                value={country}
                onChange={handleChangeCountryCode}
                options={countries}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </Col>
            <Col xs={7}>
              <Input
                size="sm"
                id="phone-input"
                placeholder={t("phoneNumber")}
                name="phone"
                className={`${
                  size.width < 768
                    ? "responsive-half-custom-phone-register"
                    : "contract-half-custom-phone-register"
                }  w-100`}
                // className={`${
                //   size.width < 768
                //     ? "responsive-half-custom-phone-register"
                //     : "contract-half-custom-phone-register"
                // }  w-100 ${
                //   contractClicked &&
                //   (!isValidPhoneNumber(contractData.phone) ||
                //     !isPatternPhoneValid(contractData.phone, code)) &&
                //   "empty-input-style"
                // }`}
                value={contractData.phone}
                onChange={handleChange}
              />
            </Col>
          </Row>

          {/* {contractClicked && !isValidPhoneNumber(contractData.phone) && (
            <span className="err-validation">Required!</span>
          )}
          {contractClicked &&
            !isPatternPhoneValid(contractData.phone, code) && (
              <span className="err-validation">
                Phone number format is not correct!
              </span>
            )} */}
        </Col>
        <Col xs={12} className="mb-4">
          <Input
            size="sm"
            id="email-input"
            placeholder={t("email")}
            name="email"
            // className={`full-custom-input-register ${
            //   contractClicked &&
            //   (!isValidEmail(contractData.email) ||
            //     !isValidFormatEmail(contractData.email)) &&
            //   "empty-input-style"
            // }`}
            className={`full-custom-input-register dir-ltr text-left pl-2`}
            value={contractData.email}
            onChange={handleChange}
            maxLength="25"
          />
          {/* {contractClicked && !isValidEmail(contractData.email) && (
            <span className="err-validation">Required!</span>
          )}
          {contractClicked && !isValidFormatEmail(contractData.email) && (
            <span className="err-validation">Email format isn't correct!</span>
          )} */}
        </Col>
        <Col sm={12} md={12} className="mb-4">
          <Input
            size="sm"
            id="subject-input"
            placeholder={t("subject")}
            name="subject"
            // className={`half-custom-input-register ${
            //   contractClicked &&
            //   !isValidFullName(contractData.subject) &&
            //   "empty-input-style"
            // }`}
            className={`half-custom-input-register`}
            value={contractData.subject}
            onChange={handleChange}
            maxLength="25"
          />
          {/* {contractClicked && !isValidSubject(contractData.subject) && (
            <span className="err-validation">Required!</span>
          )} */}
        </Col>
        <Col sm={12} md={12} className="mb-4">
          <Input
            size="sm"
            id="message-input"
            placeholder={t("message")}
            name="message"
            // className={`custom-input-message-contact ${
            //   contractClicked &&
            //   !isValidMessage(contractData.message) &&
            //   "empty-input-style"
            // }`}
            className={`custom-input-message-contact`}
            value={contractData.message}
            onChange={handleChange}
            textArea={true}
            rows={4}
            maxLength="250"
          />
          {/* {contractClicked && !isValidMessage(contractData.message) && (
            <span className="err-validation">Required!</span>
          )} */}
        </Col>
        <Col xs={12}>
          <Button
            variant="primary"
            data-test="docs-btn-anchor"
            className="submit-request-btn mt-4"
            onClick={sendForm}
          >
            {t("submitRequest")}
            {isLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
