import React from "react";
import { InlineShareButtons } from "sharethis-reactjs";

interface IProp {
  url: string;
  title: string;
  description: string;
  image: string;
}

export const ShareThis: React.FC<IProp> = ({
  url,
  title,
  description,
  image,
}) => {
  return (
    <InlineShareButtons
      config={{
        alignment: "center", // alignment of buttons (left, center, right)
        color: "social", // set the color of buttons (social, white)
        enabled: true, // show/hide buttons (true, false)
        font_size: 12, // font size for the buttons
        labels: "cta", // button labels (cta, counts, null)
        language: "en", // which language to use (see LANGUAGES)
        networks: [
          // which networks to include (see SHARING NETWORKS)
          "whatsapp",
          "linkedin",
          "messenger",
          "facebook",
          "twitter",
          "email",
          "pinterest",
          "wechat",
        ],
        padding: 16, // padding within buttons (INTEGER)
        radius: 4, // the corner radius on each button (INTEGER)
        show_total: true,
        size: 40, // the size of each button (INTEGER)

        // OPTIONAL PARAMETERS

        //   min_count: 10,                    // (threshold for total share count to be displayed)
        url: url, // (defaults to current url)
        image: image, // (defaults to og:image or twitter:image)
        description: description, // (defaults to og:description or twitter:description)
        title: title, // (defaults to og:title or twitter:title)
      }}
    />
  );
};

// export
export default ShareThis;
