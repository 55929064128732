import { createSlice } from "@reduxjs/toolkit";

export const agreementByTypeReceiveSlice = createSlice({
  name: "agreementByTypeReceive",
  initialState: [],
  reducers: {
    agreementByTypeReceive: (state, action) => {
      return (state = action.payload);
    },
    agreementByTypeReceiveFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { agreementByTypeReceive, agreementByTypeReceiveFailure } = agreementByTypeReceiveSlice.actions;
export default agreementByTypeReceiveSlice.reducer;
