/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IOfferReceived } from "models/interfaces";
import { Gallery } from "react-photoswipe-gallery";
import { LightBoxItem } from "components";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useTranslation } from "react-i18next";

interface IProp {
  setShowMoreDetail: (key: any) => void;
  fade: boolean;
  data: IOfferReceived;
}
export const MoreDetail: React.FC<IProp> = ({
  setShowMoreDetail,
  fade,
  data,
}) => {
  const [showCover, setShowCover] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [showAllString, setShowAllString] = useState(false);
  const windowSize = UseWindowSize();
  const isMobile = windowSize.width < 768;
  const { t } = useTranslation();

  const handleShow = () => {
    setShowCover(!showCover);
    setShowMoreDetail(showCover);
    setFadeOut(true);
  };

  const showAllStringChange = () => {
    setShowAllString(!showAllString);
  };

  const getOriginUrl = (address) => {
    const url = new URL(address);
    const hostname = url.hostname;

    const siteAddress = hostname.replace("www.", "");
    return siteAddress;
  };

  return (
    <div className="dashboard-more-detail-wrapper more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`dashboard-receive-offer-card-wrapper mb-3 offers-card-info-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Body className="request-card-border location-cover-info offer-card-cover-body">
          <Row>
            <Col xs={6} className={`${isMobile ? "px-0" : ""}`}>
              <Row className="detail-more-wrapper">
                {data?.fromCountryCity && (
                  <Col xs={12} className="text-align-title h-detail-items">
                    <span className="detail-more-title mr-1">{t("from")}: </span>
                    <span className="detail-more-description">
                      {data?.fromCountryCity}
                    </span>
                  </Col>
                )}
                {data?.toCountryCity && (
                  <Col xs={12} className="text-align-title h-detail-items">
                    <span className="detail-more-title mr-1">{t("to")}: </span>
                    <span className="detail-more-description">
                      {data?.toCountryCity}
                    </span>
                  </Col>
                )}
                {data?.deliveryTypes && (
                  <Col xs={12} className="text-align-title h-detail-items">
                    <span className="detail-more-title mr-1">
                      {t("requestedServices")}:{" "}
                    </span>
                    <span className="detail-more-description">
                      {data?.deliveryTypes}
                    </span>
                  </Col>
                )}
                {data?.packagetypes && (
                  <Col xs={12} className="text-align-title h-detail-items">
                    <span className="detail-more-title mr-1">
                      {t("packages")}:{" "}
                    </span>
                    <span className="detail-more-description">
                      {data?.packagetypes}
                    </span>
                  </Col>
                )}{" "}
                {data?.sizerange && (
                  <Col xs={12} className="text-align-title h-detail-items">
                    <span className="detail-more-title mr-1">
                      {t("size")} :
                    </span>
                    <span className="detail-more-description">
                      {data?.sizerange}
                    </span>
                  </Col>
                )}
              </Row>
            </Col>
            <Col xs={6}>
              <Row className="detail-more-wrapper">
                <Col xs={12}>
                  {data?.message &&
                    (data?.message.length <= 40 ? (
                      <div className="text-align-title h-detail-items">
                        <span className="detail-more-title mr-1">
                          {t("note")}:
                        </span>
                        <span className="detail-more-description">
                          {data?.message}
                        </span>
                      </div>
                    ) : (
                      <div className="text-align-title h-detail-items">
                        <span className="detail-more-title mr-1">
                          {t("note")}:
                        </span>
                        <span className="detail-more-description">
                          {showAllString
                            ? data?.message
                            : data?.message.substring(0, 40)}
                          {!showAllString ? (
                            <a
                              className="more-string"
                              onClick={showAllStringChange}
                            >
                              {t("more")} ...
                            </a>
                          ) : (
                            <a
                              className="more-string"
                              onClick={showAllStringChange}
                            >
                              {t("less")}
                            </a>
                          )}
                        </span>
                      </div>
                    ))}
                </Col>
                <Col xs={12}>
                  {data?.hasPurchase && (
                    <div className="text-align-title">
                      <span className="location-title mr-1">
                        Purchase link :
                      </span>
                      {data?.purchases?.map((item, idx) => {
                        return item.link ? (
                          <>
                            <a
                              href={item.link}
                              key={idx}
                              target="_blank"
                              className="purchase-link-more"
                            >
                              {item.link ? getOriginUrl(item.link) : ""} - (QTY:{" "}
                              {item.quantity ? item.quantity : ""})
                            </a>
                          </>
                        ) : (
                          <span className="location-description">......</span>
                        );
                      })}
                    </div>
                  )}
                </Col>
                {data?.images?.length !== 0 && (
                  <Col xs={12}>
                    <div className="text-align-title h-detail-items">
                      <span className="detail-more-title">{t("images")}: </span>
                      <Row className="image-offer-request-wrapper mt-2">
                        <Gallery>
                          {data?.images?.map((item) => {
                            return (
                              <div className="p-0">
                                <LightBoxItem
                                  original={item}
                                  thumbnail={item}
                                  width="auto"
                                  height="auto"
                                />
                              </div>
                            );
                          })}
                        </Gallery>
                      </Row>
                    </div>
                  </Col>
                )}
                <Col xs={12} className="text-align-title h-detail-items">
                  <span className="detail-more-title">Request ID: </span>
                  <span className="detail-more-description">{data?.reqId}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
