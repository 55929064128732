import { createSlice } from "@reduxjs/toolkit";

export const closeAccountRequestSlice = createSlice({
  name: "closeAccountRequest",
  initialState: [],
  reducers: {
    closeAccountRequest: (state, action) => {
      return (state = action.payload);
    },
    closeAccountRequestFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { closeAccountRequest, closeAccountRequestFailure } = closeAccountRequestSlice.actions;
export default closeAccountRequestSlice.reducer;
