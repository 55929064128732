import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { PackageCard } from "./PackageCard";
import { IOfferReceived, Option } from "models/interfaces";
import Select from "react-select";
import { DebounceInput } from "react-debounce-input";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  // getRequestStatus,
  getAllStatusChanges,
  getAllRequestStatus,
  getFilterRequestStatus,
  getSearchByTravelId,
} from "redux/actions/dashboard";
import { useTranslation } from "react-i18next";

interface IProps {
  display: Option;
  trvId: string;
  offerReceivedData: IOfferReceived[];
}

export const ReceivedOffer: React.FC<IProps> = ({
  display,
  trvId,
  offerReceivedData,
}) => {
  const windowSize = UseWindowSize();
  const dispatch = useAppDispatch();
  const [travelId, setTravelId] = useState("");
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();
  const [filter, setFilter] = useState({ value: 0, label: t("displayAll") });
  const requestStatusData = useAppSelector((state) => state.getChangedStatus);
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  // useEffect(() => {
  //   dispatch(getAllStatusChanges());
  // }, []);

  // useEffect(() => {
  //   setTravelId("");
  //   dispatch(getSearchByTravelId(""));
  // }, []);

  useEffect(() => {
    if (trvId !== "" && trvId !== null) {
      setTravelId(trvId);
      dispatch(getSearchByTravelId(trvId));
    }
  }, [trvId]);

  useEffect(() => {
    const options = requestStatusData?.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    setOptions([{ value: null, label: t("displayAll") }, ...options]);
  }, [requestStatusData]);

  const customStyle = {
    control: (styles) => ({
      ...styles,
      height: 47,
      width: "auto",
      minWidth: 167,
      color: "#989898",
      fontSize: 13,
      fontWeight: 500,
      textAlign: isPersian ? "right" : "left",
    }),
    option: (styles) => ({
      ...styles,
      color: "#989898",
      backgroundColor: "#f3f3f3",
    }),
    singleValue: (styles) => ({
      ...styles,
      // color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "100%",
      whiteSpace: "nowrap",
      textAlign: "left",
    }),
  };

  const handleFilterChange = (selected) => {
    setFilter(selected);
    if (selected.label === t("displayAll")) {
      dispatch(getAllRequestStatus());
    } else {
      const data = {
        type: "request",
        changestatusName: selected.value,
      };
      dispatch(getFilterRequestStatus(data));
    }
  };

  const changeTravelId = (e) => {
    setTravelId(e.target.value);
    if (e.target.value !== "" && e.target.value !== null)
      dispatch(getSearchByTravelId(e.target.value));
  };

  return (
    <div className="receive-offer-wrapper dir-ltr my-5" id="receive-offer-wrapper">
      {windowSize?.width >= 992 && (
        <Row className={`mt-2 mb-5 ${isPersian ? "dir-rtl":"dir-ltr"}`}>
          <Col xs={6} className="align-self-center">
            <h2 className="mt-2 mb-4">{t("latestSentRequest")}</h2>
          </Col>
          <Col xs={6} className="text-align-filter-dashboard">
            <DebounceInput
              minLength={1}
              debounceTimeout={100}
              onChange={changeTravelId}
              // onKeyDown={handleFlightKeyDown}
              placeholder={t("travelID")}
              value={travelId}
              className="custom-select-search-offer d-inline-block"
            />
            <Select
              className="custom-select-status-type d-inline-block"
              value={filter}
              onChange={handleFilterChange}
              placeholder={t("displayAll")}
              options={options}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={customStyle}
            />
          </Col>
        </Row>
      )}
      {windowSize?.width < 992 && (
        <Row
          className="my-4"
          style={{
            // width: "360px",
            marginRight: 0,
            marginLeft: "18px",
            alignItems: "center",
          }}
        >
          <Col xs={12}>
            <h2>{t("latestSentRequest")}</h2>
          </Col>
          <Col xs={6} sm={6} md={2} className="text-left mt-4">
            <DebounceInput
              minLength={1}
              debounceTimeout={100}
              onChange={changeTravelId}
              // onKeyDown={handleFlightKeyDown}
              placeholder={t("travelID")}
              value={travelId}
              className="custom-select-search-offer d-inline-block"
            />
          </Col>
          <Col xs={6} sm={6} md={2} className="text-left mt-3 pl-0">
            <Select
              className="custom-select-status-type d-inline-block"
              value={filter}
              onChange={handleFilterChange}
              placeholder={t("displayAll")}
              options={options}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={customStyle}
            />
          </Col>
        </Row>
      )}
      <PackageCard display={display} offerReceivedData={offerReceivedData} />
    </div>
  );
};
