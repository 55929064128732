/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useAppDispatch, useAppSelector } from "redux/store";
import { RightSidebar } from "layers";
import { useTranslation } from "react-i18next";

interface IProp {
  setShowDetail: (key: any) => void;
  setIsConfirmed: Function;
  fade: boolean;
  status: string;
}
export const ChangeStatusConfirm: React.FC<IProp> = ({
  setShowDetail,
  setIsConfirmed,
  fade,
  status,
}) => {
  const [showCover, setShowCover] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const handleShow = () => {
    setShowCover(!showCover);
    setShowDetail(showCover);
    setFadeOut(true);
  };

  const confirm = () => {
    setIsConfirmed(true);
    // setShowDetail(false);
  };

  const notConfirm = () => {
    setShowDetail(false);
    setIsConfirmed(false);
  };

  return (
    <div className="dashboard-more-detail-wrapper more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`receive-offer-card-wrapper mb-3 review-card-info-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Body className="request-card-border location-cover-info offer-card-cover-body">
          <Row className="detail-review-wrapper">
            <div className="review-question-text text-center">
              {isPersian ? (
                <span>آیا میخواهید وضعیت خود را به "{status}" تغییر دهید؟</span>
              ) : (
                <span>Do you want to change status to "{status}"?</span>
              )}
            </div>
            <div className="note-offer-detail mt-5 mx-auto">
              <Button
                variant="primary"
                className="confirm-review-btn"
                onClick={confirm}
              >
                {t("yes")}
              </Button>
              <Button
                variant="white"
                className="no-confirm-review-btn"
                onClick={notConfirm}
              >
                {t("no")}
              </Button>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
