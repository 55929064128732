import { createSlice } from "@reduxjs/toolkit";

export const proofSlice = createSlice({
  name: "proof",
  initialState: [],
  reducers: {
    proof: (state, action) => {
      return (state = action.payload);
    },
    proofFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { proof, proofFailure } = proofSlice.actions;
export default proofSlice.reducer;
