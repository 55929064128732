import { createSlice } from "@reduxjs/toolkit";

export const modifyTravelerAgreementSlice = createSlice({
  name: "modifyTravelerAgreement",
  initialState: [],
  reducers: {
    modifyTravelerAgreement: (state, action) => {
      return (state = action.payload);
    },
    modifyTravelerAgreementFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { modifyTravelerAgreement, modifyTravelerAgreementFailure } = modifyTravelerAgreementSlice.actions;
export default modifyTravelerAgreementSlice.reducer;