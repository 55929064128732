import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "layers";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getUserInfo } from "redux/actions/Authorization";
import { getAllCurrencies } from "redux/actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { getAllAnnouncement } from "redux/actions/notifications";
import { INotification } from "models/interfaces";
import { useTranslation } from "react-i18next";

export const NotificationsBox: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const token = window.localStorage.getItem("token");
  const [openTransactionIndex, setOpenTransactionIndex] = useState<
    number | null
  >(null);
  const [allAnnouncement, setAllAnnouncement] = useState<INotification[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(false);

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getAllCurrencies());
  }, [token]);

  window.addEventListener("storage", () => {
    window.localStorage.setItem("token", window.localStorage.getItem("token"));
  });
  const allNotification: any = useAppSelector((state) => state.allNotification);

  useEffect(() => {
    const data = {
      page: currentPage,
      pageSize: 10,
    };
    dispatch(getAllAnnouncement(data));
  }, [currentPage]);

  useEffect(() => {
    if (allNotification?.isSuccess) {
      if (currentPage > 1) {
        setAllAnnouncement([
          ...allAnnouncement,
          ...allNotification?.data?.data,
        ]);
      } else setAllAnnouncement(allNotification?.data?.data);
      if (allNotification.data.pageCount > 1) {
        setHasMoreData(true);
      }
    }
  }, [allNotification]);

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <>
      {allAnnouncement && allAnnouncement?.length !== 0 ? (
        <div className="transaction-page-wrapper">
          {allAnnouncement &&
            allAnnouncement?.map((item, index) => {
              const isTransactionOpen = openTransactionIndex === index;

              return (
                <div
                  key={index}
                  className="transaction-page-box"
                  onClick={() =>
                    setOpenTransactionIndex(isTransactionOpen ? null : index)
                  }
                >
                  <Row>
                    <Col xs={10}>
                      <span className="transaction-name d-block">
                        {item?.title}
                      </span>
                      <span className="transaction-date">
                        {item?.insertTime}
                      </span>
                    </Col>
                    <Col xs={2} className="transaction-amount-wrapper">
                      <FontAwesomeIcon
                        className="collapse-chevron"
                        icon={
                          isTransactionOpen ? faChevronDown : faChevronRight
                        }
                      />
                    </Col>
                  </Row>
                  {isTransactionOpen && (
                    <>
                      <Row className="drawer mt-2">
                        <Col xs={12} className="collapse-detail-transaction">
                          <a href={item.url}>{item.description}</a>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        <div className="no-data text-center pt-5 pb-4">{t("noData")}</div>
      )}
      {hasMoreData && (
        <Button className="transaction-load-more-btn" onClick={loadMore}>
          {t("loadMore")}
        </Button>
      )}
    </>
  );
};
