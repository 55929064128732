import React, { useState, useEffect } from "react";
import { Button } from "layers";
import { PackageCard } from "./PackageCard";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { IMyPackages } from "models/interfaces";
import { RightSidebar } from "layers";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  packagesData: IMyPackages[];
  setPkgId: Function;
}

export const MyPackages: React.FC<IProps> = ({ packagesData, setPkgId }) => {
  const size = UseWindowSize();
  const isMobile = size.width < 768;
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const openAddPackage = () => {
    setShowSidebar(!showSidebar);
    navigate("/dashboard/addPackage", { state: t("myPackages") });
  };

  return (
    <div className="dashboard-travels-info-wrapper" id="packages-info-wrapper">
      <div className={`mt-4 ${isMobile && "responsive-dashboard-title-width"}`}>
        <h2>{t("myPackages")}</h2>
        <Button
          variant="primary"
          data-test="docs-btn-anchor"
          onClick={openAddPackage}
          className="add-new-travel"
        >
          {t("addNew")}
        </Button>
      </div>
      <PackageCard packagesData={packagesData} setPkgId={setPkgId} />
      {showSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSidebar}
            setIsOpen={setShowSidebar}
            sidebarType="dashboardPackage"
            mode="add"
          />
        </div>
      )}
    </div>
  );
};
