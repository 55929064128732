import { createSlice } from "@reduxjs/toolkit";

export const chooseRequestSlice = createSlice({
  name: "chooseRequest",
  initialState: [],
  reducers: {
    chooseRequest: (state, action) => {
      return (state = action.payload);
    },
    chooseRequestFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { chooseRequest, chooseRequestFailure } = chooseRequestSlice.actions;
export default chooseRequestSlice.reducer;
