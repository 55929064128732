import { BaseService } from "./baseService";

class ChatService extends BaseService {
  userChat(data) {
    return this.authorizedHttp().post("/User/Chat", data);
  }
  userAllChat() {
    return this.authorizedHttp().get("/User/Chat/GetAll");
  }
  checkHasChat() {
    return this.authorizedHttp().get("/User/Chat/HasNewChat");
  }
  getRooms() {
    return this.authorizedHttp().get("/User/Chat/GetRooms");
  }
  getChatByTypeId(id) {
    return this.authorizedHttp().get(`/User/Chat/GetByTypeId/${id}`);
  }
  getMultiChatByTypeId(typeId, roomId) {
    return this.authorizedHttp().get(
      `/User/Chat/GetByTypeId/${typeId}/${roomId}`
    );
  }
  getChatCount() {
    return this.authorizedHttp().get(`/User/Chat/Count`);
  }
}

export default new ChatService();
