import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Col, Row } from "react-bootstrap";
import StripeImg from "../../assets/images/stripe.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { payment } from "redux/actions/stripe";
import { useAppDispatch, useAppSelector } from "redux/store";
import { Oval } from "react-loader-spinner";
import { ICheckout } from "models/interfaces";
import { TaxCountries } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IProp {
  isOpen: boolean;
  setIsOpen: Function;
  checkoutData: ICheckout;
  price: number;
  titleTab: string;
}
export const StripePayment: React.FC<IProp> = ({
  setIsOpen,
  isOpen,
  checkoutData,
  price,
  titleTab,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const [termsChecked, setTermsChecked] = useState(false);
  const [governmentChecked, setGovernmentChecked] = useState(false);
  const stripeResult: any = useAppSelector((state) => state.paymentResult);
  const [taxPercentage, setTaxPercentage] = useState(0);
  const [provinceTax, setProvinceTax] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [taxRateId, setTaxRateId] = useState("");
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const subtotal = (
    checkoutData?.operationCost +
    checkoutData?.commission +
    price
  ).toFixed(2);
  const [hasProvince, setHasProvince] = useState(false);
  const dispatch = useAppDispatch();

  const handleTermsCheckedChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleGovernmentChange = () => {
    setGovernmentChecked(!governmentChecked);
  };

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/dashboard", { state: t(`${titleTab}`) });
  };

  useEffect(() => {
    if (stripeResult?.isSuccess) {
      setIsPaymentLoading(false);
      window.location.href = stripeResult?.data?.redirectUrl;
    }
  }, [stripeResult]);

  const completeCheckout = () => {
    setIsPaymentLoading(true);
    const data = {
      id: checkoutData?.id,
      taxrateId: taxRateId,
    };
    dispatch(payment(data));
  };

  const calculateTaxPerProvince = () => {
    const tax: number = parseFloat(
      ((taxPercentage * checkoutData?.commission) / 100).toFixed(2)
    );
    setProvinceTax(tax);
  };

  const getTotalPrice = () => {
    setTotalPrice((parseFloat(subtotal) + provinceTax).toFixed(2));
  };

  useEffect(() => {
    calculateTaxPerProvince();
  }, [taxPercentage]);

  useEffect(() => {
    getTotalPrice();
  }, [provinceTax]);

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          navigate("/dashboard", { state: t(`${titleTab}`) });
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="payment-slider-container">
      <Row className="sidebar-content-wrapper">
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className="payment-form">
          <h1>{t("payment")}</h1>
        </Col>
        <Col xs={12} className="payment-form">
          <TaxCountries
            checkoutData={checkoutData}
            setHasProvince={setHasProvince}
            setTaxPercentage={setTaxPercentage}
            setTaxRateId={setTaxRateId}
          />
        </Col>
        <Col xs={12} className="payment-form mt-4">
          <div className="amount-deposit-wrapper">
            <span className="amount-deposit-title">
              {t("originalPrice")}:{" "}
              <span className="amount-deposit-value-option">
                {price} {checkoutData?.currency}
              </span>
            </span>
            <span className="amount-deposit-title">
              {t("operationsFee")}:{" "}
              <span className="amount-deposit-value-option">
                {checkoutData?.operationCost} {checkoutData?.currency}
              </span>
            </span>
            <span className="amount-deposit-title">
              {t("commissionFee")}:{" "}
              <span className="amount-deposit-value-option">
                {checkoutData?.commission} {checkoutData?.currency}
              </span>
            </span>
            <span className="amount-subtotal-title">
              {t("subtotal")}:{" "}
              <span className="amount-deposit-value-option">
                {subtotal} {checkoutData?.currency}
              </span>
            </span>
            <span className="amount-percentage-title">
              {`${
                hasProvince
                  ? `Tax(${taxPercentage}%)`
                  : isPersian
                  ? "مالیات"
                  : "Tax"
              }`}
              :{" "}
              <span
                className={`${
                  hasProvince
                    ? "amount-deposit-value-option"
                    : "amount-deposit-value-option-disabled"
                }`}
              >
                {hasProvince ? provinceTax : t("selectYourCountry")}{" "}
                {hasProvince ? checkoutData?.currency : ""}
              </span>
            </span>
            {isPersian ? (
              <span className="you-will-pay">پرداختی شما</span>
            ) : (
              <span className="you-will-pay">You will pay</span>
            )}

            <span
              className={`${
                hasProvince
                  ? "amount-deposit-value"
                  : "amount-deposit-value-disabled"
              }`}
            >
              {hasProvince ? totalPrice : parseFloat(subtotal)}{" "}
              {checkoutData?.currency}
            </span>
            {isPersian ? (
              <span className="amount-deposit-description mb-2">
                به کولبار می باشد. بنابراین به محض تایید فرستنده، پرداخت را
                دریافت می کنید
              </span>
            ) : (
              <span className="amount-deposit-description mb-2">
                to Koolbaar, so as soon as sender approve you will receive the
                payment.
              </span>
            )}
          </div>
        </Col>
      </Row>
      <Row className="sidebar-content-wrapper">
        <Col xs={12} className="payment-form mt-5">
          <div style={{ marginTop: "55px" }}>
            <label className="checkbox-container">
              {t("termConditionLinkText")}{" "}
              <a
                target="_blank"
                href="/termsCondition"
                style={{ textDecorationLine: "underline" }}
              >
                {t("termConditionLink")}{" "}
              </a>
              {t("termConditionLink2")}.
              <input
                type="checkbox"
                checked={termsChecked}
                onChange={handleTermsCheckedChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </Col>
        <Col xs={12} className="payment-form">
          <div>
            <label className="checkbox-container gov-checkbox-container">
              {t("acceptAllGovernmentTaxes")}
              <input
                type="checkbox"
                checked={governmentChecked}
                onChange={handleGovernmentChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </Col>
        <div style={{ marginTop: "24px" }}>
          <img className="ml-3" src={StripeImg} alt="stripe payment" />
          <Button
            variant="primary"
            className="deposit-request-btn"
            onClick={completeCheckout}
            disabled={!hasProvince || !termsChecked || !governmentChecked}
          >
            {t("completeCheckout")}{" "}
            {isPaymentLoading && (
              <Oval
                width="15"
                height="15"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "5px" }}
              />
            )}
          </Button>
        </div>
      </Row>
    </div>
  );
};
