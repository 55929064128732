import axios from "axios";

export class BaseService {
  authorizedHttp() {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Accept-Language": localStorage.getItem("language"),
        Currency: localStorage.getItem("currency"),
        "Time-Zone": localStorage.getItem("timezone"),
      },
    });
  }

  authorizedFormDataHttp() {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept-Language": localStorage.getItem("language"),
        Currency: localStorage.getItem("currency"),
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Time-Zone": localStorage.getItem("timezone"),
      },
    });
  }

  http() {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Accept-Language": localStorage.getItem("language"),
        Currency: localStorage.getItem("currency"),
        "Time-Zone": localStorage.getItem("timezone"),
      },
    });
  }

  currencyHttp() {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Accept-Language": localStorage.getItem("language"),
        "Time-Zone": localStorage.getItem("timezone"),
      },
    });
  }

  registerHttp() {
    return axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept-Language": localStorage.getItem("language"),
        Currency: localStorage.getItem("currency"),
        "Time-Zone": localStorage.getItem("timezone"),
      },
    });
  }
}
