import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  cancellationStatus,
  getAllDashboardData,
} from "redux/actions/dashboard";
import { Oval } from "react-loader-spinner";
import {
  getAllAnnouncement,
  getAllAnnouncementCount,
} from "redux/actions/notifications";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface IProp {
  id: string;
  titleTab: string;
  setIsOpen: Function;
  type: string;
  isOpen: boolean;
}

export const Cancellation: React.FC<IProp> = ({
  id,
  titleTab,
  setIsOpen,
  type,
  isOpen,
}) => {
  const size = UseWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [reason, setReason] = useState("");
  const [reasons, setReasons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [isSuccessContract, setIsSuccessContract] = useState(false);
  const dispatch = useAppDispatch();
  const [selectedReasonIds, setSelectedReasonIds] = useState([]);
  const cancellation: any = useAppSelector((state) => state.cancellation);
  const senderReasons: any = useAppSelector(
    (state) => state.senderReasonCancellation
  );
  const travelerReasons: any = useAppSelector(
    (state) => state.travelerReasonCancellation
  );

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const cancelRequest = () => {
    setIsLoading(true);
    let data;
    data = {
      id: id,
      reason: reason,
      cancellationreasonIds: selectedReasonIds.toString(),
    };
    dispatch(cancellationStatus(data));
    setIsSuccessContract(true);
  };

  useEffect(() => {
    if (!cancellation?.isSuccess) {
      setIsLoading(false);
    } else if (cancellation?.isSuccess && isSuccessContract) {
      document.documentElement.style.overflow = "auto";
      setIsLoading(false);
      setIsOpen(false);
      dispatch(getAllDashboardData());
      const notifyData = {
        page: 1,
        pageSize: 5,
      };
      dispatch(getAllAnnouncement(notifyData));
      dispatch(getAllAnnouncementCount());
      setIsSuccessContract(false);
      navigate("/dashboard", { state: t(`${titleTab}`) });
    }
  }, [cancellation]);

  useEffect(() => {
    if (type === "sender") {
      if (senderReasons?.isSuccess) {
        setReasons(senderReasons.data);
      }
    } else if (type === "traveler") {
      if (travelerReasons?.isSuccess) {
        setReasons(travelerReasons.data);
      }
    }
  }, [type, senderReasons, travelerReasons]);

  const handleCheckedChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedReasonIds((prevSelected) => [...prevSelected, value]);
      setIsShowMessage(true);
    } else {
      setSelectedReasonIds((prevSelected) =>
        prevSelected.filter((id) => id !== value)
      );
      setIsShowMessage(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          navigate("/dashboard", { state: t(`${titleTab}`) });
        }
      },
      false
    );
  }, []);

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/dashboard", { state: t(`${titleTab}`) });
  };

  return (
    <div className="request-slider-container">
      <Row className="request-wrapper">
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className="offer-form">
          <h1>{t("cancellation")}</h1>
          <div className="pickup-input-wrapper mb-2">
            <span>{t("chooseCancellationReason")}</span>
          </div>
          {reasons?.map((item) => {
            return (
              <div className="mt-1">
                <input
                  className="ml-0 mr-1 contract-radio-btn"
                  type="checkbox"
                  name={item.name}
                  value={item.id}
                  onChange={handleCheckedChange}
                />
                <span className="contract-content-radio">{item.name}</span>
              </div>
            );
          })}
        </Col>
        {/* {isShowMessage && ( */}
        <Col xs={12} className="offer-form mt-4">
          <div className="pickup-input-wrapper">
            <span>{t("describeReason")}</span>
          </div>
          <div>
            <Input
              size="sm"
              id="message"
              placeholder={t("describeReason")}
              className="custom-input-message"
              value={reason}
              onChange={handleReasonChange}
              textArea={true}
              rows={4}
            />
          </div>
        </Col>
        {/* )} */}

        <div style={{ marginTop: "24px" }}>
          <Button
            variant="primary"
            data-test="docs-btn-anchor"
            className="submit-request-btn mt-4"
            onClick={cancelRequest}
          >
            {t("submitTheRequest")}
            {isLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </div>
      </Row>
    </div>
  );
};
