import AgreementService from "services/agreementService";
import {
  agreementByTypeReceive,
  agreementByTypeReceiveFailure,
} from "redux/slices/agreement/agreementByTypeReceive";
import {
  agreementByTypeSend,
  agreementByTypeSendFailure,
} from "redux/slices/agreement/agreementByTypeSend";
import {
  allAgreementsStatus,
  allAgreementsStatusFailure,
} from "redux/slices/agreement/allAgreementsStatus";
import {
  modifySenderAgreement,
  modifySenderAgreementFailure,
} from "redux/slices/agreement/modifySenderAgreement";
import {
  modifyTravelerAgreement,
  modifyTravelerAgreementFailure,
} from "redux/slices/agreement/modifyTravelerAgreement";
import {
  approveSender,
  approveSenderFailure,
} from "redux/slices/agreement/approveSender";
import {
  approveTraveler,
  approveTravelerFailure,
} from "redux/slices/agreement/approveTraveler";
import { toast } from "react-toastify";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

export const getAllAgreementStatus = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AgreementService.getAllAgreementStatus();
    dispatch(allAgreementsStatus(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(allAgreementsStatusFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getSendAgreementByType = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AgreementService.getSendAgreementByType(id);
    dispatch(agreementByTypeSend(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(agreementByTypeSendFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getReceiveAgreementByType = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AgreementService.getReceiveAgreementByType(id);
    dispatch(agreementByTypeReceive(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(agreementByTypeReceiveFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const editSenderAgreement = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AgreementService.editSenderAgreement(data);
    dispatch(modifySenderAgreement(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(modifySenderAgreementFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const editTravelerAgreement = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AgreementService.editTravelerAgreement(data);
    dispatch(modifyTravelerAgreement(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(modifyTravelerAgreementFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const approveBySender = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AgreementService.approveBySender(data);
    dispatch(approveSender(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(approveSenderFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const approveByTraveler = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AgreementService.approveByTraveler(data);
    dispatch(approveTraveler(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(approveTravelerFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};
