import { BaseService } from "./baseService";

class LandingService extends BaseService {
  chooseRequest(data) {
    return this.authorizedFormDataHttp().post("/Request/Choose", data);
  }

  createRequest(data) {
    return this.authorizedFormDataHttp().post("/Request/New", data);
  }

  chooseOffer(data) {
    return this.authorizedFormDataHttp().post("/Offer/Choose", data);
  }

  createOffer(data) {
    return this.authorizedFormDataHttp().post("/Offer/New", data);
  }

  retrieveTravelPackage(data) {
    return this.authorizedHttp().post("/User/Retrieve", data);
  }

  getAllProfileById(id) {
    return this.authorizedHttp().get(`/Profile/GetById/${id}`);
  }

  getNewDashboard() {
    return this.authorizedHttp().get(`/user/Dashboard/HasNew`);
  }

  sendContactForm(data) {
    return this.http().post(`/ContactUs`, data);
  }

  filterByTypeId(data) {
    return this.http().post(`/HomeFilter/ByTypeId`, data);
  }

  changeLanguage(data) {
    return this.authorizedHttp().post(`/user/UserInfo/ChangeLanguage`, data);
  }

  getPackageById(id) {
    return this.http().get(`/Package/GetByPkgId/${id}`);
  }
  
  getTravelById(id) {
    return this.http().get(`/Travel/GetByTrvId/${id}`);
  }

  checkAvailabilityPackage() {
    return this.authorizedHttp().get("/User/Availability/Check/package");
  }
  
  checkAvailabilityTravel() {
    return this.authorizedHttp().get("/User/Availability/Check/travel");
  }
}

export default new LandingService();
