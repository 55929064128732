import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { Button, Input } from "layers";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import LogoImg from "assets/images/logo/koolbaar-logo-transparent.png";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  editTravelerAgreement,
  approveByTraveler,
} from "redux/actions/agreement";
import { Gallery } from "react-photoswipe-gallery";
import { LightBoxItem } from "components";
import { AddContractItems } from "components/common/addContractItems";
import { AddTravelingCompanion } from "components/common/addTravelingCompanion";
import { Oval } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import { getDate } from "helpers/convertDate";
import Select from "react-select";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  disableContract: boolean;
  setIsOpen: (key: any) => void;
  contractId: string;
  titleTab: string;
  data: any;
}

export const TravelerContract: React.FC<IProps> = ({
  isOpen,
  disableContract,
  setIsOpen,
  contractId,
  titleTab,
  data,
}) => {
  const handleClose = () => setIsOpen(false);
  const windowSize = UseWindowSize();
  const isMobileTablet = windowSize.width < 992;
  const isTablet = windowSize.width >= 768 && windowSize.width < 992;
  const isMobile = windowSize.width < 768;
  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [isApproved, setIsApproved] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [travelerDepartureDate, setTravelerDepartureDate] = useState(null);
  const [travelerArrivalDate, setTravelerArrivalDate] = useState(null);
  const [senderDeadline, setSenderDeadline] = useState(null);
  const [fromDate1, setFromDate1] = useState(getDate(new Date()));
  const [fromDate2, setFromDate2] = useState(getDate(new Date()));
  const [isDownloaded, setIsDownloaded] = useState(false);
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [totalWeightUnit, setTotalWeightUnit] = useState({
    value: "kg",
    label: "KG",
  });
  const [weightUnit, setWeightUnit] = useState({
    value: "kg",
    label: "KG",
  });
  const [currenciesOption, setCurrenciesOption] = useState([]);
  const [contractData, setContractData] = useState({
    travelerName: "",
    travelerDepartureAddress: "",
    travelerArrivalAddress: "",
    travelerPhone: "",
    travelerEmail: "",
    travelerWhatsapp: "",
    travelerTypeIdentity: "",
    travelerIdentityDocumentNo: "",
    travelerCityCountryDeparture: "",
    travelerCityCountryArrival: "",
    travelerOfferWeight: "",
    travelerWeightShipper: "",
    offerPrice: "",
    senderName: "",
    senderDepartureAddress: "",
    // totalValue: "",
    totalWeight: "",
    otherAgreementDetail: "",
    senderPhone: "",
    senderEmail: "",
    senderWhatsapp: "",
    senderIdentityDocumentNo: "",
    senderRecipientAddress: "",
    senderTypeIdentity: "",
    senderRecipientFullName: "",
    senderRecipientPhone: "",
    senderRecipientWhatsapp: "",
    detail: "",
  });
  const [isChangedTravelerName, setIsChangedTravelerName] = useState(false);
  const [expensesIds, setExpensesIds] = useState([]);
  const isChangedSenderName = false;
  const isChangedDepartureSenderAddress = false;
  const isChangedSenderPhone = false;
  const isChangedSenderEmail = false;
  const [
    isChangedDepartureTravelerAddress,
    setIsChangedDepartureTravelerAddress,
  ] = useState(false);
  const [isChangedArrivalTravelerAddress, setIsChangedArrivalTravelerAddress] =
    useState(false);
  const [isChangedTravelerPhone, setIsChangedTravelerPhone] = useState(false);
  const [isChangedTravelerWhatsapp, setIsChangedTravelerWhatsapp] =
    useState(false);
  const [isChangedTravelerEmail, setIsChangedTravelerEmail] = useState(false);
  const [isChangedTravelerTypeIdentity, setIsChangedTravelerTypeIdentity] =
    useState(false);
  const [
    isChangedTravelerIdentityDocumentNo,
    setIsChangedTravelerIdentityDocumentNo,
  ] = useState(false);
  const [
    isChangedTravelerCityCountryDeparture,
    setIsChangedTravelerCityCountryDeparture,
  ] = useState(false);
  const [
    isChangedTravelerCityCountryArrival,
    setIsChangedTravelerCityCountryArrival,
  ] = useState(false);
  const [isChangedTravelerOfferWeight, setIsChangedTravelerOfferWeight] =
    useState(false);
  const [isChangedTravelerWeightShipper, setIsChangedTravelerWeightShipper] =
    useState(false);
  const [isChangedOfferPrice, setIsChangedOfferPrice] = useState(false);
  const [isChangedDetail, setIsChangedDetail] = useState(false);
  const [cancellationPolicyOptions, setCancellationPolicyOptions] = useState(
    []
  );
  const isChangedSenderWhatsapp = false;
  const isChangedSenderTypeIdentity = false;
  const isChangedSenderIdentityDocumentNo = false;
  const isChangedSenderRecipientFullName = false;
  const isChangedSenderRecipientAddress = false;
  const isChangedSenderRecipientPhone = false;
  const isChangedSenderRecipientWhatsapp = false;
  // const isChangedTotalValue = false;
  const isChangedTotalWeight = false;

  const [expensesOptions, setExpensesOptions] = useState([]);
  const [editClicked, setEditClicked] = useState(false);
  const approveTravelerData: any = useAppSelector(
    (state) => state.approveTraveler
  );
  const receivedData: any = data.data;
  const [hasTravelingCompanion, setHasTravelingCompanion] = useState(false);
  const [hasItemsToBuy, setHasItemsToBuy] = useState(false);
  const [agreementTermsOptions, setAgreementTermsOptions] = useState([]);
  const { t } = useTranslation();
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (receivedData?.sender) {
      setTravelingCompanions(receivedData?.sender?.travelingcompanions);
      setContractItems(receivedData?.sender?.itemtobuys);
      setHasItemsToBuy(
        receivedData?.sender?.itemtobuys?.length !== 0 ? true : false
      );
      setAgreementTermsOptions(receivedData?.sender?.agreementterms);
      setHasTravelingCompanion(receivedData?.sender?.isTravelingCompanion);
    }
    if (receivedData?.traveler) {
      const updatedExpensesOptions = receivedData.traveler.extraExpenses.map(
        (item) => ({
          ...item,
          isChecked: item.isChecked,
        })
      );
      setExpensesOptions(updatedExpensesOptions);

      setCancellationPolicyOptions(
        receivedData?.traveler?.cancellationPloicies
      );
    }
  }, [receivedData]);

  const modifyTravelerData: any = useAppSelector(
    (state) => state.modifyTravelerAgreement
  );

  useEffect(() => {
    if (receivedData?.traveler) {
      setFromDate1(receivedData?.traveler?.departureDate);
      setFromDate2(receivedData?.traveler?.arrivalDate);
      setCurrency({
        value: receivedData?.traveler?.currencyType,
        label: receivedData?.traveler?.currencyType?.toUpperCase(),
      });
      setTotalWeightUnit({
        value: receivedData?.sender?.totalWeightUnit,
        label: receivedData?.sender?.totalWeightUnit?.toUpperCase(),
      });
      setWeightUnit({
        value: receivedData?.traveler?.weightUnit,
        label: receivedData?.traveler?.weightUnit?.toUpperCase(),
      });
      setContractData({
        ...contractData,
        travelerName: receivedData?.traveler?.fullName,
        travelerDepartureAddress: receivedData?.traveler?.departureAddress,
        travelerArrivalAddress: receivedData?.traveler?.arrivalAddress,
        travelerPhone: receivedData?.traveler?.phoneNumber,
        travelerEmail: receivedData?.traveler?.email,
        travelerWhatsapp: receivedData?.traveler?.whatsapp,
        travelerTypeIdentity: receivedData?.traveler?.identityType,
        travelerIdentityDocumentNo: receivedData?.traveler?.identityNumber,
        travelerCityCountryDeparture: receivedData?.traveler?.fromCountryCity,
        travelerCityCountryArrival: receivedData?.traveler?.toCountryCity,
        travelerOfferWeight: receivedData?.traveler?.weightOffered,
        travelerWeightShipper: receivedData?.traveler?.weightBooked,
        offerPrice: receivedData?.traveler?.offerPrice,
      });
    }
  }, [receivedData]);

  const downloadContract = () => {
    setIsDownloaded(true);
  };

  useEffect(() => {
    if (isDownloaded) {
      const input = document.getElementById("pdfdiv");

      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });
        let position = 0;
        doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25);
          heightLeft -= pageHeight;
        }
        setTimeout(() => {
          doc.save("contract.pdf");
        }, 200);
      });
    }
  }, [isDownloaded]);

  const [contractItems, setContractItems] = useState<any>([
    {
      designation: "",
      quantity: "",
      value: "",
      price: "",
      currencyType: "",
      weight: "",
      weightUnit: "",
      link: "",
    },
  ]);

  const [travelingCompanions, setTravelingCompanions] = useState<any>([
    {
      firstName: "",
      lastName: "",
      gender: "",
      age: "",
      cellPhoneNumber: "",
    },
  ]);

  useEffect(() => {
    if (
      travelingCompanions?.find((item) => {
        return (
          item.firstName === "" &&
          item.lastName === "" &&
          item.gender === "" &&
          item.age === "" &&
          item.phone === ""
        );
      })
    )
      setHasTravelingCompanion(false);
    else setHasTravelingCompanion(true);
  }, [travelingCompanions]);

  const adjustInputSize = (text, id) => {
    const nameLocation = document.getElementById(id);
    if (text?.length < 1) {
      nameLocation.style.width =
        getTextSize(nameLocation.ariaLabel, "16px sans-serif") + 10 + "px";
    } else {
      nameLocation.style.width =
        getTextSize(text, "16px sans-serif") + 10 + "px";
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setContractData({ ...contractData, [e.target.name]: e.target.value });
    adjustInputSize(e.target.value, e.target.name);
    setIsChangedTravelerName(true);
    setIsChangedDepartureTravelerAddress(true);
    setIsChangedArrivalTravelerAddress(true);
    setIsChangedTravelerPhone(true);
    setIsChangedTravelerEmail(true);
    setIsChangedTravelerWhatsapp(true);
    setIsChangedTravelerTypeIdentity(true);
    setIsChangedTravelerIdentityDocumentNo(true);
    setIsChangedTravelerCityCountryDeparture(true);
    setIsChangedTravelerCityCountryArrival(true);
    setIsChangedTravelerOfferWeight(true);
    setIsChangedTravelerWeightShipper(true);
    setIsChangedOfferPrice(true);
    setIsChangedDetail(true);
  };

  const currencies = useAppSelector((state) => state.currencies);

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];

  useEffect(() => {
    if (isChangedSenderName === false) adjustInputSize("", "senderName");
    if (isChangedDepartureSenderAddress === false)
      adjustInputSize("", "senderDepartureAddress");
    if (isChangedSenderPhone === false) adjustInputSize("", "senderPhone");
    if (isChangedSenderEmail === false) adjustInputSize("", "senderEmail");
    if (isChangedSenderWhatsapp === false)
      adjustInputSize("", "senderWhatsapp");
    if (isChangedSenderTypeIdentity === false)
      adjustInputSize("", "senderTypeIdentity");
    if (isChangedSenderIdentityDocumentNo === false)
      adjustInputSize("", "senderIdentityDocumentNo");
    if (isChangedSenderRecipientFullName === false)
      adjustInputSize("", "senderRecipientFullName");
    if (isChangedSenderRecipientAddress === false)
      adjustInputSize("", "senderRecipientAddress");
    if (isChangedSenderRecipientPhone === false)
      adjustInputSize("", "senderRecipientPhone");
    if (isChangedTravelerName === false) adjustInputSize("", "travelerName");
    if (isChangedSenderRecipientWhatsapp === false)
      adjustInputSize("", "senderRecipientWhatsapp");
    // if (isChangedTotalValue === false) adjustInputSize("", "totalValue");
    if (isChangedTotalWeight === false) adjustInputSize("", "totalWeight");
    // if (isChangedOtherAgreementDetail === false)
    //   adjustInputSize("", "otherAgreementDetail");
    if (isChangedDepartureTravelerAddress === false)
      adjustInputSize("", "travelerDepartureAddress");
    if (isChangedArrivalTravelerAddress === false)
      adjustInputSize("", "travelerArrivalAddress");
    if (isChangedTravelerPhone === false) adjustInputSize("", "travelerPhone");
    if (isChangedTravelerWhatsapp === false)
      adjustInputSize("", "travelerWhatsapp");
    if (isChangedTravelerEmail === false) adjustInputSize("", "travelerEmail");
    if (isChangedTravelerTypeIdentity === false)
      adjustInputSize("", "travelerTypeIdentity");
    if (isChangedTravelerIdentityDocumentNo === false)
      adjustInputSize("", "travelerIdentityDocumentNo");
    if (isChangedTravelerCityCountryDeparture === false)
      adjustInputSize("", "travelerCityCountryDeparture");
    if (isChangedTravelerCityCountryArrival === false)
      adjustInputSize("", "travelerCityCountryArrival");
    if (isChangedTravelerOfferWeight === false)
      adjustInputSize("", "travelerOfferWeight");
    if (isChangedTravelerWeightShipper === false)
      adjustInputSize("", "travelerWeightShipper");
    if (isChangedOfferPrice === false) adjustInputSize("", "offerPrice");
    if (isChangedDetail === false) adjustInputSize("", "detail");
  }, [contractData]);

  function getTextSize(txt, font) {
    let element = document.createElement("canvas");
    let context = element.getContext("2d");
    context.font = font;
    return context.measureText(txt).width;
  }

  const preventTypeTextAndDot = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventTypeText = (e) => {
    const validInputRegex = /^[0-9.]$/; // Allow digits and dot
    const currentValue = e.target.value + e.key;
    if (validInputRegex.test(e.key) || e.key === "Backspace") {
      const regex = /^(?!1000(\.0)?$)\d{1,3}(\.\d{0,1})?$/;
      if (regex.test(currentValue) || currentValue === "") {
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const edit = () => {
    setEditClicked(true);
  };

  const submit = () => {
    setIsOpenApprove(true);
  };

  useEffect(() => {
    const data = {
      typeId: contractId,
      departureAddress: contractData.travelerDepartureAddress,
      arrivalAddress: contractData.travelerArrivalAddress,
      whatsapp: contractData.travelerWhatsapp,
      identityType: contractData.travelerTypeIdentity,
      identityNumber: contractData.travelerIdentityDocumentNo,
      departureDate: fromDate1,
      arrivalDate: fromDate2,
      weightOffered: contractData.travelerOfferWeight,
      weightBooked: contractData.travelerWeightShipper,
      weightUnit: weightUnit?.value ? weightUnit.value : "kg",
      offerPrice: contractData.offerPrice,
      expenseIds: expensesIds.toString(),
      expensesOther: "",
      detail: contractData.detail,
    };
    if (isApproved) {
      setIsLoading(true);
      dispatch(editTravelerAgreement(data));
    }
  }, [isApproved]);

  useEffect(() => {
    if (!modifyTravelerData?.isSuccess) {
      setIsApproved(false);
    } else if (modifyTravelerData?.isSuccess) {
      document.documentElement.style.overflow = "auto";
      setIsApproved(false);
      dispatch(approveByTraveler(contractId));
      setIsOpen(false);
      setIsOpenApprove(false);
      navigate("/dashboard", { state: t(`${titleTab}`) });
    }
    setIsLoading(false);
  }, [modifyTravelerData]);

  const changeExpenses = (event) => {
    const value = event.target.value;
    const isChecked = event.target.checked;
    updateCheckboxValue(event.target.value, event.target.checked);

    if (isChecked) {
      setExpensesIds((prevSelectedExpenses) => [
        ...prevSelectedExpenses,
        value,
      ]);
    } else {
      setExpensesIds((prevSelectedExpenses) =>
        prevSelectedExpenses.filter((item) => item !== value)
      );
    }
  };

  const updateCheckboxValue = (itemId, checked) => {
    const updatedExpensesOptions = expensesOptions.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          isChecked: checked,
        };
      }
      return item;
    });
    setExpensesOptions(updatedExpensesOptions);
  };

  // useEffect(() => {
  //   // Example: Change checkbox with value '2' to checked
  //   changeCheckboxValue('2', true);
  // }, []);

  useEffect(() => {
    if (approveTravelerData?.isSuccess) {
      window.location.reload();
    } else setIsLoading(false);
  }, [approveTravelerData]);

  const confirm = () => {
    setIsApproved(true);
    setIsOpenApprove(false);
  };

  const notConfirm = () => {
    setIsApproved(false);
    setIsOpenApprove(false);
  };

  const onDepartureDateChange = (date) => {
    setTravelerDepartureDate(date);
    setFromDate1(getDate(date));
  };

  const onArrivalDateChange = (date) => {
    setTravelerArrivalDate(date);
    setFromDate2(getDate(date));
  };

  const onSenderDeadlineChange = (date) => {
    setSenderDeadline(date);
  };

  const handleTotalWeightUnitChange = (selected) => {
    setTotalWeightUnit(selected);
  };

  const handleWeightUnitChange = (selected) => {
    setWeightUnit(selected);
  };

  const handleCurrencyChange = (selected) => {
    setCurrency(selected);
  };

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      border: "transparent",
      backgroundColor: isDisabled ? "#efefef !important" : "white",
      textAlign: "center",
    }),
    option: (styles) => ({
      ...styles,
      color: "#707070",
      backgroundColor: "#fff",
      flexWrap: "nowrap",
      fontWeight: "normal",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: "-5px",
    }),
  };

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/dashboard", { state: t(`${titleTab}`) });
  };

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "fade contract-modal-wrapper modal show") {
          document.documentElement.style.overflow = "auto";
          navigate("/dashboard", { state: t(`${titleTab}`) });
        }
      },
      false
    );
  }, []);

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      {isOpenApprove && (
        <Modal
          id="pdfdiv"
          className="direct-modal-wrapper"
          show={isOpen}
          onHide={handleClose}
        >
          <Row className="detail-contract-wrapper">
            <div className="review-question-text text-center">
              <span>
                Please review your details carefully. Changes cannot be made
                once submitted.
              </span>
            </div>
            <div className="note-offer-detail mt-5 mx-auto">
              <Button
                variant="primary"
                className="confirm-contract-btn"
                onClick={confirm}
              >
                {t("approve")}
              </Button>
              <Button
                variant="white"
                className="no-confirm-contract-btn"
                onClick={notConfirm}
              >
                {t("cancel")}
              </Button>
            </div>
          </Row>
        </Modal>
      )}
      <Modal
        ref={dropdownRef}
        id="pdfdiv"
        className="contract-modal-wrapper"
        show={isOpen}
        onHide={handleClose}
      >
        <Button
          variant="white"
          className="close-agreement-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Modal.Body>
          <Row className="mt-4">
            <Col xs={12} sm={12} md={12} lg={7} className="ml-0">
              <a href="/" className="contract-logo-img-wrapper">
                <img src={LogoImg} alt="logo" className="contract-logo-img" />
              </a>
              <span
                className={`agreement-title-contract ${
                  isMobileTablet && "d-block w-100 my-3"
                }`}
              >
                Agreement between Traveler and Sender
              </span>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className="text-right">
              {!isDownloaded && (
                <Button
                  variant="primary"
                  className="download-contract-btn"
                  onClick={downloadContract}
                >
                  Download
                </Button>
              )}
              {!disableContract && !isDownloaded && (
                <Button
                  className="edit-contract-btn"
                  onClick={editClicked ? submit : edit}
                >
                  {editClicked ? t("submitRequest") : t("edit")}
                  {isLoading && editClicked && (
                    <Oval
                      width="20"
                      height="20"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                    />
                  )}
                </Button>
              )}
            </Col>
          </Row>
          <Row className="ml-0">
            <span className={`contract-title ${isDownloaded && "mt-5"}`}>
              Traveler
            </span>
          </Row>
          <Row className="ml-0 mt-4 contract-content-wrapper">
            <p className="contract-content">
              -Mr./Ms. <span className="star-require">*</span>
              <Input
                size="sm"
                id="travelerName"
                name="travelerName"
                ariaLabel={
                  receivedData?.traveler?.fullName
                    ? `${receivedData?.traveler.fullName}`
                    : "................"
                }
                placeholder={
                  receivedData?.traveler?.fullName
                    ? `${receivedData?.traveler.fullName}`
                    : "................"
                }
                className="custom-input-contract"
                value={contractData.travelerName}
                onChange={handleChange}
                disabled={!editClicked}
              />{" "}
            </p>
            <p className="contract-content">
              - Address country of departure
              <span className="star-require">*</span> :
              <Input
                size="sm"
                id="travelerDepartureAddress"
                name="travelerDepartureAddress"
                ariaLabel={
                  receivedData?.traveler?.departureAddress
                    ? `${receivedData?.traveler.departureAddress}`
                    : "--------------------------"
                }
                placeholder={
                  receivedData?.traveler?.departureAddress
                    ? `${receivedData?.traveler.departureAddress}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                value={contractData.travelerDepartureAddress}
                onChange={handleChange}
                disabled={!editClicked}
              />
              - Address country of arrival
              <span className="star-require">*</span> :
              <Input
                size="sm"
                id="travelerArrivalAddress"
                name="travelerArrivalAddress"
                ariaLabel={
                  receivedData?.traveler?.arrivalAddress
                    ? `${receivedData?.traveler.arrivalAddress}`
                    : "--------------------------"
                }
                placeholder={
                  receivedData?.traveler?.arrivalAddress
                    ? `${receivedData?.traveler.arrivalAddress}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                value={contractData.travelerArrivalAddress}
                onChange={handleChange}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              - Phone<span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="travelerPhone"
                name="travelerPhone"
                ariaLabel={
                  receivedData?.traveler?.phoneNumber
                    ? `${receivedData?.traveler.phoneNumber}`
                    : "--------------------------"
                }
                placeholder={
                  receivedData?.traveler?.phoneNumber
                    ? `${receivedData?.traveler.phoneNumber}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                value={contractData.travelerPhone}
                onChange={handleChange}
                onKeyPress={preventTypeTextAndDot}
                disabled
              />{" "}
              - Whatsapp:{" "}
              <Input
                size="sm"
                id="travelerWhatsapp"
                name="travelerWhatsapp"
                ariaLabel={
                  receivedData?.traveler?.whatsapp
                    ? `${receivedData?.traveler.whatsapp}`
                    : "----------------"
                }
                placeholder={
                  receivedData?.traveler?.whatsapp
                    ? `${receivedData?.traveler.whatsapp}`
                    : "----------------"
                }
                className="custom-input-contract"
                value={contractData.travelerWhatsapp}
                onKeyPress={preventTypeTextAndDot}
                onChange={handleChange}
                disabled={!editClicked}
              />{" "}
              - email<span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="travelerEmail"
                name="travelerEmail"
                ariaLabel={
                  receivedData?.traveler?.email
                    ? `${receivedData?.traveler.email}`
                    : "--------------------------"
                }
                placeholder={
                  receivedData?.traveler?.email
                    ? `${receivedData?.traveler.email}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                value={contractData.travelerEmail}
                onChange={handleChange}
                disabled
              />
            </p>
            <p className="contract-content">
              - Identity Document Type
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="travelerTypeIdentity"
                name="travelerTypeIdentity"
                ariaLabel={
                  receivedData?.traveler?.identityType
                    ? `${receivedData?.traveler.identityType}`
                    : "(For International Traveling Passport is Mandatory for the Traveler)"
                }
                placeholder={
                  receivedData?.traveler?.identityType
                    ? `${receivedData?.traveler.identityType}`
                    : "(For International Traveling Passport is Mandatory for the Traveler)"
                }
                className="custom-mandatory-contract"
                value={contractData.travelerTypeIdentity}
                onChange={handleChange}
                disabled={!editClicked}
              />{" "}
              - Identity Document Number
              {/* <span className="star-require">*</span> :{" "} */}
              <Input
                size="sm"
                id="travelerIdentityDocumentNo"
                name="travelerIdentityDocumentNo"
                ariaLabel={
                  receivedData?.traveler?.identityNumber
                    ? `${receivedData?.traveler.identityNumber}`
                    : "-----------"
                }
                placeholder={
                  receivedData?.traveler?.identityNumber
                    ? `${receivedData?.traveler.identityNumber}`
                    : "-----------"
                }
                className="custom-input-contract"
                value={contractData.travelerIdentityDocumentNo}
                onChange={handleChange}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              - Country/city of departure
              <span className="star-require">*</span>{" "}
              <Input
                size="sm"
                id="travelerCityCountryDeparture"
                name="travelerCityCountryDeparture"
                ariaLabel={
                  receivedData?.traveler?.fromCountryCity
                    ? `${receivedData?.traveler.fromCountryCity}`
                    : "............................."
                }
                placeholder={
                  receivedData?.traveler?.fromCountryCity
                    ? `${receivedData?.traveler.fromCountryCity}`
                    : "............................."
                }
                className="custom-country-contract"
                value={contractData.travelerCityCountryDeparture}
                onChange={handleChange}
                disabled={!editClicked}
              />{" "}
              - Country/city of arrival
              <span className="star-require">*</span>
              <Input
                size="sm"
                id="travelerCityCountryArrival"
                name="travelerCityCountryArrival"
                ariaLabel={
                  receivedData?.traveler?.toCountryCity
                    ? `${receivedData?.traveler.toCountryCity}`
                    : "............................."
                }
                placeholder={
                  receivedData?.traveler?.toCountryCity
                    ? `${receivedData?.traveler.toCountryCity}`
                    : "............................."
                }
                className="custom-country-contract"
                value={contractData.travelerCityCountryArrival}
                onChange={handleChange}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              - Departure date<span className="star-require">*</span>{" "}
              <DatePicker
                className="custom-input-date-contract"
                id="travelerDepartureDate"
                name="travelerDepartureDate"
                ariaLabel={
                  receivedData?.traveler?.departureDate
                    ? `${receivedData?.traveler.departureDate}`
                    : "............................."
                }
                placeholderText={
                  receivedData?.traveler?.departureDate
                    ? `${receivedData?.traveler.departureDate}`
                    : "............................."
                }
                selected={travelerDepartureDate}
                onChange={(date) => onDepartureDateChange(date)}
                dateFormat="EEEE, MM/dd/yyyy"
                showTimeInput
                minDate={new Date()}
                disabled={!editClicked}
              />
              - Arrival date<span className="star-require">*</span>
              <DatePicker
                className="custom-input-date-contract"
                id="travelerArrivalDate"
                name="travelerArrivalDate"
                ariaLabel={
                  receivedData?.traveler?.arrivalDate
                    ? `${receivedData?.traveler.arrivalDate}`
                    : "............................."
                }
                placeholderText={
                  receivedData?.traveler?.arrivalDate
                    ? `${receivedData?.traveler.arrivalDate}`
                    : "............................."
                }
                selected={travelerArrivalDate}
                onChange={(date) => onArrivalDateChange(date)}
                dateFormat="EEEE, MM/dd/yyyy"
                showTimeInput
                minDate={new Date()}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              -Number of Kg offered by the traveler{" "}
              <Input
                size="sm"
                id="travelerOfferWeight"
                name="travelerOfferWeight"
                ariaLabel={
                  receivedData?.traveler?.weightOffered
                    ? `${receivedData?.traveler.weightOffered}`
                    : "..................."
                }
                placeholder={
                  receivedData?.traveler?.weightOffered
                    ? `${receivedData?.traveler.weightOffered}`
                    : "..................."
                }
                className="custom-input-contract"
                value={contractData.travelerOfferWeight}
                onChange={handleChange}
                onKeyPress={preventTypeText}
                disabled={!editClicked}
              />{" "}
              <Select
                id="weightUnit"
                className="custom-input-weight-unit-contract d-inline-block"
                options={unitWeightOption}
                value={weightUnit}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleWeightUnitChange}
                isDisabled={!editClicked}
                styles={unitCustomStyle}
                placeholder={
                  receivedData?.traveler?.weightUnit
                    ? receivedData?.traveler?.weightUnit
                    : "KG"
                }
              />
              -Number of Kg booked by the shipper{" "}
              <Input
                size="sm"
                id="travelerWeightShipper"
                name="travelerWeightShipper"
                ariaLabel={
                  receivedData?.traveler?.weightBooked
                    ? `${receivedData?.traveler.weightBooked}`
                    : "..................."
                }
                placeholder={
                  receivedData?.traveler?.weightBooked
                    ? `${receivedData?.traveler.weightBooked}`
                    : "..................."
                }
                className="custom-input-contract"
                value={contractData.travelerWeightShipper}
                onChange={handleChange}
                onKeyPress={preventTypeText}
                disabled={!editClicked}
              />
              <Select
                id="weightUnit"
                className="custom-input-weight-unit-contract d-inline-block"
                options={unitWeightOption}
                value={weightUnit}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleWeightUnitChange}
                isDisabled={!editClicked}
                styles={unitCustomStyle}
                placeholder={
                  receivedData?.traveler?.weightUnit
                    ? `${receivedData?.traveler?.weightUnit}`
                    : "KG"
                }
              />
            </p>
            <p className="contract-content">
              - The price of the service has been fixed at an amount of
              <span className="star-require">*</span>{" "}
              <Input
                size="sm"
                id="offerPrice"
                name="offerPrice"
                ariaLabel={
                  receivedData?.traveler?.offerPrice
                    ? `${receivedData?.traveler.offerPrice}`
                    : "..................."
                }
                placeholder={
                  receivedData?.traveler?.offerPrice
                    ? `${receivedData?.traveler.offerPrice}`
                    : "..................."
                }
                className="custom-input-contract"
                value={contractData.offerPrice}
                onChange={handleChange}
                disabled
                onKeyPress={preventTypeTextAndDot}
              />
              <Select
                id="currency"
                className="custom-input-weight-unit-contract d-inline-block"
                options={currenciesOption}
                value={currency}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleCurrencyChange}
                isDisabled
                styles={unitCustomStyle}
                placeholder={
                  receivedData?.traveler?.currencyType
                    ? `${receivedData?.traveler?.currencyType}`
                    : "CAD"
                }
              />
              that will be paid by Koolbaar after delivery"
            </p>
            {/* Expense */}
            <p className="contract-content">* Including:</p>
            {expensesOptions?.map((item) => {
              return (
                <div key={item.id}>
                  <input
                    className="ml-0 mr-1 contract-radio-btn"
                    type="checkbox"
                    name="site_name"
                    value={item.id}
                    checked={item.isChecked}
                    onChange={changeExpenses}
                    disabled={!editClicked}
                    ref={item.isChecked ? checkboxRef : null}
                  />
                  <span className="contract-content-radio">{item.name}</span>
                </div>
              );
            })}
            <p className="contract-content">
              Cancellation policy (Will be Filled by the Traveler)
            </p>
            {/* cancellationPolicy */}
            {cancellationPolicyOptions?.map((item) => {
              return (
                <>
                  <input
                    className="ml-0 mr-1 contract-radio-btn"
                    type="radio"
                    name="cancellationPolicy"
                    value={item.id}
                    checked={item.isChecked}
                    disabled
                  />
                  <span className="contract-content-radio">{item.name}</span>
                </>
              );
            })}
            <p className="contract-content">
              -Detail:{" "}
              <Input
                size="sm"
                id="detail"
                name="detail"
                ariaLabel={
                  receivedData?.traveler?.detail
                    ? `${receivedData?.traveler.detail}`
                    : `${
                        isMobileTablet
                          ? "------------------------"
                          : "----------------------------------------------------------------"
                      }`
                }
                placeholder={
                  receivedData?.traveler?.detail
                    ? `${receivedData?.traveler.detail}`
                    : `${
                        isMobileTablet
                          ? "------------------------"
                          : "----------------------------------------------------------------"
                      }`
                }
                className="custom-input-detail-contract"
                value={contractData.detail}
                onChange={handleChange}
                disabled={!editClicked}
              />
            </p>
          </Row>
          {/* {receivedData?.images?.length !== 0 && (
            <>
              <p className="contract-content">Images: </p>
              <Row className="image-card-wrapper">
                <Gallery>
                  {receivedData?.images?.map((item) => {
                    return (
                      <div className="p-0 mt-3 mr-3">
                        <LightBoxItem
                          original={item}
                          thumbnail={item}
                          width="70px"
                          height="70px"
                        />
                      </div>
                    );
                  })}
                </Gallery>
              </Row>
            </>
          )} */}
          <Row className="ml-0 mt-5 contract-content-wrapper">
            <span className="contract-title">Sender</span>
          </Row>
          <Row className="ml-0 mt-4 contract-content-wrapper">
            <p className="contract-content">
              -Mr./Ms.<span className="star-require">*</span>{" "}
              <Input
                size="sm"
                id="senderName"
                name="senderName"
                ariaLabel={
                  receivedData?.sender?.fullName
                    ? `${receivedData?.sender.fullName}`
                    : "................"
                }
                placeholder={
                  receivedData?.sender?.fullName
                    ? `${receivedData?.sender.fullName}`
                    : "................"
                }
                value={receivedData?.sender?.fullName}
                className="custom-input-contract"
                disabled
              />
            </p>
            <p className="contract-content">
              - Address country of departure
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderDepartureAddress"
                name="senderDepartureAddress"
                ariaLabel={
                  receivedData?.sender?.departureAddress
                    ? `${receivedData?.sender.departureAddress}`
                    : "--------------------------"
                }
                placeholder={
                  receivedData?.sender?.departureAddress
                    ? `${receivedData?.sender.departureAddress}`
                    : "--------------------------"
                }
                value={receivedData?.sender?.departureAddress}
                className="custom-input-contract"
                disabled
              />{" "}
            </p>
            <p className="contract-content">
              - Phone<span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderPhone"
                name="senderPhone"
                value={receivedData?.sender?.phoneNumber}
                ariaLabel={
                  receivedData?.sender?.phoneNumber
                    ? `${receivedData?.sender.phoneNumber}`
                    : "--------------------------"
                }
                placeholder={
                  receivedData?.sender?.phoneNumber
                    ? `${receivedData?.sender.phoneNumber}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                onKeyPress={preventTypeTextAndDot}
                disabled
              />{" "}
              - Whatsapp:{" "}
              <Input
                size="sm"
                id="senderWhatsapp"
                name="senderWhatsapp"
                value={receivedData?.sender?.whatsapp}
                ariaLabel={
                  receivedData?.sender?.whatsapp
                    ? `${receivedData?.sender.whatsapp}`
                    : "----------------"
                }
                placeholder={
                  receivedData?.sender?.whatsapp
                    ? `${receivedData?.sender.whatsapp}`
                    : "----------------"
                }
                className="custom-input-contract"
                onKeyPress={preventTypeTextAndDot}
                disabled
              />{" "}
              - email<span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderEmail"
                name="senderEmail"
                value={receivedData?.sender?.email}
                ariaLabel={
                  receivedData?.sender?.email
                    ? `${receivedData?.sender.email}`
                    : "--------------------------"
                }
                placeholder={
                  receivedData?.sender?.email
                    ? `${receivedData?.sender.email}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                disabled
              />
            </p>
            <p className="contract-content">
              - Identity Document Type
              <span className="star-require">*</span> :
              <Input
                size="sm"
                id="senderTypeIdentity"
                name="senderTypeIdentity"
                value={receivedData?.sender?.identityType}
                ariaLabel={
                  receivedData?.sender?.identityType
                    ? `${receivedData?.sender.identityType}`
                    : "(For International Traveling Passport is Mandatory for the Traveler)"
                }
                placeholder={
                  receivedData?.sender?.identityType
                    ? `${receivedData?.sender.identityType}`
                    : "(For International Traveling Passport is Mandatory for the Traveler)"
                }
                className="custom-input-contract"
                disabled
              />{" "}
              - Identity Document Number
              {/* <span className="star-require">*</span> :{" "} */}
              <Input
                size="sm"
                id="senderIdentityDocumentNo"
                name="senderIdentityDocumentNo"
                value={receivedData?.sender?.identityNumber}
                ariaLabel={
                  receivedData?.sender?.identityNumber
                    ? `${receivedData?.sender.identityNumber}`
                    : "-----------"
                }
                placeholder={
                  receivedData?.sender?.identityNumber
                    ? `${receivedData?.sender.identityNumber}`
                    : "-----------"
                }
                className="custom-input-contract"
                disabled
              />
            </p>
            <p className="contract-content">
              Recipient&apos;s Full Name
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderRecipientFullName"
                name="senderRecipientFullName"
                ariaLabel={
                  receivedData?.sender?.recipientFullName
                    ? `${receivedData?.sender.recipientFullName}`
                    : "-----------"
                }
                placeholder={
                  receivedData?.sender?.recipientFullName
                    ? `${receivedData?.sender.recipientFullName}`
                    : "-----------"
                }
                value={receivedData?.sender?.recipientFullName}
                className="custom-input-contract"
                disabled
              />
            </p>
            <p className="contract-content">
              - Recipient&apos;s Address
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderRecipientAddress"
                name="senderRecipientAddress"
                value={receivedData?.sender?.recipientAddress}
                ariaLabel={
                  receivedData?.sender?.recipientAddress
                    ? `${receivedData?.sender.recipientAddress}`
                    : "-----------------------"
                }
                placeholder={
                  receivedData?.sender?.recipientAddress
                    ? `${receivedData?.sender.recipientAddress}`
                    : "-----------------------"
                }
                className="custom-input-address-contract"
                disabled
              />{" "}
              (If the traveler is supposed to either Drop off or Post the
              package to the Recipient)
            </p>
            <p className="contract-content">
              - Recipient&apos;s phone
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderRecipientPhone"
                name="senderRecipientPhone"
                value={receivedData?.sender?.recipientPhone}
                ariaLabel={
                  receivedData?.sender?.recipientPhone
                    ? `${receivedData?.sender.recipientPhone}`
                    : "-----------"
                }
                placeholder={
                  receivedData?.sender?.recipientPhone
                    ? `${receivedData?.sender.recipientPhone}`
                    : "-----------"
                }
                onKeyPress={preventTypeTextAndDot}
                className="custom-input-contract"
                disabled
              />{" "}
              - Recipient&apos;s Whatsapp :{" "}
              <Input
                size="sm"
                id="senderRecipientWhatsapp"
                name="senderRecipientWhatsapp"
                value={receivedData?.sender?.recipientWhatsapp}
                ariaLabel={
                  receivedData?.sender?.recipientWhatsapp
                    ? `${receivedData?.sender.recipientWhatsapp}`
                    : "-----------"
                }
                placeholder={
                  receivedData?.sender?.recipientWhatsapp
                    ? `${receivedData?.sender.recipientWhatsapp}`
                    : "-----------"
                }
                className="custom-input-contract"
                onKeyPress={preventTypeTextAndDot}
                disabled
              />
            </p>
            <p className="contract-content">
              (To be filled in if the recipient i.e. the one who receives the
              package, is different from the sender)
            </p>
            <p className="contract-content">
              - Deadline for delivery/Post
              <span className="star-require">*</span> :{" "}
              <DatePicker
                className="custom-input-date-contract"
                id="senderDeadline"
                name="senderDeadline"
                ariaLabel={
                  receivedData?.sender?.shippingDeadline
                    ? `${receivedData?.sender.shippingDeadline}`
                    : "-----------"
                }
                placeholderText={
                  receivedData?.sender?.shippingDeadline
                    ? `${receivedData?.sender.shippingDeadline}`
                    : "-----------"
                }
                selected={senderDeadline}
                onChange={(date) => onSenderDeadlineChange(date)}
                dateFormat="EEEE, MM/dd/yyyy"
                showTimeInput
                minDate={new Date()}
                disabled
              />
            </p>
            <AddContractItems
              contractItems={contractItems}
              setContractItems={setContractItems}
              disabled
            />
            <AddTravelingCompanion
              travelingCompanions={travelingCompanions}
              setTravelingCompanions={setTravelingCompanions}
              disabled
            />
            <p className="contract-content">
              {/* Total Value:{" "}
              <Input
                size="sm"
                id="totalValue"
                name="totalValue"
                ariaLabel={
                  receivedData?.sender?.totalValue
                    ? `${receivedData?.sender.totalValue}`
                    : "(Sum of all Values)"
                }
                placeholder={
                  receivedData?.sender?.totalValue
                    ? `${receivedData?.sender.totalValue}`
                    : "(Sum of all Values)"
                }
                value={receivedData?.sender?.totalValue}
                className="custom-input-total-contract"
                disabled
              />
              <Select
                id="currency"
                className="custom-input-weight-unit-contract d-inline-block"
                options={currenciesOption}
                // value={currency}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                isDisabled
                styles={unitCustomStyle}
                placeholder={
                  receivedData?.sender?.currencyType
                    ? `${receivedData?.sender?.currencyType}`
                    : "CAD"
                }
              /> */}
              Total Weight:{" "}
              <Input
                size="sm"
                id="totalWeight"
                name="totalWeight"
                ariaLabel={
                  receivedData?.sender?.totalWeight
                    ? `${receivedData?.sender.totalWeight}`
                    : "(Sum of all Weights)"
                }
                placeholder={
                  receivedData?.sender?.totalWeight
                    ? `${receivedData?.sender.totalWeight}`
                    : "(Sum of all Weights)"
                }
                className="custom-input-total-weight-contract"
                value={receivedData?.sender?.totalWeight}
                onChange={handleChange}
                disabled
              />
              <Select
                id="totalWeightUnit"
                className="custom-input-weight-unit-contract d-inline-block"
                options={unitWeightOption}
                value={totalWeightUnit}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleTotalWeightUnitChange}
                isDisabled
                styles={unitCustomStyle}
                placeholder={
                  receivedData?.sender?.totalWeightUnit
                    ? `${receivedData?.sender?.totalWeightUnit}`
                    : "KG"
                }
              />
            </p>
            {/* agreement */}
            {agreementTermsOptions?.map((item) => {
              return (
                <div className="d-block w-100">
                  <input
                    className="ml-0 mr-1 contract-radio-btn"
                    type="radio"
                    name="agreementTerm"
                    value={item.id}
                    checked={item.isChecked}
                    disabled
                  />
                  <span className="contract-content-radio">{item.name}</span>
                </div>
              );
            })}
            {/* {isAgreementOther && ( */}
            <p className="contract-content">
              Detail:{" "}
              {/* <Input
                size="sm"
                id="otherAgreementDetail"
                name="otherAgreementDetail"
                value={receivedData?.sender?.agreementtermOther}
                ariaLabel={
                  receivedData?.sender?.agreementtermOther
                    ? `${receivedData?.sender.agreementtermOther}`
                    : "ex: Traveling companion. Mr. John accompanies my father from Toronto to Dubai."
                }
                placeholder={
                  receivedData?.sender?.agreementtermOther
                    ? `${receivedData?.sender.agreementtermOther}`
                    : "ex: Traveling companion. Mr. John accompanies my father from Toronto to Dubai."
                }
                className="custom-input-other-detail-contract"
                disabled
              /> */}
              <span className="custom-input-other-detail-contract">
                {receivedData?.sender.agreementtermOther
                  ? receivedData?.sender.agreementtermOther
                  : "-----------"}
              </span>
            </p>
            {/* )} */}
          </Row>
          {receivedData?.sender?.images?.length !== 0 && (
            <>
              <p className="contract-content">{t("images")}: </p>
              <Row className="image-card-wrapper">
                <Gallery>
                  {receivedData?.sender?.images?.map((item) => {
                    return (
                      <div className="p-0 mt-3 mr-3">
                        <LightBoxItem
                          original={item}
                          thumbnail={item}
                          width="70px"
                          height="70px"
                        />
                      </div>
                    );
                  })}
                </Gallery>
              </Row>
            </>
          )}
          <Row>
            <Col xs={12} sm={12} md={12} lg={4} className="signature-wrapper">
              <span>Traveler Signature</span>
              <div></div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} className="signature-wrapper">
              <span>Witness Signature (Optional)</span>
              <div></div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} className="signature-wrapper">
              <span>Sender Signature</span>
              <div></div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
