import { createSlice } from "@reduxjs/toolkit";

export const allNotificationSlice = createSlice({
  name: "allNotification",
  initialState: [],
  reducers: {
    allNotification: (state, action) => {
      return (state = action.payload);
    },
    allNotificationFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allNotification, allNotificationFailure } =
  allNotificationSlice.actions;
export default allNotificationSlice.reducer;
