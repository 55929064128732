import { BaseService } from "./baseService";

class AgreementService extends BaseService {
  getAllAgreementStatus() {
    return this.authorizedHttp().get("/user/Agreementstatus/All");
  }

  getSendAgreementByType(id) {
    return this.authorizedHttp().get(`/user/Agreement/GetByTypeId/${id}`);
  }

  getReceiveAgreementByType(id) {
    return this.authorizedHttp().get(`/user/Agreement/GetByTypeId/${id}`);
  }

  editTravelerAgreement(data) {
    return this.authorizedHttp().put("/user/Agreement/Traveler", data);
  }

  editSenderAgreement(data) {
    return this.authorizedHttp().put("/user/Agreement/Sender", data);
  }

  approveByTraveler(id) {
    return this.authorizedHttp().put(`/user/Agreement/ApproveByTraveler/${id}`);
  }

  approveBySender(id) {
    return this.authorizedHttp().put(`/user/Agreement/ApproveBySender/${id}`);
  }
}

export default new AgreementService();
