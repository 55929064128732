import React, { useState, useEffect } from "react";
import { Row, Col, Card, Dropdown, OverlayTrigger } from "react-bootstrap";
import { RightSidebar } from "layers";
import { Button } from "layers";
import { PackageCover } from "./PackageCover";
import { MoreDetail } from "./MoreDetail";
import PlaneIcon from "../../assets/images/plane.png";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import {
  IChatData,
  IChatEngineData,
  ICheckout,
  IOfferSent,
  Message,
} from "models/interfaces";
import { useAppDispatch, useAppSelector } from "redux/store";
import { ProofDelivery } from "./ProofDelivery";
import { ReviewConfirm, ChangeStatusConfirm, DenyConfirm } from "components";
import {
  alterOfferStatus,
  getAllDashboardData,
  getProof,
  proofDocument,
  alterButtonStatus,
  alterButtonToPaymentOfferStatus,
  getDisapprovalReason,
  cancellationReasonForSender,
  cancellationReasonForTraveler,
  getAllDashboardProfile,
} from "redux/actions/dashboard";
import { Oval } from "react-loader-spinner";
import ChatImage from "assets/images/chat.png";
import { useTranslation } from "react-i18next";
import { Chat } from "components/modals/Chat";
import { SenderContract } from "components/modals/SenderContract";
import { IButton } from "models/interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { getSendAgreementByType } from "redux/actions/agreement";
import { TravelerContract } from "components/modals/TravelerContract";
import { ProofMultipleUploader } from "components/common/proofDropZone";
import { checkTokenValidation } from "redux/actions/Authorization";
import { getChatByTypeId, getUserChat } from "redux/actions/chat";
import {
  getAllAnnouncement,
  getAllAnnouncementCount,
} from "redux/actions/notifications";
import { useNavigate } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import { SingleChatList } from "components/singleUserChat";
import { toast } from "react-toastify";

interface IProps {
  data: IOfferSent;
}

export const Cards: React.FC<IProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const isTraveler = data.isMine;
  const [checkoutData, setCheckoutData] = useState<ICheckout>();
  const [isOpenStripeCheckout, setIsOpenStripeCheckout] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [roomId, setRoomId] = useState<string>("");
  const [contractInfo, setContractInfo] = useState([]);
  const [isClickedChat, setIsClickedChat] = useState(false);
  const [showProblemSidebar, setShowProblemSidebar] = useState(false);
  const [showCancelSidebar, setShowCancelSidebar] = useState(false);
  const [showProofDelivery, setShowProofDelivery] = useState(false);
  const [showMoreDetail, setShowMoreDetail] = useState(false);
  const [showTimelineDetail, setShowTimelineDetail] = useState(false);
  const [showStatusBox, setShowStatusBox] = useState(false);
  const [fade, setFade] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isProofLoading, setIsProofLoading] = useState(false);
  const [isOtherLoading, setIsOtherLoading] = useState(false);
  const [isOpenDropzone, setIsOpenDropzone] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [isCalledApi, setIsCalledApi] = useState(false);
  const [price, setPrice] = useState<number>();
  const [chatEngineData, setChatEngineData] = useState<IChatEngineData>();
  const [packageData, setPackageData] = useState<IChatData>();
  const windowSize = UseWindowSize();
  const isMobile = windowSize.width < 768;
  const isTablet = windowSize.width < 992;
  const allStatus = useAppSelector((state) => state?.getChangedStatus);
  const proof: any = useAppSelector((state) => state?.proof);
  const [contractClicked, setContractClicked] = useState(false);
  const changeStatusData = useAppSelector((state) => state?.changeOfferStatus);
  const changeBtnStatus: any = useAppSelector(
    (state) => state?.changeButtonStatus
  );
  const changeBtnStatusPaymentOffer: any = useAppSelector(
    (state) => state?.changeButtonPaymentStatusOffer
  );
  const [isOpenChatModal, setIsOpenChatModal] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);
  const [isOpenSentModal, setIsOpenSentModal] = useState(false);
  const [moreDetailButton, setMoreDetailButton] = useState<IButton>();
  const [reportButton, setReportButton] = useState<IButton>();
  const [secondButton, setSecondButton] = useState<IButton>();
  const [thirdButton, setThirdButton] = useState<IButton>();
  const [otherButtons, setOtherButtons] = useState<IButton[]>();
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [isCloseBtn, setIsCloseBtn] = useState(false);
  const [isOpenReviewConfirm, setIsOpenReviewConfirm] = useState(false);
  const [isOpenDenyConfirm, setIsOpenDenyConfirm] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [disableContract, setDisableContract] = useState(false);
  const [reviewId, setReviewId] = useState("");
  const [reviewOwner, setReviewOwner] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isDenyConfirmed, setIsDenyConfirmed] = useState(false);
  const [isOpenDisapproval, setIsOpenDisapproval] = useState(false);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);
  const [confirmId, setConfirmId] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [confirmName, setConfirmName] = useState("");
  const [lastUpdated, setLastUpdated] = useState("...");
  const proofById: any = useAppSelector((state) => state?.proofById);
  const isValidToken = useAppSelector<any>((state) => state.validationToken);
  const chatData = useAppSelector<any>((state) => state.userChat);
  let token = window.localStorage.getItem("token");
  const navigate = useNavigate();
  const contractData: any = useAppSelector(
    (state) => state?.agreementByTypeSend
  );
  const [latestMessages, setLatestMessages] = useState<Message[]>([]);
  const chatByTypeId: any = useAppSelector((state) => state.chatByTypeId);

  useEffect(() => {
    if (isClickedChat) {
      if (chatByTypeId?.isSuccess) {
        setLatestMessages(chatByTypeId?.data?.messages);
        setRoomId(chatByTypeId?.data?.roomdId);
      } else if (!chatByTypeId?.isSuccess) {
        toast.error(chatByTypeId?.message);
        setIsOpenChatModal(false);
      }
    }
  }, [chatByTypeId]);

  useEffect(() => {
    if (chatByTypeId?.isSuccess && isClickedChat) {
      setIsOpenChatModal(true);
    }
  }, [chatByTypeId, isClickedChat]);

  const fromTooltip = (
    <Tooltip id="fromCity">
      <span>
        Fr{t("from")}om: {data?.fromCountryCity}
      </span>
    </Tooltip>
  );
  const toTooltip = (
    <Tooltip id="toCity">
      <span>
        {t("to")}: {data?.toCountryCity}
      </span>
    </Tooltip>
  );

  useEffect(() => {
    const typeData = {
      typeId: data.offId,
    };
    if (isClickedChat && isValidToken?.length !== 0) {
      if (isValidToken?.isSuccess) {
        dispatch(getUserChat(typeData));
        setIsCalledApi(true);
      }
    }
  }, [isValidToken, isClickedChat]);

  useEffect(() => {
    if (isClickedChat && chatData?.length !== 0) {
      if (chatData?.isSuccess && isCalledApi) {
        if (chatData.data?.senderFullName !== chatData.data?.travelerFullName) {
          // setIsOpenChatModal(true);
        }
        setChatEngineData(chatData.data.chatengine);
        setPackageData(chatData.data);
        setIsClickedChat(false);
        setIsCalledApi(false);
      }
    }
  }, [chatData, isClickedChat, isOpenChatModal]);

  const handleTimelineDetail = () => {
    setShowTimelineDetail(true);
    setShowMoreDetail(false);
    setFade(true);
  };

  const handleMoreDetail = () => {
    setShowMoreDetail(true);
    setShowTimelineDetail(false);
    setFade(true);
  };

  useEffect(() => {
    if (images[0]) {
      setIsOpenDropzone(false);
      setShowProofDelivery(true);
      setImages(images);
    }
  }, [images]);

  const showUpdateStatus = () => {
    setShowStatusBox(!showStatusBox);
  };

  useEffect(() => {
    if (proofById.isSuccess) {
      setLastUpdated(proofById.data.lastUpdated);
    }
  }, [proofById]);

  const handleProblemSidebar = () => {
    setShowProblemSidebar(!showProblemSidebar);
    navigate("/dashboard/report-problem", { state: t("latestSentOffer") });
  };

  const handleCancelSidebar = (isMine) => {
    setShowCancelSidebar(!showCancelSidebar);
    const type = isMine ? "traveler" : "sender";
    setShowCancelSidebar(!showCancelSidebar);
    if (type === "sender") dispatch(cancellationReasonForSender());
    else if (type === "traveler") dispatch(cancellationReasonForTraveler());
    navigate("/dashboard/cancellation", { state: t("latestSentOffer") });
  };

  const handleProofDelivery = (id) => {
    setImages([]);
    setFiles([]);
    setShowProofDelivery(!showProofDelivery);
    setFade(!fade);
    dispatch(getProof(id));
  };

  const changeStatus = (offId, changestatusId) => {
    setIsStatusLoading(true);
    const data = { offId, changestatusId };
    dispatch(alterOfferStatus(data));
  };

  useEffect(() => {
    if (changeStatusData) {
      setIsStatusLoading(false);
      setIsRejectLoading(false);
      setShowStatusBox(false);
    }
  }, [changeStatusData]);

  useEffect(() => {
    if (proof.isSuccess) {
      setIsProofLoading(false);
      setIsOpenDropzone(false);
      setShowProofDelivery(false);
    } else {
      setIsProofLoading(false);
    }
  }, [proof]);

  const openChatModal = () => {
    setIsOpenChatModal(false);

    const tokenData = {
      accessToken: token,
    };
    setIsClickedChat(true);
    dispatch(checkTokenValidation(tokenData));
    dispatch(getChatByTypeId(data.offId));
  };

  const openUploader = () => {
    setIsOpenDropzone(true);
    setShowProofDelivery(false);
    setIsCloseBtn(false);
  };

  useEffect(() => {
    if (isCloseBtn) {
      setIsOpenDropzone(false);
      setShowProofDelivery(true);
    }
  }, [isCloseBtn]);

  const openContractModal = () => {
    setDisableContract(true);
    dispatch(getSendAgreementByType(data.offId));
    setContractClicked(true);
  };

  const openEditContractModal = () => {
    setDisableContract(false);
    dispatch(getSendAgreementByType(data.offId));
    setContractClicked(true);
  };

  useEffect(() => {
    setContractClicked(false);
    if (contractClicked) {
      if (contractData?.isSuccess) {
        setContractInfo(contractData);
        setIsOpenSentModal(true);
        navigate(`/dashboard/${data.offId}`);
      } else if (!contractData?.isSuccess) {
        setIsOpenSentModal(false);
      }
    }
  }, [contractData]);

  useEffect(() => {
    setMoreDetailButton(
      data.buttons?.find((btn) => {
        return isPersian ? btn.text === "جزئیات" : btn.text === "Detail";
      })
    );
    const includesConfirmDeliveryDeny = data.buttons.some(
      (item) => item.code === "ConfirmDeliveryDeny"
    );

    if (data.isMine) {
      // if isMine is true means the owner is Traveler
      // if isMine is false means the owner is Sender
      setSecondButton(
        includesConfirmDeliveryDeny
          ? data.buttons?.find((btn) => {
              if (btn.code === "Accept") return btn;
              else if (btn.code === "Cancel") return btn;
              else if (btn.code === "ConfirmDeliveryDeny") return btn;
              else if (btn.code === "ViewEditContract") return btn;
              else if (btn.code === "RateTheSender") return btn;
              else return;
            })
          : data.buttons?.find((btn) => {
              if (btn.code === "Accept") return btn;
              else if (btn.code === "Cancel") return btn;
              else if (
                isPersian
                  ? btn.text === "مشاهده قرارداد" && btn.code !== "PickedUp"
                  : btn.text === "View Contract" && btn.code !== "PickedUp"
              )
                return btn;
              else if (
                isPersian
                  ? btn.text === "مشاهده قرارداد" && btn.code !== "Purchased"
                  : btn.text === "View Contract" && btn.code !== "Purchased"
              )
                return btn;
              else if (
                isPersian
                  ? btn.text === "مشاهده قرارداد" && btn.code !== "Active"
                  : btn.text === "View Contract" && btn.code !== "Active"
              )
                return btn;
              else if (btn.code === "ViewEditContract") return btn;
              else if (btn.code === "RateTheSender") return btn;
              else return;
            })
      );

      setThirdButton(
        data.buttons?.find((btn) => {
          if (btn.code === "Reject") return btn;
          else if (
            isPersian
              ? btn.text === "مشاهده قرارداد" && btn.code === "PickedUp"
              : btn.text === "View Contract" && btn.code === "PickedUp"
          )
            return btn;
          else if (
            isPersian
              ? btn.text === "مشاهده قرارداد" && btn.code === "Purchased"
              : btn.text === "View Contract" && btn.code === "Purchased"
          )
            return btn;
          else if (
            isPersian
              ? btn.text === "مشاهده قرارداد" && btn.code === "Active"
              : btn.text === "View Contract" && btn.code === "Active"
          )
            return btn;
          else if (btn.code === "IAmStillInterested") return btn;
          else if (btn.code === "ProofOfDelivery") return btn;
          else if (btn.code === "DeliveredPosted") return btn;
          else return;
        })
      );
    } else {
      setSecondButton(
        includesConfirmDeliveryDeny
          ? data.buttons?.find((btn) => {
              if (btn.code === "Accept") return btn;
              else if (btn.code === "Cancel") return btn;
              else if (btn.code === "ConfirmDeliveryDeny") return btn;
              else return;
            })
          : data.buttons?.find((btn) => {
              if (btn.code === "Accept") return btn;
              else if (btn.code === "Cancel") return btn;
              else if (
                isPersian
                  ? btn.text === "مشاهده قرارداد" && btn.code !== "Active"
                  : btn.text === "View Contract" && btn.code !== "Active"
              )
                return btn;
              else return;
            })
      );

      setThirdButton(
        data.buttons?.find((btn) => {
          if (btn.code === "Reject") return btn;
          else if (btn.code === "IAmStillInterested") return btn;
          else if (
            isPersian
              ? btn.text === "مشاهده قرارداد" && btn.code === "Active"
              : btn.text === "View Contract" && btn.code === "Active"
          )
            return btn;
          else if (btn.code === "ViewEditContract") return btn;
          else if (btn.code === "ProofOfDelivery") return btn;
          else if (btn.code === "Payment") return btn;
          else if (btn.code === "RateTheTraveler") return btn;
          else return;
        })
      );
    }

    if (data.isMine) {
      setOtherButtons(
        data.buttons?.filter((btn) => {
          return (
            btn.code !== "Report" &&
            btn.code !== "More" &&
            btn.code !== "Accept" &&
            btn.code !== "Reject" &&
            btn.code !== "Cancel" &&
            btn.code !== "ViewContract" &&
            btn.code !== "ConfirmDeliveryDeny" &&
            btn.code !== "ViewEditContract" &&
            btn.code !== "IAmStillInterested" &&
            btn.code !== "ProofOfDelivery" &&
            btn.code !== "DeliveredPosted" &&
            btn.code !== "RateTheSender"
          );
        })
      );
    } else {
      setOtherButtons(
        data.buttons?.filter((btn) => {
          return (
            btn.code !== "Report" &&
            btn.code !== "More" &&
            btn.code !== "Accept" &&
            btn.code !== "Reject" &&
            btn.code !== "Cancel" &&
            btn.code !== "ViewContract" &&
            btn.code !== "ConfirmDeliveryDeny" &&
            btn.code !== "ViewEditContract" &&
            btn.code !== "IAmStillInterested" &&
            btn.code !== "ProofOfDelivery" &&
            btn.code !== "Payment" &&
            btn.code !== "RateTheTraveler"
          );
        })
      );
    }

    setReportButton(
      data.buttons?.find((btn) => {
        return isPersian ? btn.text === "گزارش" : btn.text === "Report";
      })
    );
  }, [data]);

  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  const submitProofOfDelivery = (id) => {
    setIsProofLoading(true);
    const body = new FormData();
    body.append("Id", id);
    for (const file of files) {
      // var newFile = new File([file], file.name);
      // var newFile = new File([file], `image${Math.floor((Math.random() * 100) + 1).toString()}`, {
      //   type: file.type,
      //   lastModified: file.lastModified,
      // });
      body.append("Files", file);
    }
    dispatch(proofDocument(body));
  };

  const rateReview = (id, owner) => {
    setIsOpenReviewConfirm(true);
    setReviewId(id);
    setReviewOwner(owner);
  };

  const openConfirmDelivery = (id, owner) => {
    setIsOpenDenyConfirm(true);
    setReviewId(id);
    setReviewOwner(owner);
    dispatch(getDisapprovalReason());
  };

  useEffect(() => {
    if (isDenyConfirmed) setIsOpenDenyConfirm(false);
  }, [isDenyConfirmed]);

  useEffect(() => {
    if (!isOpenDisapproval) setIsOpenDenyConfirm(false);
  }, [isOpenDisapproval]);

  const handleOtherBtnChange = (id, code, name) => {
    setIsOpenConfirm(true);
    setConfirmId(id);
    setConfirmCode(code);
    setConfirmName(name);
  };

  // const handlePaymentChange = (id, code, name, method) => {
  //   setConfirmId(id);
  //   setConfirmCode(code);
  //   setConfirmName(name);
  //   if (method === "By Koolbaar after delivery") {
  //     setHasStripePayment(true);
  //     setIsOpenConfirm(false);
  //     setIsPaymentLoading(true);
  //   } else {
  //     setHasStripePayment(false);
  //     setIsOpenConfirm(true);
  //   }
  // };

  useEffect(() => {
    setPrice(parseInt(data.offerPrice));
    if (isConfirmed && confirmCode === "Payment") {
      const data = {
        id: confirmId,
        buttonCode: confirmCode,
      };
      {
        // setIsOpenStripeCheckout(false);
        dispatch(alterButtonToPaymentOfferStatus(data));
      }
    } else if (isConfirmed && confirmCode !== "Payment") {
      const data = {
        id: confirmId,
        buttonCode: confirmCode,
      };
      dispatch(alterButtonStatus(data));
    }
  }, [isConfirmed]);

  useEffect(() => {
    if (isDenyConfirmed) {
      const data = {
        id: reviewId,
        buttonCode: "DeliveryConfirmedBySender",
      };
      dispatch(alterButtonStatus(data));
    }
  }, [isDenyConfirmed]);

  useEffect(() => {
    if (changeBtnStatus.isSuccess) {
      setIsOtherLoading(false);
      setIsOpenConfirm(false);
      setIsConfirmed(false);
      dispatch(getAllDashboardData());
      const notifyData = {
        page: 1,
        pageSize: 5,
      };
      dispatch(getAllAnnouncement(notifyData));
      dispatch(getAllAnnouncementCount());
    } else {
      setIsOtherLoading(false);
      setIsOpenConfirm(false);
    }
  }, [changeBtnStatus]);

  useEffect(() => {
    if (changeBtnStatusPaymentOffer?.isSuccess && isConfirmed) {
      setIsOtherLoading(false);
      setIsOpenConfirm(false);
      setIsConfirmed(false);
      dispatch(getAllDashboardData());
      const notifyData = {
        page: 1,
        pageSize: 5,
      };
      dispatch(getAllAnnouncement(notifyData));
      dispatch(getAllAnnouncementCount());
    } else {
      setIsOpenConfirm(false);
      setIsOtherLoading(false);
    }
    if (changeBtnStatusPaymentOffer?.data?.totalAmount && isConfirmed) {
      setIsOpenStripeCheckout(true);
      setCheckoutData(changeBtnStatusPaymentOffer?.data);
      navigate("/dashboard/payment", { state: t("latestSentOffer") });
    }
  }, [changeBtnStatusPaymentOffer]);

  const openProfileSidebar = () => {
    setOpenProfile(!openProfile);
    dispatch(getAllDashboardProfile(data.offId));
    navigate(`/dashboard/${data.offId}`);
  };

  return (
    <Col
      key={data?.owner}
      lg={6}
      md={12}
      sm={12}
      className={`${windowSize.width < 768 && "p-0"}`}
      style={
        isMobile
          ? { width: "355px" }
          : isTablet
          ? { marginTop: "0" }
          : { marginTop: "-27px", marginBottom: "4rem" }
      }
      data-testid="container"
    >
      {isOpenDropzone ? (
        <ProofMultipleUploader
          title=""
          setPhotos={setImages}
          photos={images}
          disabled={false}
          description="Drop proof of delivery images here or click to upload"
          height="h-266"
          width="w-100"
          setIsCloseBtn={setIsCloseBtn}
          type="proof of delivery"
        />
      ) : (
        <Card className="receive-offer-card-wrapper contract-modal-wrapper">
          <Card.Body className="card-received-body">
            <Row className={`${isMobile ? "pr-2" : ""}`}>
              <Col xs={2} className="card-receive-side-info">
                <div className="header-card-titles">
                  <div>
                    <OverlayTrigger placement="top" overlay={toTooltip}>
                      <span className="text-center cursor-pointer offer-header-card-titles">
                        {data.toCity}
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <span className="text-center offer-header-card-date">
                      {data.fromDate2}
                    </span>
                  </div>
                </div>
                <div className="header-card-plane rotate-plane-wrapper">
                  <div>
                    <span className="mb-0">
                      --
                      <img
                        src={PlaneIcon}
                        className="card-request-icon"
                        alt="location-img"
                      />
                      --
                    </span>
                  </div>
                </div>
                <div className="header-card-titles pt-2">
                  <div>
                    <OverlayTrigger placement="top" overlay={fromTooltip}>
                      <span className="text-center cursor-pointer offer-header-card-titles">
                        {data.fromCity}
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <span className="text-center offer-header-card-date">
                      {data.fromDate1}
                    </span>
                  </div>
                </div>
              </Col>
              <Col xs={7} className="receive-body-info">
                <h3 className="received-card-label mt-1 mb-2">
                  {t("packages")}: <span>{data.packagetypes}</span>
                </h3>
                <h3 className="received-card-label mt-1 mb-2">
                  {t("size")}: <span>{data?.size}</span>
                </h3>
                <h3 className="received-card-label mt-1 mb-2">
                  {t("weight")}:{" "}
                  <span>
                    {data?.weight} {data?.weightUnit}
                  </span>
                </h3>
                {/* <div className="size-received-container">
                  <span className="card-text">
                    {t("itemValue")}: {data.itemValue}
                  </span>
                </div> */}
                <h3 className="received-card-label mt-3 mb-2">
                  {t("shippingDeadline")}:{" "}
                  <div className="shipping-received-container">
                    <span className="card-text">
                      {data.shippingDeadline}{" "}
                      <span
                        style={{ background: data.daysLeftHex }}
                        className="receive-expire-date"
                      >
                        {data?.daysLeft}
                      </span>
                    </span>
                  </div>
                </h3>
                <h3 className="received-counterpart-label mt-2 mb-2">
                  {t("counterpart")}:{" "}
                  <div className="shipping-received-container">
                    <a className="card-text" onClick={openProfileSidebar}>
                      <span>{data.chatTo}</span>
                    </a>
                    <Button
                      variant="primary"
                      className="chat-btn"
                      onClick={openChatModal}
                    >
                      <img src={ChatImage} className="chat-icon" />
                      {t("chat")}
                    </Button>
                  </div>
                </h3>
              </Col>
              <Col
                xs={3}
                className={`receive-body-offer text-right ${
                  isMobile && "px-1"
                }`}
              >
                {showStatusBox ? (
                  <div className="delivered-box-info">
                    {allStatus?.map((status) => {
                      return (
                        <a
                          onClick={() => {
                            changeStatus(data.offId, status.id);
                          }}
                        >
                          {status.name}
                        </a>
                      );
                    })}
                  </div>
                ) : (
                  <>
                    <Button
                      variant="transparent"
                      data-test="docs-btn-anchor"
                      className="offer-status-btn"
                    >
                      <div className="offer-box-btn">
                        {t("offer")}{" "}
                        <span className="font-weight-bold">
                          {data.offerPrice}
                        </span>
                        <span className="ml-1">{data.currencyType}</span>
                      </div>
                      <div
                        style={{
                          background: data.changestatus.hexColor
                            ? data.changestatus.hexColor
                            : "#ffa200",
                        }}
                        className="status-box-btn"
                      >
                        {data.changestatus.name}
                      </div>
                    </Button>
                    {reportButton && (
                      <Button
                        data-test="docs-btn-anchor"
                        className="report-problem-btn"
                        onClick={handleProblemSidebar}
                      >
                        {t("reportAProblem")}
                      </Button>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Card.Body>
          {showProofDelivery ? (
            <Card.Footer className="card-receive-footer">
              <div className="last-update-deliver">
                {lastUpdated !== "N/A" && (
                  <span>Last Updated at: {lastUpdated}</span>
                )}
              </div>
              {isTraveler && (
                <Button
                  onClick={openUploader}
                  variant="primary"
                  className="upload-more-btn ml-auto"
                >
                  {images[0] ? t("uploadMore") : t("upload")}
                </Button>
              )}
              {isTraveler && (
                <Button
                  variant="white"
                  className="detail-submit-btn"
                  onClick={() => submitProofOfDelivery(data.offId)}
                >
                  {t("submitRequest")}{" "}
                  {isProofLoading && (
                    <Oval
                      width="15"
                      height="15"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "5px" }}
                    />
                  )}
                </Button>
              )}
            </Card.Footer>
          ) : (
            <Card.Footer className="card-receive-footer m-auto">
              <Button
                variant="gray7"
                className={`receive-more-detail-btn ${
                  isMobile ? "mr-2" : "mr-3"
                }`}
                onClick={() => {
                  handleMoreDetail();
                }}
                onAnimationEnd={() => setFade(false)}
              >
                {/* {data?.status === "Pending" ? "More Details" : "Timeline"} */}
                {moreDetailButton?.text
                  ? moreDetailButton?.text
                  : isPersian
                  ? "جزئیات"
                  : "Detail"}
              </Button>
              {secondButton && (
                <Button
                  style={{
                    background: secondButton?.hexColor
                      ? secondButton?.hexColor
                      : "transparent",
                    border: secondButton?.hexColor
                      ? `1px solid ${secondButton?.hexColor}`
                      : "transparent",
                  }}
                  onClick={() =>
                    secondButton.code === "RateTheSender"
                      ? rateReview(data.offId, data.owner)
                      : secondButton.code === "ViewContract"
                      ? openContractModal()
                      : secondButton.code === "Cancel"
                      ? handleCancelSidebar(data.isMine)
                      : secondButton.code === "ViewEditContract"
                      ? openEditContractModal()
                      : secondButton.code === "ConfirmDeliveryDeny"
                      ? openConfirmDelivery(data.offId, data.owner)
                      : handleOtherBtnChange(
                          data.offId,
                          secondButton.code,
                          secondButton.text
                        )
                  }
                  className={`reject-btn ${isMobile ? "mr-2" : "mr-3"}`}
                >
                  {secondButton?.text}
                  {(isRejectLoading || isOtherLoading) && (
                    <Oval
                      width="15"
                      height="15"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "5px" }}
                    />
                  )}
                </Button>
              )}
              {thirdButton && (
                <Button
                  variant="primary"
                  data-test="docs-btn-anchor"
                  className={`update-status-btn ${isMobile ? "mr-2" : "mr-3"}`}
                  style={{
                    background: thirdButton?.hexColor
                      ? thirdButton?.hexColor
                      : "transparent",
                    border:
                      thirdButton.code !== "ProofOfDelivery" &&
                      thirdButton?.hexColor
                        ? `1px solid ${thirdButton?.hexColor}`
                        : thirdButton.code === "ProofOfDelivery" &&
                          thirdButton?.hexColor
                        ? `1px solid #00043d`
                        : "transparent",

                    color: thirdButton.code === "ProofOfDelivery" && "#00043d",
                  }}
                  onClick={() =>
                    thirdButton.code === "ProofOfDelivery"
                      ? handleProofDelivery(data.offId)
                      : thirdButton.code === "RateTheTraveler"
                      ? rateReview(data.offId, data.owner)
                      : thirdButton.code === "ViewContract"
                      ? openContractModal()
                      : thirdButton.code === "ViewEditContract"
                      ? openEditContractModal()
                      : thirdButton.code === "ConfirmDeliveryDeny"
                      ? openConfirmDelivery(data.offId, data.owner)
                      : handleOtherBtnChange(
                          data.offId,
                          thirdButton.code,
                          thirdButton.text
                        )
                  }
                >
                  {thirdButton?.text}{" "}
                  {(isStatusLoading || isOtherLoading) && (
                    <Oval
                      width="15"
                      height="15"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "5px" }}
                    />
                  )}
                  {isPaymentLoading && (
                    <Oval
                      width="15"
                      height="15"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "5px" }}
                    />
                  )}
                </Button>
              )}
              {otherButtons?.length !== 0 && (
                <Dropdown
                  className="more-buttons-dropdown d-inline"
                  onToggle={handleSelect}
                >
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="px-0 mx-0"
                  >
                    <Button
                      variant="gray7"
                      data-test="docs-btn-anchor"
                      className={`receive-more-detail-btn ${
                        isMobile ? "mr-2" : "mr-3"
                      }`}
                    >
                      {isPersian ? "بیشتر" : "More"}{" "}
                      <FontAwesomeIcon className="ml-2" icon={faEllipsisV} />
                    </Button>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {otherButtons?.map((btn) => {
                      return (
                        <Dropdown.Item
                          onClick={() =>
                            btn.code === "Timeline"
                              ? handleTimelineDetail()
                              : btn.code === "ConfirmDeliveryDeny"
                              ? openConfirmDelivery(data.offId, data.owner)
                              : btn.code === "ViewContract"
                              ? openContractModal()
                              : handleOtherBtnChange(
                                  data.offId,
                                  btn.code,
                                  btn.text
                                )
                          }
                        >
                          {btn.text}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Card.Footer>
          )}
        </Card>
      )}

      {showTimelineDetail && (
        <PackageCover
          data={data}
          fade={fade}
          setShowTimelineDetail={setShowTimelineDetail}
        />
      )}
      {showMoreDetail && (
        <MoreDetail
          data={data}
          fade={fade}
          setShowMoreDetail={setShowMoreDetail}
        />
      )}
      {isOpenReviewConfirm && (
        <ReviewConfirm
          reviewId={reviewId}
          reviewOwner={reviewOwner}
          reviewFor={data.chatTo}
          fade={fade}
          setShowReviewDetail={setIsOpenReviewConfirm}
          from={data?.fromCity}
          to={data?.toCity}
          date={data?.fromDate1}
          packages={data?.packagetypes}
          titleTab={"latestSentOffer"}
        />
      )}
      {isOpenStripeCheckout && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={isOpenStripeCheckout}
            setIsOpen={setIsOpenStripeCheckout}
            sidebarType="payment"
            checkoutData={checkoutData}
            price={price}
            titleTab={"latestSentRequest"}
          />
        </div>
      )}
      {isOpenDenyConfirm && (
        <DenyConfirm
          reviewId={reviewId}
          reviewOwner={reviewOwner}
          fade={fade}
          setShowConfirmDenyDetail={setIsOpenDenyConfirm}
          setIsDenyConfirmed={setIsDenyConfirmed}
          setIsOpenDisapproval={setIsOpenDisapproval}
          isOpenDisapproval={isOpenDisapproval}
          titleTab={"latestSentOffer"}
        />
      )}
      {isOpenConfirm && (
        <ChangeStatusConfirm
          fade={fade}
          setShowDetail={setIsOpenConfirm}
          setIsConfirmed={setIsConfirmed}
          status={confirmName}
        />
      )}
      {showProofDelivery && (
        <ProofDelivery
          fade={fade}
          images={images}
          setImages={setImages}
          setFiles={setFiles}
          setShowProofDelivery={setShowProofDelivery}
        />
      )}
      {showSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSidebar}
            setIsOpen={setShowSidebar}
            sidebarType="request"
          />
        </div>
      )}
      {showProblemSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showProblemSidebar}
            setIsOpen={setShowProblemSidebar}
            sidebarType="offerProblem"
            id={data.offId}
            titleTab={"latestSentOffer"}
          />
        </div>
      )}
      {showCancelSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showCancelSidebar}
            setIsOpen={setShowCancelSidebar}
            sidebarType="cancellation"
            id={data.offId}
            type={data.isMine ? "traveler" : "sender"}
            titleTab={"latestSentOffer"}
          />
        </div>
      )}
      {isOpenSentModal &&
        (isTraveler ? (
          <TravelerContract
            isOpen={isOpenSentModal}
            setIsOpen={setIsOpenSentModal}
            disableContract={disableContract}
            contractId={data.offId}
            data={contractInfo}
            titleTab={"latestSentOffer"}
          />
        ) : (
          <SenderContract
            isOpen={isOpenSentModal}
            setIsOpen={setIsOpenSentModal}
            disableContract={disableContract}
            contractId={data.offId}
            data={contractInfo}
            titleTab={"latestSentOffer"}
          />
        ))}
      {isOpenChatModal && (
        // <Chat
        //   isOpen={isOpenChatModal}
        //   setIsOpen={setIsOpenChatModal}
        //   chatEngineData={chatEngineData}
        //   packageData={packageData}
        // />
        <Row>
          <Col lg={4} md={6} sm={12}>
            <SingleChatList
              isOpen={isOpenChatModal}
              setIsOpen={setIsOpenChatModal}
              id={data?.offId}
              packageData={packageData}
              latestMessages={latestMessages}
              roomId={roomId}
            />
          </Col>
        </Row>
      )}
      {openProfile && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={openProfile}
            setIsOpen={setOpenProfile}
            sidebarType="dashboardReview"
            titleTab={"latestSentOffer"}
          />
        </div>
      )}
    </Col>
  );
};
