import PaymentService from "services/paymentService";
import {
  paymentResult,
  paymentResultFailure,
} from "redux/slices/stripe/paymentResult";
import {
  verifyStripe,
  verifyStripeFailure,
} from "redux/slices/stripe/verifyStripe";
import { toast } from "react-toastify";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

export const payment = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await PaymentService.payment(data);
    dispatch(paymentResult(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(paymentResultFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getVerifyStripe = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await PaymentService.verifyStripe();
    dispatch(verifyStripe(res.data));
  } catch (err) {
    dispatch(verifyStripeFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};
