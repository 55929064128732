import React, { useState, useEffect } from "react";
import MyTravel from "../../assets/images/myTravel.png";
import MyPackage from "../../assets/images/myPackage.png";
import LatestOffer from "../../assets/images/latestOffer.png";
import LatestRequest from "../../assets/images/latestRequests.png";
import { MyTravels } from "components/myTravels";
import { MyPackages } from "components/myPackages";
import { SentOffer } from "components/sentOffers";
import { ReceivedOffer } from "components/receivedOffers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { clickOnOffers, clickOnRequests } from "redux/actions/dashboard";
import { useAppDispatch, useAppSelector } from "redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBank,
  faBell,
  faCertificate,
  faLock,
  faMoneyBill,
  faPassport,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { PersonalInfo } from "components/personalInfo";
import { VerificationInfo } from "components/verificationInfo";
import { Col } from "react-bootstrap";
import { FinancialInfo } from "components/financialInfo";
import ChangePassword from "components/changePassword";
import { Transactions } from "components/financialInfo/transactions";
import { NotificationsBox } from "components/notifications";
import { UseWindowSize } from "components/windowSize/UseWindowSize";

interface IProp {
  selectedTab: string;
  onSelectedTab: Function;
  userData: any;
}

export const ProfileTabs: React.FC<IProp> = ({
  selectedTab,
  onSelectedTab,
  userData,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const isMobileTablet = windowSize.width < 992;

  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(
    isPersian ? "اطلاعات شخصی" : "Personal details"
  );

  const handleTabClick = (tabTitle) => {
    setActiveTab(tabTitle);
    onSelectedTab(tabTitle);
  };

  useEffect(() => {
    if (selectedTab === t("personalInfo") || selectedTab === "personalInfo") {
      setActiveTab(t("personalInfo"));
      navigate("/settings/personalInfo", { state: "personalInfo" });
    } else if (
      selectedTab === t("identityVerification") ||
      selectedTab === "identityVerification"
    ) {
      setActiveTab(t("identityVerification"));
      navigate("/settings/identityVerification", {
        state: "identityVerification",
      });
    } else if (selectedTab === t("security") || selectedTab === "security") {
      setActiveTab(t("security"));
      navigate("/settings/security", { state: "security" });
    } else if (
      selectedTab === t("addBankAccount") ||
      selectedTab === "addBankAccount"
    ) {
      setActiveTab(t("addBankAccount"));
      navigate("/settings/addBankAccount", { state: "addBankAccount" });
    } else if (
      selectedTab === t("transactions") ||
      selectedTab === "transactions"
    ) {
      setActiveTab(t("transactions"));
      navigate("/settings/transactions", { state: "transactions" });
    } else if (
      selectedTab === t("notifications") ||
      selectedTab === "notifications"
    ) {
      setActiveTab(t("notifications"));
      navigate("/settings/notifications", { state: "notifications" });
    }
  }, [selectedTab]);

  // useEffect(() => {
  //   console.log(offersClicked, requestsClicked);
  // }, [offersClicked, requestsClicked]);

  return (
    <>
      {!isMobileTablet && (
        <Col lg={4} md={4} sm={12}>
          <div className="profile-tabs-container">
            <div className="tabs-wrapper scrollable-tabs-container">
              <div className="tab-container">
                <div
                  className={`tab ${
                    activeTab === t("personalInfo") ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(t("personalInfo"))}
                >
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    color={`${
                      activeTab === t("personalInfo") ? "#006ce4" : "#1a1a1a"
                    }`}
                    className="mx-3"
                  />
                  <span>{t("personalInfo")} </span>
                </div>
                <div
                  className={`tab ${
                    activeTab === t("identityVerification") ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(t("identityVerification"))}
                >
                  <FontAwesomeIcon
                    icon={faPassport}
                    color={`${
                      activeTab === t("identityVerification")
                        ? "#006ce4"
                        : "#1a1a1a"
                    }`}
                    className="mx-3"
                  />
                  <span>{t("identityVerification")} </span>
                </div>
                <div
                  className={`tab ${
                    activeTab === t("security") ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(t("security"))}
                >
                  <FontAwesomeIcon
                    icon={faLock}
                    color={`${
                      activeTab === t("security") ? "#006ce4" : "#1a1a1a"
                    }`}
                    className="mx-3"
                  />
                  <span>{t("security")} </span>
                </div>
                <div
                  className={`tab ${
                    activeTab === t("addBankAccount") ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(t("addBankAccount"))}
                >
                  <FontAwesomeIcon
                    icon={faBank}
                    color={`${
                      activeTab === t("addBankAccount") ? "#006ce4" : "#1a1a1a"
                    }`}
                    className="mx-3"
                  />
                  <span> {t("addBankAccount")} </span>
                </div>
                <div
                  className={`tab ${
                    activeTab === t("transactions") ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(t("transactions"))}
                >
                  <FontAwesomeIcon
                    icon={faMoneyBill}
                    color={`${
                      activeTab === t("transactions") ? "#006ce4" : "#1a1a1a"
                    }`}
                    className="mx-3"
                  />
                  <span> {t("transactions")} </span>
                </div>
                <div
                  className={`tab ${
                    activeTab === t("notifications") ? "active" : ""
                  }`}
                  onClick={() => handleTabClick(t("notifications"))}
                >
                  <FontAwesomeIcon
                    icon={faBell}
                    color={`${
                      activeTab === t("notifications") ? "#006ce4" : "#1a1a1a"
                    }`}
                    className="mx-3"
                  />
                  <span> {t("notifications")} </span>
                </div>
              </div>
            </div>
          </div>
        </Col>
      )}

      <Col xs={isMobileTablet ? 12 : 8}>
        {activeTab === t("personalInfo") && (
          <PersonalInfo userData={userData} />
        )}
        {activeTab === t("identityVerification") && (
          <Col lg={12} md={12} sm={12}>
            <VerificationInfo userData={userData} />
          </Col>
        )}
        {activeTab === t("addBankAccount") && (
          <FinancialInfo userData={userData} />
        )}
        {activeTab === t("security") && <ChangePassword />}
        {activeTab === t("transactions") && <Transactions />}{" "}
        {activeTab === t("notifications") && <NotificationsBox />}
      </Col>
    </>
  );
};
