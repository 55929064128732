import ChatService from "services/chatService";
import { userChat, userChatFailure } from "redux/slices/chat/userChat";
import { userAllChat, userAllChatFailure } from "redux/slices/chat/userAllChat";
import { hasNewChat, hasNewChatFailure } from "redux/slices/chat/hasNewChat";
import { getRooms, getRoomsFailure } from "redux/slices/chat/getRooms";
import { chatCount, chatCountFailure } from "redux/slices/chat/chatCount";
import {
  chatByTypeId,
  chatByTypeIdFailure,
} from "redux/slices/chat/chatByTypeId";
import { toast } from "react-toastify";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

export const getUserChat = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ChatService.userChat(data);
    dispatch(userChat(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(userChatFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getUserAllChat = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ChatService.userAllChat();
    dispatch(userAllChat(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(userAllChatFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const checkHasChat = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ChatService.checkHasChat();
    dispatch(hasNewChat(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(hasNewChatFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getChatByTypeId = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ChatService.getChatByTypeId(id);
    dispatch(chatByTypeId(res.data));
    // if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(chatByTypeIdFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getMultiChatByTypeId = (typeId, roomId) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ChatService.getMultiChatByTypeId(typeId, roomId);
    dispatch(chatByTypeId(res.data));
    // if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(chatByTypeIdFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getAllRooms = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ChatService.getRooms();
    dispatch(getRooms(res.data));
  } catch (err) {
    dispatch(getRoomsFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};
export const getChatCount = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ChatService.getChatCount();
    dispatch(chatCount(res.data));
  } catch (err) {
    dispatch(chatCountFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};
