import React, { useState, useEffect } from "react";
import { Button, RightSidebar } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { PackageCard } from "./PackageCard";
import { Option } from "models/interfaces";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getAllHomeTravelFilter } from "redux/actions/flight";
import { useTranslation } from "react-i18next";
import { MobileFilters } from "components/modals/MobileFilter";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { LoginConfirm } from "components/modals/Login";
import AlarmIcon from "assets/images/noun-reminder.png";
import { checkAvailabilityPackage } from "redux/actions/landing";
import { ScreenFilter } from "components/common/screenFilter";
import { MobileFilter } from "components/common/mobileFilter";

interface IProps {
  type: Option;
  size: Option;
  policy: Option;
  weight: Option;
  services: Option;
  payments: Option;
  deliveryTypes: Option;
  tab: number;
  onChangeTab: (key: any) => void;
  availableTravelData: any;
}

export const AvailableTravelers: React.FC<IProps> = ({
  type,
  size,
  policy,
  weight,
  services,
  payments,
  deliveryTypes,
  tab,
  onChangeTab,
  availableTravelData,
}) => {
  const windowSize = UseWindowSize();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [travelerData, setTravelerData] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [showPackageSidebar, setShowPackageSidebar] = useState(false);
  const navigate = useNavigate();
  const str = window.location.pathname;
  const [showLoginSidebar, setShowLoginSidebar] = useState(false);
  const [showSignInSidebar, setShowSignInSidebar] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showAlertSidebar, setShowAlertSidebar] = useState(false);
  const [count, setCount] = useState(0);
  const alertsCount: any = useAppSelector((state) => state.fareAlertCount);
  const isMobile = windowSize.width < 768;
  const isMobileTablet = windowSize.width < 768;
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (alertsCount?.isSuccess) {
      setCount(alertsCount.data.travelCount);
    }
  }, [alertsCount]);

  useEffect(() => {
    setIsLoading(true);
    const data = {
      type: "travel",
      packagetypeIds: type.value ? type.value : null,
      weightrangeIds: weight?.value ? weight?.value : null,
      deliverytypeIds: deliveryTypes?.value ? deliveryTypes?.value : null,
      paymentmethodIds: payments?.value ? payments?.value : null,
      sizerangeIds: size?.value ? size?.value : null,
      cancellationpolicyIds: policy?.value ? policy?.value : null,
      serviceIds: services?.value ? services?.value : null,
      userLevelIds: null,
      page: 1,
    };
    if (tab === 2 && isMobileTablet) dispatch(getAllHomeTravelFilter(data));
    setTravelerData([]);
  }, [type, size, weight, services, payments, policy]);

  useEffect(() => {
    if (
      window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("token") !== "null" &&
      window.localStorage.getItem("token") !== undefined &&
      window.localStorage.getItem("token") !== "undefined"
    )
      dispatch(checkAvailabilityPackage());
  }, []);

  useEffect(() => {
    if (availableTravelData && availableTravelData?.length !== 0) {
      if (currentPage > 1) {
        setTravelerData([...travelerData, ...availableTravelData?.data]);
      } else setTravelerData(availableTravelData?.data);
      setPageCount(availableTravelData?.pageCount);
      setIsLoading(false);
      if (availableTravelData.pageCount > 1) {
        setHasLoadMore(true);
      }
    }
  }, [availableTravelData]);

  // useEffect(() => {
  //   if (availableTravelData?.length !== 0) {
  //     setTravelerData(availableTravelData?.data);
  //     setPageCount(availableTravelData?.pageCount);
  //     setIsLoading(false);
  //     setIsEmpty(false);
  //     return;
  //   }

  //   setIsEmpty(true);
  // }, [availableTravelData]);

  // useEffect(() => {
  //   if (availableTravelData?.length !== 0 && isLoad === true) {
  //     // setRequestData([...requestData, searchFlightResult]);
  //     setTravelerData(travelerData.concat(availableTravelData?.data));
  //     setIsLoad(false);
  //   }
  // }, [availableTravelData, travelerData]);

  const handleAlertSidebar = (data) => {
    setShowAlertSidebar(!showAlertSidebar);
    if (!window.location.pathname.includes("/set-alert")) navigate("set-alert");
  };

  const token = window.localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(
    token === "undefined" || token === "null" || token === null ? false : true
  );

  window.addEventListener("storage", () => {
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogin(false);
    else setIsLogin(true);
  });

  useEffect(() => {
    const str = window.location.pathname;
    if (str?.includes("/travelId/")) {
      onChangeTab(2);
      const n = str.lastIndexOf("/");
      const result = str.substring(n + 1);
      setTravelerData(
        availableTravelData?.data?.filter((item) => item.trvId === result)
      );
      setHasLoadMore(false);
    } else {
      // setTravelerData(availableTravelData?.data);
    }
  }, [availableTravelData]);

  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  useEffect(() => {
    if (str?.includes("addPackage") && isLogin && windowSize?.width <= 991) {
      setShowLoginSidebar(false);
      if (isLogin) setShowPackageSidebar(true);
      else setShowLoginModal(!showLoginModal);
      onChangeTab(2);
    }
  }, [str, windowSize]);

  const handlePackageSidebar = () => {
    if (windowSize?.width <= 991) {
      setShowLoginSidebar(false);
      if (isLogin) setShowPackageSidebar(true);
      else setShowLoginModal(!showLoginModal);
      navigate("/addPackage");
    }
  };

  useEffect(() => {
    if (showLoginSidebar) HandleLoginSidebar();
  }, [showLoginSidebar]);

  const HandleLoginSidebar = () => {
    setShowSignInSidebar(!showSignInSidebar);
  };

  return (
    <div className="requests-info-wrapper">
      <div className="text-center display-responsive">
        <Button
          variant="primary"
          data-test="docs-btn-anchor"
          className="add-travel-btn animated_btn w-90 mb-3"
          onClick={handlePackageSidebar}
        >
          {t("addMyRequest")}
        </Button>
      </div>{" "}
      {windowSize?.width >= 992 && (
        <h2 className="mt-2 mb-4">{t("availableTravelers")}</h2>
      )}
      {windowSize?.width < 992 && (
        <Row
          className="my-4"
          style={
            windowSize?.width < 768
              ? { width: "360px", margin: "auto", alignItems: "center" }
              : {
                  width: "745px",
                  margin: "auto",
                  alignItems: "center",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                }
          }
        >
          <Col xs={6} className={`${isMobile && "pr-0"}`}>
            <h2>{t("availableTravelers")}</h2>
          </Col>
          <Col xs={6} className="pl-0 text-right pr-0">
            <a className="filter-responsive-btn" onClick={handleOpenFilter}>
              <FontAwesomeIcon icon={faFilter} color="#00043d" />
            </a>
            {window.localStorage.getItem("token") && (
              <Button
                className="alert-responsive-btn"
                onClick={handleAlertSidebar}
              >
                <img src={AlarmIcon} className="alarm-icon" alt="attach-img" />
                <span className="setAlert-notification-count">{count}</span>
              </Button>
            )}
            {/* <Link to={"/addPackage"}> */}

            {/* </Link> */}
          </Col>
        </Row>
      )}
      {showPackageSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showPackageSidebar}
            // isLogin={isLogin}
            setIsOpen={setShowPackageSidebar}
            sidebarType="addPackage"
          />
        </div>
      )}
      {windowSize?.width > 992 ? (
        <Row className="home-request-info-wrapper dir-rtl">
          <Col xs={9} className="dir-ltr">
            <PackageCard
              travelerData={travelerData}
              isEmpty={isEmpty}
              isLoading={isLoading}
              setIsLoad={setIsLoad}
              type={type}
              size={size}
              policy={policy}
              weight={weight}
              deliveryTypes={deliveryTypes}
              payments={payments}
              services={services}
              pageCount={pageCount}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              hasLoadMore={hasLoadMore}
            />
          </Col>
          <Col xs={3} className="dir-ltr">
            <ScreenFilter type="travel" />
          </Col>
        </Row>
      ) : (
        <PackageCard
          travelerData={travelerData}
          isEmpty={isEmpty}
          isLoading={isLoading}
          setIsLoad={setIsLoad}
          type={type}
          size={size}
          policy={policy}
          weight={weight}
          deliveryTypes={deliveryTypes}
          payments={payments}
          services={services}
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          hasLoadMore={hasLoadMore}
        />
      )}
      {showLoginModal && (
        <LoginConfirm
          title={t("addRequest")}
          isOpen={showLoginModal}
          setIsOpen={setShowLoginModal}
          type="addPackage"
          setShowLoginSidebar={setShowLoginSidebar}
        />
      )}
      {isOpenFilter && (
        <MobileFilter
          type="travel"
          isOpen={isOpenFilter}
          setIsOpen={setIsOpenFilter}
        />
      )}
      {showSignInSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSignInSidebar}
            setIsOpen={setShowSignInSidebar}
            setIsLogin={setIsLogin}
            sidebarType="login"
          />
        </div>
      )}
      {showAlertSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showAlertSidebar}
            setIsOpen={setShowAlertSidebar}
            sidebarType="alert"
            type="Travel"
          />
        </div>
      )}
    </div>
  );
};
