import { createSlice } from "@reduxjs/toolkit";

export const fareAlertCountSlice = createSlice({
  name: "fareAlertCount",
  initialState: [],
  reducers: {
    fareAlertCount: (state, action) => {
      return (state = action.payload.data);
    },
    fareAlertCountFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { fareAlertCount, fareAlertCountFailure } =
  fareAlertCountSlice.actions;
export default fareAlertCountSlice.reducer;

