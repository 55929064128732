import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "photoswipe/dist/photoswipe.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "./languages/i18n";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "styles/app.scss";
import "styles/global.scss";
import "styles/responsive.scss";
import {
  Home,
  Dashboard,
  Profile2,
  Settings,
  Transaction,
  Notifications,
  SuccessPayment,
  FailedPayment,
  CloseAccounts,
  AboutUs,
  AccountType,
  PrivacyPolicy,
  ProhibitedItems,
  HowWorks,
  VisionMission,
  Services,
  TermsCondition,
  FounderProfile,
} from "pages";

function App(): JSX.Element {
  useEffect(() => {
    const language =
      localStorage.getItem("language") === "en" ? "english" : "persian";
    import(`./styles/${language}.scss`).then();
  }, [localStorage.getItem("language")]);

  useEffect(() => {
    const url = `https://api.ipgeolocation.io/timezone?apiKey=1ce2b34a28574b49b4fd567f9808317a`;
    fetch(url, { method: "get" })
      .then((response) => response.json())
      .then((data) => {
        window.localStorage.setItem(
          "timezone",
          data.timezone ? data.timezone : "America/Montreal"
        );
      });
  }, []);

  return (
    <div className="App">
      {/* {isMobileTablet && <AddToHomeScreen />} */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/iAmTraveler" element={<Home />} />
          <Route path="/LookingForTraveler" element={<Home />} />
          <Route path="/contactUS" element={<Home />} />
          <Route path="/signin" element={<Home />} />
          <Route path="/addTravel" element={<Home />} />
          <Route path="/addPackage" element={<Home />} />
          <Route path="/packageId/:term" element={<Home />} />
          <Route path="/travelId/:term" element={<Home />} />
          <Route path="/user/:id" element={<Home />} />
          <Route path="/set-alert" element={<Home />} />
          <Route path="/payment/successful" element={<SuccessPayment />} />
          <Route path="/payment/failed" element={<FailedPayment />} />
          <Route path="/close-accounts" element={<CloseAccounts />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/addTravel" element={<Dashboard />} />
          <Route path="/dashboard/editTravel" element={<Dashboard />} />
          <Route path="/dashboard/addPackage" element={<Dashboard />} />
          <Route path="/dashboard/editPackage" element={<Dashboard />} />
          <Route path="/dashboard/:id" element={<Dashboard />} />
          <Route path="/dashboard/:username" element={<Dashboard />} />
          <Route path="/dashboard/report-problem" element={<Dashboard />} />
          <Route path="/dashboard/cancellation" element={<Dashboard />} />
          <Route path="/dashboard/confirm-deny" element={<Dashboard />} />
          <Route path="/dashboard/payment" element={<Dashboard />} />
          <Route path="/dashboard/review" element={<Dashboard />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/:tab" element={<Profile2 />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/accountType" element={<AccountType />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/prohibitedItems" element={<ProhibitedItems />} />
          <Route path="/services" element={<Services />} />
          <Route path="/termsCondition" element={<TermsCondition />} />
          <Route path="/howitworks" element={<HowWorks />} />
          <Route path="/vision-mission" element={<VisionMission />} />
          <Route path="/founder-profile" element={<FounderProfile />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
