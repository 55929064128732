import { createSlice } from "@reduxjs/toolkit";

export const offersClickedSlice = createSlice({
  name: "offersClicked",
  initialState: [],
  reducers: {
    offersClicked: (state, action) => {
      return (state = action.payload);
    },
    offersClickedFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { offersClicked, offersClickedFailure } = offersClickedSlice.actions;
export default offersClickedSlice.reducer;
