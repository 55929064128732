import { createSlice } from "@reduxjs/toolkit";

export const checkTravelSlice = createSlice({
  name: "checkTravel",
  initialState: [],
  reducers: {
    checkTravel: (state, action) => {
      return (state = action.payload);
    },
    checkTravelFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { checkTravel, checkTravelFailure } = checkTravelSlice.actions;
export default checkTravelSlice.reducer;
