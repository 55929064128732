import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { ILocation } from "models/interfaces";

interface IProp {
  setLat?: React.Dispatch<React.SetStateAction<number | string>>;
  setLng?: React.Dispatch<React.SetStateAction<number | string>>;
  positionLat?: any;
  positionLong?: any;
}

const containerStyle = {
  width: "97%",
  marginLeft: "15px",
  height: "213px",
};

const mobileContainerStyle = {
  width: "96%",
  marginLeft: "15px",
  height: "213px",
};
const tabletContainerStyle = {
  width: "100%",
  marginLeft: "15px",
  height: "213px",
};

export const GoogleMapAPI: React.FC<IProp> = ({
  positionLat,
  positionLong,
  setLat,
  setLng,
}) => {
  const size = UseWindowSize();
  const [location, setLocation] = useState<ILocation>({
    lat: 49.246251500646025,
    lng: -123.06729125976562,
  });

  useEffect(() => {
    if (positionLat && positionLong)
      setLocation({
        lat: parseFloat(positionLat),
        lng: parseFloat(positionLong),
      });
    else if (!positionLat || !positionLong)
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
      });
    else
      setLocation({
        lat: 49.246251500646025,
        lng: -123.06729125976562,
      });
  }, [positionLat, positionLong]);

  const handleMarkLocation = (e) => {
    setLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    setLat(e.latLng.lat());
    setLng(e.latLng.lng());
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyAtDg1xWnADH7dCR_ZaJmhwTqMmQo9-VGM">
      <GoogleMap
        mapContainerStyle={
          size.width < 768
            ? mobileContainerStyle
            : size.width > 768 && size.width < 992
            ? tabletContainerStyle
            : containerStyle
        }
        center={location}
        zoom={10}
      >
        <Marker draggable position={location} onDragEnd={handleMarkLocation} />
      </GoogleMap>
    </LoadScript>
  );
};
