import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { rtkQueryErrorLogger } from "helpers/axiosSetting";
// import { axiosMiddleware } from "helpers/axiosSetting";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import rootReducer from "./reducers";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return [
      rtkQueryErrorLogger,
      ...getDefaultMiddleware({
        thunk: {
          extraArgument: {},
        },
        serializableCheck: false,
      }),
    ];
  },
});

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => {
//     return [
//       axiosMiddleware,
//       ...getDefaultMiddleware({
//         thunk: {
//           extraArgument: {},
//         },
//         serializableCheck: false,
//       }),
//     ];
//   },
// });

// export const store = configureStore({
//   reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => {
//     return getDefaultMiddleware().concat(axiosMiddleware)
//   },
// })

export type RootState = ReturnType<typeof store.getState>;
export type ReduxState = ReturnType<typeof rootReducer>;
export type AppDispatch = ThunkDispatch<ReduxState, any, AnyAction>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
