import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { IUserInfo } from "models/interfaces";
import { ProfileUploader } from "components";
import { SkeletonForm } from "components/Skeleton/skeletonForm";
import { PersonaTemplate } from "components/persona";
import { useTranslation } from "react-i18next";

interface IProps {
  userData: IUserInfo;
}

export const VerificationInfo: React.FC<IProps> = ({ userData }) => {
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const { t } = useTranslation();
  const [deleted, detDeleted] = useState(false);
  const [isOpenPersonaModal, setIsOpenPersonaModal] = useState(false);
  const size = UseWindowSize();
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [secondIdentityPhoto, setSecondIdentityPhoto] = useState(null);
  const [isPassportVerified, setIsPassportVerified] = useState("");
  const [isSecondIdentityVerified, setIsSecondIdentityVerified] =
    useState("");
  const isMobile = size.width < 768;
  const isTablet = size.width >= 768 && size.width < 992;
  const [isPassportPhotoRemoved, setIsPassportPhotoRemoved] = useState(false);
  const [isSecondIdentityPhotoRemoved, setIsSecondIdentityPhotoRemoved] =
    useState(false);

  useEffect(() => {
    if (userData) {
      // setIsPassportVerified(userData.isPassportVerified);
      // setIsSecondIdentityVerified(userData.isSecondIdentityVerified);
      setIsPassportVerified(userData.passportVerifyStatus);
      setIsSecondIdentityVerified(userData.secondIdentityVerifyStatus);
    }
  }, [userData]);

  useEffect(() => {
    // if (deleted && personalPhoto === null) setIsPersonalPhotoRemoved(true);
    // else setIsPersonalPhotoRemoved(false);
    if (deleted && passportPhoto === null) setIsPassportPhotoRemoved(true);
    else setIsPassportPhotoRemoved(false);
    if (deleted && secondIdentityPhoto === null)
      setIsSecondIdentityPhotoRemoved(true);
    else setIsSecondIdentityPhotoRemoved(false);
  }, [passportPhoto, secondIdentityPhoto]);

  return (
    <div>
      <PersonaTemplate
        isOpen={isOpenPersonaModal}
        setIsOpen={setIsOpenPersonaModal}
      />
      {/* {!isMobile && (
        <Row>
          <Col xs={12}>
            <h1 className="account-title">Identity Verification</h1>
          </Col>
        </Row>
      )} */}
      {userData ? (
        <>
          <Row className={`${isTablet && "w-700"}`}>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={`${isMobile ? (isPersian ? "pr-0" : "px-4") : "text-center"}`}
            >
              <section
                className="container"
                style={
                  isMobile
                    ? { width: "400px", paddingLeft: 0 }
                    : { width: "100%" }
                }
              >
                <ProfileUploader
                  image={userData?.passportPhoto}
                  title=""
                  // title="Upload Passport front page
                  // (All corners must be visible)"
                  photo={passportPhoto}
                  setPhoto={setPassportPhoto}
                  confirmedStatus={isPassportVerified}
                  setDeleted={detDeleted}
                  type="Passport"
                />
              </section>
              <span className="expire-date-passport-text">
                {t("passportExpirationDate")}:{" "}
                <span>{userData.passportExpirationDate}</span>
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              className={`pr-0 ${
                isMobile ? (isPersian ? "pr-0" : "px-4") : "text-center"
              }`}
            >
              <section
                className="container pl-0"
                style={isMobile ? { width: "400px" } : { width: "100%" }}
              >
                <ProfileUploader
                  image={userData?.secondIdentityPhoto}
                  title=""
                  // title="Upload ID Driving License/PR Card/ Health Insurance Card"
                  photo={secondIdentityPhoto}
                  setPhoto={setSecondIdentityPhoto}
                  confirmedStatus={isSecondIdentityVerified}
                  type="SecondIdentity"
                />
                {/* <Uploader
                  image={userData?.secondIdentityPhoto}
                  title="Upload ID Driving License/PR Card/ 
                  Green Card/National Card)"
                  photo={secondIdentityPhoto}
                  setPhoto={setSecondIdentityPhoto}
                /> */}
              </section>
              <span
                className={`expire-date-passport-text ${
                  isMobile ? "pl-3" : ""
                }`}
              >
                {t("idExpirationDate")}:{" "}
                <span>{userData.secondIdentityExpirationDate}</span>
              </span>
            </Col>
          </Row>
        </>
      ) : (
        <SkeletonForm />
      )}
    </div>
  );
};
