import { createSlice } from "@reduxjs/toolkit";

export const approveSenderSlice = createSlice({
  name: "approveSender",
  initialState: [],
  reducers: {
    approveSender: (state, action) => {
      return (state = action.payload);
    },
    approveSenderFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { approveSender, approveSenderFailure } = approveSenderSlice.actions;
export default approveSenderSlice.reducer;
