import { createSlice } from "@reduxjs/toolkit";

export const approveTravelerSlice = createSlice({
  name: "approveTraveler",
  initialState: [],
  reducers: {
    approveTraveler: (state, action) => {
      return (state = action.payload);
    },
    approveTravelerFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { approveTraveler, approveTravelerFailure } = approveTravelerSlice.actions;
export default approveTravelerSlice.reducer;

