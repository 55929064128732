import { createSlice } from "@reduxjs/toolkit";

export const searchByPkgIdSlice = createSlice({
  name: "searchByPkgId",
  initialState: [],
  reducers: {
    searchByPkgId: (state, action) => {
      return (state = action.payload);
    },
    searchByPkgIdFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { searchByPkgId, searchByPkgIdFailure } =
searchByPkgIdSlice.actions;
export default searchByPkgIdSlice.reducer;
