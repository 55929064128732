import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import store from "redux/store";
import { Provider } from "react-redux";
import CookieConsent from "react-cookie-consent";
import { HelmetProvider } from "react-helmet-async";
import { GoogleOAuthProvider } from "@react-oauth/google";

const helmetContext = {};
const clientId =
  "165924336796-1o4rjbggsh4ph9qu8m5qnauvsn5ge2rn.apps.googleusercontent.com";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

ReactDOM.render(
  <GoogleOAuthProvider clientId={clientId}>
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <App />
        <CookieConsent
          location="bottom"
          buttonText="I Understand!"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#00043d" }}
          buttonStyle={{
            color: "#00043d",
            backgroundColor: "#ffa200",
            fontSize: "14px",
            fontFamily: "Inter",
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
          {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
        </CookieConsent>
      </Provider>
    </HelmetProvider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
reportWebVitals();
