import { createSlice } from "@reduxjs/toolkit";

export const allRequestStatusSlice = createSlice({
  name: "allRequestStatus",
  initialState: [],
  reducers: {
    allRequestStatus: (state, action) => {
      return (state = action.payload.data);
    },
    allRequestStatusFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allRequestStatus, allRequestStatusFailure } =
allRequestStatusSlice.actions;
export default allRequestStatusSlice.reducer;
