import { createSlice } from "@reduxjs/toolkit";

export const otpCodeSlice = createSlice({
  name: "otpCode",
  initialState: [],
  reducers: {
    otpCode: (state, action) => {
      return (state = action.payload);
    },
    otpCodeFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { otpCode, otpCodeFailure } = otpCodeSlice.actions;
export default otpCodeSlice.reducer;
