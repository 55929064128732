import React, { FC, useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "layers";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import PlaneIcon from "../../assets/images/svg/box.svg";
import CalendarIcon from "../../assets/images/calendar.png";
import DeparturePlan from "../../assets/images/svg/departure-plane.svg";
import ArrivalPlan from "../../assets/images/svg/arrival-plane.svg";
import { components } from "react-select";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch, useAppSelector } from "redux/store";
import { searchAllFlight, getAllInfoHomeRequests } from "redux/actions/flight";
import { IFlightSelect, ITravelInformation } from "models/interfaces";
import DatePicker from "react-datepicker";
import { addDays } from "date-fns";
import { getDate, getDefaultDate, getDefaultTime } from "helpers/convertDate";
import { Oval } from "react-loader-spinner";
import AsyncSelect from "react-select/async";
import { searchLocation } from "helpers/searchLocation";
import { useTranslation } from "react-i18next";

export const ManualFlightTabOne: FC<{}> = () => {
  const size = UseWindowSize();
  const { t } = useTranslation();

  const flightFromWrapper = useRef(null);
  const flightsToWrapper = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [isFromDropdownOpen, setIsFromDropdownOpen] = useState(false);
  const [isToDropdownOpen, setIsToDropdownOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [from, setFrom] = useState("");
  const [fromCity, setFromCity] = useState("");
  const [fromAirportName, setFromAirportName] = useState("");
  const [toAirportName, setToAirportName] = useState("");
  const [fromCountry, setFromCountry] = useState("");
  const [to, setTo] = useState("");
  const [toCity, setToCity] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [fromText, setFromText] = useState<IFlightSelect>({
    value: "",
    cityLabel: "",
    airportLabel: "",
    countryLabel: "",
    label: <></>,
  });
  const [toText, setToText] = useState<IFlightSelect>({
    value: "",
    cityLabel: "",
    airportLabel: "",
    countryLabel: "",
    label: <></>,
  });
  const [departureDate, setDepartureDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(getDefaultDate(new Date()));
  const [fromTime, setFromTime] = useState(getDefaultTime(new Date()));
  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [toDate, setToDate] = useState(getDefaultDate(new Date()));
  const [toTime, setToTime] = useState(getDefaultTime(new Date()));
  const searchFlightResult: any = useAppSelector(
    (state) => state.filterHomeRequestSearch
  );

  const locations = useAppSelector((state) => state.searchFlight);
  // const [locationsOptions, setLocationsOptions] = useState([]);

  const handleFromTextChange = (selected) => {
    setFromText(selected);
    setIsSelected(true);
    setIsFromDropdownOpen(false);
    setFrom(selected?.value);
    setFromCity(selected?.cityLabel);
    setFromAirportName(selected?.airportLabel);
    setFromCountry(selected?.countryLabel);
  };

  const handleToTextChange = (selected) => {
    setToText(selected);
    setIsSelected(true);
    setIsToDropdownOpen(false);
    setTo(selected?.value);
    setToAirportName(selected?.airportLabel);
    setToCity(selected?.cityLabel);
    setToCountry(selected?.countryLabel);
  };

  const onInputChange = (inputValue) => {
    if (inputValue !== "" && inputValue !== undefined) {
      setIsLoading(true);
      const data = { text: inputValue };
      dispatch(searchAllFlight(data));
    }
  };

  const onDepartureDateChange = (date) => {
    setDepartureDate(date);
    setFromDate(getDefaultDate(date));
    setFromTime(getDefaultTime(date));
  };

  const onArrivalDateChange = (date) => {
    setArrivalDate(date);
    setToDate(getDefaultDate(date));
    setToTime(getDefaultTime(date));
  };

  // useEffect(() => {
  //   if (isFromDropdownOpen)
  //     setFromText({
  //       value: locationsOptions[0]?.airportCode,
  //       cityLabel: locationsOptions[0]?.cityName,
  //       airportLabel: locationsOptions[0]?.airportName,
  //       countryLabel: locationsOptions[0]?.countryName,
  //       label: locationsOptions[0]?.airportName,
  //     });

  //   if (isToDropdownOpen)
  //     setToText({
  //       value: locationsOptions[0]?.airportCode,
  //       cityLabel: locationsOptions[0]?.cityName,
  //       airportLabel: locationsOptions[0]?.airportName,
  //       countryLabel: locationsOptions[0]?.countryName,
  //       label: locationsOptions[0]?.airportName,
  //     });
  // }, [locations, locationsOptions, isFromDropdownOpen, isToDropdownOpen]);

  const customStyle = {
    control: (styles) => ({
      ...styles,
      height: 45,
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#ffa200",
      fontSize: "14px",
      zIndex: 1,
      borderBottom: "1px solid rgb(255 255 255 / 35%)",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#333333",
    }),
    Menu: (styles) => ({
      ...styles,
      background: "#ffa200",
      border: "none",
    }),
    menuList: (styles) => ({
      ...styles,
      height: 200,
      background: "#ffa200",
      width: size.width < 768 ? 300 : 378,
      borderRadius: 5,
      border: "none",
      padding: "5px 15px 0 0",
    }),
  };

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    );
  };

  const openFromDropdown = () => {
    setIsFromDropdownOpen(!isFromDropdownOpen);
  };
  const openToDropdown = () => {
    setIsToDropdownOpen(!isToDropdownOpen);
  };

  const searchFlight = () => {
    setIsSearchLoading(true);

    const data: ITravelInformation = {
      type: "package",
      fromCityEntityId: fromText?.value ? fromText?.value : null,
      fromDate: getDate(departureDate),
      toCityEntityId: toText?.value ? toText?.value : null,
      toDate: getDate(arrivalDate),
    };
    dispatch(getAllInfoHomeRequests(data));
  };

  useEffect(() => {
    if (searchFlightResult?.isSuccess) {
      setIsSearchLoading(false);
    } else {
      setIsSearchLoading(false);
    }
  }, [searchFlightResult]);

  function flightListOutsideClickListener(ref) {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsFromDropdownOpen(false);
        setIsToDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }

  flightListOutsideClickListener(flightFromWrapper);
  flightListOutsideClickListener(flightsToWrapper);

  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  return (
    <Row className="tabs-wrapper travelers-tabs">
      <Col lg={5} md={12} sm={12}>
        <Row>
          <Col
            lg={6}
            md={6}
            sm={12}
            className={
              size.width >= 991 ? (isPersian ? "pr-4 pl-0" : "pl-4 pr-0") : ""
            }
          >
            <div
              className={`text-align-last-left mb-1 ${
                isPersian ? "text-right" : ""
              }`}
            >
              <span className="search-arrival-info">{t("departureInfo")}</span>
            </div>
            <Row
              className={`${
                isFromDropdownOpen
                  ? "from-active-location-input-wrapper"
                  : "from-deActive-location-input-wrapper"
              }`}
              onClick={openFromDropdown}
            >
              <Col xs={10}>
                {from ? (
                  <>
                    {/* <span className="abbr-name-location">
                      {fromAirportName}
                    </span> */}
                    <span className="country-name-location">
                      {fromCity}
                      {fromCity && fromCountry && ","} {fromCountry}
                    </span>
                  </>
                ) : (
                  <span className="abbr-title-location">{t("city")}</span>
                )}
              </Col>
              <Col xs={2} className="pl-0 text-center">
                <img
                  src={DeparturePlan}
                  className="location-icons"
                  alt="location-img"
                />
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="location-chevron"
                  color={`${isFromDropdownOpen ? "white" : "black"}`}
                />
              </Col>
            </Row>
            {isFromDropdownOpen && (
              <div
                ref={flightFromWrapper}
                className="flight-info-dropdown d-inline-block flight-list-distance"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  height: 275,
                }}
              >
                <AsyncSelect
                  className="custom-place-location d-inline-block"
                  value={fromText}
                  menuIsOpen
                  autoFocus
                  onChange={handleFromTextChange}
                  loadOptions={searchLocation}
                  isLoading={isLoading}
                  placeholder="Search your Location"
                  loadingMessage={() => "Loading ..."}
                  cacheOptions
                  defaultOptions
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    MenuList: SelectMenuButton,
                  }}
                  styles={customStyle}
                />
              </div>
            )}
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            className={size.width >= 768 ? "p-0 ml-title-15" : ""}
          >
            <div
              className={`${
                size.width < 768 ? "mt-2" : ""
              } text-align-last-left mb-1`}
            >
              <span className="search-departure-info">
                {t("DepartureFirsTab")}
              </span>
            </div>

            <div
              className={
                size.width >= 992 && !isPersian
                  ? "d-inline-block"
                  : size.width >= 992 && isPersian
                  ? "d-flex"
                  : ""
              }
            >
              <DatePicker
                selected={departureDate}
                onChange={(date) => onDepartureDateChange(date)}
                minDate={new Date()}
                showTimeInput
                customInput={
                  <Row className="from-time-input-wrapper">
                    <Col xs={10}>
                      <span className="abbr-title-location">{fromDate}</span>
                      <span className="country-name-time">{fromTime}</span>
                    </Col>
                    <Col xs={2} className="pl-0 text-center">
                      <img
                        src={CalendarIcon}
                        className="time-icons"
                        alt="time-img"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="location-chevron"
                        color="black"
                      />
                    </Col>
                  </Row>
                }
              />
            </div>
          </Col>
        </Row>
      </Col>

      <div
        // lg={1}
        // md={1}
        // sm={12}
        className={`${
          size?.width < 768 ? "mt-3" : ""
        } px-0 plane-manual-select`}
      >
        {size?.width < 768 ? (
          <span
            className="mb-0"
            style={{ color: "rgba(0,0,0,0.17)", padding: "0 20px" }}
          >
            - - - - - - - - - - -{" "}
            <img
              src={PlaneIcon}
              className="search-plane-icon"
              alt="location-img"
            />{" "}
            - - - - - - - - - - -
          </span>
        ) : (
          <>
            <img
              src={PlaneIcon}
              className="change-flight-plane-icon"
              alt="location-img"
            />
            {/* <a href="/" className="change-flight-text d-block">
              Change Flight
            </a> */}
          </>
        )}
      </div>

      <Col lg={5} md={12} sm={12}>
        <Row>
          <Col
            lg={6}
            md={6}
            sm={12}
            className={size.width >= 992 ? "pl-2 pr-0" : ""}
          >
            <div className="text-align-last-left mb-1">
              <span className="search-arrival-info">{t("arrivalInfo")}</span>
            </div>
            <Row
              className={`${
                isToDropdownOpen
                  ? "from-active-location-input-wrapper"
                  : "from-deActive-location-input-wrapper"
              }`}
              onClick={openToDropdown}
            >
              <Col xs={10}>
                {to ? (
                  <>
                    {/* <span className="abbr-name-location">{toAirportName}</span> */}
                    <span className="country-name-location">
                      {toCity}
                      {toCity && toCountry && ","} {toCountry}
                    </span>
                  </>
                ) : (
                  <span className="abbr-title-location">{t("city")}</span>
                )}
              </Col>
              <Col xs={2} className="pl-0 text-center">
                <img
                  // src={isToDropdownOpen ? whiteLocationIcon : blackLocationIcon}
                  src={ArrivalPlan}
                  className="location-icons"
                  alt="location-img"
                />
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className="location-chevron"
                  color={`${isToDropdownOpen ? "white" : "black"}`}
                />
              </Col>
            </Row>
            {isToDropdownOpen && (
              <div
                ref={flightsToWrapper}
                className="flight-info-dropdown flight-list-distance d-inline-block"
                style={{
                  position: "absolute",
                  zIndex: 1,
                  height: 275,
                }}
              >
                <AsyncSelect
                  className="custom-place-location d-inline-block"
                  value={toText}
                  menuIsOpen
                  autoFocus
                  onChange={handleToTextChange}
                  loadOptions={searchLocation}
                  isLoading={isLoading}
                  placeholder="Search your Location"
                  loadingMessage={() => "Loading ..."}
                  cacheOptions
                  defaultOptions
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                    // loadingMessage: () => {"Loading ..."},
                    MenuList: SelectMenuButton,
                  }}
                  styles={customStyle}
                />
              </div>
            )}
          </Col>
          <Col
            lg={6}
            md={6}
            sm={12}
            className={size.width >= 768 ? "m-0 p-0" : ""}
          >
            <div
              className={`${
                size.width < 768 ? "mt-2" : ""
              } text-align-last-left mb-1`}
            >
              <span
                className={`${
                  size.width >= 768 ? " ml-title-30" : ""
                } search-departure-info`}
              >
                {t("arrivalFirstTab")}
              </span>
            </div>
            <div
              className={
                size.width >= 992 && !isPersian
                  ? "d-inline-block"
                  : size.width >= 992 && isPersian
                  ? "d-flex"
                  : ""
              }
            >
              <DatePicker
                selected={arrivalDate}
                onChange={(date) => onArrivalDateChange(date)}
                minDate={departureDate}
                showTimeInput
                customInput={
                  <Row className="to-time-input-wrapper">
                    <Col xs={10}>
                      <span className="abbr-title-location">{toDate}</span>
                      <span className="country-name-time">{toTime}</span>
                    </Col>
                    <Col xs={2} className="pl-0 text-center">
                      <img
                        src={CalendarIcon}
                        className="time-icons"
                        alt="time-img"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="location-chevron"
                        color="black"
                      />
                    </Col>
                  </Row>
                }
              />
            </div>
          </Col>
        </Row>
      </Col>

      {size.width < 768 ? (
        <Col xs={12}>
          {/* <Button
            type="button"
            className="responsive-change-flight-btn"
            variant="white"
          >
            {t("changeFlight")}
          </Button> */}
          <Button
            type="button"
            className="responsive-search-btn"
            variant="warning"
            onClick={searchFlight}
          >
            {t("searchPackage")}
            {isSearchLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </Col>
      ) : (
        <Col lg={2} md={2} sm={12}>
          <Button
            variant="warning"
            className="search-btn"
            onClick={searchFlight}
          >
            {t("searchPackage")}
            {isSearchLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </Col>
      )}
    </Row>
  );
};
