import { createSlice } from "@reduxjs/toolkit";

export const proofByIdSlice = createSlice({
  name: "proofById",
  initialState: [],
  reducers: {
    proofById: (state, action) => {
      return (state = action.payload);
    },
    proofByIdFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { proofById, proofByIdFailure } = proofByIdSlice.actions;
export default proofByIdSlice.reducer;
