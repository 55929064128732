import { createSlice } from "@reduxjs/toolkit";

export const verificationResponseSlice = createSlice({
  name: "verificationResponse",
  initialState: [],
  reducers: {
    verificationResponse: (state, action) => {
      return (state = action.payload);
    },
    verificationResponseFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { verificationResponse, verificationResponseFailure } = verificationResponseSlice.actions;
export default verificationResponseSlice.reducer;
