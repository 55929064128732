import { createSlice } from "@reduxjs/toolkit";

export const hasNewChatSlice = createSlice({
  name: "hasNewChat",
  initialState: [],
  reducers: {
    hasNewChat: (state, action) => {
      return (state = action.payload);
    },
    hasNewChatFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { hasNewChat, hasNewChatFailure } = hasNewChatSlice.actions;
export default hasNewChatSlice.reducer;
