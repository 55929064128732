import { createSlice } from "@reduxjs/toolkit";

export const checkPackageSlice = createSlice({
  name: "checkPackage",
  initialState: [],
  reducers: {
    checkPackage: (state, action) => {
      return (state = action.payload);
    },
    checkPackageFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { checkPackage, checkPackageFailure } = checkPackageSlice.actions;
export default checkPackageSlice.reducer;
