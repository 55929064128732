import React, { useState, useEffect } from "react";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import { Header, Footer } from "layers";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getUserInfo } from "redux/actions/Authorization";
import { ToastContainer } from "react-toastify";
import { getAllCurrencies } from "redux/actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faCheck,
  faClose,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProfileTabs } from "components/ProfileTabs";

export const Profile2: React.FC = () => {
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const userData = useAppSelector(
    (state) => state?.userInfo?.data && state?.userInfo?.data[0]?.data
  );
  const token = window.localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getAllCurrencies());
  }, [token]);

  window.addEventListener("storage", () => {
    window.localStorage.setItem("token", window.localStorage.getItem("token"));
  });

  const handleSelectedTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(location.pathname?.replace("/settings/", ""));
  }, [location]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <div className="title-dashboard d-flex">
        <h2>{t("myAccount")}</h2>
        <a href="/accountType">
          <div
            className="user-account-type-box d-flex shiny-box"
            style={{ backgroundColor: userData?.userLevelHexColor }}
          >
            <div className="userlevel-icons-wrapper">
              <FontAwesomeIcon
                className="icon-validation-user userlevel-certificate-icon"
                icon={faCertificate}
                // color={userData?.userLevelHexColor}
                color={"#fff"}
              ></FontAwesomeIcon>
              <FontAwesomeIcon
                className="icon-validation-user userlevel-check-icon"
                icon={faCheck}
                color={userData?.userLevelHexColor}
              />
            </div>
            <span style={{ color: "#fff" }}>{userData?.userLevel}</span>
          </div>
        </a>

        <Dropdown
          className="userlevel-info-dropdown d-inline"
          // onToggle={handleSelect}
        >
          <Dropdown.Toggle
            variant="transparent"
            id="dropdown-basic"
            className="px-0 mx-0"
          >
            <FontAwesomeIcon
              style={{ fontSize: "24px" }}
              icon={faInfoCircle}
              color="primary"
            />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item>
              {userData?.isPassportVerified ? (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faCheck}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faClose}
                />
              )}
              {t("validPass")}
            </Dropdown.Item>
            <Dropdown.Item>
              {userData?.isPhoneNumberVerified ? (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faCheck}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faClose}
                />
              )}
              {t("validPhone")}
            </Dropdown.Item>
            <Dropdown.Item>
              {userData?.isStripeVerified ? (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faCheck}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faClose}
                />
              )}
              {t("validBankAccount")}
            </Dropdown.Item>
            <Dropdown.Item>
              {userData?.isSecondIdentityVerified ? (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faCheck}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faClose}
                />
              )}
              {t("validID")}
            </Dropdown.Item>
            <Dropdown.Item>
              {userData?.isAddressVerified ? (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faCheck}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faClose}
                />
              )}
              {t("proofOfAddress")}
            </Dropdown.Item>
            <Dropdown.Item>
              {userData?.isSuccessfulTransaction ? (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faCheck}
                />
              ) : (
                <FontAwesomeIcon
                  className="icon-validation-user"
                  icon={faClose}
                />
              )}
              {t("successfulTransaction")} (3+)
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="flex-grow-1 request-info-wrapper mb-3 px-3">
        <Row className={isPersian ? "text-right" : ""}>
          <ProfileTabs
            selectedTab={activeTab}
            onSelectedTab={handleSelectedTab}
            userData={userData}
          />
        </Row>
      </div>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
