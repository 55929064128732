import { createSlice } from "@reduxjs/toolkit";

export const agreementTermSlice = createSlice({
  name: "agreementTerm",
  initialState: [],
  reducers: {
    agreementTerm: (state, action) => {
      return (state = action.payload.data);
    },
    agreementTermFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { agreementTerm, agreementTermFailure } = agreementTermSlice.actions;
export default agreementTermSlice.reducer;
