import { createSlice } from "@reduxjs/toolkit";

export const changeLanguageSlice = createSlice({
  name: "changeLanguage",
  initialState: [],
  reducers: {
    changeLanguage: (state, action) => {
      return (state = action.payload);
    },
    changeLanguageFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { changeLanguage, changeLanguageFailure } = changeLanguageSlice.actions;
export default changeLanguageSlice.reducer;
