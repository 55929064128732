import { createSlice } from "@reduxjs/toolkit";

export const changeStatusSlice = createSlice({
  name: "changeStatus",
  initialState: [],
  reducers: {
    changeStatus: (state, action) => {
      return (state = action.payload);
    },
    changeStatusFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { changeStatus, changeStatusFailure } = changeStatusSlice.actions;
export default changeStatusSlice.reducer;
