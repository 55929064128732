import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import AttachIcon from "assets/images/attach.png";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "layers";

interface IProp {
  title: string;
  photos?: any;
  //   setPhotos?: React.Dispatch<React.SetStateAction<File>[]>;
  setPhotos?: any;
  description: any;
  disabled: boolean;
  width: string;
  height?: string;
  type?: string;
  setIsCloseBtn?: Function;
}

export const ProofMultipleUploader: React.FC<IProp> = ({
  title,
  photos,
  setPhotos,
  description,
  disabled,
  width,
  height,
  type,
  setIsCloseBtn,
}) => {
  const { isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".png"],
      // "image/*": []
    },
    multiple: true,
    maxFiles: 10,
  });

  const windowSize = UseWindowSize();

  const baseStyle = {
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#d6d6d6",
    borderStyle: "solid",
    height: "174px",
    margin: "0 0 29px",
    padding: "60px 30px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    lineHeight: "150px",
    position: "relative",
  };
  const customBaseStyle = {
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#d6d6d6",
    borderStyle: "solid",
    // height: "100%",
    height: "260px",
    margin: "0 0 29px",
    padding: "60px 30px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    lineHeight: "150px",
    position: "relative",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file, index) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setPhotos((prevState) => [...prevState, file]);
        setPhotos(photos.push(file));
      };
      reader.readAsDataURL(file);
      // setPhotos(acceptedFiles);

      return file;
    });
  }, []);

  // const onDrop = useCallback((acceptedFiles) => {
  //   acceptedFiles.map((file, index) => {
  //     const reader = new FileReader();
  //     reader.onload = function (e) {
  //       // setPhotos((prevState) => [...prevState, file]);
  //       photos.push(file);
  //       setPhotos(photos)
  //     };
  //     reader.readAsDataURL(file);
  //     // setPhotos(acceptedFiles);
  //     return file;
  //   });
  // }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    multiple: true,
    maxFiles: 10,
    onDrop: onDrop,
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...(height ? customBaseStyle : baseStyle),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  ) as React.CSSProperties;

  const handleShow = () => {
    setIsCloseBtn(true);
  };

  return (
    <>
      <Button
        variant="primary"
        className="close-dropzone-btn"
        style={{ marginTop: "-27px" }}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faClose} />
      </Button>
      {title && <span className="multi-upload-title">{title}</span>}
      {!disabled && (
        <div
          {...getRootProps({ style })}
          className={windowSize.width < 768 ? "w-100" : width}
        >
          <input {...getInputProps()} />
          <p className="multi-thumb-title">{description}</p>
          <img src={AttachIcon} className="attach-icon" alt="attach-img" />
        </div>
      )}
    </>
  );
};
