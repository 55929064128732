import React, { useEffect, useState } from "react";
import { Button } from "layers";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { PackageCard } from "./PackageCard";
import { Option, IRequest } from "models/interfaces";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getAllHomeRequestFilter } from "redux/actions/flight";
import { useTranslation } from "react-i18next";
import { RightSidebar } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { MobileFilters } from "components/modals/MobileFilter";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { LoginConfirm } from "components/modals/Login";
import AlarmIcon from "assets/images/noun-reminder.png";
import { title } from "process";
import { checkAvailabilityTravel } from "redux/actions/landing";
import { ScreenFilter } from "components/common/screenFilter";
import { MobileFilter } from "components/common/mobileFilter";

interface IProps {
  type: Option;
  size: Option;
  weight: Option;
  deliveryTypes: Option;
  payments: Option;
  services: Option;
  tab: number;
  onChangeTab: (key: any) => void;
  availableRequestData: any;
  setShareImage: Function;
}

export const AvailableRequests: React.FC<IProps> = ({
  type,
  size,
  weight,
  deliveryTypes,
  services,
  payments,
  tab,
  availableRequestData,
  setShareImage,
}) => {
  const windowSize = UseWindowSize();
  const isMobile = windowSize.width < 768;
  const isMobileTablet = windowSize.width < 992;
  const dispatch = useAppDispatch();
  const [count, setCount] = useState(0);
  const { t } = useTranslation();
  const [requestData, setRequestData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoad, setIsLoad] = useState(false);
  const [showOfferSidebar, setShowOfferSidebar] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const navigate = useNavigate();
  const [showLoginSidebar, setShowLoginSidebar] = useState(false);
  const [showSignInSidebar, setShowSignInSidebar] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showAlertSidebar, setShowAlertSidebar] = useState(false);
  const [hasLoadMore, setHasLoadMore] = useState(false);
  const [homeRequestByTravelInfo, setHomeRequestByTravelInfo] =
    useState<IRequest[]>();
  const str = window.location.pathname;
  // const homeRequestData = useAppSelector<any>((state) => state.homeRequestFilter);
  const homeRequestByTravelData = useAppSelector(
    (state) => state.filterHomeRequestSearch
  );
  const alertsCount: any = useAppSelector((state) => state.fareAlertCount);

  useEffect(() => {
    if (alertsCount?.isSuccess) {
      setCount(alertsCount.data.packageCount);
    }
  }, [alertsCount]);

  // const searchFlightResult: any = useAppSelector(
  //   (state) => state.homeRequestFilter
  // );

  // useEffect(() => {
  //   document.addEventListener(
  //     "click",
  //     function (e) {
  //       const target = e.target as HTMLTextAreaElement;
  //       const element = target.className;
  //       if (element === "bm-overlay") {
  //         document.documentElement.style.overflow = "auto";
  //         navigate("/");
  //       }
  //     },
  //     false
  //   );
  // }, []);

  const token = window.localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(
    token === "undefined" || token === "null" || token === null ? false : true
  );

  window.addEventListener("storage", () => {
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogin(false);
    else setIsLogin(true);
  });

  useEffect(() => {
    setIsLoading(true);
    const data = {
      type: "package",
      packagetypeIds: type.value ? type.value : null,
      weightrangeIds: weight?.value ? weight?.value : null,
      deliverytypeIds: deliveryTypes?.value ? deliveryTypes?.value : null,
      sizerangeIds: size?.value ? size?.value : null,
      serviceIds: services?.value ? services?.value : null,
      paymentmethodIds: payments?.value ? payments?.value : null,
      page: 1,
    };

    if (tab === 1 && isMobileTablet) {
      dispatch(getAllHomeRequestFilter(data));
    }
    setRequestData([]);
  }, [type, size, weight, deliveryTypes, payments, services]);

  useEffect(() => {
    if (
      window.localStorage.getItem("token") !== null &&
      window.localStorage.getItem("token") !== "null" &&
      window.localStorage.getItem("token") !== undefined &&
      window.localStorage.getItem("token") !== "undefined"
    )
      dispatch(checkAvailabilityTravel());
  }, []);

  // useEffect(() => {
  //   setIsLoading(true);
  //   if (availableRequestData?.length !== 0) {
  //     setRequestData(availableRequestData?.data);
  //     setPageCount(availableRequestData?.pageCount);
  //     setIsLoading(false);
  //     setIsEmpty(false);
  //     return;
  //   }
  //   // setRequestData([]);
  //   setIsEmpty(true);
  // }, [availableRequestData]);

  // useEffect(() => {
  //   if (availableRequestData?.length !== 0 && isLoad === true) {
  //     // setRequestData([...requestData, searchFlightResult]);
  //     setRequestData(requestData.concat(availableRequestData?.data));
  //     setIsLoad(false);
  //   }
  // }, [availableRequestData, requestData]);

  useEffect(() => {
    if (availableRequestData && availableRequestData?.length !== 0) {
      if (currentPage > 1) {
        setRequestData([...requestData, ...availableRequestData?.data]);
      } else setRequestData(availableRequestData?.data);
      setPageCount(availableRequestData?.pageCount);
      setIsLoading(false);
      if (availableRequestData.pageCount > 1) {
        setHasLoadMore(true);
      }
    }
  }, [availableRequestData]);

  useEffect(() => {
    if (homeRequestByTravelData?.length !== 0)
      setHomeRequestByTravelInfo(homeRequestByTravelData);
  }, [homeRequestByTravelData]);

  useEffect(() => {
    if (str?.includes("addTravel") && isLogin && windowSize?.width <= 991) {
      setShowLoginSidebar(false);
      if (isLogin) setShowOfferSidebar(true);
      else setShowLoginModal(!showLoginModal);
    }
  }, [str, windowSize]);

  const handleOfferSidebar = () => {
    if (windowSize?.width <= 991) {
      setShowLoginSidebar(false);
      if (isLogin) setShowOfferSidebar(true);
      else setShowLoginModal(!showLoginModal);
      navigate("/addTravel");
    }
  };

  useEffect(() => {
    if (showLoginSidebar) HandleLoginSidebar();
  }, [showLoginSidebar]);

  const HandleLoginSidebar = () => {
    setShowSignInSidebar(!showSignInSidebar);
  };

  const handleOpenFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  useEffect(() => {
    const str = window.location.pathname;
    if (str?.includes("/packageId/")) {
      const n = str.lastIndexOf("/");
      const result = str.substring(n + 1);
      setRequestData(
        availableRequestData?.data?.filter((item) => item.pkgId === result)
      );
      setHasLoadMore(false);
    } else {
      // setRequestData(availableRequestData?.data);
    }
  }, [availableRequestData]);

  const handleAlertSidebar = (data) => {
    setShowAlertSidebar(!showAlertSidebar);
    if (!window.location.pathname.includes("/set-alert")) navigate("set-alert");
  };

  return (
    <div className="requests-info-wrapper">
      <div className="text-center display-responsive">
        <Button
          variant="primary"
          className="add-travel-btn animated_btn w-90 mb-3"
          onClick={handleOfferSidebar}
        >
          {t("addMyTravel")}
        </Button>
      </div>
      {windowSize?.width >= 992 && (
        <h2 className="mt-2 mb-4 mx-3">{t("availableRequests")}</h2>
      )}
      {windowSize?.width < 992 && (
        <Row
          className="my-4"
          style={
            windowSize?.width < 768
              ? { width: "360px", margin: "auto", alignItems: "center" }
              : {
                  width: "745px",
                  margin: "auto",
                  alignItems: "center",
                  paddingRight: "30px",
                  paddingLeft: "30px",
                }
          }
        >
          <Col xs={6} className={`${isMobile && "pr-0"}`}>
            <h2>{t("availableRequests")}</h2>
          </Col>
          <Col xs={6} className="pl-0 text-right pr-0">
            <a className="filter-responsive-btn" onClick={handleOpenFilter}>
              <FontAwesomeIcon icon={faFilter} color="#00043d" />
            </a>
            {window.localStorage.getItem("token") && (
              <Button
                className="alert-responsive-btn"
                onClick={handleAlertSidebar}
              >
                <img src={AlarmIcon} className="alarm-icon" alt="attach-img" />
                <span className="setAlert-notification-count">{count}</span>
              </Button>
            )}
          </Col>
        </Row>
      )}

      {showOfferSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showOfferSidebar}
            // isLogin={isLogin}
            setIsOpen={setShowOfferSidebar}
            sidebarType="addTravel"
          />
        </div>
      )}
      {windowSize?.width > 992 ? (
        <Row className="home-request-info-wrapper dir-rtl">
          <Col xs={9} className="dir-ltr">
            <PackageCard
              isEmpty={isEmpty}
              isLoading={isLoading}
              setIsLoad={setIsLoad}
              requestData={requestData}
              type={type}
              size={size}
              weight={weight}
              deliveryTypes={deliveryTypes}
              payments={payments}
              services={services}
              pageCount={pageCount}
              availableRequestData={availableRequestData}
              setShareImage={setShareImage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              hasLoadMore={hasLoadMore}
            />
          </Col>
          <Col xs={3} className="dir-ltr">
            <ScreenFilter type="package" />
          </Col>
        </Row>
      ) : (
        <PackageCard
          isEmpty={isEmpty}
          isLoading={isLoading}
          setIsLoad={setIsLoad}
          requestData={requestData}
          type={type}
          size={size}
          weight={weight}
          deliveryTypes={deliveryTypes}
          payments={payments}
          services={services}
          pageCount={pageCount}
          availableRequestData={availableRequestData}
          setShareImage={setShareImage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          hasLoadMore={hasLoadMore}
        />
      )}

      {showLoginModal && (
        <LoginConfirm
          title={t("addTravel")}
          isOpen={showLoginModal}
          setIsOpen={setShowLoginModal}
          type="addTravel"
          setShowLoginSidebar={setShowLoginSidebar}
        />
      )}
      {isOpenFilter && (
        <MobileFilter
          type="package"
          isOpen={isOpenFilter}
          setIsOpen={setIsOpenFilter}
        />
      )}
      {showSignInSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSignInSidebar}
            setIsOpen={setShowSignInSidebar}
            setIsLogin={setIsLogin}
            sidebarType="login"
          />
        </div>
      )}
      {showAlertSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showAlertSidebar}
            setIsOpen={setShowAlertSidebar}
            sidebarType="alert"
            type="Package"
          />
        </div>
      )}
    </div>
  );
};
