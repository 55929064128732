import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import data from "json/myTravels.json";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { SkeletonGrid } from "components";
import { Cards } from "./Cards";
import { IMyTraveler } from "models/interfaces";
import { useTranslation } from "react-i18next";

interface IProps {
  travelerData: IMyTraveler[];
  setTrvId: Function;
}

export const TravelCard: React.FC<IProps> = ({ travelerData, setTrvId }) => {
  const [loading, setLoading] = useState(true);
  const windowSize = UseWindowSize();
  const isMobileTablet = windowSize.width < 992;
  const isMobile = windowSize.width < 768;
  const { t } = useTranslation();

  useEffect(() => {
    if (travelerData) setLoading(false);
  }, [travelerData]);

  return (
    <>
      {isMobile ? (
        <div className="container-horizontal">
          {travelerData?.map((item, idx) => (
            <div className="traveler-card-horizontal">
              <Cards key={idx} data={item} setTravelId={setTrvId} />
            </div>
          ))}
        </div>
      ) : (
        <div className="flex-grow-1 request-info-wrapper mb-3">
          <Row>
            {travelerData === undefined && loading ? (
              (data?.items ?? []).map((item) => (
                <Col key={item.name} lg={3} md={6} sm={12}>
                  <SkeletonGrid />
                </Col>
              ))
            ) : (
              <>
                {travelerData?.length !== 0 ? (
                  travelerData?.map((item, idx) => (
                    <Cards key={idx} data={item} setTravelId={setTrvId} />
                  ))
                ) : (
                  <div className="no-data-dashboard text-center">
                    {t("noTraveler")}
                  </div>
                )}
              </>
            )}
          </Row>
        </div>
      )}
    </>
  );
};
