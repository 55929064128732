import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { getAllCurrencies } from "redux/actions/types";
import { useAppDispatch } from "redux/store";

export const Services: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>Services</h2>
      </div>
      <div className="flex-grow-1 services-pages-wrapper my-5">
        <h4>{t("serviceFirstLine")}</h4>
        <h4>{t("serviceSecondLine")}</h4>
        <h3 className="mt-5">
          <span className="bullet-circle"></span>
          {t("deliveredTrustedTitle")}
        </h3>
        <p className="mt-4 mb-2">{t("temptNumberTitle")}</p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            {t("temptNumberItem1")}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            {t("temptNumberItem2")}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            {t("temptNumberItem3")}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            {t("temptNumberItem4")}
          </li>
        </ul>
        <p>{t("temptNumberEnd")}</p>
        <a href="accountType">
          <h3 className="mt-5">
            <span className="bullet-circle"></span>
            {t("usersEvaluationTitle")}
          </h3>
        </a>
        <p className="mt-4 mb-2">{t("usersEvaluationTitleItem")}</p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            {t("usersEvaluationItem1")}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            {t("usersEvaluationItem2")}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            {t("usersEvaluationItem3")}
          </li>
          <li>
            <FontAwesomeIcon icon={faCheck} />
            {t("usersEvaluationItem4")}
          </li>
        </ul>
        <h3 className="mt-5">
          <span className="bullet-circle"></span>
          {t("securePaymentTitle")}
        </h3>
        <p className="mt-4 mb-2">{t("securePaymentItem1")}</p>
        <p>{t("securePaymentItem2")}</p>
        <p>{t("securePaymentItem3")}</p>
        <p>{t("securePaymentItem4")}</p>
        <p>{t("securePaymentItem5")}</p>
        <h3 className="mt-5">
          <span className="bullet-circle"></span>
          {t("trackingServiceTitle")}
        </h3>
        <p className="mt-4 mb-2">{t("trackingServiceItem1")}</p>
        <p>{t("trackingServiceItem2")}</p>
        <h3 className="mt-5">
          <span className="bullet-circle"></span>
          {t("refundPolicyTitle")}
        </h3>
        <p className="mt-4 mb-2">{t("refundPolicyItem")}</p>
        <h3 className="mt-5">
          <span className="bullet-circle"></span>
          {t("chatFunctionality")}
        </h3>
        <p className="mt-4 mb-2">{t("chatFunctionalityItem")}</p>
        <h3 className="mt-5">
          <span className="bullet-circle"></span>
          {t("contractSenderTravelerTitle")}
        </h3>
        <p className="mt-4 mb-2">{t("contractSenderTravelerItem1")}</p>
        <p>{t("contractSenderTravelerItem2")}</p>
        <p>{t("contractSenderTravelerItem3")}</p>
        <h3 className="mt-5">
          <span className="bullet-circle"></span>
          {t("reviewRatingTitle")}
        </h3>
        <p className="mt-4 mb-2">{t("reviewRatingItem")}</p>
      </div>
      <Footer />
    </div>
  );
};
