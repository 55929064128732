import { createSlice } from "@reduxjs/toolkit";

export const filterOfferStatusSlice = createSlice({
  name: "filterOfferStatus",
  initialState: [],
  reducers: {
    filterOfferStatus: (state, action) => {
      return (state = action.payload.data);
    },
    filterOfferStatusFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { filterOfferStatus, filterOfferStatusFailure } =
filterOfferStatusSlice.actions;
export default filterOfferStatusSlice.reducer;
