import { toast } from "react-toastify";

export const rtkQueryErrorLogger = () => {
  return (next) => (action) => {
    let result = next(action);

    if (result?.payload?.status === 401) {
      // callRefresh();

      window.localStorage.removeItem("token");
      window.localStorage.removeItem("avatar");
      window.localStorage.removeItem("expire");
      window.localStorage.removeItem("timestampExpire");
      window.localStorage.removeItem("refreshToken");
      window.localStorage.removeItem("tokenType");
      window.localStorage.removeItem("userLevel");
      window.localStorage.removeItem("userLevelHexColor");
      const isBrowser = typeof window !== "undefined";
      if (isBrowser) {
        const win: Window = window;
        win.location = "/";
      }
    }
    if (result?.payload?.status === 500) {
      toast.error(result?.payload?.data?.message);
    }

    return result;
  };
};
