import { createSlice } from "@reduxjs/toolkit";

export const verifyPhoneNoSlice = createSlice({
  name: "verifyPhoneNo",
  initialState: [],
  reducers: {
    verifyPhoneNo: (state, action) => {
      return (state = action.payload);
    },
    verifyPhoneNoFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { verifyPhoneNo, verifyPhoneNoFailure } = verifyPhoneNoSlice.actions;
export default verifyPhoneNoSlice.reducer;
