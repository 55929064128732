import { createSlice } from "@reduxjs/toolkit";

export const createUserSlice = createSlice({
  name: "createUser",
  initialState: [],
  reducers: {
    createUser: (state, action) => {
      return (state = action.payload);
    },
    createUserFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { createUser, createUserFailure } = createUserSlice.actions;
export default createUserSlice.reducer;
