import { IChatData, IChatEngineData } from "models/interfaces";
import React, { useEffect, useState } from "react";
import { Row, Col, Modal } from "react-bootstrap";
import {
  ChatEngineWrapper,
  ChatSocket,
  ChatFeed,
  ChatCard,
} from "react-chat-engine";
import PlaneIcon from "../../assets/images/plane.png";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { getTimeZone } from "helpers/getTimeZone";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
  chatEngineData?: IChatEngineData;
  packageData?: IChatData;
}

export const Chat: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  chatEngineData,
  packageData,
}) => {
  const handleClose = () => setIsOpen(false);
  const [chatId, setChatId] = useState<number>(null);
  const [timezone, setTimezone] = useState<any>(null);
  const [projectId, setProjectId] = useState<string>("");
  const [chatAccessKey, setChatAccessKey] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [userSecret, setUserSecret] = useState<string>("");
  const { t } = useTranslation();

  useEffect(() => {
    if (chatEngineData) {
      setChatId(chatEngineData?.chatId);
      setProjectId(chatEngineData?.projectId);
      setChatAccessKey(chatEngineData?.chatAccessKey);
      setChatAccessKey(chatEngineData?.chatAccessKey);
      setUserName(chatEngineData?.userName);
      setUserSecret(chatEngineData?.userSecret);
      setTimezone(getTimeZone());
    }
    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }, [chatEngineData]);

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          setIsOpen(false);
        }
      },
      false
    );
  }, []);

  const CustomChatHeader = (chat) => {
    return (
      <div className="py-3 px-4">
        <Button
          variant="primary"
          className="chat-close-dropzone-btn"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Row>
          <Col xs={12} className="text-center header-card-plane">
            <div>
              {/* <span className="fw-bold">{packageData?.packagetypes}</span> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={4}
            className="text-left header-card-titles pr-0"
            // style={{ marginTop: "-5px" }}
          >
            <div>
              <span className="text-left">{packageData?.fromCountryCity}</span>
            </div>
          </Col>
          <Col xs={4} className="text-center header-card-plane p-0">
            <div>
              <span className="mb-0">
                ...{" "}
                <img
                  src={PlaneIcon}
                  className="card-request-icon mt-1"
                  alt="location-img"
                />{" "}
                ...
              </span>
            </div>
          </Col>
          <Col
            xs={4}
            className="header-card-titles pl-0"
            // style={{ marginTop: "-5px" }}
          >
            <div className="text-right">
              <span>{packageData?.toCountryCity}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={5} className="text-left header-card-titles pr-0">
            <div>
              <span className="text-left">{packageData?.fromCityEntity}</span>
            </div>
            <div className="mt-2">
              <span className="text-left">
                {t("sender")}: <strong>{packageData?.senderFullName}</strong>
              </span>
            </div>
          </Col>
          <Col xs={2}></Col>
          <Col xs={5} className="header-card-titles pl-0">
            <div className="text-right">
              <span>{packageData?.toCityEntity}</span>
            </div>
            <div className="text-right mt-2">
              <span>
                {t("traveler")}:{" "}
                <strong>{packageData?.travelerFullName}</strong>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div className="chat-one-to-one-container">
      {isOpen && projectId !== "" && (
        <ChatEngineWrapper>
          <ChatSocket
            projectID={projectId}
            userName={userName}
            userSecret={userSecret}
            chatID={chatId}
            chatAccessKey={chatAccessKey}
            offset={parseInt(timezone)}
          />
          <ChatFeed activeChat={chatId} renderChatHeader={CustomChatHeader} />
        </ChatEngineWrapper>
      )}
    </div>
  );
};
