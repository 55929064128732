/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IOfferSent } from "models/interfaces";
import { getUserInfo } from "redux/actions/Authorization";
import { useAppDispatch, useAppSelector } from "redux/store";
import { RightSidebar } from "layers";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProp {
  setShowReviewDetail: (key: any) => void;
  fade: boolean;
  reviewId: string;
  reviewOwner: string;
  reviewFor: string;
  from: string;
  to: string;
  date: string;
  packages: any;
  titleTab: string;
}
export const ReviewConfirm: React.FC<IProp> = ({
  setShowReviewDetail,
  fade,
  reviewId,
  reviewOwner,
  reviewFor,
  from,
  to,
  date,
  packages,
  titleTab,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showCover, setShowCover] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [isOpenWriteReview, setIsOpenWriteReview] = useState(false);
  const [closeConfirm, setCloseConfirm] = useState(false);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const userData = useAppSelector(
    (state) => state?.userInfo?.data && state?.userInfo?.data[0]?.data
  );
  const handleShow = () => {
    setShowCover(!showCover);
    setShowReviewDetail(showCover);
    setFadeOut(true);
  };

  useEffect(() => {
    if (closeConfirm) setShowReviewDetail(false);
  }, [closeConfirm]);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  const confirm = () => {
    setIsOpenWriteReview(!isOpenWriteReview);
    // navigate("/dashboard/review");
    navigate("/dashboard/review", { state: t(`${titleTab}`) });
  };

  const notConfirm = () => {
    setShowReviewDetail(false);
  };

  useEffect(() => {
    setUserName(`${userData.firstName} ${userData.lastName}`);
  }, [userData]);

  return (
    <div className="dashboard-more-detail-wrapper more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`receive-offer-card-wrapper mb-3 review-card-info-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Body className="request-card-border location-cover-info offer-card-cover-body">
          <Row className="detail-review-wrapper">
            <div className="review-question-text text-center">
              <span>Do you want to submit a Rating/Review for this person</span>
            </div>
            <div className="note-offer-detail mt-5 mx-auto">
              <Button
                variant="primary"
                className="confirm-review-btn"
                onClick={confirm}
              >
                {t("yes")}
              </Button>
              <Button
                variant="white"
                className="no-confirm-review-btn"
                onClick={notConfirm}
              >
                {t("no")}
              </Button>
            </div>
          </Row>
        </Card.Body>
      </Card>
      {isOpenWriteReview && (
        <div className="offer-sidebar">
          <RightSidebar
            setCloseConfirm={setCloseConfirm}
            isOpen={isOpenWriteReview}
            setIsOpen={setIsOpenWriteReview}
            sidebarType="writeReview"
            owner={reviewOwner}
            userName={userName}
            reviewFor={reviewFor}
            titleTab={titleTab}
            reviewData={{
              userName,
              fromTo: `${from} to ${to}`,
              date,
              packages,
            }}
            id={reviewId}
          />
        </div>
      )}
    </div>
  );
};
