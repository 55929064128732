import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCheck,
  faCertificate,
} from "@fortawesome/free-solid-svg-icons";
import { getAllCurrencies } from "redux/actions/types";
import { useAppDispatch } from "redux/store";

export const AccountType: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>{t("accountBenefits")}</h2>
      </div>
      <div className="flex-grow-1 other-pages-wrapper my-5">
        <p className="mt-4 account-type-text">{t("verifyUserAccount")}</p>
        <h4 className="mt-5">
          {t("regular")}{" "}
          <div className="accountType-userlevel-icons-wrapper account-type-svg">
            <FontAwesomeIcon
              className="mr-2 userlevel-certificate-icon"
              icon={faCertificate}
              color={"#e2d8ed"}
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              className="mr-2 userlevel-check-icon"
              icon={faCheck}
            />
          </div>
        </h4>
        <p className="mt-3 account-type-text">
          {t("profilePic")} <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("validPass")} <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("validPhone")} <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("validBankAccount")}{" "}
          <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("validID")} <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("proofOfAddress")}{" "}
          <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("successfulTransaction")} (3+)
          <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>

        <h4 className="mt-5">
          {t("bronze")}
          <div className="accountType-userlevel-icons-wrapper account-type-svg">
            <FontAwesomeIcon
              className="mr-2 userlevel-certificate-icon"
              icon={faCertificate}
              color={"#cd7f32"}
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              className="mr-2 userlevel-check-icon"
              icon={faCheck}
            />
          </div>
        </h4>
        <p className="mt-3 account-type-text">
          {t("profilePic")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
        <p className="account-type-text">
          {t("validPass")} <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("validPhone")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
        <p className="account-type-text">
          {t("validBankAccount")}{" "}
          <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("validID")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
        <p className="account-type-text">
          {t("proofOfAddress")}{" "}
          <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("successfulTransaction")} (3+)
          <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <h4 className="mt-5">
          {t("silver")}
          <div className="accountType-userlevel-icons-wrapper account-type-svg">
            <FontAwesomeIcon
              className="mr-2 userlevel-certificate-icon"
              icon={faCertificate}
              color={"#c0c0c0"}
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              className="mr-2 userlevel-check-icon"
              icon={faCheck}
            />
          </div>
        </h4>
        <Row>
          <Col xs={4}>
            <p className="mt-3 account-type-text">
              {t("profilePic")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
            <p className="account-type-text">
              {t("validPass")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faClose} />
            </p>
            <p className="account-type-text">
              {t("validPhone")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
            <p className="account-type-text">
              {t("validBankAccount")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faClose} />
            </p>
            <p className="account-type-text">
              {t("validID")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
            <p className="account-type-text">
              {t("proofOfAddress")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faClose} />
            </p>
            <p className="account-type-text">
              {t("successfulTransaction")} (3+)
              <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
          </Col>
          <Col xs={2}>
            <p className="mt-3 account-type-text">
              <strong>{t("or")}</strong>
            </p>
          </Col>

          <Col xs={6}>
            <p className="mt-3 account-type-text">
              {t("profilePic")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
            <p className="account-type-text">
              {t("validPass")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
            <p className="account-type-text">
              {t("validPhone")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
            <p className="account-type-text">
              {t("validBankAccount")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faClose} />
            </p>
            <p className="account-type-text">
              {t("validID")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
            <p className="account-type-text">
              {t("proofOfAddress")}{" "}
              <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
            </p>
            <p className="account-type-text">
              {t("successfulTransaction")} (3+)
              <FontAwesomeIcon className="account-type-svg" icon={faClose} />
            </p>
          </Col>
        </Row>
        <h4 className="mt-5">
          {t("golden")}
          <div className="accountType-userlevel-icons-wrapper account-type-svg">
            <FontAwesomeIcon
              className="mr-2 userlevel-certificate-icon"
              icon={faCertificate}
              color={"#ffd700"}
            ></FontAwesomeIcon>
            <FontAwesomeIcon
              className="mr-2 userlevel-check-icon"
              icon={faCheck}
            />
          </div>
        </h4>
        <p className="mt-3 account-type-text">
          {t("profilePic")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
        <p className="account-type-text">
          {t("validPass")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
        <p className="account-type-text">
          {t("validPhone")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
        <p className="account-type-text">
          {t("validBankAccount")}{" "}
          <FontAwesomeIcon className="account-type-svg" icon={faClose} />
        </p>
        <p className="account-type-text">
          {t("validID")} <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
        <p className="account-type-text">
          {t("proofOfAddress")}{" "}
          <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
        <p className="account-type-text">
          {t("successfulTransaction")} (3+)
          <FontAwesomeIcon className="account-type-svg" icon={faCheck} />
        </p>
      </div>
      <Footer />
    </div>
  );
};
