import { createSlice } from "@reduxjs/toolkit";

export const offerStatusSlice = createSlice({
  name: "offerStatus",
  initialState: [],
  reducers: {
    offerStatus: (state, action) => {
      return (state = action.payload.data);
    },
    offerStatusFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { offerStatus, offerStatusFailure } =
offerStatusSlice.actions;
export default offerStatusSlice.reducer;
