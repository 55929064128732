import { createSlice } from "@reduxjs/toolkit";

export const changeButtonPaymentStatusOfferSlice = createSlice({
  name: "changeButtonPaymentStatusOffer",
  initialState: [],
  reducers: {
    changeButtonPaymentStatusOffer: (state, action) => {
      return (state = action.payload);
    },
    changeButtonPaymentStatusOfferFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { changeButtonPaymentStatusOffer, changeButtonPaymentStatusOfferFailure } = changeButtonPaymentStatusOfferSlice.actions;
export default changeButtonPaymentStatusOfferSlice.reducer;