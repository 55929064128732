import { createSlice } from "@reduxjs/toolkit";

export const allFareAlertsSlice = createSlice({
  name: "allFareAlerts",
  initialState: [],
  reducers: {
    allFareAlerts: (state, action) => {
      return (state = action.payload.data);
    },
    allFareAlertsFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allFareAlerts, allFareAlertsFailure } =
  allFareAlertsSlice.actions;
export default allFareAlertsSlice.reducer;

