import { BaseService } from "./baseService";

class AuthorizationService extends BaseService {
  createUser(data) {
    return this.registerHttp().post("/register", data);
  }

  login(data) {
    return this.http().post("/token", data);
  }

  forgetPassword(data) {
    return this.http().post("/ForgotPassword", data);
  }

  refresh(data) {
    return this.http().post("/refresh", data);
  }

  validToken(data) {
    return this.http().post("/token/IsValid", data);
  }

  logout(data) {
    return this.authorizedHttp().post("/Logout", data);
  }

  checkToken(data) {
    return this.http().post("/ExternalAuth/CheckToken", data);
  }

  getUserInfo() {
    return this.authorizedHttp().get("/user/UserInfo");
  }

  editUserInfo(data) {
    return this.authorizedFormDataHttp().put("/user/UserInfo/Edit", data);
  }

  verifyPhoneNumber(data) {
    return this.authorizedHttp().post("/user/UserInfo/Verify", data);
  }

  sendVerificationCode(data) {
    return this.authorizedHttp().post(
      "/user/UserInfo/SendVerificationCode",
      data
    );
  }

  getOtpCode(data) {
    return this.http().post("/register/OTP", data);
  }

  uploadUserIdentity(data) {
    return this.authorizedFormDataHttp().post("/user/UserInfo/Upload", data);
  }
}

export default new AuthorizationService();
