import { createSlice } from "@reduxjs/toolkit";

export const setFareAlertSlice = createSlice({
  name: "setFareAlert",
  initialState: [],
  reducers: {
    setFareAlert: (state, action) => {
      return (state = action.payload.data);
    },
    setFareAlertFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { setFareAlert, setFareAlertFailure } =
  setFareAlertSlice.actions;
export default setFareAlertSlice.reducer;

