import React, { useState, useEffect } from "react";
import { GoogleMapAPI, Uploader } from "components";
import { Button, Input, LabeledInput } from "layers";
import { Modal } from "react-bootstrap";
import UserAvatar from "./../../assets/images/user-avatar.png";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { IUserInfo } from "models/interfaces";
import { ProfileUploader } from "components";
import { SkeletonForm } from "components/Skeleton/skeletonForm";
import { PersonaTemplate } from "components/persona";
import {
  modifyUserInfo,
  verifyPhoneNumber,
  sendVerifyCode,
  getUserInfo,
} from "redux/actions/Authorization";
import { useAppDispatch, useAppSelector } from "redux/store";
import { toast } from "react-toastify";
import PhoneInput, { getCountryCallingCode } from "react-phone-number-input";
import { Confirmation } from "components/modals/Confirmation";
import { Oval } from "react-loader-spinner";
import Geocode from "react-geocode";
import Select from "react-select";
import OtpInput from "react-otp-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faEye,
  faChevronDown,
  faChevronRight,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  isValidFirstName,
  isValidLastName,
  isValidPhoneNumber,
  isValidEmail,
  isMatchPasswords,
  isValidFormatEmail,
  isValidAdrs,
  isValidCountry,
  isValidState,
  isValidCity,
  isValidPostalCode,
  isValid,
  isValidPhoneNumberLength,
  isValidAddress,
  isPatternPasswordValid,
  isPatternPhoneValid,
} from "helpers/profileValidation";
import { gatVerifyAddress, getVerificationType } from "redux/actions/persona";
import { getAllCountriesList } from "redux/actions/types";
import { Rating } from "react-simple-star-rating";
import { useTranslation } from "react-i18next";
import { UploadIdentity } from "components/modals/UploadIdentity";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import type { GetProp, UploadFile, UploadProps } from "antd";

interface IProps {
  userData: IUserInfo;
}

export const PersonalInfo: React.FC<IProps> = ({ userData }) => {
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];
  const [disable, setDisable] = useState(false);
  const [deleted, detDeleted] = useState(false);
  const [otpCodeValue, setOtpCodeValue] = useState("");
  const [receivedSMS, setReceivedSMS] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPersonaModal, setIsOpenPersonaModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const size = UseWindowSize();
  const [passportPhoto, setPassportPhoto] = useState(null);
  const [secondIdentityPhoto, setSecondIdentityPhoto] = useState(null);
  const [code, setCode] = useState(`+1`);
  const [rating, setRating] = useState(0);
  const [countryId, setCountryId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [avatar, setAvatar] = useState(UserAvatar);
  const [personalPhoto, setPersonalPhoto] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isOpenOtpCode, setIsOpenOtpCode] = useState(false);
  const [isPassportVerified, setIsPassportVerified] = useState(false);
  const [isSecondIdentityVerified, setIsSecondIdentityVerified] =
    useState(false);
  const { t } = useTranslation();
  const [hasOneDigitChars, setHasOneDigitChars] = useState(false);
  const [hasEightChars, setHasEightChars] = useState(false);
  const [hasUppercaseChar, setHasUppercaseChar] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [changeImageStyle, setChangeImageStyle] = useState(false);
  const [verifiedNumberDone, setVerifiedNumberDone] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowRePassword, setIsShowRePassword] = useState(false);
  const [positionLat, setLat] = useState(null);
  const [positionLong, setLng] = useState(null);
  const isMobile = size.width < 768;
  const isTablet = size.width >= 768 && size.width < 992;
  const [editClicked, setEditClicked] = useState(false);
  const [verifiedClicked, setVerifiedClicked] = useState(false);
  const [positionAddress, setPositionAddress] = useState<any>("CA");
  const [isAddressVerified, setIsAddressVerified] = useState("");
  const [isChangedAddress, setIsChangedAddress] = useState(false);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [countries, setCountries] = useState<any>();
  const [isPersonalPhotoRemoved, setIsPersonalPhotoRemoved] = useState(false);
  const [isPassportPhotoRemoved, setIsPassportPhotoRemoved] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [isLoadingCode, setIsLoadingCode] = useState(false);
  const [countryCode, setCountryCode] = useState<any>({
    value: "Canada",
    label: (
      <>
        <div className="number-code-wrapper">
          <img
            className="number-code-flag"
            src="https://upload.wikimedia.org/wikipedia/en/c/cf/Flag_of_Canada.svg"
          />
          <span className="number-code-code">+1</span>
        </div>
      </>
    ),
  });
  const [country, setCountry] = useState<any>();
  const [isSecondIdentityPhotoRemoved, setIsSecondIdentityPhotoRemoved] =
    useState(false);
  const verificationCodeData = useAppSelector<any>(
    (state) => state.verificationCode
  );
  const verifyNumberData = useAppSelector<any>((state) => state.verifyPhoneNo);
  const allCountries = useAppSelector<any>((state) => state.allCountries);

  const [userInfo, setUserInfo] = useState<IUserInfo>({
    personalPhoto: null,
    aboutMe: "",
    firstName: "",
    state: "",
    city: "",
    postalCode: "",
    lastName: "",
    displayName: "",
    phoneCode: "",
    phoneNumberWithoutCode: "",
    verificationCode: "",
    email: "",
    address: "",
    positionLat: "",
    positionLong: "",
    passportPhoto: null,
    secondIdentityPhoto: null,
    isPhoneNumberVerified: false,
    isAddressVerified: false,
    isPassportVerified: false,
    isSecondIdentityVerified: false,
    balance: "",
    password: "",
    rePassword: "",
    rating: 0,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
    if (
      (e.target.name === "address" && e.target.value === userData.address) ||
      (e.target.name === "state" && e.target.value === userData.state) ||
      (e.target.name === "city" && e.target.value === userData.city) ||
      (e.target.name === "postalCode" && e.target.value == userData.postalCode)
    ) {
      setIsChangedAddress(false);
    } else {
      setIsChangedAddress(true);
    }

    if (
      e.target.name === "phoneNumberWithoutCode" &&
      e.target.value !== userData.phoneNumberWithoutCode
    ) {
      setIsVerified(false);
    } else {
      setIsVerified(userData.isPhoneNumberVerified);
    }
  };

  const handleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };
  const handleShowRePassword = () => {
    setIsShowRePassword(!isShowRePassword);
  };

  const isUpperCaseLowerCase = () => {
    const upperCaseRegex = /[A-Z]/;
    const lowerCaseRegex = /[a-z]/;
    return (
      lowerCaseRegex.test(userInfo?.password) &&
      upperCaseRegex.test(userInfo?.password)
    );
  };

  const isSpecialChars = () => {
    const passwordRegex = /[@$!%?&]/;
    return passwordRegex.test(userInfo?.password);
  };

  const hasOneDigit = () => {
    const passwordRegex = /\d/;
    return passwordRegex.test(userInfo?.password);
  };

  const isEightChars = () => {
    const passwordRegex = /.{8,}/;
    return passwordRegex.test(userInfo?.password);
  };

  useEffect(() => {
    if (isEightChars()) {
      setHasEightChars(true);
    } else {
      setHasEightChars(false);
    }
    if (isUpperCaseLowerCase()) {
      setHasUppercaseChar(true);
    } else {
      setHasUppercaseChar(false);
    }
    if (isSpecialChars()) {
      setHasSpecialChar(true);
    } else {
      setHasSpecialChar(false);
    }
    if (hasOneDigit()) {
      setHasOneDigitChars(true);
    } else {
      setHasOneDigitChars(false);
    }
  }, [userInfo]);

  useEffect(() => {
    if (userData && countryName) {
      if (
        userInfo?.address !== userData.address ||
        userInfo?.state !== userData.state ||
        userInfo?.city !== userData.city ||
        userInfo?.postalCode !== userData.postalCode ||
        countryName !== userData.country
      )
        setIsChangedAddress(true);
      else setIsChangedAddress(false);
    }
  }, [userData, userInfo, countryName]);

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const updateAvatars = (info) => {
    setAvatar(URL.createObjectURL(info.file.originFileObj));
    setChangeImageStyle(true);
    setPersonalPhoto(info.file.originFileObj);
    window.localStorage.setItem(
      "avatar",
      URL.createObjectURL(info.file.originFileObj)
    );
    window.dispatchEvent(new Event("avatar"));
  };

  // const updateAvatars = (e) => {
  //   setAvatar(URL.createObjectURL(e.target.files[0]));
  //   setChangeImageStyle(true);
  //   setPersonalPhoto(e.target.files[0]);
  //   window.localStorage.setItem(
  //     "avatar",
  //     URL.createObjectURL(e.target.files[0])
  //   );
  //   window.dispatchEvent(new Event("avatar"));
  // };

  const editProfile = () => {
    setEditClicked(true);
    if (!isValid(true, userInfo, code)) {
      toast.error(
        lang === "fa"
          ? "پارامترها را به درستی وارد نمایید"
          : "Enter the parameters correctly!"
      );
      setDisable(true);
      return;
    }
    setDisable(false);
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    if (isConfirmed && isOpenModal) {
      updatePersonalInformation();
    }
  }, [isConfirmed, isOpenModal]);

  useEffect(() => {
    dispatch(getAllCountriesList());
  }, []);

  const verifyAddress = () => {
    setVerifiedClicked(true);
    if (!isValidAddress(userInfo, countryName)) {
      toast.error(
        "please fill out the address fields and click 'Update Information' below."
      );
      return;
    } else {
      // dispatch(gatVerifyAddress());
      setIsOpenPersonaModal(true);
    }
  };

  const verifyNumber = () => {
    if (otpCodeValue) {
      const data = {
        phoneCode: code,
        phoneNumberWithoutCode: userInfo?.phoneNumberWithoutCode,
        verifyCode: otpCodeValue,
      };
      dispatch(verifyPhoneNumber(data));
      setOtpCodeValue("");
    }
  };

  useEffect(() => {
    if (verifyNumberData?.isSuccess) {
      setIsOpenOtpCode(false);
      setVerifiedNumberDone(true);
      window.location.reload();
      return;
    } else if (!verifyNumberData?.isSuccess) {
      setIsLoadingCode(false);
    }
  }, [verifyNumberData]);

  const sendSMS = () => {
    verifySms();
  };

  const verifySms = () => {
    const data = {
      phoneCode: code,
      phoneNumberWithoutCode: userInfo.phoneNumberWithoutCode,
    };
    dispatch(sendVerifyCode(data));
  };

  useEffect(() => {
    if (verificationCodeData?.isSuccess) {
      setReceivedSMS(true);
      setIsOpenOtpCode(true);
      return;
    }
    setReceivedSMS(false);
  }, [verificationCodeData]);

  useEffect(() => {
    setCountries(
      allCountries?.map((item) => {
        return {
          value: item.name,
          flag: item.flag,
          code: item.number,
          id: item.id,
          label: (
            <>
              <div className="number-code-wrapper">
                <span className="number-code-name">{item.name}</span>
              </div>
            </>
          ),
        };
      })
    );
  }, [allCountries]);

  const handleChangeCountryCode = (selected) => {
    const countryCode = {
      value: selected.value,
      label: (
        <>
          <div className="number-code-wrapper">
            <img className="number-code-flag" src={selected.flag} />
            <span className="number-code-code">{selected.code}</span>
          </div>
        </>
      ),
    };
    setCode(selected.code);
    setCountryCode(countryCode);
  };

  const handleChangeCountry = (selected) => {
    const options = {
      value: selected.id,
      label: selected.value,
    };
    setCountry(options);
    setCountryId(selected.id);
    setCountryName(selected.value);
  };

  useEffect(() => {
    if (userData) {
      setPersonalPhoto(userData?.personalPhoto);
      setPassportPhoto(userData?.passportPhoto);
      setSecondIdentityPhoto(userData?.secondIdentityPhoto);
      setLat(userData.positionLat);
      setLng(userData.positionLong);
      setCode(userData.phoneCode ? userData.phoneCode : "+1");
      setCountryId(
        userData.countryId
          ? userData.countryId
          : "39464629-9724-4a0c-c1e4-08dadb809a4a"
      );
      setCountryName(userData.country);
      const cId = userData.countryId
        ? userData.countryId
        : "39464629-9724-4a0c-c1e4-08dadb809a4a";
      const cCode = userData.phoneCode ? userData.phoneCode : "+1";
      setCountry(
        allCountries?.map((c) => {
          if (c.id === cId) {
            return {
              value: c.name,
              label: c.name,
            };
          }
        })
      );
      setCountryCode(
        allCountries?.map((c) => {
          if (c.number === cCode) {
            return {
              value: c.name,
              label: (
                <>
                  <div className="number-code-wrapper">
                    <img className="number-code-flag" src={c.flag} />
                    <span className="number-code-code">{c.number}</span>
                  </div>
                </>
              ),
            };
          }
        })
      );

      setIsVerified(userData.isPhoneNumberVerified);
      // setIsAddressVerified(userData.isAddressVerified);
      setIsAddressVerified(userData.addressVerifyStatus);
      setIsPassportVerified(userData.isPassportVerified);
      setIsSecondIdentityVerified(userData.isSecondIdentityVerified);
      setRating(userData.rating);
      setUserInfo({
        ...userInfo,
        aboutMe: userData.aboutMe,
        firstName: userData.firstName,
        lastName: userData.lastName,
        state: userData.state,
        city: userData.city,
        postalCode: userData.postalCode,
        displayName: userData.displayName,
        address: userData.address,
        phoneNumberWithoutCode: userData.phoneNumberWithoutCode,
        email: userData.email,
      });
    }
  }, [userData, allCountries]);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (userData && (!userData.phoneNumber || userData.phoneNumber === "+1")) {
      Geocode.setApiKey("AIzaSyAtDg1xWnADH7dCR_ZaJmhwTqMmQo9-VGM");

      Geocode.fromLatLng(userData.positionLat, userData.positionLong).then(
        (response) => {
          const address = response.results[0]?.address_components[5]?.short_name
            ? response.results[0]?.address_components[5]?.short_name
            : "CA";
          setPositionAddress(address);
          if (getCountryCallingCode(address))
            setCode(`+${getCountryCallingCode(address)}`);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [userData]);

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 50,
      minWidth: 130,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#fff",
      whiteSpace: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "auto",
    }),
  };

  const preventTypeText = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const updatePersonalInformation = () => {
    callUpdateInfo();
  };

  const handleOtpChange = (e) => {
    setOtp(e);
    if (e.length === 6) {
      setIsLoadingCode(true);
      setOtpCodeValue(e);
    }
  };

  useEffect(() => {
    if (otpCodeValue.length === 6) verifyNumber();
  }, [otpCodeValue]);

  const callUpdateInfo = () => {
    const body = new FormData();

    body.append("PersonalPhoto", personalPhoto);
    body.append("AboutMe", userInfo.aboutMe ? userInfo.aboutMe : "");
    body.append("FirstName", userInfo.firstName ? userInfo.firstName : "");
    body.append("LastName", userInfo.lastName ? userInfo.lastName : "");
    body.append(
      "DisplayName",
      userInfo.displayName ? userInfo.displayName : ""
    );
    body.append("phoneCode", code);
    body.append("PhoneNumberWithoutCode", userInfo.phoneNumberWithoutCode);
    body.append("Email", userInfo.email);
    body.append("Address", userInfo.address ? userInfo.address : "");
    body.append("PositionLat", positionLat ? positionLat : "");
    body.append("PositionLong", positionLong ? positionLong : "");
    body.append("state", userInfo.state ? userInfo.state : "");
    body.append("city", userInfo.city ? userInfo.city : "");
    body.append("postalCode", userInfo.postalCode ? userInfo.postalCode : "");
    body.append("CountryId", countryId ? countryId : "");
    body.append("Password", userInfo.password);
    body.append("RePassword", userInfo.rePassword);
    body.append(
      "IsPersonalPhotoRemoved",
      JSON.stringify(isPersonalPhotoRemoved)
    );
    body.append(
      "IsSecondIdentityPhotoRemoved",
      JSON.stringify(isPersonalPhotoRemoved)
    );
    body.append(
      "IsPassportPhotoRemoved",
      JSON.stringify(isPassportPhotoRemoved)
    );
    if (passportPhoto) body.append("PassportPhoto", passportPhoto);
    body.append(
      "SecondIdentityPhoto",
      secondIdentityPhoto ? secondIdentityPhoto : ""
    );
    if (!disable) dispatch(modifyUserInfo(body));
  };

  useEffect(() => {
    // if (deleted && personalPhoto === null) setIsPersonalPhotoRemoved(true);
    // else setIsPersonalPhotoRemoved(false);
    if (deleted && passportPhoto === null) setIsPassportPhotoRemoved(true);
    else setIsPassportPhotoRemoved(false);
    if (deleted && secondIdentityPhoto === null)
      setIsSecondIdentityPhotoRemoved(true);
    else setIsSecondIdentityPhotoRemoved(false);
  }, [passportPhoto, secondIdentityPhoto]);

  return (
    <div className="mt-3">
      <PersonaTemplate
        isOpen={isOpenPersonaModal}
        setIsOpen={setIsOpenPersonaModal}
      />
      {!isMobile && (
        <Row>
          <Col xs={7}>
            {/* <h1 className="account-title">Personal information</h1> */}
          </Col>
          {/* <Col xs={5} className="pr-3 align-self-center mb-4 text-right ">
            <Button
              variant="primary"
              className="top-update-info-btn"
              onClick={editProfile}
            >
              Update Information
            </Button>
          </Col> */}
        </Row>
      )}
      {userData ? (
        <>
          <div className="d-flex ml-15">
            <div
              className={`${
                "user-profile-box"
                // changeImageStyle ? "user-profile-box" : "user-default-box"
              }`}
            >
              {/* <Input
                label={
                  <img
                    src={
                      userData?.personalPhoto && !changeImageStyle
                        ? userData?.personalPhoto
                        : avatar
                    }
                    alt="user-avatar"
                    className="avatar-main-img"
                  />
                }
                size="sm"
                id="avatar-input"
                name="avatar"
                type="file"
                className="avatar-control-file"
                onChange={updateAvatars}
              /> */}
              <ImgCrop rotationSlider>
                <Upload
                  maxCount={1}
                  action=""
                  listType="picture-card"
                  onChange={updateAvatars}
                  showUploadList={false}
                  onPreview={onPreview}
                >
                  {avatar ? (
                    <img
                      src={
                        userData?.personalPhoto && !changeImageStyle
                          ? userData?.personalPhoto
                          : avatar
                      }
                      alt="avatar"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <div>Upload</div>
                  )}
                </Upload>
              </ImgCrop>
              <Rating
                size={isMobile ? 16 : 20}
                initialValue={rating}
                readonly
              />
            </div>
            <div className="profile-box-container w-100">
              <span className="short-label">{t("aboutMe")}</span>
              <div className="labeled-input-wrapper">
                <LabeledInput
                  size="sm"
                  id="aboutMe"
                  name="aboutMe"
                  placeholder={t("aboutMe")}
                  className="custom-textarea-personal-info inputText"
                  type="text"
                  textArea={true}
                  rows={2}
                  value={userInfo.aboutMe}
                  onChange={handleChange}
                />
                <span className="profile-char-number">
                  {userInfo?.aboutMe?.length} {t("character")}
                </span>
              </div>
            </div>
          </div>

          <Row className={`mt-4 mx-auto ${isTablet && "w-700"}`}>
            <Col xs={isMobile ? 12 : 6} className="mb-4 pr-0">
              <div className="labeled-input-wrapper">
                <LabeledInput
                  size="sm"
                  id="firstName"
                  name="firstName"
                  placeholder={t("firstName")}
                  className={`half-custom-input-register inputText w-100 ${
                    editClicked &&
                    !isValidFirstName(userInfo.firstName) &&
                    "empty-input-style"
                  }`}
                  value={userInfo.firstName}
                  onChange={handleChange}
                  disabled={isPassportVerified || isSecondIdentityVerified}
                />
              </div>
              {editClicked && !isValidFirstName(userInfo.firstName) && (
                <span className="err-validation">Required!</span>
              )}
            </Col>
            <Col
              xs={isMobile ? 12 : 6}
              className={`mb-4 ${!isMobile && "text-left"} pr-0`}
            >
              <div className="labeled-input-wrapper">
                <LabeledInput
                  size="sm"
                  id="lastName"
                  name="lastName"
                  placeholder={t("lastName")}
                  className={`half-custom-input-register inputText w-100 ${
                    editClicked &&
                    !isValidLastName(userInfo.lastName) &&
                    "empty-input-style"
                  }`}
                  value={userInfo.lastName}
                  onChange={handleChange}
                  disabled={isPassportVerified || isSecondIdentityVerified}
                />
              </div>
              {editClicked && !isValidLastName(userInfo.lastName) && (
                <span className="err-validation">Required!</span>
              )}
            </Col>
            <Col xs={12} className="mb-4 pr-0">
              <div className="labeled-input-wrapper">
                <LabeledInput
                  size="sm"
                  id="displayName"
                  name="displayName"
                  placeholder={t("displayName")}
                  className={`half-custom-input-register inputText w-100`}
                  value={userInfo.displayName}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col
              xs={12}
              className={`d-flex text-left phone-wrapper dir-ltr pr-0 ${
                isMobile && "flex-wrap"
              }`}
            >
              <Select
                styles={customStyle}
                className="code-number-wrapper d-inline-block"
                value={countryCode}
                onChange={handleChangeCountryCode}
                options={countries}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
              <div className="labeled-input-wrapper d-inline-flex phone-labeled-personal-input">
                <LabeledInput
                  size="sm"
                  id="phoneNumberWithoutCode"
                  name="phoneNumberWithoutCode"
                  placeholder={t("phoneNumber")}
                  className={`${
                    size.width < 768
                      ? "responsive-half-custom-phone-register"
                      : `${
                          isVerified
                            ? "custom-phone-personal width-405"
                            : verifiedNumberDone
                            ? "verified-half-custom-phone-personal"
                            : "half-custom-phone-personal"
                        }`
                  } inputText d-inline-flex ${
                    editClicked &&
                    (!isValidPhoneNumber(userInfo.phoneNumberWithoutCode) ||
                      !isPatternPhoneValid(
                        userInfo.phoneNumberWithoutCode,
                        code
                      )) &&
                    "empty-input-style"
                  }`}
                  value={userInfo.phoneNumberWithoutCode}
                  onChange={handleChange}
                  onKeyPress={preventTypeText}
                />
                <div className="d-inline-block">
                  {isVerified && (
                    <Button className="verified-address-btn">Verified</Button>
                  )}
                </div>
              </div>
              {/* {receivedSMS && !isVerified && (
                <div className="labeled-input-wrapper d-inline-flex">
                  <LabeledInput
                    size="sm"
                    id="verificationCode"
                    name="verificationCode"
                    placeholder="Verification Code"
                    className={`${
                      size.width < 768
                        ? "responsive-half-custom-phone-register"
                        : "half-custom-verify-code"
                    } inputText d-inline-flex`}
                    value={userInfo.verificationCode}
                    onChange={handleChange}
                  />
                </div>
              )} */}
              {!isVerified && !verifiedNumberDone && (
                <Button
                  onClick={sendSMS}
                  variant="white"
                  className="without-verify-mobile-btn"
                >
                  {t("sendVerificationCode")}
                  {isLoading && (
                    <Oval
                      width="15"
                      height="15"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                    />
                  )}
                </Button>
              )}
            </Col>
            <Col xs={12} className="mb-4 pr-0">
              {editClicked &&
                !isValidPhoneNumber(userInfo.phoneNumberWithoutCode) && (
                  <span className="err-validation">Required!</span>
                )}
              {editClicked &&
                !isPatternPhoneValid(userInfo.phoneNumberWithoutCode, code) && (
                  <span className="err-validation">
                    Phone number format is not correct!
                  </span>
                )}
            </Col>
            <Col xs={12} className="mb-4 pr-0">
              <div className="labeled-input-wrapper w-100">
                <LabeledInput
                  size="sm"
                  id="email"
                  name="email"
                  placeholder={t("email")}
                  className={`full-custom-input-register dir-ltr text-left pl-2 ${
                    editClicked &&
                    (!isValidEmail(userInfo.email) ||
                      !isValidFormatEmail(userInfo.email)) &&
                    "empty-input-style"
                  } inputText`}
                  value={userInfo.email}
                  onChange={handleChange}
                  disabled
                />
              </div>
              {editClicked && !isValidEmail(userInfo.email) && (
                <span className="err-validation">Required!</span>
              )}
              {editClicked && !isValidFormatEmail(userInfo.email) && (
                <span className="err-validation">
                  Email format isn't correct!
                </span>
              )}
            </Col>
            <Col xs={12} className="mb-4 relative pr-0">
              <span className="country-title-personal-info">Country</span>
              <Select
                styles={customStyle}
                className={`country-name-wrapper d-inline-block w-100 ${
                  verifiedClicked &&
                  !isValidCountry(countryName) &&
                  "custom-empty-input-style"
                }`}
                value={country}
                onChange={handleChangeCountry}
                options={countries}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
              {verifiedClicked && !isValidCountry(countryName) && (
                <span className="err-validation">Required!</span>
              )}
            </Col>
            <Col xs={12} className="mb-4 pr-0">
              <div className="labeled-input-wrapper position-relative">
                <LabeledInput
                  size="sm"
                  id="address"
                  name="address"
                  placeholder={t("address")}
                  className={`full-custom-input-register inputText ${
                    verifiedClicked &&
                    !isValidAdrs(userInfo.address) &&
                    "empty-input-style"
                  }`}
                  value={userInfo.address}
                  onChange={handleChange}
                />
              </div>
              {verifiedClicked && !isValidAdrs(userInfo.address) && (
                <span className="err-validation">Required!</span>
              )}
              <div className="d-inline-block">
                {/* {isAddressVerified ? (
                  <Button className="verified-address-btn">Verified</Button>
                ) : (
                  <Button
                    className={`${
                      userData.isAddressNeedsForReview
                        ? "needs-review-address-btn"
                        : "verify-address-btn"
                    } `}
                    onClick={verifyAddress}
                    disabled={userData.isAddressNeedsForReview}
                  >
                    {userData.isAddressNeedsForReview
                      ? t("beingEvaluated")
                      : t("verify")}
                  </Button>
                )} */}
                {isAddressVerified === "Passed" ? (
                  <Button disabled className="verified-address-btn">
                    Verified
                  </Button>
                ) : isAddressVerified === "Pending" ? (
                  <Button disabled className="needs-review-address-btn">
                    {isAddressVerified}
                  </Button>
                ) : (
                  <Button
                    className="verify-address-btn"
                    onClick={verifyAddress}
                  >
                    {t("verify")}
                  </Button>
                )}
              </div>
            </Col>
            <Col xs={isMobile ? 12 : 4} className="mb-4 pr-0">
              <div className="labeled-input-wrapper">
                <LabeledInput
                  size="sm"
                  id="state"
                  name="state"
                  placeholder={t("state")}
                  className={`custom-state-input inputText w-100 ${
                    verifiedClicked &&
                    !isValidState(userInfo.state) &&
                    "empty-input-style"
                  }`}
                  value={userInfo.state}
                  onChange={handleChange}
                />
              </div>
              {verifiedClicked && !isValidState(userInfo.state) && (
                <span className="err-validation">Required!</span>
              )}
            </Col>
            <Col
              xs={isMobile ? 12 : 4}
              className={`mb-4 ${!isMobile && "text-left"} pr-0`}
            >
              <div className="labeled-input-wrapper">
                <LabeledInput
                  size="sm"
                  id="city"
                  name="city"
                  placeholder={t("city")}
                  className={`custom-city-input inputText w-100 ${
                    verifiedClicked &&
                    !isValidCity(userInfo.city) &&
                    "empty-input-style"
                  }`}
                  value={userInfo.city}
                  onChange={handleChange}
                />
              </div>
              {verifiedClicked && !isValidCity(userInfo.city) && (
                <span className="err-validation">Required!</span>
              )}
            </Col>
            <Col
              xs={isMobile ? 12 : 4}
              className={`mb-4 ${!isMobile && "text-left"} pr-0`}
            >
              <div className="labeled-input-wrapper">
                <LabeledInput
                  size="sm"
                  id="postalCode"
                  name="postalCode"
                  placeholder={t("postalCode")}
                  className={`custom-postalCode-input inputText w-100 ${
                    verifiedClicked &&
                    !isValidPostalCode(userInfo.postalCode) &&
                    "empty-input-style"
                  }`}
                  value={userInfo.postalCode}
                  onChange={handleChange}
                />
              </div>
              {verifiedClicked && !isValidPostalCode(userInfo.postalCode) && (
                <span className="err-validation">Required!</span>
              )}
            </Col>
            {isChangedAddress && (
              <span className="warning-payable-title d-block mt-0 mb-2 w-100 pl-3 pb-4">
                {t("reVerifyAddress")}
              </span>
            )}
          </Row>
          <div className={`map-wrapper ${isTablet && "w-700"}`}>
            <GoogleMapAPI
              positionLat={parseFloat(userData.positionLat)}
              positionLong={parseFloat(userData.positionLong)}
              setLat={setLat}
              setLng={setLng}
            />
          </div>
          {/* <div className="change-password-profile-wrapper mt-4">
            <Row className="accordion-header" onClick={toggleAccordion}>
              <Col xs={8}>
                <span className="profile-accordion-title">
                  {t("changePassword")}
                </span>
              </Col>
              <Col xs={4} className="text-right">
                <FontAwesomeIcon
                  className="collapse-chevron text-right"
                  icon={isOpen ? faChevronDown : faChevronRight}
                />
              </Col>
            </Row>
            {isOpen && (
              <Row className="drawer mt-3">
                <Col xs={12} sm={12} md={6} lg={6} className="mb-4">
                  <div className="mb-2 text-align-last-left ">
                    {isShowPassword ? (
                      <a
                        className="hide-show-pass-wrapper"
                        onClick={handleShowPassword}
                      >
                        <FontAwesomeIcon icon={faEye} /> Hide
                      </a>
                    ) : (
                      <a
                        className="hide-show-pass-wrapper"
                        onClick={handleShowPassword}
                      >
                        <FontAwesomeIcon icon={faEyeSlash} /> Show
                      </a>
                    )}
                  </div>
                  <div className="labeled-input-wrapper">
                    <LabeledInput
                      size="sm"
                      id="password-input"
                      placeholder="**********"
                      className={`half-custom-input-register inputText text-left pr-0 pl-2 ${
                        editClicked &&
                        !isPatternPasswordValid(userInfo.password) &&
                        "empty-input-style"
                      }`}
                      name="password"
                      type={isShowPassword ? "text" : "password"}
                      value={userInfo.password}
                      onChange={handleChange}
                    />
                  </div>
                  {editClicked &&
                    !isPatternPasswordValid(userInfo.password) && (
                      <span className="err-validation">
                        "Password pattern is not correct"
                      </span>
                    )}
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  className="mb-4 text-left pl-0"
                >
                  <div className="mb-2 ml-3 text-align-last-left ">
                    {isShowRePassword ? (
                      <a
                        className="hide-show-pass-wrapper"
                        onClick={handleShowRePassword}
                      >
                        <FontAwesomeIcon icon={faEye} /> Hide
                      </a>
                    ) : (
                      <a
                        className="hide-show-pass-wrapper"
                        onClick={handleShowRePassword}
                      >
                        <FontAwesomeIcon icon={faEyeSlash} /> Show
                      </a>
                    )}
                  </div>
                  <div className="labeled-input-wrapper">
                    <LabeledInput
                      size="sm"
                      id="rePassword-input"
                      placeholder="**********"
                      name="rePassword"
                      type={isShowRePassword ? "text" : "password"}
                      className={`half-custom-input-register ml-3 inputText text-left pr-0 pl-2 ${
                        editClicked &&
                        !isMatchPasswords(
                          userInfo.rePassword,
                          userInfo.password
                        ) &&
                        "empty-input-style"
                      }`}
                      value={userInfo.rePassword}
                      onChange={handleChange}
                    />
                  </div>
                  {editClicked &&
                    !isMatchPasswords(
                      userInfo.rePassword,
                      userInfo.password
                    ) && (
                      <span className="err-validation">
                        The passwords don't match!
                      </span>
                    )}
                </Col>
                <Col
                  xs={12}
                  className={`mb-4 ${isPersian ? "dir-rtl" : "dir-ltr"}`}
                >
                  <span className="password-condition-title mb-2">
                    {t("createPassContains")}:
                  </span>
                  <span
                    className={`${
                      hasEightChars
                        ? "password-condition-correct"
                        : "password-condition-wrong"
                    }`}
                  >
                    {hasEightChars && <FontAwesomeIcon icon={faCheck} />}
                    {t("eightChars")}
                  </span>
                  <span
                    className={`${
                      hasOneDigitChars
                        ? "password-condition-correct"
                        : "password-condition-wrong"
                    }`}
                  >
                    {hasOneDigitChars && <FontAwesomeIcon icon={faCheck} />}
                    {t("oneNumber")}
                  </span>
                  <span
                    className={`${
                      hasUppercaseChar
                        ? "password-condition-correct"
                        : "password-condition-wrong"
                    }`}
                  >
                    {hasUppercaseChar && <FontAwesomeIcon icon={faCheck} />}
                    {t("uppercaseLowercase")}
                  </span>
                  <span
                    className={`${
                      hasSpecialChar
                        ? "password-condition-correct"
                        : "password-condition-wrong"
                    }`}
                  >
                    {hasSpecialChar && <FontAwesomeIcon icon={faCheck} />}1
                    {t("specialChar")}{" "}
                    <span
                      className={`${
                        hasSpecialChar
                          ? "password-special-char-example-correct"
                          : "password-special-char-example"
                      }  `}
                    >
                      $, !, @, %, &
                    </span>
                  </span>
                </Col>
              </Row>
            )}
          </div> */}
          <Row
            className={`update-info-btn-wrapper mt-4 ${isTablet && "w-700"} ${
              isMobile && "ml-3"
            }`}
          >
            <Button
              variant="primary"
              className="update-info-btn"
              onClick={editProfile}
            >
              {t("updateInformation")}
            </Button>
            {isOpenModal && (
              <Confirmation
                title={t("updateInformation")}
                description={t("sureEditProfile")}
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                setIsConfirmed={setIsConfirmed}
                type="updateProfile"
              />
            )}
          </Row>
          <Row
            className={`mt-5 ${!isMobile && !isTablet && "pl-5 pr-3"} ${
              isPersian ? "text-right" : "text-left"
            } ${isMobile && "mx-3"} ${isTablet && "w-700"}`}
          >
            {isPersian ? (
              <span className="close-account-texts">
                ما قدردان حضور شما هستیم ، اما اگر نیاز به بستن حساب خود دارید،
                می‌توانید این کار را از{" "}
                <a style={{ color: "#60a0c5" }} href="/close-accounts">
                  اینجا{" "}
                </a>
                انجام دهید.
              </span>
            ) : (
              <span>
                Your presence has been appreciated, but if you need to close
                your account, you can do it{" "}
                <a style={{ color: "#60a0c5" }} href="/close-accounts">
                  here
                </a>
                .
              </span>
            )}

            {/* <Button
              variant="white"
              className="close-account-link mb-4 mt-3"
              
            >
              Close Account{" "}
              <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
            </Button> */}
          </Row>
        </>
      ) : (
        <SkeletonForm />
      )}
      {isOpenOtpCode && (
        <Modal
          show={isOpenOtpCode}
          className="otp-modal-wrapper"
          backdrop="static"
          onHide={() => setIsOpenOtpCode(false)}
        >
          <Modal.Body>
            <div className="close-modal-btn-wrapper"></div>
            <div className="content-otp">
              <span className="enter-verify-information mb-3">
                {t("verificationCodeSent")}
              </span>
              <OtpInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
                containerStyle={{ display: "block", margin: "20px 0" }}
                inputStyle={{
                  width: isMobile ? "2rem" : "3rem",
                  height: isMobile ? "2rem" : "3rem",
                  margin: "10px",
                  border: "1px solid #c5c5c5",
                  borderRadius: "4px",
                  fontSize: "28px",
                  color: "#00043d",
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsOpenOtpCode(false)}>
              {t("close")}
            </Button>
            <Button
              variant="primary"
              disabled={otp?.length !== 6}
              onClick={verifyNumber}
            >
              {t("verify")}{" "}
              {isLoadingCode && (
                <Oval
                  width="20"
                  height="20"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {isOpenPersonaModal && (
        <UploadIdentity
          isOpen={isOpenPersonaModal}
          setIsOpen={setIsOpenPersonaModal}
          type="address"
        />
      )}
    </div>
  );
};
