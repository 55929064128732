import React, { useState, useEffect } from "react";
import { Row, Col, Card, Dropdown } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { PersonalInfo, VerificationInfo, FinancialInfo } from "components";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getUserInfo } from "redux/actions/Authorization";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { ToastContainer } from "react-toastify";
import { getAllCurrencies } from "redux/actions/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCertificate,
  faCheck,
  faClose,
  faInbox,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProfileTabs } from "components/ProfileTabs";
import { SettingItems } from "components/SettingItems";

export const Settings: React.FC = () => {
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const userData = useAppSelector(
    (state) => state?.userInfo?.data && state?.userInfo?.data[0]?.data
  );
  const token = window.localStorage.getItem("token");
  const location = useLocation();

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getAllCurrencies());
  }, [token]);

  window.addEventListener("storage", () => {
    window.localStorage.setItem("token", window.localStorage.getItem("token"));
  });

  const handleSelectedTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    setActiveTab(t(location.state));
  }, [location.state]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />

      <div className="title-dashboard d-flex">
        <h2>{t("myAccount")}</h2>
        <a href="/accountType">
          <div
            className="user-account-type-box d-flex shiny-box"
            style={{ backgroundColor: userData?.userLevelHexColor }}
          >
            <div className="userlevel-icons-wrapper">
              <FontAwesomeIcon
                className="icon-validation-user userlevel-certificate-icon"
                icon={faCertificate}
                // color={userData?.userLevelHexColor}
                color={"#fff"}
              ></FontAwesomeIcon>
              <FontAwesomeIcon
                className="icon-validation-user userlevel-check-icon"
                icon={faCheck}
                color={userData?.userLevelHexColor}
              />
            </div>
            <span style={{ color: "#fff" }}>{userData?.userLevel}</span>
          </div>
        </a>
      </div>
      <SettingItems selectedTab={activeTab} onSelectedTab={handleSelectedTab} />
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
