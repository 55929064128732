import { createSlice } from "@reduxjs/toolkit";

export const verifyStripeSlice = createSlice({
  name: "verifyStripe",
  initialState: [],
  reducers: {
    verifyStripe: (state, action) => {
      return (state = action.payload);
    },
    verifyStripeFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { verifyStripe, verifyStripeFailure } = verifyStripeSlice.actions;
export default verifyStripeSlice.reducer;
