import React, { useState, useEffect } from "react";
import { Row, Col, Card, OverlayTrigger } from "react-bootstrap";
import { Button } from "layers";
import PlaneIcon from "../../assets/images/plane.png";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IMyTraveler } from "models/interfaces";
import { RightSidebar } from "layers";
import { getTravel, deleteUserTravel } from "redux/actions/dashboard";
import { useAppDispatch } from "redux/store";
import { Confirmation } from "components/modals/Confirmation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";

interface IProps {
  data: IMyTraveler;
  setTravelId: Function;
}

export const Cards: React.FC<IProps> = ({ data, setTravelId }) => {
  const windowSize = UseWindowSize();
  const { t } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [trvId, setTrvId] = useState("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const openEditTravel = (id) => {
    setShowSidebar(!showSidebar);
    dispatch(getTravel(id));
    setTrvId(id);
    navigate("/dashboard/editTravel", { state: t("myTravels") });
  };

  const removeTravelOpenModal = (id) => {
    setIsOpenModal(true);
    setTrvId(id);
  };

  useEffect(() => {
    if (isConfirmed) dispatch(deleteUserTravel(trvId));
  }, [isConfirmed]);

  const viewRequests = () => {
    if (data.requests > 0) {
      setTravelId(data.trvId);
      navigate("/dashboard/latestSentRequest", { state: t("latestSentRequest") });
      // const element = document.getElementById("receive-offer-wrapper");
      // if (element) {
      //   element.scrollIntoView({ behavior: "smooth" });
      // }
    }
  };

  const fromTooltip = (
    <Tooltip id="fromCity">
      <span>{t("from")}: {data?.fromCountryCity}</span>
    </Tooltip>
  );
  const toTooltip = (
    <Tooltip id="toCity">
      <span>{t("to")}: {data?.toCountryCity}</span>
    </Tooltip>
  );

  return (
    <Col
      key={data?.owner}
      lg={3}
      md={6}
      sm={12}
      className={`${windowSize.width < 768 ? "p-0 mb-1" : "mb-5"} `}
      style={
        windowSize.width < 768 ? { width: "100%", marginRight: "13px" } : null
      }
    >
      <Card
        className={`my-travel-package-card-wrapper ${
          !data.isAvailable && "half-opacity"
        }`}
      >
        <Card.Header className="card-request-header">
          <Row>
            <Col xs={5} className="text-left header-card-titles pr-0">
              <OverlayTrigger placement="top" overlay={fromTooltip}>
                <div>
                  <span className="text-left cursor-pointer">
                    {data?.fromCity}
                  </span>
                </div>
              </OverlayTrigger>
              <div>
                <span className="text-left mt-1">{data?.fromDate1}</span>
              </div>
            </Col>
            <Col xs={2} className="text-center header-card-plane p-0">
              <div>
                <span>{data?.flightNumber ? data.flightNumber : "---"}</span>
              </div>
            </Col>
            <Col xs={5} className="header-card-titles pl-0">
              <OverlayTrigger placement="top" overlay={toTooltip}>
                <div className="text-right cursor-pointer">
                  <span>{data?.toCity}</span>
                </div>
              </OverlayTrigger>
              <div className="text-right mt-1">
                <span>{data?.fromDate2}</span>
              </div>
            </Col>
            <Col xs={12} className="text-center header-card-plane p-0">
              <div>
                <span className="mb-0">
                  <img
                    src={PlaneIcon}
                    className="card-request-icon"
                    alt="location-img"
                  />{" "}
                  <span className="airline-box-title">{data?.airlineName}</span>
                </span>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="card-request-body">
          <Row>
            <Col xs={7} className="request-body-info text-left">
              <div>
                <span className="card-text">
                  {t("size")}: {data?.size}
                </span>
              </div>
              <div>
                <span className="card-text">
                  {t("weight")}: {data?.weight} {data?.weightUnit}
                </span>
              </div>
              {/* <div>
                <span className="card-text">
                  {t("itemValue")}: {data?.itemValue} {data?.currencyType}
                </span>
              </div> */}
            </Col>
            <Col xs={5} className="request-body-package pl-0">
              <div>
                <span>{t("travelID")}</span>
              </div>
              <div>
                <span>{data?.trvId}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={7} className="request-body-date text-left">
              <div>
                <span className="card-text">{t("travelDate")}:</span>
              </div>
              <div>
                <span className="card-text">{data?.fromDate1}</span>
              </div>
            </Col>
            <Col xs={5} style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                data-test="docs-btn-anchor"
                className="offer-btn cursor-pointer"
                onClick={viewRequests}
              >
                {t("requests")}{" "}
                <span className="d-block">{data?.requests}</span>
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="card-request-footer">
          {data.isAvailable && data?.requests < 1 && (
            <Button
              variant="gray7"
              onClick={() => removeTravelOpenModal(data.trvId)}
              className="remove-travel-btn"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}

          {data.isAvailable && data?.requests < 1 && (
            <Button
              variant="gray7"
              onClick={() => openEditTravel(data.trvId)}
              className="edit-travel-btn"
            >
              {t("edit")}
            </Button>
          )}
          <Button
            variant="warning"
            data-test="docs-btn-anchor"
            className="view-request-btn"
            onClick={viewRequests}
          >
            {t("viewRequests")}
          </Button>
        </Card.Footer>
        {showSidebar && (
          <div className="offer-sidebar">
            <RightSidebar
              isOpen={showSidebar}
              setIsOpen={setShowSidebar}
              sidebarType="dashboardTravel"
              mode="edit"
              trvId={trvId}
            />
          </div>
        )}
        {isOpenModal && (
          <Confirmation
            trvId={trvId}
            title={t("removeMyTravel")}
            description={t("confirmRemoveCard")}
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
            setIsConfirmed={setIsConfirmed}
            type="travel"
          />
        )}
      </Card>
    </Col>
  );
};
