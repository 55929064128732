import { createSlice } from "@reduxjs/toolkit";

export const chatByTypeIdSlice = createSlice({
  name: "chatByTypeId",
  initialState: [],
  reducers: {
    chatByTypeId: (state, action) => {
      return (state = action.payload);
    },
    chatByTypeIdFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { chatByTypeId, chatByTypeIdFailure } = chatByTypeIdSlice.actions;
export default chatByTypeIdSlice.reducer;
