import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { getAllCurrencies } from "redux/actions/types";
import { useAppDispatch } from "redux/store";

export const TermsCondition: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);
  return (
    <div className="d-flex flex-column min-vh-100 ltr-dir">
      <Header />
      <div className="title-dashboard">
        <h2>Terms & Condition</h2>
      </div>
      <div className="flex-grow-1 rules-pages-wrapper my-5">
        <h1 className="mb-4">
          Terms and agreements concerning the services provided by Koolbaar
        </h1>
        <h3>Article 1: Object of the agreement</h3>
        <p>
          The Koolbaar platform's purpose is to bring together and connect
          people who need to transport packages (the Senders) and people who can
          carry those objects (the Travelers). Those Travelers will perceive an
          allowance, based on which Koolbaar will deduct a commission.
        </p>
        <p>Under no circumstances, Koolbaar shall be considered as :</p>
        <ul>
          <li>- A transporter of goods or persons</li>
          <li>- producer, an importer, or an advertiser for those goods</li>
          <li>- A delivery company</li>
          <li>- An internet adviser, a journalist, or a reviewer</li>
        </ul>
        <h3 className="mt-5">Article 2: Koolbaar’s Purpose</h3>
        <p>
          The sole purpose of Koolbaar is to create a network for its members or
          clients and to endorse the transportation of goods by private
          persons. 
        </p>
        <p>
          Koolbaar is the owner of a platform and a network of people. Those
          people have no link of subordination with Koolbaar, nor are the
          employee, shareholders, or owners.
        </p>
        <h3 className="mt-5">Article 3: The carrying agreement</h3>
        <p>
          The carrying agreement is directly concluded between the Traveler and
          the Sender, without any intervention from Koolbaar.
        </p>
        <p>
          Koolbaar will not interfere in the conclusion or the execution of the
          agreement. Therefore, Koolbaar will not bear any liability in case of
          fraud, misunderstanding, or lack of execution of the agreement.
        </p>
        <p>
          The intervention of Koolbaar regarding the payment of the carrying
          mandate will be limited, as explained in Article 10
        </p>
        <h3 className="mt-5">
          Article 4: recommendations and limitations concerning the carried item
        </h3>
        <p>
          It is the Sender’s and the Traveler’s duty to ensure, before
          completion of the agreement, that the carried item can be legally
          transported, owned, and possessed in the country of destination, as
          well as the country of departure and any country that could be crossed
          during the travel.
        </p>
        <p>
          Both parties will also ensure that no item that may be transported by
          or for any user contains any item that may be prohibited or restricted
          in the destination country. These include (non-exhaustive list)
        </p>
        <ul>
          <li>- Alcoholic beverages;</li>
          <li>- Weapons and materials that can be used to produce them;</li>
          <li>
            - Specific medication or herbal supplements and narcotic drugs;
          </li>
          <li>
            - Items that are of a sexual/adult nature (e.g., pornography);
          </li>
          <li>
            - Items that are of an offensive and violent nature, including items
            associated with hate organizations (e.g., torture devices, snuff
            films);
          </li>
          <li>- Live animals;</li>
          <li>
            - Foodstuffs and other processed and unprocessed animal and
            vegetable products;
          </li>
          <li>- Currency and the equipment used in the production thereof;</li>
          <li>- Tobacco and tobacco-related items;</li>
          <li>- Embargoed-goods;</li>
          <li>
            - Items that violate copyright or trademark laws, Beta software,
            bootleg recordings, things that would enable duplication of
            copyrighted material, counterfeit items;
          </li>
          <li>- Stolen property;</li>
          <li>- Testers or replica perfumes or cosmetics.</li>
        </ul>
        <h3 className="mt-5">
          Article 5: General requirements to subscribe to a carrying contract
          with a member of Koolbaar’s network 
        </h3>
        <p>
          Each user must be of 18 years of age and capable of forming legal
          contracts.
        </p>
        <p>
          Each user will not be violating any laws, third-party rights, or the
          site’s policies.
        </p>
        <p>
          Each user will comply with all applicable domestic and international
          laws. It is prohibited for any user’s posting to use any other
          person’s identification documents or sensitive financial information
          on the site.
        </p>
        <p>
          No affiliation, association, or connection exists between Koolbaar and
          its users.  User posting on the website constitutes a valid electronic
          agreement and is aware of the terms and conditions before posting.
        </p>
        <h3 className="mt-5">
          Article 6: specific guarantees and requirements for the Travelers
        </h3>
        <p>
          Travelers must be at the age to contract and accomplish legal
          obligations to execute the carrying contract.
        </p>
        <p>
          The persons who have been dismissed from their legal or civic rights
          cannot subscribe as Travelers.
        </p>
        <p>
          Those with prior criminal condemnations for robbery, theft, or fraud
          cannot be Travelers. The Travelers aren’t employees or attendants of
          Koolbaar.
        </p>
        <p>
          There is no subordination link between Koolbaar and them. Koolbaar is
          not entitled to exercise supervisory power on the Traveler.
        </p>
        <p>The Traveler has to be a private person. </p>
        <p>
          Professional transporters cannot carry items during their journeys.
        </p>
        <p>
          The travel expenses and costs are solely covered and paid by the
          Traveler, who will receive no other benefit than the “Pay-out.”
        </p>
        <h3 className="mt-5">
          Article 7: specific guarantees and requirements for the Sender of the
          item.
        </h3>
        <p>
          The Sender has to be at the age to contract and accomplish a legal
          agreement.
        </p>
        <p>
          The Sender must package the item before transportation to protect the
          thing to avoid any reasonable deterioration.
        </p>
        <p>
          The Sender has to inform themselves and the Traveler concerning the
          taxes and fees that the Traveler could expose.  Those taxes and fees
          will be pre-paid to Koolbaar before the travel. Suppose some
          unavoidable expenses have to be revealed during the trip, and the
          Sender has not foreseen them. In that case, they will be automatically
          charged to him without possibly waiving those costs. 
        </p>
        <p>
          The Sender must guarantee that the item’s addressee will be present at
          the agreed time and place of delivery.
        </p>
        <h3 className="mt-5">Article 8: Precaution Before carrying</h3>
        <p>
          Any breakable or fragile object shall be packaged in the Sender and
          the Traveler's presence to witness the item's good state before
          carrying it. It is recommended to test the electronic device to ensure
          their good working before travel.
        </p>
        <p>
          It is also recommended to take pictures of the carried item and the
          packaging before the travel to provide proof in case of a dispute.
        </p>
        <p>
          The Traveler must scrutinize the item and the packaging, ensuring it
          does not hide or contain illegal or prohibited material.
        </p>
        <p>
          If the Traveler has any suspicion that the carried item can contain
          illegal or prohibited material, the Traveler must refuse to take it.
        </p>

        <h3 className="mt-5">Article 9: Transport and risks</h3>
        <p>
          The traveler must ensure that the transportation is made cautiously
          and without risk of breaking or causing damage to the carried item.
        </p>
        <p>
          The Traveler will also ensure that transportation will be made
          according to the sanitary and legal recommendations. 
        </p>
        <p>
          Koolbaar shall not be held responsible for the loss, deterioration, or
          theft of the carried item during the traveling time.
        </p>
        <p>
          The traveling time starts when the Travelers pick up the item and ends
          when it is delivered to the person who signs the proof of delivery.
        </p>
        <p>
          As the demander of the travel, the Sender will be in charge of any
          customs-related or regulatory-related claim or dispute resulting from
          the carrying. 
        </p>
        <p>
          The Traveler shall be considered the guardian of the object. He is the
          depositor of the precarious possession of the carried item. Therefore,
          he will be held liable for any loss or damage caused by the entity or
          its behavior during transportation.
        </p>
        <p>
          Under certain circumstances, Koolbaar offers insurance to cover
          certain losses or damages. The beneficiary of the insurance will
          expose any franchise.
        </p>
        <p>Except for those cases, Koolbaar will decline any responsibility.</p>
        <h3 className="mt-5">Article 10: Payment and Payout</h3>
        <p>
          The Travelers shall perceive an all-inclusive payout to cover their
          expenses and efforts during the travel.
        </p>
        <p>
          This payout is freely fixed between the parties at the moment of the
          conclusion of the agreement. Koolbaar uses its experience to propose
          informative, usually applied rates.{" "}
        </p>
        <p>
          If the item has to be purchased or some payments must be made to take
          possession of the article, the Travelers will advance those amounts
          first.
        </p>
        <p>
          The payment of the item’s price (in case of a new thing that has to be
          purchased), the potential costs and fees, and the “all-inclusive
          allowance” are made directly to Koolbaar before the travel.
        </p>
        <p>
          Under no circumstances can the travel be executed before reception and
          notification of the reception by Koolbaar.
        </p>
        <p>
          Suppose the parties execute the carrying without previous payment. In
          that case, Koolbaar shall offer no guarantee concerning the cost of
          the allowance or any other amount arising from the agreement or its
          execution.
        </p>
        <p>
          Upon the Traveler's claim of delivering the parcel to the final
          destination, the addressee has a window of 24 hours to either confirm
          or deny the delivery. In the event of no action within this 24-hour
          period, Koolbaar will deem the parcel as successfully delivered and
          initiate the payout process for the Traveler. Both payout and refund
          processes will be completed within a span of seven days.
        </p>
        <p>
          If the addressee denies the delivery, Koolbaar will take charge of the
          refund process and manage it accordingly. This ensures that any
          disputes or issues regarding delivery are promptly addressed and
          resolved by Koolbaar's dedicated processes.
        </p>
        <p>
          Payment is made directly on the site using the “stripe.com” payment
          system. The price must be determined according to the Bank’s or
          Payment organization’s disposals.
        </p>
        <p>
          Koolbaar shall bear no responsibility in case of money lost due to the
          failure of the Bank’s or P.O.’s system or improper use of the system.
        </p>
        <h3 className="mt-5">
          Article 11: Koolbaar's Commission, Fees, and Operations Cost
        </h3>
        <p>
          Koolbaar shall receive a commission and fees equivalent to 18% of the
          payout amount. This structure comprises a 9.5% service commission and an
          8.5% operations cost. The service commission is allocated to cover
          Koolbaar's platform facilitation and support, while the cost of the
          operations encompasses various expenses, including Stripe transaction
          fees, email and SMS notifications, platform maintenance, and services
          related to user identity verification.
        </p>
        <p>
          Both parties are legally bound to maintain full transparency regarding
          the agreed payout, commission, fees, and operations cost. Any endeavor
          to manipulate or misrepresent the payout is strictly prohibited and
          shall be considered fraudulent activity.
        </p>
        <h3 className="mt-5">
          Article 12: Verification of Successful Delivery
        </h3>
        <p>
          For clarity and transparency, we strongly recommend that during the
          delivery process, the addressee meticulously inspects the parcel. If
          no issues are identified, the addressee is advised to sign the
          agreement letter.
        </p>
        <p>
          In addition, it is mandatory for the Traveler to capture a photograph
          when handing over the parcel to the addressee. Furthermore, to ensure
          proper documentation, Travelers must upload scans of the signed
          agreement, photographs, or any other valid proof of delivery onto the
          Koolbaar platform. This should be completed when changing the status
          of the parcel to "Delivered/Posted."
        </p>
        <p>
          This practice aims to establish a comprehensive record of successful
          deliveries and contributes to the overall transparency and
          accountability of the transaction process.
        </p>
        <h3 className="mt-5">Article 13: Handling of Denied Deliveries</h3>
        <p>
          In accordance with the terms of this agreement, a denied delivery
          occurs under the following circumstances:
        </p>
        <ol>
          <li>
            <span className="bullet-icon" />
            An item is either partially or entirely undelivered.
          </li>
          <li>
            <span className="bullet-icon" />
            An item is delivered but has sustained damage.
          </li>
          <li>
            <span className="bullet-icon" />
            An item is delivered but does not match the description provided by
            the buyer.
          </li>
        </ol>
        <p>
          Koolbaar hereby disclaims any liability for non-delivery or defective
          delivery of items. In the event that a denied delivery is reported to
          Koolbaar, the platform will pause the payout process, subject to a
          thorough assessment that considers evidence such as proof of
          deliveries, timeline, agreements, and other relevant details.
        </p>
        <p>
          If the evidence supports the sender's claim of a denied delivery,
          Koolbaar will initiate a refund process, deducting the applicable
          commission and operations costs. This ensures that rightful
          resolutions are achieved while upholding fairness and transparency in
          the transaction process.
        </p>
        <p>
          This approach aims to provide a comprehensive review of denied
          delivery situations, protect the interests of both parties and foster
          a reliable and trustworthy environment for all users of the Koolbaar
          platform.
        </p>
        <h3 className="mt-5">
          Article 14: Traveler's Entitlements and Sender Responsibilities
        </h3>
        <p>
          This article outlines the rights and entitlements of Travelers in
          specific scenarios, as well as the responsibilities of Senders within
          the Koolbaar platform.
        </p>
        <p style={{ textDecoration: "underline" }}>Non-Compliance by Sender:</p>
        <p>
          If a Sender fails to present the parcel for delivery to the Traveler
          or neglects to provide the necessary product specifications within the
          stipulated timeframe, Koolbaar will uphold the Traveler's rights. In
          such cases, Koolbaar will proceed to payout the Traveler with the
          agreed-upon amount. It is important to note that in instances of
          non-compliance by the Sender, no refund shall be issued to the Sender.
        </p>
        <a href="/privacyPolicy">Cancellation Policy Disregard: </a>
        <p>
          When a Sender cancels the agreement without adhering to the
          established Cancellation Policy defined by the Traveler, Koolbaar will
          enforce the Traveler's rights. Consequently, no refund will be granted
          to the Sender, and the Traveler will receive the agreed-upon payout
          amount.
        </p>
        <p>
          This approach aims to safeguard the rights of Travelers and promote
          accountability within the Koolbaar platform. By setting clear
          expectations and consequences for non-compliance, we strive to foster
          a reliable and balanced environment for Travelers and Senders.
        </p>
        <h3 className="mt-5">
          Article 15: Violation of Provisions and Blacklisting
        </h3>
        <p>
          Any breach or infringement of the present terms will result in the
          immediate annulment of the carrying agreement.{" "}
        </p>
        <p>
          The individual responsible for the breach or infringement will be held
          accountable for any associated costs and losses from the contract's
          termination.
        </p>
        <p>
          In the event of a breach or infringement, Koolbaar retains the right
          to impose a penalty of 1,000.00 USD for each reported violation and
          seek compensation for any ensuing financial or branding losses.
        </p>
        <p>
          Moreover, any user found to be in violation of these terms will be
          placed on a blacklist. Subsequently, such users will be permanently
          barred from receiving verification from Koolbaar and banned entirely
          from the platform. That blacklisted users will be permanently banned
          from using the Koolbaar platform and will not be eligible for
          verification.
        </p>
        <p>
          Koolbaar retains the right to terminate the agreement with users found
          violating the terms.
        </p>
        <p>
          These measures have been implemented to ensure the agreement's
          robustness and encourage all participants to observe the terms and
          conditions diligently. By adhering to these protocols, we aim to
          uphold a secure, reliable, and responsible environment within the
          Koolbaar community.
        </p>
        <h3 className="mt-5">
          Article 16: validity and interpretation of the terms. 
        </h3>
        <p>
          The present terms are exhaustive and self-sufficient.  Koolbaar
          undertakes no other obligations than the expressively explained here
          above. The present terms can be translated into other languages for a
          better understanding by Koolbaar’s customers.
        </p>
        <p>
          In case of divergence between the different translations, the original
          version – which is the English version- shall be preferred. The
          present terms and agreements are submitted to Canadian Law. The
          annulment of a clause in the current terms shall not lead to the
          dissolution of the entire agreement.
        </p>
        <p>
          In case of annulment of a clause, the Parties will replace the
          annulled clause with a clause with the same financial and legal effect
          in good faith negotiations.
        </p>
        <h3 className="mt-5">Article 17: dispute resolution</h3>
        <p>
          Any disputes arising from the contraction, execution, or
          interpretation of the carrying agreement or Koolbaar’s terms shall
          preferably be resolved in a consensual and negotiated manner.
        </p>
        <p>
          If such a dispute resolution is not possible or if one Party did not
          react to an invitation for a consensual dispute resolution, the
          Canadian Jurisdictions should investigate the dispute. Canadian law
          shall be applied to resolve the conflicts.
        </p>
      </div>
      <Footer />
    </div>
  );
};
