import { createSlice } from "@reduxjs/toolkit";

export const senderReasonCancellationSlice = createSlice({
  name: "senderReasonCancellation",
  initialState: [],
  reducers: {
    senderReasonCancellation: (state, action) => {
      return (state = action.payload);
    },
    senderReasonCancellationFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { senderReasonCancellation, senderReasonCancellationFailure } =
senderReasonCancellationSlice.actions;
export default senderReasonCancellationSlice.reducer;
