import { createSlice } from "@reduxjs/toolkit";

export const serviceSlice = createSlice({
  name: "service",
  initialState: [],
  reducers: {
    service: (state, action) => {
      return (state = action.payload.data);
    },
    serviceFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { service, serviceFailure } = serviceSlice.actions;
export default serviceSlice.reducer;
