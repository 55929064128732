import React, { FC, useState, useEffect } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Col, Row } from "react-bootstrap";
import { Input, Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IReview } from "models/interfaces";
import { Rating } from "react-simple-star-rating";
import {
  MonthNumber,
  getDefaultDate,
  getNumberOfMonth,
  getStringOfMonth,
} from "helpers/convertDate";
import { Oval } from "react-loader-spinner";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getAllDashboardData, sendUserReview } from "redux/actions/dashboard";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProp {
  setIsOpen: Function;
  isOpen: boolean;
  id: string;
  owner: string;
  reviewFor: string;
  titleTab: string;
  data: any;
  setCloseConfirm: Function;
}

export const WriteReview: React.FC<IProp> = ({
  isOpen,
  setIsOpen,
  id,
  owner,
  reviewFor,
  data,
  setCloseConfirm,
  titleTab,
}) => {
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const isMobile = windowSize.width < 768;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [rating, setRating] = useState(0);
  const [previewChecked, setPreviewChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const reviewSendData: any = useAppSelector((state) => state.sendReview);
  const [reviewData, setReviewData] = useState<IReview>({
    response: "",
    description: "",
  });
  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    setCloseConfirm(true);
    document.documentElement.style.overflow = "auto";
    navigate("/dashboard", { state: t(`${titleTab}`) });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setReviewData({ ...reviewData, [e.target.name]: e.target.value });
  };

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const handlePreviewCheckedChange = () => {
    setPreviewChecked(!previewChecked);
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const sendReview = () => {
    setIsLoading(true);
    const data = {
      id: id,
      title: reviewData.response,
      description: reviewData.description,
      rate: rating,
    };
    dispatch(sendUserReview(data));
  };

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          navigate("/dashboard", { state: t(`${titleTab}`) });
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    if (!reviewSendData?.isSuccess) {
      setIsLoading(false);
    } else if (reviewSendData?.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      setCloseConfirm(true);
      navigate("/dashboard", { state: t(`${titleTab}`) });
      window.location.reload();
    }
  }, [reviewSendData]);

  const convertHumanDateToUnix = (date) => {
    const separatedDate = date?.split("-");
    const month = getStringOfMonth(separatedDate[1]);
    const year = parseInt(separatedDate[0]);
    const day = parseInt(separatedDate[2]);
    const data = { year, month, day };
    return `${data.day} ${data.month} ${data.year}`;
  };

  return (
    <div className="review-slider-container">
      <Row className="sidebar-content-wrapper">
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className="payment-form">
          <h1>
            {t("writeReview")} {reviewFor}
          </h1>
        </Col>
        <Col xs={12} sm={12} md={9} lg={9} className="payment-form text-left">
          <Input
            id="response"
            name="response"
            placeholder="Title (For example: He is so responsive and caring)"
            className="response-input-review"
            value={reviewData.response}
            onChange={handleChange}
          />
        </Col>
        <Col
          xs={12}
          sm={12}
          md={3}
          lg={3}
          className="payment-form review-rating-wrapper text-left"
        >
          <Rating
            // fillColor="#989898"
            // emptyColor="#fff"
            // fillIcon=
            // emptyIcon=
            size={25}
            onClick={handleRating}
            /* Available Props */
          />
        </Col>
        <Col xs={12} className="payment-form text-left">
          <Input
            id="description"
            placeholder="Description"
            className="custom-textarea-review"
            type="text"
            name="description"
            value={reviewData.description}
            onChange={handleChange}
            textArea={true}
            rows={3}
          />
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className="request-form">
          <div className="my-2">
            <label className="checkbox-container">
              {t("previewOn")}
              <input
                type="checkbox"
                checked={previewChecked}
                onChange={handlePreviewCheckedChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className={`request-form ${isMobile ? "text-left" : "text-right"}`}
        >
          <div>
            <Button
              variant="primary"
              onClick={sendReview}
              className="submit-review-btn"
            >
              {t("submitRequest")}
              {isLoading && (
                <Oval
                  width="20"
                  height="20"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                />
              )}
            </Button>
          </div>
        </Col>
        {previewChecked && (
          <Col xs={12} className="mt-4">
            <div className="review-box-wrapper">
              <h3 className="review-title">{reviewData.response}</h3>
              <h3 className="review-name">{reviewFor}</h3>
              <p className="review-text">{reviewData.description}</p>
              <div className="text-left">
                <span className="review-details">
                  {convertHumanDateToUnix(data.date)} | {data.fromTo}
                  {/* | Carry{" "} */}
                  {/* {data.packages} */}
                </span>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};
