export const isValidPackageType = (type) =>
  type !== null &&
  type !== undefined &&
  (Array.isArray(type) ? type?.length !== 0 : true);

export const isValidSize = (size) =>
  size !== null &&
  size !== undefined &&
  (Array.isArray(size) ? size?.length !== 0 : true);

// export const isValidService = (service) =>
//   service !== null &&
//   service !== undefined &&
//   (Array.isArray(service) ? service?.length !== 0 : true);

export const isValidPayment = (payment) =>
  payment !== null &&
  payment !== undefined &&
  (Array.isArray(payment) ? payment?.length !== 0 : true);

export const isValidPolicy = (policy, isCancellation) => {
  return isCancellation
    ? policy !== null &&
        policy !== undefined &&
        Object.keys(policy).length !== 0
    : true;
};

export const isValidDefinedLengthSize = (length) => length !== "";

export const isValidDefinedWidthSize = (width) => width !== "";

export const isValidDefinedHeightSize = (height) => height !== "";

export const isValidFrom = (from) =>
  from !== undefined && from && Object.keys(from).length !== 0;

export const isValidTo = (to) =>
  to !== undefined && to && Object.keys(to).length !== 0;

export const isEndDateGreaterThanStartDate = (from2, to1) =>
  from2.getTime() < to1.getTime();

// export const isEndDateGreaterEqualThanStartDate = (from1, to1, from2, to2) =>
//   from1.getTime() <= from2.getTime() && to1.getTime() <= to2.getTime();

export const isEndDateGreaterEqualThanStartDate = (date1, date2) =>
  date1.getTime() <= date2.getTime();

export const isValid = (termsChecked, data, isCancellation) => {
  return (
    termsChecked &&
    isValidPackageType(data.type) &&
    isValidSize(data.size) &&
    // isValidService(data.service) &&
    isValidPayment(data.payment) &&
    isValidPolicy(data.policy, isCancellation) &&
    (Array.isArray(data.size)
      ? data?.size?.find((item) => {
          return item.label === "Custom"
            ? isValidDefinedLengthSize(data.length) &&
                isValidDefinedWidthSize(data.width) &&
                isValidDefinedHeightSize(data.height)
            : true;
        })
      : data.size.label === "Custom"
      ? isValidDefinedLengthSize(data.length) &&
        isValidDefinedWidthSize(data.width) &&
        isValidDefinedHeightSize(data.height)
      : true) &&
    isValidFrom(data.from) &&
    isValidTo(data.to) &&
    isEndDateGreaterEqualThanStartDate(
      data.arrivalBetweenDate,
      data.departureBetweenDate
    ) &&
    isEndDateGreaterEqualThanStartDate(
      data.departureBetweenDate,
      data.arrivalToDate
    ) &&
    isEndDateGreaterEqualThanStartDate(data.arrivalToDate, data.departureToDate)
  );
};
