import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { MultipleUploader } from "components";
import { Col, Row, Dropdown } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { InputOption } from "components";
import {
  searchAirportLocation,
  searchLocationApi,
} from "helpers/searchLocation";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { IPackage } from "models/interfaces";
import { useAppSelector, useAppDispatch } from "redux/store";
import { getDate, convertHumanDateToUnix } from "helpers/convertDate";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faInfo } from "@fortawesome/free-solid-svg-icons";
import { SizeInformation } from "components/modals/sizeInformation";
import { useTranslation } from "react-i18next";
import { searchAirline } from "helpers/searchAirline";
import {
  addNewPackage,
  editUserPackage,
  getAllDashboardData,
} from "redux/actions/dashboard";
import { AddInputForms } from "components/common/addInputForm";
import { Oval } from "react-loader-spinner";
import { addDays } from "date-fns";
import {
  getAllSizeRange,
  getAllDeliveryType,
  getAllPackagesType,
} from "redux/actions/types";
import { searchLocation } from "helpers/searchLocation";
import {
  isValidPackageType,
  isValidSize,
  isValidDefinedLengthSize,
  isValidDefinedWidthSize,
  isValidDefinedHeightSize,
  isValidOfferPrice,
  isValidFrom,
  isValidTo,
  isEndDateGreaterEqualStartDate,
  isEndDateGreaterStartDate,
  isValid,
} from "helpers/packageValidation";
import { useNavigate } from "react-router-dom";

interface IProp {
  setIsOpen: Function;
  isOpen: boolean;
  mode: string;
  pkgId: string;
}

const getOneAddDate = (date) => {
  const originalDate = new Date(date);
  const updatedDate = new Date(originalDate);
  var d = new Date(updatedDate.setDate(originalDate.getDate() + 1));
  return d;
};

export const AddEditPackage: React.FC<IProp> = ({
  setIsOpen,
  mode,
  pkgId,
  isOpen,
}) => {
  const windowSize = UseWindowSize();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [acceptPurchase, setAcceptPurchase] = useState(false);
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [requestData, setRequestData] = useState<IPackage>({
    packagetypeIds: [],
    packageType: "",
    sizerangeId: "",
    weight: "",
    weightUnit: "kg",
    value: "",
    sizeWidth: "",
    sizeHeight: "",
    sizeLength: "",
    lengthUnit: "cm",
    deliverytypeIds: [],
    fromCountry: "",
    fromCountryCity: "",
    toCountry: "",
    toCountryCity: "",
    fromDate1: "",
    fromDate2: "",
    offerPrice: "",
    currencyType: "CAD",
    message: "",
    images: [],
    Purchases: [],
    airlineName: "",
    flightNumber: "",
  });
  const [size, setSize] = useState(null);
  const [airline, setAirline] = useState<any>();
  const navigate = useNavigate();
  const [packagetypeIds, setPackagetypeIds] = useState<any>([]);
  const [deliverytypeIds, setDeliverytypeIds] = useState<any>([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [sizeOptions, setSizeOptions] = useState([]);
  const userPackage: any = useAppSelector((state) => state?.userPackage);
  const sizeRanges = useAppSelector((state) => state.sizeRange);
  const [termsChecked, setTermsChecked] = useState(false);
  const [images, setImages] = useState<any>([]);
  const [governmentChecked, setGovernmentChecked] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isCompanion, setIsCompanion] = useState(false);
  const [typeOptions, setTypeOptions] = useState([]);
  const [type, setType] = useState(null);
  const { t } = useTranslation();
  const packagesType = useAppSelector((state) => state.packageTypes);
  const [weightUnit, setWeightUnit] = useState({ value: "kg", label: "KG" });
  const [lengthUnit, setLengthUnit] = useState({ value: "cm", label: "CM" });
  const [service, setService] = useState(null);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [purchases, setPurchases] = useState<any>([
    { Link: "", Quantity: "0" },
  ]);
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  var today = new Date();
  var tomorrow = new Date();
  const services = useAppSelector((state) => state.deliveryType);
  const screenSize = UseWindowSize();
  const [arrivalBetweenDate, setArrivalBetweenDate] = useState(new Date());
  const [arrivalToDate, setArrivalToDate] = useState(new Date());
  const [departureBetweenDate, setDepartureBetweenDate] = useState<any>(
    getOneAddDate(today)
  );
  const [departureToDate, setDepartureToDate] = useState<any>(
    tomorrow.setDate(today.getDate() + 1)
  );
  const [fromDate1, setFromDate1] = useState(getDate(new Date()));
  const [fromDate2, setFromDate2] = useState(getDate(getOneAddDate(today)));
  const [toDate1, setToDate1] = useState(getDate(new Date()));
  const [toDate2, setToDate2] = useState(
    getDate(new Date(getOneAddDate(today)))
  );
  const [from, setFrom] = useState<any>();
  const [to, setTo] = useState<any>();
  const currencies = useAppSelector((state) => state.currencies);
  const addPackageData: any = useAppSelector((state) => state.addPackage);
  const editPackageData: any = useAppSelector((state) => state?.editPackage);
  const isMobile = screenSize.width < 768;

  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [currenciesOption, setCurrenciesOption] = useState([
    {
      value: localStorage.getItem("currency"),
      label: localStorage.getItem("currency"),
    },
  ]);

  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];

  const unitLengthOption = [
    { value: "cm", label: "CM" },
    { value: "inch", label: "INCH" },
  ];

  const handleAirlineChange = (selected) => {
    setAirline(selected);
  };

  const preventTypeTextAndDot = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventTypeText = (e) => {
    const validInputRegex = /^[0-9.]$/; // Allow digits and dot
    const currentValue = e.target.value + e.key;
    if (validInputRegex.test(e.key) || e.key === "Backspace") {
      const regex = /^(?!1000(\.0)?$)\d{1,3}(\.\d{0,1})?$/;
      if (regex.test(currentValue) || currentValue === "") {
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const handleTermsCheckedChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleLengthUnitChange = (selected) => {
    setLengthUnit(selected);
  };

  const handleGovernmentChange = () => {
    setGovernmentChecked(!governmentChecked);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    // setRequestData({ ...requestData, [e.target.name]: e.target.value });
    const newValue = e.target.value;

    if (newValue.length <= 100) {
      setRequestData({ ...requestData, [e.target.name]: newValue });
    } else {
      setRequestData({
        ...requestData,
        [e.target.name]: newValue.slice(0, 100),
      });
    }
  };

  useEffect(() => {
    dispatch(getAllSizeRange());
    dispatch(getAllDeliveryType());
    dispatch(getAllPackagesType());
  }, []);

  useEffect(() => {
    const options = packagesType?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setType(null);
    setPackagetypeIds([packagesType[0]?.id]);
    setTypeOptions(options);
  }, [packagesType]);

  const handleTypeChange = (selected) => {
    if (
      selected?.find((item) => {
        return item.value === "60db617a-dba2-406c-a960-1928a8b45320";
      })
    ) {
      setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);
      setIsCompanion(true);
      setIsCustom(false);
      if (isPersian)
        setSize({
          value: "6a50f6c5-0c00-498e-9653-1d8914172181",
          label: "سفارشی",
        });
      else
        setSize({
          value: "6a50f6c5-0c00-498e-9653-1d8914172181",
          label: "Custom",
        });
      setRequestData({
        ...requestData,
        sizeWidth: "0",
        sizeHeight: "0",
        sizeLength: "0",
      });
      if (isPersian)
        setType([
          {
            value: "60db617a-dba2-406c-a960-1928a8b45320",
            label: "همسفر",
          },
        ]);
      else
        setType([
          {
            value: "60db617a-dba2-406c-a960-1928a8b45320",
            label: "Traveling Companion",
          },
        ]);
    } else {
      setIsCustom(false);
      setIsCompanion(false);

      setType(selected);
      var result1 = selected.map(function (item) {
        return item.value;
      });
      const result2 = result1.filter(function (item) {
        return item !== "60db617a-dba2-406c-a960-1928a8b45320";
      });
      setPackagetypeIds(result2);
      if (isPersian)
        setSize({
          value: "aed0d42c-c59f-4267-9d84-ebb772705553",
          label: "کوچک",
        });
      else
        setSize({
          value: "aed0d42c-c59f-4267-9d84-ebb772705553",
          label: "Small",
        });
    }
  };

  useEffect(() => {
    const options = sizeRanges?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setSize(null);
    setSizeOptions(options);
  }, [sizeRanges]);

  useEffect(() => {
    const options = services?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setService(null);
    setDeliverytypeIds([services[0]?.id]);
    setServicesOptions(options);
  }, [services]);

  const handleServicesChange = (selected) => {
    setService(selected);
    setDeliverytypeIds(
      selected?.map((item) => {
        return item.value;
      })
    );
  };

  const handleFromChange = (selected) => {
    setFrom(selected);
  };

  const handleToChange = (selected) => {
    setTo(selected);
  };

  const getOneAddDateString = (date) => {
    const originalDate = new Date(date);
    const updatedDate = new Date(originalDate);
    updatedDate.setDate(originalDate.getDate() + 1);
    const year = updatedDate.getFullYear();
    const month = String(updatedDate.getMonth() + 1).padStart(2, "0");
    const day = String(updatedDate.getDate()).padStart(2, "0");
    const updatedDateString = `${year}-${month}-${day}`;
    return updatedDateString;
  };

  const onArrivalBetweenDateChange = (date) => {
    setArrivalBetweenDate(date);
    setFromDate1(getDate(date));
    setDepartureBetweenDate(getOneAddDate(date));
    setFromDate2(getOneAddDateString(getDate(date)));
    setDepartureToDate(getOneAddDate(date));
    setToDate2(getOneAddDateString(getDate(date)));
  };

  // const onArrivalToDateChange = (date) => {
  //   setArrivalToDate(date);
  //   setToDate1(getDate(date));
  // };

  const onDepartureFromDateChange = (date) => {
    setDepartureBetweenDate(date);
    setFromDate2(getDate(date));
    setDepartureToDate(date);
    setToDate2(getDate(date));
  };

  const onDepartureToDateChange = (date) => {
    setDepartureToDate(date);
    setToDate2(getDate(date));
  };

  const handleUnitChange = (selected) => {
    setWeightUnit(selected);
  };

  const handleCurrencyChange = (selected) => {
    setCurrency(selected);
  };

  const handleSizeChange = (selected) => {
    setSize(selected);
    if (selected.value === "6a50f6c5-0c00-498e-9653-1d8914172181")
      setIsCustom(true);
    else setIsCustom(false);
  };

  const SelectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>{props.children}</components.MenuList>
    );
  };

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      backgroundColor: "#707070",
      whiteSpace: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "auto",
    }),
  };

  const customStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 50,
      minWidth: 165,
      backgroundColor: isDisabled ? "#efefef !important" : "white",
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#fff",
      whiteSpace: "nowrap",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "auto",
    }),
  };

  useEffect(() => {
    if (mode === "add") {
      setFrom(null);
      setTo(null);
      setType(null);
      setService(null);
      setSize(null);
      setArrivalBetweenDate(new Date());
      setArrivalToDate(new Date());
      setDepartureBetweenDate(tomorrow.setDate(today.getDate() + 1));
      setDepartureToDate(new Date());
      setRequestData({
        ...requestData,
        weight: "",
        message: "",
        value: "",
        offerPrice: "",
        sizeWidth: "0",
        sizeHeight: "0",
        sizeLength: "0",
        airlineName: "",
        flightNumber: "",
      });
      setImages([]);
    }
  }, [mode]);

  useEffect(() => {
    setIsCompanion(false);
    const data = userPackage.data;
    if (userPackage?.length !== 0 && mode === "edit") {
      if (data.packagetypes) {
        let types = data?.packagetypes?.split(", ");
        if (data?.packagetypes?.includes(",")) {
          const packages = types
            .map((item) => {
              return typeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setType(packages);
          const results = packages?.map((pkg) => {
            return pkg.value;
          });
          setPackagetypeIds(results);
        } else {
          if (
            data?.packagetypes === "Traveling Companion" ||
            data?.packagetypes === "همسفر"
          ) {
            setIsCompanion(true);
            if (isPersian)
              setSize({
                value: "6a50f6c5-0c00-498e-9653-1d8914172181",
                label: "سفارشی",
              });
            else
              setSize({
                value: "6a50f6c5-0c00-498e-9653-1d8914172181",
                label: "Custom",
              });
            setPackagetypeIds(["60db617a-dba2-406c-a960-1928a8b45320"]);

            setRequestData({
              ...requestData,
              sizeWidth: "0",
              sizeHeight: "0",
              sizeLength: "0",
            });
            setType([
              {
                value: "60db617a-dba2-406c-a960-1928a8b45320",
                label: isPersian ? "همسفر" : "Traveling Companion",
              },
            ]);
          } else setIsCompanion(false);
          const type = typeOptions.find(
            (option) => option.label === data.packagetypes
          );
          setType(type);
          // setPackagetypeIds(type?.value)
        }
      } else {
        setType({ value: packagesType[0]?.id, label: packagesType[0]?.name });
      }
      if (data.sizerange) {
        let sizes = data?.sizerange?.split(", ");
        if (
          data?.sizerange?.includes("Custom") ||
          data?.sizerange?.includes("سفارشی")
        )
          setIsCustom(true);
        else setIsCustom(false);
        if (data?.sizerange?.includes(",")) {
          const ranges = sizes
            .map((item) => {
              return sizeOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setSize(ranges[0]);
        } else {
          if (
            data?.packagetypes === "همسفر" ||
            data?.packagetypes === "Traveling Companion"
          ) {
            if (isPersian)
              setSize({
                value: "6a50f6c5-0c00-498e-9653-1d8914172181",
                label: "سفارشی",
              });
            else
              setSize({
                value: "6a50f6c5-0c00-498e-9653-1d8914172181",
                label: "Custom",
              });
          } else
            setSize(
              sizeOptions.find((option) => option.label === data.sizerange)
            );
        }
      }

      if (data.deliveryTypes) {
        let types = data?.deliveryTypes?.split(", ");
        if (data?.deliveryTypes?.includes(",")) {
          const deliveries = types
            .map((item) => {
              return servicesOptions?.filter((x) => {
                return item === x.label;
              });
            })
            .flat(2);
          setService(deliveries);
          const results = deliveries?.map((pkg) => {
            return pkg.value;
          });
          setDeliverytypeIds(results);
        } else
          setService(
            servicesOptions.find(
              (option) => option.label === data.deliveryTypes
            )
          );
      } else {
        setService({
          value: services[0]?.id,
          label: services[0]?.name,
        });
      }
    }
  }, [userPackage, sizeOptions, typeOptions, servicesOptions]);

  useEffect(() => {
    if (userPackage?.length !== 0 && mode === "edit") {
      const data = userPackage?.data;
      searchLocationApi(data.fromCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data.fromCityEntityId)
            setFrom({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  {/* <div>
                    <span>{`${item?.airportName}${" "}(${
                      item?.airportCode
                    })`}</span>
                  </div> */}
                  <div>
                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });

      searchLocationApi(data.toCity).then((response) => {
        return response?.data?.data?.find((item) => {
          if (item?.id === data.toCityEntityId)
            setTo({
              value: item.id,
              countryLabel: item.countryName,
              cityLabel: item.cityName,
              airportLabel: item.airportName,
              label: (
                <>
                  {/* <div>
                    <span>{`${item?.airportName}${" "}(${
                      item?.airportCode
                    })`}</span>
                  </div> */}
                  <div>
                    <span>{`${item?.cityName}${" "},${
                      item?.countryName
                    }`}</span>
                  </div>
                </>
              ),
            });
        });
      });
      setAcceptPurchase(data.acceptPurchase);
      const currency = data.currencyType ? data.currencyType : "CAD";
      const unit = data.weightUnit ? data.weightUnit : "KG";
      const lUnit = data.lengthUnit ? data.lengthUnit : "CM";
      setWeightUnit({ value: unit, label: unit?.toUpperCase() });
      setLengthUnit({ value: lUnit, label: lUnit?.toUpperCase() });
      setCurrency({ value: currency, label: currency?.toUpperCase() });
      // setSize(sizeOptions.find((option) => option.label === data.sizerange));
      setIsCustom(
        isPersian ? data.sizerange === "سفارشی" : data.sizerange === "Custom"
      );
      setArrivalBetweenDate(
        data?.fromDate1 ? convertHumanDateToUnix(data?.fromDate1) : new Date()
      );
      // setArrivalToDate(convertHumanDateToUnix(data?.toDate1));
      setDepartureBetweenDate(
        data?.fromDate2
          ? convertHumanDateToUnix(data?.fromDate2)
          : tomorrow.setDate(today.getDate() + 1)
      );
      setDepartureToDate(
        data?.toDate2
          ? convertHumanDateToUnix(data?.toDate2)
          : tomorrow.setDate(today.getDate() + 1)
      );
      setFromDate1(data?.fromDate1);
      setToDate1(data?.toDate1);
      setFromDate2(data?.fromDate2);
      setToDate2(data?.toDate2);
      const newPurchases = data?.purchases?.map(({ link, quantity }) => ({
        Link: link,
        Quantity: quantity,
      }));
      setPurchases(
        newPurchases?.length !== 0
          ? newPurchases
          : [{ Link: "", Quantity: "0" }]
      );
      setAirline({ value: data?.airlineName, label: data?.airlineName });

      setRequestData({
        ...requestData,
        weight: data.weight,
        message: data.description,
        value: data.itemValue,
        offerPrice: data.offerPrice,
        sizeWidth: data.sizeWidth,
        sizeHeight: data.sizeHeight,
        sizeLength: data.sizeLength,
        flightNumber: data?.flightNumber,
        // link: data.link,
      });
      setImages(data.images);
    }
  }, [userPackage]);

  const addEditPackages = () => {
    setSubmitClicked(true);
    const data = {
      offerPrice: requestData.offerPrice,
      type,
      size,
      length: requestData.sizeLength,
      width: requestData.sizeWidth,
      height: requestData.sizeHeight,
      from,
      to,
      arrivalBetweenDate,
      departureBetweenDate,
      departureToDate,
      isCompanion,
    };
    if (!isValid(termsChecked, governmentChecked, data)) {
      toast.error(
        lang === "fa"
          ? "پارامترها را به درستی وارد نمایید"
          : "Enter the parameters correctly!"
      );
      return;
    }
    addEditPackagesApiCall();
    document.documentElement.style.overflow = "auto";
  };

  const addEditPackagesApiCall = () => {
    document.documentElement.style.overflow = "hidden";
    const body = new FormData();
    if (mode === "edit") body.append("pkgId", pkgId);
    body.append("packagetypeIds", packagetypeIds);
    body.append("packageType", "");
    body.append("sizerangeId", size.value);
    body.append("weight", requestData.weight);
    body.append("weightUnit", weightUnit.value);
    body.append("value", requestData.value);
    body.append("sizeWidth", requestData.sizeWidth);
    body.append("sizeHeight", requestData.sizeHeight);
    body.append("sizeLength", requestData.sizeLength);
    body.append("lengthUnit", lengthUnit.value);
    body.append("deliverytypeIds", deliverytypeIds);
    body.append("fromCityEntityId", from.value ? from.value : "");
    body.append("toCityEntityId", to.value ? to.value : "");
    body.append("fromDate1", fromDate1);
    body.append("fromDate2", fromDate2);
    body.append("toDate2", toDate2);
    body.append("offerPrice", requestData.offerPrice);
    body.append("currencyType", currency.value);
    body.append("message", requestData.message);
    isCompanion &&
      body.append("airlineName", airline?.label ? airline?.label : "");
    isCompanion && body.append("flightNumber", requestData?.flightNumber);
    for (const image of images) {
      body.append("images", image);
    }
    const itemsToBuy = purchases.filter((purchase) => {
      return !(purchase.Link === "" && purchase.Quantity === "0");
    });
    let i;
    for (i = 0; i < itemsToBuy?.length; i++) {
      body.append(`Purchases[${i}][Quantity]`, itemsToBuy[i].Quantity);
      body.append(`Purchases[${i}][Link]`, itemsToBuy[i].Link);
    }
    if (mode === "add") dispatch(addNewPackage(body));
    else if (mode === "edit") dispatch(editUserPackage(body));
    setIsLoading(true);
  };

  useEffect(() => {
    if (!addPackageData?.isSuccess) {
      setIsLoading(false);
    } else if (addPackageData?.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      dispatch(getAllDashboardData());
    }
  }, [addPackageData]);

  useEffect(() => {
    if (!editPackageData?.isSuccess) {
      setIsLoading(false);
    } else if (editPackageData?.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      dispatch(getAllDashboardData());
    }
  }, [editPackageData]);

  const openInfoModal = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  const handleChangeClose = () => {
    const str = window.location.pathname;
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    if (str?.includes("dashboard/addPackage"))
      navigate("/dashboard/myPackages", { state: t("myPackages") });
    if (str?.includes("dashboard/editPackage"))
      navigate("/dashboard/myPackages", { state: t("myPackages") });
  };

  const handleSelect = (show) => {
    setOpenProfileBox(show);
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="request-slider-container">
      <Row className={`request-wrapper ${isPersian ? "dir-rtl" : ""}`}>
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className="request-form">
          <h1 className={isPersian ? "text-right" : "text-left"}>
            {mode === "add" ? t("addRequest") : t("editRequest")}
          </h1>
          <Row className="align-items-center">
            <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
              <span className="send-pack-title">
                {t("from")}
                <span className="star-require">*</span>
              </span>
            </Col>
            <Col xs={10}>
              <AsyncSelect
                className={`custom-package-place-from ${
                  submitClicked &&
                  !isValidFrom(from) &&
                  "custom-empty-input-style"
                }`}
                value={from}
                onChange={handleFromChange}
                cacheOptions
                defaultOptions
                placeholder={
                  <span className="select-placeholder-text">
                    {t("countryCityAirport")}
                  </span>
                }
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                styles={customStyle}
                loadOptions={searchAirportLocation}
                loadingMessage={() => "Loading ..."}
              />
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
              <span className="send-pack-title">
                {t("to")}
                <span className="star-require">*</span>
              </span>
            </Col>
            <Col xs={10}>
              <AsyncSelect
                className={`custom-input-at-destination ${
                  submitClicked && !isValidTo(to) && "custom-empty-input-style"
                }`}
                value={to}
                onChange={handleToChange}
                cacheOptions
                defaultOptions
                placeholder={
                  <span className="select-placeholder-text">
                    {t("countryCityAirport")}
                  </span>
                }
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                styles={customStyle}
                loadOptions={searchAirportLocation}
                loadingMessage={() => "Loading ..."}
              />
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col xs={12} className="request-form">
              <span
                className={`send-pack-title mt-4 d-block w-100 ${
                  isPersian ? "text-right" : ""
                }`}
              >
                {t("shippingDateRange")}:
              </span>
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={3}
                      className={`${isPersian ? "text-right pr-3" : ""}`}
                    >
                      <span className="send-pack-title">
                        {t("fromDate")}
                        <span className="star-require">*</span>
                      </span>
                    </Col>
                    <Col xs={9} className={isPersian ? "pr-0" : "pl-0"}>
                      {" "}
                      <DatePicker
                        className="custom-input-between"
                        selected={arrivalBetweenDate}
                        onChange={(date) => onArrivalBetweenDateChange(date)}
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        minDate={new Date()}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Row className="align-items-center mt-3">
                    <Col
                      xs={3}
                      className={`${!isMobile && "text-center"} ${
                        isPersian ? "text-right pr-3" : "pr-0"
                      }`}
                    >
                      <span className="send-pack-title">
                        {t("toDate")}
                        <span className="star-require">*</span>
                      </span>
                    </Col>
                    <Col
                      xs={9}
                      className={`${isPersian ? " text-right pr-1" : "pl-0"}`}
                    >
                      <DatePicker
                        className={`custom-input-to-request d-inline-block ${
                          submitClicked &&
                          !isEndDateGreaterEqualStartDate(
                            arrivalBetweenDate,
                            departureBetweenDate
                          ) &&
                          "empty-input-style"
                        }`}
                        selected={departureBetweenDate}
                        onChange={(date) => onDepartureFromDateChange(date)}
                        dateFormat="EEEE, MM/dd/yyyy"
                        showTimeInput
                        minDate={addDays(arrivalBetweenDate, 1)}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center mt-3">
            <Col
              xs={isPersian ? 2 : 3}
              className={`${isPersian ? "text-right pr-3" : ""}`}
            >
              <span className="send-pack-title">
                {t("PackagesType")}
                <span className="star-require">*</span>
              </span>
            </Col>
            <Col xs={isPersian ? 10 : 9}>
              <Select
                className={`custom-select-send ${
                  submitClicked &&
                  !isValidPackageType(type) &&
                  "custom-empty-input-style"
                }`}
                value={type}
                placeholder={t("selectFromOptions")}
                onChange={handleTypeChange}
                options={typeOptions}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                  Option: InputOption,
                }}
                styles={customStyle}
                isMulti
              />
            </Col>
          </Row>
          {isCompanion && (
            <span className="warning-payable-title d-block mt-2 mb-3 w-100">
              {t("companionWarning")}
            </span>
          )}
          {!isCompanion && (
            <Row className="align-items-center mt-3">
              <Col xs={2} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("weight")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={10}>
                <Input
                  size="sm"
                  id="weight"
                  name="weight"
                  placeholder={t("weightKgLbs")}
                  className="custom-input-weight"
                  value={requestData.weight}
                  onChange={handleChange}
                  disabled={isCompanion}
                  onKeyPress={preventTypeText}
                />
                <Select
                  className="custom-select-unit-size d-inline-block"
                  value={weightUnit}
                  onChange={handleUnitChange}
                  options={unitWeightOption}
                  styles={unitCustomStyle}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                />
              </Col>
            </Row>
          )}
          {!isCompanion && (
            <Row className="align-items-center mt-3">
              <Col
                xs={2}
                className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
              >
                <span className="send-pack-title">
                  {t("andSize")}
                  <span className="star-require">*</span>
                </span>
              </Col>
              <Col xs={9}>
                <Select
                  className={`custom-select-size-package ${
                    submitClicked &&
                    !isValidSize(size, isCompanion) &&
                    "custom-empty-input-style"
                  }`}
                  value={size}
                  onChange={handleSizeChange}
                  options={sizeOptions}
                  components={{
                    IndicatorSeparator: () => null,
                    Option: InputOption,
                  }}
                  styles={customStyle}
                  isDisabled={isCompanion}
                />
              </Col>
              <Col xs={1} className={`${isPersian ? "pr-0" : ""}`}>
                <Dropdown
                  className="size-info-dropdown d-inline"
                  onToggle={handleSelect}
                >
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="px-0 mx-0"
                  >
                    <div className="triangle">
                      <FontAwesomeIcon
                        icon={faInfo}
                        className="info-defined-wrapper"
                      />
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <strong>{t("small")}</strong>, {t("smallExample")}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <strong>{t("medium")}</strong>, {t("mediumExample")}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <strong>{t("large")}</strong>, {t("largeExample")}
                    </Dropdown.Item>
                    <Dropdown.Item>
                      <strong>{t("xlarge")}</strong>, {t("xLargeExample")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          )}
        </Col>

        <Col xs={12} className="request-form">
          {isCustom && (
            <Row className="align-items-center mt-3">
              <Col
                xs={2}
                className={`${isPersian ? "text-right pr-3" : "pr-0"}`}
              >
                <span className="send-pack-title">
                  {t("definedAs")}
                  {size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" && (
                    <span className="star-require">*</span>
                  )}
                </span>
              </Col>
              <Col xs={2} className="pl-0">
                {" "}
                <Input
                  size="sm"
                  id="sizeLength"
                  name="sizeLength"
                  placeholder="Length"
                  className={`custom-input-size-length ${
                    submitClicked &&
                    size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                    !isValidDefinedLengthSize(requestData.sizeLength) &&
                    "empty-input-style"
                  }`}
                  value={requestData.sizeLength}
                  onChange={handleChange}
                  disabled={!isCustom || isCompanion}
                  onKeyPress={preventTypeText}
                />
              </Col>
              <Col xs={2} className="pl-0">
                <Input
                  size="sm"
                  id="sizeWidth"
                  name="sizeWidth"
                  placeholder="Width"
                  className={`custom-input-size-width ${
                    submitClicked &&
                    size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                    !isValidDefinedWidthSize(requestData.sizeWidth) &&
                    "empty-input-style"
                  }`}
                  value={requestData.sizeWidth}
                  onChange={handleChange}
                  disabled={!isCustom || isCompanion}
                  onKeyPress={preventTypeText}
                />
              </Col>
              <Col xs={2} className="pl-0">
                <Input
                  size="sm"
                  id="sizeHeight"
                  name="sizeHeight"
                  placeholder="Height"
                  className={`custom-input-size-height ${
                    submitClicked &&
                    size?.value === "6a50f6c5-0c00-498e-9653-1d8914172181" &&
                    !isValidDefinedHeightSize(requestData.sizeHeight) &&
                    "empty-input-style"
                  }`}
                  value={requestData.sizeHeight}
                  onChange={handleChange}
                  disabled={!isCustom || isCompanion}
                  onKeyPress={preventTypeText}
                />
              </Col>
              <Col xs={2} className="pl-0">
                <Select
                  className="custom-select-unit-length d-inline-block"
                  value={lengthUnit}
                  onChange={handleLengthUnitChange}
                  options={unitLengthOption}
                  styles={unitCustomStyle}
                  isDisabled={!isCustom || isCompanion}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: () => null,
                  }}
                />
              </Col>
              <Col
                xs={2}
                className={`${isPersian ? "text-right" : "text-left"}`}
              >
                <div className="triangle">
                  <FontAwesomeIcon
                    icon={faInfo}
                    className="info-defined-wrapper"
                    onClick={openInfoModal}
                  />
                </div>
              </Col>
            </Row>
          )}
        </Col>
        {isInfoOpen && (
          <SizeInformation isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} />
        )}
        <Col xs={12} className="request-form">
          <Row className="align-items-center mt-3">
            <Col
              xs={isPersian ? 3 : 4}
              className={`${isPersian ? "text-right pr-3" : ""}`}
            >
              <span className="send-pack-title">
                {t("forPrice")}
                <span className="star-require">*</span>
              </span>
            </Col>
            <Col xs={isPersian ? 9 : 8}>
              <Input
                size="sm"
                id="offerPrice"
                name="offerPrice"
                placeholder={t("amount")}
                className={`custom-input-and-offer d-inline-block ${
                  submitClicked &&
                  !isValidOfferPrice(requestData.offerPrice) &&
                  "empty-input-style"
                }`}
                value={requestData.offerPrice}
                onChange={handleChange}
                onKeyPress={preventTypeTextAndDot}
              />
              <Select
                className="custom-select-unit-price d-inline-block"
                value={currency}
                onChange={handleCurrencyChange}
                options={currenciesOption}
                styles={unitCustomStyle}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
              />
            </Col>
          </Row>
          <span className="info-payable-title d-block mt-3 mb-3 w-100">
            {t("onlinePaymentForCanada")}
          </span>
          <Row className="align-items-center mt-3">
            <Col xs={4} className={`${isPersian ? "text-right pr-3" : ""}`}>
              <span className="send-pack-title">{t("deliveryDeadlineIs")}</span>
            </Col>
            <Col xs={8}>
              <DatePicker
                className={`custom-input-between-deadline`}
                selected={departureToDate}
                onChange={(date) => onDepartureToDateChange(date)}
                dateFormat="EEEE, MM/dd/yyyy"
                showTimeInput
                minDate={addDays(departureBetweenDate, 0)}
              />
            </Col>
          </Row>
        </Col>
        {!isCompanion && (
          <Col xs={12} className="request-form">
            <Row className="align-items-center mt-3">
              <Col xs={3} className={`${isPersian ? "text-right pr-3" : ""}`}>
                <span className="send-pack-title">
                  {t("requestedServices")}
                </span>
              </Col>
              <Col xs={9}>
                <Select
                  className="custom-select-package-service"
                  value={service}
                  onChange={handleServicesChange}
                  options={servicesOptions}
                  components={{
                    IndicatorSeparator: () => null,
                    Option: InputOption,
                  }}
                  styles={customStyle}
                  isMulti
                />
              </Col>
            </Row>
          </Col>
        )}
        {isCompanion && (
          <Col xs={12} className="request-form">
            <Row>
              <Col lg={isPersian ? 7 : 6} md={isPersian ? 7 : 6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={4}
                    lg={5}
                    md={5}
                    sm={4}
                    className={isPersian ? "text-right pr-3" : ""}
                  >
                    <span className="send-pack-title">{t("flightNameIs")}</span>
                  </Col>
                  <Col
                    xs={8}
                    lg={7}
                    md={7}
                    sm={8}
                    className={`${isPersian ? "pr-0" : "pl-0"} `}
                  >
                    <AsyncSelect
                      className="custom-input-airline"
                      value={airline}
                      onChange={handleAirlineChange}
                      cacheOptions
                      defaultOptions
                      placeholder={t("flightNameIs")}
                      components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => null,
                        Option: InputOption,
                      }}
                      styles={customStyle}
                      loadOptions={searchAirline}
                      loadingMessage={() => "Loading ..."}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={isPersian ? 5 : 6} md={isPersian ? 5 : 6} sm={12}>
                <Row className="align-items-center mt-3">
                  <Col
                    xs={4}
                    lg={isPersian ? 4 : 5}
                    md={isPersian ? 4 : 5}
                    sm={4}
                    className={` ${isMobile ? "" : "text-right"}`}
                  >
                    <span className="send-pack-title">
                      {t("andFlightNumber")}
                    </span>
                  </Col>
                  <Col
                    xs={8}
                    lg={isPersian ? 8 : 7}
                    md={isPersian ? 8 : 7}
                    sm={8}
                    className={`${isPersian ? "pr-0" : "pl-0"} `}
                  >
                    <Input
                      id="flightNumber"
                      name="flightNumber"
                      placeholder="XDS4528"
                      className="custom-input-offer-flight-number"
                      value={requestData.flightNumber}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        )}

        <Col xs={12} sm={12} md={6} lg={6} className="request-form mt-4">
          <div
            style={
              isMobile
                ? isPersian
                  ? { width: "345px", paddingLeft: "20px" }
                  : { width: "345px", paddingRight: "20px" }
                : isPersian
                ? { width: "100%", textAlign: "right" }
                : { width: "580px" }
            }
          >
            <MultipleUploader
              title={t("uploadPkgImgOptional")}
              setPhotos={setImages}
              image={images}
              disabled={false}
              width="w-273"
              description={t("dropImageHere")}
              hasRemove={true}
              pkgId={pkgId}
            />
            {/* <Uploader title="Upload package photo" /> */}
          </div>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6} className="request-form mt-4">
          <div
            className={`send-input-wrapper ${isPersian ? "text-right" : ""}`}
            style={{ marginTop: "18px" }}
          >
            <span className="message-title">{t("noteDetail")}</span>
            <div>
              <Input
                size="sm"
                id="message"
                name="message"
                placeholder={t("acceptAllVisibleDocs")}
                className="custom-input-message-package"
                value={requestData.message}
                onChange={handleChange}
                textArea={true}
                rows={4}
                maxLength="100"
              />
            </div>
          </div>
        </Col>
        <AddInputForms
          setPurchases={setPurchases}
          purchases={purchases}
          disabled={false}
        />
        <Col xs={12} className="request-form">
          <div style={{ marginTop: "30px" }}>
            <label className="checkbox-container">
              {t("termConditionLinkText")}{" "}
              <a
                target="_blank"
                href="/termsCondition"
                style={{ textDecorationLine: "underline" }}
              >
                {t("termConditionLink")}{" "}
              </a>
              {t("termConditionLink2")}.
              <input
                type="checkbox"
                checked={termsChecked}
                onChange={handleTermsCheckedChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </Col>
        <Col xs={12} className="request-form">
          <div>
            <label className="checkbox-container gov-checkbox-container">
              {t("acceptAllGovernmentTaxes")}
              <span className="star-require">*</span>
              <input
                type="checkbox"
                checked={governmentChecked}
                onChange={handleGovernmentChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </Col>
        {/* <Col xs={12} className="request-form"> */}
        <div
          className="d-flex w-100 px-3 dir-ltr"
          style={{ marginTop: "24px" }}
        >
          <Button
            onClick={handleChangeClose}
            className="close-request-btn ml-auto mr-2 mt-4"
          >
            {t("close")}
          </Button>
          <Button
            variant="primary"
            className="request-submit-request-btn"
            onClick={addEditPackages}
            disabled={!termsChecked || !governmentChecked}
          >
            {t("submitRequest")}
            {isLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </div>
        {/* </Col> */}
      </Row>
    </div>
  );
};
