/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faLock,
  faDashboard,
  faUser,
  faBars,
  faBell,
  faMessage,
  faCertificate,
  faCheck,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { UseWindowSize } from "../../components/windowSize/UseWindowSize";
import { RightSidebar } from "layers";
import BellIcon from "../../assets/images/bell.png";
import { Dropdown } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/store";
import { logoutUser } from "redux/actions/Authorization";
import { Button } from "layers";
import { useTranslation } from "react-i18next";
import { stack as Menu } from "react-burger-menu";
import User from "assets/images/user.png";
import Select from "react-select";
import { getChatCount, getUserAllChat } from "redux/actions/chat";
import { MultiChat } from "components/modals/MultiChat";
import {
  getAllAnnouncement,
  getAllAnnouncementCount,
} from "redux/actions/notifications";
import { useLocation } from "react-router-dom";
import MultiUserChat from "components/multiUserChat";
import { getNewDashboard, sendChangeLanguage } from "redux/actions/landing";

export const MenuHeader: React.FC = () => {
  const dropdownRef = useRef(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const size = UseWindowSize();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const loginData = useAppSelector<any>((state) => state.login);
  const hasChat: any = useAppSelector((state) => state.hasNewChat);
  const [hasNewChat, setHasNewChat] = useState(false);
  const [hasNewDashboardCount, setHasNewDashboardCount] = useState(false);
  const [notifyCount, setNotifyCount] = useState(0);
  const [msgCount, setMsgCount] = useState(0);
  const [isOpenChatModal, setIsOpenChatModal] = useState(false);
  const isMobile = size.width < 768;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(false);
  const [showContactSidebar, setShowContactSidebar] = useState(false);

  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const isPersian = lang === "fa";

  const navigate = useNavigate();
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [language, setLanguage] = useState([
    { value: lang, label: lang?.toUpperCase() },
  ]);
  const allNotificationCount: any = useAppSelector(
    (state) => state.allNotificationCount
  );
  const allDashboardCount: any = useAppSelector((state) => state.newDashboard);
  const allChatCount: any = useAppSelector((state) => state.chatCount);
  const currencies = useAppSelector((state) => state.currencies);
  const [languageOptions, setLanguageOptions] = useState([
    { value: "en", label: "EN" },
    { value: "fa", label: "FA" },
  ]);
  const [currenciesOption, setCurrenciesOption] = useState([
    {
      value: localStorage.getItem("currency"),
      label: localStorage.getItem("currency"),
    },
  ]);
  const [isToggle, setIsToggle] = useState(size?.width < 768);
  const [isLogin, setIsLogin] = useState(
    window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
      ? false
      : true
  );
  const AvatarImg = window.localStorage.getItem("avatar")
    ? window.localStorage.getItem("avatar")
    : null;

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  useEffect(() => {
    if (
      window.localStorage.getItem("token") !== "" &&
      window.localStorage.getItem("token") != null &&
      window.localStorage.getItem("token") != "null" &&
      window.localStorage.getItem("token") != undefined &&
      isMobile
    ) {
      dispatch(getAllAnnouncementCount());
      dispatch(getNewDashboard());
    }
  }, []);

  // const audioPlayer = useRef(null);

  // function playAudio() {
  //   audioPlayer.current.play();
  // }

  useEffect(() => {
    if (isLogin) dispatch(getChatCount());
  }, []);

  useEffect(() => {
    if (allNotificationCount?.isSuccess) {
      setNotifyCount(allNotificationCount?.data?.count);
      // playAudio();
    }
  }, [allNotificationCount]);

  useEffect(() => {
    if (allNotificationCount?.isSuccess) {
      setHasNewDashboardCount(allDashboardCount?.data);
      // playAudio();
    }
  }, [allDashboardCount]);

  useEffect(() => {
    if (allChatCount?.isSuccess) {
      setMsgCount(allChatCount?.data?.count);
    }
  }, [allChatCount]);

  const customStyle = {
    control: (styles) => ({
      ...styles,
      height: 30,
      backgroundColor: "transparent",
      border: "2px solid #fff",
    }),
    option: (styles) => ({
      ...styles,
      color: "#00043d",
      backgroundColor: "#f8f8f8",
      flexWrap: "nowrap",
      fontWeight: 500,
      fontSize: 11,
      width: "75px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#fff",
      fontWeight: 500,
      textAlign: "center",
    }),
    menuList: (styles) => ({
      ...styles,
      overFlowY: "hidden",
      width: 55,
    }),
  };

  const handleCurrencyChange = (selected) => {
    setCurrency(selected);
    localStorage.setItem("currency", selected.value);
    window.location.reload();
  };

  const handleLanguageChange = (selected) => {
    setLanguage(selected);
    localStorage.setItem("language", selected.value);
    i18n.changeLanguage(selected.value);
    const data = {
      language: selected.value,
    };
    if (window.localStorage.getItem("token"))
      dispatch(sendChangeLanguage(data));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const handleSelect = (show) => {
    setOpenMobileDropdown(show);
  };

  const handleToggle = (e) => {
    setIsToggle(!isToggle);
    setIsOpen(!isOpen);
  };

  const handleIsOpen = (e) => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setShowSidebar(false);
    if (loginData?.data?.isSuccess === true) {
      setIsLogin(true);
    }
  }, [loginData]);

  const getAllNotifications = () => {
    const data = {
      page: 1,
      pageSize: 5,
    };
    dispatch(getAllAnnouncement(data));
  };

  const handleLoginSidebar = () => {
    setShowSidebar(true);
    setIsOpen(false);
    navigate("/signin");
  };

  const logoutBtn = () => {
    const data = {
      accessToken: window.localStorage.getItem("token"),
      refreshToken: window.localStorage.getItem("refreshToken"),
    };
    dispatch(logoutUser(data));
  };

  const handleOpenChatModal = () => {
    setIsOpenChatModal(!isOpenChatModal);
    // dispatch(getUserAllChat());
    setHasNewChat(false);
  };

  useEffect(() => {
    if (hasChat?.isSuccess) {
      setHasNewChat(hasChat.data);
    }
  }, [hasChat]);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOpenContact = () => {
    setShowContactSidebar(!showContactSidebar);
    setIsOpen(!isOpen);
    navigate("/contactUS");
  };

  useEffect(() => {
    const str = window.location.pathname;
    if (str?.includes("contactUS")) {
      handleOpenContact();
    }
  }, []);

  // const handleOpenMenu = () => {
  //   setIsOpen(false);
  //   document.documentElement.style.overflow = "hidden";
  // };

  useEffect(() => {
    if (isOpen) document.documentElement.style.overflow = "hidden";
    else document.documentElement.style.overflow = "auto";
  }, [isOpen]);

  return (
    <>
      {/* <audio ref={audioPlayer} src="https://api.koolbaar.com/audio/ding.mp3" /> */}
      {size?.width < 768 ? (
        <>
          <Navbar
            collapseOnSelect
            expand="lg"
            className="ml-auto nav-menu-wrapper"
          >
            <div className="nav-menu-container">
              <div className="menu-responsive-wrapper">
                <Menu
                  isOpen={isOpen}
                  onOpen={handleIsOpen}
                  onClose={handleIsOpen}
                  right
                >
                  <a
                    className="menu-close-card-btn"
                    onClick={() => setIsOpen(false)}
                  >
                    <FontAwesomeIcon icon={faClose} />
                  </a>
                  <a id="home" className="menu-item" href="/">
                    {t("home")}
                  </a>
                  {isLogin && (
                    <a id="dashboard" className="menu-item" href="/dashboard">
                      {t("dashboard")}
                    </a>
                  )}
                  <a id="about" className="menu-item" href="/aboutUs">
                    {t("aboutUs")}
                  </a>
                  <a id="howitworks" className="menu-item" href="/howitworks">
                    {t("howItWorks")}
                  </a>

                  <a
                    id="visionmission"
                    className="menu-item"
                    href="/vision-mission"
                  >
                    {t("visionAndMission")}
                  </a>
                  <a
                    id="prohibitedItems"
                    className="menu-item"
                    href="/prohibitedItems"
                  >
                    {t("prohibitedItems")}
                  </a>
                  <a
                    id="contact"
                    className="menu-item"
                    onClick={handleOpenContact}
                  >
                    {t("contact")}
                  </a>

                  {!isLogin ? (
                    <Button
                      variant="warning"
                      data-test="docs-btn-anchor"
                      className="login-menu-btn"
                      onClick={handleLoginSidebar}
                    >
                      {t("loginSignup")}
                    </Button>
                  ) : (
                    <Button
                      variant="warning"
                      data-test="docs-btn-anchor"
                      className="login-menu-btn"
                      onClick={logoutBtn}
                    >
                      {t("logout")}
                    </Button>
                  )}
                </Menu>

                <div>
                  <Select
                    className={`custom-select-lang d-inline-block ${
                      isPersian ? "ml-1" : "mr-1"
                    }`}
                    value={language}
                    onChange={handleLanguageChange}
                    options={languageOptions}
                    styles={customStyle}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                  <Select
                    className="custom-select-currency d-inline-block mr-1"
                    value={currency}
                    onChange={handleCurrencyChange}
                    options={currenciesOption}
                    styles={customStyle}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator: () => null,
                    }}
                  />
                </div>
              </div>
              {isLogin ? (
                <>
                  <a className="bell-notify-icon ml-2" href={"/notifications"}>
                    <FontAwesomeIcon icon={faBell} />
                    {notifyCount > 0 && (
                      <div className="bell-notify-count">
                        {notifyCount > 99 ? "+99" : notifyCount}
                      </div>
                    )}
                  </a>
                  <div className="message-notify-icon">
                    <FontAwesomeIcon
                      onClick={handleOpenChatModal}
                      icon={faEnvelope}
                    />
                    {msgCount > 0 && (
                      <div className="message-notify-count">
                        {msgCount > 99 ? "+99" : msgCount}
                      </div>
                    )}
                  </div>
                  <Dropdown
                    className="profile-dropdown d-inline mr-2 responsive-header-profile-dropdown"
                    onToggle={handleSelect}
                  >
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="px-0 mx-0"
                    >
                      <span
                        className={`${
                          AvatarImg === "null" && "pt-1"
                        } user-profile-avatar d-inline-block`}
                      >
                        {AvatarImg !== "null" ? (
                          <>
                            <img src={AvatarImg} alt="avatar-img" />
                            <div className="login-userlevel-icons-wrapper">
                              <FontAwesomeIcon
                                className="mr-2 userlevel-certificate-icon"
                                icon={faCertificate}
                                color={
                                  window.localStorage.getItem(
                                    "userLevelHexColor"
                                  )
                                    ? window.localStorage.getItem(
                                        "userLevelHexColor"
                                      )
                                    : "#808080"
                                }
                              ></FontAwesomeIcon>
                              <FontAwesomeIcon
                                className="mr-2 userlevel-check-icon"
                                icon={faCheck}
                              />
                            </div>
                          </>
                        ) : (
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ fontSize: 20 }}
                          />
                        )}
                      </span>
                    </Dropdown.Toggle>
                    {openMobileDropdown && (
                      <Dropdown.Menu>
                        <Dropdown.Item
                          href="/settings"
                          onClick={() => {
                            localStorage.setItem("tab", "userInfo");
                          }}
                        >
                          {t("profile")}
                        </Dropdown.Item>
                        <Dropdown.Item className="mt-2" href="/dashboard">
                          {t("dashboard")}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="mt-2 logout-border-top"
                          onClick={logoutBtn}
                        >
                          {t("logout")}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                </>
              ) : size.width < 768 ? (
                <a onClick={handleLoginSidebar}>
                  <img
                    src={User}
                    alt="user-img"
                    className="responsive-user-avatar"
                  />
                </a>
              ) : (
                <Button
                  variant="warning"
                  data-test="docs-btn-anchor"
                  className="login-header-btn"
                  onClick={handleLoginSidebar}
                >
                  {t("loginSignup")}
                </Button>
              )}
              <a onClick={handleToggle} className="toggle-btn">
                <FontAwesomeIcon icon={faBars} />
              </a>
            </div>
          </Navbar>
        </>
      ) : size?.width < 992 ? (
        <Navbar
          collapseOnSelect
          expand="lg"
          className="pull-right d-inline-flex ml-auto menu-responsive-medium"
        >
          <Nav className="header-nav-wrapper" defaultActiveKey="/" as="ul">
            <Nav.Item as="li">
              <Nav.Link eventKey="1" as={Link} to="/">
                {t("home")}
              </Nav.Link>
            </Nav.Item>
            <NavDropdown
              className="main-menu-dropdown"
              title={t("more")}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item href="/aboutUs">
                {t("aboutUs")}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleOpenContact}>
                {t("contact")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/howitworks">
                {t("howItWorks")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/vision-mission">
                {t("visionAndMission")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/prohibitedItems">
                {t("prohibitedItems")}
              </NavDropdown.Item>
            </NavDropdown>
            {window.localStorage.getItem("token") && (
              <Nav.Item as="li">
                <Nav.Link eventKey="2" as={Link} to="/dashboard">
                  {t("dashboard")}
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
        </Navbar>
      ) : (
        <Navbar
          collapseOnSelect
          expand="lg"
          className="pull-right d-inline-flex ml-auto"
        >
          <Nav className="header-nav-wrapper" defaultActiveKey="/" as="ul">
            <Nav.Item as="li">
              {/* <Nav.Link eventKey="1" as={Link} to="/">
                {t("home")}
              </Nav.Link> */}
              <a id="home" className="menu-item" href="/">
                {t("home")}
              </a>
            </Nav.Item>
            <NavDropdown
              className={`main-menu-dropdown ${
                dropdownOpen ? "d-block-dropdown" : "d-none-dropdown"
              }`}
              title={t("more")}
              id="screen-nav-dropdown"
              onClick={handleDropdownToggle}
              ref={dropdownRef}
            >
              <NavDropdown.Item href="/aboutUs">
                {t("aboutUs")}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleOpenContact}>
                {t("contact")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/howitworks">
                {t("howItWorks")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/vision-mission">
                {t("visionAndMission")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/prohibitedItems">
                {t("prohibitedItems")}
              </NavDropdown.Item>
            </NavDropdown>
            {window.localStorage.getItem("token") && (
              <Nav.Item as="li" className="position-relative">
                <a href="/dashboard" onClick={getAllNotifications}>
                  {t("dashboard")}
                </a>{" "}
                {hasNewDashboardCount && (
                  <span className="dashboard-notify-count"></span>
                )}
              </Nav.Item>
            )}
          </Nav>
        </Navbar>
      )}
      {showSidebar && (
        <RightSidebar
          isOpen={showSidebar}
          setIsOpen={setShowSidebar}
          sidebarType="login"
        />
      )}
      {showContactSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showContactSidebar}
            setIsOpen={setShowContactSidebar}
            sidebarType="contactUs"
          />
        </div>
      )}
      {isOpenChatModal && (
        <MultiUserChat
          isOpen={isOpenChatModal}
          setIsOpen={setIsOpenChatModal}
        />
        // <MultiChat isOpen={isOpenChatModal} setIsOpen={setIsOpenChatModal} />
      )}
    </>
  );
};
