import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: [],
  reducers: {
    login: (state, action) => {
      return (state = action.payload);
    },
    loginFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { login, loginFailure } = loginSlice.actions;
export default loginSlice.reducer;
