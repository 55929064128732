import React, { useState, useEffect, useRef } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { Button, Input } from "layers";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import LogoImg from "assets/images/logo/koolbaar-logo-transparent.png";
import { useAppDispatch, useAppSelector } from "redux/store";
import { AddTravelingCompanion } from "components/common/addTravelingCompanion";
import { AddContractItems } from "components/common/addContractItems";
import { Gallery } from "react-photoswipe-gallery";
import { LightBoxItem } from "components";
import { approveBySender, editSenderAgreement } from "redux/actions/agreement";
import { Oval } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import { getDate } from "helpers/convertDate";
import Select from "react-select";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  isOpen: boolean;
  disableContract: boolean;
  setIsOpen: (key: any) => void;
  contractId: string;
  titleTab: string;
  data: any;
}

export const SenderContract: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  disableContract,
  contractId,
  data,
  titleTab,
}) => {
  const handleClose = () => setIsOpen(false);
  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const dropdownRef = useRef(null);
  const isMobileTablet = windowSize.width < 992;
  const isTablet = windowSize.width >= 768 && windowSize.width < 992;
  const isMobile = windowSize.width < 768;
  const [agreementTermId, setAgreementTermId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [isOpenApprove, setIsOpenApprove] = useState(false);
  const [travelerDepartureDate, setTravelerDepartureDate] = useState(null);
  const [travelerArrivalDate, setTravelerArrivalDate] = useState(null);
  const [senderDeadline, setSenderDeadline] = useState(null);
  const [deadlineDate, setDeadlineDate] = useState(getDate(new Date()));
  const [currency, setCurrency] = useState({ value: curr, label: curr });
  const [totalWeightUnit, setTotalWeightUnit] = useState({
    value: "kg",
    label: "KG",
  });
  const [weightUnit, setWeightUnit] = useState({
    value: "kg",
    label: "KG",
  });
  const [currenciesOption, setCurrenciesOption] = useState([]);
  const { t } = useTranslation();
  const [contractData, setContractData] = useState({
    senderName: "",
    senderDepartureAddress: "",
    // totalValue: "",
    totalWeight: "",
    otherAgreementDetail: "",
    senderPhone: "",
    senderEmail: "",
    senderWhatsapp: "",
    senderIdentityDocumentNo: "",
    senderRecipientAddress: "",
    senderTypeIdentity: "",
    senderRecipientFullName: "",
    senderRecipientPhone: "",
    senderRecipientWhatsapp: "",
    detail: "",
  });
  const [travelingCompanions, setTravelingCompanions] = useState<any>([
    {
      firstName: "",
      lastName: "",
      gender: "",
      age: "",
      cellPhoneNumber: "",
    },
  ]);
  const [contractItems, setContractItems] = useState<any>([
    {
      designation: "",
      quantity: "",
      value: "",
      price: "",
      currencyType: "",
      weight: "",
      weightUnit: "",
      link: "",
    },
  ]);
  const [isAgreementOther, setIsAgreementOther] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [hasTravelingCompanion, setHasTravelingCompanion] = useState(false);
  const [isChangedSenderName, setIsChangedSenderName] = useState(false);
  const [isChangedSenderWhatsapp, setIsChangedSenderWhatsapp] = useState(false);
  const [isChangedSenderTypeIdentity, setIsChangedSenderTypeIdentity] =
    useState(false);
  const [
    isChangedSenderIdentityDocumentNo,
    setIsChangedSenderIdentityDocumentNo,
  ] = useState(false);
  const [
    isChangedSenderRecipientFullName,
    setIsChangedSenderRecipientFullName,
  ] = useState(false);
  const [isChangedSenderRecipientAddress, setIsChangedSenderRecipientAddress] =
    useState(false);
  const [isChangedSenderRecipientPhone, setIsChangedSenderRecipientPhone] =
    useState(false);
  const [isChangedTotalValue, setIsChangedTotalValue] = useState(false);
  const [isChangedTotalWeight, setIsChangedTotalWeight] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const [
    isChangedSenderRecipientWhatsapp,
    setIsChangedSenderRecipientWhatsapp,
  ] = useState(false);
  const isChangedTravelerName = false;
  const isChangedDepartureTravelerAddress = false;
  const isChangedArrivalTravelerAddress = false;
  const isChangedTravelerPhone = false;
  const isChangedTravelerWhatsapp = false;
  const isChangedTravelerEmail = false;
  const isChangedTravelerTypeIdentity = false;
  const isChangedTravelerIdentityDocumentNo = false;
  const isChangedTravelerCityCountryDeparture = false;
  const isChangedTravelerCityCountryArrival = false;
  const isChangedTravelerOfferWeight = false;
  const isChangedTravelerWeightShipper = false;
  const isChangedOfferPrice = false;
  const isChangedDetail = false;
  const navigate = useNavigate();

  const currencies = useAppSelector((state) => state.currencies);

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: item.code,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const unitWeightOption = [
    { value: "kg", label: "KG" },
    { value: "lbs", label: "LBS" },
  ];

  const modifySenderData: any = useAppSelector(
    (state) => state.modifySenderAgreement
  );
  const approveSenderData: any = useAppSelector((state) => state.approveSender);
  const [isChangedDepartureSenderAddress, setIsChangedDepartureSenderAddress] =
    useState(false);
  const [cancellationPolicyOptions, setCancellationPolicyOptions] = useState(
    []
  );
  // const [isChangedArrivalSenderAddress, setIsChangedArrivalSenderAddress] = useState(false);
  const [isChangedSenderPhone, setIsChangedSenderPhone] = useState(false);
  const [isChangedSenderEmail, setIsChangedSenderEmail] = useState(false);
  const [agreementTermsOptions, setAgreementTermsOptions] = useState([]);
  const sendData: any = data.data;
  const [expensesOptions, setExpensesOptions] = useState([]);

  useEffect(() => {
    setExpensesOptions(sendData?.traveler?.extraExpenses);
    setTravelingCompanions(
      sendData?.sender?.travelingcompanions?.length !== 0
        ? sendData?.sender?.travelingcompanions
        : [
            {
              firstName: "",
              lastName: "",
              gender: "",
              age: "",
              phone: "",
            },
          ]
    );
    setAgreementTermsOptions(sendData?.sender?.agreementterms);
    setContractItems(
      sendData?.sender?.itemtobuys?.length !== 0
        ? sendData?.sender?.itemtobuys
        : [
            {
              designation: "",
              quantity: "",
              value: "",
              price: "",
              currencyType: "",
              weight: "",
              weightUnit: "",
              link: "",
            },
          ]
    );
    setHasTravelingCompanion(sendData?.sender?.isTravelingCompanion);
    setCancellationPolicyOptions(sendData?.traveler?.cancellationPloicies);
  }, [sendData]);

  useEffect(() => {
    if (sendData?.sender) {
      setTotalWeightUnit({
        value: sendData?.sender?.totalWeightUnit,
        label: sendData?.sender?.totalWeightUnit?.toUpperCase(),
      });
      setDeadlineDate(sendData?.sender?.shippingDeadline);
      setContractData({
        ...contractData,
        senderName: sendData?.sender?.fullName,
        senderDepartureAddress: sendData?.sender?.departureAddress,
        senderPhone: sendData?.sender?.phoneNumber,
        senderWhatsapp: sendData?.sender.whatsapp,
        senderEmail: sendData?.sender?.email,
        senderTypeIdentity: sendData?.sender?.identityType,
        senderIdentityDocumentNo: sendData?.sender?.identityNumber,
        senderRecipientFullName: sendData?.sender?.recipientFullName,
        senderRecipientAddress: sendData?.sender?.recipientAddress,
        senderRecipientPhone: sendData?.sender?.recipientPhone,
        senderRecipientWhatsapp: sendData?.sender?.recipientWhatsapp,
        totalWeight: sendData?.sender?.totalWeight,
        otherAgreementDetail: sendData?.sender?.agreementtermOther,
        // totalValue: sendData?.sender?.totalValue,
      });
      if (sendData?.sender.agreementtermOther) setIsAgreementOther(true);
      else setIsAgreementOther(false);
      // setAgreementTermId(
      //   agreementTermsOptions?.find((term) => {
      //     return term.isChecked === true;
      //   })
      // );
    }
    if (sendData?.traveler) {
      setCurrency({
        value: sendData?.traveler?.currencyType,
        label: sendData?.traveler?.currencyType?.toUpperCase(),
      });
      setWeightUnit({
        value: sendData?.traveler?.weightUnit,
        label: sendData?.traveler?.weightUnit?.toUpperCase(),
      });
    }
  }, [sendData]);

  const downloadContract = () => {
    setIsDownloaded(true);
  };

  useEffect(() => {
    if (isDownloaded) {
      const input = document.getElementById("pdfdiv");

      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        const doc = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });
        let position = 0;
        doc.addImage(imgData, "PNG", 10, 0, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight + 25);
          heightLeft -= pageHeight;
        }
        setTimeout(() => {
          doc.save("contract.pdf");
        }, 200);
      });
    }
  }, [isDownloaded]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setContractData({ ...contractData, [e.target.name]: e.target.value });
    adjustInputSize(e.target.value, e.target.name);
    setIsChangedSenderName(true);
    setIsChangedSenderWhatsapp(true);
    setIsChangedSenderTypeIdentity(true);
    setIsChangedSenderIdentityDocumentNo(true);
    setIsChangedSenderRecipientFullName(true);
    setIsChangedSenderRecipientAddress(true);
    setIsChangedSenderRecipientPhone(true);
    setIsChangedSenderRecipientWhatsapp(true);
    // setIsChangedTotalValue(true);
    setIsChangedTotalWeight(true);
    // setIsChangedOtherAgreementDetail(true);
    setIsChangedDepartureSenderAddress(true);
    setIsChangedSenderPhone(true);
    setIsChangedSenderEmail(true);
  };

  const unitCustomStyle = {
    control: (styles, { isDisabled }) => ({
      ...styles,
      height: 30,
      padding: 0,
      border: "transparent",
      backgroundColor: isDisabled ? "#efefef !important" : "white",
      textAlign: "center",
    }),
    option: (styles) => ({
      ...styles,
      color: "#707070",
      backgroundColor: "#fff",
      flexWrap: "nowrap",
      fontWeight: "normal",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#00043d",
    }),
    menu: (styles) => ({
      ...styles,
      marginTop: "-5px",
    }),
  };

  const adjustInputSize = (text, id) => {
    const nameLocation = document.getElementById(id);
    if (text?.length < 1) {
      nameLocation.style.width =
        getTextSize(nameLocation.ariaLabel, "16px sans-serif") + 15 + "px";
    } else {
      nameLocation.style.width =
        getTextSize(text, "16px sans-serif") + 15 + "px";
    }
  };

  useEffect(() => {
    if (isChangedSenderName === false) adjustInputSize("", "senderName");
    if (isChangedDepartureSenderAddress === false)
      adjustInputSize("", "senderDepartureAddress");
    if (isChangedSenderPhone === false) adjustInputSize("", "senderPhone");
    if (isChangedSenderEmail === false) adjustInputSize("", "senderEmail");
    if (isChangedSenderWhatsapp === false)
      adjustInputSize("", "senderWhatsapp");
    if (isChangedSenderTypeIdentity === false)
      adjustInputSize("", "senderTypeIdentity");
    if (isChangedSenderIdentityDocumentNo === false)
      adjustInputSize("", "senderIdentityDocumentNo");
    if (isChangedSenderRecipientFullName === false)
      adjustInputSize("", "senderRecipientFullName");
    if (isChangedSenderRecipientAddress === false)
      adjustInputSize("", "senderRecipientAddress");
    if (isChangedSenderRecipientPhone === false)
      adjustInputSize("", "senderRecipientPhone");
    if (isChangedTravelerName === false) adjustInputSize("", "travelerName");
    if (isChangedSenderRecipientWhatsapp === false)
      adjustInputSize("", "senderRecipientWhatsapp");
    // if (isChangedTotalValue === false) adjustInputSize("", "totalValue");
    if (isChangedTotalWeight === false) adjustInputSize("", "totalWeight");
    // if (isChangedOtherAgreementDetail === false)
    //   adjustInputSize("", "otherAgreementDetail");
    if (isChangedDepartureTravelerAddress === false)
      adjustInputSize("", "travelerDepartureAddress");
    if (isChangedArrivalTravelerAddress === false)
      adjustInputSize("", "travelerArrivalAddress");
    if (isChangedTravelerPhone === false) adjustInputSize("", "travelerPhone");
    if (isChangedTravelerWhatsapp === false)
      adjustInputSize("", "travelerWhatsapp");
    if (isChangedTravelerEmail === false) adjustInputSize("", "travelerEmail");
    if (isChangedTravelerTypeIdentity === false)
      adjustInputSize("", "travelerTypeIdentity");
    if (isChangedTravelerIdentityDocumentNo === false)
      adjustInputSize("", "travelerIdentityDocumentNo");
    if (isChangedTravelerCityCountryDeparture === false)
      adjustInputSize("", "travelerCityCountryDeparture");
    if (isChangedTravelerCityCountryArrival === false)
      adjustInputSize("", "travelerCityCountryArrival");
    if (isChangedTravelerOfferWeight === false)
      adjustInputSize("", "travelerOfferWeight");
    if (isChangedTravelerWeightShipper === false)
      adjustInputSize("", "travelerWeightShipper");
    if (isChangedOfferPrice === false) adjustInputSize("", "offerPrice");
    if (isChangedDetail === false) adjustInputSize("", "detail");
  }, [contractData]);

  function getTextSize(txt, font) {
    let element = document.createElement("canvas");
    let context = element.getContext("2d");
    context.font = font;
    return context.measureText(txt).width;
  }

  function changeAgreementTerms(event) {
    const selectedId = event.target.value;
    setAgreementTermsOptions((prevOptions) =>
      prevOptions.map((item) => ({
        ...item,
        isChecked: item.id === selectedId,
      }))
    );

    setAgreementTermId(selectedId);
    setIsAgreementOther(selectedId === "a0960ad7-911f-4e5e-8d0b-6c3d5eb20de8");
  }
  const preventTypeTextAndDot = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const preventTypeText = (e) => {
    const validInputRegex = /^[0-9.]$/; // Allow digits and dot
    const currentValue = e.target.value + e.key;
    if (validInputRegex.test(e.key) || e.key === "Backspace") {
      const regex = /^(?!1000(\.0)?$)\d{1,3}(\.\d{0,1})?$/;
      if (regex.test(currentValue) || currentValue === "") {
      } else {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (
      travelingCompanions?.find((item) => {
        return (
          item.firstName === "" &&
          item.lastName === "" &&
          item.gender === "" &&
          item.age === "" &&
          item.phone === ""
        );
      })
    )
      setHasTravelingCompanion(false);
    else setHasTravelingCompanion(true);
  }, [travelingCompanions]);

  const edit = () => {
    setEditClicked(true);
  };

  const getString = (str) => {
    const getStrings = /[a-zA-Z]/g;
    return str.match(getStrings);
  };

  const submit = () => {
    setIsOpenApprove(true);
  };

  useEffect(() => {
    let companions;
    let buyItems;
    if (
      travelingCompanions?.find((item) => {
        return (
          item.firstName === "" &&
          item.lastName === "" &&
          item.gender === "" &&
          item.age === "" &&
          item.phone === ""
        );
      })
    )
      companions = [];
    else companions = travelingCompanions;
    if (
      contractItems?.find((item) => {
        return (
          item.designation === "" &&
          item.quantity === "" &&
          item.value === "" &&
          item.price === "" &&
          item.currencyType === "" &&
          item.weight === "" &&
          item.weightUnit === "" &&
          item.link === ""
        );
      })
    )
      buyItems = [];
    else buyItems = contractItems;
    const data = {
      typeId: contractId,
      departureAddress: contractData.senderDepartureAddress,
      whatsapp: contractData.senderWhatsapp,
      identityType: contractData.senderTypeIdentity,
      identityNumber: contractData.senderIdentityDocumentNo,
      recipientFullName: contractData.senderRecipientFullName,
      recipientAddress: contractData.senderRecipientAddress,
      recipientPhoneNumber: contractData.senderRecipientPhone,
      recipientWhatsapp: contractData.senderRecipientWhatsapp,
      deadline: deadlineDate,
      // totalValue: contractData.totalValue,
      currencyType: currency?.value ? currency?.value : "cad",
      totalWeight: contractData.totalWeight,
      totalWeightUnit: totalWeightUnit?.value ? totalWeightUnit.value : "kg",
      agreementtermId: agreementTermId ? agreementTermId : null,
      agreementtermOther: contractData.otherAgreementDetail,
      itemtobuys: buyItems,
      travelingcompanions: companions,
    };
    if (isApproved) {
      setIsLoading(true);
      dispatch(editSenderAgreement(data));
    }
  }, [isApproved]);

  useEffect(() => {
    if (!modifySenderData?.isSuccess) {
      setIsApproved(false);
    } else if (modifySenderData?.isSuccess) {
      document.documentElement.style.overflow = "auto";
      // dispatch(getAgreementByType(contractId));
      dispatch(approveBySender(contractId));
      setIsApproved(false);
      setIsOpen(false);
      setIsOpenApprove(false);
      navigate("/dashboard", { state: t(`${titleTab}`) });
    }
    setIsLoading(false);
  }, [modifySenderData]);

  useEffect(() => {
    if (approveSenderData?.isSuccess) {
      window.location.reload();
    }
  }, [approveSenderData]);

  const confirm = () => {
    setIsApproved(true);
    setIsOpenApprove(false);
  };

  const notConfirm = () => {
    setIsApproved(false);
    setIsOpenApprove(false);
  };

  const onDepartureDateChange = (date) => {
    setTravelerDepartureDate(date);
  };

  const onArrivalDateChange = (date) => {
    setTravelerArrivalDate(date);
  };

  const onSenderDeadlineChange = (date) => {
    setSenderDeadline(date);
    setDeadlineDate(getDate(date));
  };

  const handleTotalWeightUnitChange = (selected) => {
    setTotalWeightUnit(selected);
  };

  const handleWeightUnitChange = (selected) => {
    setWeightUnit(selected);
  };

  const handleCurrencyChange = (selected) => {
    setCurrency(selected);
  };

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/dashboard", { state: t(`${titleTab}`) });
  };

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "fade contract-modal-wrapper modal show") {
          document.documentElement.style.overflow = "auto";
          navigate("/dashboard", { state: t(`${titleTab}`) });
        }
      },
      false
    );
  }, []);

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <>
      {isOpenApprove && (
        <Modal
          id="pdfdiv"
          className="direct-modal-wrapper"
          show={isOpen}
          onHide={handleClose}
        >
          <Row className="detail-contract-wrapper">
            <div className="review-question-text text-center">
              <span>
                Please review your details carefully. Changes cannot be made
                once submitted.
              </span>
            </div>
            <div className="note-offer-detail mt-5 mx-auto">
              <Button
                variant="primary"
                className="confirm-contract-btn"
                onClick={confirm}
              >
                {t("approve")}
              </Button>
              <Button
                variant="white"
                className="no-confirm-contract-btn"
                onClick={notConfirm}
              >
                {t("cancel")}
              </Button>
            </div>
          </Row>
        </Modal>
      )}
      <Modal
        ref={dropdownRef}
        id="pdfdiv"
        className="contract-modal-wrapper"
        show={isOpen}
        onHide={handleClose}
      >
        <Button
          variant="white"
          className="close-agreement-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Modal.Body>
          <Row className="mt-4">
            <Col xs={12} sm={12} md={12} lg={7} className="ml-0">
              <a href="/" className="contract-logo-img-wrapper">
                <img src={LogoImg} alt="logo" className="contract-logo-img" />
              </a>
              <span
                className={`agreement-title-contract ${
                  isMobileTablet && "d-block w-100 mt-3"
                }`}
              >
                Agreement between Traveler and Sender
              </span>
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} className="text-right">
              {!isDownloaded && (
                <Button
                  variant="primary"
                  className="download-contract-btn"
                  onClick={downloadContract}
                >
                  Download
                </Button>
              )}
              {!disableContract && !isDownloaded && (
                <Button
                  className="edit-contract-btn"
                  onClick={editClicked ? submit : edit}
                >
                  {editClicked ? t("submitRequest") : t("edit")}
                  {isLoading && editClicked && (
                    <Oval
                      width="20"
                      height="20"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                    />
                  )}
                </Button>
              )}
            </Col>
          </Row>
          <Row className="ml-0">
            <span className={`contract-title ${isDownloaded && "mt-5"}`}>
              Sender
            </span>
          </Row>
          <Row className="ml-0 mt-4 contract-content-wrapper">
            <p className="contract-content">
              -Mr./Ms.<span className="star-require">*</span>{" "}
              <Input
                size="sm"
                id="senderName"
                name="senderName"
                ariaLabel={
                  sendData?.sender?.fullName
                    ? `${sendData?.sender.fullName}`
                    : "................"
                }
                placeholder={
                  sendData?.sender?.fullName
                    ? `${sendData?.sender.fullName}`
                    : "................"
                }
                className="custom-input-contract"
                value={contractData.senderName}
                onChange={handleChange}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              - Address country of departure
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderDepartureAddress"
                name="senderDepartureAddress"
                ariaLabel={
                  sendData?.sender?.departureAddress
                    ? `${sendData?.sender.departureAddress}`
                    : "--------------------------"
                }
                placeholder={
                  sendData?.sender?.departureAddress
                    ? `${sendData?.sender.departureAddress}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                value={contractData.senderDepartureAddress}
                onChange={handleChange}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              - Phone<span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderPhone"
                name="senderPhone"
                ariaLabel={
                  sendData?.sender?.phoneNumber
                    ? `${sendData?.sender.phoneNumber}`
                    : "--------------------------"
                }
                placeholder={
                  sendData?.sender?.phoneNumber
                    ? `${sendData?.sender.phoneNumber}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                value={contractData.senderPhone}
                onChange={handleChange}
                onKeyPress={preventTypeTextAndDot}
                disabled
              />{" "}
              - Whatsapp:{" "}
              <Input
                size="sm"
                id="senderWhatsapp"
                name="senderWhatsapp"
                ariaLabel={
                  sendData?.sender?.whatsapp
                    ? `${sendData?.sender.whatsapp}`
                    : "----------------"
                }
                placeholder={
                  sendData?.sender?.whatsapp
                    ? `${sendData?.sender.whatsapp}`
                    : "----------------"
                }
                className="custom-input-contract"
                value={contractData.senderWhatsapp}
                onChange={handleChange}
                disabled={!editClicked}
                onKeyPress={preventTypeTextAndDot}
              />{" "}
              - email<span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderEmail"
                name="senderEmail"
                ariaLabel={
                  sendData?.sender?.email
                    ? `${sendData?.sender.email}`
                    : "--------------------------"
                }
                placeholder={
                  sendData?.sender?.email
                    ? `${sendData?.sender.email}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                value={contractData.senderEmail}
                onChange={handleChange}
                disabled
              />
            </p>
            <p className="contract-content">
              - Identity Document Type
              <span className="star-require">*</span> :
              <Input
                size="sm"
                id="senderTypeIdentity"
                name="senderTypeIdentity"
                ariaLabel={
                  sendData?.sender?.identityType
                    ? `${sendData?.sender.identityType}`
                    : "(For International Traveling Passport is Mandatory for the Traveler)"
                }
                placeholder={
                  sendData?.sender?.identityType
                    ? `${sendData?.sender.identityType}`
                    : "(For International Traveling Passport is Mandatory for the Traveler)"
                }
                className="custom-input-contract"
                value={contractData.senderTypeIdentity}
                onChange={handleChange}
                disabled={!editClicked}
              />{" "}
              - Identity Document Number
              {/* <span className="star-require">*</span> :{" "} */}
              <Input
                size="sm"
                id="senderIdentityDocumentNo"
                name="senderIdentityDocumentNo"
                ariaLabel={
                  sendData?.sender?.identityNumber
                    ? `${sendData?.sender.identityNumber}`
                    : "-----------"
                }
                placeholder={
                  sendData?.sender?.identityNumber
                    ? `${sendData?.sender.identityNumber}`
                    : "-----------"
                }
                className="custom-input-contract"
                value={contractData.senderIdentityDocumentNo}
                onChange={handleChange}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              Recipient&apos;s Full Name
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderRecipientFullName"
                name="senderRecipientFullName"
                ariaLabel={
                  sendData?.sender?.recipientFullName
                    ? `${sendData?.sender.recipientFullName}`
                    : "-----------"
                }
                placeholder={
                  sendData?.sender?.recipientFullName
                    ? `${sendData?.sender.recipientFullName}`
                    : "-----------"
                }
                className="custom-input-contract"
                value={contractData.senderRecipientFullName}
                onChange={handleChange}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              - Recipient&apos;s Address
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderRecipientAddress"
                name="senderRecipientAddress"
                ariaLabel={
                  sendData?.sender?.recipientAddress
                    ? `${sendData?.sender.recipientAddress}`
                    : "-----------------------"
                }
                placeholder={
                  sendData?.sender?.recipientAddress
                    ? `${sendData?.sender.recipientAddress}`
                    : "-----------------------"
                }
                className="custom-input-address-contract"
                value={contractData.senderRecipientAddress}
                onChange={handleChange}
                disabled={!editClicked}
              />{" "}
              (If the traveler is supposed to either Drop off or Post the
              package to the Recipient)
            </p>
            <p className="contract-content">
              - Recipient&apos;s phone
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="senderRecipientPhone"
                name="senderRecipientPhone"
                ariaLabel={
                  sendData?.sender?.recipientPhone
                    ? `${sendData?.sender.recipientPhone}`
                    : "-----------"
                }
                placeholder={
                  sendData?.sender?.recipientPhone
                    ? `${sendData?.sender.recipientPhone}`
                    : "-----------"
                }
                className="custom-input-contract"
                value={contractData.senderRecipientPhone}
                onKeyPress={preventTypeTextAndDot}
                onChange={handleChange}
                disabled={!editClicked}
              />{" "}
              - Recipient&apos;s Whatsapp :{" "}
              <Input
                size="sm"
                id="senderRecipientWhatsapp"
                name="senderRecipientWhatsapp"
                ariaLabel={
                  sendData?.sender?.recipientWhatsapp
                    ? `${sendData?.sender.recipientWhatsapp}`
                    : "-----------"
                }
                placeholder={
                  sendData?.sender?.recipientWhatsapp
                    ? `${sendData?.sender.recipientWhatsapp}`
                    : "-----------"
                }
                className="custom-input-contract"
                value={contractData.senderRecipientWhatsapp}
                onChange={handleChange}
                onKeyPress={preventTypeTextAndDot}
                disabled={!editClicked}
              />
            </p>
            <p className="contract-content">
              (To be filled in if the recipient i.e. the one who receives the
              package, is different from the sender)
            </p>
            <p className="contract-content">
              - Deadline for delivery/Post
              <span className="star-require">*</span> :{" "}
              <DatePicker
                className="custom-input-date-contract"
                id="senderDeadline"
                name="senderDeadline"
                ariaLabel={
                  sendData?.sender?.shippingDeadline
                    ? `${sendData?.sender.shippingDeadline}`
                    : "-----------"
                }
                placeholderText={
                  sendData?.sender?.shippingDeadline
                    ? `${sendData?.sender.shippingDeadline}`
                    : "-----------"
                }
                selected={senderDeadline}
                onChange={(date) => onSenderDeadlineChange(date)}
                dateFormat="EEEE, MM/dd/yyyy"
                showTimeInput
                minDate={new Date()}
                disabled={!editClicked}
              />
            </p>
            <AddContractItems
              contractItems={contractItems}
              setContractItems={setContractItems}
              disabled={!editClicked}
            />
            <AddTravelingCompanion
              travelingCompanions={travelingCompanions}
              setTravelingCompanions={setTravelingCompanions}
              disabled={!editClicked}
            />
            <p className="contract-content">
              {/* Total Value:{" "}
              <Input
                size="sm"
                id="totalValue"
                name="totalValue"
                ariaLabel={
                  sendData?.sender?.totalValue
                    ? `${sendData?.sender.totalValue}`
                    : "(Sum of all Values)"
                }
                placeholder={
                  sendData?.sender?.totalValue
                    ? `${sendData?.sender.totalValue}`
                    : "(Sum of all Values)"
                }
                className="custom-input-total-contract"
                value={contractData.totalValue}
                onChange={handleChange}
                onKeyPress={preventTypeTextAndDot}
                disabled={hasTravelingCompanion && !editClicked}
              />
              <Select
                id="currency"
                className="custom-input-weight-unit-contract d-inline-block"
                options={currenciesOption}
                value={currency}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleCurrencyChange}
                isDisabled={hasTravelingCompanion && !editClicked}
                styles={unitCustomStyle}
                placeholder={
                  sendData?.sender?.currencyType
                    ? `${sendData?.sender?.currencyType}`
                    : "CAD"
                }
              /> */}
              Total Weight:{" "}
              <Input
                size="sm"
                id="totalWeight"
                name="totalWeight"
                value={contractData.totalWeight}
                ariaLabel={
                  sendData?.sender?.totalWeight
                    ? `${sendData?.sender.totalWeight}`
                    : "(Sum of all Weights)"
                }
                placeholder={
                  sendData?.sender?.totalWeight
                    ? `${sendData?.sender.totalWeight}`
                    : "(Sum of all Weights)"
                }
                className="custom-input-total-weight-contract"
                onChange={handleChange}
                disabled={hasTravelingCompanion && !editClicked}
                onKeyPress={preventTypeText}
              />
              <Select
                id="totalWeightUnit"
                className="custom-input-weight-unit-contract d-inline-block"
                options={unitWeightOption}
                value={totalWeightUnit}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleTotalWeightUnitChange}
                isDisabled={hasTravelingCompanion && !editClicked}
                styles={unitCustomStyle}
                placeholder={
                  sendData?.sender?.totalWeightUnit
                    ? `${sendData?.sender?.totalWeightUnit}`
                    : "KG"
                }
              />
              {/* <Input
                  size="sm"
                  id="totalWeightUnit"
                  name="totalWeightUnit"
                  value={contractData.totalWeightUnit}
                  ariaLabel={
                    sendData?.sender?.totalWeightUnit
                      ? `${sendData?.sender?.totalWeightUnit}`
                      : "Kg"
                  }
                  placeholder={
                    sendData?.sender?.totalWeightUnit
                      ? `${sendData?.sender?.totalWeightUnit}`
                      : "Kg"
                  }
                  className="custom-input-total-weight-contract"
                  onChange={handleChange}
                  disabled={hasTravelingCompanion && !editClicked}
                /> */}
            </p>
            {agreementTermsOptions?.map((item) => {
              return (
                <div className="d-block w-100">
                  <input
                    onChange={changeAgreementTerms}
                    className="ml-0 mr-1 contract-radio-btn"
                    type="radio"
                    name="agreementTerm"
                    value={item.id}
                    checked={item.isChecked}
                    disabled={!editClicked}
                  />
                  <span className="contract-content-radio">{item.name}</span>
                </div>
              );
            })}
            {isAgreementOther && (
              <p className="contract-content">
                Detail: {}
                <Input
                  size="sm"
                  id="otherAgreementDetail"
                  name="otherAgreementDetail"
                  value={contractData.otherAgreementDetail}
                  ariaLabel={
                    sendData?.sender?.agreementtermOther
                      ? `${sendData?.sender.agreementtermOther}`
                      : "ex: Traveling companion. Mr. John accompanies my father from Toronto to Dubai."
                  }
                  placeholder={
                    sendData?.sender?.agreementtermOther
                      ? `${sendData?.sender.agreementtermOther}`
                      : "ex: Traveling companion. Mr. John accompanies my father from Toronto to Dubai."
                  }
                  className="custom-input-other-detail-contract"
                  onChange={handleChange}
                  disabled={!editClicked}
                />
              </p>
            )}
          </Row>
          {sendData?.sender?.images?.length !== 0 && (
            <>
              <p className="contract-content">{t("images")}: </p>
              <Row className="image-card-wrapper">
                <Gallery>
                  {sendData?.sender?.images?.map((item) => {
                    return (
                      <div className="p-0 mt-3 mr-3">
                        <LightBoxItem
                          original={item}
                          thumbnail={item}
                          width="70px"
                          height="70px"
                        />
                      </div>
                    );
                  })}
                </Gallery>
              </Row>
            </>
          )}
          <Row className="ml-0 mt-5 contract-content-wrapper">
            <span className="contract-title">Traveler</span>
          </Row>
          <Row className="ml-0 mt-4 contract-content-wrapper">
            <p className="contract-content">
              -Mr./Ms. <span className="star-require">*</span>
              <Input
                size="sm"
                id="travelerName"
                name="travelerName"
                ariaLabel={
                  sendData?.traveler?.fullName
                    ? `${sendData?.traveler.fullName}`
                    : "................"
                }
                placeholder={
                  sendData?.traveler?.fullName
                    ? `${sendData?.traveler.fullName}`
                    : "................"
                }
                value={sendData?.traveler?.fullName}
                className="custom-input-contract"
                disabled
              />{" "}
            </p>
            <p className="contract-content">
              - Address country of departure
              <span className="star-require">*</span> :
              <Input
                size="sm"
                id="travelerDepartureAddress"
                name="travelerDepartureAddress"
                ariaLabel={
                  sendData?.traveler?.departureAddress
                    ? `${sendData?.traveler.departureAddress}`
                    : "--------------------------"
                }
                placeholder={
                  sendData?.traveler?.departureAddress
                    ? `${sendData?.traveler.departureAddress}`
                    : "--------------------------"
                }
                value={sendData?.traveler?.departureAddress}
                className="custom-input-contract"
                disabled
              />
              - Address country of arrival
              <span className="star-require">*</span> :
              <Input
                size="sm"
                id="travelerArrivalAddress"
                name="travelerArrivalAddress"
                value={sendData?.traveler?.arrivalAddress}
                ariaLabel={
                  sendData?.traveler?.arrivalAddress
                    ? `${sendData?.traveler.arrivalAddress}`
                    : "--------------------------"
                }
                placeholder={
                  sendData?.traveler?.arrivalAddress
                    ? `${sendData?.traveler.arrivalAddress}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                disabled
              />
            </p>
            <p className="contract-content">
              - Phone<span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="travelerPhone"
                name="travelerPhone"
                value={sendData?.traveler?.phoneNumber}
                ariaLabel={
                  sendData?.traveler?.phoneNumber
                    ? `${sendData?.traveler.phoneNumber}`
                    : "--------------------------"
                }
                placeholder={
                  sendData?.traveler?.phoneNumber
                    ? `${sendData?.traveler.phoneNumber}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                disabled
              />{" "}
              - Whatsapp:{" "}
              <Input
                size="sm"
                id="travelerWhatsapp"
                name="travelerWhatsapp"
                value={sendData?.traveler?.whatsapp}
                ariaLabel={
                  sendData?.traveler?.whatsapp
                    ? `${sendData?.traveler.whatsapp}`
                    : "----------------"
                }
                placeholder={
                  sendData?.traveler?.whatsapp
                    ? `${sendData?.traveler.whatsapp}`
                    : "----------------"
                }
                className="custom-input-contract"
                disabled
              />{" "}
              - email<span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="travelerEmail"
                name="travelerEmail"
                value={sendData?.traveler?.email}
                ariaLabel={
                  sendData?.traveler?.email
                    ? `${sendData?.traveler.email}`
                    : "--------------------------"
                }
                placeholder={
                  sendData?.traveler?.email
                    ? `${sendData?.traveler.email}`
                    : "--------------------------"
                }
                className="custom-input-contract"
                disabled
              />
            </p>
            <p className="contract-content">
              - Identity Document Type
              <span className="star-require">*</span> :{" "}
              <Input
                size="sm"
                id="travelerTypeIdentity"
                name="travelerTypeIdentity"
                value={sendData?.traveler?.identityType}
                ariaLabel={
                  sendData?.traveler?.identityType
                    ? `${sendData?.traveler.identityType}`
                    : "(For International Traveling Passport is Mandatory for the Traveler)"
                }
                placeholder={
                  sendData?.traveler?.identityType
                    ? `${sendData?.traveler.identityType}`
                    : "(For International Traveling Passport is Mandatory for the Traveler)"
                }
                className="custom-mandatory-contract"
                disabled
              />{" "}
              - Identity Document Number
              {/* <span className="star-require">*</span> :{" "} */}
              <Input
                size="sm"
                id="travelerIdentityDocumentNo"
                name="travelerIdentityDocumentNo"
                value={sendData?.traveler?.identityNumber}
                ariaLabel={
                  sendData?.traveler?.identityNumber
                    ? `${sendData?.traveler.identityNumber}`
                    : "-----------"
                }
                placeholder={
                  sendData?.traveler?.identityNumber
                    ? `${sendData?.traveler.identityNumber}`
                    : "-----------"
                }
                className="custom-input-contract"
                disabled
              />
            </p>
            <p className="contract-content">
              - Country/city of departure
              <span className="star-require">*</span>{" "}
              <Input
                size="sm"
                id="travelerCityCountryDeparture"
                name="travelerCityCountryDeparture"
                value={sendData?.traveler?.fromCountryCity}
                ariaLabel={
                  sendData?.traveler?.fromCountryCity
                    ? `${sendData?.traveler.fromCountryCity}`
                    : "............................."
                }
                placeholder={
                  sendData?.traveler?.fromCountryCity
                    ? `${sendData?.traveler.fromCountryCity}`
                    : "............................."
                }
                className="custom-country-contract"
                disabled
              />{" "}
              - Country/city of arrival
              <span className="star-require">*</span>
              <Input
                size="sm"
                id="travelerCityCountryArrival"
                name="travelerCityCountryArrival"
                value={sendData?.traveler?.toCountryCity}
                ariaLabel={
                  sendData?.traveler?.toCountryCity
                    ? `${sendData?.traveler.toCountryCity}`
                    : "............................."
                }
                placeholder={
                  sendData?.traveler?.toCountryCity
                    ? `${sendData?.traveler.toCountryCity}`
                    : "............................."
                }
                className="custom-country-contract"
                disabled
              />
            </p>
            <p className="contract-content">
              - Departure date<span className="star-require">*</span>{" "}
              <DatePicker
                className="custom-input-date-contract"
                id="travelerDepartureDate"
                name="travelerDepartureDate"
                ariaLabel={
                  sendData?.traveler?.departureDate
                    ? `${sendData?.traveler.departureDate}`
                    : "............................."
                }
                placeholderText={
                  sendData?.traveler?.departureDate
                    ? `${sendData?.traveler.departureDate}`
                    : "............................."
                }
                selected={travelerDepartureDate}
                onChange={(date) => onDepartureDateChange(date)}
                dateFormat="EEEE, MM/dd/yyyy"
                showTimeInput
                minDate={new Date()}
                disabled
              />
              - Arrival date<span className="star-require">*</span>
              <DatePicker
                className="custom-input-date-contract"
                id="travelerArrivalDate"
                name="travelerArrivalDate"
                ariaLabel={
                  sendData?.traveler?.arrivalDate
                    ? `${sendData?.traveler.arrivalDate}`
                    : "............................."
                }
                placeholderText={
                  sendData?.traveler?.arrivalDate
                    ? `${sendData?.traveler.arrivalDate}`
                    : "............................."
                }
                selected={travelerArrivalDate}
                onChange={(date) => onArrivalDateChange(date)}
                dateFormat="EEEE, MM/dd/yyyy"
                showTimeInput
                minDate={new Date()}
                disabled
              />
            </p>
            <p className="contract-content">
              -Number of Kg offered by the traveler{" "}
              <Input
                size="sm"
                id="travelerOfferWeight"
                name="travelerOfferWeight"
                className="custom-input-contract"
                value={sendData?.traveler?.weightOffered}
                ariaLabel={
                  sendData?.traveler?.weightOffered
                    ? `${sendData?.traveler.weightOffered}`
                    : "......."
                }
                placeholder={
                  sendData?.traveler?.weightOffered
                    ? `${sendData?.traveler.weightOffered}`
                    : "......."
                }
                disabled
              />
              <Select
                id="weightUnit"
                className="custom-input-weight-unit-contract d-inline-block"
                options={unitWeightOption}
                value={weightUnit}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleWeightUnitChange}
                isDisabled
                styles={unitCustomStyle}
                placeholder={
                  sendData?.traveler?.weightUnit
                    ? `${sendData?.traveler?.weightUnit}`
                    : "KG"
                }
              />
              -Number of Kg booked by the shipper{" "}
              <Input
                size="sm"
                id="travelerWeightShipper"
                name="travelerWeightShipper"
                value={sendData?.traveler?.weightBooked}
                ariaLabel={
                  sendData?.traveler?.weightBooked
                    ? `${sendData?.traveler.weightBooked}`
                    : "......."
                }
                placeholder={
                  sendData?.traveler?.weightBooked
                    ? `${sendData?.traveler.weightBooked}`
                    : "......."
                }
                className="custom-input-contract"
                disabled
              />
              <Select
                id="weightUnit"
                className="custom-input-weight-unit-contract d-inline-block"
                options={unitWeightOption}
                value={weightUnit}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                onChange={handleWeightUnitChange}
                isDisabled
                styles={unitCustomStyle}
                placeholder={
                  sendData?.traveler?.weightUnit
                    ? `${sendData?.traveler?.weightUnit}`
                    : "KG"
                }
              />
            </p>
            <p className="contract-content">
              - The price of the service has been fixed at an amount of
              <span className="star-require">*</span>{" "}
              <Input
                size="sm"
                id="offerPrice"
                name="offerPrice"
                value={sendData?.traveler?.offerPrice}
                ariaLabel={
                  sendData?.traveler?.offerPrice
                    ? `${sendData?.traveler.offerPrice}`
                    : "..................."
                }
                placeholder={
                  sendData?.traveler?.offerPrice
                    ? `${sendData?.traveler.offerPrice}`
                    : "..................."
                }
                className="custom-input-contract"
                disabled
              />
              <Select
                id="currency"
                className="custom-input-weight-unit-contract d-inline-block"
                options={currenciesOption}
                // value={currency}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                isDisabled
                styles={unitCustomStyle}
                placeholder={
                  sendData?.sender?.currencyType
                    ? `${sendData?.sender?.currencyType}`
                    : "CAD"
                }
              />
              that will be paid by Koolbaar after delivery"
            </p>
            <p className="contract-content">* Including:</p>
            {expensesOptions?.map((item) => {
              return (
                <>
                  <input
                    className="ml-0 mr-1 contract-radio-btn"
                    type="checkbox"
                    checked={item.isChecked}
                    name="site_name"
                    disabled
                  />
                  <span className="contract-content-radio">{item.name}</span>
                </>
              );
            })}
            <p className="contract-content">
              Cancellation policy (Will be Filled by the Traveler)
            </p>
            {/* cancellationPolicy */}
            {cancellationPolicyOptions?.map((item) => {
              return (
                <>
                  <input
                    className="ml-0 mr-1 contract-radio-btn"
                    type="radio"
                    name="cancellationPolicy"
                    checked={item.isChecked}
                    disabled
                  />
                  <span className="contract-content-radio">{item.name}</span>
                </>
              );
            })}
            <p className="contract-content">
              -Detail:{" "}
              <Input
                size="sm"
                id="detail"
                name="detail"
                value={sendData?.traveler?.detail}
                ariaLabel={
                  sendData?.traveler?.detail
                    ? `${sendData?.traveler.detail}`
                    : `${
                        isMobileTablet
                          ? "------------------------"
                          : "----------------------------------------------------------------"
                      }`
                }
                placeholder={
                  sendData?.traveler?.detail
                    ? `${sendData?.traveler.detail}`
                    : `${
                        isMobileTablet
                          ? "------------------------"
                          : "----------------------------------------------------------------"
                      }`
                }
                className="custom-input-detail-contract"
                disabled
              />
            </p>
          </Row>
          {/* {sendData?.images?.length !== 0 && (
            <>
              <p className="contract-content">Images: </p>
              <Row className="image-card-wrapper">
                <Gallery>
                  {sendData?.images?.map((item) => {
                    return (
                      <div className="p-0 mt-3 mr-3">
                        <LightBoxItem
                          original={item}
                          thumbnail={item}
                          width="70px"
                          height="70px"
                        />
                      </div>
                    );
                  })}
                </Gallery>
              </Row>
            </>
          )} */}

          <Row>
            <Col xs={12} sm={12} md={12} lg={4} className="signature-wrapper">
              <span>Traveler Signature</span>
              <div></div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} className="signature-wrapper">
              <span>Witness Signature (Optional)</span>
              <div></div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} className="signature-wrapper">
              <span>Sender Signature</span>
              <div></div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};
