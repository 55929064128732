import FlightsService from "services/flightsService";
import {
  flightInquiry,
  flightInquiryFailure,
} from "redux/slices/flight/flightInquiry";
import { homeRequestFilter } from "redux/slices/flight/homeRequestFilter";
import { homeTravelFilter } from "redux/slices/flight/homeTravelFilter";
import {
  filterHomeRequestSearch,
  filterHomeRequestSearchFailure,
} from "redux/slices/flight/filterHomeRequestSearch";import {
  filterHomeTravelSearch,
  filterHomeTravelSearchFailure,
} from "redux/slices/flight/filterHomeTravelSearch";
import { allAirlines, allAirlinesFailure } from "redux/slices/flight/airlines";
import { searchFlight } from "redux/slices/flight/searchFlight";
import { toast } from "react-toastify";

export const getFlightInquiry = (data) => async (dispatch) => {
  try {
    const res = await FlightsService.flightInquiry(data);
    dispatch(flightInquiry(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(flightInquiryFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getAllHomeRequestFilter = (data) => async (dispatch) => {
  try {
    const res = await FlightsService.getHomeFilter(data);
    dispatch(homeRequestFilter(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    toast.error(err?.response?.data?.message);
  }
};

export const getAllHomeTravelFilter = (data) => async (dispatch) => {
  try {
    const res = await FlightsService.getHomeFilter(data);
    dispatch(homeTravelFilter(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    // toast.error(err?.response?.data?.message);
  }
};

export const searchAllFlight = (data) => async (dispatch) => {
  try {
    const res = await FlightsService.searchFlight(data);
    dispatch(searchFlight(res.data));
  } catch (err) {
    dispatch(searchFlight(err?.response));
    // toast.error(err?.response?.data?.message);
  }
};
export const getAllAirlines = (data) => async (dispatch) => {
  try {
    const res = await FlightsService.getAirlines(data);
    dispatch(allAirlines(res.data));
  } catch (err) {
    dispatch(allAirlinesFailure(err?.response));
    // toast.error(err?.response?.data?.message);
  }
};

export const getAllInfoHomeRequests = (data) => async (dispatch) => {
  try {
    const res = await FlightsService.getHomeRequestByTravelInfo(data);
    dispatch(filterHomeRequestSearch(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(filterHomeRequestSearchFailure(err?.response));
    // toast.error(err?.response?.data?.message);
  }
};

export const getAllInfoHomeTravels = (data) => async (dispatch) => {
  try {
    const res = await FlightsService.getHomeRequestByTravelInfo(data);
    dispatch(filterHomeTravelSearch(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(filterHomeTravelSearchFailure(err?.response));
    // toast.error(err?.response?.data?.message);
  }
};
