/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { IOfferSent } from "models/interfaces";
import { chunk } from "lodash";

interface IProp {
  setShowTimelineDetail: (key: any) => void;
  fade: boolean;
  data: IOfferSent;
}
export const PackageCover: React.FC<IProp> = ({
  setShowTimelineDetail,
  fade,
  data,
}) => {
  const [showCover, setShowCover] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const timelines = data.timelines;

  const handleShow = () => {
    setShowCover(!showCover);
    setShowTimelineDetail(showCover);
    setFadeOut(true);
  };

  return (
    <div className="dashboard-more-detail-wrapper more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`dashboard-receive-offer-card-wrapper mb-3 offers-card-info-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Body className="request-card-border location-cover-info offer-card-cover-body">
          <Row className="detail-cover-wrapper">
            <Col xs={12} className="deliver-status timeline-scroll text-left">
              {/* {timelines?.length !== 0 &&
                timelines?.map((item) => {
                  return (
                    <div>
                      <span className="offer-deliver-bullet"></span>
                      <p className="offer-deliver-status">{item.date}</p>
                      <p className="offer-deliver-status-desc">{item.name}</p>
                    </div>
                  );
                })} */}
              <Row>
                {timelines?.length !== 0 &&
                  chunk(timelines, 4).map((chunkedItems, index) => (
                    <Col xs={6} sm={6} md={4} lg={4} key={index} className="border-left-timeline">
                      {chunkedItems.map((item) => (
                        <div key={item.date}>
                          <span
                            style={{ backgroundColor: item.hexColor }}
                            className="offer-deliver-bullet"
                          ></span>
                          <p className="offer-deliver-status">{item.date}</p>
                          <p className="offer-deliver-status-desc">
                            {item.name}
                          </p>
                        </div>
                      ))}
                    </Col>
                  ))}
              </Row>
            </Col>
            {/* <Col xs={2} className="note-offer-detail mt-0">
              <span className="d-block">Note:</span>
              <span>{data.message}</span>
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
