import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "layers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
}

export const FirsStepAddCard: React.FC<IProps> = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const [hasVibration, setHasVibration] = useState(false);
  const navigate = useNavigate();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const handleClose = () => {
    window.localStorage.setItem("visitedBefore", "true");
    setIsOpen(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setHasVibration(true);
    }, 350);
    setTimeout(() => {
      setHasVibration(false);
    }, 800);
  }, []);

  return (
    <>
      <Modal
        className={`add-card-modal-wrapper ${hasVibration && "vibrate"} `}
        show={isOpen}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="close-modal-btn-wrapper">
            <a onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </a>
          </div>
          <Row>
            <Col xs={12}>
              {isPersian ? (
                <span className="remove-desc-title">
                  به کولبار خوش آمدید، برای شروع، آگهی سفر یا ارسال بار خود را
                  اضافه کنید
                </span>
              ) : (
                <span className="remove-desc-title">
                  Welcome to Koolbaar! To start, create your Travel or Parcel
                  Ad!
                </span>
              )}
            </Col>
          </Row>
          <Button
            variant="warning"
            data-test="docs-btn-anchor"
            onClick={handleClose}
            className="confirm-remove-btn"
          >
            {t("ok")}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
