import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "layers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
  setShowLoginSidebar: (key: any) => void;
  type: string;
  title: string;
}

export const LoginConfirm: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  setShowLoginSidebar,
  title,
  type,
}) => {
  const { t } = useTranslation();
  const [hasVibration, setHasVibration] = useState(false);
  const navigate = useNavigate();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const handleClose = () => {
    setIsOpen(false);
    navigate("/");
  };

  useEffect(() => {
    setTimeout(() => {
      setHasVibration(true);
    }, 350);
    setTimeout(() => {
      setHasVibration(false);
    }, 800);
  }, []);

  const Login = () => {
    setIsOpen(!isOpen);
    setShowLoginSidebar(true);
  };

  return (
    <>
      <Modal
        className={`remove-modal-wrapper ${hasVibration && "vibrate"} `}
        show={isOpen}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="close-modal-btn-wrapper">
            <a onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </a>
          </div>
          <span className="enter-travel-information">{t("login")}</span>
          <Row>
            <Col xs={12}>
              {isPersian ? (
                <span className="remove-desc-title">
                  برای "{title}"، شما ابتدا باید وارد شوید یا ثبت نام کنید.
                </span>
              ) : (
                <span className="remove-desc-title">
                  To "{title}", you must first register or login
                </span>
              )}
            </Col>
          </Row>
          <Button
            variant="warning"
            data-test="docs-btn-anchor"
            onClick={Login}
            className="confirm-remove-btn"
          >
            {t("login")}
          </Button>
        </Modal.Body>
      </Modal>

      {/* {showOfferSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showOfferSidebar}
            setIsOpen={setShowOfferSidebar}
            sidebarType={type}
          />
        </div>
      )} */}
    </>
  );
};
