import React, { useEffect } from "react";
import { Button } from "layers";
import { PaymentSetting } from "./paymentSetting";
import { Transactions } from "./transactions";
import { IUserInfo } from "models/interfaces";
import { SkeletonFinancial } from "components/Skeleton/skeletonFinancial";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getVerifyStripe } from "redux/actions/stripe";
import { useTranslation } from "react-i18next";

interface IProps {
  userData: IUserInfo;
}

export const FinancialInfo: React.FC<IProps> = ({ userData }) => {
  const size = UseWindowSize();
  const isMobile = size.width < 768;
  const dispatch = useAppDispatch();
  const withdrawData: any = useAppSelector((state) => state.verifyStripe);
  const { t } = useTranslation();

  const withdraw = () => {
    // if (!userData?.isStripeVerified)
    dispatch(getVerifyStripe());
  };

  useEffect(() => {
    if (withdrawData?.isSuccess) {
      // window.location.href = withdrawData?.data?.redirectUrl;
      const url = withdrawData?.data?.redirectUrl;
      if (url) {
        window.open(url, "_blank");
      }
    }
  }, [withdrawData]);

  return (
    <div>
      {!isMobile && <h1 className="account-title">{t("securePayouts")}</h1>}
      {userData ? (
        <>
          <div className="balance-wrapper">
            {/* <h1>
              Balance : <span>{userData?.balance}</span>
            </h1> */}
            <Button
              variant="primary"
              data-test="docs-btn-anchor"
              onClick={withdraw}
              className="withdraw-btn"
            >
              {t("addBankAccount")}
            </Button>
          </div>
          {/* <PaymentSetting gateways={userData?.gateways} /> */}
          {/* <Transactions /> */}
        </>
      ) : (
        <SkeletonFinancial />
      )}
    </div>
  );
};
