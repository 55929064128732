import { createSlice } from "@reduxjs/toolkit";

export const allNotificationCountSlice = createSlice({
  name: "allNotificationCount",
  initialState: [],
  reducers: {
    allNotificationCount: (state, action) => {
      return (state = action.payload);
    },
    allNotificationCountFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allNotificationCount, allNotificationCountFailure } =
  allNotificationCountSlice.actions;
export default allNotificationCountSlice.reducer;
