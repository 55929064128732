import { BaseService } from "./baseService";

class DashboardService extends BaseService{
  getDashboard() {
    return this.authorizedHttp().get("/user/Dashboard");
  }

  getAllChangedStatus() {
    return this.authorizedHttp().get("/user/Changestatus/AllByCategory");
  }

  getAllRequestStatus() {
    return this.authorizedHttp().get("/user/Request/All");
  }

  getAllOfferStatus() {
    return this.authorizedHttp().get("/user/Offer/All");
  }

  changeOfferStatus(data) {
    return this.authorizedHttp().post("/user/Offer/ChangeStatus", data);
  }

  changeStatus(data) {
    return this.authorizedHttp().post("/user/Changestatus", data);
  }
  
  changeStatusToPayment(data) {
    return this.authorizedHttp().post("/user/Changestatus", data);
  }

  changeRequestStatus(data) {
    return this.authorizedHttp().post("/user/Request/ChangeStatus", data);
  }

  sendUserAgreement(data) {
    return this.authorizedHttp().post("/user/Agreement", data);
  }

  reportOfferProblem(data) {
    return this.authorizedHttp().post("/user/Offer/ReportProblem", data);
  }

  reportRequestProblem(data) {
    return this.authorizedHttp().post("/user/Request/ReportProblem", data);
  }

  addNewTravel(data) {
    return this.authorizedFormDataHttp().post("/user/Travel/New", data);
  }

  getTravelById(id) {
    return this.authorizedHttp().get(`/user/Travel/GetByTrvId/${id}`);
  }

  editNewTravel(data) {
    return this.authorizedFormDataHttp().put("/user/Travel/Edit", data);
  }

  removeTravel(id) {
    return this.authorizedHttp().delete(`/user/Travel/DelByTrvId/${id}`);
  }

  addNewPackage(data) {
    return this.authorizedFormDataHttp().post("/user/Package/New", data);
  }

  getPackageById(id) {
    return this.authorizedHttp().get(`/user/Package/GetByPkgId/${id}`);
  }

  editNewPackage(data) {
    return this.authorizedFormDataHttp().put("/user/Package/Edit", data);
  }

  removePackage(id) {
    return this.authorizedHttp().delete(`/user/Package/DelByPkgId/${id}`);
  }

  getRequestTimeline(id) {
    return this.authorizedHttp().get(`/user/Request/GetTimelineByReqId/${id}`);
  }

  getOfferTimeline(id) {
    return this.authorizedHttp().get(`/user/Offer/GetTimelineByOffId/${id}`);
  }

  filterRequestStatus(data) {
    // return this.authorizedHttp().get(`/user/Request/GetByStatusId/${id}`);
    return this.authorizedHttp().post(`/user/Changestatus/FilterByName`, data);
  }

  filterOfferStatus(data) {
    // return this.authorizedHttp().get(`/user/Offer/GetByStatusId/${id}`);
    return this.authorizedHttp().post(`/user/Changestatus/FilterByName`, data);
  }

  getOfferStatus() {
    return this.authorizedHttp().get(`/user/Changestatus/GetByType/offer`);
  }

  getRequestStatus() {
    return this.authorizedHttp().get(`/user/Changestatus/GetByType/request`);
  }

  searchByPackageId(data) {
    return this.authorizedHttp().post(`/user/Package/SearchByPkgId`, data);
  }

  searchByTravelId(data) {
    return this.authorizedHttp().post(`/user/Travel/SearchByTrvId`, data);
  }

  getOffersByPkgId(id) {
    return this.authorizedHttp().get(`/user/Offer/GetByPkgId/${id}`);
  }

  getRequestsByTrvId(id) {
    return this.authorizedHttp().get(`/user/Request/GetByTrvId/${id}`);
  }

  changeGeneralStatus(data) {
    return this.authorizedHttp().post(`/user/Changestatus`, data);
  }

  cancellation(data) {
    return this.authorizedHttp().post(`/user/Cancellation`, data);
  }

  disapprovalReason() {
    return this.authorizedHttp().get(`/User/Deliverydisapprovalreason`);
  }

  sendDisapprovalReason(data) {
    return this.authorizedHttp().post(`/user/DeliveryDenied`, data);
  }

  senderReasonCancellation() {
    return this.authorizedHttp().get(`/user/Cancellationreason/GetByType/Sender`);
  }

  travelerReasonCancellation() {
    return this.authorizedHttp().get(`/user/Cancellationreason/GetByType/Traveler`);
  }

  proofDocuments(data) {
    return this.authorizedFormDataHttp().post(`/user/Proof`, data);
  }

  getProof(id) {
    return this.authorizedHttp().get(`/user/Proof/GetById/${id}`);
  }

  sendReview(data) {
    return this.authorizedHttp().post(`/user/Review`, data);
  }
  
  getAllDashboardProfileById(id) {
    return this.authorizedHttp().get(`/Profile/GetByOffReqId/${id}`);
  }
  clickOnOffers() {
    return this.authorizedHttp().get(`/user/Dashboard/TabClicked/offers`);
  }
  
  clickOnRequests() {
    return this.authorizedHttp().get(`/user/Dashboard/TabClicked/requests`);
  }
}

export default new DashboardService();
