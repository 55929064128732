import { createSlice } from "@reduxjs/toolkit";

export const changeStatusFareAlertSlice = createSlice({
  name: "changeStatusFareAlert",
  initialState: [],
  reducers: {
    changeStatusFareAlert: (state, action) => {
      return (state = action.payload.data);
    },
    changeStatusFareAlertFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { changeStatusFareAlert, changeStatusFareAlertFailure } =
  changeStatusFareAlertSlice.actions;
export default changeStatusFareAlertSlice.reducer;

