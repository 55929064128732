import React, { useState, useEffect } from "react";
import { Row, Col, Card, OverlayTrigger } from "react-bootstrap";
import { Button } from "layers";
import PlaneIcon from "../../assets/images/plane.png";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { IMyPackages } from "models/interfaces";
import { RightSidebar } from "layers";
import { getPackage, deleteUserPackage } from "redux/actions/dashboard";
import { useAppDispatch } from "redux/store";
import { Confirmation } from "components/modals/Confirmation";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";

interface IProps {
  data: IMyPackages;
  setPackageId: Function;
}

export const Cards: React.FC<IProps> = ({ data, setPackageId }) => {
  const windowSize = UseWindowSize();
  const [showSidebar, setShowSidebar] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [pkgId, setPkgId] = useState("");
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const openEditTravel = (id) => {
    setShowSidebar(!showSidebar);
    dispatch(getPackage(id));
    setPkgId(id);
    // navigate("/dashboard/editPackage");
    navigate("/dashboard/editPackage", { state: t("myPackages") });
  };

  const removeTravelOpenModal = (id) => {
    setIsOpenModal(!isOpenModal);
    setPkgId(id);
  };

  useEffect(() => {
    if (isConfirmed) dispatch(deleteUserPackage(pkgId));
  }, [isConfirmed]);

  const viewOffers = () => {
    if (data.offers > 0) {
      setPackageId(data.pkgId);
      navigate("/dashboard/latestSentOffer", { state: t("latestSentOffer") });
      // const element = document.getElementById("send-offer-wrapper");
      // if (element) {
      //   element.scrollIntoView({ behavior: "smooth" });
      // }
    }
  };

  const fromTooltip = (
    <Tooltip id="fromCity">
      <span>{t("from")}: {data?.fromCountryCity}</span>
    </Tooltip>
  );
  const toTooltip = (
    <Tooltip id="toCity">
      <span>{t("to")}: {data?.toCountryCity}</span>
    </Tooltip>
  );

  return (
    <Col
      key={data?.owner}
      lg={3}
      md={6}
      sm={12}
      className={`${windowSize.width < 768 && "p-0"} mb-5`}
      style={windowSize.width < 768 ? { width: "100%" } : null}
      data-testid="container"
    >
      <Card
        className={`my-package-card-wrapper ${
          !data.isAvailable && "half-opacity"
        }`}
      >
        <Card.Header className="card-dashboard-package-header">
          <Row>
            <Col xs={12} className="text-center header-card-plane px-1">
              <div>
                <span>{data?.packagetypes}</span>
              </div>
              <div>
                <span className="mb-0">
                  <img
                    src={PlaneIcon}
                    className="card-request-icon"
                    alt="location-img"
                  />{" "}
                  <span className="airline-box-title">{data?.airlineName}</span>
                </span>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "-15px" }}>
            <Col xs={6} className="text-left header-card-titles">
              <OverlayTrigger placement="top" overlay={fromTooltip}>
                <div>
                  <span className="text-left cursor-pointer">
                    {data?.fromCity}
                  </span>
                </div>
              </OverlayTrigger>
              <div>
                <span className="text-left">{data?.fromDate1}</span>
              </div>
            </Col>
            <Col xs={6} className="header-card-titles">
              <OverlayTrigger placement="top" overlay={toTooltip}>
                <div className="text-right cursor-pointer">
                  <span>{data?.toCity}</span>
                </div>
              </OverlayTrigger>
              <div className="text-right">
                <span>{data?.fromDate2}</span>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="card-request-body pt-1">
          <Row>
            <Col xs={7} className="request-body-info text-align-title">
              <div>
                <span className="card-text">
                  {t("size")}: {data?.size}
                </span>
              </div>
              <div>
                <span className="card-text">
                  {t("weight")}: {data?.weight} {data?.weightUnit}
                </span>
              </div>
              {/* <div>
                <span className="card-text">
                  {t("itemValue")}: {data?.itemValue} {data?.currencyType}
                </span>
              </div> */}
            </Col>
            <Col xs={5} className="request-body-package pl-0">
              <div>
                <span>{t("packageID")}</span>
              </div>
              <div>
                <span>{data?.pkgId}</span>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col xs={7} className="request-body-date">
              <div>
                <span className="card-text">{t("shippingDeadline")}:</span>
              </div>
              <div>
                <span className="card-text">{data?.shippingDeadline}</span>
              </div>
            </Col>
            <Col xs={5} style={{ textAlign: "right" }}>
              <Button
                variant="primary"
                data-test="docs-btn-anchor"
                className="dashboard-offer-btn cursor-pointer"
                onClick={viewOffers}
              >
                {t("offer")}{" "}
                <span className="d-block">
                  <strong>{data?.offers}</strong>
                </span>
              </Button>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="card-request-footer">
          {data.isAvailable && data?.offers < 1 && (
            <Button
              variant="gray7"
              onClick={() => removeTravelOpenModal(data.pkgId)}
              className="remove-travel-btn"
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
          {data.isAvailable && data?.offers < 1 && (
            <Button
              variant="gray7"
              onClick={() => openEditTravel(data.pkgId)}
              className="edit-travel-btn"
            >
              {t("edit")}
            </Button>
          )}

          <Button
            variant="warning"
            data-test="docs-btn-anchor"
            className="view-request-btn"
            onClick={viewOffers}
          >
            {t("viewOffers")}
          </Button>
        </Card.Footer>
        {showSidebar && (
          <div className="offer-sidebar">
            <RightSidebar
              isOpen={showSidebar}
              setIsOpen={setShowSidebar}
              sidebarType="dashboardPackage"
              mode="edit"
              pkgId={pkgId}
            />
          </div>
        )}
        {isOpenModal && (
          <Confirmation
            pkgId={pkgId}
            title={t("removeMyPackage")}
            description={t("confirmRemoveCard")}
            isOpen={isOpenModal}
            setIsOpen={setIsOpenModal}
            setIsConfirmed={setIsConfirmed}
            type="package"
          />
        )}
      </Card>
    </Col>
  );
};
