// ChatComponent.tsx

import React, { useState, useEffect, KeyboardEvent, useRef } from "react";
import { Row, Col, Image } from "react-bootstrap";
import * as signalR from "@microsoft/signalr";
import { Button, Input } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperPlane,
  faPaperclip,
  faClose,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useAppSelector } from "redux/store";
import { IChatData, Message, User } from "models/interfaces";
import NoMessage from "assets/images/no-message-2.png";
import PlaneIcon from "../../assets/images/plane.png";
import { useTranslation } from "react-i18next";
import PdfIcon from "../../assets/images/pdf.png";
import DocsIcon from "../../assets/images/docs.png";
import { toast } from "react-toastify";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
  id: string;
  packageData: IChatData;
  avatar?: string;
  roomId: string;
  latestMessages: Message[];
}
export const SingleChatList: React.FC<IProps> = ({
  isOpen,
  setIsOpen,
  id,
  avatar,
  roomId,
  packageData,
  latestMessages,
}) => {
  const [newMessage, setNewMessage] = useState<string>("");
  const handleClose = () => setIsOpen(false);
  const [connection, setConnection] = useState<any>();
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMsg, setNewMsg] = useState<Message>();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [base64File, setFile] = useState<any>(null);
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const [zIndexes, setZIndexes] = useState([1]);
  const [zIndex, setZIndex] = useState(1);
  const accessToken = window.localStorage.getItem("token");
  const refreshToken = window.localStorage.getItem("refreshToken");
  const typeId = id;
  const userData = useAppSelector(
    (state) => state?.userInfo?.data && state?.userInfo?.data[0]?.data
  );
  const chatWrapperRef = useRef<HTMLDivElement>(null);
  const chatWrapper = document.getElementById("chat-wrapper");
  const { t } = useTranslation();

  const onMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
    setHasFile(false);
  };

  const handleKeywordKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      hasFile ? handleSendFile() : handleSendMessage();
    }
  };

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {};
  }

  useEffect(() => {
    async function joinRoom() {
      try {
        const connection = new signalR.HubConnectionBuilder()
          .withUrl("https://api.koolbaar.com/chathub")
          .build();

        connection.serverTimeoutInMilliseconds = 300000;
        connection.keepAliveIntervalInMilliseconds = 300000;

        connection.on(
          "ReceiveMessage",
          (
            message,
            time,
            isMine,
            isReadByReceiver,
            messageSenderPersonalPhoto,
            requestedUserName
          ) => {
            const newMes: Message = {
              message: message,
              time: time,
              isMine: isMine,
              isReadByReceiver: isReadByReceiver,
              personalPhoto: messageSenderPersonalPhoto,
              requestedUserName: requestedUserName,
            };
            setNewMsg(newMes);
          }
        );

        await connection.start();
        setConnection(connection);
      } catch (error) {}
    }

    joinRoom();
  }, []);

  useEffect(() => {
    if (newMsg) {
      setMessages([...messages, newMsg]);
    }
  }, [newMsg]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (connection)
      connection
        .invoke("StartChat", accessToken, refreshToken, typeId, roomId)
        .then((res) => {
          if (latestMessages) {
            setMessages(latestMessages);
            scrollToBottom();
          }
        })
        .catch((error: Error) => {
          console.error("Error sending data:", error);
        });
  }, [connection]);

  const scrollToBottom = () => {
    if (chatWrapperRef.current) {
      chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file?.size > 10 * 1024 * 1024) {
      toast.warn("File size should be less than 10MB");
    } else {
      setAttachedFile(file);
      getBase64(file);
      setHasFile(true);
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setThumbnail(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else if (file && file.type.startsWith("application/pdf")) {
        setThumbnail(PdfIcon);
      } else if (file && file.type.startsWith("application/vnd")) {
        setThumbnail(DocsIcon);
      } else {
        setThumbnail(null);
      }
    }
  };

  async function handleSendMessage() {
    setAttachedFile(null);
    setThumbnail(null);
    setNewMessage("");
    scrollToBottom();
    try {
      await connection.invoke(
        "SendMessage",
        accessToken,
        refreshToken,
        typeId,
        newMessage,
        roomId
      );
    } catch (error) {}
  }

  const renderMessageContent = (message: string) => {
    const fileExtensions = {
      docs: [".doc", ".docx"],
      pdf: [".pdf"],
      image: [".jpg", ".jpeg", ".png", ".gif"],
    };

    const isLink = (url: string) => {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    };

    const getFileExtension = (url: string) => {
      return url.split(".").pop()?.toLowerCase() || "";
    };

    const handleDownload = (url: string) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/").pop() || "download";
      link.target = "_blank";
      link.click();
    };

    if (isLink(message)) {
      const extension = getFileExtension(message);
      if (fileExtensions.docs.includes(`.${extension}`)) {
        return (
          <div
            onClick={() => handleDownload(message)}
            className="file-icon"
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: 70, height: 70 }}
              src={DocsIcon}
              alt="Docs-icon"
            />{" "}
            <span className="filename-text mx-3">
              {message.substring(message.lastIndexOf("/") + 1)}
            </span>
          </div>
        );
      } else if (fileExtensions.pdf.includes(`.${extension}`)) {
        return (
          <div
            onClick={() => handleDownload(message)}
            className="file-icon"
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: 70, height: 70 }}
              src={PdfIcon}
              alt="Pdf-icon"
            />
            <span className="filename-text">
              {" "}
              {message.substring(message.lastIndexOf("/") + 1)}
            </span>
          </div>
        );
      } else if (fileExtensions.image.includes(`.${extension}`)) {
        return (
          <>
            <img
              src={message}
              alt="content"
              className="image-content"
              onClick={() => handleDownload(message)}
              style={{ cursor: "pointer", width: 140, height: 140 }}
            />{" "}
            <span className="filename-text">
              {message.substring(message.lastIndexOf("/") + 1)}
            </span>
          </>
        );
      }
    }

    return <span>{message}</span>;
  };

  async function handleSendFile() {
    if (typeId) {
      setAttachedFile(null);
      setFile(null);
      setThumbnail(null);
      setNewMessage("");
      scrollToBottom();

      try {
        await connection.invoke(
          "SendFile",
          accessToken,
          refreshToken,
          typeId,
          base64File,
          roomId
        );
      } catch (error) {}
    }
  }

  const CustomChatHeader = (chat) => {
    return (
      <div className="py-3 px-4" style={{ background: "#dfdfdf" }}>
        <Row>
          <Col xs={12} className="text-center header-card-plane">
            <div>
              {/* <span className="fw-bold">{packageData?.packagetypes}</span> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            xs={4}
            className="text-left header-card-titles pr-0"
            // style={{ marginTop: "-5px" }}
          >
            <div>
              <span className="text-left">{packageData?.fromCountryCity}</span>
            </div>
          </Col>
          <Col xs={4} className="text-center header-card-plane p-0">
            <div>
              <span className="mb-0">
                ...{" "}
                <img
                  src={PlaneIcon}
                  className="card-request-icon mt-1"
                  alt="location-img"
                />{" "}
                ...
              </span>
            </div>
          </Col>
          <Col
            xs={4}
            className="header-card-titles pl-0"
            // style={{ marginTop: "-5px" }}
          >
            <div className="text-right">
              <span>{packageData?.toCountryCity}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={5} className="text-left header-card-titles pr-0">
            <div>
              <span className="text-left">{packageData?.fromCityEntity}</span>
            </div>
            <div className="mt-2">
              <span className="text-left">
                {t("sender")}: <strong>{packageData?.senderFullName}</strong>
              </span>
            </div>
          </Col>
          <Col xs={2}></Col>
          <Col xs={5} className="header-card-titles pl-0">
            <div className="text-right">
              <span>{packageData?.toCityEntity}</span>
            </div>
            <div className="text-right mt-2">
              <span>
                {t("traveler")}:{" "}
                <strong>{packageData?.travelerFullName}</strong>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const handleIndexClick = () => {
    const maxZIndex = Math.max(...zIndexes);
    const newZIndex = maxZIndex + 1;
    setZIndex(newZIndex);
    setZIndexes([...zIndexes, newZIndex]);
  };

  return (
    <div
      className="single-chat-container pt-2"
      style={{ zIndex: zIndex }}
      onClick={handleIndexClick}
    >
      <a onClick={handleClose} className="pl-3">
        <FontAwesomeIcon icon={faClose} />
      </a>
      <div className="chat-socket-container">
        <CustomChatHeader />
        <div>
          <Row>
            <Col xs={12}>
              <div
                className="single-chat-window text-center"
                ref={chatWrapperRef}
                id="chat-wrapper"
              >
                {messages && messages?.length !== 0 ? (
                  messages?.map((msg, index) => (
                    <div className="mb-4" key={index}>
                      <div
                        className={`d-flex ${
                          userData?.email === msg.requestedUserName
                            ? "dir-ltr"
                            : "dir-rtl"
                        }`}
                      >
                        <Image
                          src={
                            userData?.email === msg.requestedUserName
                              ? window.localStorage.getItem("avatar")
                              : msg?.personalPhoto
                          }
                          roundedCircle
                          className="avatar-chat"
                        />
                        <div
                          key={index}
                          className={`message ${
                            userData?.email === msg.requestedUserName
                              ? "sent"
                              : "received"
                          }`}
                        >
                          <div className="message-content">
                            {msg?.message
                              ? renderMessageContent(msg.message)
                              : null}
                          </div>
                        </div>
                      </div>
                      <span
                        className={`time-chat ${
                          userData?.email === msg.requestedUserName
                            ? "text-right"
                            : "text-left"
                        }`}
                      >
                        {msg?.time}
                        {msg?.isReadByReceiver &&
                          userData?.email === msg.requestedUserName && (
                            <span>
                              <FontAwesomeIcon
                                className="ml-2"
                                icon={faCheck}
                                color="#5b7ecd"
                              />
                              <FontAwesomeIcon
                                style={{ marginLeft: "-4px" }}
                                color="#5b7ecd"
                                icon={faCheck}
                              />
                            </span>
                          )}
                      </span>
                    </div>
                  ))
                ) : (
                  <>
                    <img
                      width="300px"
                      className="pt-3"
                      src={NoMessage}
                      alt="no-message"
                    />
                    <p className="no-message-yet">No Message Yet</p>
                  </>
                )}
              </div>
              <div className="single-message-input d-flex align-items-center">
                <Image
                  src={window.localStorage.getItem("avatar")}
                  roundedCircle
                  className="avatar"
                />
                {!hasFile && (
                  <input
                    autoFocus
                    style={{ width: "250px" }}
                    placeholder="Type message..."
                    className="custom-send-message"
                    value={newMessage}
                    onChange={onMessageChange}
                    onKeyDown={handleKeywordKeyPress}
                  />
                )}
                <div
                  className="ml-auto d-flex"
                  style={{ alignItems: "baseline" }}
                >
                  {thumbnail && (
                    <div className="thumbnail-preview">
                      <img
                        style={{ width: 100, height: 100 }}
                        src={thumbnail}
                        alt="Thumbnail"
                      />
                    </div>
                  )}
                  <div className="file-input-container">
                    <label className="file-input-label">
                      <FontAwesomeIcon
                        className="mr-3 ml-2 chat-icons"
                        color="#d6d6d6"
                        icon={faPaperclip}
                      />
                      <input
                        className="ml-auto"
                        type="file"
                        onChange={handleFileChange}
                        accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx"
                      />
                    </label>
                  </div>
                  <a onClick={hasFile ? handleSendFile : handleSendMessage}>
                    <FontAwesomeIcon
                      className="chat-icons"
                      icon={faPaperPlane}
                      color="#5b7ecd"
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
