import { createSlice } from "@reduxjs/toolkit";

export const secondIdentityVerificationSlice = createSlice({
  name: "secondIdentityVerification",
  initialState: [],
  reducers: {
    secondIdentityVerification: (state, action) => {
      return (state = action.payload);
    },
    secondIdentityVerificationFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { secondIdentityVerification, secondIdentityVerificationFailure } =
  secondIdentityVerificationSlice.actions;
export default secondIdentityVerificationSlice.reducer;
