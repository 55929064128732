import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Input, Button } from "layers";
import { IPurchase } from "models/interfaces";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useTranslation } from "react-i18next";

interface IProp {
  purchases: IPurchase[];
  setPurchases: Function;
  disabled: boolean;
}

export const AddInputForms: React.FC<IProp> = ({
  purchases,
  setPurchases,
  disabled,
}) => {
  const [linkValidities, setLinkValidities] = useState<boolean[]>([]);
  const windowSize = UseWindowSize();
  const isMobile = windowSize.width < 768;
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const { t } = useTranslation();

  function isValidLink(link) {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(link);
  }

  const handleShareholderLinkChange = (idx) => (evt) => {
    const newValidities = [...linkValidities];
    const linkValue = evt.target.value;

    if (linkValue.trim() === "") {
      newValidities[idx] = true;
    } else {
      newValidities[idx] = isValidLink(linkValue);
    }

    setLinkValidities(newValidities);

    const newPurchase = purchases.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;

      return {
        ...shareholder,
        Link: linkValue,
        Quantity: newValidities[idx] && linkValue.trim() !== "" ? "1" : "0",
      };
    });

    setPurchases(newPurchase);
  };

  const handleShareholderQuantityChange = (idx) => (evt) => {
    const newPurchase = purchases.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, Quantity: evt.target.value };
    });

    setPurchases(newPurchase);
  };

  const handleAddShareholder = () => {
    setPurchases(purchases.concat([{ Link: "", Quantity: "0" }]));
    setLinkValidities([...linkValidities, true]); // Set default validity for the new input
  };

  const handleRemoveShareholder = (idx) => () => {
    setPurchases(purchases.filter((s, sidx) => idx !== sidx));
    setLinkValidities(linkValidities.filter((v, vidx) => idx !== vidx)); // Remove the validity of the removed input
  };

  useEffect(() => {
    // Initialize linkValidities with default values for existing inputs
    const initialValues = purchases.map(() => true);
    setLinkValidities(initialValues);

    // Check validation of links from props
    const newValidities = purchases.map((shareholder) =>
      isValidLink(shareholder.Link)
    );
    setLinkValidities(newValidities);
  }, [purchases]);

  const getOriginUrl = (address) => {
    const url = new URL(address);
    const hostname = url.hostname;

    const siteAddress = hostname.replace("www.", "");
    return siteAddress;
  };

  return (
    <form className="w-100 mt-3 dir-ltr">
      {purchases.map((shareholder, idx) => (
        <Row className="links-wrapper" key={idx}>
          <Col xs={9} className={`request-form`}>
            <div className="send-input-wrapper">
              {idx === 0 && (
                <span className="message-title">
                  {disabled &&
                  shareholder.Quantity !== "0" &&
                  shareholder.Quantity !== 0
                    ? t("itemsLinks")
                    : !disabled
                    ? t("itemsLinks")
                    : ""}
                </span>
              )}
              <div>
                {disabled ? (
                  <a
                    target="_blank"
                    href={shareholder.Link}
                    className="link-disable-text"
                  >
                    {shareholder.Link ? getOriginUrl(shareholder.Link) : ""}
                  </a>
                ) : (
                  <Input
                    placeholder="EX: https://www.amazon.ca/Dress"
                    className={`${
                      !linkValidities[idx] && shareholder.Link.trim() !== ""
                        ? "empty-custom-input-link"
                        : "custom-input-link"
                    }`}
                    value={shareholder.Link}
                    onChange={handleShareholderLinkChange(idx)}
                    disabled={disabled}
                  />
                )}
              </div>
              {!linkValidities[idx] && shareholder.Link.trim() !== "" && (
                <span className="err-validation">This is not a valid link</span>
              )}
            </div>
          </Col>
          <Col xs={2} className={`request-form ${isMobile && "pr-0"}`}>
            <div className="send-input-wrapper">
              {!disabled
                ? idx === 0 && <span className="qty-title">{t("qty")}</span>
                : idx === 0 && <span className="qty-title"></span>}
              <div>
                {disabled ? (
                  <span className="link-disable-text">
                    {shareholder.Quantity !== "0" && shareholder.Quantity !== 0
                      ? `${t("qty")}: ${shareholder.Quantity}`
                      : ""}
                  </span>
                ) : (
                  <Input
                    placeholder=""
                    className="custom-input-link-count"
                    value={shareholder.Quantity}
                    onChange={handleShareholderQuantityChange(idx)}
                    type="number"
                    disabled={disabled}
                    min="1"
                  />
                )}
              </div>
            </div>
          </Col>
          {!disabled && (
            <Col xs={1} className={`request-form ${isMobile && "pr-0"}`}>
              {idx === 0 ? (
                <Button
                  variant="white"
                  onClick={handleAddShareholder}
                  className="add-purchase-btn"
                  disabled={disabled}
                >
                  +
                </Button>
              ) : (
                <Button
                  variant="white"
                  onClick={handleRemoveShareholder(idx)}
                  className="remove-purchase-btn"
                  disabled={disabled}
                >
                  -
                </Button>
              )}
            </Col>
          )}
        </Row>
      ))}
    </form>
  );
};
