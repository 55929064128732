import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import data from "json/receives.json";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { SkeletonGrid } from "components";
import { IOfferReceived, Option } from "models/interfaces";
import { Cards } from "./Cards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface IProps {
  display: Option;
  offerReceivedData: IOfferReceived[];
}

export const PackageCard: React.FC<IProps> = ({
  display,
  offerReceivedData,
}) => {
  const [loading, setLoading] = useState(true);
  const [isScrolled, setIsScrolled] = useState(false);
  const windowSize = UseWindowSize();
  const isMobileTablet = windowSize.width < 992;
  const isMobile = windowSize.width < 768;
  const scrollRequestRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (offerReceivedData) setLoading(false);
  }, [offerReceivedData]);

  // const handleScroll = (event) => {
  //   setIsScrolled(true);
  // };

  const handleScroll = () => {
    const container = scrollRequestRef.current;
    if (container) {
      const isScrolledToRight = container.scrollLeft > 0;
      const isScrolledToThreshold = container.scrollLeft <= 50;

      const scrollText = container.querySelector(".request-scroll-indicator");
      if (scrollText) {
        if (isScrolledToRight) {
          scrollText.style.display = "none";
        } else if (isScrolledToThreshold) {
          scrollText.style.display = "block";
        }
      }
    }
  };

  const container = scrollRequestRef.current;
  if (container) {
    container.addEventListener("scroll", handleScroll);
  }

  return (
    <>
      {isMobile ? (
        <div
          className={`container-horizontal ${isMobile ? "dir-rtl" : "dir-ltr"}`}
          ref={scrollRequestRef}
        >
          {/* {offerReceivedData?.length !== 0 && isMobile &&
            (isScrolled ? null : (
              <div className="request-scroll-indicator ">
                <div className="scroll-right-icons">
                  <FontAwesomeIcon icon={faChevronRight} />
                  <FontAwesomeIcon icon={faChevronRight} />
                  <FontAwesomeIcon icon={faChevronRight} />
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </div>
            ))} */}

          {offerReceivedData?.length !== 0 ? (
            offerReceivedData?.map((item, idx) => (
              <div className="offer-card-horizontal">
                <Cards key={idx} data={item} />
              </div>
            ))
          ) : (
            <div className="no-Data">{t("noRequest")}</div>
          )}
        </div>
      ) : (
        <div className="flex-grow-1 request-info-wrapper mb-3">
          <Row>
            {loading ? (
              (data?.items ?? []).map((item) => (
                <Col key={item.name} lg={3} md={6} sm={12}>
                  <SkeletonGrid />
                </Col>
              ))
            ) : (
              <>
                {offerReceivedData?.length !== 0 ? (
                  offerReceivedData?.map((item, idx) => (
                    <Cards key={idx} data={item} />
                  ))
                ) : (
                  <div className="no-Data">{t("noRequest")}</div>
                )}
              </>
            )}
          </Row>
        </div>
      )}
    </>
  );
};
