import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faCopy,
  faCopyright,
  faLink,
  faSave,
  faShare,
  faShareAltSquare,
} from "@fortawesome/free-solid-svg-icons";
import { ShareThis } from "components";
import * as htmlToImage from "html-to-image";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import { useTranslation } from "react-i18next";

interface IProp {
  onShowShareCover: (key: any) => void;
  fade: boolean;
  httpsUrl: string;
  title: string;
  description: string;
  image: string;
}
export const ShareThisCover: React.FC<IProp> = ({
  onShowShareCover,
  fade,
  httpsUrl,
  image,
  title,
}) => {
  const [showShareCover, setShowShareCover] = useState(false);
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { t } = useTranslation();

  const handleCopyText = () => {
    const textArea = document.createElement("textarea");
    textArea.value = httpsUrl;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleShow = () => {
    setShowShareCover(!showShareCover);
    onShowShareCover(showShareCover);
    setFadeOut(true);
  };

  const saveCard = () => {
    const dataURL = image;
    const downloadLink = document.createElement("a");
    downloadLink.href = dataURL;
    downloadLink.download = `${title}.png`;
    downloadLink.click();
  };

  return (
    <div className="more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`request-package-card-wrapper mb-3 request-card-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Body className="request-card-border location-card-info request-card-cover-body">
          <div className="p-3">
            <WhatsappShareButton url={httpsUrl}>
              <WhatsappIcon round={false} size={40} />
            </WhatsappShareButton>
            <TelegramShareButton title={"Accept offer"} url={httpsUrl}>
              <TelegramIcon size={40} round={false} />
            </TelegramShareButton>
            <EmailShareButton title={"Accept offer"} url={httpsUrl}>
              <EmailIcon size={40} round={false} />
            </EmailShareButton>
            <FacebookShareButton title={"Accept offer"} url={httpsUrl}>
              <FacebookIcon size={40} round={false} />
            </FacebookShareButton>
            <TwitterShareButton title={"Accept offer"} url={httpsUrl}>
              <TwitterIcon size={40} round={false} />
            </TwitterShareButton>
            <LinkedinShareButton title={"Accept offer"} url={httpsUrl}>
              <LinkedinIcon size={40} round={false} />
            </LinkedinShareButton>
            <div className="copy-url-btn">
              {isCopied ? (
                <span style={{ color: "#d91e00" }}>{t("linkCopied")}!</span>
              ) : (
                <a onClick={handleCopyText}>
                  <FontAwesomeIcon icon={faLink} /> {t("copyLink")}
                </a>
              )}
            </div>
            <div className="save-image-btn" onClick={saveCard}>
              <a>
                <FontAwesomeIcon icon={faSave} /> {t("saveCard")}
              </a>
            </div>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};
