import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Banner, Footer, RightSidebar } from "layers";
import {
  FlightInfo,
  Filters,
  AvailableRequests,
  AvailableTravelers,
} from "components";
import { getAllCurrencies } from "redux/actions/types";
// import LogoImg from "assets/images/logo/koolbaar-logo-transparent.png";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getFareAlertsCount } from "redux/actions/fareAlert";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { FirsStepAddCard } from "components/modals/FirstStepAddCard";
import AddToHomeScreen from "components/addToHomescreen";
import { UseWindowSize } from "components/windowSize/UseWindowSize";

export const Home: React.FC = () => {
  const { t } = useTranslation();
  const windowSize = UseWindowSize();
  const [tab, setTab] = useState(1);
  const [isChangedTab, setIsChangedTab] = useState(false);
  const [shareImage, setShareImage] = useState("");
  const [availableRequestData, setAvailableRequestData] = useState([]);
  const [availableTravelData, setAvailableTravelData] = useState([]);
  const [type, setType] = useState({ value: null, label: t("all") });
  const [size, setSize] = useState({ value: null, label: t("all") });
  const [policy, setPolicy] = useState({ value: null, label: t("all") });
  const [weight, setWeight] = useState({ value: null, label: t("all") });
  const [services, setServices] = useState({ value: null, label: t("all") });
  const [showSignInSidebar, setShowSignInSidebar] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const filterById: any = useAppSelector((state) => state.filterByTypeId);
  const isMobileTablet = windowSize.width < 992;

  const navigate = useNavigate();
  const [deliveryTypes, setDeliveryTypes] = useState({
    value: null,
    label: t("all"),
  });

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("visitedBefore");
    if (!JSON.parse(hasVisitedBefore)) {
      setIsOpen(true);
      window.localStorage.setItem("visitedBefore", "false");
    }
  }, []);

  const dispatch = useAppDispatch();
  const [payments, setPayments] = useState({ value: null, label: t("all") });
  const homeRequestData = useAppSelector<any>(
    (state) => state.homeRequestFilter
  );
  const homeTravelerData = useAppSelector<any>(
    (state) => state.homeTravelFilter
  );
  const searchRequestFlightResult: any = useAppSelector(
    (state) => state.filterHomeRequestSearch
  );
  const searchTravelFlightResult: any = useAppSelector(
    (state) => state.filterHomeTravelSearch
  );
  const stripeResult: any = useAppSelector((state) => state.paymentResult);

  useEffect(() => {
    const str = window.location.pathname;
    if (str?.includes("signin")) {
      setShowSignInSidebar(true);
      navigate("/signin");
    }
  }, []);

  useEffect(() => {
    if (
      window.localStorage.getItem("token") !== "null" &&
      window.localStorage.getItem("token") !== null
    )
      dispatch(getFareAlertsCount());
  }, []);

  useEffect(() => {
    if (homeRequestData !== 0) setAvailableRequestData(homeRequestData);
  }, [homeRequestData]);

  useEffect(() => {
    if (filterById?.data?.data !== 0) {
      if (tab === 1) setAvailableRequestData(filterById?.data);
      if (tab === 2) setAvailableTravelData(filterById?.data);
    }
  }, [filterById]);

  useEffect(() => {
    if (homeTravelerData !== 0) setAvailableTravelData(homeTravelerData);
  }, [homeTravelerData]);

  useEffect(() => {
    if (searchRequestFlightResult?.isSuccess)
      setAvailableRequestData(searchRequestFlightResult?.data);
  }, [searchRequestFlightResult]);

  useEffect(() => {
    if (searchTravelFlightResult?.isSuccess)
      setAvailableTravelData(searchTravelFlightResult?.data);
  }, [searchTravelFlightResult]);

  useEffect(() => {
    if (stripeResult?.isSuccess) {
      window.location.href = stripeResult?.data?.redirectUrl;
    }
  }, [stripeResult]);

  const handleTypeFilter = (selected) => {
    setType(selected);
  };

  const handleSizeFilter = (selected) => {
    setSize(selected);
  };

  const handlePolicyFilter = (selected) => {
    setPolicy(selected);
  };

  const handleWeightFilter = (selected) => {
    setWeight(selected);
  };

  const handleServicesFilter = (selected) => {
    setServices(selected);
  };

  const handleDeliveryTypesFilter = (selected) => {
    setDeliveryTypes(selected);
  };

  const handlePaymentFilter = (selected) => {
    setPayments(selected);
  };

  const handleTab = (tab) => {
    setTab(tab);
  };

  const handleSidebarTab = (tab) => {
    setTab(tab);
    if (tab === 2) setIsChangedTab(true);
    else setIsChangedTab(false);
  };

  useEffect(() => {
    const str = window.location.pathname;
    if (str?.includes("/travelId/")) {
      setTab(2);
    }
  }, []);

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  const title = "Koolbaar";
  const description =
    "Koolbaar aims to build a secure, comprehensive, user-friendly, and review-based peer-to-peer (P2P) platform by delivering parcels, gifts, international and domestic ride-sharing, etc. Our platform empowers people to connect by providing and receiving various services.";
  return (
    <div className="d-flex flex-column min-vh-100">
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={shareImage} />
      </Helmet>
      <Banner />
      {/* <ChatList /> */}
      <FlightInfo
        onSelectTab={handleTab}
        onSelectSidebarTab={handleSidebarTab}
        isChangedTab={isChangedTab}
      />
      <Filters tab={tab} />
      {tab === 1 ? (
        <AvailableRequests
          type={type}
          size={size}
          weight={weight}
          deliveryTypes={deliveryTypes}
          payments={payments}
          services={services}
          tab={tab}
          availableRequestData={availableRequestData}
          onChangeTab={handleSidebarTab}
          setShareImage={setShareImage}
        />
      ) : (
        <AvailableTravelers
          type={type}
          size={size}
          policy={policy}
          weight={weight}
          services={services}
          deliveryTypes={deliveryTypes}
          payments={payments}
          tab={tab}
          availableTravelData={availableTravelData}
          onChangeTab={handleSidebarTab}
        />
      )}
      {showSignInSidebar && (
        <div className="offer-sidebar">
          <RightSidebar
            isOpen={showSignInSidebar}
            setIsOpen={setShowSignInSidebar}
            sidebarType="login"
          />
        </div>
      )}
      <Footer />
      {/* {isMobileTablet && <AddToHomeScreen />} */}
      <FirsStepAddCard isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
