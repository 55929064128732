import { createSlice } from "@reduxjs/toolkit";

export const modifySenderAgreementSlice = createSlice({
  name: "modifySenderAgreement",
  initialState: [],
  reducers: {
    modifySenderAgreement: (state, action) => {
      return (state = action.payload);
    },
    modifySenderAgreementFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { modifySenderAgreement, modifySenderAgreementFailure } = modifySenderAgreementSlice.actions;
export default modifySenderAgreementSlice.reducer;