import { BaseService } from "./baseService";

class CloseAccountService extends BaseService {
  getAllCloseAccountReasons() {
    return this.authorizedHttp().get("/User/CloseAccount/Reasons");
  }

  sendCloseAccountRequest(data) {
    return this.authorizedHttp().post("/User/CloseAccount", data);
  }
}

export default new CloseAccountService();
