import { createSlice } from "@reduxjs/toolkit";

export const allDashboardProfileSlice = createSlice({
  name: "allDashboardProfile",
  initialState: [],
  reducers: {
    allDashboardProfile: (state, action) => {
      return (state = action.payload);
    },
    allDashboardProfileFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allDashboardProfile, allDashboardProfileFailure } =
  allDashboardProfileSlice.actions;
export default allDashboardProfileSlice.reducer;
