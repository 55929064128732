import LandingService from "services/landingService";
import {
  createOffer,
  createOfferFailure,
} from "redux/slices/landing/createOffer";
import {
  createRequest,
  createRequestFailure,
} from "redux/slices/landing/createRequest";
import {
  chooseRequest,
  chooseRequestFailure,
} from "redux/slices/landing/chooseRequest";
import {
  chooseOffer,
  chooseOfferFailure,
} from "redux/slices/landing/chooseOffer";
import {
  sendContact,
  sendContactFailure,
} from "redux/slices/landing/contactForm";
import {
  packageById,
  packageByIdFailure,
} from "redux/slices/landing/packageById";
import {
  filterByTypeId,
  filterByTypeIdFailure,
} from "redux/slices/landing/filterByTypeId";
import { travelById, travelByIdFailure } from "redux/slices/landing/travelById";
import {
  checkPackage,
  checkPackageFailure,
} from "redux/slices/landing/checkPackage";
import {
  checkTravel,
  checkTravelFailure,
} from "redux/slices/landing/checkTravel";
import {
  changeLanguage,
  changeLanguageFailure,
} from "redux/slices/landing/changeLanguage";
import {
  newDashboard,
  newDashboardFailure,
} from "redux/slices/landing/newDashboard";

import { toast } from "react-toastify";
import { retrieve, retrieveFailure } from "redux/slices/landing/retrieve";
import { allProfile, allProfileFailure } from "redux/slices/landing/allProfile";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

// export const addNewLandingTravel = (data) => async (dispatch) => {
//   try {
//     const res = await LandingService.createOffer(data);
//     if (res?.data?.isSuccess) toast.success(res?.data?.message);
//     else toast.error(res?.data?.message);
//     dispatch(createOffer(res.data));
//   } catch (err) {
//     dispatch(createOfferFailure(err?.response));
//     toast.error(err?.response?.data?.message);
//   }
// };

export const addNewLandingTravel = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.createOffer(data);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    else toast.error(res?.data?.message);
    dispatch(createOffer(res.data));
  } catch (err) {
    dispatch(createOfferFailure(err?.response));
  }
};

export const addNewLandingPackage = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.createRequest(data);
    dispatch(createRequest(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(createRequestFailure(err?.response));
  }
};

export const applyFilterByTypeId = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.filterByTypeId(data);
    dispatch(filterByTypeId(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(filterByTypeIdFailure(err?.response));
  }
};
export const sendChangeLanguage = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.changeLanguage(data);
    dispatch(changeLanguage(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(changeLanguageFailure(err?.response));
  }
};

export const chooseLandingPackage = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.chooseRequest(data);
    dispatch(chooseRequest(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(chooseRequestFailure(err?.response));
  }
};

export const chooseLandingTravel = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.chooseOffer(data);
    dispatch(chooseOffer(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(chooseOfferFailure(err?.response));
  }
};

export const retrieveTravelPackage = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.retrieveTravelPackage(data);
    dispatch(retrieve(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(retrieveFailure(err?.response));
  }
};

export const checkAvailabilityPackage = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.checkAvailabilityPackage();
    dispatch(checkPackage(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(checkPackageFailure(err?.response));
  }
};

export const checkAvailabilityTravel = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.checkAvailabilityTravel();
    dispatch(checkTravel(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(checkTravelFailure(err?.response));
  }
};

export const getAllProfile = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.getAllProfileById(data);
    dispatch(allProfile(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(allProfileFailure(err?.response));
  }
};

export const getNewDashboard = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await LandingService.getNewDashboard();
    dispatch(newDashboard(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(newDashboardFailure(err?.response));
  }
};

export const sendContactForm = (data) => async (dispatch) => {
  try {
    const res = await LandingService.sendContactForm(data);
    dispatch(sendContact(res.data));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(sendContactFailure(err?.response));
  }
};

export const getPackageById = (data) => async (dispatch) => {
  try {
    const res = await LandingService.getPackageById(data);
    dispatch(packageById(res.data));
  } catch (err) {
    dispatch(packageByIdFailure(err?.response));
  }
};

export const getTravelById = (data) => async (dispatch) => {
  try {
    const res = await LandingService.getTravelById(data);
    dispatch(travelById(res.data));
  } catch (err) {
    dispatch(travelByIdFailure(err?.response));
  }
};
