import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { RightSidebar } from "layers";
import { Logo, Button, MenuHeader } from "layers";
import { UseWindowSize } from "../../components/windowSize/UseWindowSize";
import { MultiChat } from "components/modals/MultiChat";
import { logoutUser } from "redux/actions/Authorization";
import { useAppDispatch, useAppSelector } from "redux/store";
import { showLogoutResult } from "redux/slices/Authorization/logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  faCertificate,
  faCheck,
  faLock,
  faDashboard,
  faUser,
  faBell,
  faMessage,
  faComment,
  faComments,
  faEnvelopeOpen,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Confirmation } from "components/modals/Confirmation";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { checkHasChat, getChatCount, getUserAllChat } from "redux/actions/chat";
import {
  getAllAnnouncement,
  getAllAnnouncementCount,
} from "redux/actions/notifications";
import { INotification } from "models/interfaces";
import MultiUserChat from "components/multiUserChat";
import { getNewDashboard, sendChangeLanguage } from "redux/actions/landing";

export const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const size = UseWindowSize();
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [allAnnouncement, setAllAnnouncement] = useState<INotification[]>();
  const [isOpenChatModal, setIsOpenChatModal] = useState(false);
  const location = useLocation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const curr = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const currText = localStorage.getItem("currency")
    ? localStorage.getItem("currency")
    : "CAD";
  const dispatch = useAppDispatch();
  const [currency, setCurrency] = useState({
    value: curr,
    label: curr,
    text: currText,
  });
  const [hasNewChat, setHasNewChat] = useState(false);
  const [notifyCount, setNotifyCount] = useState(0);
  const [language, setLanguage] = useState([
    { value: lang, text: lang?.toUpperCase(), label: lang?.toUpperCase() },
  ]);
  const currencies = useAppSelector((state) => state.currencies);
  const hasChat: any = useAppSelector((state) => state.hasNewChat);
  const allNotificationCount: any = useAppSelector(
    (state) => state.allNotificationCount
  );
  const allChatCount: any = useAppSelector((state) => state.chatCount);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [languageOptions, setLanguageOptions] = useState([
    { value: "en", label: "English United State (EN)", text: "EN" },
    { value: "fa", label: "Persian/Farsi (FA)", text: "FA" },
  ]);
  const [currenciesOption, setCurrenciesOption] = useState([
    {
      value: localStorage.getItem("currency"),
      label: localStorage.getItem("currency"),
      text: localStorage.getItem("currency"),
    },
  ]);
  const [showSidebar, setShowSidebar] = useState(false);
  const isMobile = size.width < 768;
  const [msgCount, setMsgCount] = useState(0);
  const token = window.localStorage.getItem("token");
  const [isLogin, setIsLogin] = useState(
    token === "undefined" || token === "null" || token === null ? false : true
  );

  window.addEventListener("storage", () => {
    if (
      window.localStorage.getItem("token") === "undefined" ||
      window.localStorage.getItem("token") === "null" ||
      window.localStorage.getItem("token") === null
    )
      setIsLogin(false);
    else setIsLogin(true);
  });
  const [avatarImg, setAvatarImg] = useState(
    window.localStorage.getItem("avatar")
      ? window.localStorage.getItem("avatar")
      : null
  );
  const loginData = useAppSelector<any>((state) => state.login);
  const allNotification = useAppSelector<any>((state) => state.allNotification);
  const logoutData = useAppSelector(showLogoutResult);

  window.addEventListener("avatar", () => {
    setAvatarImg(window.localStorage.getItem("avatar"));
  });

  useEffect(() => {
    const options = currencies?.map((item) => {
      return {
        value: item.code,
        label: `${item.name} (${item.code})`,
        text: item.name,
      };
    });
    setCurrenciesOption(options);
  }, [currencies]);

  const handleLoginSidebar = () => {
    setShowSidebar(true);
    navigate("/signin");
  };

  const handleCurrencyChange = (selected) => {
    setCurrency(selected);
    localStorage.setItem("currency", selected.value);
    window.location.reload();
  };

  const handleLanguageChange = (selected) => {
    setLanguage(selected);
    localStorage.setItem("language", selected.value);
    i18n.changeLanguage(selected.value);
    i18n.on("languageChanged", (lng) => {
      document.documentElement.setAttribute("lang", lng);
    });
    const data = {
      language: selected.value,
    };
    if (window.localStorage.getItem("token"))
      dispatch(sendChangeLanguage(data));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const getAllNotifications = () => {
    const data = {
      page: 1,
      pageSize: 5,
    };
    dispatch(getAllAnnouncement(data));
  };

  useEffect(() => {
    if (token) dispatch(getChatCount());
  }, []);

  useEffect(() => {
    if (allNotification?.isSuccess)
      setAllAnnouncement(allNotification?.data?.data);
  }, [allNotification]);

  const customStyle = {
    control: (styles) => ({
      ...styles,
      height: 30,
      padding: 0,
      backgroundColor: "#00043d",
      border: "1px solid #ffffffbf",
      boxShadow: "none",
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      background: "#00043d",
      width: "100%",
      flexWrap: "nowrap",
      fontWeight: 500,
      fontSize: 12,
      padding: "4px 8px",
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#fff",
      fontWeight: 500,
      fontSize: 12,
    }),
    menuList: (styles) => ({
      ...styles,
      height: "auto",
      background: "#00043d",
      width: 195,
      borderRadius: 5,
      border: "none",
    }),
    menu: (styles) => ({
      ...styles,
      background: "#00043d",
    }),
  };

  const currencyCustomStyle = {
    control: (styles) => ({
      ...styles,
      height: 30,
      padding: 0,
      backgroundColor: "#00043d",
      border: "1px solid #ffffffbf",
      boxShadow: "none",
    }),
    option: (styles) => ({
      ...styles,
      color: "#fff",
      background: "#00043d",
      width: "100%",
      flexWrap: "nowrap",
      fontWeight: 500,
      fontSize: 12,
      padding: "4px 8px",
      lineHeight: 2,
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "#fff",
      fontWeight: 500,
      fontSize: 12,
    }),
    menuList: (styles) => ({
      ...styles,
      height: "auto",
      overflowX: "hidden",
      background: "#00043d",
      width: 195,
      borderRadius: 5,
      border: "none",
    }),
    menu: (styles) => ({
      ...styles,
      background: "#00043d",
    }),
  };

  useEffect(() => {
    setShowSidebar(false);
    if (loginData?.data?.isSuccess === true) {
      setIsLogin(true);
    }
  }, [loginData]);

  const logoutBtn = () => {
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    if (isConfirmed && isOpenModal) {
      const data = {
        accessToken: window.localStorage.getItem("token"),
        refreshToken: window.localStorage.getItem("refreshToken"),
      };
      dispatch(logoutUser(data));
    }
  }, [isConfirmed, isOpenModal]);

  useEffect(() => {
    if (
      window.localStorage.getItem("token") !== "" &&
      window.localStorage.getItem("token") != null &&
      window.localStorage.getItem("token") != "null" &&
      window.localStorage.getItem("token") != undefined &&
      !isMobile
    ) {
      dispatch(checkHasChat());
      dispatch(getAllAnnouncementCount());
      dispatch(getNewDashboard());
    }
  }, []);

  useEffect(() => {
    if (hasChat?.isSuccess) {
      setHasNewChat(hasChat.data);
    }
  }, [hasChat]);

  // const audioPlayer = useRef(null);

  // function playAudio() {
  //   audioPlayer.current.play();
  // }

  useEffect(() => {
    if (allNotificationCount?.isSuccess) {
      setNotifyCount(allNotificationCount?.data?.count);
    }
  }, [allNotificationCount]);

  useEffect(() => {
    if (allChatCount?.isSuccess) {
      setMsgCount(allChatCount?.data?.count);
    }
  }, [allChatCount]);

  useEffect(() => {
    if (logoutData.data?.length !== 0) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("avatar");
      window.localStorage.removeItem("expire");
      window.localStorage.removeItem("timestampExpire");
      window.localStorage.removeItem("refreshToken");
      window.localStorage.removeItem("tokenType");
      setIsLogin(false);
      const win: Window = window;
      win.location = "/";
    }
  }, [logoutData]);

  const handleOpenChatModal = () => {
    setIsOpenChatModal(!isOpenChatModal);
    // dispatch(getUserAllChat());
    setHasNewChat(false);
  };

  return (
    <>
      {/* <audio ref={audioPlayer} src="https://api.koolbaar.com/audio/ding.mp3" /> */}
      {size?.width < 768 ? (
        <Row
          style={
            window.location.pathname?.includes("/dashboard") ||
            window.location.pathname === "/payment/failed" ||
            window.location.pathname === "/payment/successful" ||
            window.location.pathname === "/transaction" ||
            window.location.pathname === "/notifications" ||
            // window.location.pathname === "/contact" ||
            window.location.pathname === "/aboutUs" ||
            window.location.pathname === "/accountType" ||
            window.location.pathname === "/termsCondition" ||
            window.location.pathname === "/privacyPolicy" ||
            window.location.pathname === "/prohibitedItems" ||
            window.location.pathname === "/howitworks" ||
            window.location.pathname === "/services" ||
            window.location.pathname === "/vision-mission" ||
            window.location.pathname === "/close-accounts" ||
            window.location.pathname === "/founder-profile" ||
            window.location.pathname?.includes("/settings")
              ? { background: "#00043d" }
              : { background: "transparent" }
          }
          className={`${
            isLogin ? "dashboard-header-wrapper" : "header-wrapper"
          }`}
        >
          <Col xs={2}>
            <Logo />
          </Col>
          <Col xs={10} className="pl-0">
            <MenuHeader />
          </Col>
        </Row>
      ) : (
        <Row
          style={
            window.location.pathname?.includes("/dashboard") ||
            window.location.pathname === "/payment/successful" ||
            window.location.pathname === "/payment/failed" ||
            // window.location.pathname === "/contact" ||
            window.location.pathname === "/aboutUs" ||
            window.location.pathname === "/accountType" ||
            window.location.pathname === "/termsCondition" ||
            window.location.pathname === "/privacyPolicy" ||
            window.location.pathname === "/prohibitedItems" ||
            window.location.pathname === "/howitworks" ||
            window.location.pathname === "/transaction" ||
            window.location.pathname === "/notifications" ||
            window.location.pathname === "/close-accounts" ||
            window.location.pathname === "/services" ||
            window.location.pathname === "/vision-mission" ||
            window.location.pathname === "/founder-profile" ||
            window.location.pathname?.includes("/settings")
              ? { background: "#00043d" }
              : { background: "transparent" }
          }
          className={`${
            isLogin ? "dashboard-header-wrapper" : "header-wrapper"
          } `}
        >
          <Col className="text-right">
            <Logo />
          </Col>
          <Col xs={6} className="d-flex">
            <Select
              className="nav-menu-wrapper d-inline-block mr-3"
              value={language}
              onChange={handleLanguageChange}
              options={languageOptions}
              styles={customStyle}
              formatOptionLabel={(option, { context }) => {
                return context === "menu" ? option.label : option.text;
              }}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
            />
            <Select
              className="custom-select-currency d-inline-block"
              value={currency}
              onChange={handleCurrencyChange}
              options={currenciesOption}
              formatOptionLabel={(option, { context }) => {
                return context === "menu" ? option.label : option.text;
              }}
              styles={currencyCustomStyle}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
            />

            <MenuHeader />
          </Col>
          <Col className="text-left">
            {isLogin ? (
              <div className="d-flex">
                {/* <div className="bell-notify-icon">
                  <FontAwesomeIcon icon={faBell} />
                  <div className="bell-notify-count">99+</div>
                </div> */}
                {notifyCount > 0 ? (
                  <Dropdown className="notification-dropdown d-inline ml-1 header-notification-dropdown">
                    <Dropdown.Toggle
                      variant="transparent"
                      id="dropdown-basic"
                      className="px-0 mx-0"
                    >
                      <div
                        className="bell-notify-icon"
                        onClick={getAllNotifications}
                      >
                        <FontAwesomeIcon icon={faBell} />
                        {notifyCount > 0 && (
                          <div className="bell-notify-count">
                            {notifyCount > 99 ? "+99" : notifyCount}
                          </div>
                        )}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <div className="mb-2 d-flex align-item-center">
                        <h2 className="d-inline mb-0 notify-box-main-title">
                          {t("notifications")}
                        </h2>
                        <Button
                          className="view-all-btn ml-auto"
                          href="/notifications"
                        >
                          {t("viewAll")}
                        </Button>
                      </div>
                      {allAnnouncement?.length > 5
                        ? allAnnouncement?.slice(0, 5)?.map((notify) => {
                            return (
                              <Dropdown.Item href="/notifications">
                                <span className="notification-title-card">
                                  {notify.title}
                                </span>
                                <span className="notification-time-card">
                                  {notify.insertTime}
                                </span>
                              </Dropdown.Item>
                            );
                          })
                        : allAnnouncement?.length <= 5
                        ? allAnnouncement?.map((notify) => {
                            return (
                              <Dropdown.Item href="/notifications">
                                <span className="notification-title-card">
                                  {notify.title}
                                </span>
                                <span className="notification-time-card">
                                  {notify.insertTime}
                                </span>
                              </Dropdown.Item>
                            );
                          })
                        : null}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : (
                  <a className="bell-notify-icon ml-2" href={"/notifications"}>
                    <FontAwesomeIcon icon={faBell} />
                    {notifyCount > 0 && (
                      <div className="bell-notify-count">
                        {notifyCount > 99 ? "+99" : notifyCount}
                      </div>
                    )}
                  </a>
                )}

                <a onClick={handleOpenChatModal}>
                  <div className="message-notify-icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                    {msgCount > 0 && (
                      <div className="message-notify-count">
                        {msgCount > 99 ? "+99" : msgCount}
                      </div>
                    )}
                  </div>
                </a>
                <Dropdown className="profile-dropdown d-inline ml-1 header-profile-dropdown">
                  <Dropdown.Toggle
                    variant="transparent"
                    id="dropdown-basic"
                    className="px-0 mx-0"
                  >
                    <div className="d-flex">
                      <span
                        className={`${
                          avatarImg === "null" && "pt-3"
                        } user-profile-avatar d-inline-block`}
                      >
                        {avatarImg !== "null" ? (
                          <>
                            <img src={avatarImg} alt="avatar-img" />
                            <div className="login-userlevel-icons-wrapper">
                              <FontAwesomeIcon
                                className="mr-2 userlevel-certificate-icon"
                                icon={faCertificate}
                                color={
                                  window.localStorage.getItem(
                                    "userLevelHexColor"
                                  )
                                    ? window.localStorage.getItem(
                                        "userLevelHexColor"
                                      )
                                    : "#808080"
                                }
                              ></FontAwesomeIcon>
                              <FontAwesomeIcon
                                className="mr-2 userlevel-check-icon"
                                icon={faCheck}
                              />
                            </div>
                            {/* <img
                              src={CertificateIcon}
                              alt="icon"
                              className="certificate-on-avatar"
                            /> */}
                          </>
                        ) : (
                          <FontAwesomeIcon
                            className="no-img-avatar"
                            icon={faUser}
                          />
                        )}
                      </span>
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        localStorage.setItem("tab", "userInfo");
                      }}
                      href="/settings"
                    >
                      {/* <FontAwesomeIcon icon={faUser} /> */}
                      {t("profile")}
                    </Dropdown.Item>
                    <Dropdown.Item className="mt-2" href="/dashboard">
                      {/* <FontAwesomeIcon icon={faDashboard} /> */}
                      {t("dashboard")}
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="mt-2 logout-border-top"
                      onClick={logoutBtn}
                    >
                      {/* <FontAwesomeIcon icon={faLock} /> */}
                      {t("logout")}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {isOpenModal && (
                  <Confirmation
                    title={t("exit")}
                    description={t("exitDesc")}
                    isOpen={isOpenModal}
                    setIsOpen={setIsOpenModal}
                    setIsConfirmed={setIsConfirmed}
                    type="updateProfile"
                  />
                )}
              </div>
            ) : (
              <a
                // variant="warning"
                data-test="docs-btn-anchor"
                className="login-header-btn"
                onClick={handleLoginSidebar}
              >
                {t("loginSignup")}
              </a>
            )}
          </Col>
        </Row>
      )}
      {showSidebar && (
        <RightSidebar
          isOpen={showSidebar}
          setIsOpen={setShowSidebar}
          setIsLogin={setIsLogin}
          sidebarType="login"
        />
      )}
      {isOpenChatModal && (
        <MultiUserChat
          isOpen={isOpenChatModal}
          setIsOpen={setIsOpenChatModal}
        />
        // <MultiChat isOpen={isOpenChatModal} setIsOpen={setIsOpenChatModal} />
      )}
    </>
  );
};
