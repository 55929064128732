import React, { useState, useEffect } from "react";
import MyTravel from "../../assets/images/myTravel.png";
import MyPackage from "../../assets/images/myPackage.png";
import LatestOffer from "../../assets/images/latestOffer.png";
import LatestRequest from "../../assets/images/latestRequests.png";
import { MyTravels } from "components/myTravels";
import { MyPackages } from "components/myPackages";
import { SentOffer } from "components/sentOffers";
import { ReceivedOffer } from "components/receivedOffers";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { clickOnOffers, clickOnRequests } from "redux/actions/dashboard";
import { useAppDispatch, useAppSelector } from "redux/store";

interface IProp {
  dashboardData: any;
  offerSentData: any;
  offerReceivedData: any;
  trvId: string;
  setTrvId: Function;
  pkgId: string;
  selectedTab: string;
  setPkgId: Function;
  display: any;
  hasNewOffer: boolean;
  hasNewPackage: boolean;
  hasNewRequest: boolean;
  hasNewTravel: boolean;
  setSelectedTab: Function;
}

export const DashboardTabs: React.FC<IProp> = ({
  dashboardData,
  offerSentData,
  offerReceivedData,
  trvId,
  setTrvId,
  pkgId,
  selectedTab,
  setSelectedTab,
  setPkgId,
  display,
  hasNewTravel,
  hasNewPackage,
  hasNewOffer,
  hasNewRequest,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const offersClicked: any = useAppSelector((state) => state.offersClicked);
  const requestsClicked: any = useAppSelector((state) => state.requestsClicked);

  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";

  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(
    isPersian ? "سفرهای من" : "My Travels"
  );

  const handleTabClick = (tabTitle) => {
    setActiveTab(tabTitle);
    setSelectedTab(tabTitle);
  };

  useEffect(() => {
    if (selectedTab === t("myTravels") || selectedTab === "myTravels") {
      setActiveTab(t("myTravels"));
      navigate("/dashboard/myTravels", { state: t("myTravels") });
    } else if (
      selectedTab === t("myPackages") ||
      selectedTab === "myPackages"
    ) {
      setActiveTab(t("myPackages"));
      navigate("/dashboard/myPackages", { state: t("myPackages") });
    } else if (
      selectedTab === t("latestSentOffer") ||
      selectedTab === "latestSentOffer"
    ) {
      setActiveTab(t("latestSentOffer"));
      navigate("/dashboard/latestSentOffer", { state: t("latestSentOffer") });
      dispatch(clickOnOffers());
    } else if (
      selectedTab === t("latestSentRequest") ||
      selectedTab === "latestSentRequest"
    ) {
      setActiveTab(t("latestSentRequest"));
      navigate("/dashboard/latestSentRequest", {
        state: t("latestSentRequest"),
      });
      dispatch(clickOnRequests());
    }
  }, [selectedTab]);

  // useEffect(() => {
  //   console.log(offersClicked, requestsClicked);
  // }, [offersClicked, requestsClicked]);

  return (
    <div className="dashboard-tabs-container">
      <div className="tabs-wrapper scrollable-tabs-container">
        <div className="tab-container">
          {/* <div
            className={`tab ${activeTab === t("myTravels") ? "active" : ""}`}
            onClick={() => handleTabClick(t("myTravels"))}
          >
            {t("myTravels")}{" "}
            <span className="bullet-notification-tab"></span>
            <img src={MyTravel} className="tabs-icon" alt="tab-img" />
          </div> */}

          <div
            className={`tab ${activeTab === t("myTravels") ? "active" : ""}`}
            onClick={() => handleTabClick(t("myTravels"))}
          >
            {hasNewTravel && (
              <span className={`bullet-notification-tab blinking`}></span>
            )}
            {t("myTravels")}{" "}
            <img src={MyTravel} className="tabs-icon" alt="tab-img" />
          </div>
          <div
            className={`tab ${activeTab === t("myPackages") ? "active" : ""}`}
            onClick={() => handleTabClick(t("myPackages"))}
          >
            {hasNewPackage && (
              <span className={`bullet-notification-tab blinking`}></span>
            )}
            {t("myPackages")}{" "}
            <img src={MyPackage} className="tabs-icon" alt="tab-img" />
          </div>
          <div
            className={`tab ${
              activeTab === t("latestSentOffer") ? "active" : ""
            }`}
            onClick={() => handleTabClick(t("latestSentOffer"))}
          >
            {hasNewOffer && (
              <span className={`bullet-notification-tab blinking`}></span>
            )}
            {t("latestSentOffer")}{" "}
            <img src={LatestOffer} className="tabs-icon" alt="tab-img" />
          </div>
          <div
            className={`tab ${
              activeTab === t("latestSentRequest") ? "active" : ""
            }`}
            onClick={() => handleTabClick(t("latestSentRequest"))}
          >
            {hasNewRequest && (
              <span className={`bullet-notification-tab blinking`}></span>
            )}
            {t("latestSentRequest")}{" "}
            <img src={LatestRequest} className="tabs-icon" alt="tab-img" />
          </div>
        </div>
      </div>
      {activeTab === t("myTravels") && (
        <div>
          <MyTravels
            travelerData={dashboardData?.data?.travels}
            setTrvId={setTrvId}
          />
        </div>
      )}
      {activeTab === t("myPackages") && (
        <div>
          <MyPackages
            packagesData={dashboardData?.data?.packages}
            setPkgId={setPkgId}
          />
        </div>
      )}
      {activeTab === t("latestSentOffer") && (
        <div>
          <SentOffer
            offerSentData={offerSentData}
            display={display}
            pkgId={pkgId}
          />
        </div>
      )}
      {activeTab === t("latestSentRequest") && (
        <div>
          <ReceivedOffer
            offerReceivedData={offerReceivedData}
            display={display}
            trvId={trvId}
          />
        </div>
      )}
    </div>
  );
};
