import { createSlice } from "@reduxjs/toolkit";

export const cancellationPolicySlice = createSlice({
  name: "cancellationPolicy",
  initialState: [],
  reducers: {
    cancellationPolicy: (state, action) => {
      return (state = action.payload.data);
    },
    cancellationPolicyFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { cancellationPolicy, cancellationPolicyFailure } = cancellationPolicySlice.actions;
export default cancellationPolicySlice.reducer;
