import axios from "axios";

export const searchAirline = (airline) => {
  const airlines = axios.post(
    process.env.REACT_APP_BASE_URL + `Airline/Search`,
    { text: airline },
    {
      headers: {
        "Accept-Language": localStorage.getItem("language"),
        Currency: localStorage.getItem("currency"),
        "Time-Zone": localStorage.getItem("timezone"),
      },
    }
  );

  if (airlines) {
    return airlines.then((response) => {
      return response?.data?.data?.map((item) => ({
        label: item.name,
        value: item.name,
      }));
    });
  }
};
