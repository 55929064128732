import { createSlice } from "@reduxjs/toolkit";

export const sendDisapprovalReasonSlice = createSlice({
  name: "sendDisapprovalReason",
  initialState: [],
  reducers: {
    sendDisapprovalReason: (state, action) => {
      return (state = action.payload);
    },
    sendDisapprovalReasonFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { sendDisapprovalReason, sendDisapprovalReasonFailure } = sendDisapprovalReasonSlice.actions;
export default sendDisapprovalReasonSlice.reducer;
