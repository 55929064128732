import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { PackageCard } from "./PackageCard";
import { IOfferSent, Option } from "models/interfaces";
import { DebounceInput } from "react-debounce-input";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  // getOfferStatus,
  getAllStatusChanges,
  getAllOfferStatus,
  getFilterOfferStatus,
  getSearchByPackageId,
} from "redux/actions/dashboard";
import { useTranslation } from "react-i18next";

interface IProps {
  display: Option;
  pkgId: string;
  offerSentData: IOfferSent[];
}

export const SentOffer: React.FC<IProps> = ({
  display,
  offerSentData,
  pkgId,
}) => {
  const windowSize = UseWindowSize();
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const dispatch = useAppDispatch();
  const [packageId, setPackageId] = useState("");
  const [options, setOptions] = useState([]);
  const [filter, setFilter] = useState({ value: 0, label: t("displayAll") });
  const offerStatusData = useAppSelector((state) => state.getChangedStatus);

  // useEffect(() => {
  //   dispatch(getAllStatusChanges());
  // }, []);

  useEffect(() => {
    if (pkgId !== "" && pkgId !== null) {
      setPackageId(pkgId);
      dispatch(getSearchByPackageId(pkgId));
    }
  }, [pkgId]);

  useEffect(() => {
    const options = offerStatusData?.map((item) => {
      return {
        value: item.name,
        label: item.name,
      };
    });
    setOptions([{ value: null, label: t("displayAll") }, ...options]);
  }, [offerStatusData]);

  const customStyle = {
    control: (styles) => ({
      ...styles,
      height: 47,
      width: "auto",
      minWidth: 167,
      color: "#989898",
      fontSize: 13,
      fontWeight: 500,
      textAlign: isPersian ? "right" : "left",
    }),
    option: (styles) => ({
      ...styles,
      color: "#989898",
      backgroundColor: "#f3f3f3",
    }),
    singleValue: (styles) => ({
      ...styles,
      // color: "#00043d",
    }),
    menuList: (styles) => ({
      ...styles,
      width: "100%",
      whiteSpace: "nowrap",
      textAlign: "left",
    }),
  };

  const handleFilterChange = (selected) => {
    setFilter(selected);
    if (selected.label === t("displayAll")) {
      dispatch(getAllOfferStatus());
    } else {
      const data = {
        type: "offer",
        changestatusName: selected.value,
      };
      dispatch(getFilterOfferStatus(data));
    }
  };

  const changePackageId = (e) => {
    setPackageId(e.target.value);
    if (e.target.value !== "" && e.target.value !== null)
      dispatch(getSearchByPackageId(e.target.value));
  };

  return (
    <div className="send-offer-wrapper dir-ltr my-5" id="send-offer-wrapper">
      {windowSize?.width >= 992 && (
        <Row className={`${isPersian ? "dir-rtl":"dir-ltr"} mt-2 mb-5`}>
          <Col xs={6} className="align-self-center">
            <h2>{t("latestSentOffer")}</h2>
          </Col>
          <Col xs={6} className="text-align-filter-dashboard">
            <DebounceInput
              minLength={1}
              debounceTimeout={100}
              onChange={changePackageId}
              // onKeyDown={handleFlightKeyDown}
              placeholder={t("packageID")}
              value={packageId}
              className="custom-select-search-offer d-inline-block"
            />
            <Select
              className="custom-select-status-type d-inline-block"
              value={filter}
              onChange={handleFilterChange}
              placeholder={t("displayAll")}
              options={options}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={customStyle}
            />
          </Col>
        </Row>
      )}
      {windowSize?.width < 992 && (
        <Row
          className="my-4"
          style={{
            // width: "360px",
            marginRight: 0,
            marginLeft: "18px",
            alignItems: "center",
          }}
        >
          <Col xs={12}>
            <h2>{t("latestSentOffer")}</h2>
          </Col>
          <Col xs={6} sm={6} md={2} className="text-left mt-4">
            <DebounceInput
              minLength={1}
              debounceTimeout={100}
              onChange={changePackageId}
              // onKeyDown={handleFlightKeyDown}
              placeholder={t("packageID")}
              value={packageId}
              className="custom-select-search-offer d-inline-block"
            />
          </Col>
          <Col xs={6} sm={6} md={2} className="text-left mt-3 pl-0">
            <Select
              className="custom-select-status-type d-inline-block"
              value={filter}
              onChange={handleFilterChange}
              placeholder={t("displayAll")}
              options={options}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={customStyle}
            />
          </Col>
        </Row>
      )}
      <PackageCard display={display} offerSentData={offerSentData} />
    </div>
  );
};
