/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Button } from "layers";
import { Gallery } from "react-photoswipe-gallery";
import { LightBoxItem } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import AvatarImg from "../../assets/images/avatar.png";
import { ITraveler } from "models/interfaces";
import { useTranslation } from "react-i18next";

interface IProp {
  onShowCover: (key: any) => void;
  fade: boolean;
  data: ITraveler;
}
export const PackageCover: React.FC<IProp> = ({ onShowCover, fade, data }) => {
  const [showCover, setShowCover] = useState(false);
  const [openProfileBox, setOpenProfileBox] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [showAllString, setShowAllString] = useState(false);
  const { t } = useTranslation();

  const handleShow = () => {
    setShowCover(!showCover);
    onShowCover(showCover);
    setFadeOut(true);
  };

  const showAllStringChange = () => {
    setShowAllString(!showAllString);
  };

  return (
    <div className="more-detail-wrapper">
      <Button variant="primary" className="close-card-btn" onClick={handleShow}>
        <FontAwesomeIcon icon={faClose} />
      </Button>
      <Card
        className={`traveler-package-card-wrapper mb-3 travelers-card-wrapper ${
          fade ? "fadeIn" : ""
        } ${fadeOut ? "fadeOut" : ""}`}
      >
        <Card.Header className="traveler-card-cover-header">
          <Row>
            {data?.description?.length <= 40 ? (
              <Col xs={12}>
                <span className="d-block">{t("note")}:{" "}</span>
                {data?.description}
              </Col>
            ) : (
              <Col xs={12}>
                <span className="d-block">{t("note")}:</span>

                {showAllString
                  ? data?.description
                  : data?.description.substring(0, 40)}
                {!showAllString ? (
                  <a className="more-string" onClick={showAllStringChange}>
                    {t("more")} ...
                  </a>
                ) : (
                  <a className="more-string" onClick={showAllStringChange}>
                    {t("less")}
                  </a>
                )}
              </Col>
            )}
          </Row>
        </Card.Header>
        <Card.Body className="request-card-border location-cover-info traveler-card-cover-body">
          {data?.airlineName && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">{t("flightNameIs")}: </span>
              <span className="location-description">{data?.airlineName}</span>
            </div>
          )}
          {data?.flightNumber && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">{t("flightNumber")}: </span>
              <span className="location-description">{data?.flightNumber}</span>
            </div>
          )}
          {data?.deliveryTypes && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">{t("servicesProvided")}: </span>
              <span className="location-description">
                {data?.deliveryTypes}
              </span>
            </div>
          )}
          {data?.packagetypes && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">{t("packages")}: </span>
              <span className="location-description">{data?.packagetypes}</span>
            </div>
          )}
          {data?.paymentmethods && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">
                {t("paymentMethod")}:{" "}
              </span>
              <span className="location-description">
                {data?.paymentmethods}
              </span>
            </div>
          )}{" "}
          {data?.cancellationpolicy && (
            <div className="location-title-wrapper">
              <span className="location-title mr-1">
                {t("cancellationPolicy")}:{" "}
              </span>
              <span className="location-description">
                {data?.cancellationpolicy}
              </span>
            </div>
          )}
          <Row className="image-more-wrapper mt-2">
            <Gallery>
              {data?.images?.map((item, idx) => {
                return (
                  <div
                    className="text-center images-delivery-img-wrapper"
                    key={idx}
                  >
                    <LightBoxItem
                      original={item}
                      thumbnail={item}
                      width="45px"
                      height="45px"
                    />
                  </div>
                );
              })}
            </Gallery>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};
