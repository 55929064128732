import DashboardService from "services/dashboardService";
import {
  userDashboard,
  userDashboardFailure,
} from "redux/slices/dashboard/userDashboard";
import {
  getChangedStatus,
  getChangedStatusFailure,
} from "redux/slices/dashboard/getChangedStatus";
import {
  changeOfferStatus,
  changeOfferStatusFailure,
} from "redux/slices/dashboard/changeOfferStatus";
import {
  changeButtonStatus,
  changeButtonStatusFailure,
} from "redux/slices/dashboard/changeButtonStatus";
import {
  changeButtonPaymentStatusOffer,
  changeButtonPaymentStatusOfferFailure,
} from "redux/slices/dashboard/changeButtonPaymentStatusOffer";
import {
  changeButtonPaymentStatusRequest,
  changeButtonPaymentStatusRequestFailure,
} from "redux/slices/dashboard/changeButtonPaymentStatusRequest";
import {
  changeRequestStatus,
  changeRequestStatusFailure,
} from "redux/slices/dashboard/changeRequestStatus";
import {
  sendAgreement,
  sendAgreementFailure,
} from "redux/slices/dashboard/sendAgreement";
import {
  changeStatus,
  changeStatusFailure,
} from "redux/slices/dashboard/changeStatus";
import { proof, proofFailure } from "redux/slices/dashboard/proofDocuments";
import { proofById, proofByIdFailure } from "redux/slices/dashboard/proofById";
import {
  disapprovalReason,
  disapprovalReasonFailure,
} from "redux/slices/dashboard/disapprovalReason";
import {
  sendDisapprovalReason,
  sendDisapprovalReasonFailure,
} from "redux/slices/dashboard/sendDisapprovalReason";
import {
  cancellation,
  cancellationFailure,
} from "redux/slices/dashboard/cancellation";
import {
  senderReasonCancellation,
  senderReasonCancellationFailure,
} from "redux/slices/dashboard/senderReasonCancellation";
import {
  travelerReasonCancellation,
  travelerReasonCancellationFailure,
} from "redux/slices/dashboard/travelerReasonCancellation";
import { addTravel, addTravelFailure } from "redux/slices/dashboard/addTravel";
import {
  editTravel,
  editTravelFailure,
} from "redux/slices/dashboard/editTravel";
import {
  userTravel,
  userTravelFailure,
} from "redux/slices/dashboard/userTravelById";
import {
  deleteTravel,
  deleteTravelFailure,
} from "redux/slices/dashboard/userDeleteTravelById";
import {
  addPackage,
  addPackageFailure,
} from "redux/slices/dashboard/addPackage";
import {
  editPackage,
  editPackageFailure,
} from "redux/slices/dashboard/editPackage";
import {
  userPackage,
  userPackageFailure,
} from "redux/slices/dashboard/userPackageById";
import {
  deletePackage,
  deletePackageFailure,
} from "redux/slices/dashboard/userDeletePackageById";
import {
  requestTimeline,
  requestTimelineFailure,
} from "redux/slices/dashboard/requestTimeline";
import {
  offerTimeline,
  offerTimelineFailure,
} from "redux/slices/dashboard/offerTimeline";
import {
  offerProblem,
  offerProblemFailure,
} from "redux/slices/dashboard/reportOfferProblem";
import {
  offerStatus,
  offerStatusFailure,
} from "redux/slices/dashboard/offerStatus";
import {
  requestStatus,
  requestStatusFailure,
} from "redux/slices/dashboard/requestStatus";
import {
  requestProblem,
  requestProblemFailure,
} from "redux/slices/dashboard/reportRequestProblem";
import {
  allOfferStatus,
  allOfferStatusFailure,
} from "redux/slices/dashboard/allOfferStatus";
import {
  allRequestStatus,
  allRequestStatusFailure,
} from "redux/slices/dashboard/allRequestStatus";
import {
  filterOfferStatus,
  filterOfferStatusFailure,
} from "redux/slices/dashboard/filterOfferStatus";
import {
  filterRequestStatus,
  filterRequestStatusFailure,
} from "redux/slices/dashboard/filterRequestStatus";
import {
  searchByPkgId,
  searchByPkgIdFailure,
} from "redux/slices/dashboard/searchByPkgId";
import {
  searchByTrvId,
  searchByTrvIdFailure,
} from "redux/slices/dashboard/searchByTrvId";
import {
  sendReview,
  sendReviewFailure,
} from "redux/slices/dashboard/sendReview";
import {
  requestsClicked,
  requestsClickedFailure,
} from "redux/slices/dashboard/requestsClicked";
import {
  offersClicked,
  offersClickedFailure,
} from "redux/slices/dashboard/offersClicked";
import {
  allDashboardProfile,
  allDashboardProfileFailure,
} from "redux/slices/dashboard/dashboardProfile";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { toast } from "react-toastify";
import { isTokenExpired } from "helpers/checkTokenExpired";

export const getAllDashboardData = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getDashboard();
    dispatch(userDashboard(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(userDashboardFailure(err?.response));
  }
};

export const getAllStatusChanges = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getAllChangedStatus();
    dispatch(getChangedStatus(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(getChangedStatusFailure(err?.response));
  }
};

export const alterOfferStatus = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.changeOfferStatus(data);
    dispatch(changeOfferStatus(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(changeOfferStatusFailure(err?.response));
  }
};

export const alterButtonStatus = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.changeStatus(data);
    dispatch(changeButtonStatus(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(changeButtonStatusFailure(err?.response));
  }
};

export const alterButtonToPaymentOfferStatus = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.changeStatusToPayment(data);
    dispatch(changeButtonPaymentStatusOffer(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(changeButtonPaymentStatusOfferFailure(err?.response));
  }
};

export const alterButtonToPaymentRequestStatus = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.changeStatusToPayment(data);
    dispatch(changeButtonPaymentStatusRequest(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(changeButtonPaymentStatusRequestFailure(err?.response));
  }
};

export const alterRequestStatus = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.changeRequestStatus(data);
    dispatch(changeRequestStatus(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(changeRequestStatusFailure(err?.response));
  }
};

export const changeGeneralStatus = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.changeGeneralStatus(data);
    dispatch(changeStatus(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(changeStatusFailure(err?.response));
  }
};

export const cancellationStatus = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.cancellation(data);
    dispatch(cancellation(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(cancellationFailure(err?.response));
  }
};

export const cancellationReasonForSender = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.senderReasonCancellation();
    dispatch(senderReasonCancellation(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
  } catch (err) {
    dispatch(senderReasonCancellationFailure(err?.response));
  }
};

export const cancellationReasonForTraveler = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.travelerReasonCancellation();
    dispatch(travelerReasonCancellation(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
  } catch (err) {
    dispatch(travelerReasonCancellationFailure(err?.response));
  }
};

export const getDisapprovalReason = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.disapprovalReason();
    dispatch(disapprovalReason(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
  } catch (err) {
    dispatch(disapprovalReasonFailure(err?.response));
  }
};

export const proofDocument = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.proofDocuments(data);
    dispatch(proof(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(proofFailure(err?.response));
  }
};

export const sendDeliveryDisapprovalReason = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.sendDisapprovalReason(data);
    dispatch(sendDisapprovalReason(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(sendDisapprovalReasonFailure(err?.response));
  }
};

export const getProof = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getProof(id);
    dispatch(proofById(res.data));
    if (!res?.data?.isSuccess) return toast.error(res?.data?.message);
    // toast.success(res?.data?.message);
  } catch (err) {
    dispatch(proofByIdFailure(err?.response));
  }
};

export const sendUserAgreement = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.sendUserAgreement(data);
    dispatch(sendAgreement(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(sendAgreementFailure(err?.response));
  }
};

export const sendUserReview = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.sendReview(data);
    dispatch(sendReview(res.data));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(sendReviewFailure(err?.response));
  }
};

export const reportOfferProblem = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.reportOfferProblem(data);
    dispatch(offerProblem(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(offerProblemFailure(err?.response));
  }
};

export const reportRequestProblem = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.reportRequestProblem(data);
    dispatch(requestProblem(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(requestProblemFailure(err?.response));
  }
};

export const getTravel = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getTravelById(id);
    dispatch(userTravel(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(userTravelFailure(err?.response));
  }
};

export const addNewTravel = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.addNewTravel(data);
    dispatch(addTravel(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(addTravelFailure(err?.response));
  }
};

export const editUserTravel = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.editNewTravel(data);
    dispatch(editTravel(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(editTravelFailure(err?.response));
  }
};

export const deleteUserTravel = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.removeTravel(id);
    dispatch(deleteTravel(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(deleteTravelFailure(err?.response));
  }
};

export const getPackage = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getPackageById(id);
    dispatch(userPackage(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(userPackageFailure(err?.response));
  }
};

export const addNewPackage = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.addNewPackage(data);
    dispatch(addPackage(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(addPackageFailure(err?.response));
  }
};

export const editUserPackage = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.editNewPackage(data);
    dispatch(editPackage(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(editPackageFailure(err?.response));
  }
};

export const deleteUserPackage = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.removePackage(id);
    dispatch(deletePackage(res.data));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(deletePackageFailure(err?.response));
  }
};

export const getRequestTimeline = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getRequestTimeline(id);
    dispatch(requestTimeline(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(requestTimelineFailure(err?.response));
  }
};

export const getOfferTimeline = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getOfferTimeline(id);
    dispatch(offerTimeline(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(offerTimelineFailure(err?.response));
  }
};

export const getOfferStatus = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getOfferStatus();
    dispatch(offerStatus(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(offerStatusFailure(err?.response));
  }
};

export const getFilterOfferStatus = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.filterOfferStatus(id);
    dispatch(filterOfferStatus(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(filterOfferStatusFailure(err?.response));
  }
};

export const getRequestStatus = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getRequestStatus();
    dispatch(requestStatus(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(requestStatusFailure(err?.response));
  }
};

export const getFilterRequestStatus = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.filterRequestStatus(id);
    dispatch(filterRequestStatus(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(filterRequestStatusFailure(err?.response));
  }
};

export const getAllRequestStatus = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getAllRequestStatus();
    dispatch(allRequestStatus(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(allRequestStatusFailure(err?.response));
  }
};

export const getAllOfferStatus = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getAllOfferStatus();
    dispatch(allOfferStatus(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(allOfferStatusFailure(err?.response));
  }
};

export const getSearchByPackageId = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getOffersByPkgId(id);
    dispatch(searchByPkgId(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(searchByPkgIdFailure(err?.response));
  }
};

export const getSearchByTravelId = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getRequestsByTrvId(id);
    dispatch(searchByTrvId(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(searchByTrvIdFailure(err?.response));
  }
};

export const getAllDashboardProfile = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.getAllDashboardProfileById(data);
    dispatch(allDashboardProfile(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(allDashboardProfileFailure(err?.response));
  }
};

export const clickOnRequests = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.clickOnRequests();
    dispatch(requestsClicked(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(requestsClickedFailure(err?.response));
  }
};

export const clickOnOffers = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await DashboardService.clickOnOffers();
    dispatch(offersClicked(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(offersClickedFailure(err?.response));
  }
};
