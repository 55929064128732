import { createSlice } from "@reduxjs/toolkit";

export const agreementByTypeSendSlice = createSlice({
  name: "agreementByTypeSend",
  initialState: [],
  reducers: {
    agreementByTypeSend: (state, action) => {
      return (state = action.payload);
    },
    agreementByTypeSendFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { agreementByTypeSend, agreementByTypeSendFailure } = agreementByTypeSendSlice.actions;
export default agreementByTypeSendSlice.reducer;
