import React, { useState, useEffect } from "react";
import { MyTravels, MyPackages, ReceivedOffer, SentOffer } from "components";
import { Header, Footer } from "layers";
import {
  getAllDashboardData,
  getAllStatusChanges,
} from "redux/actions/dashboard";
import { useAppDispatch, useAppSelector } from "redux/store";
import { ToastContainer } from "react-toastify";
import { getAllCurrencies } from "redux/actions/types";
import { getUserInfo } from "redux/actions/Authorization";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DashboardTabs } from "components/dashboardTabs";
import { MobileDashboardTabs } from "components/mobileDashboardTabs";
import { UseWindowSize } from "components/windowSize/UseWindowSize";

export const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const windowSize = UseWindowSize();
  const isMobile = windowSize.width < 768;
  const [display, setDisplay] = useState({ value: 0, label: t("all") });
  const [offerSentData, setOfferSentData] = useState([]);
  const [offerReceivedData, setOfferReceivedData] = useState([]);
  const [pkgId, setPkgId] = useState("");
  const [trvId, setTrvId] = useState("");
  const [hasNewOffer, setHasNewOffer] = useState(false);
  const [hasNewPackage, setHasNewPackage] = useState(false);
  const [hasNewRequest, setHasNewRequest] = useState(false);
  const [hasNewTravel, setHasNewTravel] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const dashboardData: any = useAppSelector((state) => state?.userDashboard);
  const allOfferStatus = useAppSelector<any>((state) => state.allOfferStatus);
  const searchByPkgId = useAppSelector<any>((state) => state.searchByPkgId);
  const searchByTrvId = useAppSelector<any>((state) => state.searchByTrvId);
  const { state } = useLocation();
  const location = useLocation();

  const allRequestStatus = useAppSelector<any>(
    (state) => state.allRequestStatus
  );
  const filterOfferStatus = useAppSelector<any>(
    (state) => state.filterOfferStatus
  );
  const filterRequestStatus = useAppSelector<any>(
    (state) => state.filterRequestStatus
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSelectedTab(location.pathname?.replace("/dashboard/", ""));
    const element = document.getElementById("dashboard-data-wrapper");
    element.scrollIntoView({ behavior: "smooth" });
  }, [state, location, dashboardData]);

  useEffect(() => {
    dispatch(getAllDashboardData());
    dispatch(getAllStatusChanges());
  }, []);

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    if (filterOfferStatus !== 0) {
      setOfferSentData(filterOfferStatus);
    }
  }, [filterOfferStatus]);

  useEffect(() => {
    if (filterRequestStatus !== 0) {
      setOfferReceivedData(filterRequestStatus);
    }
  }, [filterRequestStatus]);

  useEffect(() => {
    if (allOfferStatus !== 0) {
      setOfferSentData(allOfferStatus);
    }
  }, [allOfferStatus]);

  useEffect(() => {
    if (searchByPkgId?.isSuccess) {
      setOfferSentData(searchByPkgId.data);
    }
  }, [searchByPkgId]);

  useEffect(() => {
    if (searchByTrvId?.isSuccess) {
      setOfferReceivedData(searchByTrvId.data);
    }
  }, [searchByTrvId]);

  useEffect(() => {
    if (allRequestStatus !== 0) {
      setOfferReceivedData(allRequestStatus);
    }
  }, [allRequestStatus]);

  useEffect(() => {
    if (dashboardData?.data?.offers !== 0) {
      setOfferSentData(dashboardData?.data?.offers);
    }
    if (dashboardData?.data?.requests !== 0) {
      setOfferReceivedData(dashboardData?.data?.requests);
    }
  }, [dashboardData]);

  useEffect(() => {
    setHasNewOffer(dashboardData?.data?.hasNewOffer);
    setHasNewPackage(dashboardData?.data?.hasNewPackage);
    setHasNewRequest(dashboardData?.data?.hasNewRequest);
    setHasNewTravel(dashboardData?.data?.hasNewTravel);
  }, [dashboardData]);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard" id="dashboard-data-wrapper">
        <h2>{t("dashboard")}</h2>
      </div>
      {isMobile ? (
        <MobileDashboardTabs
          dashboardData={dashboardData}
          offerSentData={offerSentData}
          offerReceivedData={offerReceivedData}
          trvId={trvId}
          setTrvId={setTrvId}
          pkgId={pkgId}
          setPkgId={setPkgId}
          display={display}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          hasNewOffer={hasNewOffer}
          hasNewPackage={hasNewPackage}
          hasNewRequest={hasNewRequest}
          hasNewTravel={hasNewTravel}
        />
      ) : (
        <DashboardTabs
          dashboardData={dashboardData}
          offerSentData={offerSentData}
          offerReceivedData={offerReceivedData}
          trvId={trvId}
          setTrvId={setTrvId}
          pkgId={pkgId}
          setPkgId={setPkgId}
          display={display}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          hasNewOffer={hasNewOffer}
          hasNewPackage={hasNewPackage}
          hasNewRequest={hasNewRequest}
          hasNewTravel={hasNewTravel}
        />
      )}

      {/* <MyTravels
        travelerData={dashboardData?.data?.travels}
        setTrvId={setTrvId}
      />
      <MyPackages
        packagesData={dashboardData?.data?.packages}
        setPkgId={setPkgId}
      />
      <SentOffer
        offerSentData={offerSentData}
        display={display}
        pkgId={pkgId}
      />
      <ReceivedOffer
        offerReceivedData={offerReceivedData}
        display={display}
        trvId={trvId}
      /> */}
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};
