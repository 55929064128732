import CloseAccountService from "services/closeAccountService";
import {
  closeAccountReasons,
  closeAccountReasonsFailure,
} from "redux/slices/closeAccounts/getCloseAccountReasons";
import {
  closeAccountRequest,
  closeAccountRequestFailure,
} from "redux/slices/closeAccounts/closeAccountRequest";
import { toast } from "react-toastify";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

export const getCloseAccountReasons = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await CloseAccountService.getAllCloseAccountReasons();
    dispatch(closeAccountReasons(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(closeAccountReasonsFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const sendCloseAccountRequest = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await CloseAccountService.sendCloseAccountRequest(data);
    dispatch(closeAccountRequest(res.data));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(closeAccountRequestFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};
