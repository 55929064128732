import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Row, Col, Card } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useAppDispatch, useAppSelector } from "redux/store";
import { Oval } from "react-loader-spinner";
import PlaneIcon from "../../assets/images/plane.png";
import { useTranslation } from "react-i18next";
import { retrieveTravelPackage } from "redux/actions/landing";
import { IMyPackages, IRetrieve } from "models/interfaces";

interface IProp {
  setReuseData: Function;
  retrieveData: IRetrieve;
}

export const ReusePackageHistory: React.FC<IProp> = ({
  setReuseData,
  retrieveData,
}) => {
  const size = UseWindowSize();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IMyPackages[]>();
  const dispatch = useAppDispatch();
  const allRetrieveData: any = useAppSelector((state) => state.retrieve);

  const reuseAllData = (item) => {
    if (allRetrieveData?.isSuccess) {
      setReuseData(item);
    }
  };

  useEffect(() => {
    dispatch(retrieveTravelPackage(retrieveData));
  }, []);

  useEffect(() => {
    if (allRetrieveData?.isSuccess) {
      setData(allRetrieveData.data);
      setIsLoading(false);
    }
  }, [allRetrieveData]);

  return (
    <Row className="request-wrapper pt-0">
      <Col xs={12} className="request-form">
        {isLoading && (
          <Oval
            width="50"
            height="50"
            color="#fff"
            ariaLabel="three-dots-loading"
            wrapperStyle={{ display: "inline", marginLeft: "8px" }}
          />
        )}
        {data?.length !== 0 ? (
          data?.map((item, idx) => (
            <Card className="history-package-card-wrapper mb-4">
              <Card.Header className="card-dashboard-package-header">
                <Row>
                  <Col xs={12} className="text-center header-card-plane px-1">
                    <div>
                      <span>{item?.packagetypes}</span>
                    </div>
                    <div>
                      <span className="mb-0">
                        <img
                          src={PlaneIcon}
                          className="card-request-icon"
                          alt="location-img"
                        />{" "}
                        <span className="airline-box-title">
                          {item?.airlineName}
                        </span>
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "-30px" }}>
                  <Col xs={6} className="text-left header-card-titles">
                    <div>
                      <span className="text-left">{item?.fromCity}</span>
                    </div>
                    <div>
                      <span className="text-left">{item?.fromDate1}</span>
                    </div>
                  </Col>
                  <Col xs={6} className="header-card-titles">
                    <div className="text-right">
                      <span>{item?.toCity}</span>
                    </div>
                    <div className="text-right">
                      <span>{item?.fromDate2}</span>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="card-request-body pt-1">
                <Row>
                  <Col xs={12} className="request-body-info">
                    <div>
                      <span className="package-travel-card-id">
                        {t("PKGID")}: {item?.pkgId}
                      </span>
                    </div>
                    <div>
                      <span className="card-text">
                        {t("size")}: {item?.size}
                      </span>
                    </div>
                    <div>
                      <span className="card-text">
                        {t("weight")}: {item?.weight}
                      </span>
                    </div>
                    {/* <div>
                      <span className="card-text">
                        {t("itemValue")}: {item?.itemValue}
                      </span>
                    </div> */}
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col xs={7} className="request-body-date">
                    <div>
                      <span className="card-text">
                        {t("shippingDeadline")}:{" "}
                        <span className="card-text">
                          {item?.shippingDeadline}
                        </span>
                      </span>
                    </div>
                    <div></div>
                  </Col>
                  <Col xs={5} style={{ textAlign: "right" }}>
                    {/* <Button
                      variant="primary"
                      data-test="docs-btn-anchor"
                      className="dashboard-offer-btn"
                    >
                      {t("offer")}{" "}
                      <span className="d-block">
                        <strong>{item?.offers}</strong>
                      </span>
                    </Button> */}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className="card-history-footer">
                {/* <Row> */}
                {/* <Button variant="gray7" className="remove-history-btn">
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button variant="gray7" className="detail-history-btn">
                  Detail
                </Button> */}
                <Button
                  variant="warning"
                  data-test="docs-btn-anchor"
                  className="reuse-btn"
                  onClick={() => reuseAllData(item)}
                >
                  Reuse
                </Button>
                {/* </Row> */}
              </Card.Footer>
            </Card>
          ))
        ) : (
          <div className="no-data text-center">{t("noData")}</div>
        )}
      </Col>
    </Row>
  );
};
