import { createSlice } from "@reduxjs/toolkit";

export const chatCountSlice = createSlice({
  name: "chatCount",
  initialState: [],
  reducers: {
    chatCount: (state, action) => {
      return (state = action.payload);
    },
    chatCountFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { chatCount, chatCountFailure } = chatCountSlice.actions;
export default chatCountSlice.reducer;
