import { createSlice } from "@reduxjs/toolkit";

export const sendContactSlice = createSlice({
  name: "sendContact",
  initialState: [],
  reducers: {
    sendContact: (state, action) => {
      return (state = action.payload);
    },
    sendContactFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { sendContact, sendContactFailure } = sendContactSlice.actions;
export default sendContactSlice.reducer;
