import { createSlice } from "@reduxjs/toolkit";

export const searchByTrvIdSlice = createSlice({
  name: "searchByTrvId",
  initialState: [],
  reducers: {
    searchByTrvId: (state, action) => {
      return (state = action.payload);
    },
    searchByTrvIdFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { searchByTrvId, searchByTrvIdFailure } =
searchByTrvIdSlice.actions;
export default searchByTrvIdSlice.reducer;
