import React, { useState, useEffect } from "react";
import { Input, Button } from "layers";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/store";
import {
  addNewBankAccount,
  editBankAccount,
  getAllGateways,
  getAllBankAccounts,
} from "redux/actions/banks";
import { IAccount } from "models/interfaces";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProp {
  setIsOpen: Function;
  mode: string;
  data: IAccount;
  isOpen: boolean;
}

export const AddAccount: React.FC<IProp> = ({
  setIsOpen,
  mode,
  data,
  isOpen,
}) => {
  const [checked, setChecked] = useState(false);
  const { t } = useTranslation();
  const [valueChecked, setValueChecked] = useState("");
  const [gatewayId, setGatewayId] = useState("");
  const [accountId, setAccountId] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [accountData, setAccountData] = useState<IAccount>({
    gatewayId: "",
    holderName: "",
    gatewayName: "",
    number: "",
    email: "",
    swiftCode: "",
  });
  const allGateways: any = useAppSelector((state) => state.gateways);
  const addAccountData: any = useAppSelector((state) => state.addBankAccount);
  const editAccountData: any = useAppSelector((state) => state.editAccount);
  const dispatch = useAppDispatch();

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setAccountData({ ...accountData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (data && mode === "edit") {
      setAccountId(data?.id);
      setValueChecked(
        allGateways?.data?.find((option) => option.name === data.gatewayName).id
      );
      setAccountData({
        ...accountData,
        holderName: data.holderName,
        number: data.number,
        email: data.email,
        swiftCode: data.swiftCode,
      });
    }
  }, [data, mode, allGateways]);

  useEffect(() => {
    dispatch(getAllGateways());
  }, []);

  // const onGatewayChanged = (item) => {
  //   setGatewayId(item.id);
  //   setChecked(true);
  // };

  const onGatewayChanged = (e, item) => {
    setValueChecked(e.currentTarget.value);
    setGatewayId(item.id);
  };

  const addAccount = () => {
    document.documentElement.style.overflow = "hidden";
    setIsLoading(true);
    const data = {
      ...accountData,
      gatewayId,
    };
    dispatch(addNewBankAccount(data));
  };

  const editAccount = () => {
    document.documentElement.style.overflow = "hidden";
    setIsLoading(true);
    const data = {
      ...accountData,
      gatewayId,
      id: accountId,
    };
    dispatch(editBankAccount(data));
  };

  useEffect(() => {
    if (addAccountData.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      dispatch(getAllBankAccounts());
      document.documentElement.style.overflow = "auto";
    } else if (!addAccountData.isSuccess) {
      setIsLoading(false);
    }
  }, [addAccountData]);

  useEffect(() => {
    if (editAccountData.isSuccess) {
      setIsLoading(false);
      setIsOpen(false);
      dispatch(getAllBankAccounts());
      document.documentElement.style.overflow = "auto";
    } else if (!editAccountData.isSuccess) {
      setIsLoading(false);
    }
  }, [editAccountData]);

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="payment-slider-container">
      <Row className="sidebar-content-wrapper">
        <Button
          variant="white"
          className="close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
        <Col xs={12} className="payment-form">
          <h1>{mode === "add" ? "Add Account" : "Edit Account"}</h1>
        </Col>
        <Col xs={12} className="payment-form">
          <div className="gateways-radio-wrapper">
            <form>
              {allGateways.data?.map((item) => {
                return (
                  <label>
                    <input
                      // type="radio"
                      // name="radio"
                      // value={item.id}
                      // // checked={checked}
                      // onClick={() => onGatewayChanged(item)}

                      type="radio"
                      name="site_name"
                      value={item.id}
                      checked={valueChecked === item.id}
                      onChange={(e) => onGatewayChanged(e, item)}
                    />
                    <span>{item.name}</span>
                  </label>
                );
              })}
            </form>
          </div>
        </Col>
        <Col xs={6} className="payment-form pr-0">
          <div className="profile-box-container">
            <Input
              size="sm"
              id="holderName"
              name="holderName"
              placeholder="Account Holder Name"
              className="custom-account-input"
              type="text"
              value={accountData.holderName}
              onChange={handleChange}
            />
          </div>
        </Col>
        <Col xs={6} className="payment-form pl-0">
          <div className="profile-box-container">
            <Input
              size="sm"
              id="number"
              name="number"
              placeholder="Account Number"
              className="custom-account-input-2th"
              type="text"
              value={accountData.number}
              onChange={handleChange}
            />
          </div>
        </Col>
        <Col xs={6} className="payment-form pr-0">
          <div className="profile-box-container">
            <Input
              size="sm"
              id="email"
              name="email"
              placeholder={t("email")}
              className="custom-account-input text-left pl-2"
              type="text"
              value={accountData.email}
              onChange={handleChange}
            />
          </div>
        </Col>
        <Col xs={6} className="payment-form pl-0">
          <div className="profile-box-container">
            <Input
              size="sm"
              id="swiftCode"
              name="swiftCode"
              placeholder="Swift Code"
              className="custom-account-input-2th"
              type="text"
              value={accountData.swiftCode}
              onChange={handleChange}
            />
          </div>
        </Col>

        <Col
          xs={12}
          className="payment-form text-right"
          style={{ marginTop: "24px" }}
        >
          <Button
            variant="primary"
            className="add-new-request-btn"
            onClick={mode === "add" ? addAccount : editAccount}
          >
            {mode === "add" ? t("addNew") : t("edit")}
            {isLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
