import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "layers";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getAllDashboardData } from "redux/actions/dashboard";
import { getAllBankAccounts } from "redux/actions/banks";
import { getUserInfo } from "redux/actions/Authorization";
import { Oval } from "react-loader-spinner";
import { deleteUserTravel, deleteUserPackage } from "redux/actions/dashboard";
import { deleteBankAccount } from "redux/actions/banks";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface IProps {
  isOpen: boolean;
  setIsOpen: (key: any) => void;
  setIsConfirmed: (key: any) => void;
  title: string;
  description: string;
  trvId?: string;
  pkgId?: string;
  type: string;
}

export const Confirmation: React.FC<IProps> = ({
  setIsConfirmed,
  isOpen,
  setIsOpen,
  title,
  description,
  type,
  trvId,
  pkgId,
}) => {
  const screenSize = UseWindowSize();
  const dispatch = useAppDispatch();
  const handleClose = () => setIsOpen(false);
  let deletedTravel: any = useAppSelector((state) => state.deleteTravel);
  const deletedPackage: any = useAppSelector((state) => state.deletePackage);
  const deletedAccount: any = useAppSelector((state) => state.deleteAccount);
  const updatedUser: any = useAppSelector((state) => state.editUserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const clearResultsTravel = () => {
    dispatch(deleteUserTravel(trvId));
  };
  const clearResultsPackage = () => {
    dispatch(deleteUserPackage(pkgId));
  };
  const clearResultsAccount = () => {
    dispatch(deleteBankAccount(""));
  };

  useEffect(() => {
    if (type === "travel" && deletedTravel.isSuccess) {
      setIsOpen(false);
      setIsLoading(false);
      deletedTravel = [];
      clearResultsTravel();
      window.location.reload();
      // dispatch(getAllDashboardData());
    } else if (!deletedTravel.isSuccess) {
      setIsLoading(false);
      // toast.error(deletedTravel?.message);
    }

    if (type === "package" && deletedPackage.isSuccess) {
      setIsOpen(false);
      setIsLoading(false);
      clearResultsPackage();
      window.location.reload();
      // dispatch(getAllDashboardData());
    } else if (!deletedPackage.isSuccess) {
      setIsLoading(false);
      // toast.error(deletedTravel?.message);
    }

    if (type === "account" && deletedAccount.isSuccess) {
      setIsOpen(false);
      setIsLoading(false);
      clearResultsAccount();
      dispatch(getAllBankAccounts());
    } else if (!deletedAccount.isSuccess) {
      setIsLoading(false);
    }
  }, [deletedTravel, deletedPackage, deletedAccount, type]);

  useEffect(() => {
    if (type === "updateProfile" && updatedUser?.isSuccess) {
      setIsOpen(false);
      setIsLoading(false);
      window.localStorage.setItem("token", updatedUser?.data?.accessToken);
      window.localStorage.setItem(
        "refreshToken",
        updatedUser?.data?.refreshToken
      );
      window.location.reload();
      // dispatch(getUserInfo());
    } else if (updatedUser?.isSuccess === false) {
      setIsLoading(false);
      setIsConfirmed(false);
    }
  }, [updatedUser, type]);

  const confirmDeleteBtn = () => {
    setIsLoading(true);
    setIsConfirmed(true);
  };

  // useEffect(() => {
  //   if (isOpen === false) setIsConfirmed(false);
  // }, [isOpen]);

  return (
    <>
      <Modal
        className="remove-modal-wrapper"
        show={isOpen}
        backdrop="static"
        onHide={handleClose}
      >
        <Modal.Body>
          <div className="close-modal-btn-wrapper">
            <a onClick={handleClose}>
              <FontAwesomeIcon icon={faClose} />
            </a>
          </div>
          <span className="enter-travel-information">{title}</span>
          <Row>
            <Col xs={12}>
              <span className="remove-desc-title">{description}</span>
            </Col>
          </Row>
          <Button
            variant="warning"
            data-test="docs-btn-anchor"
            onClick={confirmDeleteBtn}
            className="confirm-remove-btn"
          >
            {t("confirm")}
            {isLoading && (
              <Oval
                width="20"
                height="20"
                color="#fff"
                ariaLabel="three-dots-loading"
                wrapperStyle={{ display: "inline", marginLeft: "8px" }}
              />
            )}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
