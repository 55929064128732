import { createSlice } from "@reduxjs/toolkit";

export const createOfferSlice = createSlice({
  name: "createOffer",
  initialState: [],
  reducers: {
    createOffer: (state, action) => {
      return (state = action.payload);
    },
    createOfferFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { createOffer, createOfferFailure } = createOfferSlice.actions;
export default createOfferSlice.reducer;
