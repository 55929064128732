import TypesService from "services/typesService";
import {
  getPackagesType,
  packagesTypeFailure,
} from "redux/slices/types/packagesType";
import {
  getWeightRanges,
  weightRangeFailure,
} from "redux/slices/types/weightRange";
import {
  getDeliveryType,
  deliveryTypeFailure,
} from "redux/slices/types/deliveryType";
import { getSizeRanges, sizeRangeFailure } from "redux/slices/types/sizeRange";
import { currency, currencyFailure } from "redux/slices/types/currencyTypes";
import { service, serviceFailure } from "redux/slices/types/services";
import { expenseType, expenseTypeFailure } from "redux/slices/types/expenseType";
import { agreementTerm, agreementTermFailure } from "redux/slices/types/agreementterms";
import { allCountries, allCountriesFailure } from "redux/slices/types/allCountries";
import {
  cancellationPolicy,
  cancellationPolicyFailure,
} from "redux/slices/types/cancellationPolicy";
import {
  paymentMethod,
  paymentMethodFailure,
} from "redux/slices/types/paymentMethod";
// import { toast } from "react-toastify";

export const getAllDeliveryType = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllDeliveryType();
    dispatch(getDeliveryType(res.data));
  } catch (err) {
    dispatch(deliveryTypeFailure(err?.response));
    // toast.error(err?.response?.data?.message);
  }
};

export const getAllPackagesType = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllPackagesType();
    dispatch(getPackagesType(res.data));
  } catch (err) {
    dispatch(packagesTypeFailure(err?.response));
  }
};

export const getAllWeightRange = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllWeightRange();
    dispatch(getWeightRanges(res.data));
  } catch (err) {
    dispatch(weightRangeFailure(err?.response));
  }
};

export const getAllSizeRange = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllSizeRange();
    dispatch(getSizeRanges(res.data));
  } catch (err) {
    dispatch(sizeRangeFailure(err?.response));
  }
};

export const getAllPaymentMethod = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllPaymentMethod();
    dispatch(paymentMethod(res.data));
  } catch (err) {
    dispatch(paymentMethodFailure(err?.response));
  }
};

export const getAllCountriesList = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllCountries();
    dispatch(allCountries(res.data));
  } catch (err) {
    dispatch(allCountriesFailure(err?.response));
  }
};

export const getAllCancellationPolicy = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllCancellationPolicy();
    dispatch(cancellationPolicy(res.data));
  } catch (err) {
    dispatch(cancellationPolicyFailure(err?.response));
  }
};

export const getAllCurrencies = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllCurrencies();
    dispatch(currency(res.data));
  } catch (err) {
    dispatch(currencyFailure(err?.response));
  }
};

// export const getAllServices = () => async (dispatch) => {
//   try {
//     const res = await TypesService.getAllServices();
//     dispatch(service(res.data));
//   } catch (err) {
//     dispatch(serviceFailure(err?.response));
//   }
// };

export const getAllExpenses = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllExpenses();
    dispatch(expenseType(res.data));
  } catch (err) {
    dispatch(expenseTypeFailure(err?.response));
  }
};

export const getAllAgreementTerms = () => async (dispatch) => {
  try {
    const res = await TypesService.getAllAgreementTerms();
    dispatch(agreementTerm(res.data));
  } catch (err) {
    dispatch(expenseTypeFailure(err?.response));
  }
};
