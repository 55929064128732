import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Input, Button } from "layers";
import { ICompanionAgreement } from "models/interfaces";

interface IProp {
  travelingCompanions: ICompanionAgreement[];
  setTravelingCompanions: Function;
  disabled: boolean;
}

export const AddTravelingCompanion: React.FC<IProp> = ({
  travelingCompanions,
  setTravelingCompanions,
  disabled,
}) => {
  function getTextSize(txt, font) {
    let element = document.createElement("canvas");
    let context = element.getContext("2d");
    context.font = font;
    return context.measureText(txt).width;
  }

  const [isFirstName, setIsFirstName] = useState(false);
  const [isLastName, setIsLastName] = useState(false);
  const [isGender, setIsGender] = useState(false);
  const [isAge, setIsAge] = useState(false);
  const [isCellPhoneNumber, setIsCellPhoneNumber] = useState(false);

  useEffect(() => {
    if (travelingCompanions) {
      for (let idx = 0; idx < travelingCompanions?.length; idx++) {
        if (isFirstName === false) adjustInputSize("", `firstName${idx}`);
        if (isLastName === false) adjustInputSize("", `lastName${idx}`);
        if (isGender === false) adjustInputSize("", `gender${idx}`);
        if (isAge === false) adjustInputSize("", `age${idx}`);
        if (isCellPhoneNumber === false)
          adjustInputSize("", `cellPhoneNumber${idx}`);
      }
    }
  }, [travelingCompanions]);

  const adjustInputSize = (text, id) => {
    const nameLocation = document.getElementById(id);
    if (text?.length < 1) {
      nameLocation.style.width =
        getTextSize(nameLocation.ariaLabel, "16px sans-serif") + 10 + "px";
    } else {
      nameLocation.style.width =
        getTextSize(text, "16px sans-serif") + 10 + "px";
    }
  };

  const handleShareholderFirstNameChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newTravelingCompanion = travelingCompanions?.map(
      (shareholder, sidx) => {
        if (idx !== sidx) return shareholder;
        return { ...shareholder, firstName: evt.target.value };
      }
    );
    setIsFirstName(true);
    setTravelingCompanions(newTravelingCompanion);
  };

  const handleShareholderLastNameChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newTravelingCompanion = travelingCompanions?.map(
      (shareholder, sidx) => {
        if (idx !== sidx) return shareholder;
        return { ...shareholder, lastName: evt.target.value };
      }
    );
    setIsLastName(true);
    setTravelingCompanions(newTravelingCompanion);
  };

  const handleShareholderGenderChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newTravelingCompanion = travelingCompanions?.map(
      (shareholder, sidx) => {
        if (idx !== sidx) return shareholder;
        return { ...shareholder, gender: evt.target.value };
      }
    );
    setIsGender(true);
    setTravelingCompanions(newTravelingCompanion);
  };

  const handleShareholderAgeChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newTravelingCompanion = travelingCompanions?.map(
      (shareholder, sidx) => {
        if (idx !== sidx) return shareholder;
        return { ...shareholder, age: evt.target.value };
      }
    );
    setIsAge(true);
    setTravelingCompanions(newTravelingCompanion);
  };

  const handleShareholderPhoneChange = (idx) => (evt) => {
    adjustInputSize(evt.target.value, evt.target.name);
    const newTravelingCompanion = travelingCompanions?.map(
      (shareholder, sidx) => {
        if (idx !== sidx) return shareholder;
        return { ...shareholder, cellPhoneNumber: evt.target.value };
      }
    );
    setIsCellPhoneNumber(true);
    setTravelingCompanions(newTravelingCompanion);
  };

  const handleAddShareholder = () => {
    setTravelingCompanions(
      travelingCompanions.concat([
        {
          firstName: "",
          lastName: "",
          gender: "",
          age: "",
          cellPhoneNumber: "",
        },
      ])
    );
  };

  const handleRemoveShareholder = (idx) => () => {
    setTravelingCompanions(
      travelingCompanions.filter((s, sidx) => idx !== sidx)
    );
  };

  const preventTypeText = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <p className="contract-content">- Traveling Companion:</p>
      <form className="w-100">
        {travelingCompanions?.map((shareholder, idx) => (
          <p className="contract-content" key="idx">
            - First Name:{" "}
            <Input
              id={`firstName${idx}`}
              name={`firstName${idx}`}
              ariaLabel={
                shareholder.firstName
                  ? `${shareholder.firstName}`
                  : "------------"
              }
              placeholder={
                shareholder.firstName
                  ? `${shareholder.firstName}`
                  : "------------"
              }
              className="custom-input-contract"
              value={shareholder.firstName}
              onChange={handleShareholderFirstNameChange(idx)}
              disabled={disabled}
            />
            - Last Name:{" "}
            <Input
              id={`lastName${idx}`}
              name={`lastName${idx}`}
              ariaLabel={
                shareholder.lastName
                  ? `${shareholder.lastName}`
                  : "------------"
              }
              placeholder={
                shareholder.lastName
                  ? `${shareholder.lastName}`
                  : "------------"
              }
              className="custom-input-contract"
              value={shareholder.lastName}
              onChange={handleShareholderLastNameChange(idx)}
              disabled={disabled}
            />
            - Gender: Male/Female:{" "}
            <Input
              size="sm"
              id={`gender${idx}`}
              name={`gender${idx}`}
              ariaLabel={
                shareholder.gender ? `${shareholder.gender}` : "------------"
              }
              placeholder={
                shareholder.gender ? `${shareholder.gender}` : "------------"
              }
              className="custom-input-contract"
              value={shareholder.gender}
              onChange={handleShareholderGenderChange(idx)}
              disabled={disabled}
            />
            - Age:{" "}
            <Input
              id={`age${idx}`}
              name={`age${idx}`}
              ariaLabel={
                shareholder.age ? `${shareholder.age}` : "------------"
              }
              placeholder={
                shareholder.age ? `${shareholder.age}` : "------------"
              }
              className="custom-input-contract"
              value={shareholder.age}
              onChange={handleShareholderAgeChange(idx)}
              onKeyPress={preventTypeText}
              disabled={disabled}
              // value={contractData.age}
              // onChange={handleChange}
            />
            - Cell Phone no:{" "}
            <Input
              id={`cellPhoneNumber${idx}`}
              name={`cellPhoneNumber${idx}`}
              ariaLabel={
                shareholder.cellPhoneNumber ? `${shareholder.cellPhoneNumber}` : "---------------"
              }
              placeholder={
                shareholder.cellPhoneNumber ? `${shareholder.cellPhoneNumber}` : "---------------"
              }
              className="custom-input-contract mr-4"
              value={shareholder.cellPhoneNumber}
              onChange={handleShareholderPhoneChange(idx)}
              // value={contractData.travelingCompanionPhone}
              onKeyPress={preventTypeText}
              disabled={disabled}
              // onChange={handleChange}
            />
            {!disabled &&
              (idx === 0 ? (
                <Button
                  variant="white"
                  onClick={handleAddShareholder}
                  className="add-companion-btn ml-auto"
                >
                  +
                </Button>
              ) : (
                <Button
                  variant="white"
                  onClick={handleRemoveShareholder(idx)}
                  className="remove-companion-btn ml-auto"
                >
                  -
                </Button>
              ))}
          </p>
        ))}
      </form>
    </>
  );
};
