import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import AttachIcon from "assets/images/attach.png";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { Gallery } from "react-photoswipe-gallery";
import { LightBoxItem } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from "layers";
import { deletePackageImage, deleteTravelImage } from "redux/actions/files";
import { useAppDispatch, useAppSelector } from "redux/store";
import { getPackage, getTravel } from "redux/actions/dashboard";

interface IProp {
  title: string;
  image?: any;
  setPhotos?: any;
  description: any;
  disabled: boolean;
  hasRemove?: boolean;
  width: string;
  height?: string;
  type?: string;
  pkgId?: string;
  trvId?: string;
}

export const MultipleUploader: React.FC<IProp> = ({
  title,
  image,
  setPhotos,
  description,
  disabled,
  width,
  height,
  type,
  hasRemove,
  pkgId,
  trvId,
}) => {
  const { isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: { "image/*": [] },
    multiple: true,
    maxFiles: 10,
  });
  const dispatch = useAppDispatch();
  const windowSize = UseWindowSize();
  const [imgUrlRemoved, setImgUrlRemoved] = useState(false);
  const deleteTravelImgData = useAppSelector<any>(
    (state) => state.deleteTravelImg
  );
  const deletePackageImgData = useAppSelector<any>(
    (state) => state.deletePackageImg
  );

  const baseStyle = {
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#d6d6d6",
    borderStyle: "solid",
    height: "174px",
    margin: "0 0 29px",
    padding: "60px 30px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    lineHeight: "150px",
    position: "relative",
  };
  const customBaseStyle = {
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#d6d6d6",
    borderStyle: "solid",
    height: "100%",
    margin: "0 0 29px",
    padding: "60px 30px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    lineHeight: "150px",
    position: "relative",
  };

  const defaultHeight = "174px";

  const focusedStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#00e676",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  } as React.CSSProperties;

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    // marginBottom: 8,
    // marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  } as React.CSSProperties;

  const removeThumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  } as React.CSSProperties;

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  } as React.CSSProperties;

  const img = {
    display: "block",
    width: "100%",
    height: "fit-content",
  } as React.CSSProperties;

  const [defaultImage, setDefaultImage] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        setPhotos((prevState: any) => [...prevState, file]);
        setFiles((prevState: any) => [...prevState, file]);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".png"],
      // "image/*": [],
    },
    multiple: true,
    maxFiles: 10,
    onDrop: onDrop,
    disabled: disabled,
  });

  const style = useMemo(
    () => ({
      ...(height ? customBaseStyle : baseStyle),
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  ) as React.CSSProperties;

  useEffect(() => {
    if (image?.length !== 0) {
      setDefaultImage(image.filter((item) => !(item instanceof File)));
    }
  }, [image]);

  const onLoadImage = (file) => {
    URL.revokeObjectURL(file?.preview);
  };

  const removeImage = (photo: any) => {
    setImgUrlRemoved(photo);
    const packageData = {
      pkgId: pkgId,
      imageUrl: photo,
    };
    const travelData = {
      trvId: trvId,
      imageUrl: photo,
    };
    if (pkgId) dispatch(deletePackageImage(packageData));
    if (trvId) dispatch(deleteTravelImage(travelData));
  };

  useEffect(() => {
    if (deleteTravelImgData?.isSuccess) {
      setDefaultImage((prevState: any[]) =>
        prevState.filter((image) => image !== imgUrlRemoved)
      );
      if (trvId) dispatch(getTravel(trvId));
    }
  }, [deleteTravelImgData, imgUrlRemoved]);

  useEffect(() => {
    if (deletePackageImgData?.isSuccess) {
      setDefaultImage((prevState: any[]) =>
        prevState.filter((image) => image !== imgUrlRemoved)
      );
      if (pkgId) dispatch(getPackage(pkgId));
    }
  }, [deletePackageImgData, imgUrlRemoved]);

  const removeFile = (index: number) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
    setPhotos([...defaultImage, ...updatedFiles]);
  };

  return (
    <>
      {title && <span className="multi-upload-title">{title}</span>}
      {!disabled && (
        <div
          {...getRootProps({ style })}
          className={windowSize.width < 768 ? "w-100" : width}
        >
          <input {...getInputProps()} />
          <p className="multi-thumb-title">{description}</p>
          <img src={AttachIcon} className="attach-icon" alt="attach-img" />
        </div>
      )}
      <div>
        <aside style={thumbsContainer}>
          <Gallery>
            {defaultImage?.length !== 0 &&
              defaultImage.map((photo, idx) => {
                return (
                  <div className="image-gallery-wrapper" key={idx}>
                    {hasRemove && (
                      <a
                        onClick={() => removeImage(photo)}
                        className="remove-img-dropzone"
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </a>
                    )}
                    <div style={hasRemove ? removeThumb : thumb}>
                      <div style={thumbInner}>
                        <LightBoxItem
                          original={photo}
                          thumbnail={photo}
                          width="auto"
                          height="auto"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </Gallery>
          <Gallery>
            {files?.length !== 0 &&
              files.map((file, idx) => {
                return (
                  <div className="image-gallery-wrapper" key={idx}>
                    {hasRemove && (
                      <a
                        className="remove-img-dropzone"
                        onClick={() => removeFile(idx)}
                      >
                        <FontAwesomeIcon icon={faClose} />
                      </a>
                    )}
                    <div style={hasRemove ? removeThumb : thumb}>
                      <div style={thumbInner}>
                        {/* <img
                          src={URL.createObjectURL(file)}
                          style={img}
                          alt="preview"
                          onLoad={() => onLoadImage(file)}
                        /> */}
                        <LightBoxItem
                          original={URL.createObjectURL(file)}
                          thumbnail={URL.createObjectURL(file)}
                          width="auto"
                          height="auto"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </Gallery>
        </aside>
      </div>
    </>
  );
};
