import axios from "axios";

export const searchLocationApi = (flight) => {
  const flights = axios.post(
    process.env.REACT_APP_BASE_URL + `Flight/Search`,
    { text: flight ? flight : "a" },
    {
      headers: {
        "Accept-Language": localStorage.getItem("language"),
        Currency: localStorage.getItem("currency"),
        "Time-Zone": localStorage.getItem("timezone"),
      },
    }
  );
  return flights;
};

export const searchLocation = (flight) => {
  const flights = searchLocationApi(flight);
  if (flights) {
    return flights.then((response) => {
      return response?.data?.data?.map((item) => ({
        value: item.id,
        countryLabel: item.countryName,
        cityLabel: item.cityName,
        airportLabel: item.airportName,
        label: (
          <>
            {/* <div>
              <span>{`${item?.airportName}${" "}(${item?.airportCode})`}</span>
            </div> */}
            <div>
              <span>{`${item?.cityName}${" "},${item?.countryName}`}</span>
            </div>
          </>
        ),
      }));
    });
  }
};

export const searchAirportLocation = (flight) => {
  const flights = searchLocationApi(flight);
  if (flights) {
    return flights.then((response) => {
      return response?.data?.data?.map((item) => ({
        value: item.id,
        countryLabel: item.countryName,
        cityLabel: item.cityName,
        airportLabel: item.airportName,
        label: (
          <>
            <div>
              {/* <span className="mr-2">{`${item?.airportName}${" "}(${item?.airportCode})`},</span> */}

              <span>{`${item?.cityName}${" "},${item?.countryName}`}</span>
            </div>
          </>
        ),
      }));
    });
  }
};
