import { createSlice } from "@reduxjs/toolkit";

export const passportVerificationSlice = createSlice({
  name: "passportVerification",
  initialState: [],
  reducers: {
    passportVerification: (state, action) => {
      return (state = action.payload);
    },
    passportVerificationFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { passportVerification, passportVerificationFailure } =
  passportVerificationSlice.actions;
export default passportVerificationSlice.reducer;
