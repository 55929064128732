import checkEmail from "helpers/checkEmail";

export const isValidFirstName = (firstName) => firstName !== "";

export const isValidLastName = (lastName) => lastName !== "";

export const isValidCountry = (country) => country !== "" && country !== null;
export const isValidAdrs = (address) => address !== "";
export const isValidState = (state) => state !== "";
export const isValidCity = (city) => city !== "";
export const isValidPostalCode = (postalCode) => postalCode !== "";

export const isValidPhoneNumber = (phoneNumber) => phoneNumber !== "";
export const isValidPhoneNumberLength = (phoneNumber) =>
  phoneNumber?.length === 10;

export const isValidEmail = (email) => email !== "";

export const isValidFormatEmail = (email) => {
  if (email !== "" && !checkEmail(email)) return false;
  return true;
};

export const isMatchPasswords = (rePassword, password) => {
  if (
    (rePassword !== "" && password == "") ||
    (password !== "" && rePassword == "") ||
    (rePassword !== "" && rePassword !== password)
  )
    return false;
  return true;
};

export const isPatternPasswordValid = (password) => {
  if (password !== "") {
    const regex1 = /[A-Z]/;
    const regex2 = /[@$!%?&]/;
    const regex3 = /\d/;
    const regex4 = /.{8,}/;
    const regex5 = /[a-z]/;
    if (
      regex1.test(password) &&
      regex2.test(password) &&
      regex3.test(password) &&
      regex4.test(password) &&
      regex5.test(password)
    )
      return true;
  }
  return true;
};

export const isPatternPhoneValid = (phoneNumber, code) => {
  const phone = code + phoneNumber;
  if (phoneNumber !== "" && code !== "") {
    const phonePattern = /^\+[1-9]{1}[0-9]{8,14}$/;
    if (phonePattern.test(phone)) return true;
    return false;
  }
  return true;
};

export const isValid = (checked, data, code) => {
  return (
    checked &&
    isValidFirstName(data.firstName) &&
    isValidLastName(data.lastName) &&
    isPatternPhoneValid(data.phoneNumberWithoutCode, code) &&
    isValidPhoneNumber(data.phoneNumberWithoutCode) &&
    // isValidPhoneNumberLength(data.phoneNumberWithoutCode) &&
    isValidEmail(data.email) &&
    isValidFormatEmail(data.email) &&
    isPatternPasswordValid(data.password) &&
    isMatchPasswords(data.rePassword, data.password)
  );
};

export const isValidAddress = (data, country) => {
  return (
    isValidCountry(country) &&
    isValidAdrs(data.address) &&
    isValidState(data.state) &&
    isValidCity(data.city) &&
    isValidPostalCode(data.postalCode)
  );
};
