import { createSlice } from "@reduxjs/toolkit";

export const changeButtonPaymentStatusRequestSlice = createSlice({
  name: "changeButtonPaymentStatusRequest",
  initialState: [],
  reducers: {
    changeButtonPaymentStatusRequest: (state, action) => {
      return (state = action.payload);
    },
    changeButtonPaymentStatusRequestFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { changeButtonPaymentStatusRequest, changeButtonPaymentStatusRequestFailure } = changeButtonPaymentStatusRequestSlice.actions;
export default changeButtonPaymentStatusRequestSlice.reducer;