import { createSlice } from "@reduxjs/toolkit";

export const travelerReasonCancellationSlice = createSlice({
  name: "travelerReasonCancellation",
  initialState: [],
  reducers: {
    travelerReasonCancellation: (state, action) => {
      return (state = action.payload);
    },
    travelerReasonCancellationFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { travelerReasonCancellation, travelerReasonCancellationFailure } =
travelerReasonCancellationSlice.actions;
export default travelerReasonCancellationSlice.reducer;
