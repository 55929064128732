import React, { useState, useEffect } from "react";
import { Col, Row, Modal } from "react-bootstrap";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useAppDispatch, useAppSelector } from "redux/store";
import { useTranslation } from "react-i18next";
import {
  getAllHomeRequestFilter,
  getAllHomeTravelFilter,
} from "redux/actions/flight";
import { Button, Input } from "layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { applyFilterByTypeId } from "redux/actions/landing";

interface IProp {
  type: string;
  isOpen: boolean;
  setIsOpen: Function;
}

export const MobileFilter: React.FC<IProp> = ({ type, setIsOpen }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const [count, setCount] = useState(0);
  const [typeFilter, setTypeFilter] = useState(null);
  const [size, setSize] = useState(null);
  const [policy, setPolicy] = useState(null);
  const [payment, setPayment] = useState(null);
  const [weight, setWeight] = useState(null);
  const [deliveryType, setDeliveryType] = useState(null);
  const [typeId, setTypeId] = useState("");

  const packagesType = useAppSelector((state) => state.packageTypes);
  const paymentMethod = useAppSelector((state) => state.paymentMethod);
  const sizeRanges = useAppSelector((state) => state.sizeRange);
  const policies = useAppSelector((state) => state.cancellationPolicy);
  const weightRanges = useAppSelector((state) => state.weightRange);
  const deliveryTypes = useAppSelector((state) => state.deliveryType);
  const alertsCount: any = useAppSelector((state) => state.fareAlertCount);
  const filterById: any = useAppSelector((state) => state.filterByTypeId);
  const packageData = {
    type: "package",
    packagetypeIds: null,
    weightrangeIds: null,
    deliverytypeIds: null,
    sizerangeIds: null,
    paymentmethodIds: null,
    page: 1,
  };
  const travelData = {
    type: "travel",
    packagetypeIds: null,
    weightrangeIds: null,
    deliverytypeIds: null,
    sizerangeIds: null,
    paymentmethodIds: null,
    cancellationpolicyIds: null,
    userLevelIds: null,
    page: 1,
  };
  
  useEffect(() => {
    if (alertsCount?.isSuccess && type === "package") {
      setCount(alertsCount.data.packageCount);
    }
    if (alertsCount?.isSuccess && type === "travel") {
      setCount(alertsCount.data.travelCount);
    }
  }, [alertsCount, type]);

  const handleTypeChange = (e) => {
    setTypeFilter(e);
  };

  const handleSizeChange = (e) => {
    setSize(e);
  };

  const handlePolicyChange = (e) => {
    setPolicy(e);
  };

  const handleWeightChange = (e) => {
    setWeight(e);
  };

  const handleDeliveryTypesChange = (e) => {
    setDeliveryType(e);
  };

  const handlePaymentChange = (e) => {
    setPayment(e);
  };

  const handleChangeClose = () => {
    setIsOpen(false);
  };

  const removeFilters = () => {
    if (type === "package") {
      setTypeFilter(null);
      setSize(null);
      setWeight(null);
      setDeliveryType(null);
    } else if (type === "travel") {
      setTypeFilter(null);
      setSize(null);
      setWeight(null);
      setPayment(null);
      setPolicy(null);
      setDeliveryType(null);
    }
  };

  const applyFilters = () => {
    const packageData = {
      type: "package",
      packagetypeIds: typeFilter ? typeFilter : null,
      weightrangeIds: weight ? weight : null,
      deliverytypeIds: deliveryType ? deliveryType : null,
      sizerangeIds: size ? size : null,
      paymentmethodIds: payment ? payment : null,
      page: 1,
    };
    const travelData = {
      type: "travel",
      packagetypeIds: typeFilter ? typeFilter : null,
      weightrangeIds: weight ? weight : null,
      deliverytypeIds: deliveryType ? deliveryType : null,
      sizerangeIds: size ? size : null,
      paymentmethodIds: payment ? payment : null,
      cancellationpolicyIds: policy ? policy : null,
      userLevelIds: null,
      page: 1,
    };

    setTimeout(() => {
      if (type === "package") dispatch(getAllHomeRequestFilter(packageData));
      else if (type === "travel") dispatch(getAllHomeTravelFilter(travelData));
      setIsOpen(false);
    }, 1000);
  };

  const searchByTypeId = (e) => {
    const data = {
      type: type,
      typeId: e.target.value,
    };
    setTypeId(e.target.value);
    if (e.target.value !== "") dispatch(applyFilterByTypeId(data));
    if (type === "package" && e.target.value === "")
      dispatch(getAllHomeRequestFilter(packageData));
    else if (type === "travel" && e.target.value === "")
      dispatch(getAllHomeTravelFilter(travelData));
  };

  return (
    <div className="filter-container">
      <div className="text-left pb-4">
        <Button
          variant="white"
          className="close-filter-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </div>
      <div className={`d-flex ${isPersian ? "dir-rtl" : "dir-ltr"}`}>
        <span className="screen-filter-title">{t("filters")}</span>
        <a className="screen-filter-remove" onClick={removeFilters}>
          {t("removeFilters")}
        </a>
      </div>
      <div className="filter-info-wrapper w-100">
        <Button className="w-100 mt-3 mb-4" onClick={applyFilters}>
          {t("applyFilters")}
        </Button>
        <Row className="responsive-height-filter">
          <Col xs={12}>
            <Input
              size="sm"
              id="search"
              name="search"
              placeholder={`${
                type === "travel" ? t("searchTrvId") : t("searchPkgId")
              } `}
              className="search-id-filter"
              type="text"
              onChange={searchByTypeId}
            />
          </Col>
          <Col xs={12}>
            <div className="w-100 mr-2 mt-3">
              {/* {showTypeName && (
                <span className="filters-select-title">{t("type")}</span>
              )} */}
              {/* <Select
                placeholder={t("type")}
                className="custom-select-filter-type d-inline-block w-100"
                value={typeFilter}
                onChange={handleTypeChange}
                options={typeOptions}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={customStyle}
              /> */}
              <div>
                <span className="filter-items-title">{t("type")}</span>
                {packagesType?.map((item) => (
                  <a
                    className={`filter-items-label ${
                      typeFilter === item.id ? "selected-filter" : ""
                    }`}
                    onClick={() => {
                      handleTypeChange(item.id);
                    }}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="w-100 mr-2 mt-3">
              <div>
                <span className="filter-items-title">{t("size")}</span>
                {sizeRanges?.map((item) => (
                  <a
                    className={`filter-items-label ${
                      size === item.id ? "selected-filter" : ""
                    }`}
                    onClick={() => {
                      handleSizeChange(item.id);
                    }}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="w-100 mr-2 mt-3">
              <div>
                <span className="filter-items-title">{t("weight")}</span>
                {weightRanges?.map((item) => (
                  <a
                    className={`filter-items-label ${
                      weight === item.id ? "selected-filter" : ""
                    }`}
                    onClick={() => {
                      handleWeightChange(item.id);
                    }}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <div className="w-100 mr-2 mt-3">
              <div>
                <span className="filter-items-title">{t("services")}</span>
                {deliveryTypes?.map((item) => (
                  <a
                    className={`filter-items-label ${
                      deliveryType === item.id ? "selected-filter" : ""
                    }`}
                    onClick={() => {
                      handleDeliveryTypesChange(item.id);
                    }}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Col>
          <Col xs={12}>
            {type === "travel" && (
              <div className="w-100 mr-2 mt-3">
                <div>
                  <span className="filter-items-title">
                    {t("paymentMethod")}
                  </span>
                  {paymentMethod?.map((item) => (
                    <a
                      className={`filter-items-label ${
                        payment === item.id ? "selected-filter" : ""
                      }`}
                      onClick={() => {
                        handlePaymentChange(item.id);
                      }}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </Col>
          <Col xs={12}>
            {type === "travel" && (
              <div className="w-100 mr-2 mt-3">
                <div>
                  <span className="filter-items-title">
                    {t("cancellationPolicy")}
                  </span>
                  {policies?.map((item) => (
                    <a
                      className={`filter-items-label ${
                        policy === item.id ? "selected-filter" : ""
                      }`}
                      onClick={() => {
                        handlePolicyChange(item.id);
                      }}
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
