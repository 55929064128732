import React, { useState, useEffect, KeyboardEvent } from "react";
import { Input, Button, RightSidebar } from "layers";
import { Register } from "components";
import { Col, Row } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
// import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { gapi } from "gapi-script";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { loginUser, externalLoginUser } from "redux/actions/Authorization";
import { ILogin, IExternalLogin } from "models/interfaces";
import { useTranslation } from "react-i18next";
import {
  osVersion,
  osName,
  browserName,
  browserVersion,
} from "react-device-detect";
import { useAppDispatch, useAppSelector } from "redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ForgetPassword } from "components/forgetPassword";
import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const clientId =
  "165924336796-1o4rjbggsh4ph9qu8m5qnauvsn5ge2rn.apps.googleusercontent.com";

interface IProp {
  setIsOpen: Function;
  setIsLogin: Function;
  isOpen: boolean;
}

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: () => any;
  }
}

export const Login: React.FC<IProp> = ({ setIsOpen, setIsLogin, isOpen }) => {
  const size = UseWindowSize();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const navigate = useNavigate();
  // const data = useAppSelector(showLoginResult);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(true);
  const [isForgetPasswordOpen, setIsForgetPasswordOpen] = useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState("");
  const [facebookAccessToken, setFacebookAccessToken] = useState("");
  const [googleImage, setGoogleImage] = useState("");
  const [deviceModel, setDeviceModel] = useState("");
  const [user, setUser] = useState<any>();
  const [thirdPartyResponse, setThirdPartyResponse] = useState({
    firstName: "",
    lastName: "",
    avatar: "",
    email: "",
  });
  const data = useAppSelector<any>((state) => state.login);
  const checkTokenData = useAppSelector((state) => state.checkToken);
  const [loginData, setLoginData] = useState<ILogin>({
    grantType: "password",
    username: "",
    password: "",
    clientId: "517D58DC-95A5-4732-B182-2188A9853CF5",
    clientSecret: "QVWglh6wamKIEyI8kdSlWsD/gNTUpYKdC4GjTw/zFibEcBWH5Djoyw==",
    deviceModel: "",
    deviceId: "",
    playerId: "",
    userLevel: "",
    userLevelHexColor: "",
  });
  useEffect(() => {
    setDeviceModel(`${browserName}-${browserVersion}(${osName}${osVersion})`);
  }, []);

  const openForgetPasswordSidebar = () => {
    setIsForgetPasswordOpen(true);
  };

  const onLoginClick = () => {
    window.FB.login(
      function (res) {
        if (res.authResponse) {
          setFacebookAccessToken(res.authResponse.accessToken);

          window.FB.api(
            "/me",
            { fields: "name, email, picture" },
            function (response) {
              const name = response.name.split(" ");
              // setThirdPartyResponse({
              //   email: response.email,
              //   firstName: name[0],
              //   lastName: name[1] ? name[1] : null,
              //   avatar: response.picture.data.url,
              // });
              const data: IExternalLogin = {
                provider: res?.authResponse?.graphDomain,
                accessToken: res.authResponse.accessToken,
                email: response?.email,
                firstName: name[0] ? name[0] : null,
                lastName: name[1] ? name[1] : null,
                deviceModel,
                deviceId: "",
                playerId: "",
              };
              dispatch(externalLoginUser(data));
            }
          );
        }
      },
      { scope: "email" }
    );
  };

  const loadAsyncInit = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: "598976655336817",
        autoLogAppEvents: true,
        xfbml: false,
        status: true,
        cookie: true,
        version: "v15.0",
      });
      window.FB.getLoginStatus(function (response) {
        if (response.status === "connected") {
          setFacebookAccessToken(response.authResponse.accessToken);
        }
      });
    };
  };

  const handlePopState = (event: PopStateEvent) => {
    setIsOpen(false);
    document.documentElement.style.overflow = "auto";
  };

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        const target = e.target as HTMLTextAreaElement;
        const element = target.className;
        if (element === "bm-overlay") {
          document.documentElement.style.overflow = "auto";
          navigate("/");
        }
      },
      false
    );
  }, []);

  const onFailure = (res) => {
    console.log("[Login Failed] res:", res);
  };

  const responseFacebook = (response) => {
    loadAsyncInit();
    // const data: IExternalLogin = {
    //   provider: response?.graphDomain,
    //   accessToken: facebookAccessToken,
    //   email: response?.email,
    //   deviceModel,
    //   deviceId: "",
    //   playerId: "",
    // };
    // dispatch(externalLoginUser(data));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  const login = () => {
    document.documentElement.style.overflow = "hidden";
    setIsLoading(true);
    const data = {
      ...loginData,
      deviceModel,
    };

    dispatch(loginUser(data));
  };

  const handleKeywordKeyPress = (e: KeyboardEvent<HTMLImageElement>) => {
    if (e.key == "Enter") {
      login();
    }
  };

  useEffect(() => {
    if (data?.isSuccess === true) {
      document.documentElement.style.overflow = "auto";
      window.localStorage.setItem("token", data?.data?.accessToken);
      window.localStorage.setItem("avatar", data?.data?.personalPhoto);
      window.localStorage.setItem("expire", data?.data?.expiresIn);
      window.localStorage.setItem(
        "timestampExpire",
        data?.data?.expiresInTimnestamp
      );
      window.localStorage.setItem("refreshToken", data?.data?.refreshToken);
      window.localStorage.setItem("tokenType", data?.data?.tokenType);
      window.localStorage.setItem("userLevel", data?.data?.userLevel);
      window.localStorage.setItem(
        "userLevelHexColor",
        data?.data?.userLevelHexColor
      );
      navigate("/");
      window.location.reload();
      setIsLoading(false);
      setIsOpen(false);
      window.dispatchEvent(new Event("storage"));
    } else if (data?.data?.isSuccess === false) {
      setIsLoading(false);
      navigate("/");
      // setIsOpen(false);
    }
  }, [data]);

  useEffect(() => {
    if (checkTokenData?.data?.accessToken) {
      if (
        checkTokenData?.data?.isValid &&
        !checkTokenData?.data?.isRegistered
      ) {
        setShowLoginForm(false);
        document.documentElement.style.overflow = "auto";
        window.dispatchEvent(new Event("storage"));
        window.localStorage.setItem("token", checkTokenData?.data?.accessToken);
        window.localStorage.setItem(
          "avatar",
          checkTokenData?.data?.personalPhoto
            ? checkTokenData?.data?.personalPhoto
            : googleImage
        );
        window.localStorage.setItem("expire", checkTokenData?.data?.expiresIn);
        window.localStorage.setItem(
          "userLevel",
          checkTokenData?.data?.userLevel
        );
        window.localStorage.setItem(
          "userLevelHexColor",
          checkTokenData?.data?.userLevelHexColor
        );
        window.localStorage.setItem(
          "timestampExpire",
          checkTokenData?.data?.expiresInTimnestamp
        );
        window.localStorage.setItem(
          "refreshToken",
          data?.data?.data?.refreshToken
        );
        window.localStorage.setItem(
          "tokenType",
          checkTokenData?.data?.tokenType
        );
        setIsLoading(false);
        setIsOpen(false);
        // setIsLogin(true);
        navigate("/settings");
        localStorage.setItem("tab", "userInfo");
      } else {
        document.documentElement.style.overflow = "auto";
        window.dispatchEvent(new Event("storage"));
        window.localStorage.setItem("token", checkTokenData?.data?.accessToken);
        window.localStorage.setItem(
          "avatar",
          checkTokenData?.data?.personalPhoto
            ? checkTokenData?.data?.personalPhoto
            : googleImage
        );
        window.localStorage.setItem("expire", checkTokenData?.data?.expiresIn);
        window.localStorage.setItem(
          "userLevel",
          checkTokenData?.data?.userLevel
        );
        window.localStorage.setItem(
          "userLevelHexColor",
          checkTokenData?.data?.userLevelHexColor
        );
        window.localStorage.setItem(
          "timestampExpire",
          checkTokenData?.data?.expiresInTimnestamp
        );
        window.localStorage.setItem(
          "refreshToken",
          checkTokenData?.data?.refreshToken
        );
        window.localStorage.setItem(
          "tokenType",
          checkTokenData?.data?.tokenType
        );
        navigate("/");
        window.location.reload();
        setIsLoading(false);
        setIsOpen(false);
        // setIsLogin(true);
      }
    }
  }, [checkTokenData]);

  const handleChangeClose = () => {
    setIsOpen(!isOpen);
    document.documentElement.style.overflow = "auto";
    navigate("/");
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
    // flow: "auth-code",
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setGoogleImage(res.data.picture);
          const data: IExternalLogin = {
            provider: "google",
            accessToken: user.access_token,
            email: res.data.email,
            firstName: res.data.given_name ? res.data.given_name : null,
            lastName: res.data.family_name ? res.data.family_name : null,
            deviceModel,
            deviceId: "",
            playerId: "",
          };
          dispatch(externalLoginUser(data));
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  return (
    <>
      {isForgetPasswordOpen ? (
        // <RightSidebar
        //   isOpen={isForgetPasswordOpen}
        //   setIsOpen={setIsForgetPasswordOpen}
        //   setIsLogin={setIsLogin}
        //   sidebarType="forgetPassword"
        // />
        <ForgetPassword
          setIsOpen={setIsForgetPasswordOpen}
          setIsLogin={setIsLogin}
          isOpen={isForgetPasswordOpen}
        />
      ) : (
        <div className="login-slider-container">
          <Row>
            <Button
              variant="white"
              className="login-close-sidebar-btn"
              onClick={handleChangeClose}
            >
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </Row>
          {showLoginForm && (
            <Row className="login-wrapper">
              <Col className="login-form" lg={5} md={5} xs={12}>
                <h1>{t("login")}</h1>
                <Input
                  size="sm"
                  id="email-input"
                  placeholder={t("emailAddress")}
                  className="custom-input-login"
                  name="username"
                  value={loginData.username}
                  onChange={handleChange}
                  onKeyPress={handleKeywordKeyPress}
                />
                <Input
                  size="sm"
                  id="password-input"
                  placeholder={t("password")}
                  className="custom-input-login mt-4 text-left"
                  name="password"
                  type="password"
                  value={loginData.password}
                  onChange={handleChange}
                  onKeyPress={handleKeywordKeyPress}
                />
                <Button
                  variant="primary"
                  data-test="docs-btn-anchor"
                  className="login-btn mt-4"
                  onClick={login}
                >
                  {t("login")}
                  {isLoading && (
                    <Oval
                      width="20"
                      height="20"
                      color="#fff"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                    />
                  )}
                </Button>
              </Col>
              {size.width >= 768 && (
                <Col xs={2} className="right-line-wrapper pr-1">
                  <div className="right-line-separate-login">
                    <span>{t("or")}</span>
                  </div>
                </Col>
              )}
              <Col
                className={`login-via-form ${
                  size.width < 768 ? "pl-3 mt-5" : "pl-0"
                }`}
                lg={5}
                md={5}
                xs={12}
              >
                <h1>{t("SigninWith")}</h1>
                {size.width < 768 ? (
                  <Row>
                    {/* <Col xs={6}>
                      <FacebookLogin
                        appId="598976655336817"
                        autoLoad={false}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        cssClass="facebook-btn"
                        textButton="Facebook"
                        onClick={onLoginClick}
                      />
                    </Col> */}
                    <Col xs={12}>
                      <Button
                        onClick={() => loginWithGoogle()}
                        variant="danger"
                        className="google-btn"
                      >
                        {t("google")}
                      </Button>
                      {/* <GoogleLogin
                        clientId={clientId}
                        render={(renderProps) => (
                          <Button
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            variant="danger"
                            className="google-btn"
                          >
                            {t("google")}
                          </Button>
                        )}
                        buttonText="Login"
                        onSuccess={onSuccess}
                        onFailure={onFailure}
                        prompt="select_account"
                        cookiePolicy={"single_host_origin"}
                      /> */}
                    </Col>
                  </Row>
                ) : (
                  <>
                    <FacebookLogin
                      appId="598976655336817"
                      autoLoad={false}
                      fields="name,email,picture"
                      callback={responseFacebook}
                      cssClass="facebook-btn"
                      textButton="Facebook"
                      onClick={onLoginClick}
                    />
                    {/* <button onClick={onLoginClick}>facebook</button> */}
                    {/* <GoogleLogin
                      clientId={clientId}
                      render={(renderProps) => (
                        <Button
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          variant="danger"
                          className="google-btn mt-4"
                        >
                          {t("google")}
                        </Button>
                      )}
                      buttonText="Login"
                      onSuccess={onSuccess}
                      onFailure={onFailure}
                      prompt="select_account"
                      cookiePolicy={"single_host_origin"}
                    /> */}
                    <Button
                      onClick={() => loginWithGoogle()}
                      variant="danger"
                      className="google-btn mt-4"
                    >
                      {t("google")}
                    </Button>
                  </>
                )}
                {isPersian ? (
                  <span className="mt-4 d-block forget-password-text">
                    رمز عبور خود را فراموش کرده اید؟ {" "}
                    <a
                      className="cursor-pointer"
                      style={{ color: "#3a47ff" }}
                      onClick={openForgetPasswordSidebar}
                    >
                      اینجا {" "}
                    </a>
                    را کلیک کنید.
                  </span>
                ) : (
                  <span className="mt-4 d-block forget-password-text">
                    Forgot Password? Click{" "}
                    <a
                      className="cursor-pointer"
                      style={{ color: "#3a47ff" }}
                      onClick={openForgetPasswordSidebar}
                    >
                      here
                    </a>
                  </span>
                )}
              </Col>
            </Row>
          )}

          <div className="bottom-line-separate-login"></div>
          <Register
            deviceModel={deviceModel}
            // thirdPartyResponse={thirdPartyResponse}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            setIsLogin={setIsLogin}
          />
        </div>
      )}
    </>
  );
};
