import { BaseService } from "./baseService";

class TypesService extends BaseService{
  getAllPackagesType = () => {
    return this.http().get("Packagetype");
  };

  getAllWeightRange = () => {
    return this.http().get("Weightrange");
  };

  getAllDeliveryType = () => {
    return this.http().get("Deliverytype");
  };

  getAllCountries = () => {
    return this.http().get("Country");
  };

  getAllSizeRange = () => {
    return this.http().get("Sizerange");
  };

  getAllAgreementTerms = () => {
    return this.http().get("Agreementterm");
  };

  getAllExpenses = () => {
    return this.http().get("Expense");
  };

  getAllCurrencies = () => {
    return this.currencyHttp().get("Currency");
  };

  getAllCancellationPolicy = () => {
    return this.currencyHttp().get("Cancellationpolicy");
  };
  
  getAllPaymentMethod = () => {
    return this.currencyHttp().get("Paymentmethod");
  };
}

export default new TypesService();
