import AuthorizationService from "services/authorizationService";
import { login, loginFailure } from "redux/slices/Authorization/login";
import { refresh, refreshFailure } from "redux/slices/Authorization/refresh";
import {
  isValidToken,
  isValidTokenFailure,
} from "redux/slices/Authorization/checkToken";
import { logout } from "redux/slices/Authorization/logout";
import {
  editUserInfo,
  editUserInfoFailure,
} from "redux/slices/Authorization/editUserInfo";
import {
  checkToken,
  checkTokenFailure,
} from "redux/slices/Authorization/externalLogin";
import {
  createUser,
  createUserFailure,
} from "redux/slices/Authorization/register";
import {
  getUserProfile,
  profileFailure,
} from "redux/slices/Authorization/userInfo";
import {
  verifyPhoneNo,
  verifyPhoneNoFailure,
} from "redux/slices/Authorization/verifyNumber";
import {
  uploadFile,
  uploadFileFailure,
} from "redux/slices/Authorization/uploadFile";
import {
  verificationCode,
  verificationCodeFailure,
} from "redux/slices/Authorization/sendVerificationCode";
import {
  validationToken,
  validationTokenFailure,
} from "redux/slices/Authorization/validationToken";
import { otpCode, otpCodeFailure } from "redux/slices/Authorization/otpCode";
import {
  forgetPass,
  forgetPassFailure,
} from "redux/slices/Authorization/forgetPassword";
import { toast } from "react-toastify";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

// function isTokenExpired() {
//   return apiTimestamp && currentTimestamp + tolerance >= apiTimestamp;
// }

export const loginUser = (data) => async (dispatch) => {
  try {
    const res = await AuthorizationService.login(data);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    else toast.error(res?.data?.message);
    dispatch(login(res.data));
  } catch (err) {
    dispatch(loginFailure(err?.response));
  }
};

export const forgotPassword = (data) => async (dispatch) => {
  try {
    const res = await AuthorizationService.forgetPassword(data);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    else toast.error(res?.data?.message);
    dispatch(forgetPass(res.data));
  } catch (err) {
    dispatch(forgetPassFailure(err?.response));
  }
};

export const refreshToken = (data) => async (dispatch) => {
  try {
    const res = await AuthorizationService.refresh(data);
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    else toast.error(res?.data?.message);
    dispatch(refresh(res.data));
  } catch (err) {
    dispatch(refreshFailure(err?.response));
  }
};

// export const checkValidationToken = (data) => async (dispatch) => {
//   try {
//     const res = await AuthorizationService.checkValidationToken(data);
//     if (res?.data?.isSuccess) toast.success(res?.data?.message);
//     else toast.error(res?.data?.message);
//     dispatch(isValidToken(res.data));
//   } catch (err) {
//     dispatch(isValidTokenFailure(err?.response));
//     toast.error(err?.response?.data?.message);
//   }
// };

export const logoutUser = (data) => async (dispatch) => {
  try {
    const res = await AuthorizationService.logout(data);
    dispatch(logout(res.data));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(logout(err?.response));
    // toast.error(err?.response?.data?.message);
  }
};

export const externalLoginUser = (data) => async (dispatch) => {
  try {
    const res = await AuthorizationService.checkToken(data);
    if (
      res?.data?.data?.isRegistered === false &&
      res?.data?.data?.isValid === false
    ) {
      toast.error("The entered information is incorrect");
    } else if (
      res?.data?.data?.isRegistered === false &&
      res?.data?.data?.isValid === true
    ) {
      dispatch(checkToken(res.data));
    } else {
      dispatch(checkToken(res?.data));
    }
  } catch (err) {
    dispatch(checkTokenFailure(err?.response));
  }
};

export const sendVerifyCode = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AuthorizationService.sendVerificationCode(data);
    dispatch(verificationCode(res.data));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(verificationCodeFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const verifyPhoneNumber = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AuthorizationService.verifyPhoneNumber(data);
    if (res?.data?.isSuccess) {
      toast.success(res?.data?.message);
    } else toast.error(res?.data?.message);
    dispatch(verifyPhoneNo(res.data));
  } catch (err) {
    dispatch(verifyPhoneNoFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};
export const uploadUserIdentity = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AuthorizationService.uploadUserIdentity(data);
    if (res?.data?.isSuccess) {
      toast.success(res?.data?.message);
    } else toast.error(res?.data?.message);
    dispatch(uploadFile(res.data));
  } catch (err) {
    dispatch(uploadFileFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const createNewUser = (data) => async (dispatch) => {
  try {
    const res = await AuthorizationService.createUser(data);
    dispatch(createUser(res.data));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(createUserFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getVerificationOtpCode = (data) => async (dispatch) => {
  try {
    const res = await AuthorizationService.getOtpCode(data);
    dispatch(otpCode(res.data));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(otpCodeFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const checkTokenValidation = (data) => async (dispatch) => {
  try {
    const res = await AuthorizationService.validToken(data);
    if (res?.data?.isSuccess) dispatch(validationToken(res.data));
    if (!res?.data?.isSuccess) toast.warn("You should be logged in");
  } catch (err) {
    dispatch(validationTokenFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const getUserInfo = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AuthorizationService.getUserInfo();
    dispatch(getUserProfile(res.data));
  } catch (err) {
    dispatch(profileFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};

export const modifyUserInfo = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await AuthorizationService.editUserInfo(data);
    dispatch(editUserInfo(res.data));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(editUserInfoFailure(err?.response));
    toast.error(err?.response?.data?.message);
  }
};
