import ReminderService from "services/reminderService";
import {
  allFareAlerts,
  allFareAlertsFailure,
} from "redux/slices/fareAlert/getFareAlert";
import {
  deleteFareAlert,
  deleteFareAlertFailure,
} from "redux/slices/fareAlert/removeFareAlert";
import {
  setFareAlert,
  setFareAlertFailure,
} from "redux/slices/fareAlert/setFareAlert";
import {
  fareAlertCount,
  fareAlertCountFailure,
} from "redux/slices/fareAlert/fareAlertCount";
import {
  changeStatusFareAlert,
  changeStatusFareAlertFailure,
} from "redux/slices/fareAlert/changeStatusFareAlert";
import { toast } from "react-toastify";
import { refreshTokenFunc } from "helpers/refreshTokenFunc";
import { isTokenExpired } from "helpers/checkTokenExpired";

export const getAllFareAlerts = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ReminderService.getAllFareAlerts(data);
    dispatch(allFareAlerts(res));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(allFareAlertsFailure(err?.response));
  }
};

export const getFareAlertsCount = () => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ReminderService.getFareAlertCount();
    dispatch(fareAlertCount(res));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(fareAlertCountFailure(err?.response));
  }
};

export const setNewFareAlert = (data) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ReminderService.setFareAlerts(data);
    dispatch(setFareAlert(res));
    if (!res?.data?.isSuccess) toast.error(res?.data?.message);
  } catch (err) {
    dispatch(setFareAlertFailure(err?.response));
  }
};

export const removeFareAlert = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ReminderService.removeFareAlert(id);
    dispatch(deleteFareAlert(res));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(deleteFareAlertFailure(err?.response));
  }
};

export const changeFareAlertStatus = (id) => async (dispatch) => {
  try {
    if (isTokenExpired()) await refreshTokenFunc();
    const res = await ReminderService.changeStatusFareAlert(id);
    dispatch(changeStatusFareAlert(res));
    if (res?.data?.isSuccess) toast.success(res?.data?.message);
  } catch (err) {
    dispatch(changeStatusFareAlertFailure(err?.response));
  }
};
