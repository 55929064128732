import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Header, Footer, Button } from "layers";
import { useTranslation } from "react-i18next";
import { getAllCurrencies } from "redux/actions/types";
import { useAppDispatch } from "redux/store";

export const VisionMission: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllCurrencies());
  }, []);

  return (
    <div className="d-flex flex-column min-vh-100">
      <Header />
      <div className="title-dashboard">
        <h2>{t("ourVisionAndMission")}</h2>
      </div>
      <div className="flex-grow-1 services-pages-wrapper my-5">
        <h3 className="mt-5">{t("ourVision")}</h3>
        <p className="mt-4 mb-2">{t("visionDesc")}</p>
        <h3 className="mt-5">{t("ourMission")}</h3>
        <p className="mt-4 mb-2">{t("missionTitle")}:</p>
        <ul>
          <li className="ml-3 mt-3">
            <span className="works-bullet-circle"></span>
            <strong>{t("missionTitle1")}:</strong> {t("missionDesc1")}
          </li>
          <li className="ml-3 mt-3">
            <span className="works-bullet-circle"></span>
            <strong>{t("missionTitle2")}:</strong> {t("missionDesc2")}
          </li>
          <li className="ml-3 mt-3">
            <span className="works-bullet-circle"></span>
            <strong>{t("missionTitle3")}:</strong> {t("missionDesc3")}
          </li>
          <li className="ml-3 mt-3">
            <span className="works-bullet-circle"></span>
            <strong>{t("missionTitle4")}:</strong> {t("missionDesc4")}
          </li>
          <li className="ml-3 mt-3">
            <span className="works-bullet-circle"></span>
            <strong>{t("missionTitle5")}:</strong> {t("missionDesc5")}
          </li>
        </ul>
        <p className="mt-4 mb-2">{t("missionLastDesc")}</p>
      </div>
      <Footer />
    </div>
  );
};
