import { createSlice } from "@reduxjs/toolkit";

export const allTransactionsSlice = createSlice({
  name: "allTransactions",
  initialState: [],
  reducers: {
    allTransactions: (state, action) => {
      return (state = action.payload);
    },
    allTransactionsFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { allTransactions, allTransactionsFailure } = allTransactionsSlice.actions;
export default allTransactionsSlice.reducer;
