import { createSlice } from "@reduxjs/toolkit";

export const deleteTravelImgSlice = createSlice({
  name: "deleteTravelImg",
  initialState: [],
  reducers: {
    deleteTravelImg: (state, action) => {
      return (state = action.payload);
    },
    deleteTravelImgFailure: (state, action) => {
      return action.payload;
    },
  },
});

export const { deleteTravelImg, deleteTravelImgFailure } =
  deleteTravelImgSlice.actions;
export default deleteTravelImgSlice.reducer;

