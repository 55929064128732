import React, { useState, useEffect, useRef, KeyboardEvent } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faPaperPlane,
  faPaperclip,
} from "@fortawesome/free-solid-svg-icons";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { toast } from "react-toastify";
import * as signalR from "@microsoft/signalr";
import { useAppSelector } from "redux/store";
import { Message, User } from "models/interfaces";
import NoMessage from "assets/images/no-message-2.png";
import PlaneIcon from "../../assets/images/plane.png";
import PdfIcon from "../../assets/images/pdf.png";
import DocsIcon from "../../assets/images/docs.png";
import { useTranslation } from "react-i18next";

interface IProps {
  latestMessages: Message[];
  typeId: string;
  roomId: string;
  showTyping: boolean;
  packageData: User;
}

const MultiChatList: React.FC<IProps> = ({
  latestMessages,
  typeId,
  showTyping,
  roomId,
  packageData,
}) => {
  const windowSize = UseWindowSize();
  const lang = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "fa";
  const isPersian = lang === "fa";
  const isMobile = windowSize.width < 768;
  const [newMessage, setNewMessage] = useState<string>("");
  const [newMsg, setNewMsg] = useState<Message>();
  const [connection, setConnection] = useState<any>();
  const [users, setUsers] = useState<User[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [attachedFile, setAttachedFile] = useState<File | null>(null);
  const [base64File, setFile] = useState<any>(null);
  const [thumbnail, setThumbnail] = useState<string | null>(null);
  const accessToken = window.localStorage.getItem("token");
  const refreshToken = window.localStorage.getItem("refreshToken");
  const chatWrapperRef = useRef<HTMLDivElement>(null);
  const userData = useAppSelector(
    (state) => state?.userInfo?.data && state?.userInfo?.data[0]?.data
  );
  const { t } = useTranslation();
  const chatWrapper = document.getElementById("chat-wrapper");
  const onMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
    setHasFile(false);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    async function joinRoom() {
      try {
        const connection = new signalR.HubConnectionBuilder()
          .withUrl("https://api.koolbaar.com/chathub")
          .build();

        connection.serverTimeoutInMilliseconds = 300000;
        connection.keepAliveIntervalInMilliseconds = 300000;

        connection.on(
          "ReceiveMessage",
          (
            message,
            time,
            isMine,
            isReadByReceiver,
            messageSenderPersonalPhoto,
            requestedUserName
          ) => {
            const newMes: Message = {
              message: message,
              time: time,
              isMine: isMine,
              isReadByReceiver: isReadByReceiver,
              personalPhoto: messageSenderPersonalPhoto,
              requestedUserName: requestedUserName,
            };
            setNewMsg(newMes);
          }
        );

        await connection.start();
        setConnection(connection);
      } catch (error) {}
    }

    joinRoom();
  }, [typeId]);

  useEffect(() => {
    if (newMsg) setMessages([...messages, newMsg]);
  }, [newMsg]);

  useEffect(() => {
    if (connection)
      connection
        .invoke("StartChat", accessToken, refreshToken, typeId, roomId)
        .then((res) => {
          if (latestMessages) setMessages(latestMessages);
          scrollToBottom();
        })
        .catch((error: Error) => {
          console.error("Error sending data:", error);
        });
  }, [connection]);

  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setFile(reader.result);
    };
    reader.onerror = function (error) {};
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file?.size > 10 * 1024 * 1024) {
      toast.warn("File size should be less than 10MB");
    } else {
      setAttachedFile(file);
      getBase64(file);
      setHasFile(true);
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setThumbnail(reader.result as string);
        };
        reader.readAsDataURL(file);
      } else if (file && file.type.startsWith("application/pdf")) {
        setThumbnail(PdfIcon);
      } else if (file && file.type.startsWith("application/vnd")) {
        setThumbnail(DocsIcon);
      } else {
        setThumbnail(null);
      }
    }
  };

  const handleKeywordKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") {
      hasFile ? handleSendFile() : handleSendMessage();
    }
  };

  const scrollToBottom = () => {
    if (chatWrapperRef.current) {
      chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
    }
  };

  async function handleSendMessage() {
    if (typeId) {
      setAttachedFile(null);
      setThumbnail(null);
      setNewMessage("");
      scrollToBottom();
      try {
        await connection.invoke(
          "SendMessage",
          accessToken,
          refreshToken,
          typeId,
          newMessage,
          roomId
        );
      } catch (error) {}
    }
  }

  const renderMessageContent = (message: string) => {
    const fileExtensions = {
      docs: [".doc", ".docx"],
      pdf: [".pdf"],
      image: [".jpg", ".jpeg", ".png", ".gif"],
    };

    const isLink = (url: string) => {
      try {
        new URL(url);
        return true;
      } catch {
        return false;
      }
    };

    const getFileExtension = (url: string) => {
      return url.split(".").pop()?.toLowerCase() || "";
    };

    const handleDownload = (url: string) => {
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/").pop() || "download";
      link.target = "_blank";
      link.click();
    };

    if (isLink(message)) {
      const extension = getFileExtension(message);
      if (fileExtensions.docs.includes(`.${extension}`)) {
        return (
          <div
            onClick={() => handleDownload(message)}
            className="file-icon"
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: 70, height: 70 }}
              src={DocsIcon}
              alt="Docs-icon"
            />{" "}
            <span className="filename-text mx-3">
              {message.substring(message.lastIndexOf("/") + 1)}
            </span>
          </div>
        );
      } else if (fileExtensions.pdf.includes(`.${extension}`)) {
        return (
          <div
            onClick={() => handleDownload(message)}
            className="file-icon"
            style={{ cursor: "pointer" }}
          >
            <img
              style={{ width: 70, height: 70 }}
              src={PdfIcon}
              alt="Pdf-icon"
            />
            <span className="filename-text">
              {" "}
              {message.substring(message.lastIndexOf("/") + 1)}
            </span>
          </div>
        );
      } else if (fileExtensions.image.includes(`.${extension}`)) {
        return (
          <>
            <img
              src={message}
              alt="content"
              className="image-content"
              onClick={() => handleDownload(message)}
              style={{ cursor: "pointer", width: 140, height: 140 }}
            />{" "}
            <span className="filename-text">
              {message.substring(message.lastIndexOf("/") + 1)}
            </span>
          </>
        );
      }
    }

    return <span>{message}</span>;
  };

  async function handleSendFile() {
    if (typeId) {
      setAttachedFile(null);
      setFile(null);
      setThumbnail(null);
      setNewMessage("");
      scrollToBottom();

      try {
        await connection.invoke(
          "SendFile",
          accessToken,
          refreshToken,
          typeId,
          base64File,
          roomId
        );
      } catch (error) {}
    }
  }

  const CustomChatHeader = (user) => {
    return (
      <div
        className="py-3 px-4"
        style={{
          background: "#dfdfdf",
          margin: "auto",
          width: "98%",
          borderRadius: 10,
        }}
      >
        <Row>
          <Col xs={12} className="text-center header-card-plane">
            <div>
              {/* <span className="fw-bold">{packageData?.packagetypes}</span> */}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={4} className="text-left header-card-titles pr-0">
            <div>
              <span className="text-left">{packageData?.fromCountryCity}</span>
            </div>
          </Col>
          <Col xs={4} className="text-center header-card-plane p-0">
            <div>
              <span className="mb-0">
                ...{" "}
                <img
                  src={PlaneIcon}
                  className="card-request-icon mt-1"
                  alt="location-img"
                />{" "}
                ...
              </span>
            </div>
          </Col>
          <Col
            xs={4}
            className="header-card-titles pl-0"
            // style={{ marginTop: "-5px" }}
          >
            <div className="text-right">
              <span>{packageData?.toCountryCity}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={5} className="text-left header-card-titles pr-0">
            <div></div>
            <div className="mt-2">
              <span className="text-left">
                {t("sender")}: <strong>{packageData?.senderFullName}</strong>
              </span>
            </div>
          </Col>
          <Col xs={2}></Col>
          <Col xs={5} className="header-card-titles pl-0">
            <div className="text-right"></div>
            <div className="text-right mt-2">
              <span>
                {t("traveler")}:{" "}
                <strong>{packageData?.travelerFullName}</strong>
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Col
      sm={12}
      md={12}
      lg={7}
      className={`chat-window-bg ${isMobile ? "pt-2" : "pt-5"}`}
    >
      <CustomChatHeader />
      <div
        className="chat-window text-center"
        id="chat-wrapper"
        ref={chatWrapperRef}
      >
        {messages && messages?.length !== 0 ? (
          messages?.map((msg, index) => (
            <div className="mb-4" key={index}>
              <div
                className={`d-flex ${
                  userData?.email === msg.requestedUserName
                    ? "dir-ltr"
                    : "dir-rtl"
                }`}
              >
                <Image
                  src={
                    userData?.email === msg.requestedUserName
                      ? window.localStorage.getItem("avatar")
                      : msg?.personalPhoto
                  }
                  roundedCircle
                  className="avatar-chat"
                />
                <div
                  key={index}
                  className={`message ${
                    userData?.email === msg.requestedUserName
                      ? "sent"
                      : "received"
                  }`}
                >
                  <div className="message-content">
                    {msg?.message ? renderMessageContent(msg.message) : null}
                  </div>
                </div>
              </div>
              <span
                className={`time-chat ${
                  userData?.email === msg.requestedUserName
                    ? "text-right"
                    : "text-left"
                }`}
              >
                {msg?.time}
                {msg.isReadByReceiver &&
                  userData?.email === msg.requestedUserName && (
                    <span>
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faCheck}
                        color="#5b7ecd"
                      />
                      <FontAwesomeIcon
                        style={{ marginLeft: "-4px" }}
                        color="#5b7ecd"
                        icon={faCheck}
                      />
                    </span>
                  )}
              </span>
            </div>
          ))
        ) : (
          <>
            <img
              width="300px"
              className="pt-3"
              src={NoMessage}
              alt="no-message"
            />
            <p className="no-message-yet">No Message Yet</p>
          </>
        )}
      </div>
      {showTyping && (
        <div
          className={`message-input d-flex align-items-center ${
            isMobile ? "m-2" : "m-3"
          }`}
        >
          <Image
            src={window.localStorage.getItem("avatar")}
            roundedCircle
            className="avatar"
          />
          {!hasFile && (
            <input
              autoFocus
              style={{ width: "400px" }}
              placeholder="Type message..."
              className="custom-send-message"
              value={newMessage}
              onChange={onMessageChange}
              onKeyDown={handleKeywordKeyPress}
            />
          )}

          <div className="ml-auto d-flex" style={{ alignItems: "baseline" }}>
            {thumbnail && (
              <div className="thumbnail-preview">
                <img
                  style={{ width: 100, height: 100, padding: 15 }}
                  src={thumbnail}
                  alt="Thumbnail"
                />
              </div>
            )}
            <div className="file-input-container">
              <label className="file-input-label">
                <FontAwesomeIcon
                  className="mr-3 ml-2 chat-icons"
                  color="#d6d6d6"
                  icon={faPaperclip}
                />
                <input
                  className="ml-auto"
                  type="file"
                  onChange={handleFileChange}
                  accept=".jpg,.jpeg,.png,.gif,.pdf,.doc,.docx"
                />
              </label>
            </div>
            <a onClick={hasFile ? handleSendFile : handleSendMessage}>
              <FontAwesomeIcon
                className="chat-icons"
                icon={faPaperPlane}
                color="#5b7ecd"
              />
            </a>
          </div>
        </div>
      )}
    </Col>
  );
};

export default MultiChatList;
