let isRefreshing = false;
let refreshPromise;

export async function refreshTokenFunc() {
  if (isRefreshing) {
    return refreshPromise;
  }

  isRefreshing = true;

  var raw = JSON.stringify({
    accessToken: window.localStorage.getItem("token"),
    refreshToken: window.localStorage.getItem("refreshToken"),
    clientId: "517D58DC-95A5-4732-B182-2188A9853CF5",
    clientSecret: "QVWglh6wamKIEyI8kdSlWsD/gNTUpYKdC4GjTw/zFibEcBWH5Djoyw==",
    deviceModel: "Windows Edge",
    deviceId: "",
    playerId: "",
  });

  var myHeaders = new Headers();
  myHeaders.append("Accept-Language", localStorage.getItem("language"));
  myHeaders.append("Currency", localStorage.getItem("currency"));
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Time-Zone", localStorage.getItem("timezone"));

  const requestOptions: any = {
    method: "POST",
    headers: myHeaders,
    body: raw,
  };

  refreshPromise = fetch(`${process.env.REACT_APP_BASE_URL}refresh`, requestOptions)
    .then(async (response) => {
      const data = await response.json();
      if (data?.data) {
        // Update local storage and dispatch event here
        window.localStorage.setItem("token", data?.data?.accessToken);
        window.localStorage.setItem("avatar", data?.data?.personalPhoto);
        window.localStorage.setItem("expire", data?.data?.expiresIn);
        window.localStorage.setItem("userLevel", data?.data?.userLevel);
        window.localStorage.setItem(
          "userLevelHexColor",
          data?.data?.userLevelHexColor
        );
        window.localStorage.setItem(
          "timestampExpire",
          data?.data?.expiresInTimnestamp
        );
        window.localStorage.setItem("refreshToken", data?.data?.refreshToken);
        window.localStorage.setItem("tokenType", data?.data?.tokenType);
        window.dispatchEvent(new Event("storage"));
      }
    })
    .catch((error) => {
    })
    .finally(() => {
      isRefreshing = false;
    });

  return refreshPromise;
}
