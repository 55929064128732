import React, { useState, useEffect } from "react";
import { Tabs, ManualFlightTabOne, ManualFlightTabTwo } from "components";
import { TabOne } from "./TabOne";
import { TabTwo } from "./TabTwo";
import TravelerIcon from "../../assets/images/svg/traveler.svg";
import BaggageIcon from "../../assets/images/svg/baggage.svg";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Wallpaper from "../../assets/images/christmas-wallpaper.png";

interface IProps {
  onSelectTab: (key: any) => void;
  onSelectSidebarTab: (key: any) => void;
  isChangedTab: boolean;
}

type TabsType = {
  label: string;
  index: number;
  svg: string;
  Component: React.FC<{}>;
}[];

export const FlightInfo: React.FC<IProps> = ({
  onSelectTab,
  onSelectSidebarTab,
  isChangedTab,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const tabs: TabsType = [
    {
      label: t("iAmATraveler"),
      svg: TravelerIcon,
      index: 1,
      // Component: TabOne,
      Component: ManualFlightTabOne,
    },
    {
      label: t("iAmLookingForTravelers"),
      svg: BaggageIcon,
      index: 2,
      // Component: TabTwo,
      Component: ManualFlightTabTwo,
    },
  ];
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);

  useEffect(() => {
    onSelectTab(selectedTab);
  }, [selectedTab]);

  const changeTheTab = (e) => {
    setSelectedTab(e);
    navigate("/");
  };

  // useEffect(() => {
  //   onSelectSidebarTab(selectedTab);
  // }, [selectedTab]);

  useEffect(() => {
    if (isChangedTab) setSelectedTab(2);
  }, [isChangedTab]);

  return (
    <div className="flight-box-container position-relative">
      {/* <img
        src={Wallpaper}
        className="wallpaper-img"
        alt="wallpaper-img"
      /> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        theme="dark"
      />
      <div className="flight-info-wrapper">
        <Tabs selectedTab={selectedTab} onClick={changeTheTab} tabs={tabs} />
      </div>
    </div>
  );
};
