import React, { useState, useEffect, KeyboardEvent } from "react";
import { Input, Button, RightSidebar } from "layers";
import { Register } from "components";
import { Col, Row } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { UseWindowSize } from "components/windowSize/UseWindowSize";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faCheck } from "@fortawesome/free-solid-svg-icons";
import { forgotPassword } from "redux/actions/Authorization";
import checkEmail from "helpers/checkEmail";
import { validatePhoneNumber } from "helpers/validatePhoneNumber";

interface IProp {
  setIsOpen: Function;
  setIsLogin: Function;
  isOpen: boolean;
}

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: () => any;
  }
}

export const ForgetPassword: React.FC<IProp> = ({
  setIsOpen,
  setIsLogin,
  isOpen,
}) => {
  const size = UseWindowSize();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [emailPhoneNumber, setEmailPhoneNumber] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const forgetPassData: any = useAppSelector((state) => state.forgetPass);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);

  const handleChangeClose = () => {
    setShowMessage(false);
    setIsOpen(!isOpen);
    // document.documentElement.style.overflow = "auto";
  };

  const handleEmailPhoneNumberChange = (e) => {
    const value = e.target.value;
    setEmailPhoneNumber(value);
    setIsValidEmail(checkEmail(value));
    setIsValidPhoneNumber(validatePhoneNumber(value));
  };

  const sendEmailPhonePasswordLink = () => {
    setIsLoading(true);
    const data = { emailPhoneNumber: emailPhoneNumber };
    dispatch(forgotPassword(data));
  };

  useEffect(() => {
    if (forgetPassData?.isSuccess) {
      // setIsOpen(false);
      setIsLoading(false);
      setShowMessage(true);
      setMessage(forgetPassData?.data);
    }
    if (!forgetPassData?.isSuccess) {
      setIsLoading(false);
    }
    // setShowMessage(false);
  }, [forgetPassData]);

  useEffect(() => {
    if (isOpen === true) {
      document.documentElement.style.overflow = "hidden";
    }
  }, [isOpen]);

  return (
    <div className="login-slider-container">
      <Row>
        <Button
          variant="white"
          className="login-close-sidebar-btn"
          onClick={handleChangeClose}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      </Row>

      <Row className="login-wrapper">
        {showMessage ? (
          <Col className="login-form" xs={12}>
            <h1>Find Your Account</h1>
            <span className="message-close-account">
              <FontAwesomeIcon
                className="mr-2 forget-pass-message-check"
                icon={faCheck}
              />
              {message}
            </span>
          </Col>
        ) : (
          <>
            <Col className="login-form" xs={12}>
              <h1>Find Your Account</h1>
              <p>Enter your email address linked to your account</p>
              <Input
                placeholder={t("emailAddress")}
                className="forgetpass-custom-input-login"
                name="emailPhoneNumber"
                value={emailPhoneNumber}
                onChange={handleEmailPhoneNumberChange}
              />
              {/* {!isValidEmail && <p>Invalid email</p>}
          {!isValidPhoneNumber && <p>Invalid phone number</p>} */}
            </Col>

            <Col xs={12}>
              <Button
                variant="primary"
                data-test="docs-btn-anchor"
                className="login-btn mt-4"
                onClick={sendEmailPhonePasswordLink}
              >
                Submit
                {isLoading && (
                  <Oval
                    width="20"
                    height="20"
                    color="#fff"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{ display: "inline", marginLeft: "8px" }}
                  />
                )}
              </Button>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
